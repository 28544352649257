import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UiService } from 'src/app/_services/ui.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { AdminRegionsService } from 'src/app/_services/admin/admin-regions.service';
import { CustomValidators } from 'src/app/_helpers/custom.validators';

@Component({
  selector: 'app-region-item',
  templateUrl: './region-item.component.html',
  styleUrls: ['./region-item.component.scss']
})
export class RegionItemComponent {
  form = this.fb.group({
    id: [this.data.id],
    name: [this.data.name, [Validators.required, CustomValidators.noWhitespaceValidator()]],
    // maxAmount: [this.data.maxAmount, Validators.required],
    code: [this.data.code, [Validators.required, CustomValidators.noWhitespaceValidator()]],
    isActive: [this.data.isActive || false, Validators.required]
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: AdminRegionsService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<RegionItemComponent>,
    private ui: UiService,
    public auth: AuthenticationService
  ) {
    if (!this.auth.hasPermission(this.auth.perm.RegionsManage))
      this.form.disable();
  }



  onSubmit() {
    if (this.form.valid) {
      var d = this.form.value;
      var s = !d.id ? this.service.create(d) : s = this.service.modify(d);
      var k = s.subscribe({
        next: x => {
          this.ui.snack('Region ' + (d.id ? 'saved!' : 'created!'));
          k?.unsubscribe()
          this.dialogRef.close();
        }
      });
    }
  }
}
