import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListWrapperComponent } from './list-wrapper.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { PanelModule } from 'src/app/components/panel/panel.module';



@NgModule({
  declarations: [
    ListWrapperComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    TranslateModule.forChild(),
    PanelModule
  ],
  exports: [
    ListWrapperComponent
  ]
})
export class ListWrapperModule { }
