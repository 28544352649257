<div class="container2">
  <div class="center">
    <form class="login-form login-register-form" [formGroup]="form">
      <img class="login-image d-none d-l-show" src="/assets/images/login.png">
      <div class="image-text">
        <p class="image-text1">{{ 'Transfer money easily' | translate }}</p>
        <p class="image-text2">{{ 'company_name' | translate }}</p>
        <p class="image-text3" routerLink="/register">{{ 'CREATE AN ACCOUNT' | translate }}</p>
      </div>
      <div class="login">
        <h2>{{ 'Log In' | translate }}</h2>
        <p class="login-message">{{ loginMessage }}</p>
        <div class="country" *ngIf="environment.availableAuth !== 'basic'">
          <app-single-select formControlName="countryId" [items]="countries$ | async"
            placeholder="{{ 'Please select your country' | translate }}" [showFlag]="true">
          </app-single-select>
        </div>
        <mat-form-field appearance="outline">
          <mat-label *ngIf="authMethod !== 'BASIC'">{{ 'BankId' | translate }}</mat-label>
          <mat-label *ngIf="authMethod == 'BASIC'">{{ 'Email' | translate }}</mat-label>
          <input matInput type="text" formControlName="username" (keyup.enter)="login()" autocomplete="username" />
          <mat-error *ngIf="errorUserName || form.get('username').invalid">
            {{
            (authMethod === 'BASIC' ? 'Please enter a valid email address':'Please enter a valid BankId or email
            address') | translate }}</mat-error>
        </mat-form-field>
        <div *ngIf="authMethod == 'BASIC'">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Password' | translate }}</mat-label>
            <input matInput formControlName="password" placeholder="{{ 'Please enter your password' | translate }}"
              minlength="6" password [type]="hide ? 'password' : 'text'" [attr.disabled]="!isBankIdBackdoor()"
              (keyup.enter)="login()" autocomplete="current-password" />
            <button mat-icon-button matSuffix [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              <mat-icon (click)="hide = !hide">{{
                hide ? 'visibility_off' : 'visibility'
                }}</mat-icon>
            </button>
          </mat-form-field>

          <div class="forgotPassword">
            <h5 routerLink="/password">
              {{ 'Forgot Password?' | translate }}
            </h5>
          </div>
        </div>

        <div class="btn">
          <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="login()">
            {{ 'Log In' | translate }}
          </button>
        </div>
        <div class="account">
          <p class="account-text1">{{ "Don't have an account?" | translate }}</p>
          <a class="account-text2" routerLink="/register">{{ 'Register' | translate }}</a>
        </div>
      </div>
    </form>
  </div>
</div>