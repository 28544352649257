// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  clientCode: 'VRD',
  apiUrl: 'https://localhost:5001/graphql',
  uploadEndpoint: 'https://localhost:5001/api/files/upload',
  downloadEndpoint: 'https://localhost:5001/api/files/download',
  bankIdRedirectUrl: 'https://localhost:5001/auth/login?username=',
  companyName: 'Verde Money',
  homePageLink: '/',
  availableAuth: 'basic',
  defaultPage: 'dashboard',
  onboardingType: 'circles',
  calculatorPreReceipt: true,
  xApiKey: 'SILSr1A7Am2F92NeyUI8OTJlym1zdV',
  publicNavLinks: '[{"url": "/", "label": "Home" }, { "url": "https://localhost:5001/HowTo", "label": "How It Works" }, { "url": "https://localhost:5001/help", "label": "Help"}]',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
