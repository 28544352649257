import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiService } from 'src/app/_services/ui.service';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { CustomValidators } from 'src/app/_helpers/custom.validators';
import moment from 'moment';
import { AdminDocumentsService } from 'src/app/_services/admin/admin-documents.service';
import { SystemService } from 'src/app/_services/system.service';
import { AdminFilesService } from 'src/app/_services/admin/admin-files.service';
import { PublicService } from 'src/app/_services/public.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { Subscription, Unsubscribable } from 'rxjs';
import { DocumentCategoryType } from 'src/app/_graphql/schema';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent implements OnInit, OnDestroy {


  $categories: Unsubscribable;
  categories: any;
  states$: any;

  documentType = null;

  $files: any;
  fileUploaded: any;
  files: FileList;
  filesUploaded: any[] = [];
  fileFromData: any[] = [];
  removeFile = true;
  fileId: any;
  documentId: any;
  addedFiles: string[] = [];
  documentFiles: any;
  deleteFileIds: string[] = [];
  submited = false;
  minDate = moment(new Date()).add(10, "days").set({ hour: 0, minute: 0, second: 0, millisecond: 0, });

  $form: Subscription;
  form = this.fb.group({
    userId: this.data.userId,
    categoryId: [this.data.document && this.data.document.category ? this.data.document.category.id : null, Validators.required],
    description: [this.data.document ? this.data.document.description : null],
    documentNumber: [this.data.document ? this.data.document.documentNumber : null, [Validators.required, CustomValidators.noWhitespaceValidator()]],
    documentExp: [this.data.document ? this.data.document.documentExp : null, [Validators.required, CustomValidators.dateMin(this.minDate)]],
    documentIssuer: [this.data.document ? this.data.document.documentIssuer : null],
    documentState: [this.data.document ? this.data.document.documentState : null],
    documentNote: [this.data.document ? this.data.document.documentNote : null],
    // documentSource: [this.data.document ? this.data.document.documentSource : null],
    id: [this.data.document ? this.data.document.id : null]
  });

  constructor(
    private dialogRef: MatDialogRef<DocumentUploadComponent>,
    private ui: UiService,
    private service: AdminDocumentsService,
    private adminFilesService: AdminFilesService,

    private systemService: SystemService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private publicService: PublicService,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any = {}
  ) {
    if (this.data.document.files) {
      this.data.file.uploaded = true;
      this.fileFromData = this.data.document.files;
      this.removeFile = false;
      this.fileFromData.forEach(element => {
        this.filesUploaded.push(element);
      });
    }
    if (!this.data.document.documentSource) {
      this.$form = this.form.get('categoryId').valueChanges.pipe(
        distinctUntilChanged()
      ).subscribe(categoryId => {
        this.setDocType(categoryId)
      });
      this.form.markAllAsTouched();
    }
  }
  setDocType(id: string = null) {
    if (id) {
      var k = this.categories.filter(itm => itm.id == id);
      if (k.length == 1) {
        this.documentType = k[0].code
      }
    }
    var fieldDocumentState = this.form.get('documentState')
    if (this.documentType == 'PASSPORT') {
      fieldDocumentState.removeValidators([Validators.required]);
    } else {
      fieldDocumentState.addValidators([Validators.required]);
    }
    fieldDocumentState.updateValueAndValidity({ emitEvent: false })

  }
  ngOnDestroy(): void {
    this.$form?.unsubscribe();
    this.$categories?.unsubscribe();
  }

  ngOnInit() {
    this.$categories = this.systemService.documentCategories().subscribe({
      next: (x: DocumentCategoryType) => {
        this.categories = x;
        this.setDocType(this.form.get('categoryId').value);
      }
    });
    this.states$ = this.publicService.states(this.data.country.isoCode2);
    this.$files = this.adminFilesService.all({ userId: this.data.userId, noDocument: true }, false, "noDocfiles4" + this.data.userId);



    if (this.data.document.documentSource) {
      this.form.controls.categoryId.disable();
      this.form.controls.documentNumber.disable();
      this.form.controls.documentIssuer.disable();
      this.form.controls.documentState.disable();
      this.form.controls.documentNote.disable();
    }
  }

  handleFileInput(files: any) {
    if (files.length === 0) {
      return;
    }
    this.addedFiles = files;

    const index = this.filesUploaded.length;
    for (let i = 0; i < files.length; i++) {
      files[i].uploaded = false;
      this.filesUploaded[index + i] = files[i];
    }
  }

  deleteFile(fileId) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px',
      data: {
        title: 'Delete File',
        content: 'Are you sure you want to remove this file from document?',
        note: '',
      },
      disableClose: true,
      maxHeight: '80%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteFileIds.push(fileId);
        this.ui.snack('File will be removed from document on save button.');
      }
    });
  }

  undeleteFile(fileId) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px',
      data: {
        title: 'Restore File',
        content: 'Are you sure you want to restore this file from document?',
        note: '',
      },
      disableClose: true,
      maxHeight: '80%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let index = this.deleteFileIds.indexOf(fileId)
        if (index > -1) {
          this.deleteFileIds.splice(index, 1);
        }
      }
    });
  }

  downloadFile(file: any) {
    this.publicService.downloadFile(file.id, file.name);
  }

  submitForm() {
    if (this.form.valid) {
      var d = this.form.value;
      var s = !d.id ? this.service.create(d) : s = this.service.modify(d, { addedFilesIds: this.addedFiles, deletedFileIds: this.deleteFileIds });
      var k = s.subscribe({
        next: document => {
          this.ui.snack('Document ' + (d.id ? 'saved!' : 'created!'));
          if (this.addedFiles.length && !d?.id) {
            this.service.addFilesToDocument(this.addedFiles, document.id).subscribe(
              res => {
                this.adminFilesService.refetchData('noDocfiles4' + this.data.userId)
                this.adminFilesService.refetchData('files4' + this.data.userId)
                this.service.refetchData('docs4' + this.data.userId)

                this.dialogRef.close();
                k?.unsubscribe()
              },
              err => {
                this.ui.snack('Error adding files');
              }
            );
          } else {
            this.adminFilesService.refetchData('noDocfiles4' + this.data.userId)
            this.adminFilesService.refetchData('files4' + this.data.userId)
            this.service.refetchData('docs4' + this.data.userId)
            this.dialogRef.close();
            k?.unsubscribe()
            this.dialogRef.close();
          }
        }
      });
    }
  }

  addFile(value) {
    this.addedFiles = value;
  }
  addFiles(value) {
    this.addedFiles.push(value)
  }
  getFiles(all: any[], added: any[], include = false) {
    var r = [];

    all.forEach(a => {
      if (!include && added.indexOf(a.id) == -1)
        r.push(a);
      if (include && added.indexOf(a.id) !== -1)
        r.push(a);
    });
    return r;
  }
  compareObjects(o1: any, o2: any): boolean {
    return this.addedFiles.indexOf(o1.id) > -1;
  }
}
