<div class="io-px-2">
  <div *ngIf="mode == 'normal' && !(onboarding$ | async)?.steps?.length">
    <div [ngClass]="{'io-row':true, 'align-items-center':true}">
      <h3>
        {{ "recipients" | translate | uppercase }}
      </h3>
      <div class="no-flex">
        <button mat-stroked-button (click)="modalLauncherService.newRecipient()">
          {{ "Add New" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="io-carousel" *ngIf="(mode == 'normal' && (onboarding$ | async)?.steps?.length && !(items$|async)?.length) ? false : true">
    <button class="io-carousel-btn btn-left" mat-icon-button (click)="scrollLeft()" 
    *ngIf="mode !== 'normal' ? (items$|async)?.length : true">
      <mat-icon>arrow_back_ios</mat-icon>
    </button>
    <button class="io-carousel-btn btn-right" mat-icon-button (click)="scrollRight()" 
    *ngIf="mode !== 'normal' ? (items$|async)?.length : true">
      <mat-icon>arrow_forward_ios</mat-icon>
    </button>
    <div class="io-carousel-container" #widgetsContent >
      <mat-card class="recip-card create-recipient" [ngClass]="{'choose-recipient': mode !== 'normal' }" *ngIf="!(items$|async)?.length">
        <mat-card-content class="output">
          <div class="placeholder">
            <span class="title">NO RECIPIENTS</span>
            <span class="text">To initiate a money transfer, please create a recipient profile for the person you're sending money to.</span>
          </div>
        </mat-card-content>
        <div class="recipient-content io-row">
          <button class="send-money" mat-raised-button color="primary" (click)="createRecipient()">
            {{ 'Create Recipient' | translate }}
          </button>
        </div>
      </mat-card>
      <div class="box" *ngFor="let item of items$ |async ; let i = index">
        <mat-card class="recip-card" [ngClass]="{'choose-recipient': mode !== 'normal' }">
          <mat-card-header>
            <img class="countries" [src]="publicService.countryFlagImage(item?.country?.isoCode3)" />
            <mat-card-title>{{ item?.name }}</mat-card-title>
          </mat-card-header>
          <mat-card-content class="output">
            <table class="index-table">
              <tbody>
                <tr>
                  <th class="leftContent">{{ 'Address' | translate }}</th>
                  <td class="rightContent">
                    {{ item?.addressLine1 }} {{ item?.addressLine2 ? ',' : '' }} {{ item?.addressLine2 }}
                  </td>
                </tr>
                <tr>
                  <th class="leftContent">{{ 'City' | translate }}</th>
                  <td class="rightContent">{{ item?.city }}</td>
                </tr>
                <tr>
                  <th class="leftContent">{{ 'Telephone' | translate }}</th>
                  <td class="rightContent">{{ item?.phoneCode }}</td>
                </tr>
                <tr>
                  <th class="leftContent">{{ 'Country' | translate }}</th>
                  <td class="rightContent">{{ item?.country.name }}</td>
                </tr>
              </tbody>
            </table>
            <table class="delivered-table">
              <tbody>
                <tr *ngIf="mode == 'normal'" style="padding-top: 50px;">
                  <th class="leftContent">{{ 'Delivered' | translate }}</th>
                  <td class="deliverdNumber">{{ item?.sendingCurrency }} {{ item?.moneySent }}</td>
                </tr>
              </tbody>
            </table>

          </mat-card-content>
          <div class="recipient-content io-row">


            <button class="send-money" mat-raised-button *ngIf="mode == 'normal'" color="primary"
              (click)="sendMoney(item)">
              {{ 'Send Money' | translate }}
            </button>
            <button mat-raised-button *ngIf="mode !== 'normal'" color="primary" (click)="sendMoney(item)">
              {{ 'Choose Recipient' | translate }}
            </button>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>