<form [formGroup]="form">
    <div mat-dialog-title>
        {{ 'Set risk rating'|translate }}
    </div>

    <mat-dialog-content>

        <p>Please select user risk rating from the lis below.</p>
        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
            formControlName="riskRating">
            <div class="io-my-1">
                <mat-radio-button color="primary" [value]="1">
                    Low
                </mat-radio-button>
            </div>
            <div class="io-my-1">
                <mat-radio-button color="primary" [value]="2">
                    Medium
                </mat-radio-button>
            </div>
            <div class="io-my-1">
                <mat-radio-button color="primary" [value]="3">
                    High
                </mat-radio-button>
            </div>
        </mat-radio-group>
        <!-- <pre>{{form.value | json}}</pre> -->

    </mat-dialog-content>

    <mat-dialog-actions [formGroup]="form" align="end">
        <button class="no-button" mat-stroked-button mat-dialog-close>Cancel</button>
        <button class="yes-button" mat-raised-button mat-dialog-close [disabled]="!form.valid" (click)="onSubmit()">Set
            Rating</button>
    </mat-dialog-actions>
</form>