<list-wrapper #wrapper [mainService]="service" [panelTitle]="componentConfig?.hideTitle ? '' : 'Transactions'"
  [managePerm]="auth.perm.TransactionsManage" [readPerm]="auth.perm.TransactionsRead" (onEdit)="editItem($event)"
  [hideAddBtn]="true" [customKey]="customKey">
  <form [formGroup]="form" class="io-row-m width-100" toolbox style="align-items: center">
    <mat-form-field class="width-100 width-mff" appearance="outline" *ngIf="componentConfig?.query">
      <mat-label>{{ 'Transaction Number' | translate }}</mat-label>
      <input matInput formControlName="query">
      <button mat-icon-button matSuffix type="button" (click)="form.patchValue({'query': null})"
        [disabled]="!form.get('query').value">
        <mat-icon *ngIf="form.get('query').value">clear</mat-icon><mat-icon
          *ngIf="!form.get('query').value">search</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="width-100 width-mff" appearance="outline" *ngIf="componentConfig?.dateFrom">
      <mat-label>{{'From' | translate}}</mat-label>
      <input matInput readonly [matDatepicker]="picker2" [max]="form.get('dateTo').value || maxDate"
        placeholder="{{'From' | translate}}" formControlName="dateFrom">
      <div matSuffix>
        <mat-datepicker-toggle [for]="picker2"></mat-datepicker-toggle>
        <button mat-icon-button (click)="form.get('dateFrom').setValue(null)" *ngIf="form.get('dateFrom').value">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="width-100 width-mff" appearance="outline" *ngIf="componentConfig?.dateTo">
      <mat-label>{{'To' | translate}}</mat-label>
      <input matInput readonly [matDatepicker]="picker3" [min]="form.get('dateFrom').value" [max]="maxDate"
        placeholder="{{'To' | translate}}" formControlName="dateTo">
      <div matSuffix>
        <mat-datepicker-toggle [for]="picker3"></mat-datepicker-toggle>
        <button mat-icon-button (click)="form.get('dateTo').setValue(null)" *ngIf="form.get('dateTo').value">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <mat-datepicker #picker3></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="width-100 width-mff" appearance="outline" *ngIf="componentConfig?.countryFromId">
      <mat-label>{{'Country from' | translate}}</mat-label>
      <mat-select formControlName="countryFromId">
        <mat-option *ngFor="let item of countriesSender$ | async" [value]="item.id">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="width-100 width-mff" appearance="outline" *ngIf="componentConfig?.countryToId">
      <mat-label>{{'Country to' | translate}}</mat-label>
      <mat-select formControlName="countryToId">
        <mat-option *ngFor="let item of countriesRecipient$ | async" [value]="item.id">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="width-100 width-mff" appearance="outline" *ngIf="componentConfig?.recipientId">
      <mat-label>{{'Recipient' | translate}}</mat-label>
      <mat-select formControlName="recipientId">
        <mat-option [value]="null">{{'All'|translate}}</mat-option>
        <mat-option *ngFor="let item of recipients$ | async" [value]="item.id">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="width-100 width-mff" appearance="outline" *ngIf="componentConfig?.statusIds">
      <mat-label>{{'Statuses' | translate}}</mat-label>
      <mat-select multiple formControlName="statusIds">
        <mat-option *ngFor="let status of transactionStatuses$ | async" [value]="status.id">{{status.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- <mat-form-field class="width-100 width-mff" appearance="outline">
      <mat-label>{{'Outbound' | translate}}</mat-label>
      <mat-select formControlName="paymentNetworkId" (selectionChange)="changeDisplayedFilters($event)">
        <mat-option *ngFor="let itm of paymentNetworks$ | async" [value]="itm.id">{{itm.name}}</mat-option>
      </mat-select>
    </mat-form-field> -->
    <app-single-select class="width-100 width-mff" formControlName="paymentNetworkId" [items]="paymentNetworks$ | async"
      placeholder="{{ 'Outbound' | translate }}" [required]="true" [optionValue]="['extendedName', 'id']" [panelWidth]="'15%'">
    </app-single-select>
    <mat-form-field class="width-100 width-mff" appearance="outline"
      *ngIf="displayPayoutFilters && this.auth.hasPermission(this.auth.perm.TransactionOutboundStatusManage)">
      <mat-label>{{'Outbound Statuses' | translate}}</mat-label>
      <mat-select multiple formControlName="outboundStatusIds">
        <mat-option *ngFor="let status of outboundStatuses$ | async" [value]="status">{{status}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="width-100 width-mff" appearance="outline"
      *ngIf="displayPayoutFilters && (payoutAgents$|async)?.length">
      <mat-label>{{'Payout Agent' | translate}}</mat-label>
      <mat-select formControlName="payoutAgentId">
        <mat-option [value]="null">
          All
        </mat-option>
        <mat-option *ngFor="let agent of payoutAgents$|async" [value]="agent.id">
          {{agent.firstName}} {{agent.lastName}} ({{agent.email}})
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div style="display: flex; align-items: center;" *ngIf="componentConfig?.onHold">
      <mat-checkbox color="primary" class="on-hold" #checkbox formControlName="onHold">
        {{'On Hold' | translate}}</mat-checkbox>
    </div>
    <div class="d-none d-m-show"></div>
    <div class="no-flex" *ngIf="auth.hasPermission(auth.perm.TransactionsReport)">
      <button mat-raised-button color="primary" type="button" (click)="getReport()" class="btns">{{'Download Report' |
        translate}}</button>
    </div>
  </form>
  <div class="io-py-1">
    <app-transaction *ngFor=" let item of items$|async; last as last;" [item]="item"
      (click)="wrapper.edit(item)"></app-transaction>
  </div>
</list-wrapper>