import { Component, OnInit, Inject, Optional, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessagesService } from '../../_services/messages.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { PublicService } from 'src/app/_services/public.service';
import { AdminFilesService } from 'src/app/_services/admin/admin-files.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  $messages: any;
  messageText: string;
  sendingMessage = false;
  filesUploaded: any[] = [];
  fileId: any;
  @Input('ticketId') ticketId: any;
  @Input('userId') userId: any;

  constructor(
    public messagesService: MessagesService,
    public auth: AuthenticationService,
    public publicService: PublicService,
    public adminFilesService: AdminFilesService,
    @Optional() public dialogRef: MatDialogRef<MessagesComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data) {
      this.$messages = this.messagesService.all((this.data && this.data.ticketId) ? this.data.ticketId : null);
    }
  }

  ngOnInit() {
    const inputs = {
      transactionId: (this.data && this.data.transaction) ? this.data.transaction.id : null,
      ticketId: (this.data && this.data.ticketId) ? this.data.ticketId : null
    };
    this.messagesService.markMessagesSeen(inputs);
  }

  sendMessage(fileId: string = null) {
    const message: any = {
      text: this.messageText || '',
      transactionId: (this.data && this.data.transaction) ? this.data.transaction.id : null,
      ticketId: (this.data && this.data.ticketId) ? this.data.ticketId : null,
      receiverId: (this.data && this.data.userId) ? this.data.userId : null,
    };

    document.getElementById('message').addEventListener('keypress', function (event) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    });

    if (fileId) {
      message.fileId = fileId;
    }

    if (this.filesUploaded.length) {
      this.filesUploaded.forEach(file => {
        message.fileId = file.id;
        this.transferMessage(message);
        message.text = '';
      });

      this.filesUploaded = [];
    } else {
      this.transferMessage(message);
    }
  }

  transferMessage(message: any) {
    this.sendingMessage = true;
    this.messagesService.sendMessage(message).subscribe(
      res => {
        this.sendingMessage = false;
        this.messageText = '';
      },
      error => {
        this.sendingMessage = false;
      }
    );
  }

  handleFileInput(files: any) {
    if (files.length === 0) {
      return;
    }

    const index = this.filesUploaded.length;
    for (let i = 0; i < files.length; i++) {
      files[i].uploaded = false;
      this.filesUploaded[index + i] = files[i];
    }

    this.publicService
      .upload(files, null, this.auth.isAdmin() ? this.data.userId : null, null, this.data.ticketId, null, false)
      .subscribe((uploadedFiles) => {
        this.fileId = uploadedFiles.id;
        if (!uploadedFiles || uploadedFiles.length !== files.length) {
          console.error('Missing one or more file ids.');
          return;
        }

        for (let i = 0; i < files.length; i++) {
          this.filesUploaded[index + i] = uploadedFiles[i];
          this.filesUploaded[index + i].uploaded = true;
        }

      });
  }

  deleteFile(fileId) {
    if (this.filesUploaded.length === 0) {
      return;
    }

    this.filesUploaded = this.filesUploaded.filter(
      file => file.id !== fileId
    );
  }
}
