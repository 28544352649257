import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoutComponent } from './logout/logout.component';
import { PanelPlaceholderComponent } from './panel-placeholder/panel-placeholder.component';
import { PanelComponent } from './panel.component';
import { PanelPlaceholderAdminComponent } from './panel-placeholder-admin/panel-placeholder.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { PanelTitleComponent } from './panel-title/panel-title.component';



@NgModule({
  declarations: [
    LogoutComponent,
    PanelPlaceholderComponent,
    PanelPlaceholderAdminComponent,
    PanelComponent,
    PanelTitleComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    TranslateModule.forChild(),
  ],
  exports: [
    LogoutComponent,
    PanelPlaceholderComponent,
    PanelPlaceholderAdminComponent,
    PanelComponent,
    PanelTitleComponent,

  ]

})
export class PanelModule { }
