<list-wrapper #wrapper [mainService]="service" [customKey]="customKey" panelTitle=""
  [managePerm]="auth.perm.RegionsManage" [readPerm]="auth.perm.RegionsRead" [hideAddBtn]="true">
  <div class="io-py-1">
    <div class="io-item io-item-shadow" *ngFor=" let item of items$|async; last as last;">
      <div class="main">
        <h4>{{ item?.transaction?.transactionId }}</h4>
        <div class="data createdby">
          <small>{{'Date'|translate}}</small>
          <span>{{ item?.created | timezone2 | date:'short'}}&nbsp;</span>
        </div>
        <div class="data">
          <small>{{'Note'|translate}}</small>
          <span>{{ item?.note }}&nbsp;</span>
        </div>
        <div class="data createdby">
          <small>{{'Created by'|translate}}</small>
          <span>{{ item?.createdByName }}&nbsp;</span>
        </div>
      </div>
    </div>
  </div>

  <!-- <mat-action-list [multiple]="false">
    <mat-list-item *ngFor=" let item of items$|async; last as last;" (click)="wrapper.edit(item)"
      [ngClass]=" {'io-disabled': !item.isActive}">
      <h4 matLine>{{item.name}} ({{item.code}})</h4>
      <p mat-line>max alowed: {{ item.maxAmount }}</p>

      <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
    </mat-list-item>
  </mat-action-list> -->
</list-wrapper>