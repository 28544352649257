<list-wrapper #wrapper [mainService]="service" panelTitle="Regions" [managePerm]="auth.perm.RegionsManage"
  [readPerm]="auth.perm.RegionsRead" (onNew)="newItem()" (onEdit)="editItem($event)">
  <mat-action-list [multiple]="false">
    <mat-list-item *ngFor=" let item of items$|async; last as last;" (click)="wrapper.edit(item)"
      [ngClass]=" {'io-disabled': !item.isActive}">
      <h4 matLine>{{item.name}}</h4>
      <p mat-line>{{item.code}}</p>

      <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
    </mat-list-item>
  </mat-action-list>
</list-wrapper>