import { NgModule } from "@angular/core";
import { VerifyUserComponent } from "./verify-user.component";
import { CommonModule } from "@angular/common";
import { PlatformRoutingModule } from "src/app/platform/platform-routing.module";
import { MaterialModule } from "src/app/material.module";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ComponentsModule } from "src/app/components/components.module";
import { TranslateModule } from "@ngx-translate/core";


@NgModule({
  declarations: [
    VerifyUserComponent
  ],
  imports: [
    CommonModule,
    PlatformRoutingModule,
    MaterialModule,
    BrowserModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    ComponentsModule,
  ],

})
export class VerifyModule { }
