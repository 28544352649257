import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstNotStarted'
})
export class FirstNotStartedPipe implements PipeTransform {
  transform(list: any[], element: any): boolean {
    if (!list || !element) return false;

    const firstNotStartedIndex = list.findIndex(item => 
      item.status === 'NOT_STARTED' || item.status === 'PENDING');
    
    if (firstNotStartedIndex === -1 || list.indexOf(element) !== firstNotStartedIndex) {
      return false;
    }
    
    return true;
  }
}
