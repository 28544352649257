import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UiService } from 'src/app/_services/ui.service';
import { TranslationsService } from 'src/app/_services/translations.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tab-top-wrapper',
  templateUrl: './tab-top-wrapper.component.html',
  styleUrls: ['./tab-top-wrapper.component.scss']
})
export class TabTopWrapperComponent implements OnInit {
  loading = false;
  navLinks = [];

  constructor(
    public trans: TranslationsService,
    public auth: AuthenticationService,
  ) {
    this.navLinks.push({
      path: "profile",
      label: "Profile",
      active: true
    });
    this.navLinks.push({
      path: "transactions",
      label: "Transactions",
      active: false
    });
    this.navLinks.push({
      path: "recipients",
      label: "Recipients",
      active: false
    });
    this.navLinks.push({
      path: "tickets",
      label: "Tickets",
      active: false
    });
    if (auth.hasPermission(auth.perm.PaymentMethodsManage))
      this.navLinks.push({
        path: "payment-methods",
        label: "Payment Methods",
        active: false
      });
  }

  ngOnInit() { }
}
