<div>
    <div class="io-row align-items-center io-pl-3 border-bottom" style="background-color: white; ">
        <button mat-icon-button routerLink="../.." class="no-flex">
            <mat-icon>chevron_left</mat-icon>
        </button>
        <app-admin-ticket-list-item [item]=" item$|async" [hasShadow]="false" [isClickable]="false">
            <button *ngIf="auth.hasPermission(auth.perm.FlagsManage)" color="primary"
                [disabled]="(item$|async)?.raisedFlag?.resolved || 
                (item$|async)?.raisedFlag?.flag.code === 'DELETE_ACCOUNT_REQUESTED'" 
                mat-raised-button (click)="closeTicket()">{{'Close ticket'|translate}}</button>
        </app-admin-ticket-list-item>
    </div>
    <div class="io-px-2 io-py-2">
        <div class="io-row-l">
            <div class="flex-2">
                <app-chat (fileUploaded)="onFileUploaded()" [ticketId]="itemId"></app-chat>
            </div>
            <div *ngIf="(item$|async) as item" class="">
                <mat-accordion>
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ 'Ticket info' | translate }}
                            </mat-panel-title>
                            <mat-panel-description>

                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div *ngIf="isJSON(item?.description) as json; else elseBlock">
                            <div *ngIf="json">
                                <div *ngFor="let _itm of json" class="io-item io-p-3 io-item-shadow"
                                    style="margin: 0 0 24px 0;">
                                    <div style="padding: 16px">
                                        <div><strong *ngIf="_itm?.name">Name: {{_itm?.name}}</strong></div>
                                        <div><small *ngIf="_itm?.sources">Sources: {{ _itm?.sources.join(', ')
                                                }}</small>
                                        </div>
                                        <div><small *ngIf="_itm?.types">Types: {{ _itm?.types.join(', ') }}</small>
                                        </div>
                                    </div>
                                    <mat-list style="width: 100%; flex: 1;">
                                        <mat-list-item *ngFor="let field of _itm?.fields">
                                            <h3 mat-line>{{ field?.name }}</h3>
                                            <p mat-line>{{ field?.value }}</p>
                                        </mat-list-item>
                                    </mat-list>
                                </div>

                            </div>
                        </div>
                        <ng-template #elseBlock>
                            <table class="table-details">
                                <tbody>
                                    <tr>
                                        <th>{{'Description'|translate}}</th>
                                        <td>{{ item?.description || 'No fin' }}</td>
                                    </tr>
                                    <tr>
                                        <th>{{'Created'|translate}}</th>
                                        <td>{{item?.created | date }}</td>
                                    </tr>
                                    <tr>
                                        <th>{{'Created by'|translate}}</th>
                                        <td>{{item?.createdByName}}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </ng-template>
                    </mat-expansion-panel>
                </mat-accordion>
                <div class="io-my-2" *ngIf="item.raisedFlag as rf">
                    <mat-accordion>
                        <mat-expansion-panel [expanded]="true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>{{ 'Flag' | translate }}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <table class="table-details">
                                <tbody>
                                    <tr>
                                        <th>{{ 'Name' | translate }}</th>
                                        <td>{{ rf?.flag?.name }}</td>
                                    </tr>
                                    <tr>
                                        <th>{{ 'Created' | translate }}</th>
                                        <td>{{ rf?.created | timezone | date }}</td>
                                    </tr>
                                    <tr>
                                        <th>{{ 'Created by' | translate }}</th>
                                        <td>{{ rf?.createdByName | noData }}

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <app-json-renderer [items]="rf?.description"></app-json-renderer>
                            <table class="table-details">
                                <tbody>
                                    <tr>
                                        <th>{{ 'Resolved' | translate }}</th>
                                        <td>{{ rf?.resolved | timezone | date | noData }}</td>
                                    </tr>
                                    <tr>
                                        <th>{{ 'Resolved by' | translate }}</th>
                                        <td>{{rf.resolvedBy?.firstName | noData }} {{rf.resolvedBy?.middleName
                                            }}{{rf.resolvedBy?.lastName }}

                                        </td>
                                    </tr>

                                    <tr>
                                        <th>{{ 'Resolved Note' | translate }}</th>
                                        <td>{{ rf?.resolvedNote | noData }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <div class="io-my-2">
                    <mat-accordion>
                        <mat-expansion-panel [expanded]="true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>{{ 'Files' | translate }}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <app-files-list [files]="item?.files" [ticketId]="item?.id"
                                [userId]="item?.raisedFlag?.forUserId" [newButtonHide]="true">
                            </app-files-list>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <mat-accordion>
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>{{ 'Internal note' | translate }} </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="internal-note">{{item?.internalNote}}</p>
                        <p *ngIf="!item?.internalNote?.length">{{ 'No notes yet.' | translate }}</p>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
</div>