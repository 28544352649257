import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { PipesModule } from 'src/app/_pipes/pipes.module';
import { AdminKycLogsComponent } from './admin-kyc-logs.component';
import { ListWrapperModule } from '../../list-wrapper/list-wrapper.module';
import { AdminKycLogItemComponent } from './admin-kyc-log-item/admin-kyc-log-item.component';

@NgModule({
  declarations: [AdminKycLogsComponent, AdminKycLogItemComponent],
  imports: [CommonModule, MaterialModule, ListWrapperModule, TranslateModule, FormsModule, ReactiveFormsModule, PipesModule],
  entryComponents: []
})
export class AdminKycLogsModule { }
