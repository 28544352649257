import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { CountryType } from 'src/app/_graphql/schema';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class AdminCountriesService extends BaseService<CountryType>{

  selectOneFields = gql`
    fragment CountrySelectOneFields on CountryType  {
      currency
      id
      isActive
      isoCode2
      isoCode3
      isRecipient
      isSender
      maxAmount
      name
      phonePrefix
      region {
        code
        id
        isActive
        maxAmount
        name
      }
    }
  `;

  selectAllFields = gql`
    fragment CountrySelectAllFields on CountryType  {
      currency
      id
      isActive
      isoCode2
      isoCode3
      isRecipient
      isSender
      maxAmount
      name
      phonePrefix
      region {
        code
        id
        isActive
        maxAmount
        name
      }
    }
  `;

  constructor(protected apollo: Apollo) {
    super(apollo);
    this.initGql('country', 'countries');
  }

  public pictureName(country: string) {
    return country
      ? country
        .toString()
        .toLowerCase()
        .split(' ')
        .join('-')
      : '';
  }
}
