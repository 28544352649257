import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { RegionItemComponent } from './region-item/region-item.component';
import { RegionType } from 'src/app/_graphql/schema';
import { AdminRegionsService } from 'src/app/_services/admin/admin-regions.service';
import { Subscribable } from 'rxjs';

@Component({
  selector: 'app-regions',
  templateUrl: './regions.component.html',
  styleUrls: ['./regions.component.scss']
})
export class RegionsComponent {
  items$: Subscribable<any>;

  constructor(
    public service: AdminRegionsService,
    private dialog: MatDialog,
    public auth: AuthenticationService
  ) {
    this.items$ = this.service.all()
  }



  newItem() {
    this.openDialog(RegionItemComponent);
  }

  editItem(region: RegionType) {
    this.openDialog(RegionItemComponent, region);
  }

  regionActiveChanged(event: any, region: any) {
    // event.stopPropagation();
    return false;
  }

  openDialog(component: any, param = {}) {
    this.dialog.open(component, {
      height: 'auto',
      width: '360px',
      disableClose: true,
      data: param
    });
  }
}
