<div [ngClass]="{'io-item':true, 'io-item-shadow':hasShadow, clickable:isClickable}">
    <div class="main">
            <mat-icon class="status d-none d-l-show" *ngIf="!item.resolved" color="warn">error</mat-icon>
            <mat-icon class="status done d-none d-l-show" *ngIf="item.resolved" color="success">check_circle</mat-icon>
        <div class="data flex-3">
            <small>Subject</small>
            <span>{{item?.flag.name}}</span>
        </div>

        <app-ticket-status [ticket]="item" class="d-none d-l-show"></app-ticket-status>
        <div class="data">
            <small>Level</small>
            <span>
                {{ (item?.level == 'USER' ? 'User' : "Transaction") | translate }}
            </span>
        </div>
        <div class="data user">
            <small *ngIf="item?.user">{{ 'User' | translate}}</small>
            <small *ngIf="item?.transaction">{{ 'Transaction' | translate}}</small>
            <span>
                {{item?.user?.firstName}}
                {{item?.user?.middleName}}
                {{item?.user?.lastName}}
                {{item?.transaction?.transactionId}}
            </span>
        </div>
        <div class="data">
            <small>{{ 'Created' | translate}}</small>
            <span matTooltip="{{ item?.created | timezone | date }}">
                {{item?.created | timezone | date }}
            </span>
        </div>

        <div class="data no-flex cta">
            <ng-content></ng-content>
        </div>
    </div>
</div>