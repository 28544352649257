<div class="chat-panel">
  <div class="messages" #scrollMe [scrollTop]="scrollMe.scrollHeight">
    <div class="message-list">
      <div *ngFor="let msg of $messages | async" class="message"
        [ngClass]="{ 'message-right': msg.sender.id !== auth.user.id }">
        <div class="message-info">
          <img class="user-image" src="/assets/images/avatar.png">
          <div class="message-from">{{ msg.sender.email }}</div>
        </div>
        <div class="message-send">
          <div class="message-text">{{ msg.text }}</div>
          <div class="message-uploaded-files" *ngIf="msg.file">
            {{ msg.file.name }}
            <button mat-icon-button color="primary" (click)="publicService.downloadFile(msg.file.id, msg.file.name)">
              <mat-icon style="font-size: 18px;">get_app</mat-icon>
            </button>
          </div>
          <div class="message-timestamp">{{ msg.created | dateAgo }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="message-control">
    <mat-form-field mat-form-field appearance="outline">
      <textarea matInput id="message" type="text" (keydown.enter)="sendMessage()" placeholder="Send Message"
        [(ngModel)]="messageText" matTextareaAutosize matAutosizeMinRows="1" matAutosizeMaxRows="5"></textarea>
      <!-- <mat-hint>Receiving currency.</mat-hint> -->

      <div matSuffix class="message-commands">
        <button mat-icon-button color="primary" (click)="uploader.click()" [disabled]="sendingMessage">
          <mat-icon>attachment</mat-icon>
        </button>
        <input hidden multiple type="file" #uploader (change)="handleFileInput($event.target.files)" />
      </div>

      <div *ngIf="filesUploaded.length" class="uploaded-files">
        <div *ngFor="let file of filesUploaded" [ngClass]="{ 'file-uploading': !file.uploaded }">
          {{ file.name }}
          <button mat-icon-button [disabled]="!file.uploaded" (click)="deleteFile(file.id)" class="uploaded-file-delete"
            color="primary">
            <mat-icon class="delete-icon">close</mat-icon>
          </button>
        </div>
      </div>

    </mat-form-field>
    <div>
      <button mat-raised-button color="primary" class="send-button" (click)="sendMessage(this.fileId)"
        [disabled]="sendingMessage">Send</button>
    </div>
  </div>
</div>