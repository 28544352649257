<div mat-dialog-title>
  Create ticket for "{{data?.raisedFlag?.flag?.name}}" flag
</div>
<mat-dialog-content>
  <form class="container" [formGroup]="form">
    <mat-form-field appearance="outline">
      <mat-label>Subject</mat-label>
      <textarea matInput formControlName="subject" maxlength="200"></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description" maxlength="200"></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Internal note</mat-label>
      <textarea matInput formControlName="internalNote" maxlength="200"></textarea>
      <mat-hint>Not visible to external users.</mat-hint>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" (click)="createTicket()" [disabled]="!form.valid">Create</button>
</mat-dialog-actions>
