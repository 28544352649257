<list-wrapper #wrapper [mainService]="service" panelTitle="Languages" [managePerm]="auth.perm.CompanyManage"
  [readPerm]="auth.perm.CompanyManage" (onNew)="newItem()" (onEdit)="editItem($event)">
  <mat-action-list [multiple]="false">
    <mat-list-item *ngFor=" let item of items$|async; last as last;" (click)="wrapper.edit(item)">
      <app-country-flag matListIcon [item]="item"></app-country-flag>

      <h4 mat-line>{{ item?.name }}</h4>
      <p matLine class="demo-2">
        {{ item?.isoCode2 }} / {{ item?.isoCode3 }}
      </p>
      <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
    </mat-list-item>
  </mat-action-list>
</list-wrapper>