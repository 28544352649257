import { Component, OnInit, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-external-id',
  templateUrl: './external-id.component.html',
  styleUrls: ['./external-id.component.scss']
})
export class ExternalIdComponent implements OnInit {
  dataForm = this.fb.group({
    externalId: null
  });

  data: any;
  sentData: any;
  hideId = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public params: any,
    private dialogRef: MatDialogRef<ExternalIdComponent>,
    private fb: UntypedFormBuilder
  ) {
    this.sentData = params;
    if (this.sentData.transaction.paymentNetworkCode === 'BAKAAL') {
      this.hideId = false;
    }

    this.data = {
      transactionId: params.transaction.id,
      // statusId: params.status.id,
      externalId: null
    };
  }

  ngOnInit() {}

  setStatus() {
    this.data.externalId = this.dataForm.controls.externalId.value;
    this.dialogRef.close(this.data);
  }

  close() {
    this.dialogRef.close();
  }
}
