import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { EndUserType, Recipient } from 'src/app/_graphql/schema';
import { BaseService } from '../admin/base.service';



@Injectable({
  providedIn: 'root'
})
export class UserRecipientsService extends BaseService<Recipient>{

  selectOneFields = gql`
    fragment RecipientSelectOneFields on Recipient  {
      addressLine1
      addressLine2
      bankAccNumber
      iban
      bankName
      city
      dateOfBirth
      email
      firstName
      id
      lastName
      middleName
      name
      phone
      phoneCode
      relationship {id name }
      sendingCurrency
      country { id name  isoCode2 isoCode3 phonePrefix isActive }
  }`;

  selectAllFields = gql`
    fragment RecipientSelectAllFields on Recipient  {
      id name city phoneCode addressLine1 addressLine2 firstName
      sendingCurrency moneySent
      country { id name  isoCode2 isoCode3 phonePrefix isActive }
  }`;

  constructor(protected apollo: Apollo) {
    super(apollo);
    this.mainScope = "user";
    this.initGql('recipient');
  }
}
