<div class="wrapper">
  <div class="toolbox" *ngIf=" mainService.totalCount > 0 || disablePlaceholder">

    <ng-content select="[toolbox]"></ng-content>



  </div>

  <div class="main-content io-py-1">
    <div>
      <div *ngIf=" mainService.totalCount > 0 || disablePlaceholder">
        <ng-content></ng-content>
      </div>
      <div *ngIf=" mainService.totalCount == 0 && !disablePlaceholder">
        <ng-content select="[placeholder]"></ng-content>
      </div>
    </div>
  </div>


  <ng-content select="[footer]"></ng-content>
  <mat-paginator *ngIf="showPagination && ( mainService.totalCount > 0)" [disabled]="mainService.loading"
    [length]="mainService.totalCount" [pageSize]="mainService.queryAllParams.take" [pageSizeOptions]="[5, 10, 25, 100]"
    (page)="mainService.applyPager($event.pageIndex, $event.pageSize, this.customKey)"
    [pageIndex]="mainService.pageIndex">
  </mat-paginator>
</div>
<button *ngIf="auth.hasPermission(managePerm) && !hideAddBtn" color="primary" class="fab-fixed" mat-fab
  aria-label="Add new" (click)="newItem()">
  <mat-icon>add</mat-icon>
</button>

<ng-template #loading>
  <!-- <div style="min-height: 75vh;">
    <div>
      <mat-spinner diameter="40"></mat-spinner>
    </div>
  </div> -->
</ng-template>