import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, forwardRef, Optional, Self, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, Subscribable, Unsubscribable } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { CountryType } from 'src/app/_graphql/schema';
import { CustomValidators } from 'src/app/_helpers/custom.validators';
import { PublicService } from 'src/app/_services/public.service';
export interface FormFieldValue {
  countryControl: string;
}
@Component({
  selector: 'app-single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SingleSelectComponent,
    }
  ]
})
export class SingleSelectComponent implements OnInit, ControlValueAccessor, OnDestroy, MatFormFieldControl<FormFieldValue> {
  form:UntypedFormGroup = this.fb.group({
    countryControl: new UntypedFormControl('', [CustomValidators.checkIfCountryIsChoosen()]),

  })
  controlToParent: UntypedFormControl = new UntypedFormControl(null);

  @ViewChild('autoGroup') matAutocomplete: MatAutocomplete;

  input: ElementRef;
  @Input()
  set value(value: FormFieldValue) {
    this.form.patchValue(value);
    this.stateChanges.next();
  }
  get value() {
    return this.form.value;
  }
  @Input()
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  get placeholder() {
    return this._placeholder;
  }
  @Input()
  set items(value: any) {
    this._items = value;
    this.form.patchValue({countryControl: this.items?.find(x => x[this.optionValue[1]] === this.idFromParent)});

  }
  get items() {
    return this._items;
  }

  _items:any;
  @Input() disabled = false;
  @Input() appearance = 'outline';
  @Input() required: boolean = false;
  @Input() optionValue: string[] = ['name', 'id'];
  @Input() showFlag: boolean = false;
  @Input() hintText: string = '';
  @Input() panelWidth: string = '';

  @Output() selectionChange = new EventEmitter<string>();





  constructor(
    public publicService: PublicService,
    @Optional() @Self() public ngControl: NgControl,
    private fb: UntypedFormBuilder,
    private focusMonitor: FocusMonitor,
  ) {
    if (this.ngControl != null) {
      ngControl.valueAccessor = this;
    }
    
   }

  stateChanges = new Subject<void>();
  id: string;
  private _placeholder: string;
  focused: boolean;
  get empty(): boolean {
    return !this.value.countryControl;
  }
  shouldLabelFloat: boolean;

  get errorState() {
    return this.ngControl.errors !== null && !!this.ngControl.touched;
  } 
  dropdownList:any[];
  unsubList$:Unsubscribable;
  formUnsub$:Unsubscribable;
  idFromParent: string;
  lastValueOfControl;
  setDescribedByIds(ids: string[]): void {
    // throw new Error('Method not implemented.');
  }
  onContainerClick(event: MouseEvent): void {
    // throw new Error('Method not implemented.');
  }

  public currentCountryTo: any = { name: 'banner' };
  ngOnInit() {
     if (this.ngControl?.control?.hasValidator(Validators.required))
      this.form.get('countryControl').setValidators([Validators.required, CustomValidators.checkIfCountryIsChoosen()]);

    this.formUnsub$ =  this.form.get('countryControl').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(data => {
      if (data === '' || this.form.invalid) {
        this.controlToParent.setValue(null);
        this.propagateChange(this.controlToParent.value);
      }
      if (data === '') {
        this.lastValueOfControl = null;
      }
    });
  }

  selectionChangeEmit() {
    this.lastValueOfControl = null;
    this.controlToParent.setValue(this.form.value.countryControl[this.optionValue[1]]);
    this.propagateChange((this.controlToParent.value));
    this.selectionChange.emit(this.controlToParent.value);

  }
  public writeValue(data: any) { 
    this.idFromParent = data;
    if (this.items) 
      this.form.patchValue({countryControl: this.items?.find(x => x[this.optionValue[1]] === data)});
      // this.controlToParent.setValue(this.form?.value?.countryControl[this.optionValue[1]])
  }
  
  registerOnTouched(onTouched: any) {
  }

  markAsTouched() {

  } 
   public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
    this.form.controls['countryControl'].disable();
  }
  private propagateChange = (_: any) => {};
  focusInput() {
    if (this.form.valid)
      this.lastValueOfControl = this.form.value.countryControl;
    this.clearInput();
  }
  onBlur() {
    setTimeout(() => {
      if (this.lastValueOfControl && !this.matAutocomplete?.isOpen) {
        this.form.patchValue({countryControl: this.lastValueOfControl});
        this.controlToParent.setValue(this.form.value.countryControl[this.optionValue[1]]);
        this.propagateChange(this.controlToParent.value);
      }  
    }, 200);
  }
  displayFn(value?: any) {
    return value ? this._items?.find(_ => _[this.optionValue[0]] === value[this.optionValue[0]])[this.optionValue[0]] : undefined;
  }
  ngOnDestroy(): void {
    this.unsubList$?.unsubscribe();
    this.formUnsub$?.unsubscribe();
  }
  clearInput() {
    this.form.patchValue({countryControl: null});
    this.controlToParent.setValue(null);
    this.propagateChange(this.controlToParent.value);
  }

}
