import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { UiService } from 'src/app/_services/ui.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { CurrencyItemComponent } from '../../currency/currency-item/currency-item.component';
import { CountryItemComponent } from '../../countries/country-item/country-item.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminCorridorsService } from 'src/app/_services/admin/admin-corridors.service';
import { AdminCountriesService } from 'src/app/_services/admin/admin-countries.service';
import { AdminCurrenciesService } from 'src/app/_services/admin/admin-currencies.service';
import { SystemService } from 'src/app/_services/system.service';
import { CorridorType, CountryType, PaymentNetworkType } from 'src/app/_graphql/schema';
import { Unsubscribable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidators } from 'src/app/_helpers/custom.validators';


@Component({
  selector: 'app-corridor-item',
  templateUrl: './corridor-item.component.html',
  styleUrls: ['./corridor-item.component.scss']
})
export class CorridorItemComponent implements OnInit, OnDestroy {
  itemId: string;
  item: CorridorType;
  $item: Unsubscribable;

  paymentServices$: any;
  $paymentNetworks: Unsubscribable;
  paymentNetworks: PaymentNetworkType[] = [];

  $countrySenders: Unsubscribable;
  countrySenders: CountryType[] = []
  $countryReceivers: Unsubscribable;
  countryReceivers: CountryType[] = []
  currencies$: any;
  manager = true;
  showOutbound = false;

  feeVariableIdx = null;
  feeFixedIdx = null;
  feeOutboundIdx = null;

  dialogRef: MatDialogRef<any>
  calculatorAmount = new UntypedFormControl(100);
  calculatorOpen = false;


  @ViewChild('calcDialog') calcDialog = {} as TemplateRef<any>;


  form = this.fb.group({
    id: [null],
    isActive: [false, Validators.required],
    minAmount: [null, Validators.required],
    maxAmount: [null, Validators.required],
    exchangeCorrectionRate: [0, [Validators.required, Validators.pattern('^[-]?[0-9]+(?:\\.[0-9]{0,6})?')]],
    fixedFee: [null, Validators.required],
    variableFee: [null, Validators.required],
    fromId: [null, Validators.required],
    toId: [null, Validators.required],
    paymentNetworkId: [null, Validators.required],
    paymentServiceId: [null, Validators.required],
    receivingCurrency: [null, Validators.required],
    checkCurrency: [null, Validators.required],
    VARIABLE_FEE: this.fb.array([]),
    FIXED_FEE: this.fb.array([]),
    OUTBOUND_FEE: this.fb.array([]),
  });

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private service: AdminCorridorsService,
    public systemService: SystemService,
    public countriesService: AdminCountriesService,
    public currenciesService: AdminCurrenciesService,
    public auth: AuthenticationService,
    private ui: UiService,
    private dialog: MatDialog,
    private snack: MatSnackBar
  ) {

    if (!this.itemId && this.route.snapshot.paramMap.get('corridorId') != 'new') {
      this.itemId = this.route.snapshot.paramMap.get('corridorId');
    }
    if (this.itemId) {
      this.$item = this.service.one(this.itemId).subscribe({
        next: x => {

          this.showOutbound = x.network?.code == "BAKAAL"
          this.item = x;
          var _x: any = Object.assign({
            fromId: x.from.id,
            toId: x.to.id,
            paymentNetworkId: x.network.id,
            paymentServiceId: x.service.id,
            fixedFee: 0,
            fees: []
          }, x);
          this.form.patchValue(_x)

          this.getFA('FIXED_FEE').clear();
          this.getFA('VARIABLE_FEE').clear();
          this.getFA('OUTBOUND_FEE').clear();

          x.fees.forEach(fee => {
            this.insertFeeGroup(fee.feeTypeCode, fee.feeValueType, fee.toAmount, fee.feeAmount);
          });
          this.setupDropdowns();
        }
      });
    } else {
      this.setupDropdowns();
    }


    if (!this.auth.hasPermission(auth.perm.CorridorsManage)) {
      this.form.disable();
    }

    if (this.itemId) {
      this.form.controls.fromId.disable();
      this.form.controls.toId.disable();
      this.form.controls.paymentNetworkId.disable();
      this.form.controls.paymentServiceId.disable();
    }
  }

  setupDropdowns() {
    this.$countrySenders = this.countriesService.dropdownAll({ isSender: true }, true, 'senders').subscribe({
      next: x => {
        this.countrySenders = [...x];
        if (this.item && this.item?.from?.id) {
          if (x.findIndex(country => country.id === this.item?.from?.id) === -1) {
            var s = this.countriesService.one(this.item?.from?.id).subscribe({
              next: x => {
                this.countrySenders = [...this.countrySenders, x]
              }
            })
          }
        }

      }
    });
    this.$countryReceivers = this.countriesService.dropdownAll({ isRecipient: true }, true, 'receivers').subscribe({
      next: x => {
        this.countryReceivers = [...x];
        if (this.item && this.item?.to?.id) {
          if (x.findIndex(country => country.id === this.item?.to?.id) === -1) {
            var s = this.countriesService.one(this.item?.to?.id).subscribe({
              next: x => {
                this.countryReceivers = [...this.countryReceivers, x]
              }
            })
          }
        }
      }
    });

    this.paymentServices$ = this.systemService.paymentServices();
    this.$paymentNetworks = this.systemService.paymentNetworks().subscribe({
      next: x => {
        this.paymentNetworks = x;
      }
    });


    this.form.get('minAmount')?.valueChanges.subscribe({
      next: x => {
        this.revalidate();
      }
    })
    this.form.get('maxAmount')?.valueChanges.subscribe({
      next: x => {
        this.revalidate();
      }
    })
    this.form.get('paymentNetworkId')?.valueChanges.subscribe({
      next: selectedId => {
        var selectedNetwork = this.paymentNetworks.find(network => network.id == selectedId)
        this.showOutbound = selectedNetwork && selectedNetwork.code == 'BAKAAL'
      }
    })

    this.currencies$ = this.currenciesService.dropdownAll();
  }


  ngOnInit() {
  }
  navigateToCorridors(): void {
    this.router.navigate(['/', 'admin', 'corridors']);
  }
  revalidate() {
    this._revalidate('VARIABLE_FEE')
    this._revalidate('FIXED_FEE')
    this._revalidate('OUTBOUND_FEE')
  }
  _revalidate(key: string) {
    this.calculatorAmount.setValidators([Validators.min(this.form.get('minAmount').value), Validators.max(this.form.get('maxAmount').value)])
    this.calculatorAmount.updateValueAndValidity()
    var fa = this.getFA(key);
    Object.keys(fa.controls).forEach(element => {
      var fg = fa.controls[element] as UntypedFormGroup;
      fg.markAllAsTouched();
      let fc = fg.get('toAmount') as UntypedFormControl;
      fc.setValue(fc.value, { emitEvent: true })
      fc.markAsDirty();
    });
  }


  getFG(q: UntypedFormArray, idx: number): UntypedFormGroup {
    return q.at(idx) as UntypedFormGroup;
  }
  getFA(name: string): UntypedFormArray {
    return this.form.get(name) as UntypedFormArray
  }

  insertFeeGroup(feeTypeCode: string, feeValueType: string, toAmount: number = null, feeAmount: number = null) {
    if (feeTypeCode == 'FIXED_FEE')
      this.form.patchValue({ fixedFee: 0 })
    if (feeTypeCode == 'VARIABLE_FEE')
      this.form.patchValue({ variableFee: 0 })

    var formArray = this.form.get(feeTypeCode) as UntypedFormArray;
    formArray?.push(this.fb.group({
      feeTypeCode: [feeTypeCode, [Validators.required]],
      feeValueType: [feeValueType, [Validators.required]],
      toAmount: [toAmount, [Validators.required, CustomValidators.upperAmountValidation()]],
      feeAmount: [feeAmount, [Validators.required]],
    }));
  }
  checkArray(idx: number, fa: UntypedFormArray) {
    fa.at(idx).get('toAmount').updateValueAndValidity();
  }



  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    if (this.form.valid) {
      var d = this.form.getRawValue()
      d.fees = [
        ...d.FIXED_FEE,
        ...d.VARIABLE_FEE,
        ...d.OUTBOUND_FEE,
      ]
      delete d.FIXED_FEE;
      delete d.VARIABLE_FEE;
      delete d.OUTBOUND_FEE;

      var s = !d.id ? this.service.create(d) : s = this.service.modify(d);
      var k = s.subscribe({
        next: x => {
          this.ui.snack('Corridor ' + (d.id ? 'saved!' : 'created!'));
          if (!d.id)
            this.router.navigate(['/', 'admin', 'corridors'])
          k?.unsubscribe()
          // this.dialogRef.close();
        }
      });
    }
  }
  removeLine(index: number, fa: UntypedFormArray) {
    fa.removeAt(index);
  }

  calculateFee() {
    if (!this.form.valid) {
      return;
    }
    let fixedFeeFA = this.getFA('FIXED_FEE');
    let variableFeeFA = this.getFA('VARIABLE_FEE');
    let outboundFeeFA = this.getFA('OUTBOUND_FEE');

    let amount = this.calculatorAmount.value ? parseFloat(this.calculatorAmount.value) : 0;
    let fixedFee = fixedFeeFA.controls.length == 0 ? parseFloat(this.form.get('fixedFee').value) : 0
    let variableFee = variableFeeFA.controls.length == 0 ? parseFloat(this.form.get('variableFee').value) : 0
    this.feeFixedIdx = null;
    fixedFeeFA.controls.every((fg: UntypedFormGroup, idx) => {
      if (amount < fg.get('toAmount').value) {
        fixedFee = parseFloat(fg.get('feeAmount').value)
        this.feeFixedIdx = idx;
        return false;
      } else {
        return true;
      }
    });
    this.feeVariableIdx = null
    variableFeeFA.controls.every((fg: UntypedFormGroup, idx) => {
      if (amount < fg.get('toAmount').value) {
        variableFee = parseFloat(fg.get('feeAmount').value)
        this.feeVariableIdx = idx;
        return false;
      } else {
        return true;
      }
    });

    this.feeOutboundIdx = null
    outboundFeeFA.controls.every((fg: UntypedFormGroup, idx) => {
      if (amount < fg.get('toAmount').value) {
        this.feeOutboundIdx = idx;
        return false;
      } else {
        return true;
      }
    });


    let v = variableFee > 0 ? (amount * variableFee / 100) : 0;
    let f = fixedFee;
    return {
      total: v + f, variableFee: v, fixedFee: f
    };
  }

  newCountry(ev: Event) {
    ev.stopPropagation();
    const dialogRef2 = this.dialog.open(CountryItemComponent, {
      height: 'auto',
      width: '720px',
      data: false
    });
  }

  createCurrency(ev: Event) {
    ev.stopPropagation();
    this.dialog.open(CurrencyItemComponent, {
      height: 'auto',
      width: '400px',
      data: false
    });
  }


  ngOnDestroy(): void {
    this.$countrySenders?.unsubscribe();
    this.$countryReceivers?.unsubscribe();
    this.$item?.unsubscribe();
    this.$paymentNetworks?.unsubscribe();
    this.dialogRef?.close();
  }
  get minAmount() {
    return this.form.get('minAmount').value ? parseFloat(this.form.get('minAmount').value) : 0;
  }
  get maxAmount() {
    return this.form.get('maxAmount').value ? parseFloat(this.form.get('maxAmount').value) : 0;
  }

  get currency() {
    var f = this.countrySenders.filter(country => country.id == this.form.get('fromId').value);
    return f.length == 1 ? f[0].currency : ""
  }
  get checkCurrency() {
    return this.form.get("checkCurrency").value
  }
  isRangeCovered(ar) {

    if (ar.length == 0)
      return false;
    var lastToAmount = ar[ar.length - 1].toAmount;
    if (lastToAmount && parseFloat(lastToAmount) > this.maxAmount)
      return false;
    else
      return true

  }

  isSubmitDisabled() {
    if (!this.auth.hasPermission(this.auth.perm.CorridorsManage))
      return true;
    var response = false;

    if (this.form.invalid)
      response = true;
    // check ranges

    if (this.currency == this.checkCurrency) {
      var checkSections = ['VARIABLE_FEE', 'FIXED_FEE', 'OUTBOUND_FEE'];
      checkSections.forEach(section => {
        var fa = this.getFA(section);
        if (fa.controls.length) {
          var fc = fa.at(fa.controls.length - 1).get('toAmount');
          if (fc.errors) {
            response = true;
          }
          if (fc.value && parseFloat(fc.value) <= this.maxAmount) {
            response = true;
          }
        }
      });
    }


    return response;
  }
  openCalculator() {
    this.calculatorOpen = true;
    this.dialogRef = this.dialog.open(this.calcDialog, {
      hasBackdrop: false,
      width: '260px',
      height: 'auto',
      position: { bottom: '20px', right: '20px' }
    });
    var $dialog = this.dialogRef.afterClosed().subscribe((result: any) => {
      this.calculatorOpen = false;
      $dialog.unsubscribe();
    });
  }
}
