import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnduserProfileComponent } from './enduser-profile.component';
import { EnduserEditComponent } from './enduser-edit/enduser-edit.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { DocumentsModule } from 'src/app/components/documents/documents.module';
import { FilesModule } from 'src/app/components/files/files.module';
import { FormConfigsModule } from 'src/app/components/form-configs/form-configs.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    EnduserProfileComponent,
    EnduserEditComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    TranslateModule.forChild(),
    DocumentsModule,
    FilesModule,
    FormConfigsModule,
    RouterModule
  ],
  exports: [
    EnduserProfileComponent,
    EnduserEditComponent,
  ]
})
export class EnduserProfileModule { }
