<app-hero></app-hero>

<div class="send-container row">
  <app-single-select #countryFromm [items]="countriesFrom"
    (selectionChange)="fromCountrySelected($event)" placeholder="{{ 'Send money from' | translate }}">
  </app-single-select>

  <app-single-select #countryTo [items]="countriesTo"
    placeholder="{{ 'Send money to' | translate }}">
  </app-single-select>
</div>

<div class="container">
  <h2>{{ 'page.home.front_page_title' | translate }}</h2>
</div>

<div class="box-container">
  <div class="box" *ngFor="let fus of fusion">
    <img width="100px" src="{{ fus.img }}" />
    <h3>{{ fus.title | translate }}</h3>
    <p>
      {{ fus.content | translate }}
    </p>
  </div>
</div>

<section class="heading" style="background-image: url('/assets/images/HeroCountryFlags/banner.jpg')">
  <div class="heading2">
    <div class="heading3">
      <h2>{{ 'page.home.heading_title' | translate }}</h2>
      <p>
        {{ 'page.home.heading_content1' | translate }}
      </p>
      <br />
      <p>
        {{ 'page.home.heading_content2' | translate }}
      </p>
    </div>
  </div>
</section>

<div class="container">
  <h2>{{ 'page.home.front_page_title2' | translate }}</h2>
</div>

<div class="box-container2">
  <div class="box2" *ngFor="let mo of more">
    <img width="100px" src="{{ mo.img }}" />
    <h3>{{ mo.title | translate}}</h3>
    <p>
      {{ mo.content | translate}}
    </p>
  </div>
</div>

<hr class="horizontal-line" />

<div class="container" style="margin-bottom: 50px">
  <h3 class="faq-title">{{ 'page.home.faq_title' | translate }}</h3>
  <mat-accordion>
    <mat-expansion-panel class="accordion" *ngFor="let item of items">
      <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto">
        <mat-panel-title style="padding: 2% 0% 2% 0%">
          {{ item.title | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>{{ item.content | translate }}</p>
    </mat-expansion-panel>
  </mat-accordion>
</div>