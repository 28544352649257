import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiService } from 'src/app/_services/ui.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AdminLanguagesService } from 'src/app/_services/admin/admin-languages.service';
import { CustomValidators } from 'src/app/_helpers/custom.validators';

@Component({
  selector: 'app-language-item',
  templateUrl: './language-item.component.html',
  styleUrls: ['./language-item.component.scss']
})
export class LanguageItemComponent implements OnInit {
  form = this.fb.group({
    id: [this.data.id],
    name: [this.data.name, [Validators.required, CustomValidators.noWhitespaceValidator()]],
    isoCode2: [this.data.isoCode2, [Validators.required, Validators.minLength(2), Validators.maxLength(2), CustomValidators.noWhitespaceValidator()]],
    isoCode3: [this.data.isoCode3, [Validators.required, Validators.minLength(3), Validators.maxLength(3), CustomValidators.noWhitespaceValidator()]]
  });

  constructor(private ui: UiService,
    private service: AdminLanguagesService,
    private dialogRef: MatDialogRef<LanguageItemComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  submitForm() {
    if (this.form.valid) {
      var d = this.form.value;
      var s = !d.id ? this.service.create(d) : s = this.service.modify(d);
      var k = s.subscribe({
        next: x => {
          this.ui.snack('Language ' + (d.id ? 'saved!' : 'created!'));
          k?.unsubscribe()
          this.dialogRef.close();
        }
      });
    }
  }
}
