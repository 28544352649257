import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-fee-rating',
  templateUrl: './fee-rating.component.html',
  styleUrls: ['./fee-rating.component.scss']
})
export class FeeRatingComponent implements OnInit {
  @Input() fees: any;
  @Input() feeType: string;
  @Input() maxCorridor: number;
  error = null;
  manager = false;

  constructor(
    public auth: AuthenticationService,
  ) {
    if (!this.auth.hasPermission(this.auth.perm.CorridorsManage)) {
      this.manager = true;
    }
  }

  feeValue: any = [
    {
      type: 'PERCENT'
    },
    {
      type: 'VALUE'
    },
  ];

  ngOnInit() {
    if (this.fees.length === 0) {
      this.fees.push({
        toAmount: '',
        feeAmount: '',
        feeTypeCode: this.feeType,
        feeValueType: ''
      });
    }
    this.fees = this.fees.sort((a, b) => a.toAmount > b.toAmount);
  }

  addNewRow(amount: number, percentage: number, selected: any) {
    const feeTypeCode = this.feeType;
    this.fees.push({
      feeTypeCode,
      feeAmount: percentage,
      toAmount: amount,
      feeValueType: selected
    });
  }

  deleteRow() {
    this.fees.pop();
  }

  isValid() {
    if (!this.fees) {
      this.error = 'Missing elements on the range - please restart application.';
      return false;
    }

    // if (this.fees.length === 0) {
    //   this.error =
    //     'Missing elements on the range - please restart application.';
    //   return false;
    // }

    if (this.fees.length !== this.fees.filter(x => x.toAmount > 0).length) {
      this.error = 'Amount needs to be positive number for all ranges.';
      return false;
    }

    if (this.fees.length !== this.fees.filter(x => x.feeAmount > 0).length) {
      this.error =
        'Percentage needs to be set to positive number for all ranges.';
      return false;
    }

    let valid = true;
    let prevVal = 0;
    this.fees.forEach((el, index) => {
      if (index + 1 === this.fees.length) {
        if (el.toAmount < this.maxCorridor) {
          this.error = `Latest amount needs to be higher than the corridor maximum limit ${this.maxCorridor}.`;
          valid = false;
          return;
        }
      }

      if (index > 0 && el.toAmount <= prevVal) {
        this.error =
          'Every next amount needs to be higher than the previous one.';
        valid = false;
      }
      prevVal = el.toAmount;
    });

    if (valid) {
      this.error = null;
      return true;
    }

    return false;
  }
}
