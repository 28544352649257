import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, ValidationErrors, Validator, Validators } from '@angular/forms';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
  providers: [{ provide: NG_VALIDATORS, useExisting: PasswordStrengthComponent, multi: true }]
})
export class PasswordStrengthComponent implements OnInit, AfterContentInit, OnDestroy {
  value1: number = 0;
  $unsub: any;

  @Input('minLength') minLength: number = 6;
  @Input('maxLength') maxLength: number = 30;
  @Input('hasNumber') hasNumber: boolean = false;
  @Input('hasUpper') hasUpper: boolean = false;
  @Input('hasLower') hasLower: boolean = false;
  @Input('hasNonAlphabetic') hasNonAlphabetic: boolean = false;
  @Input('userPassword') userPassword!: AbstractControl | null;
  @Input('infoMessage') infoMessage: string = "";

  userRequirements: { [key: string]: boolean }[] = [];
  @Output() onStrengthChanged: EventEmitter<number> = new EventEmitter();


  map: any;
  constructor() { }

  ngOnInit(): void {
    this.setValidators();
    this.updateStrengthProgress();
  }

  ngAfterContentInit(): void {
    this.setValidators();
    this.updateStrengthProgress();
  }

  ngOnDestroy(): void {
    this.$unsub?.unsubscribe();
  }

  private setValidators(): void {
    this.map = new Map();
    this.userPassword.addValidators([Validators.minLength(this.minLength), Validators.maxLength(this.maxLength)]);

    if (this.hasNumber) {
      this.map.set('hasNumber', false);
      this.userPassword.addValidators([Validators.pattern(".*[0-9].*")]);
    }
    if (this.hasUpper) {
      this.map.set('hasUpper', false);
      this.userPassword.addValidators([Validators.pattern(".*[A-Z].*")]);
    }
    if (this.hasLower) {
      this.map.set('hasLower', false);
      this.userPassword.addValidators([Validators.pattern(".*[a-z].*")]);
    }

    if (this.hasNonAlphabetic) {
      this.map.set('hasNonAlphabetic', false);
      this.userPassword.addValidators([Validators.pattern(".*[^a-zA-Z0-9].*")]);
    }

    this.map.set('hasMinLength', false);
    this.map.set('hasMaxLength', false);
    this.userPassword.updateValueAndValidity();
  }

  updateStrengthProgress() {
    this.$unsub = this.userPassword.valueChanges.subscribe((e: any) => {
      this.value1 = 0;

      if (this.hasNumber) {
        this.map.set('hasNumber', /[\d]/.test(e));
      }
      if (this.hasUpper) {
        this.map.set('hasUpper', /[A-Z]/.test(e));
      }
      if (this.hasLower) {
        this.map.set('hasLower', /[a-z]/.test(e));
      }

      if (this.hasNonAlphabetic) {
        this.map.set('hasNonAlphabetic', /[\W_]/.test(e))
      }
      this.map.set('hasMinLength', e?.length >= this.minLength);
      this.map.set('hasMaxLength', e?.length <= this.maxLength);

      this.map.forEach(e => {
        if (e)
          this.value1 += 100 / this.map.size;
      });
      if (!e?.length)
        this.value1 = 0;
      this.onStrengthChanged.emit(this.value1);
    },
      err => console.log("ERROR", err))
  }

}
