import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiService } from 'src/app/_services/ui.service';
import { UserTransactionsService } from 'src/app/_services/user/transactions.service';

@Component({
  selector: 'app-cancel-transaction',
  templateUrl: './cancel-transaction.component.html',
  styleUrls: ['./cancel-transaction.component.scss']
})
export class CancelTransactionComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public paymentService: UserTransactionsService,
    public ui: UiService,
  ) { }

  ngOnInit() {
  }
  cancelTransaction() {
    this.paymentService.cancelTransaction(this.data.id);
  }

}
