import { Component } from '@angular/core';
import { EndUserService } from 'src/app/_services/user/enduser.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent {
  environment = environment;
  onboarding$: any;
  constructor(
    public service: EndUserService
  ) {
    this.onboarding$ = service.fetchOnboarding();
  }
}
