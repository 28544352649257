import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { UiService } from 'src/app/_services/ui.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PublicService } from 'src/app/_services/public.service';
import { EndUserService } from 'src/app/_services/user/enduser.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterComponent implements OnInit {

  shouldRecordConsent = false;
  authMethod: string = AuthenticationService.AUTHMETHID_BASIC;
  environment = environment;
  disabled = false;
  fromType = "user"
  isLoaded: boolean = false;
  form: any;
  constructor(
    private userService: EndUserService,
    private ui: UiService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private auth: AuthenticationService,
    private publicService: PublicService,
    private snack: MatSnackBar
  ) { }

  ngOnInit() {
  }

  register(user): void {
    this.form = { user }
    this.disabled = true;
    this.userService.registerEndUser(this.form).subscribe(
      ({ data }: any) => {
        this.shouldRecordConsent = true;
        this.login({
          username: user.email,
          password: user.password,
          authMethod: user.authMethod,
        });
        this.ui.snack('You have been successfully registered');
      },
      error => {
        this.ui.snack(error.message);
        this.disabled = false;
        if (error.message === 'GraphQL error: User already exists.') {
          this.ui.snack(
            'User with this username already exists, did you meen to login?'
          );
        } else {
          this.ui.snack(error.message);
        }
      }
    );
  }

  login(credentials: any = null): void {
    if (credentials.authMethod === AuthenticationService.AUTHMETHID_BASIC) {
      var s = this.auth.login(credentials).subscribe({
        next: (data: any) => {
          if (data && data?.authToken) {
            this.auth.storeUser(data);
            if (!this.shouldRecordConsent)
              this.router.navigate(['/platform']);
            else
              this.userService.addConsent('REGISTER', null).then(() => { this.router.navigate(['/platform']); })
          }
          s?.unsubscribe();
        },
        error: e => {
          this.ui.snack(e.message);
          s?.unsubscribe();
        }
      });

      return;
    } else {
      window.location.href =
        environment.bankIdRedirectUrl + credentials.username;
    }
  }
  checkIfConfigIsLoaded() {
    this.isLoaded = true;
  }
}
