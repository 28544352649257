<app-hero [country]="currentCountryTo.name"></app-hero>
<div class="io-row">
  <div class="position">
    <h3>{{ 'page.countries.instructions_title1' | translate:{country: currentCountryTo?.name} }}</h3>
    <p>
      <br />{{ 'page.countries.instructions_content1' | translate:{country: currentCountryTo?.name} }}
      <br /><br />{{ 'page.countries.instructions_content2' | translate }} <br />{{
      'page.countries.instructions_content3' | translate }}
      <br />{{ 'page.countries.instructions_content4' | translate }}
    </p>
    <ul>
      <li>{{ 'page.countries.instructions_content5' | translate:{country: currentCountryTo?.name} }}</li>
    </ul>
    <p>
      {{ 'page.countries.instructions_content6' | translate:{country: currentCountryTo?.name} }}
    </p>
    <h3>{{ 'page.countries.instructions_title2' | translate:{country: currentCountryTo?.name} }}</h3>

    <ul>
      <li>
        {{ 'page.countries.instructions_content7' | translate:{country: currentCountryTo?.name} }}
      </li>
      <li>
        {{ 'page.countries.instructions_content8' | translate }}
      </li>
      <li>
        {{ 'page.countries.instructions_content9' | translate }}
      </li>
    </ul>
  </div>

  <!--Changing flags-->
  <app-calculator (countryToChange)="changedCountryTo($event)"></app-calculator>
</div>

<section class="heading" style="background-image: url('/assets/images/HeroCountryFlags/banner.jpg')">
  <div class="heading2">
    <div class="heading3">
      <h2>{{'page.countries.heading_title' | translate }}</h2>
      <p>
        {{ 'page.countries.heading_content1' | translate }}
      </p>
      <br />
      <p>
        {{ 'page.countries.heading_content2' | translate }}
      </p>
    </div>
  </div>
</section>

<div class="container">
  <h2>{{ 'page.countries.features_main_title' | translate }}</h2>
</div>

<div class="box-container2">
  <div class="box2" *ngFor="let mo of more">
    <img width="100px" src="{{ mo.img }}" />
    <h3>{{ mo.title | translate }}</h3>
    <p>
      {{ mo.content | translate }}
    </p>
  </div>
</div>