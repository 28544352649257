<div class="main-content" *ngIf="userDeleted">
  <strong>{{ 'User deleted' | translate }}</strong>
</div>
<div class="io-row-l io-mx-2 io-my-2" *ngIf="!userDeleted">
  <div class="flex-3">
    <mat-card class="io-mb-2">
      <mat-card-title>{{ 'About' | translate }}</mat-card-title>
      <mat-card-content>
        <form-data-display-configuration [columns]="3" fieldsGroup="updateUser" [country]="user?.country?.isoCode2"
          [data]="user">
        </form-data-display-configuration>
      </mat-card-content>
    </mat-card>
    <div class="io-mb-2">
      <mat-expansion-panel *ngIf="(questionnaire$|async)?.customProperties as questionnaire">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'Questionnaire' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <table class="table-details" *ngIf="questionnaire?.length == 0">
          <tbody>
            <tr>
              <th>Questionnaire not filled up yet.</th>
            </tr>
          </tbody>
        </table>
        <ng-container *ngIf="questionnaire?.length > 0">
        <table class="table-details" *ngIf="(questionnaireLabels$ | async) as questionnaireLabels">
          <tbody *ngIf="questionnaireLabels?.length > 0">
            <tr *ngFor="let cp of questionnaireLabels[0]?.fields; let i = index">
              <th style="width: 45%;">{{ cp?.label | translate }}</th>
              <td>
                <span>
                  {{(questionnaire | filterOn: 'key' : cp?.name)[0]?.value | toArray | arrayFormatter }}
                </span>
                <span *ngIf="cp?.name === 'times' || cp?.name === 'send'">
                  {{
                    (cp?.name === 'times' ? (questionnaire | filterOn: 'key': ['minTimes'])[0]?.value 
                    : (questionnaire | filterOn: 'key': ['minValue'])[0]?.value) || '<'
                  }}
                  {{
                    (questionnaire | filterOn: 'key': ['minTimes'])[0]?.value && 
                    (questionnaire | filterOn: 'key': ['maxTimes'])[0]?.value && cp?.name === 'times' ? '-' :
                    (questionnaire | filterOn: 'key': ['minValue'])[0]?.value && 
                    (questionnaire | filterOn: 'key': ['maxValue'])[0]?.value && cp?.name === 'send' ? '-' : ''
                  }}
                  {{
                    (cp?.name === 'times' ? (questionnaire | filterOn: 'key': ['maxTimes'])[0]?.value 
                    : (questionnaire | filterOn: 'key': ['maxValue'])[0]?.value) || '+'
                  }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      </mat-expansion-panel>
    </div>
  </div>
  <!-- <div *ngFor="let cp of (publicService.getFieldsConfig(user?.country?.isoCode2, 'questionnaire') | async)[0]?.fields">
    {{cp?.label}}
  </div> -->
  <div class="flex" style="max-width: 380px;">
    <div class="io-mb-2">
      <mat-expansion-panel *ngIf="auth.hasPermission(auth.perm.DocumentsRead)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'Documents' | translate }}
            (<span *ngIf="(documents$|async) as _docs;else documentsNo">{{
              _docs.length
              }}</span><ng-template #documentsNo>0</ng-template>)
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-document-list *ngIf="user" [userId]="user?.id" [documents]="(documents$|async)" [country]="user?.country">
        </app-document-list>
        <mat-action-row *ngIf="auth.hasPermission(auth.perm.DocumentsManage)">
          <button mat-stroked-button (click)="ms.uploadDocument(user.id, null, null, null, country);">{{'Add
            document'|
            translate}}</button>
        </mat-action-row>
      </mat-expansion-panel>
    </div>
    <div class="io-mb-2">
      <mat-expansion-panel *ngIf="auth.hasPermission(auth.perm.DocumentsRead)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'Files' | translate }}
            ({{ (files$|async)?.length || 0 }})
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-files-list [userId]="userId" [files]="files$|async"></app-files-list>
        <mat-action-row *ngIf="auth.hasPermission(auth.perm.FilesManage)">
          <button mat-stroked-button (click)="ms.uploadFile(userId);">{{'Add file'|
            translate}}</button>
        </mat-action-row>
      </mat-expansion-panel>
    </div>
  </div>
</div>