<!-- <pre>{{onboarding|json}}</pre> -->
<div *ngIf="onboarding && onboarding.visible" class="onbrd ">
  <div *ngFor="let step of onboarding.steps; index as idx"
    [ngClass]="{ 'completed':  step.active, 'step1': idx==0, 'step2': idx==1, 'step3': idx==2, 'steps': true }">
    <svg width="290" height="290" viewBox="0 0 290 290" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M116.758 7.04365C148.643 0.333947 181.865 4.79342 210.851 19.674C239.838 34.5545 262.825 58.9508 275.957 88.7702C275.971 88.8029 275.986 88.835 276.002 88.8666L270.769 88.4005L283.334 104.482L282.399 83.2306L279.553 87.0111C266.04 56.4185 242.433 31.3907 212.678 16.1155C182.875 0.815761 148.717 -3.76933 115.935 3.12939C83.1519 10.0281 53.7389 27.9909 32.6299 54.0044C11.5209 80.0179 2.98023e-06 112.5 0 146C0 147.105 0.89543 148 2 148C3.10457 148 4 147.105 4 146C4 113.417 15.2052 81.8257 35.7359 56.5249C56.2666 31.224 84.8738 13.7534 116.758 7.04365Z"
        fill="currentColor" />
    </svg>
    <div class="circ">
      <span class="num">0{{idx+1}}</span>
      <strong class="title">{{ step.title | translate }}</strong>
      <small class="desc">{{ step.description | translate }}</small>
      <button mat-raised-button class="button-recipient" (click)="runStepAction(step.action)">
        {{ step.actionLabel | translate }}
      </button>
    </div>
  </div>
</div>
<!-- class="mobile d-show d-l-none" -->


<div *ngIf="onboarding && onboarding.visible">
  <div class="carousel" #carouselEl>
    <div *ngFor="let step of onboarding.steps; index as idx"
      [ngClass]="{ 'completed':  step.active, 'step1': idx==0, 'step2': idx==1, 'step3': idx==2, 'carousel-item': true , 'steps': true}">
      <div class="circ">
        <span class="num">0{{idx+1}}</span>
        <strong class="title">{{ step.title | translate }}</strong>
        <small class="desc">{{ step.description | translate }}</small>
        <button mat-raised-button class="button-recipient" (click)="runStepAction(step.action)">
          {{ step.actionLabel | translate }}
        </button>
      </div>
    </div>

  </div>
</div>