<div class="chat">
    <mat-card style="padding-bottom: 0; padding-top: 0;">
        <div *ngIf="(items$|async) as messages">
            <div *ngIf="messages.length < 10 || showAll; else elseTpl">
                <app-message-row-item *ngFor="let item of messages" [item]="item"></app-message-row-item>
            </div>
            <ng-template #elseTpl>
                <app-message-row-item *ngFor="let item of messages.slice(0, 1)" [item]="item"></app-message-row-item>
                <button class="more-button" (click)="showAll=true">
                    <span>Show {{messages.length - 3}} more</span>
                </button>

                <app-message-row-item *ngFor="let item of messages.slice(messages.length-2)"
                    [item]="item"></app-message-row-item>
            </ng-template>
            <div *ngIf="messages.length == 0" class="no-messages">
                <div class="io-py-3">No messages yet.</div>
            </div>
        </div>

        <div class="no-flex">
            <div class="io-row width-100 align-items-center">
                <div>
                    <mat-form-field class="width-100">
                        <textarea matInput id="message" type="text" (keydown.enter)="sendMessage()"
                            placeholder="Send Message" [(ngModel)]="messageText" [disabled]="sendingMessage"
                            matTextareaAutosize matAutosizeMinRows="1" matAutosizeMaxRows="5"></textarea>
                        <div matSuffix>
                            <button *ngIf="!filesUploaded.length" type="button" mat-icon-button color="primary"
                                (click)="uploader.click()" [disabled]="sendingMessage" style="margin-right: 4px;">
                                <mat-icon>attachment</mat-icon>
                            </button>
                            <button matSuffix mat-flat-button color="primary" class="send-button"
                                (click)="sendMessage()" [disabled]="isDisabled() ">Send</button>
                            <input hidden type="file" #uploader (change)="handleFileInput($event.target.files)" />
                        </div>

                        <div *ngIf="filesUploaded.length" class="uploaded-files">
                            <button *ngFor="let file of filesUploaded" class="no-flex" mat-flat-button color="secondary"
                                (click)="deleteFile(file.id)">
                                <span>{{ file.name }}</span>
                                <mat-icon color="warn">close</mat-icon>
                            </button>
                        </div>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </mat-card>
</div>