<list-wrapper #wrapper [mainService]="service" panelTitle="Currencies" [managePerm]="auth.perm.CompanyManage"
   [readPerm]="auth.perm.CompanyManage" (onNew)="newItem()" (onEdit)="editItem($event)">
   <mat-action-list [multiple]="false">
      <mat-list-item *ngFor=" let item of items$|async; last as last;" (click)="wrapper.edit(item)">
         <mat-icon mat-list-icon>euro_symbol</mat-icon>
         <h4 mat-line>{{ item?.isoCode }}</h4>
         <p matLine>FxSpread: <strong>{{ item.fxSpread }}</strong></p>
         <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
      </mat-list-item>
   </mat-action-list>
</list-wrapper>