import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { UiService } from 'src/app/_services/ui.service';
import { EndUserService } from 'src/app/_services/user/enduser.service.js';

import * as M from '../../../../assets/materialize/js/materialize.js';

@Component({
  selector: 'app-onboarding-stepped',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent {
  //onboarding component should be updated after recipient added, after transaction added, after kyc is completed
  @Input() onboarding: any;
  onboardingType: any = 'stepped';
  options: any = {};
  onboardingService$: Subscription;
  filterFunction(t): boolean {
    return !t.active;
  }
  constructor(
    // private service: EndUserService,
    public modalLauncherService: ModalLauncherService,
    private router: Router,
    public ui: UiService
  ) {

  }

  runStepAction(stepAction) {
    if (stepAction === "CreateRecipient")
      this.modalLauncherService.newRecipient();

    else if (stepAction == "CreateTransaction")
      this.modalLauncherService.sendMoney();

    else if (stepAction == "Verification")
      this.router.navigate(['/', 'platform', 'kyc']);

    else if (stepAction == "ViewTransactions")
      this.router.navigate(['/', 'platform', 'transactions']);
  }
}
