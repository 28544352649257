import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiService } from 'src/app/_services/ui.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { CurrencyItemComponent } from '../../currency/currency-item/currency-item.component';
import { RegionItemComponent } from '../../regions/region-item/region-item.component';
import { AdminCountriesService } from 'src/app/_services/admin/admin-countries.service';
import { AdminRegionsService } from 'src/app/_services/admin/admin-regions.service';
import { AdminCurrenciesService } from 'src/app/_services/admin/admin-currencies.service';

@Component({
  selector: 'app-country-item',
  templateUrl: './country-item.component.html',
  styleUrls: ['./country-item.component.scss']
})
export class CountryItemComponent implements OnInit {

  form: UntypedFormGroup;

  regions: any;
  currencies: any;
  country: string;
  countryAdd: any = {};

  constructor(
    private ui: UiService,
    public service: AdminCountriesService,
    private regionsService: AdminRegionsService,
    public auth: AuthenticationService,
    private dialogRef: MatDialogRef<CountryItemComponent>,
    private fb: UntypedFormBuilder,
    private currenciesService: AdminCurrenciesService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    var disabled = false;
    this.form = this.fb.group({
      id: [this.data.id],
      name: [{ value: this.data.name, disabled: true }, Validators.required],
      isoCode2: [{ value: this.data.isoCode2, disabled: true }, [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      isoCode3: [{ value: this.data.isoCode3, disabled: true }, [Validators.required, Validators.minLength(3), Validators.maxLength(3)]],
      isActive: [this.data.isActive || false, Validators.required],
      isSender: [{ value: this.data.isSender || false, disabled: disabled }, Validators.required],
      isRecipient: [{ value: this.data.isRecipient || false, disabled: disabled }, Validators.required],
      phonePrefix: [{ value: this.data.phonePrefix, disabled: disabled }, Validators.required],
      currency: [{ value: this.data.currency, disabled: disabled }, Validators.required],
      regionId: [
        {
          value: (this.data.region ? this.data.region.id : null), disabled: disabled
        },
        Validators.required
      ],
      // maxAmount: [this.data.maxAmount || 10000, Validators.required]
    });



    if (!this.auth.hasPermission(auth.perm.CountriesManage)) {
      this.form.disable();
    }



    this.regions = this.regionsService.dropdownAll();
    this.currencies = this.currenciesService.dropdownAll();
    this.country = data.isoCode3;
  }


  ngOnInit() { }

  onSubmit() {
    if (this.form.valid) {
      var d = Object.assign({}, this.form.getRawValue());
      var s = !d.id ? this.service.create(d) : s = this.service.modify(d);
      var k = s.subscribe({
        next: x => {
          this.ui.snack('Country ' + (d.id ? 'saved!' : 'created!'));
          k?.unsubscribe()
          this.dialogRef.close();
        },
      });
    }
  }











  createNewCurrency() {
    this.dialog.open(CurrencyItemComponent, {
      height: 'auto',
      width: '800px',
      data: {}
    });
  }

  createCurrency(ev: Event) {
    ev.stopPropagation();
    this.dialog.open(CurrencyItemComponent, {
      height: 'auto',
      width: '360px',
      disableClose: true,
      data: false
    });
  }

  createRegion(ev: Event) {
    ev.stopPropagation();
    this.dialog.open(RegionItemComponent, {
      height: 'auto',
      width: '360px',
      disableClose: true,
      data: false
    });
  }
}
