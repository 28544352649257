<div mat-dialog-title>
  <span>{{ 'Send Money' | translate}}</span>
  <button mat-icon-button mat-dialog-close class="close">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <mat-progress-bar *ngIf="ui.loading" mode="query" id="progress-bar"></mat-progress-bar>
</div>

<div class="calculator-enduser">
  <mat-vertical-stepper #stepper>

    <mat-step [completed]="false">
      <ng-template matStepLabel>{{ 'Choose Recipient' | translate }}</ng-template>
      <div>
        <app-recipients-selector [mode]='chooseBox' (selectionChange)="setRecipient($event)">
        </app-recipients-selector>
      </div>
    </mat-step>

    <mat-step [completed]="false">
      <ng-template matStepLabel>{{ data ? 'Send Money to' : '' | translate }} <a class="recipient-name">{{
          data?.recipient.firstName || data?.transaction?.recipientFirstName }}</a></ng-template>
      <app-calculator *ngIf="mode == 'calculator'" (modeEvent)="receiveMode($event)"
        (calculatorData)="currentCalculatorData($event)" (currency)="currencies($event)"
        (transactionID)="transactionID($event)"></app-calculator>
    </mat-step>

    <mat-step [completed]="false">
      <ng-template matStepLabel>{{ 'Choose Payment Method' | translate }}</ng-template>
      <div *ngIf="mode == 'inbounds' && inbounds?.length" class="inbound-content">
        <div *ngFor="let inbound of inbounds">

          <button mat-raised-button class="inbound-button"
            *ngIf="inbound?.paymentMethods == null || inbound?.paymentMethods.length==1"
            (click)="initiatePayment(inbound.code, inbound?.paymentMethods ? inbound?.paymentMethods[0].id : null)"
            [disabled]="disabled">
            <img src="/assets/images/{{userTransactionsService.pictureName(inbound.code)}}.svg" height="46px"
              *ngIf="inbound.code != 'MOOV'">
            <ng-template [ngTemplateOutlet]="tplMoov"
              [ngTemplateOutletContext]="{paymentMethod: inbound?.paymentMethods[0]}"
              *ngIf="inbound?.paymentMethods && inbound.code == 'MOOV'"></ng-template>
          </button>

          <!-- Multiple payment methods by moov  -->
          <div *ngIf="inbound?.paymentMethods && inbound?.paymentMethods?.length!=1 && inbound.code == 'MOOV'">
            <button mat-raised-button class="inbound-button" *ngFor="let paymentMethod of inbound.paymentMethods"
              (click)="initiatePayment(inbound.code, paymentMethod.id)" [disabled]="disabled">

              <ng-template [ngTemplateOutlet]="tplMoov"
                [ngTemplateOutletContext]="{paymentMethod: paymentMethod}"></ng-template>
            </button>
          </div>
          <mat-card class="inbound-card"
            *ngIf="inbound?.paymentMethods && inbound?.paymentMethods?.length!=1 && inbound.code != 'MOOV'">
            <div style="text-align: center;">
              <img src="/assets/images/{{userTransactionsService.pictureName(inbound.code)}}.svg" height="46px">
            </div>


            <button mat-button
              style="width:100%; display: flex; flex-direction: column; line-height: 12px; align-items: center; padding: 8px 16px; margin-top: 8px;"
              [disabled]="disabled" *ngIf="inbound?.paymentMethods.length === 0" (click)="navigateToPaymentMethods()">
              {{inbound?.code === 'MOOV' ? ('Add Payment Method' | translate)
              : ('Add Agreement' | translate)}}
            </button>
            <button mat-button *ngFor="let paymentMethod of inbound.paymentMethods"
              style="width:100%; display: flex; flex-direction: column; line-height: 12px; align-items: center; padding: 8px 16px; margin-top: 8px;"
              (click)="initiatePayment(inbound.code, paymentMethod.id)" [disabled]="disabled">
              <strong style="max-width: 200px; overflow: hidden; text-overflow: ellipsis; display: block;">
                {{paymentMethod.account.name}}
              </strong>
              <small style="font-weight: normal;">
                <span *ngIf="paymentMethod.account.lastFourNumbers">
                  <span style="opacity: 0.5;">XX-XXXX-</span>{{paymentMethod.account.lastFourNumbers}}
                </span>
                <span *ngIf="!paymentMethod.account.lastFourNumbers">{{paymentMethod.account.accountNumber}}</span>
              </small>

            </button>
          </mat-card>
        </div>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>{{ 'Payment' | translate }}</ng-template>

      <div *ngIf="mode == 'inbound'" class="poli">
        <iframe #iframe [src]="iFrameUrl|safe: 'resourceUrl'" (load)="loadingIframe()"
          style="height:inherit;width:100%;margin:0;padding:0;min-height: 500px;border:none;" name="poliframe"
          id="poliframe"></iframe>
      </div>

      <div *ngIf="mode == 'success'" class="recipient-data">
        <mat-dialog-content>
          <mat-icon class="icon transaction-successfull">check_circle</mat-icon>
          <h4 class="info-description">{{ 'Transaction is successful.' | translate }}</h4>
        </mat-dialog-content>
        <mat-dialog-actions>
          <button class="info-actions" mat-raised-button color="primary" mat-dialog-close>{{ 'OK' | translate
            }}</button>
        </mat-dialog-actions>
      </div>

      <div *ngIf="mode == 'fail'" class="recipient-data">
        <mat-dialog-content>
          <mat-icon class="icon transaction-faild">report</mat-icon>
          <h4 class="info-description">{{ 'Transaction failed.' | translate }}</h4>
          <p style="font-size:14px;">
            {{ transaction?.inboundName }} {{ ' could not verify the overall status of your payment attempt. Please
            check your Internet Banking now to see if you were still charged for this attempt:' | translate }}
          </p>
          <ul style="font-size:14px;">
            <li>{{ 'If you DO see a deduction of funds from your account, please send a screenshot of this bank
              statement to our customer support team, who can help credit you for your purchase' | translate }}</li>
            <li>{{ 'If you DO NOT see a deduction of funds from your account, please retry another payment attempt' |
              translate }}</li>
          </ul>

        </mat-dialog-content>
        <mat-dialog-actions>
          <button class="info-actions" mat-raised-button color="primary" mat-dialog-close>{{ 'OK' | translate
            }}</button>
        </mat-dialog-actions>
      </div>

      <div *ngIf="mode == 'cancel'" class="recipient-data">
        <mat-dialog-content>
          <mat-icon class="icon transaction-canceled">cancel</mat-icon>
          <h4 class="info-description">{{ 'Transaction is canceled.' | translate }}</h4>
        </mat-dialog-content>
        <mat-dialog-actions>
          <button class="info-actions" mat-raised-button color="primary" mat-dialog-close>{{ 'OK' | translate
            }}</button>
        </mat-dialog-actions>
      </div>

      <div *ngIf="mode == 'flagOrTicket'" class="recipient-data">
        <mat-dialog-content>
          <mat-icon class="icon transaction-review">info</mat-icon>
          <h4 class="info-description" *ngIf="!KYCFlag">{{ 'Your transaction is under review' | translate }}</h4>
          <h4 class="info-description" *ngIf="KYCFlag">{{('FLAG_' + KYCFlag) + '_DESC' | translate }}</h4>
        </mat-dialog-content>
        <mat-dialog-actions>
          <button *ngIf="!KYCFlag" class="info-actions" mat-raised-button color="primary" mat-dialog-close>{{ 'OK' |
            translate }}</button>
          <button *ngIf="KYCFlag" class="info-actions" mat-raised-button color="primary" (click)="goToKYC()">{{ 'Go to
            Verification' | translate }}</button>
        </mat-dialog-actions>
      </div>

      <div *ngIf="mode == 'pending'" class="recipient-data">
        <mat-dialog-content>
          <mat-icon class="icon transaction-error">info</mat-icon>
          <h4 class="info-description">{{ errorMsg | translate }}</h4>
        </mat-dialog-content>
        <mat-dialog-actions>
          <button class="info-actions" mat-raised-button color="primary" mat-dialog-close>{{ 'OK' | translate
            }}</button>
        </mat-dialog-actions>
      </div>

      <div *ngIf="mode == 'informations'" class="recipient-data">
        <mat-dialog-content style="padding: 0">
          <div *ngIf="inboundType === 'MANUAL'">

            <p style="font-size: 18px;">
              {{ 'Transaction ' | translate }}
              <strong>
                {{ transaction?.transactionId }}
              </strong>
            </p>
          </div>

          <div *ngIf="inboundType === 'AZUPAY'">

            <p style="font-size: 18px;">
              {{ 'PayID ' | translate }}
              <strong>
                {{ transaction?.paymentMethodNumber }}
              </strong>
            </p>
          </div>

          <table class="index-table">
            <tbody>
              <tr>
                <th>{{ 'Recipient' | translate }}</th>
                <td>
                  {{ transaction?.recipientFirstName }} {{ transaction?.recipientLastName }}
                </td>
              </tr>
              <tr>
                <th></th>
                <td>
                  {{ transaction?.recipientCity }}
                </td>
              </tr>
              <tr>
                <th></th>
                <td>
                  {{ transaction?.recipientPhone }}
                </td>
              </tr>
              <tr>
                <th>{{ 'Transaction' | translate }}</th>
                <td>{{transaction?.transactionId}}</td>
              </tr>
              <tr>
                <th>{{ 'Payment Method' | translate }}</th>
                <td>
                  {{ transaction?.paymentMethodName }}
                  <br *ngIf="transaction?.paymentMethodNumber" />
                  {{ transaction?.paymentMethodNumber}}
                </td>
              </tr>
              <tr>
                <th>{{ 'Send Amount' | translate }}</th>
                <td>{{ transaction?.amount | number: '1.2-2'}}<a> {{transaction.currency}}</a></td>
              </tr>
              <tr>
                <th>{{ 'Fee' | translate }}</th>
                <td>{{ transaction?.fixedFeePaid + transaction?.variableFeePaid | number: '1.2-2'}}<a>
                    {{transaction.currency}}</a></td>
              </tr>
              <tr>
                <th>{{ 'Exchange Rate' | translate }}</th>
                <td><a>{{transaction.currency}} 1 = {{transaction.receivingCurrency }} </a>{{ transaction.quote |
                  number: '1.6-6' }}</td>
              </tr>
              <tr>
                <td colspan="2">
                  <div class="info-manual"></div>
                </td>
              <tr>
                <th>{{ 'Total to Pay' | translate }}</th>
                <td style="color: #011227; font-size: 20px;">{{ transaction.paidAmount | number: '1.2-2' }}<a>
                    {{transaction.currency}}</a></td>
              </tr>
              <tr>
                <th>{{ 'Recipient Receives' | translate }}</th>
                <td style="color: #011227; font-size: 14px;">{{ transaction.receivingAmount | number: '1.2-2' }}<a>
                    {{transaction.receivingCurrency}}</a></td>
              </tr>
              <tr *ngIf="transaction?.expectedPayoutTime">
                <th>{{ 'Estimated Date Available' | translate }}</th>
                <td>{{ transaction?.expectedPayoutTime | timezone | date }}</td>
              </tr>
              <tr>
                <th colspan="2" class="checkbox-text io-pt-2">
                  <small
                    [innerHTML]="'Recipient may receive less due to fees charged by the recipient\'s bank and foreign taxes.'|translate"></small>
                </th>
              </tr>
            </tbody>
          </table>

          <div *ngIf="inboundType === 'AZUPAY'" class="final-content">
            <div class="final-instructions">
              <p>{{ 'calculator.inbound.azupay.step1' | translate }}</p>
              <p>{{ 'calculator.inbound.azupay.step2' | translate }}</p>
              <p>{{ 'calculator.inbound.azupay.step3' | translate }}</p>
            </div>
          </div>
          <div *ngIf="inboundType === 'MANUAL'" class="final-content">
            <div class="final-instructions">
              <p [innerHTML]="'Go to our nearest office in order to complete the payment.'|translate"></p>
            </div>
          </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
          <button mat-raised-button color="primary" mat-dialog-close>{{ 'Close' | translate }}</button>
        </mat-dialog-actions>
      </div>
    </mat-step>
  </mat-vertical-stepper>
  <div class="io-row align-items-center info-note-message"
    *ngIf="($raisedFlags | async | filterOn : ['flag',  'code'] : ['DELETE_ACCOUNT_REQUESTED'])?.length">
    <mat-icon class="no-flex" color="warn">info</mat-icon>
    <p>
      {{ 'Account deletion is in the process. You will not be able to create transaction.' |translate }}
    </p>
  </div>
</div>


<ng-template #tplMoov let-paymentMethod="paymentMethod">
  <strong style="max-width: 200px; overflow: hidden; text-overflow: ellipsis; display: block; margin: 0 auto;">
    {{paymentMethod.account.name}}
  </strong>
  <small style="font-weight: normal; margin: 0 auto;">
    <span *ngIf="paymentMethod.account.lastFourNumbers">
      <span style="opacity: 0.5;">XX-XXXX-</span>{{paymentMethod.account.lastFourNumbers}}
    </span>
    <span *ngIf="!paymentMethod.account.lastFourNumbers">{{paymentMethod.account.accountNumber}}</span>
  </small>

</ng-template>