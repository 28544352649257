import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UiService } from '../_services/ui.service';
import { AuthenticationService } from '../_services/authentication.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }

    if (this.requests.length == 0) {
      this.ui.loaderStop();
    }
  }
  constructor(
    private ui: UiService,
    private authenticationService: AuthenticationService
  ) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var authenticationService = this.authenticationService;
    // add authorization header with jwt token if available
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
      var jwt = JSON.parse(atob(currentUser.token.split('.')[1]));
      const sessionValidSeconds = jwt.exp - Math.round(Date.now() / 1000)
      if (20 > sessionValidSeconds) {
        var path = window.location.href.indexOf("/admin") == -1 ? '' : '/admin';
        setTimeout(function () {
          authenticationService.logout('/logout' + path);
        }, 800);
      }
      request = request.clone({
        setHeaders: {
          'X-API-KEY': environment.xApiKey,
          Authorization: `Bearer ${currentUser.token}`
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          'X-API-KEY': environment.xApiKey,
        }
      });
    }

    this.ui.loaderShow();
    this.requests.push(request);

    next.handle(request)
    return Observable.create(observer => {
      const subscription = next.handle(request)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(request);
              observer.next(event);
            } else {
              // this.removeRequest(request);
            }
          },
          err => {
            this.removeRequest(request);
            observer.error(err);
          },
          () => {
            this.removeRequest(request);
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(request);
        subscription.unsubscribe();
      };
    });

    // return next.handle(request);
  }
}
