<div *ngIf="fees" class="fees" [ngClass]="{'fee-control': false, invalid: !isValid()}">
    <div *ngFor="let fee of fees; let i=index; let last = last; let first = first" class="io-row">
        <mat-form-field>
            <mat-label>Lower amount</mat-label>
            <input matInput [value]="first ? 0 : fees[i-1].toAmount" [disabled]="true">
        </mat-form-field>
        <span class="no-flex" style="line-height: 46px;"> - </span>

        <mat-form-field>
            <mat-label>Upper amount</mat-label>
            <input matInput type="textbox" [(ngModel)]="fee.toAmount" [disabled]="manager" appTwoDigitDecimaNumber>
        </mat-form-field>

        <span class="no-flex" style="line-height: 46px;"> : </span>
        <mat-form-field class="flex-2">
            <input matInput type="textbox" [(ngModel)]="fee.feeAmount" [disabled]="manager" appTwoDigitDecimaNumber>
            <span *ngIf="feeType === 'VARIABLE_FEE'" class="precent" matSuffix>%
            </span>
            <mat-select *ngIf="feeType === 'OUTBOUND_FEE'" [(ngModel)]="fee.feeValueType"
                placeholder="{{fee.feeValueType}}" [disabled]="manager">
                <mat-option *ngFor="let value of feeValue" [value]="value.type">{{value.type}}</mat-option>
            </mat-select>
        </mat-form-field>

        <button [ngClass]="{'no-flex':true, 'hide-btn':!last}" mat-mini-fab (click)="addNewRow(null, null, 'PERCENT')"
            type="submit" [disabled]="manager || !last">
            <mat-icon>add</mat-icon>
        </button>
        <button [ngClass]="{'no-flex':true, 'hide-btn':!last}" mat-mini-fab (click)="deleteRow()"
            [disabled]="manager || !last">
            <mat-icon>remove</mat-icon>
        </button>
    </div>
    <mat-hint [ngClass]="{ 'err-msg': true, 'invalid-msg': error } ">{{ error }}&nbsp;</mat-hint>
</div>