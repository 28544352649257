<div class="container">

    <h1>
        <img src="/assets/images/payto.svg"> Service Agreement
    </h1>
    <p>This is your PayTo Service Agreement with {{'company_name'|translate}}. It explains what your obligations are if
        you ask us to establish a PayTo payment agreement. It also details what our obligations are to you. </p>

    <ol>
        <li>Establishing a Payment Agreement
            <ol>
                <li>
                    By requesting us to establish a payment agreement, you have consented to us to using, disclosing,
                    and recording your details, including the account or PayID details you have provided to us, and the
                    details of the payment agreement in the Mandate Management Service operated by NPP Australia Limited
                    as a payment agreement creation request.
                </li>
                <li>
                    Not all financial institutions offer PayTo services. If your financial institution does not offer
                    PayTo services, we will let you know and offer you an alternative payment option.
                </li>
                <li>
                    If your financial institution supports PayTo, your financial institution will retrieve the payment
                    agreement creation request and deliver it to you, for you to view and to authorise at your
                    discretion.
                </li>
                <li>
                    Your authorisation of the payment agreement creation request is required to be given to your
                    financial institution for the payment agreement to be established. If your account is a joint
                    account, your financial institution may need all joint account holders to authorise the payment
                    agreement.
                </li>
                <li>
                    The method by which your financial institution communicates and delivers the payment agreement
                    creation request to you is up to your financial institution.
                </li>
            </ol>
        </li>
        <li>
            Viewing the Payment Agreement
            <ol>
                <li>
                    Your financial institution will provide you with a facility to view the payment agreements you have
                    authorised.
                </li>
                <li>
                    You may also <a [href]="contact_page">contact us</a> to confirm details of your payment agreement
                    with us.
                </li>
            </ol>
        </li>
        <li>
            Payments under the Payment Agreement
            <ol>
                <li>
                    As soon as a payment agreement is established, we may arrange for payment to be made from your
                    account once you send money.
                </li>
                <li>
                    We will only arrange for payments to be made from your account in accordance with the payment
                    agreement.
                </li>
                <li>
                    Before we arrange for any payment to be made, we or our financial institution will check the payment
                    agreement in the Mandate Management Service to ensure it remains valid and active (that is, that it
                    has not been suspended or cancelled, or otherwise amended, by you).
                </li>
            </ol>
        </li>
        <li>
            Amendments by us
            <ol>
                <li>
                    We may vary any details of this Service Agreement and any of our details included in the payment
                    agreement at any time by giving you at least fourteen (14) days written notice.
                </li>
                <li>
                    If we wish to vary any payment terms of the payment agreement, where required we will submit these
                    as requested amendments to the payment agreement in the Mandate Management Service. Your financial
                    institution will notify you of the amendment and seek your authorisation (where required). This will
                    be recorded in the Mandate Management Service for the amendment to take effect.
                </li>
                <li>
                    We may suspend or cancel the payment agreement at any time. If we do so, we will not be able to
                    arrange for payments to be made from your account.
                </li>
                <li>
                    The status of our payment agreement with you will be as represented by the record of the payment
                    agreement in the Mandate Management Service.
                </li>
            </ol>
        </li>
        <li>
            Amendments by you
            <ol>
                <li>
                    You may change your account number or PayID in our payment agreement or suspend or cancel our
                    payment agreement via your financial institution, which is required to act promptly on your
                    instructions by modifying the record of the payment agreement in the Mandate Management Service.
                </li>
            </ol>
        </li>
        <li>
            Your obligations
            <ol>
                <li>
                    It is your responsibility to ensure that there are sufficient clear funds available in your account
                    to allow a payment to be made in accordance with the payment agreement.
                </li>
                <li>
                    If there are insufficient clear funds in your account to meet a payment:
                    <ol>
                        <li>
                            Make sure your account has enough balance to send money before you create a transaction on
                            {{'company_name'|translate}} APP or
                            website.
                        </li>
                        <li>
                            you can transfer fund between your account before you create a transaction.
                        </li>
                        <li>
                            you may also have more than one active agreement with {{'company_name'|translate}} and those
                            agreements can be from two different banks if you wish to do so.
                        </li>
                        <li>
                            You should check your account statement to verify that the amounts debited from your account
                            are correct.
                        </li>
                    </ol>
                </li>
            </ol>
        </li>
        <li>
            Dispute
            <ol>
                <li>
                    The record of the payment agreement in the Mandate Management Service is evidence of the value and
                    frequency of payments that you have authorised us to have made from your account. If you believe
                    that there has been an error relating to payments from your account, you may notify us directly on
                    <a [href]="contact_page">contact page</a> so that we can resolve your claim quickly.
                </li>
                <li>
                    If we conclude as a result of our investigations that a payment has been made incorrectly from your
                    account, we will respond to your query by arranging for your financial institution to adjust your
                    account accordingly. We will also notify you in writing of the amount by which your account has been
                    adjusted.
                </li>
                <li>
                    If we conclude as a result of our investigations that a payment has not been made incorrectly from
                    your account, we will respond to your query by providing you with reasons and any evidence for this
                    finding in writing.
                </li>
                <li>
                    As an alternative to contacting us in the first instance, you may contact your financial
                    institution. Your financial institution will be able to review the payment agreement in the Mandate
                    Management Service and the payment/s you believe have been made in error, and if appropriate recover
                    the payment/s from us.
                </li>
            </ol>
        </li>
        <li>
            Accounts
            <ol>
                <li>
                    You should check:
                    <ol>
                        <li>
                            with your financial institution whether PayTo is available from your account.
                        </li>
                        <li>
                            that your account details are in the correct format or the PayID which you have provided to
                            us are correct; and with your financial institution if you have any queries about PayTo
                            service.
                        </li>
                    </ol>
                </li>
            </ol>
        </li>
        <li>
            Confidentiality
            <ol>
                <li>
                    We will keep any information (including your account details or PayID details) in your payment
                    agreement creation request confidential. We will make reasonable efforts to keep any such
                    information that we have about you secure and to ensure that any of our employees who have access to
                    information about you do not make any unauthorised use, modification, reproduction or disclosure of
                    that information.
                </li>
                <li>
                    We will only disclose information that we have about you:
                    <ol style="list-style-type: lower-alpha">
                        <li>
                            to the extent specifically required by law; or
                        </li>
                        <li>
                            for the purposes of this service agreement (including disclosing information in connection
                            with any query or claim).
                        </li>
                    </ol>
                </li>
            </ol>
        </li>
    </ol>
    <p>For more information about PayTo, please visit <a href="https://payto.com.au/" target="_blank">payto.com.au</a>.
    </p>
</div>