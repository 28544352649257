import { Apollo, gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, first, map, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { InterfacePermissions } from '../_graphql/schema';
import packageJson from '../../../package.json';
import { environment } from 'src/environments/environment';




@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  static readonly AUTHMETHID_BASIC = 'BASIC';
  static readonly AUTHMETHID_DIGLIAS = 'DIGLIAS';

  user: any;
  authToken: string;

  requestReset = gql`
    mutation requestResetPassword($username: String!) {
      user {
        requestResetPassword(username: $username)
      }
    }
  `;

  resetPassword = gql`
    mutation resetPassword(
      $username: String!, 
      $verificationCode: String!, 
      $newPassword: String!) {
    	  user {
          resetPassword(
          username: $username
          code: $verificationCode
          newPassword: $newPassword
        )
	    }
    }
  `;

  checkUserNameQuery = gql`
    query checkUsername($username: String) {
      checkUsername(username: $username) {
        authMethod
        username
      }
    }
  `;

  constructor(
    private router: Router,
    private apollo: Apollo,
  ) {

    const storage = JSON.parse(localStorage.getItem('currentUser'));

    if (storage && storage.user) {
      this.user = storage.user;
      this.authToken = storage.token;
    }
  }



  hasRoles(neededRoles: string[]) {
    if (!neededRoles || neededRoles.length === 0) {
      return false;
    }

    if (!this.user || !this.user.roles || this.user.roles.length === 0) {
      return false;
    }

    let retVal = false;
    neededRoles.forEach(el => {
      if (this.user.roles.includes(el)) {
        retVal = true;
      }
    });

    return retVal;
  }

  // getDefaultPath(neededRoles: any[]) {
  //   let retVal = '';
  //   Object.keys(menuAccessDefinition).forEach(el => {
  //     neededRoles.forEach(needed => {
  //       if (menuAccessDefinition[el].includes(needed)) {
  //         retVal = el;
  //       }
  //     });
  //   });

  //   return '/admin/' + retVal;
  // }

  storeUser(userData) {
    this.apollo?.client?.resetStore();
    localStorage.setItem(
      'currentUser',
      JSON.stringify({
        token: userData.authToken,
        user: userData
      })
    );

    this.authToken = userData.authToken;
    this.user = userData;
  }



  getRoleForRoute(localRoute: string) { }

  public logout(redirectPath: string = '/logout'): void {
    this.router.navigate([redirectPath]);
  }

  public isLoggedIn(onlyAsUser = false) {
    return !!this.authToken && (!onlyAsUser);
  }

  public resetPasswordEndUser(x: any) {
    return this.apollo.mutate({
      mutation: this.resetPassword,
      variables: x
    });
  }

  public requestResetPasswordEndUser(x: any) {
    return this.apollo.mutate({
      mutation: this.requestReset,
      variables: x
    });
  }

  public checkUserName(username) {
    return this.apollo
      .watchQuery({
        query: this.checkUserNameQuery,
        variables: {
          username
        }
      })
      .valueChanges.pipe(
        map((result: any) => result.data && result.data.checkUsername)
      );
  }
  adminLogin(data: any) {
    return this.apollo.mutate({
      mutation: gql` mutation login($username: String!, $password: String!) {
        employee {
          login(username: $username, password:$password){
            id
            name
            email
            authToken
            permissions
            roles
            country{
              id
              isoCode3
              isoCode2
            }
          }
        }
      }
    `,
      variables: { username: data?.username, password: data?.password }
    }).pipe(take(1)).pipe(
      first(),
      map((result: any) => {
        return result?.data?.employee || null;
      }));
  }

  public login(data: any) {
    var appVersion = 'W' + environment.clientCode + '_' + packageJson.version

    return this.apollo.mutate({
      mutation: gql`mutation login($username: String!, $password: String!, $appVersion: String ) {
      user {
        login(username: $username, password: $password, appVersion: $appVersion){
          roles
          authToken
          id
          country {id name isoCode2 isoCode3 phonePrefix currency}
          email
          firstName middleName lastName
          permissions
        }
      }
    }`,
      variables: { username: data?.username, password: data?.password, appVersion: appVersion }
    }
    ).pipe(take(1)).pipe(
      first(),
      map((result: any) => {
        return result?.data?.user?.login || null;
      }));;
  }

  isAdmin() {
    return this.hasRoles(['Manager', 'Admin']);
    // return this.hasPermission(this.perm.CompanyManage);
  }

  public get perm() {
    return InterfacePermissions;
  }

  hasPermission(permission: InterfacePermissions): boolean {
    return this.user && this.user.permissions && this.user?.permissions?.indexOf(permission) !== -1;
  }
  public confirmIdentity(data: any) {
    var appVersion = 'W' + environment.clientCode + '_' + packageJson.version;

    return this.apollo.mutate({
      mutation: gql `mutation confirmIdentity($userName: String!, $password: String!, 
        $action: AccountAction!, $appVersion: String) {
        user {
          confirmIdentity(username: $userName, password: $password, 
            action: $action, appVersion: $appVersion) {
              id email authToken permissions roles country{ id isoCode3 isoCode2 }
          }
        }
      }`,
      variables: { userName: data?.userName, password: data?.password, appVersion: appVersion, action: data?.action }
    }
    ).pipe(take(1)).pipe(
      first(),
      map((result: any) => {
        return result?.data?.user?.confirmIdentity || null;
      }));
  }
  public deleteUserAccount() {

    return this.apollo.mutate({
      mutation: gql `mutation deleteAccount {
        user {
          deleteAccount {
            infoMessage status
          }
        }
      }`,
    }
    ).pipe(take(1)).pipe(
      first(),
      map((result: any) => {
        return result?.data?.user?.deleteAccount || null;
      }));
  }

}
