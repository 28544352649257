<div style="padding: 16px; flex-direction: column;" *ngIf="user$|async as user">
  <div style="text-align: right; padding-bottom: 16px">
    <span *ngIf=" user?.phoneVerificationEnabled == true && user?.phoneVerified == false">
      <small style="margin-right: 8px; font-size: 12px;">{{ 'phone_verify_hint' | translate}}
      </small>
      <button mat-stroked-button color="primary" routerLink="/platform/verify/phone" style="margin-right: 8px;">
        <mat-icon class="edit-icon" aria-label="edit icon">phone</mat-icon>
        {{ 'Verify phone' | translate }}
      </button>
    </span>
    <button mat-stroked-button color="primary" (click)="editProfile(user)">
      <mat-icon class="edit-icon" aria-label="edit icon">edit</mat-icon>
      {{ 'Edit Profile' | translate }}
    </button>
  </div>
  <mat-accordion multi="true">
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto">
        <mat-panel-title>
          {{ 'ABOUT' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="io-row-l align-items-center" style="padding: 24px 0 16px 0;">
        <div>
          <div style="text-align: center;">
            <img class="user-image" width="78" [src]="publicService.countryFlagImage(user?.country?.isoCode3)">
            <div class="profile-section">

              <div class="profile-info">
                <p class="user-profile-title">{{ user?.firstName }} {{ user?.lastName }}</p>
                <p class="user-profile-subtitle" matTooltip="{{ user?.email }}" matTooltipClass="tooltip-width-unset">{{
                  user?.email }}</p>
                <p class="user-profile-subtitle">{{ user?.created | date }}</p>
              </div>
            </div>
          </div>
          <div class="money-info">
            <div>
              <p class="user-money" matTooltip="{{ user?.moneySent | number: '1.2-2' }}">{{ user?.moneySent | number:
                '1.2-2'
                }}</p>
              <p class="user-money-title">{{ 'Amount Sent' | translate }} ({{user.country.currency}})</p>
            </div>
            <div style="width: 6px;"></div>
            <div>
              <p class="user-money" matTooltip="{{ user?.moneyReceived | number: '1.2-2' }}">{{ user?.moneyReceived |
                number:
                '1.2-2' }}</p>
              <p class="user-money-title">{{ 'Amount Delivered' | translate }} ({{user.country.currency}})</p>
            </div>
          </div>
        </div>
        <div class="vertical-line no-flex"></div>

        <div class="io-row flex-3">
          <form-data-display-configuration fieldsGroup="updateUser" [country]="user.country.isoCode2" [data]="user">
          </form-data-display-configuration>
        </div>

      </div>

    </mat-expansion-panel>
  </mat-accordion>
  <div class="io-row-l" style="padding-top: 16px;">
    <div>
      <mat-accordion multi="true">
        <mat-expansion-panel class="accordion" *ngIf="documents$|async as documents">
          <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto">
            <mat-panel-title>
              {{ 'DOCUMENTS' | translate }} ({{documents.totalCount }})
            </mat-panel-title>
          </mat-expansion-panel-header>

          <app-document-list [userId]="user.id" [documents]="documents?.data" [country]="user.country">
          </app-document-list>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div>
      <mat-accordion multi="true">
        <mat-expansion-panel class="accordion" *ngIf="files$|async as files">
          <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto">
            <mat-panel-title>
              {{ 'FILES' | translate }} ({{ files.totalCount }})
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-files-list [userId]="user.id" [files]="files.data"></app-files-list>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

</div>