<div class="io-row no-gap align-items-center">
  <div class="icon icon-status-{{_status}}">
    <mat-icon [matTooltip]="_status" class="orange" *ngIf="['Approved'].indexOf(_status) != -1">outbound</mat-icon>
    <mat-icon [matTooltip]="_status" class="received"
      *ngIf="['Reimbursed', 'Completed', 'Confirmed'].indexOf(_status) != -1">check_circle</mat-icon>
    <mat-icon [matTooltip]="_status" class="stopped"
      *ngIf="['Stopped', 'Declined'].indexOf(_status) != -1">report</mat-icon>
    <mat-icon [matTooltip]="_status" class="orange" *ngIf="['Delivered'].indexOf(_status) != -1">check_circle</mat-icon>
    <mat-icon [matTooltip]="_status" class="stopped" *ngIf="['Canceled'].indexOf(_status) != -1">cancel</mat-icon>
    <mat-icon style="opacity: 0"
      *ngIf="['Canceled', 'Delivered', 'Stopped', 'Declined', 'Reimbursed', 'Completed', 'Confirmed', 'Approved'].indexOf(_status) == -1">cancel</mat-icon>
  </div>
</div>