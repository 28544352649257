import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalculatorComponent } from './calculator.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CalculatorTransactionPreviewComponent } from 'src/app/platform/calculator-enduser/calculator-transaction-preview/calculator-transaction-preview.component';
import { CalculatorEnduserComponent } from 'src/app/platform/calculator-enduser/calculator-enduser.component';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { RecipientsModule } from 'src/app/platform/recipients/recipients.module';
import { TwoDigitDecimaNumberDirective } from './two-digit-decima-number.directive';
import { SingleSelectModule } from '../select-country/single-select.module';


@NgModule({
  declarations: [
    CalculatorComponent,
    CalculatorEnduserComponent,
    CalculatorTransactionPreviewComponent,
    TwoDigitDecimaNumberDirective,
  ],
  imports: [
    RecipientsModule,
    CommonModule,
    MaterialModule,
    FormsModule,
    PipesModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    SingleSelectModule
  ],
  exports: [
    CalculatorComponent,
    CalculatorEnduserComponent,
    CalculatorTransactionPreviewComponent,
    TwoDigitDecimaNumberDirective,
  ],
})
export class CalculatorModule { }
