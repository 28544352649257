<ng-container *ngIf="!imageAttached">
    <mat-form-field class="width-100">
        <mat-label>{{field?.label}}</mat-label>
        <input matInput [formControl]="control" [matDatepicker]="picker" [min]="getMin(field?.max)"
            [max]="getMax(field?.min)" [required]="required"/>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
        <mat-datepicker #picker startView="multi-year"
            (monthSelected)="setMonthAndYear($event, picker)"></mat-datepicker>
    </mat-form-field>
</ng-container>

<ng-container *ngIf="imageAttached">
    <mat-form-field class="width-100" style="position: relative;" *ngIf="imageDisplayType === 'small'">
        <mat-label>{{field?.label}}</mat-label>
        <input matInput [formControl]="control" [matDatepicker]="picker" [min]="getMin(field?.max)"
            [max]="getMax(field?.min)" [required]="required" (focus)="picker.open()" (blur)="hideImageComponent()"/>
            <button mat-icon-button matSuffix type="button" (click)="showImageComponent($event); openImageDialogComponent()">
                <mat-icon >info</mat-icon>
            </button>
        <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
        <mat-datepicker #picker startView="multi-year"
            (monthSelected)="setMonthAndYear($event, picker)"></mat-datepicker>
    </mat-form-field>

    <mat-form-field  class="width-100" style="position: relative;" *ngIf="imageDisplayType === 'large'">
        <mat-label>{{ field.label }}</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="control"
            [min]="getMin(field?.max)" [required]="required" [max]="getMax(field?.min)" (focus)="showImageComponent();" 
            (blur)="hideImageComponent()"/>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
    </mat-form-field>
    <ng-content select="[image]"></ng-content>
</ng-container>