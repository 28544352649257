import { Component, OnInit } from '@angular/core';
import { AdminRecipientsService } from 'src/app/_services/admin/admin-recipients.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-admin-recipients',
  templateUrl: './admin-recipients.component.html',
  styleUrls: ['./admin-recipients.component.scss']
})
export class AdminRecipientsComponent implements OnInit {
  items$: any;
  form$: Subscription;
  form: UntypedFormGroup;
  customRefreshKey: string;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    public service: AdminRecipientsService,
    public auth: AuthenticationService,
  ) {
    this.customRefreshKey = this.route.snapshot.paramMap.get('userId') ? 'recipients4' + this.route.snapshot.paramMap.get('userId') : null;
    this.form = this.fb.group({
      userId: [this.route.snapshot.paramMap.get('userId')],
    })
  }

  ngOnInit() {
    this.items$ = this.service.all(this.form.value, false, this.customRefreshKey);
    this.form$ = this.form.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(data => {
      this.getData()
    });
  }
  getData() {
    this.service.fetchMoreData(this.form.value, this.customRefreshKey)
  }

}
