import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { UiService } from '../_services/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthenticationService, private ui: UiService, private tt: TranslateService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (route && route.data && route.data?.title) {
      var k = this.tt.get('company_name').subscribe(company => {

        if (route.data?.title)
          this.ui.setTitle(route.data?.title);
        else if (company !== 'company_name') {
          this.ui.setTitle(company);
        }

        k?.unsubscribe();
      });
    }
    // user is not logged in
    if (!this.auth.user) {
      if (route.data.redirectPath) {
        this.router.navigate([route.data.redirectPath], {
          queryParams: { returnUrl: state.url }
        });
      } else {
        this.router.navigate([window.location.pathname.startsWith('/admin') ? '/admin/login' : '/login'], { queryParams: { returnUrl: state.url } });
      }
      return false;
    } else {
      // user is logged in but no permission
      if (!this.auth.hasPermission(route.data.allowedPermission)) {
        if (this.auth.hasPermission(this.auth.perm.TransactionsManage))
          this.router.navigate(['/', 'admin', 'transactions']);
        else if (this.auth.hasPermission(this.auth.perm.TransactionsPayoutsRead))
          this.router.navigate(['/', 'admin', 'payouts']);
        else if (this.auth.hasPermission(this.auth.perm.EndUser))
          this.router.navigate(['/', 'platform', environment.defaultPage]);
      }
    }
    return true;
  }
}
