<list-wrapper #wrapper [mainService]="service" [panelTitle]="componentConfig?.hideTitle ? '' : 'Payouts'"
  [managePerm]="false" [readPerm]="auth.perm.TransactionsPayoutsRead" [hideAddBtn]="true"
  (onEdit)="editItem($event)">
  <form [formGroup]="form" class="io-row-l width-100" toolbox style="align-items: center">
    <mat-form-field class="width-100 width-mff" appearance="outline">
      <mat-label>{{ 'Transaction Number' | translate }}</mat-label>
      <input matInput formControlName="query" placeholder="{{ 'Transaction Number' | translate }}">
      <button mat-icon-button matSuffix type="button" (click)="form.patchValue({'query': null})"
        [disabled]="!form.get('query').value">
        <mat-icon *ngIf="form.get('query').value">clear</mat-icon><mat-icon
          *ngIf="!form.get('query').value">search</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="width-100 width-mff" appearance="outline">
      <mat-label>{{ 'Pickup Code' | translate }}</mat-label>
      <input matInput formControlName="pickupCode" placeholder=" {{ 'Pickup Code' | translate }}">
      <button mat-icon-button matSuffix type="button" (click)="form.patchValue({'pickupCode': null})"
        [disabled]="!form.get('pickupCode').value">
        <mat-icon *ngIf="form.get('pickupCode').value">clear</mat-icon><mat-icon
          *ngIf="!form.get('pickupCode').value">search</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="width-100 width-mff" appearance="outline" *ngIf="componentConfig?.dateFrom">
      <mat-label>{{'From' | translate}}</mat-label>
      <input matInput readonly [matDatepicker]="picker2" [max]="form.get('dateTo').value || maxDate"
        placeholder="{{'From' | translate}}" formControlName="dateFrom">
      <div matSuffix>
        <mat-datepicker-toggle [for]="picker2"></mat-datepicker-toggle>
        <button mat-icon-button (click)="form.get('dateFrom').setValue(null)" *ngIf="form.get('dateFrom').value">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="width-100 width-mff" appearance="outline" *ngIf="componentConfig?.dateTo">
      <mat-label>{{'To' | translate}}</mat-label>
      <input matInput readonly [matDatepicker]="picker3" [min]="form.get('dateFrom').value" [max]="maxDate"
        placeholder="{{'To' | translate}}" formControlName="dateTo">
      <div matSuffix>
        <mat-datepicker-toggle [for]="picker3"></mat-datepicker-toggle>
        <button mat-icon-button (click)="form.get('dateTo').setValue(null)" *ngIf="form.get('dateTo').value">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <mat-datepicker #picker3></mat-datepicker>
    </mat-form-field>
  </form>

  <div class="io-py-1">

    <div *ngFor="let item of items; last as last;" class="io-item io-item-shadow">
      <div class="main">
        <h4>{{ item?.transactionId }}</h4>
        <div class="data country">
          <small matTooltip="{{'From'|translate}}">{{item?.fromName}}</small>
          <span matTooltip="{{'To'|translate}}">{{item?.toName}}</span>
        </div>
        <div class="data money">
          <small>{{'Payout'|translate}}</small>
          <span>{{ item?.receivingCurrency }} {{ item?.receivingAmount | number: '1.2-2' }}
          </span>
        </div>
        <app-transaction-status [iconOnly]="true" [status]="item?.transactionStatusInfo" class="io-mr-1">
        </app-transaction-status>
        <div class="data status">
          <small>{{ 'Status' | translate }}</small>
          <span>
            <aside matTooltip="{{ ('transaction_'+item?.transactionStatusInfo?.name?.replace(' ', '_'
              )?.toUpperCase()+'_info') | translate }}">{{item?.transactionStatusInfo?.name}}</aside>
            <s matTooltip="{{ item?.created | timezone | date: 'short' }}">
              {{ item?.created | dateAgo }}
              <aside *ngIf="!auth.isAdmin()">{{ item?.transactionStatusInfo?.note | translate }}</aside>
            </s>
          </span>
        </div>
        <div class="data">
          <small>{{'Sender'|translate}}</small>
          <span>{{ item?.userFirstName}} {{ item?.userLastName}}
            <s [matTooltip]="item?.userEmail">{{item?.userEmail}}</s>
            <s>
              <table>
                <tr>
                  <td *ngIf="item?.sender?.userStatusInfo?.flagCount > 0"
                    [matTooltip]="item?.sender.userStatusInfo.flagTooltip">
                    <mat-icon>error</mat-icon>
                  </td>
                  <td *ngIf="item?.sender?.userStatusInfo?.flagCount > 0">
                    {{item?.sender.userStatusInfo.flagCount}}
                  </td>
                  <td *ngIf="item?.sender?.userStatusInfo?.ticketCount > 0"
                    [matTooltip]="item?.sender.userStatusInfo.ticketTooltip">
                    <mat-icon>local_offer</mat-icon>
                  </td>
                  <td *ngIf="item?.sender?.userStatusInfo?.ticketCount > 0">
                    {{item?.sender.userStatusInfo.ticketCount}}
                  </td>
                </tr>
              </table>
            </s>
          </span>
        </div>

        <div class="data recipient">
          <small>{{'Recipient'|translate}}</small>
          <span>{{ item?.recipientFirstName}} {{ item?.recipientLastName}}
            <s>{{item?.recipientPhone}}</s>
          </span>
        </div>
        <app-outbound-status *ngIf="auth.hasPermission(auth.perm.TransactionOutboundStatusManage)" [iconOnly]="true"
          [status]="item.outboundStatus">
        </app-outbound-status>
        <div class="data outbound">
          <small>{{'Outbound'|translate}}</small>
          <span>
            {{item.paymentNetworkName }}
            <s *ngIf="item?.pickupCode && !auth.hasPermission(auth.perm.TransactionOutboundStatusManage)">Pickup Code: {{item?.pickupCode}}</s>
          </span>
        </div>
      </div>
    </div>

  </div>
</list-wrapper>