<div *ngIf="files?.length" style="margin-top: 20px;">
    <div>
        <mat-accordion [multi]="true">
            <mat-expansion-panel *ngFor="let file of files; let i = index;let first = first">
                <mat-expansion-panel-header style="padding: 0 16px 0 8px;">
                    <mat-panel-title class="io-row">
                        <mat-icon class="file-icon no-flex">{{file?.name | filetypeIcon}}</mat-icon>
                        <span class="file-name" *ngIf="!file.category?.name.length">{{
                            file.name }}</span>
                        <span class="file-name" *ngIf="file.category?.name.length">{{
                            file.category.name }}</span>


                    </mat-panel-title>
                </mat-expansion-panel-header>
                <table class="table-details narrow">
                    <tbody>
                        <tr>
                            <th>{{ 'Created' | translate }}</th>
                            <td>{{ file.created | date}}</td>
                        </tr>
                        <tr>
                            <th>{{ 'Document No' | translate }}</th>
                            <td>{{ file.document?.documentNumber | noData }}</td>
                        </tr>
                        <tr>
                            <th>{{ 'Document' | translate }}</th>
                            <td>{{ file.document?.category?.name | noData }}</td>
                        </tr>
                        <tr>
                            <th>{{ 'Description' | translate }}</th>
                            <td>{{ file?.description | noData }}</td>
                        </tr>
                        <tr>
                    </tbody>
                </table>
                <mat-action-row>
                    <button (click)="editDocument(userId, file)" *ngIf="isAdminMode" mat-stroked-button color="primary">
                        {{'Edit'|translate}}
                    </button>
                    <button (click)="downloadDocument(file);" mat-raised-button color="primary">
                        {{'Download'|translate}}
                    </button>
                </mat-action-row>
            </mat-expansion-panel>

        </mat-accordion>
    </div>
</div>
<p *ngIf="!files?.length">{{ 'Nothing uploaded yet.' | translate }}</p>