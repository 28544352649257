import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-json-renderer',
  templateUrl: './json-renderer.component.html',
  styleUrls: ['./json-renderer.component.scss']
})
export class JsonRendererComponent {

  type = 'string'

  _items: any | string | null;
  get items(): any {
    return this._items;
  }
  @Input() set items(value: string | null) {
    try {

      let a = JSON.parse(value);

      if (typeof a == 'object') {
        this._items = a;
        // this._items = [
        //   {
        //     "name": "evert abbott",
        //     "sources": [
        //       "ost",
        //       "lista 123"
        //     ],
        //     "types": [
        //       "ost",
        //       "lista 123"
        //     ],
        //     "fields": [
        //       {
        //         "name": "value1",
        //         "value": "value2"
        //       },
        //       {
        //         "name": "Image",
        //         'type': 'image',
        //         "value": "https://banjaluka.city/v2/images/explore/kastel-small.jpg"
        //       }, {
        //         "name": "URL",
        //         'type': 'url',
        //         "value": "https://banjaluka.city/v2/images/explore/kastel-small.jpg"
        //       }, {
        //         "name": "Do something",
        //         'type': 'btn',
        //         "value": "https://banjaluka.city/v2/images/explore/kastel-small.jpg"
        //       }, {
        //         "name": "Do something",
        //         'type': 'btn-primary',
        //         "value": "https://banjaluka.city/v2/images/explore/kastel-small.jpg"
        //       },
        //       {
        //         "name": "Do something",
        //         'type': 'btn-warn',
        //         "value": "https://banjaluka.city/v2/images/explore/kastel-small.jpg"
        //       }, {
        //         "name": "Date of birth",
        //         'type': 'date',
        //         "value": "2004-01-05"
        //       }
        //     ]
        //   }
        // ]
        this.type = 'array'
      }
    } catch (e) {
      this._items = value;
      this.type = 'string'
    }

  }


  constructor(private ui: UiService) { }

}