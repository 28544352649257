import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { UiService } from 'src/app/_services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminManagersService } from 'src/app/_services/admin/admin-managers.service';
import { AdminCountriesService } from 'src/app/_services/admin/admin-countries.service';
import { SystemService } from 'src/app/_services/system.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { AssignableRoleNames, PropertyType } from 'src/app/_graphql/schema';
import { Subscribable, Unsubscribable } from 'rxjs';
import { CustomValidators } from 'src/app/_helpers/custom.validators';

@Component({
  selector: 'app-admins-item',
  templateUrl: './admins-item.component.html',
  styleUrls: ['./admins-item.component.scss']
})
export class AdminsItemComponent implements OnInit, OnDestroy {
  hide = true;

  paymentServices$: any;
  paymentNetworks$: any;
  countries$: any;
  countriesAll$: any;

  id: string;
  country: any;
  password = 0;
  managerUnsub$: Unsubscribable;
  nameRoles$: Subscribable<PropertyType[]>;


  form = this.fb.group({
    id: [null, []],
    firstName: [null, [Validators.required, CustomValidators.noWhitespaceValidator()]],
    middleName: [null],
    lastName: [null, [Validators.required, CustomValidators.noWhitespaceValidator()]],
    userName: [null, [Validators.required, CustomValidators.noWhitespaceValidator()]],
    email: [null, Validators.required],
    phone: [null, Validators.compose([Validators.required, Validators.pattern("[0-9]+"), Validators.maxLength(18)])],
    countryId: [null, Validators.required],
    password: [null, Validators.required],
    isActive: [false, Validators.required],
    assignedToCountryIds: [null],
    assignedNetworkIds: [null],
    assignedServiceIds: [null],
    accessLevel: [null, [Validators.required]],
  });

  constructor(
    private ui: UiService,
    private service: AdminManagersService,
    public systemService: SystemService,
    public countries: AdminCountriesService,
    private dialogRef: MatDialogRef<AdminsItemComponent>,
    private fb: UntypedFormBuilder,
    public auth: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public itemId: any
  ) {

    this.paymentNetworks$ = this.systemService.paymentNetworks();
    this.paymentServices$ = this.systemService.paymentServices();
    this.countriesAll$ = this.countries.dropdownAll(null, false, 'allCountries');
    this.countries$ = this.countries.dropdownAll({ isRecipient: true });
    this.nameRoles$ = this.service.roleNames();


    if (itemId)
      this.managerUnsub$ = this.service.one(itemId).subscribe({
        next: x => {
          var _x = Object.assign({}, x);
          _x['countryId'] = _x?.country?.id;
          this.form.patchValue(_x)
          this.form.get('accessLevel').patchValue(_x?.accessLevel[0]);
          this.form.markAllAsTouched();

          this.form.get('password').clearValidators();
          this.form.get('password').updateValueAndValidity();

          this.form.get('userName').disable();
          // s?.unsubscribe();
          // this.getFormValidationErrors();
        }
      })

    // this.countriesService.all().subscribe(countries => {
    //   this.countries = countries;
    //   if (this.data.user) {
    //     this.country = this.countries.find(x => x.id === this.data.user?.country?.id);
    //   }
    // });


    // if (this.statusEdit === true) {
    //   this.form = this.fb.group({
    //     id: [this.data.user?.id],
    //     firstName: [this.data.user?.firstName, Validators.required],
    //     middleName: [this.data.user?.middleName],
    //     lastName: [this.data.user?.lastName, Validators.required],
    //     userName: [this.data.user?.userName, Validators.required],
    //     email: [this.data.user?.email, Validators.required],
    //     phone: [this.data.user?.phone, Validators.compose([Validators.required, Validators.pattern("[0-9]+"), Validators.maxLength(18)])],
    //     countryId: [this.data.user?.country?.id, Validators.required],
    //     password: [this.data.user?.password, Validators.required],
    //     isActive: [this.data.user?.isActive || false, Validators.required],
    //   });

    // }
  }

  ngOnInit() {

  }



  onSubmit() {
    if (this.form.valid) {
      var d = this.form.value;
      if (d.id)
        delete d.password;
      var s = !d.id ? this.service.create(d) : s = this.service.modify(d);
      var k = s.subscribe({
        next: x => {
          this.ui.snack('Manager ' + (d.id ? 'saved!' : 'created!'));
          k?.unsubscribe();
          this.dialogRef.close();
        }
      });
    }
  }
  public get assignableRoleNames() {
    return AssignableRoleNames;
  }
  ngOnDestroy(): void {
    this.managerUnsub$?.unsubscribe();
  }

}
