import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DrawerService } from '../wrapper/drawer.service';
import { Subscribable, Subscription, Unsubscribable } from 'rxjs';
import { AdminUsersService } from 'src/app/_services/admin/admin-users.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { AdminEndUserType, CountryType, EndUserType } from 'src/app/_graphql/schema';
import { PublicService } from 'src/app/_services/public.service';
import { gql } from 'apollo-angular';
import { SystemService } from 'src/app/_services/system.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy, AfterViewInit {
  flags$: any;
  $form: Subscription;
  form: UntypedFormGroup = this.fb.group({
    query: [null],
    active: [null],
    activeFlags: [null],
    toCountryIds: [null],
    deleted: [null],
  });
  countriesRecipient$: Subscribable<CountryType>;
  $items: Unsubscribable;
  $usersMore: Unsubscribable;
  items: AdminEndUserType[];

  constructor(
    public service: AdminUsersService,
    public auth: AuthenticationService,
    private router: Router,
    private systemService: SystemService,
    private drawerService: DrawerService,
    public publicService: PublicService,
    private fb: UntypedFormBuilder
  ) {
    this.flags$ = this.systemService.flags('USER', true);
    this.service.getMoreDetails = false;
    this.countriesRecipient$ = this.publicService.countries(null, true, null)
  }


  ngOnInit() {
    this.getData();
    this.$form = this.form.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(data => {
      this.getData()
    });
  }
  getData() {
    this.service.queryAllParams.skip = 0;
    this.service.pageIndex = 0;
    this.$items?.unsubscribe();
    var f = this.form.value;
    this.service.allClose();
    this.$items = this.service.all(f, true, null, false).subscribe({
      next: x => {
        this.items = x
        this.service.getMoreDetails = true;
        var data = this.service.getPageRequestAllFilters()
        this.service.getMoreDetails = false;
        this.$usersMore = this.service.query(this.service.selectAllQuery,
          data, false, 'users'
        ).subscribe({
          next: (_items: any) => {
            _items.data.forEach(_item => {
              var i = this.items.find(el => el.id == _item.id)
              if (i)
                i = { ...i, ..._item }
            })
            this.$usersMore?.unsubscribe()
          }
        })
      }
    })

    // this.service.fetchMoreData(this.form.value)
  }
  ngAfterViewInit() {
    var filters = this.service.getPageRequestAllFilters()
    this.form.patchValue(filters.pageRequest?.filters, { emitEvent: false })  //emitEvent:false should be removed?
  }
  // this.drawerService.sendState.next(false);
  editItem(user: any) {
    this.router.navigate(['/admin/users/' + user.id]);
    this.drawerService.close();
    this.drawerService.displayState(true)
  }

  getReport() {
    var s = this.service
      .report(this.form.value)
      .subscribe({
        next: reportId => {
          this.publicService.downloadFile(reportId, 'risk_assessment.xlsx');
          s?.unsubscribe();
        }
      });
  }

  ngOnDestroy() {
    this.$form?.unsubscribe();
    this.$items?.unsubscribe();
    this.$usersMore?.unsubscribe();
  }
}
