import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloClient, defaultDataIdFromObject, InMemoryCache } from '@apollo/client/core';
import { Component, OnInit, Injector } from '@angular/core';

import { environment } from 'src/environments/environment';




import { EnvConfigurationService } from './_services/env-configuration.service';
import { Title } from '@angular/platform-browser';
import { TranslationsService } from './_services/translations.service';
import { HttpClient } from '@angular/common/http';
import { PublicService } from './_services/public.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: PublicService.getDateInputFormat() }]
})
export class AppComponent implements OnInit {
  public loading = true;
  private tt;

  constructor(
    private title: Title,
    private http: HttpClient,
    private apollo: Apollo,
    private httpLink: HttpLink,
    protected injector: Injector,
    private ps: PublicService,
  ) { }

  ngOnInit() {
    this.http.get<any>(EnvConfigurationService.configFile).subscribe(result => {
      Object.keys(result).forEach(key => {

        if (result[key] !== '__DEPLOYMENT_CONFIG__') {
          if (result[key] == "true") {
            environment[key] = true;
          } else if (result[key] == "false") {
            environment[key] = false;
          } else {
            environment[key] = result[key];
          }
        }
      });

      // totally weird but only way to dinamically set environment.apiUrl
      // and bootstrap Apollo GraphQL client
      const apolloClient = new ApolloClient({
        cache: new InMemoryCache({

          typePolicies: {
            PaymentNetworkType : {
              keyFields: ['id', 'name']
            },
            Transaction: {
              fields: {
                userFirstName: {
                  read(val) {
                    return val || null;
                  },
                },
                userEmail: {
                  read(val) {
                    return val || null;
                  },
                },
                userLastName: {
                  read(val) {
                    return val || null;
                  },
                },
              },
            },

            Query: {
              fields: {
                system: {
                  keyArgs: false,
                  merge(existing = {}, incoming) {
                    const k = Object.assign({}, incoming, existing);
                    return k;
                  },
                },
                setup: {
                  // 
                  // Don't cache separate results based on
                  // any of this field's arguments.
                  keyArgs: ["getMoreDetails", "pageRequest", ["filters"]],

                  // Concatenate the incoming list items with
                  // the existing list items.
                  merge(existing = [], incoming) {
                    return incoming ? incoming : existing;
                  },
                },

              },
            },
            //   Transaction: {
            //     fields: {
            //       receipt: {
            //         read(receipt) { // The read function for the isInCart field
            //           return receipt ? receipt : null;
            //         }
            //       }
            //     }
            //   }
          },
          // dataIdFromObject: (object) => {
          //   switch (object.__typename) {
          //     case "PaymentNetworkType":
          //       // Use the 'id' field as the cache key for Transaction type
          //       return `${object.__typename}:${object.name}`;
          //     // Add other cases for different types
          //     default:
          //       // For types without a specified cache key, fall back to the default behavior
          //       return defaultDataIdFromObject(object);
          //   }
          // },
        }),
        link: this.httpLink.create({ uri: environment.apiUrl })
      });

      this.apollo.client = apolloClient;
      this.loading = false;

      this.tt = this.injector.get(TranslationsService);

      this.tt.get('company_name').subscribe(company => {
        if (company !== 'company_name') {
          this.title.setTitle(company);
        }
      });
      var _getFrontendConfiguration = this.ps.getFrontendConfiguration().subscribe({
        next: x => {
          PublicService.setDateInputFormat(x.inputDateFormat);
          PublicService.setDisplayDateFormat(x?.dateFormatShort, x?.dateFormatLong);
          _getFrontendConfiguration.unsubscribe();
        }
      })
    });
  }
}
