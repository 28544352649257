<user-list-wrapper [disablePlaceholder]="true" [mainService]="service" panelTitle="Recipients">
  <div toolbox class="io-row-m align-items-center width-100 toolbox-container">
    <mat-form-field class="width-100 width-mff" appearance="outline" >
      <mat-label>{{ 'Name' | translate }}</mat-label>
      <input matInput [formControl]="query" placeholder="{{ 'Name' | translate }}">
      <button mat-icon-button matSuffix type="button" (click)="query.setValue(null)"
        [disabled]="!query.value">
        <mat-icon *ngIf="query.value">clear</mat-icon><mat-icon
          *ngIf="!query.value">search</mat-icon>
      </button>
    </mat-form-field>
    <button class="no-flex" mat-stroked-button color="primary" (click)="modalLauncherService.newRecipient()">
      Add New Recipient
    </button>
  </div>
  <app-panel-placeholder key="recipients" *ngIf="service.totalCount == 0" mode="large" image="/assets/images/placeholder-recipients.svg">
    <button mat-raised-button color="primary" aria-label="Add new recipient"
      (click)="modalLauncherService.newRecipient()">
      Add New Recipient
    </button>
  </app-panel-placeholder>

  <app-recipient-list-item *ngFor="let item of items$ | async" [item]="item"></app-recipient-list-item>
</user-list-wrapper>