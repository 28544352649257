<div mat-dialog-title>Change Status</div>
<mat-dialog-content>
  <p>Are you sure you want to change transaction status from {{sentData.transaction.status.name}} to Sent?</p>
  <form [formGroup]="dataForm" *ngIf="hideId">
    <mat-form-field appearance="outline">
      <mat-label>External ID</mat-label>
      <input matInput formControlName="externalId">
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="no-button" mat-stroked-button mat-dialog-close>No</button>
  <button class="yes-button" mat-raised-button mat-dialog-close type="submit" [disabled]="!dataForm.valid" color="warn"
    (click)="setStatus()">Yes</button>
</mat-dialog-actions>