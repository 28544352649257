import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-review-step',
  templateUrl: './review-step.component.html',
  styleUrls: ['./review-step.component.scss']
})
export class ReviewStepComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() fieldsConfig;
  @Input() uniqueFormSections: [];



  constructor() { }

  ngOnInit(): void {
  }
  getFA(section: string, name: string): FormArray {
    return this.form.get(section).get(name) as FormArray;
  }

  isElementInFormArrayData(section: string, name: string, value: string) {
    var fa = this.getFA(section, name);
    return fa.value.indexOf(value);
  }
  shouldShowSectionInReview(section) {
    if (section.groupPolicy == 'AllRequired') {
      return true;
    } else if (section.name == 'consents') {
      return true;
    } else {
      var hasAnyEntries = false;
      section.fields.forEach(field => {
        if (this.form?.get(section.name)?.get(field.name).value) {
          hasAnyEntries = true;
        }
      });

      return hasAnyEntries;
    }
  }

}
