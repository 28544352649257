import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { AdminTransactionType } from 'src/app/_graphql/schema';

import { BaseService } from './base.service';



@Injectable({
  providedIn: 'root'
})
export class AdminPayoutsService extends BaseService<AdminTransactionType>{

  selectOneFields = gql`
    fragment PayoutSelectOneFields on AdminTransactionType  {
      id transactionId fromName fromIsoCode3 toName toIsoCode3 receivingCurrency receivingAmount
      created
      userFirstName 
      userLastName
      pickupCode
      transactionStatusInfo { 
        statusId, note, icon, name, flagCount, flagTooltip, ticketCount, ticketTooltip
      }
      
      paymentServiceName paymentNetworkName paymentNetworkCode outboundRefNo outboundStatus paymentServiceCode
      outboundStatus outboundRefNo remarks

      recipientAddress1 recipientAddress2 recipientCity recipientCountry recipientDateOfBirth recipientEmail recipientFirstName recipientId recipientLastName recipientMiddleName recipientName recipientPhone
    }
  `;

  selectAllFields = gql`
    fragment PayoutSelectAllFields on AdminTransactionType  {
      id transactionId fromName toName receivingCurrency receivingAmount
      created
      userFirstName
      userLastName  
      transactionStatusInfo { 
        statusId, note, icon, name, flagCount, flagTooltip, ticketCount, ticketTooltip
      }
      recipientFirstName recipientLastName  recipientPhone 
      paymentNetworkName paymentNetworkCode outboundRefNo outboundStatus paymentServiceCode
      pickupCode
  }`;

  constructor(protected apollo: Apollo) {
    super(apollo);
    this.mainScope = "payoutsManagement";
    this.initGql('payout');
  }
  setOutboundStatus(data: any) {


    this.resetRefetchQuery();
    this.mutation(
      gql
        `mutation setOutboundStatus( $transactionId: ID!, $status: String!, $note: String, $pickupCode: String ) {
        payoutsManagement {
        __typename
        setOutboundStatus( transactionId: $transactionId, status: $status, note: $note, pickupCode: $pickupCode ){
          id
          outboundStatus
          remarks
          outboundRefNo
          transactionStatusInfo { 
            statusId, note, icon, name, flagCount, flagTooltip, ticketCount, ticketTooltip isWarning isInfo
          }
        }
      }
    }
    `, { transactionId: data?.transactionId, status: data?.status, note: data?.note, pickupCode: data?.pickupCode }
    ).subscribe(data => { });;
  }


  private outboundStatusesWQ = null;
  public outboundStatuses(): any {
    if (!this.outboundStatusesWQ)
      this.outboundStatusesWQ = this.apollo
        .watchQuery({
          query: gql` query outboundStatuses { payoutsManagement { outboundStatuses } } `
        })
    return this.outboundStatusesWQ
      .valueChanges.pipe(
        map((result: any) => result.data && result.data.payoutsManagement && result.data.payoutsManagement.outboundStatuses)
      );
  }
}