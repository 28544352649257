import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { FileType } from 'src/app/_graphql/schema';
import { FilesFragmentBasic } from 'src/app/_graphql/shared-fragments';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication.service';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class AdminFilesService extends BaseService<FileType>{

  selectOneFields = gql`
    fragment FileSelectOneFields on FileType  {
      id ...FilesFragmentBasic
      contentType 
      created 
      description 
      document { id documentNumber }
      id 
      name 
      size 
    }
    ${FilesFragmentBasic}`;

  selectAllFields = gql`
    fragment FileSelectAllFields on FileType  {
      id
      ...FilesFragmentBasic
    }
    ${FilesFragmentBasic}
    `;

  constructor(
    protected apollo: Apollo,
    private auth: AuthenticationService,
    private http: HttpClient
  ) {
    super(apollo);
    this.mainScope = "usersManagement";
    this.initGql('file');
  }

  upload(
    filesToUpload: FileList,
    categoryId: string = null,
    userId: any = null,
    description: string = null,
    ticketId: any = null,
    documentId: any = null,
    refetchQuery: boolean
  ) {
    const endpoint = environment.uploadEndpoint;
    const formData: FormData = new FormData();

    for (let i = 0; i < filesToUpload.length; i++) {
      const fileToUpload = filesToUpload.item(i);
      formData.append('files', fileToUpload, fileToUpload.name);
    }

    //categoryId for file does not exist, will be ignored on backend, should be removed
    //if (categoryId) {
    //  formData.append('categoryId', categoryId);
    //}

    if (userId && this.auth.isAdmin()) {
      formData.append('forUserId', userId);
    }

    if (ticketId) {
      formData.append('ticketId', ticketId);
    }

    if (description) {
      formData.append('description', description);
    }

    if (documentId) {
      formData.append('documentId', documentId);
    }

    return this.http.post(endpoint, formData, { headers: {} }).pipe(
      map((files: any) => {
        if (!refetchQuery) {
          // this.updateFileList(userId, ticketId);
        }
        return files;
      })
    );
  }
  public updateFile(data: any) {
    return this.apollo.mutate({
      mutation: gql
        `mutation updateFile($data: FileInputType!) {
        usersManagement {
          __typename
          updateFile(data: $data) {
            id
            __typename
          }
        }
      }
      `,
      refetchQueries: [
        ...this.refetchAdditionalQueries
      ],
      variables: { data }
    });
  }
  downloadFile(fileId: string, name = 'uploaded_file.png') {
    this.http
      .get(environment.downloadEndpoint, {
        responseType: 'blob',
        params: { id: fileId }
      })
      .subscribe(fileBlob => {
        saveAs(fileBlob, name);
      });
  }
}
