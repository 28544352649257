import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { CountryItemComponent } from './country-item/country-item.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../../_services/authentication.service';
import { AdminCountriesService } from 'src/app/_services/admin/admin-countries.service';
import { Subscribable, Subscription, Unsubscribable } from 'rxjs';
import { CountryType } from 'src/app/_graphql/schema';
import { PublicService } from 'src/app/_services/public.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.scss']
})
export class CountriesComponent implements OnInit, AfterViewInit, OnDestroy {
  items$: Subscribable<CountryType[]>;
  items: CountryType[];

  errors: any[] = [];

  form: UntypedFormGroup = this.fb.group({
    query: [null],
    active: [null],
    isSender: [null],
    isRecipient: [null],
  });
  form$: Subscription;
  $items: Unsubscribable;


  constructor(
    public service: AdminCountriesService,
    public publicService: PublicService,
    private fb: UntypedFormBuilder,
    public auth: AuthenticationService,
    private dialog: MatDialog
  ) {
    this.items$ = this.service.all(this.form.value);
  }

  ngAfterViewInit() {
    // var filters = this.service.getPageRequestAllFilters()
    // this.form.patchValue(filters.pageRequest?.filters, { emitEvent: false })
  }

  ngOnInit() {
    this.getData();
    this.form$ = this.form.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(data => {
      this.getData()
    });
  }

  getData() {
    this.service.queryAllParams.skip = 0;
    this.service.pageIndex = 0;
    this.$items?.unsubscribe();
    this.service.allClose();
    this.$items = this.service.all(this.form.value, true, null, false).subscribe({
      next: res => {
        this.items = res;
      }
    })
  }


  newItem() {
    this.openDialog(CountryItemComponent, false);
  }

  setError(id) {
    this.errors.push(id)
  }

  hasError(id) {
    return this.errors.indexOf(id) > -1
  }

  editItem(item: CountryType) {
    this.openDialog(CountryItemComponent, item);
  }

  openDialog(component: any, param = {}) {
    this.dialog.open(component, {
      height: 'auto',
      width: '480px',
      disableClose: true,
      data: param
    });
  }

  ngOnDestroy(): void {
    this.$items?.unsubscribe();
  }
}
