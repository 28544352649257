import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'toArray' })
export class ToArrayPipe implements PipeTransform {
    transform(list: any[] | null | string, separator: string = ",") {
        try {
            if (typeof (list) == 'string')
                return JSON.parse(list)
        } catch (e) {
            if (typeof (list) == 'string')
                return list.split(separator);
        }


        return list;
    }
}

