import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UiService } from 'src/app/_services/ui.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EndUserService } from 'src/app/_services/user/enduser.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  loading = false;
  disabled = false;
  passwordForm = this.fb.group({
    username: [null, [Validators.required, Validators.email]],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private ui: UiService,
    private auth: AuthenticationService,
    public userService: EndUserService,
    private snack: MatSnackBar
  ) { }

  ngOnInit() { }

  sendCode() {
    this.loading = true;
    this.disabled = true;
    const userData = this.passwordForm.value;

    this.auth.requestResetPasswordEndUser(userData)
      .subscribe((x: any) => {
          this.ui.snack('Reset password code will be sent to your email address.');
          this.router.navigate(['/', 'reset-password', userData.username]);
      },
        error => {
          this.ui.snack('Reset password code will be sent to your email address.');
          this.router.navigate(['/', 'reset-password', userData.username]);
        });
  }
}
