import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SenderSectionComponent } from './sender-section.component';
import { MaterialModule } from 'src/app/material.module';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { CountryFlagModule } from 'src/app/components/country-flag/country-flag.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormConfigsModule } from 'src/app/components/form-configs/form-configs.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    SenderSectionComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    CountryFlagModule,
    TranslateModule,
    FormConfigsModule,
    RouterModule
  ],
  exports: [
    SenderSectionComponent
  ]
})
export class SenderSectionModule { }
