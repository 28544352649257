import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscribable, Subscription, Unsubscribable } from 'rxjs';
import { UiService } from 'src/app/_services/ui.service';
import { MatDialog } from '@angular/material/dialog';

import { debounceTime, distinctUntilChanged } from "rxjs/internal/operators";
import { UntypedFormBuilder, FormControl } from '@angular/forms';
import { AdminTranslationsService } from 'src/app/_services/admin/admin-translations.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { LanguageType, TranslationType } from 'src/app/_graphql/schema';
import { TranslationsItemComponent } from './trans-item/trans-item.component';
import { SystemService } from 'src/app/_services/system.service';
import { AdminLanguagesService } from 'src/app/_services/admin/admin-languages.service';

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss']
})
export class TranslationsComponent implements OnInit, AfterViewInit, OnDestroy {

  items$: Subscribable<TranslationType[]>
  translationTags$: Subscribable<any>
  $form: Subscription;
  $languages: Unsubscribable;
  languages: LanguageType[];
  $items: Unsubscribable;
  items: TranslationType[]

  form = this.fb.group({
    query: [null],
    tags: [null],
  })

  constructor(
    public service: AdminTranslationsService,
    public adminLanguagesService: AdminLanguagesService,
    private fb: UntypedFormBuilder,
    public systemService: SystemService,
    public auth: AuthenticationService,
    public ui: UiService,
    private dialog: MatDialog
  ) {
    this.service.queryAllParams.take = 100;
    this.items$ = this.service.all();
    this.translationTags$ = this.systemService.translationTags();
  }


  ngOnInit() {
    this.getData();
    this.$languages = this.adminLanguagesService.all().subscribe({
      next: x => {
        this.languages = x;
      }
    });
    this.$form = this.form.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(data => {
      this.getData();
    });
  }
  getData() {
    this.service.queryAllParams.skip = 0;
    this.$items?.unsubscribe();
    this.service.allClose();
    this.$items = this.service.all(this.form.value, true, null, false).subscribe({
      next: res=> {
        this.items = res;
      }
    })  
  }
  ngAfterViewInit() {
    // var filters = this.service.getPageRequestAllFilters()
    // this.form.patchValue(filters.pageRequest?.filters, { emitEvent: false })
  }

  openDialog(_key: string = null) {
    const dialogRef = this.dialog.open(TranslationsItemComponent, {
      height: 'auto',
      width: '800px',
      disableClose: true,
      data: { key: _key }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getData();
    });
  }


  public newItem() {
    this.openDialog();
  }
  public editItem(_key: string) {
    this.openDialog(_key);
  }


  ngOnDestroy(): void {
    this.$form?.unsubscribe();
    this.$languages?.unsubscribe();
    this.$items?.unsubscribe();
  }
}
