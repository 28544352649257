<form [formGroup]="myForm" (ngSubmit)="onSubmit()">
    <div *ngIf="noConfigurationLoadedError" class="io-py-3">
        <div *ngIf="country?.name; else noConfigAvailable">
            {{ 'This option is not available for ' | translate }}
            {{country?.name }}.
        </div>
        <ng-template #noConfigAvailable>
            {{ 'This option is currently unavailable. Please try later.' | translate }}
        </ng-template>
    </div>

    <div *ngIf="config?.description">
        <p class="info-note">{{ config?.description }}</p>
    </div>

    <div *ngFor="let field of formData?.fields; index as i" [ngClass]="{'io-pb-2': field.description}">
        <h3
            *ngIf="field.visible && formData?.fields[i].section != '' && (i == 0 || (i>0 && formData?.fields[i].section != formData?.fields[i-1].section))">
            {{ field.section }}</h3>

        <app-single-select placeholder="{{ field.label }}" *ngIf="field.visible && field.type == 'country-select'"
            [items]="(confType=='user' || confType=='updateUser') ? (countries$ | async) :  (countries1$ | async) "
            formControlName="countryId" (selectionChange)="enableButton()" [showFlag]="true"
            (selectionChange)="selectedCountry($event)">
        </app-single-select>
        <app-month-year-date-picker *ngIf="'date' == field?.type 
        && (field?.properties | filterOn: ['value']: ['month-picker'])?.length > 0" [field]="field"
            [formControlName]="field.name" [required]="field?.required">
        </app-month-year-date-picker>
        <mat-form-field appearance="outline" *ngIf="field.visible && field.type == 'text'">
            <mat-label>{{ field.label }}</mat-label>
            <input matInput [type]="field.type" [formControlName]="field.name" [value]="field.value" />
            <mat-error align="end">{{showErrors(field.name)}}</mat-error>
            <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="field.visible && field.type == 'numbers'">
            <mat-label>{{ field.label }}</mat-label>
            <mat-error align="end">{{showErrors(field.name)}}</mat-error>
            <input matInput [type]="field.type" [formControlName]="field.name" [value]="field.value"
                [ngStyle]="{'padding-bottom.px': (myForm.get(field.name).value == '' || myForm.get(field.name).value == null  ? 0: 4)}" />
            <span matPrefix disabled *ngIf="field.name == 'phone' && myForm.get(field.name).value" style="display: block;
                padding: 4pt 5pt 4.5pt 0pt;
                opacity: 0.4;">
                +{{country?.phonePrefix}}
            </span>
            <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
            <button mat-icon-button *ngIf="field.name == 'phone'" matSuffix disabled>
                <mat-icon>phone</mat-icon>
            </button>

        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="field.visible && field.type == 'gender'">
            <mat-label>{{ field.label }}</mat-label>
            <mat-select [formControlName]="field.name">
                <mat-option value="M">{{ 'Male' | translate }}</mat-option>
                <mat-option value="F">{{ 'Female' | translate }}</mat-option>
            </mat-select>
            <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="field.visible && field.type == 'email'">
            <mat-label>{{ field.label }}</mat-label>
            <input matInput [type]="field.type" [formControlName]="field.name" [value]="field.value"
                autocomplete="username" />
            <mat-error align="end">{{showErrors(field.name)}}</mat-error>
            <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="field.visible && field.type == 'date' &&
            (field?.properties | filterOn: ['value']: ['month-picker'])?.length === 0">
            <mat-label>{{ field.label }}</mat-label>
            <input matInput [matDatepicker]="picker" [formControlName]="field.name" [value]="field.value"
                [min]="getMin(field?.max)" [max]="getMax(field?.min)" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
        </mat-form-field>
        <mat-form-field *ngIf="field.type === 'address-autocomplete'">
            <mat-label>{{ field.label }}</mat-label>
            <input type="text" matInput [formControlName]="field.name" [matAutocomplete]="autoGroup">
            <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="addressChoosen($event, field.name)">
                <mat-option *ngFor="let element of addressData | async" [value]="element" class="autoCompleteOption">
                    <a>{{ element['description'] | translate }}</a>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <!-- <mat-form-field *ngIf="'select' == field?.type">
            <mat-label>{{field?.label}}
            </mat-label>
            <mat-select [formControlName]="field.name">
                <mat-option *ngFor="let _v of field.values" [value]="_v.value">{{ _v.label |
                    translate }}</mat-option>
            </mat-select>
            <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
        </mat-form-field> -->
        <app-single-select placeholder="{{ field.label }}" *ngIf="field.visible && field?.type == 'select'"
            [items]="field?.values" [formControlName]="field.name" [optionValue]="['label', 'value']"
            [hintText]="field?.description">
        </app-single-select>
        <div *ngIf="'radio' == field?.type">
            <div style="margin-top: 24px; margin-bottom: 12px;">{{field?.label}}
                <span *ngIf="field?.required">*</span>
                <div *ngIf="field?.description">
                    <small>{{ field.description }}</small>
                </div>
            </div>
            <mat-radio-group [formControlName]=" field.name">
                <div *ngFor="let _v of field.values">
                    <mat-radio-button color="primary" [value]="_v.value" style="margin-bottom: 8px;">{{ _v.label
                        |
                        translate }}</mat-radio-button>
                </div>
            </mat-radio-group>
        </div>

        <mat-form-field appearance="outline" *ngIf="field.visible && field.type == 'relationship-select'">
            <mat-label>{{ field.label }}</mat-label>

            <mat-select [formControlName]="field.name">
                <mat-option [value]="relationship.id" *ngFor="let relationship of relationships | async"
                    [disabled]="!relationship.isActive">

                    {{ relationship.name | translate }}
                </mat-option>
            </mat-select>
            <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
        </mat-form-field>

        <div *ngIf="field.visible && field.type == 'password'">
            <mat-form-field appearance="outline"
                hintLabel="{{'Needs to contain at least one lower, upper and one digit character.' | translate}}">
                <mat-label>{{ 'Password' | translate }}</mat-label>
                <input matInput #password [type]="field.type" [formControlName]="field.name" minlength="field.minlength"
                    [type]="hide ? 'password' : 'text'" />
                <button mat-icon-button matSuffix type="button" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
                <mat-hint align="end">{{password.value.length}} / {{ 6 }}</mat-hint>
                <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>

            </mat-form-field>
            <app-password-strength [hasNumber]="true" [hasUpper]="true" [hasLower]="true"
                [userPassword]="this.myForm.get(field.name)">
            </app-password-strength>
            <mat-form-field appearance="outline">
                <mat-label>{{ 'Confirm Password' | translate }}</mat-label>
                <input matInput (keyup)="confPassword($event)" required appConfirmEqualValidator="password"
                    type="password" min="6" />
            </mat-form-field>

        </div>

        <div class="checkbox" *ngIf="'moov-terms-token'==field?.type">
            <mat-checkbox color="primary" matInput [formControlName]="field.name"
                (change)="getMoovTermsToken(myForm.get(field.name))">
                <p class="checkbox checkbox-text" innerHTML="{{field?.label | translate}}">
                </p>
            </mat-checkbox>
        </div>

        <!-- <mat-form-field appearance="outline" *ngIf="field.visible && field.type == 'state-select'">
            <mat-label>{{ field.label }}</mat-label>
            <mat-select [formControlName]="field.name">
                <mat-option *ngFor="let state of states | async" [value]="state.id">{{state.name}}</mat-option>
            </mat-select>
            <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
        </mat-form-field> -->
        <app-single-select placeholder="{{ field.label }}" *ngIf="field.visible && field.type == 'state-select'"
            [items]="states | async" [formControlName]="field.name" [hintText]="field?.description">
        </app-single-select>

        <div class="checkbox" *ngIf="field.visible && field.type == 'checkbox'">
            <mat-checkbox color="primary" matInput [formControlName]="field.name">
                <p [ngClass]="{'checkbox-text':true, 'error':myForm.get(field.name).errors?.required &&
                myForm.get(field.name).touched}" [innerHTML]=" field.label | translate"></p>
            </mat-checkbox>
        </div>

        <div class="checkbox" *ngIf="field.visible && field.type == 'checkboxstring'">
            <mat-checkbox color="primary" matInput [formControlName]="field.name" [value]="field.label"
                (change)="onChangee($event)">
                <p class="checkbox">
                    <span innerHTML="{{field?.label | translate}}"></span>
                </p>
            </mat-checkbox>
        </div>

        <div *ngIf="field.visible && field.type == 'file-upload'" class="io-row align-items-center no-gap"
            style="font-size: 10pt">

            <div>{{ field.label }}
                <span *ngIf="field?.required">*</span>
                <small style="display:block; opacity: 0.64;" *ngIf="field?.description">{{
                    field.description }}</small>
                <input hidden type="file" #uploader (change)="handleFileInput($event.target.files, field.name)"
                    [accept]="field?.pattern" />
            </div>
            <div *ngIf="!myForm.get(field.name).value">
                <button type="button" mat-icon-button (click)="uploader.click()">
                    <mat-icon color="primary">attachment</mat-icon>
                </button>
            </div>

            <div *ngIf="myForm.get(field.name).value" class="uploaded">{{
                filesUploaded[myForm.get(field.name).value].name }}</div>
            <button *ngIf="myForm.get(field.name).value" mat-icon-button type="button" class="no-flex"
                (click)="removeImage(field.name)">
                <mat-icon color="warn">close</mat-icon>
            </button>
        </div>

    </div>

    <!-- <div class="checkbox" *ngIf="fieldsGroup === 'user'">
        <mat-checkbox color="primary" matInput required (change)="onChange($event)"></mat-checkbox>
        <p class="checkbox-text" [ngClass]=" checkbox ? 'agreeFalse' : 'agreeTrue' "
            [innerHTML]="'iagree.register.checkbox' | translate"></p>
    </div> -->
</form>
<!-- <pre>{{ myForm.value | json }}</pre> -->