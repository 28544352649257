<div *ngIf="(item$|async) as item">
  <div class="io-item io-item-shadow">
    <div class="main">
      <h4>{{ item?.transactionId }}</h4>
      <button mat-icon-button (click)="getBack()" class="no-flex">
        <mat-icon mat-icon-button>keyboard_arrow_left</mat-icon>
      </button>
      <div class="data country">
        <small matTooltip="{{'From'|translate}}">{{item?.fromName}}</small>
        <span matTooltip="{{'To'|translate}}">{{item?.toName}}</span>
      </div>
      <div class="data money">
        <small>{{'Payout'|translate}}</small>
        <span>{{ item?.receivingCurrency }} {{ item?.receivingAmount | number: '1.2-2' }}
        </span>
      </div>
      <app-transaction-status [iconOnly]="true" [status]="item?.transactionStatusInfo" class="io-mr-1">
      </app-transaction-status>
      <div class="data status">
        <small>{{ 'Status' | translate }}</small>
        <span>
          <aside matTooltip="{{ ('transaction_'+item?.transactionStatusInfo?.name?.replace(' ', '_'
            )?.toUpperCase()+'_info') | translate }}">{{item?.transactionStatusInfo?.name}}</aside>
          <s matTooltip="{{ item?.created | timezone | date: 'short' }}">
            {{ item?.created | dateAgo }}
            <aside *ngIf="!auth.isAdmin()">{{ item?.transactionStatusInfo?.note | translate }}</aside>
          </s>
        </span>
      </div>
      <app-outbound-status *ngIf="auth.hasPermission(auth.perm.TransactionOutboundStatusManage)" [iconOnly]="true"
        [status]="item.outboundStatus">
      </app-outbound-status>
      <div class="data outbound">
        <small>{{'Outbound'|translate}}</small>
        <span>
          {{item?.paymentNetworkName }}
          <s *ngIf="item?.pickupCode">Pickup Code: {{item?.pickupCode}}</s>
        </span>
      </div>

      <div class="data ">
        <div class="io-row  width-100">
          <button mat-stroked-button color="primary" *ngIf="item.paymentNetworkCode === 'MANUAL'" [disabled]="'Approved'
            !=item.outboundStatus" (click)=" mls.outboundUpdateStatus(item, 'Delivered' , pickupCode)">
            <span>{{
              'Deliver'
              | translate }}</span>
          </button>
          <button mat-raised-button color="warn" *ngIf="'MANUAL'==item.paymentNetworkCode"
            [disabled]="'Approved'!=item.outboundStatus"
            (click)="mls.outboundUpdateStatus(item, 'Stopped', pickupCode)">
            <span>{{
              'Stop'
              | translate }}</span>
          </button>
        </div>
      </div>

    </div>
  </div>
  <div class="io-row-l io-mx-2 io-my-2">
    <div class="flex-3">
      <mat-card class="io-mb-2">
        <mat-card-title>{{ 'Amount Info' | translate }}</mat-card-title>
        <mat-card-content>
          <div class="io-row-l align-items-start">
            <table class="table-details">
              <tbody>
                <tr>
                  <th>{{ 'Created' | translate}}</th>
                  <td>{{ item?.created | timezone2 | date:'short' }}</td>
                </tr>
                <tr>
                  <th>{{ 'Receiving amount'| translate }}</th>
                  <td>{{ item?.receivingCurrency }} {{ item?.receivingAmount | number: '1.2-2' }}</td>
                </tr>
                <tr>
                  <th>Transaction ID</th>
                  <td>{{ item?.transactionId }}</td>
                </tr>
              </tbody>
            </table>

            <div></div>
            <div></div>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="io-mb-2">
        <mat-card-title>{{ 'Outbound' | translate }}</mat-card-title>
        <mat-card-content>
          <div class="io-row-l align-items-start">
            <table class="table-details">
              <tbody>
                <tr>
                  <th>{{ 'Outbound' }}</th>
                  <td>{{ item?.paymentNetworkName | noData:'—' }}</td>
                </tr>
                <tr>
                  <th>{{ 'Payment Service' }}</th>
                  <td>{{ item?.paymentServiceName | noData:'—'}}</td>
                </tr>
                <tr>
                  <th>{{ 'Status' }}</th>
                  <td>{{ item?.outboundStatus | noData:'—' }}</td>
                </tr>
              </tbody>
            </table>
            <table class="table-details">
              <tbody>
                <tr>
                  <th>{{ 'Ref number' }}</th>
                  <td>{{ item?.outboundRefNo | noData:'—' }}</td>
                </tr>
                <tr *ngIf="item?.pickupCode">
                  <th>{{ 'Pickup Code' }}</th>
                  <td>{{ item?.pickupCode | noData:'—' }}</td>
                </tr>
                <tr>
                  <th>{{ 'Remarks' }}</th>
                  <td>
                    <span *ngIf="!item?.remarks">—</span>
                    <div *ngFor="let item of item?.remarks?.split('\\n')">{{item.replace("\\r", "")}}</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div></div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="flex">
      <div class="io-mb-2">
        <mat-expansion-panel class="sender-receiver-card" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="io-row align-items-center" style="width:100%">
                <app-country-flag [item]="{isoCode3: item?.fromIsoCode3 }" class="no-flex"
                  size="big"></app-country-flag>
                <div>
                  <small>{{ 'Sender' | translate }}</small>
                  {{ item?.userFirstName}} {{ item?.userLastName}}
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      </div>
      <div class="io-mb-2">
        <mat-expansion-panel class="sender-receiver-card" [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="io-row align-items-center" style="width:100%">
                <app-country-flag [item]="{isoCode3: item?.toIsoCode3 }" class="no-flex" size="big"></app-country-flag>
                <div>
                  <small>{{ 'Recipient' | translate }}</small>
                  {{ item?.recipientName | noData:'—' }}
                </div>
              </div>
            </mat-panel-title>

          </mat-expansion-panel-header>
          <table class="table-details">
            <tbody>
              <tr>
                <th>{{ 'Email' | translate }}</th>
                <td>{{ item?.recipientEmail | noData:'—' }}</td>
              </tr>
              <tr>
                <th>{{ 'Phone' | translate }}</th>
                <td>{{ item?.recipientPhone | noData:'—' }}</td>
              </tr>
              <tr>
                <th>{{ 'Date of birth' | translate }}</th>
                <td>{{ item?.recipientDateOfBirth | date | noData:'—' }}</td>
              </tr>
              <tr>
                <th>{{ 'Address' | translate }}</th>
                <td>
                  {{ item?.recipientAddress1 }} {{item?.recipientAddress2 }}
                  {{item?.recipientCity }}, {{item?.recipientCountry }}

                </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </div>


    </div>
  </div>
</div>