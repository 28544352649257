import { Component, OnInit, Input } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { UiService } from 'src/app/_services/ui.service';


@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss']
})
export class NotesListComponent implements OnInit {

  @Input() items: any[];

  customCollapsedHeight = '100%';
  customExpandedHeight = '100%';

  constructor(
    private dialog: MatDialog,
    private ui: UiService,
  ) { }

  ngOnInit() { }


}
