import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscribable, Unsubscribable } from 'rxjs';
import { UiService } from 'src/app/_services/ui.service';
import { AdminEndUserType, DeleteAccountResponseType, FlagType, RaisedFlagType, TicketType } from 'src/app/_graphql/schema';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { AdminFlagsService } from 'src/app/_services/admin/admin-flags.service';
import { AdminUsersService } from 'src/app/_services/admin/admin-users.service';
import { AdminTransactionsService } from 'src/app/_services/admin/admin-transactions.service';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-admin-flag-item',
  templateUrl: './admin-flag-item.component.html',
  styleUrls: ['./admin-flag-item.component.scss']
})
export class AdminFlagItemComponent implements OnInit, OnDestroy {
  itemId: string;
  item$: Subscribable<RaisedFlagType>
  user$: Subscribable<AdminEndUserType>;
  deleteAccountSteps$: Subscribable<any>;
  $flagOneUnsub: Unsubscribable;
  userId: string;
  deleteButtonsDisabled = false;

  constructor(
    public service: AdminFlagsService,
    public adminTicketsService: AdminFlagsService,
    public adminTransactionsService: AdminTransactionsService,
    public mls: ModalLauncherService,
    public auth: AuthenticationService,
    private route: ActivatedRoute,
    public adminUsersService: AdminUsersService,
    private ui: UiService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.itemId = this.route.snapshot.paramMap.get('flagId');
    this.item$ = this.service.one(this.itemId);

  }
  ngOnInit(): void {
    this.$flagOneUnsub = this.service.one(this.itemId).subscribe({
      next: flag => {
        this.userId = flag?.user?.id;
        if (flag.flag.code === 'DELETE_ACCOUNT_REQUESTED' && flag?.user)
          this.deleteAccountSteps$ = this.adminUsersService.deleteAccountSteps(flag.user?.id);
      }
    })
  }
  ngOnDestroy(): void {
    this.$flagOneUnsub?.unsubscribe();
    this.service.refetchData();
    if (this.route.snapshot.paramMap.get('userId')) {
      this.adminTicketsService.refetchData('tickets4' + this.route.snapshot.paramMap.get('userId'));
      this.service.refetchData('flags4' + this.route.snapshot.paramMap.get('userId'));
    }
  }
  deleteAccount() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px',
      data: {
        title: 'Delete Account',
        content: "This action is irreversible. Are you sure you want to delete user's account?",
        note: '',
      },
      disableClose: true,
      maxHeight: '80%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.deleteButtonsDisabled = true;
      if (result) {
          (this.adminUsersService.deleteUserAccount(this.userId) as Observable<any>).pipe(take(1)).subscribe({
          next: (res) => {
            this.deleteButtonsDisabled = false;
            if (res.data?.usersManagement?.deleteUserAccount?.status === 'SUCCESS') {
              this.ui.snack('Account successfully deleted.');
              if (this.route.snapshot.paramMap.get('userId')) {
                this.router.navigate(['admin', 'users']);
              } else {
                this.router.navigate(['admin', 'tickets-and-flags', 'flags']);
              }
            } else {
              this.ui.snack(res?.infoMessage ? res?.infoMessage : 
                'Check info messages on the accounts. After completing, press Delete Account button again.', 6000);
                this.service.refetchOneData(this.itemId);
              }
            this.adminUsersService.refetchOneData(this.itemId);
            if (this.route.snapshot.paramMap.get('userId'))
              this.service.fetchMoreData({'userId': this.route.snapshot.paramMap.get('userId')});
            this.deleteButtonsDisabled = false;
          },
          error: () => this.deleteButtonsDisabled = false
        });
      } else {
        this.deleteButtonsDisabled = false;
      }
    });
 
  }
  onFileUploaded() {
    this.service.refetchOneData(this.itemId)
  }
}
