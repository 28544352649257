<list-wrapper #wrapper [mainService]="service" panelTitle="Managers" [managePerm]="auth.perm.ManagersManage"
  [readPerm]="auth.perm.ManagersRead" (onNew)="newItem()" (onEdit)="editItem($event)">
  <form [formGroup]="form" class="io-row-m width-100" toolbox style="align-items: center">
    <mat-form-field class="width-100 width-mff" appearance="outline">
      <mat-label>{{ 'Search' | translate }}</mat-label>
      <input matInput formControlName="query" placeholder="{{ 'Name or username'|translate }}">
      <button mat-icon-button matSuffix type="button" (click)="form.patchValue({'query': null})"
        [disabled]="!form.get('query').value">
        <mat-icon *ngIf="form.get('query').value">clear</mat-icon><mat-icon
          *ngIf="!form.get('query').value">search</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="width-100 width-mff" appearance="outline"
      *ngIf="auth.hasPermission(auth.perm.ManagerRestriction)">
      <mat-label>{{'Manager type' | translate}}</mat-label>
      <mat-select formControlName="type">
        <mat-option [value]="null">{{'All'|translate}}</mat-option>
        <mat-option [value]="item.key" *ngFor="let item of nameRoles$ | async">
          {{ item.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <div class="io-py-1">
    <app-admins-list-item *ngFor="let item of items" [item]="item" (click)="wrapper.edit(item)"
      [ngClass]="{'io-disabled': !item.isActive}">
    </app-admins-list-item>
  </div>

</list-wrapper>