import { Component, OnInit, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { Subscribable, Subscription, Unsubscribable } from 'rxjs';
import { AdminTicketsService } from 'src/app/_services/admin/admin-tickets.service';
import { TicketType } from 'src/app/_graphql/schema';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { TicketsComponentConfig } from 'src/app/_modules/transactions.module';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-admin-tickets',
  templateUrl: './admin-tickets.component.html',
  styleUrls: ['./admin-tickets.component.scss']
})
export class AdminTicketsComponent implements OnDestroy, AfterViewInit, OnInit {
  @Input() componentConfig: TicketsComponentConfig;

  items$: Subscribable<TicketType[]>;
  $form: Subscription;
  tickets$: Subscription;
  tickets: any[];
  form: UntypedFormGroup;


  uid: string;
  customKey: string;
  $items: Unsubscribable;
  items: TicketType[];



  statuses = [
    { id: 'INITIATED', name: 'Initiated' },
    { id: 'RESPONDED', name: 'Responded' },
    { id: 'MORE_INFO_NEEDED', name: 'More Info Needed' },
    { id: 'CLOSED', name: 'Closed' }
  ];

  constructor(
    public service: AdminTicketsService,
    private fb: UntypedFormBuilder,
    public auth: AuthenticationService,
    private router: Router,
    private ui: UiService,
    private route: ActivatedRoute,
  ) {
    if (this.route.snapshot.data?.config) {
      this.componentConfig = this.route.snapshot.data?.config;
    }

    this.form = this.fb.group({
      query: [''],
      userId: [this.route.snapshot.paramMap.get('userId')],
      statuses: [[]],
      notResolved: [false],
      transactionId: [this.route.snapshot.paramMap.get('transactionId')]
    });
    // this.uid = this.form.get('userId').value;
    // this.customKey = this.uid ? 'tickets4' + this.uid : null;
    this.ui.setTitle("Tickets");
    var fd = this.router.getCurrentNavigation()?.extras?.state?.formData;
    if (fd) {
      this.form.patchValue(fd)
    }
  }
  ngAfterViewInit() {
    // var filters = this.service.getPageRequestAllFilters()
    // this.form.patchValue(filters.pageRequest?.filters, { emitEvent: false })
  }

  ngOnInit() {
    this.getData();
    this.items$ = this.service.all(this.form.value, true, null);
    this.$form = this.form.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(data => {
      this.getData()
    });
  }
  getData() {
    this.service.queryAllParams.skip = 0;
    this.service.pageIndex = 0;
    this.$items?.unsubscribe();
    this.service.allClose();
    this.$items = this.service.all(this.form.value, true, null, false).subscribe({
      next: res => {
        this.items = res;
      }
    })

  }


  editItem(ticket: any) {
    this.router.navigate(['.', 'ticket', ticket.id], { relativeTo: this.route })
  }

  ngOnDestroy(): void {
    this.$form?.unsubscribe();
    this.$items?.unsubscribe();
  }
}
