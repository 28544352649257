import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RiskAssessmentComponent } from './risk-assessment.component';
import { RiskAssessmentDialogComponent } from './risk-assessment-dialog/risk-assessment-dialog.component';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/material.module';



@NgModule({
  declarations: [
    RiskAssessmentComponent,
    RiskAssessmentDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
  ],
  exports: [
    RiskAssessmentComponent,
    RiskAssessmentDialogComponent
  ]
})
export class RiskAssessmentModule { }
