import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Observable, Subscribable, Subscription } from 'rxjs';
import { DrawerService } from '../../wrapper/drawer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { TransactionsComponentConfig } from 'src/app/_modules/transactions.module';
import { CountryType, Recipient, TransactionStatusType } from 'src/app/_graphql/schema';
import { PublicService } from 'src/app/_services/public.service';
import { SystemService } from 'src/app/_services/system.service';
import { AdminPayoutsService } from 'src/app/_services/admin/admin-payouts.service';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-admin-payout-transaction-item',
  templateUrl: './payout-transaction-item.component.html',
  styleUrls: ['./payout-transaction-item.component.scss']
})
export class AdminPayoutTransactionItemComponent implements OnInit {
  item$: Subscribable<any>;
  pickupCode: string = null;
  countriesSender$: Subscribable<CountryType>;
  countriesRecipient$: Subscribable<CountryType>;
  recipients$: Subscribable<Recipient[]>;
  transactionStatuses$: Subscribable<TransactionStatusType>;
  maxDate = new Date();
  @Input() componentConfig: TransactionsComponentConfig;




  constructor(
    public service: AdminPayoutsService,
    public systemService: SystemService,
    public publicService: PublicService,
    public auth: AuthenticationService,
    public mls: ModalLauncherService,
    private drawerService: DrawerService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private ui: UiService

  ) {
    service.permissionProtected = auth.hasPermission(auth.perm.TransactionOutboundStatusManage)
    if (route.snapshot.data?.config) {
      this.componentConfig = route.snapshot.data?.config;
    }

    this.transactionStatuses$ = this.systemService.transactionStatuses();
    this.countriesSender$ = this.publicService.countries(true, null, null)
    this.countriesRecipient$ = this.publicService.countries(null, true, null)


  }

  getBack() {
    if (this.auth.isAdmin())
      this.drawerService.open();
    window.history.back();
  }
  displayError(content) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px',
      data: {
        title: 'Transaction Issue',
        content: content,
        note: '',
        rejectLabel: 'Cancel',
        hideYesButton: true,
      },
      disableClose: true,
      maxHeight: '80%'
    });

    dialogRef.afterClosed().subscribe(() => {
        this.router.navigate(['/', 'admin', 'payouts']);
    });
  }
  ngOnInit() {
    // this.drawerService.sendState.next(false);
    this.pickupCode = this.route.snapshot.paramMap.get('pickupCode');
    this.item$ = this.service.one(this.route.snapshot.paramMap.get('transactionId'));
    (this.service.one(this.route.snapshot.paramMap.get('transactionId')) as Observable<any>).pipe(take(1)).subscribe({
      error: err => {
        this.ui.dismiss();
        this.displayError(err.message);
      }
    })
  }
}
