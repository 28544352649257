import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiService } from 'src/app/_services/ui.service';
import { AdminFlagsService } from 'src/app/_services/admin/admin-flags.service';
import { SystemService } from 'src/app/_services/system.service';
import { AdminUsersService } from 'src/app/_services/admin/admin-users.service';
import { AdminTicketsService } from 'src/app/_services/admin/admin-tickets.service';
import { CustomValidators } from 'src/app/_helpers/custom.validators';

@Component({
  selector: 'app-flag-raise',
  templateUrl: './flag-raise.component.html',
  styleUrls: ['./flag-raise.component.scss']
})
export class FlagRaiseComponent implements OnInit {
  flags$: any;
  form = this.fb.group({
    transactionId: [this.data.transactionId],
    userId: [null],
    forUserId: [this.data.userId],
    flagId: [null, Validators.required],
    description: ['', [Validators.required, CustomValidators.noWhitespaceValidator()]],
    raiseTicket: false
  });

  constructor(
    private dialogRef: MatDialogRef<FlagRaiseComponent>,
    private ui: UiService,
    private service: AdminFlagsService,
    private adminTicketsService: AdminTicketsService,
    private userService: AdminUsersService,
    private systemService: SystemService,
    private fb: UntypedFormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any = {}
  ) {

    if (this.data.transactionId) {
      this.form.controls.transactionId.setValue(this.data.transactionId);
    } else if (this.data.userId) {
      this.form.controls.userId.setValue(this.data.userId);
    } else {

    }
  }

  ngOnInit() {
    this.flags$ = this.systemService.flags(this.data.transactionId ? "TRANSACTION" : (this.data.userId ? "USER" : null));
  }

  setFlag(raiseTicket: boolean = false) {
    this.form.controls.raiseTicket.setValue(raiseTicket);
    var s = this.service.raiseFlag(this.form.value).subscribe({
      next: x => {
        s.unsubscribe();
        this.userService.refetchOneData(this.data.userId);
        if (raiseTicket) {
          this.ui.snack('Flag raised and ticket created.');
          this.adminTicketsService.refetchData();
        } else
          this.ui.snack('Flag raised.');
        this.dialogRef.close();
      }, error: e => {
        s.unsubscribe();
        this.ui.snack('Error in raising flag.');
      }
    }
    );
  }
}

