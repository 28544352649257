import { Component, OnInit, OnDestroy, Inject, ViewChild, ElementRef, AfterContentInit, AfterViewInit, AfterViewChecked, QueryList, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiService } from '../../../_services/ui.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { MessagesService } from 'src/app/_services/messages.service';
import { Unsubscribable } from 'rxjs';
import { TicketType } from 'src/app/_graphql/schema';
import { UserTicketsService } from 'src/app/_services/user/tickets.service';
import { PublicService } from 'src/app/_services/public.service';


@Component({
  selector: 'app-ticket-item',
  templateUrl: './ticket-item.component.html',
  styleUrls: ['./ticket-item.component.scss']
})
export class TicketItemComponent implements OnDestroy, AfterViewInit {

  @ViewChildren('scrollMe') scrollMe!: QueryList<ElementRef>;
  $scrollMe: Unsubscribable;

  transactionId: string;
  ticketId: string;
  receiverId: string;


  $ticket: Unsubscribable;
  ticket: TicketType;


  messages$: any;
  messageText: string;
  sendingMessage = false;
  filesUploaded: any[] = [];

  fileId: any;
  fileSend = false;
  addedFiles: any

  constructor(
    private ui: UiService,
    private messagesService: MessagesService,
    private publicService: PublicService,
    private ticketsService: UserTicketsService,
    public auth: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TicketItemComponent>
  ) {

    this.ticketId = this.data?.ticketId || null
    this.receiverId = this.data?.userId || null

    this.$ticket = this.ticketsService.one(this.ticketId).subscribe(ticket => {
      this.ticket = ticket;
    });
    this.messages$ = this.messagesService.all(this.ticketId);
    this.messagesService.markMessagesSeen(this.ticketId);
  }
  ngOnDestroy(): void {
    this.$ticket?.unsubscribe();
    this.$scrollMe?.unsubscribe();
  }

  isJSON(txt: any) {
    try {
      let a = JSON.parse(txt);
      if (typeof a !== 'object')
        return false;

      return a == null ? false : a;
    } catch (e) {
    }
    return false;
  }

  ngAfterViewInit(): void {
    this.$scrollMe = this.scrollMe.changes.subscribe(() => {
      const content = this.scrollMe.toArray();
      if (content.length > 0) {
        const lastItem = content[content.length - 1];
        lastItem.nativeElement.scrollIntoView({});
      }
    });
  }

  isDisabled(): boolean {
    return (this.sendingMessage || (((this.messageText || '') == '') && (this.filesUploaded.length == 0)));
  }
  sendMessage(fileId: any = null) {
    if (this.isDisabled())
      return;
    this.sendingMessage = true;
    const message: any = {
      text: this.messageText,
      transactionId: this.transactionId,
      ticketId: this.ticketId,
      receiverId: this.receiverId,
    };

    document.getElementById('message').addEventListener('keypress', function (event) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    });

    if (this.filesUploaded.length) {
      this.messagesService
        .upload(this.addedFiles, null, this.receiverId, null, this.ticketId, null, false)
        .subscribe((uploadedFiles: any[]) => {
          if (uploadedFiles.length) {
            uploadedFiles.forEach(file => {
              message.fileId = file.id;
              this.transferMessage(message);
              message.text = '';
            });

            this.filesUploaded = [];
          }
        });
    } else {
      this.transferMessage(message);
    }
  }

  transferMessage(message: any) {
    this.sendingMessage = true;
    var s = this.messagesService.sendMessage(message).subscribe({
      next: x => {
        this.sendingMessage = false;
        this.messageText = '';
        this.messagesService.refetchData();
        this.ticketsService.refetchOneData(this.ticketId);
      },
      error: e => {
        this.sendingMessage = false;
      }
    });
  }

  handleFileInput(files: any) {
    if (files.length === 0) {
      return;
    }

    this.addedFiles = files;

    const index = this.filesUploaded.length;
    for (let i = 0; i < files.length; i++) {
      files[i].uploaded = false;
      this.filesUploaded[index + i] = files[i];
    }

  }

  deleteFile(fileId) {
    if (this.filesUploaded.length === 0) {
      return;
    }

    this.filesUploaded = this.filesUploaded.filter(
      file => file.id !== fileId
    );
  }

  downloadFile(file: any) {
    this.publicService.downloadFile(file.id, file.name ? file.name : '');
  }
}
