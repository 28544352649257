<div style="min-height: 4px; position: absolute;">
  <mat-progress-bar *ngIf="loading" mode="query"></mat-progress-bar>
</div>
<div class="container2">
  <div class="center">
    <form class="login" [formGroup]="authForm" (submit)="login()">

      <h2>Log In Admin</h2>

      <mat-form-field appearance="outline">
        <mat-label>Username</mat-label>
        <input matInput type="text" id="username" formControlName="username" name="username" autocomplete="username" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput type="password" id="password" formControlName="password" name="password" minlength="4"
          [type]="hide ? 'password' : 'text'" (keyup.enter)="login()" autocomplete="current-password" />
        <button mat-icon-button type="button" matSuffix [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon (click)="hide = !hide">{{
            hide ? 'visibility_off' : 'visibility'
            }}</mat-icon>
        </button>
      </mat-form-field>

      <div class="btn">
        <button mat-raised-button class="login-btn" color="primary" [disabled]="!authForm.valid || disabled">
          Log In
        </button>
      </div>
    </form>
  </div>
</div>