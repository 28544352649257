import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PublicService } from 'src/app/_services/public.service';


@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.scss']
})
export class CountriesComponent implements AfterViewInit {

  @Input() public country: string;

  more = [
    {
      img: '/assets/images/mobile.png',
      title: 'page.countries.feature_title1',
      content: 'page.countries.feature_content1'
    },
    {
      img: '/assets/images/bank.png',
      title: 'page.countries.feature_title2',
      content: 'page.countries.feature_content2'
    },
    {
      img: '/assets/images/pickup.png',
      title: 'page.countries.feature_title3',
      content: 'page.countries.feature_content3'
    }
  ];

  public currentCountryFrom: any = { name: 'banner' };
  public currentCountryTo: any = { name: 'banner' };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: PublicService
  ) { }

  ngAfterViewInit() {

    const countryFrom = this.route.snapshot.paramMap.get('countryFrom');
    const countryTo = this.route.snapshot.paramMap.get('countryTo');

    if (countryFrom) {
      this.service.countries(true, false, true).subscribe(countries => {
        this.currentCountryFrom = countries.find(x => x.name === countryFrom);
      });
    }

    if (countryTo) {
      this.service.countries(false, true, true).all().subscribe(countries => {
        this.currentCountryTo = countries.find(x => x.name === countryTo);
      });
    }
  }

  // Changing flags
  changedCountryTo($event) {
    this.currentCountryTo = $event;
  }
}
