import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabTopWrapperComponent } from './tab-top-wrapper/tab-top-wrapper.component';
import { TabTopWrapperComponent as TabTopWrapperComponentDisabler } from './tab-top-wrapper-disabler/tab-top-wrapper.component';
import { RouterModule } from '@angular/router';
import { MatStepperModule } from '@angular/material/stepper';
import { FilterExtractValues } from '../_pipes/filter-extract-values.pipe';
import { PipesModule } from '../_pipes/pipes.module';
import { DialogsModule } from './confirmation-dialog/dialogs.module';
import { FilesModule } from './files/files.module';
import { FormConfigsModule } from './form-configs/form-configs.module';
import { LoginModule } from '../public/login/login.module';
import { MessagesModule } from './messages/messages.module';
import { PanelModule } from './panel/panel.module';
import { TicketsModule } from './tickets/tickets.module';
import { TransactionsModule } from './transactions/transactions.module';
import { ListWrapperModule } from '../admin/list-wrapper/list-wrapper.module';
import { StatusesModule } from './statuses/statuses.module';
import { ChatModule } from './chat/chat.module';
import { CountryFlagModule } from './country-flag/country-flag.module';
import { AccountsModule } from '../platform/accounts/accounts.module';
import { SingleSelectModule } from './select-country/single-select.module';
import { MultiselectSearchComponent } from './select-search/multiselect-search.component';
import { FormDataDisplayConfigurationComponent } from './form-configs/form-data-display-configuration/form-data-display-configuration.component';
import { InputCodeModule } from './input-code/input-code.module';
import { InputCodeComponent } from './input-code/input-code.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { ImageContainerComponent } from './image-container/image-container.component';


@NgModule({
  declarations: [
    TabTopWrapperComponent,
    TabTopWrapperComponentDisabler,
    MultiselectSearchComponent,
    DeleteAccountComponent,
    ImageContainerComponent,
  ],
  imports: [
    RouterModule,
    MatStepperModule,
    CommonModule,
    PipesModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    DialogsModule,
    FilesModule,
    FormConfigsModule,
    SingleSelectModule,
    LoginModule,
    MessagesModule,
    PanelModule,
    TicketsModule,
    TransactionsModule,
    ListWrapperModule,
    StatusesModule,
    ChatModule,
    CountryFlagModule,
    AccountsModule,
    InputCodeModule
  ],
  exports: [
    FilterExtractValues,
    DialogsModule,
    FilesModule,
    FormConfigsModule,
    SingleSelectModule,
    MessagesModule,
    PanelModule,
    TicketsModule,
    TransactionsModule,
    StatusesModule,
    ChatModule,
    CountryFlagModule,
    AccountsModule,
    MultiselectSearchComponent,
    FormDataDisplayConfigurationComponent,
    InputCodeComponent
  ],
  entryComponents: [
    ...[MaterialModule]
  ]
})
export class ComponentsModule { }
