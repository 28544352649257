<div [ngClass]="{'io-item':true, 'io-item-shadow':hasShadow, clickable:isClickable}">
    <!-- {{item?.raisedFlag|json}} -->
    <div class="main">
        <h4>{{ item?.number }}</h4>
        <div class="data subject">
            <small>{{ 'Subject' | translate}}</small>
            <span>{{item?.subject}}</span>
        </div>
        <app-ticket-status [ticket]="item" class="d-none d-l-show"></app-ticket-status>
        <div class="data status">
            <small>{{ 'Status' | translate}}</small>
            <span>{{ item?.status.split('_').join(' ') | translate }}</span>
        </div>
        <div class="data user">
            <small>{{ 'Created' | translate}}</small>
            <span>
                {{item?.created | date }}
            </span>
        </div>
        <div [ngClass]="{data: true,transaction: true, opacity: !item?.raisedFlag?.transaction?.transactionId}">
            <small>{{ 'Transaction' | translate}}</small>
            <span>
                {{item?.raisedFlag?.transaction?.transactionId }}
            </span>
        </div>
        <div class="data no-flex">
            <div class="no-flex io-mr-2 io-mt-1">
                <mat-icon matBadge="{{ item?.unseenMessagesCount || '' }}" matBadgeColor="warn">message</mat-icon>
            </div>
        </div>
    </div>
</div>