import { AbstractControl, FormArray, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import moment, { Moment } from "moment";

export class CustomValidators {
    static dateMin(date: Moment): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value == null) {
                return null;
            }
            const controlDate = moment(control.value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

            if (!controlDate.isValid()) {
                return null;
            }

            const validationDate = moment(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

            var isValid = validationDate.isSameOrBefore(controlDate)



            return isValid ? null : {
                'date-minimum': {
                    'date-minimum': validationDate,
                    'actual': controlDate
                }
            };
        };
    }
    static checkIfCountryIsChoosen(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null =>
            (typeof control.value === 'object' || control.value === '')
                ? null : { isInvalid: true };
    }
    static upperAmountValidation(): ValidatorFn {
        return (formControl: FormControl) => {
            let minAmount = formControl?.parent?.parent?.parent.get('minAmount')?.value;
            let valid: boolean = true;
            const formArray =
                formControl.parent && formControl.parent.parent
                    ? (formControl.parent.parent as FormArray)
                    : null;
            if (formArray) {
                formArray.value.forEach((x, i) => {
                    if (parseFloat(formControl?.value) === parseFloat(formArray?.at(i)?.value?.toAmount)) {
                        if (i > 0 && parseFloat(formControl?.value) <= parseFloat(formArray?.at(i - 1)?.value?.toAmount)) {
                            valid = false;
                            return { error: "Needs to be larger than the one before3." };
                        }
                        if (parseFloat(formArray?.at(i + 1)?.value?.toAmount) && parseFloat(formControl?.value) > parseFloat(formArray?.at(i + 1)?.value?.toAmount)) {
                            valid = false;
                            return { error: "Needs to be larger than the one before2." };
                        }
                    }
                });
                if (parseFloat(formControl?.value) < (parseFloat(minAmount))) {
                    valid = false;
                    return { error: "Needs to be larger than " + minAmount };
                }
            }
            return valid ? null : { error: "Needs to be larger than the one before and smaller than the next one." };
        };
    }
   static noWhitespaceValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
          if (control?.value && (typeof control.value === 'string') && control?.value?.trim() === '') {
            // If the input consists only of white spaces, return an error
            return { whitespace: true };
          }
          
          // If the input is valid, return null
          return null;
        };
      }
  static conditionalValidator(controlListenTo: AbstractControl, rules): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let pattern;
        let errorMsg;
        if (!control.value) {
            return null;
        }
        rules?.forEach(rule => {
            if (controlListenTo?.value && (new RegExp(rule?.values)).test(controlListenTo?.value)) {
                pattern = new RegExp(rule?.pattern);
                errorMsg = rule?.errorMessage;
            }
        })
        if (pattern && !pattern.test(control.value)) {
            return { error: errorMsg ? errorMsg : 'Invalid value.' };
        }
        return null;
    };
  }

  static medicareNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) {
          return null;
        }
        if (value.length !== 10) {
          return { error: 'Must contain 10 digits.' };
        }
        
        const digits = value.split('').map(Number);
    
        const remainder = (
          digits[0] +
          digits[1] * 3 +
          digits[2] * 7 +
          digits[3] * 9 +
          digits[4] +
          digits[5] * 3 +
          digits[6] * 7 +
          digits[7] * 9
        ) % 10;
    
        if (digits[0] < 2 || digits[0] > 6) {
            return { error: 'First digit must be in range from 2 to 6.' };
        }
        
        if (digits[8] !== remainder) {
          return { error: 'Invalid value.' };
        }
        return null;
    };
  }

}
