<div class="io-item io-item-shadow ">
    <div class="main">
        <div class="data">
            <small>Created</small>
            <span>
                {{item?.created | timezone2 | date: "short"}}
                <s>{{ item?.note }}</s>
            </span>
        </div>
        <div class="data no-flex">
            <mat-icon class="verified" *ngIf="item.success">verified</mat-icon>
            <mat-icon class="failed" *ngIf="!item.success">gpp_maybe</mat-icon>
        </div>
        <div class="data status">
            <small>{{'Status'}}</small>
            <span>{{item.success ? "Success" : "Failed"}}</span>
        </div>

        <div class="data">
            <small>{{'Provided'}}</small>
            <span>{{ formatProvidedData(item) | arrayFormatter }}</span>
        </div>

        <button class="button" mat-icon-button (click)="onShowHistory($event, item)">
            <mat-icon *ngIf="!showHistory">expand_more</mat-icon>
            <mat-icon *ngIf="showHistory">expand_less</mat-icon>
        </button>

    </div>
    <div [ngClass]="{expand:true, open: showHistory}">
        <p>{{ item?.note }}</p>
        <div class="io-ow">
            <div *ngFor="let _item of keyValueClean(item?.request)">
                <mat-card class="io-mb-3">
                    <h2>{{_item.key | translate }}</h2>
                    <table class="table-details">
                        <tbody>
                            <tr *ngFor="let _it of keyValueClean(_item.value) ">
                                <th>{{_it.key | translate}}</th>
                                <td>{{_it.value}}</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card>
            </div>
        </div>
    </div>
</div>