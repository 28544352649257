import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filetypeIcon' })
export class FiletypeIcon implements PipeTransform {
    transform(filename: string | null) {
        if (!filename)
            filename = "";

        const ext = filename.toLowerCase().split('.').pop();
        switch (ext) {
            case "doc":
            case "docx":
            case "txt":
                return "description";
            case "pdf":
                return "picture_as_pdf";
            case "png":
            case "jpg":
            case "jpeg":
            case "jfif":
                return "insert_photo";
            case "zip":
            case "rar":
            case "7z":
                return "folder_zip"
            default:
                return "insert_drive_file";
        }
    }
}

