import { Component, OnInit, Input } from '@angular/core';

import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-calculator-transaction-preview',
  templateUrl: './calculator-transaction-preview.component.html',
  styleUrls: ['./calculator-transaction-preview.component.scss'],
})
export class CalculatorTransactionPreviewComponent implements OnInit {
  @Input() item: any;
  _itm: any;
  get fromCalc(): any {
    return this.item;
  }
  @Input() set fromCalc(value: any) {
    this._itm = value;
    this.item = {
      "currency": value.sendingCurrency,
      "receivingCurrency": value.receivingCurrency,

      "amount": value?.calc?.sendAmount,
      "fixedFeePaid": value?.calc?.fee,
      "variableFeePaid": 0,

      "quote": value?.calc?.quote,
      "expectedPayoutTime": value?.calc?.expectedPayoutTime,
      "paidAmount": value?.calc?.totalToPay,
      "receivingAmount": value?.calc?.recipientReceives,

      "recipientCity": value?.recipient?.city,
      "recipientPhone": value?.recipient?.phone,
      "recipientFirstName": value?.recipient?.firstName,
      "recipientLastName": value?.recipient?.lastName,
    };

  }
  constructor(
    public auth: AuthenticationService
  ) {

  }

  ngOnInit() {

  }



}
