import { Injectable, NgZone, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { PaymentMethodsService } from './user/payment-methods.service';


@Injectable({
  providedIn: 'root'
})
export class UiService {
  public snacks: Subject<string>;
  public loaders: Subject<boolean>;
  public loading = false;
  private snackBarRef;
  public specificBarRef;
  openAccounts:boolean = true;

  constructor(
    private appTitle: Title,
    private zone: NgZone,
    public snackbar: MatSnackBar,
    private router: Router,
    private paymentMethodsService: PaymentMethodsService,


  ) {
    this.snacks = new Subject<string>();
    this.loaders = new Subject<boolean>();
  }


  setTitle(title: string) {
    this.appTitle.setTitle(title);
  }
  snack(message: string, duration: number = 3000) {
    this.snacks.next(message);
    const config = new MatSnackBarConfig();
    config.panelClass = ['background-red'];
    config.verticalPosition = 'bottom';
    config.duration = duration;
    config.horizontalPosition = 'center';
    this.zone.run(() => {
      this.snackBarRef = this.snackbar.open(message, 'OK', config);
    });
  }

  specificSnack(message: string, paymentMethodId: string, dialogRef) {
    this.snacks.next(message);
    const config = new MatSnackBarConfig();
    config.panelClass = ['background-red'];
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
    this.zone.run(() => {
      this.specificBarRef = this.snackbar.open(message, 'Edit agreement', config);
    });
    let snackDismissUnsub = this.specificBarRef.afterDismissed().subscribe((dismissedEvent) => {
      if (dismissedEvent.dismissedByAction)
        dialogRef.close();
     if (this.openAccounts === true) {
       this.router.navigate(['/', 'platform', 'payment-methods']);
       this.paymentMethodsService.changingAgreementId = paymentMethodId;
     }
     snackDismissUnsub.unsubscribe();
     this.openAccounts = true;
     this.specificBarRef = null;
    });
  }

  public dismiss(): void {
    this.snackBarRef.dismiss();
  }
  
  public dismissSpecificSnack(openAccounts = true): void {
    if (this.specificBarRef) {
      this.openAccounts = openAccounts;
      this.specificBarRef.dismiss();

    }
  }

  loaderShow() {
    requestAnimationFrame(() => {
      this.loaders.next(true);
      this.loading = true;
    });
  }
  loaderStop() {
    requestAnimationFrame(() => {
      this.loading = false;
      this.loaders.next(false);
    });
  }

}
