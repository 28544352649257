<list-wrapper #wrapper [mainService]="service" panelTitle="Corridors" [managePerm]="auth.perm.CorridorsManage"
   [readPerm]="auth.perm.CorridorsRead" (onNew)="newItem()" (onEdit)="editItem($event)">

   <form [formGroup]="form" class="io-row-m width-100" toolbox>
      <mat-form-field class="width-100 width-mff" appearance="outline">
         <mat-label>{{'Status' | translate}}</mat-label>
         <mat-select formControlName="active">
            <mat-option [value]="null">{{'All'|translate}}</mat-option>
            <mat-option [value]="true">{{'Active'|translate}}</mat-option>
            <mat-option [value]="false">{{'Inactive'|translate}}</mat-option>
         </mat-select>
      </mat-form-field>
      <app-multiselect-search formControlName="fromIds" [list]="countrySenders$ | async"
         placeholder="{{ 'From country' | translate }}" class="width-100 width-mff"></app-multiselect-search>
      <app-multiselect-search formControlName="toIds" [list]="countryReceivers$ | async"
         placeholder="{{ 'To country' | translate }}" class="width-100 width-mff"></app-multiselect-search>
      <app-multiselect-search formControlName="paymentServiceIds" [list]="paymentServices$ | async"
         placeholder="{{ 'Payment service' | translate }}" class="width-100 width-mff"></app-multiselect-search>
      <app-multiselect-search formControlName="paymentNetworkIds" [list]="paymentNetworks$ | async"
         placeholder="{{ 'Payment network' | translate }}" class="width-100 width-mff" [optionValue]="'extendedName'"></app-multiselect-search>
   </form>
   <div class="io-py-1">
      <div [ngClass]="{'io-item':true, 'io-item-shadow': true, 'io-item-inactive':!item?.isActive, clickable:true}"
         *ngFor=" let item of items; last as last;" (click)="wrapper.edit(item)">
         <!-- {{item?.raisedFlag|json}} -->
         <div class="main">
            <div class="flag">
               <div>
                  <app-country-flag [item]="item?.from" [size]="'big'"></app-country-flag>
                  <app-country-flag class="flag-2" [item]="item?.to" [size]="'big'"></app-country-flag>
               </div>
            </div>
            <div class="data name">
               <small>{{ 'From' | translate}}</small>
               <span>{{ item?.from?.name }} <s>{{ item?.from?.currency }}</s></span>
            </div>
            <div class="data name">
               <small>{{ 'To' | translate}}</small>
               <span>{{ item?.to?.name }} <s>{{ item?.receivingCurrency }}</s></span>
            </div>
            <div class="data name">
               <small>{{ 'Payment Service' | translate}}</small>
               <span>{{ item?.service.name }}</span>
            </div>
            <div class="data name">
               <small>{{ 'Payment Network' | translate}}</small>
               <span [matTooltip]="item?.network.extendedName">{{ item?.network.extendedName }}</span>
            </div>
            <div class="data name">
               <small>{{ 'Min Amount' | translate}}</small>
               <span>{{ item?.minAmount | number: '1.2-2'}} {{item?.checkCurrency}}</span>
            </div>
            <div class="data name">
               <small>{{ 'Max Amount' | translate}}</small>
               <span>{{ item?.maxAmount | number: '1.2-2' }} {{item?.checkCurrency}}</span>
            </div>
            <!-- <div class="data name">
               <small>{{ 'Fees' | translate}}</small>
               <span>{{ item?.fixedFee | number: '1.2-2' }} + {{ item?.variableFee | number: '1.2-2'}}%</span>
            </div> -->
         </div>
      </div>
   </div>
</list-wrapper>