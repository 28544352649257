import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { UiService } from 'src/app/_services/ui.service';
import { ActivatedRoute } from '@angular/router';
import { UserFlagsService } from 'src/app/_services/user/flags.service';
import { EndUserService } from 'src/app/_services/user/enduser.service';
import { UserTransactionsService } from 'src/app/_services/user/transactions.service';

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.scss']
})
export class VerifyUserComponent implements OnInit {
  counter = 0;
  isSuccess = null;
  subtitle = "";
  type = "EMAIL";
  form: FormGroup;

  constructor(
    private service: EndUserService,
    public userTransactionsService: UserTransactionsService,
    public userFlagsServi: UserFlagsService,
    private ui: UiService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
  ) {
    this.type = this.route.snapshot.paramMap.get('type') == 'phone' ? 'PHONE' : 'EMAIL';
    this.form = this.fb.group({
      verificationCode: [this.route.snapshot.paramMap.get('code'), [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
    });
    if (this.type == 'EMAIL') {
      this.subtitle = 'Thank you for verifying your email! Your verified email will now receive important system notifications and transaction status updates. We appreciate your commitment to staying informed.';
    } else {
      this.subtitle = 'Thank you for verifying your phone number! You\'ll receive important system notifications to your phone. We appreciate your commitment to staying informed.';
    }
  }

  ngOnInit() {
    if (this.form.valid)
      this.verify()
  }
  resendCode() {
    this.counter = 60;
    this.form.reset();
    this.service.resendVerificationCode(this.type);
    var int = setInterval(() => {
      this.counter--;
      if (this.counter < 1) {
        clearInterval(int);
      }
    }, 1000);
  }

  onRestart() {
    this.form.enable()
    this.form.reset();
    this.isSuccess = null;
  }
  verify(): void {
    this.form.disable()
    this.service
      .verify(this.form.controls.verificationCode.value, this.type)
      .subscribe({
        next: x => {
          this.userFlagsServi.refetchData();
          this.userTransactionsService.refetchData();
          this.isSuccess = true;
        }, error: e => {
          this.isSuccess = false;
          this.form.get('verificationCode').setErrors({ inavlid: true })
          console.log(e)
        }
      });
  }
}
