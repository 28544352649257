<table *ngIf="type=='string'" class="table-details">
  <tbody>
    <tr>
      <th>Description</th>
      <td>{{ items }}</td>
    </tr>
  </tbody>
</table>

<span *ngIf="type=='array'">
  <div *ngFor="let _item of items; index as idx" class="json">
    <table class="table-details">
      <tbody>
        <tr *ngIf="_item?.name">
          <th>Name</th>
          <td>{{ _item?.name }}</td>
        </tr>
        <tr *ngIf="_item?.sources">
          <th>Sources</th>
          <td>{{ _item?.sources.join(', ') }}</td>
        </tr>
        <tr *ngIf="_item?.types">
          <th>Types</th>
          <td>{{ _item?.types.join(', ') }}</td>
        </tr>
        <tr *ngFor="let field of _item?.fields">
          <td *ngIf="field?.type == 'section'" colspan="2" class="section">
            {{ field?.value |translate }}
          </td>
          <th *ngIf="field?.type != 'section'">
            <span *ngIf="(field?.type == null) ||  ['btn-primary', 'btn-warn', 'btn'].indexOf(field?.type) == -1">
              {{ field?.name }}
            </span>
          </th>
          <td *ngIf="field?.type != 'section'">

            <a *ngIf="field?.type == 'url'" [href]="field?.value" target="_blank">
              {{ field?.value }}
            </a>
            <img *ngIf="field?.type == 'image'" [src]="field?.value" width="100%" style="max-width: 250px;"
              onerror="this.style.display = 'none'">
            <div><a *ngIf="field?.type == 'image'" [href]="field?.value" target="_blank">
                Open in new tab
              </a></div>
            <a mat-stroked-button *ngIf="field?.type == 'btn'" [href]="field?.value" target="_blank">{{field?.name}}</a>
            <a mat-flat-button *ngIf="field?.type == 'btn-primary'" color="primary" [href]="field?.value"
              target="_blank">{{field?.name}}</a>
            <a mat-flat-button *ngIf="field?.type == 'btn-warn'" color="warn" [href]="field?.value"
              target="_blank">{{field?.name}}</a>
            <span *ngIf=" (['btn-primary', 'btn-warn', 'btn', 'image', 'url'].indexOf(field?.type) == -1)">
              {{field?.value}}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <hr *ngIf="items.length == idx" />
  </div>
  <!-- <pre>{{items|json}}</pre> -->
</span>