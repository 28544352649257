import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/material.module';
import { CountryFlagModule } from '../country-flag/country-flag.module';
import { SingleSelectComponent } from './single-select.component';



@NgModule({
  declarations: [
    SingleSelectComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    TranslateModule.forChild(),
    CountryFlagModule
  ],
  exports: [
    SingleSelectComponent
  ]
})
export class SingleSelectModule { }
