import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { PublicModule } from './public/public.module';
import { MaterialModule } from './material.module';
import { AdminModule } from './admin/admin.module';
import { PlatformModule } from './platform/platform.module';
// import { GraphQLModule } from './graphql.module';
import { ApolloModule } from 'apollo-angular';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { EnvConfigurationService } from './_services/env-configuration.service';
import { GraphQLModule } from './graphql.module';




@NgModule({
  declarations: [AppComponent],
  imports: [
    ApolloModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    LayoutModule,
    MaterialModule,
    HttpClientModule,
    GraphQLModule,
    TranslateModule.forRoot(),
    AdminModule,
    PlatformModule,
    PublicModule // keep it latest because of routes setup
  ],

  providers: [
    // {
    //   provide: APOLLO_OPTIONS,
    //   useFactory(httpLink: HttpLink) {
    //     return {
    //       cache: new InMemoryCache(),
    //       link: httpLink.create({
    //         uri: 'http://localhost:3000',
    //       }),
    //     };
    //   },
    //   deps: [HttpLink],
    // },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (envConfigService: EnvConfigurationService) => () => {
        return async () => await envConfigService.load().toPromise();
      },
      deps: [EnvConfigurationService],
      multi: true
    },
    { provide: "window", useValue: Window }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
