import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WrapperComponent } from './wrapper/wrapper.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { VerifyUserComponent } from '../public/register/verify-user/verify-user.component';
import { KycComponent } from './kyc/kyc.component';

import { TransactionsComponent } from './transactions/transactions.component';
import { TabTopWrapperComponent } from '../components/tab-top-wrapper/tab-top-wrapper.component';
import { environment } from 'src/environments/environment';
import { AccountsListComponent } from './accounts/accounts.component';
import { TicketsComponent } from './tickets/tickets.component';
import { RecipientsComponent } from './recipients/recipients.component';
import { EnduserProfileComponent } from './enduser-profile/enduser-profile.component';
import { AuthGuard } from '../_guards/auth.guard';
import { InterfacePermissions } from '../_graphql/schema';
import { DeleteAccountComponent } from '../components/delete-account/delete-account.component';


const routes: Routes = [
  {
    path: 'platform',
    component: WrapperComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Platform',
      allowedPermission: InterfacePermissions.EndUser
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/platform/' + environment.defaultPage
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Dashboard',
          allowedPermission: InterfacePermissions.EndUser
        }
      },
      {
        path: 'questionnaire',
        canActivate: [AuthGuard],
        component: QuestionnaireComponent,
        data: {
          title: 'Questionnaire',
          allowedPermission: InterfacePermissions.EndUser
        }
      },
      {
        path: 'verify/:type',
        component: VerifyUserComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Verification',
          allowedPermission: InterfacePermissions.EndUser
        }
      },
      {
        path: 'verify/:type/:code',
        component: VerifyUserComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Verification',
          allowedPermission: InterfacePermissions.EndUser
        }
      },
      {
        path: 'verify',
        redirectTo: 'verify/email'
      },
      {
        path: 'phoneverify',
        redirectTo: 'verify/phone'
      },
      {
        path: 'kyc',
        component: KycComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Verify your identity',
          allowedPermission: InterfacePermissions.EndUser
        }
      },
      {
        path: 'delete-account',
        component: DeleteAccountComponent,
        canActivate: [AuthGuard],
        data: {
          allowedPermission: InterfacePermissions.TransactionsCreate,
        }
      },
      {
        path: '',
        component: TabTopWrapperComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'profile',
            canActivate: [AuthGuard],
            component: EnduserProfileComponent,
            data: {
              title: 'Profile',
              allowedPermission: InterfacePermissions.EndUser
            }
          },
          // {
          //   path: 'transaction-item',
          //   component: EnduserProfileComponent,
          //   data: {
          //     title: 'transaction-item',
          //     allowedRoles: ['User'],
          //   }
          // },
          {
            path: 'transactions/recipient/:recipientId',
            component: TransactionsComponent,
            canActivate: [AuthGuard],
            data: {
              title: 'Transactions',
              allowedPermission: InterfacePermissions.EndUser,
              config: {
                dateTo: true,
                dateFrom: true,
                query: true,
                recipientId: true,
                searchButton: false,
              }
            }
          },
          {
            path: 'transactions',
            component: TransactionsComponent,
            canActivate: [AuthGuard],
            data: {
              title: 'Transactions',
              allowedPermission: InterfacePermissions.EndUser,
              config: {
                dateTo: true,
                dateFrom: true,
                query: true,
                recipientId: true,
                searchButton: false,
              }
            }
          },
          {
            path: 'tickets',
            component: TicketsComponent,
            canActivate: [AuthGuard],
            data: {
              title: 'Tickets',
              allowedPermission: InterfacePermissions.EndUser,
            }
          },
          {
            path: 'recipients',
            component: RecipientsComponent,
            canActivate: [AuthGuard],
            data: {
              title: 'Recipients',
              allowedPermission: InterfacePermissions.EndUser,
            }
          },
          {
            path: 'payment-methods',
            component: AccountsListComponent,
            canActivate: [AuthGuard],
            data: {
              title: 'Payment Methods',
              allowedPermission: InterfacePermissions.PaymentMethodsManage,
            }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlatformRoutingModule { }
