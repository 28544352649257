import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export enum Environment {
  Prod = 'prod',
  Staging = 'staging',
  Test = 'test',
  Dev = 'dev',
  Local = 'local'
}

interface Configuration {
  apiUrl?: string;
  stage?: Environment;
  production?: boolean;
  uploadEndpoint?: string;
  downloadEndpoint?: string;
  fixerUrl?: string;
  bankIdRedirectUrl?: string;
}

@Injectable({ providedIn: 'root' })
export class EnvConfigurationService {
  public static environment: Configuration = {};
  public static readonly configFile = 'assets/deployment_config.json';

  private configuration$: Observable<Configuration>;

  constructor(private http: HttpClient) { }

  public load(): Observable<Configuration> {
    if (!this.configuration$) {
      this.configuration$ = this.http
        .get<Configuration>(EnvConfigurationService.configFile)
        .pipe(shareReplay(1));

      this.configuration$.subscribe(result => {
        Object.keys(result).forEach(key => {
          if (result[key] == "true") {
            environment[key] = true;
          } else if (result[key] == "false") {
            environment[key] = false;
          } else {
            environment[key] = result[key];
          }
        });
      });
    }
    return this.configuration$;
  }
}
