import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Subscribable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Transaction, TransactionsFilters } from 'src/app/_graphql/schema';
import { DocumentFragmentBasic, RecipientFragmentBasic, RecipientOnTransactionFragmentBasic } from 'src/app/_graphql/shared-fragments';
import { BaseService } from '../admin/base.service';
import { UiService } from '../ui.service';
import { EndUserService } from './enduser.service';



@Injectable({
  providedIn: 'root'
})
export class UserTransactionsService extends BaseService<Transaction>{
  pendingTransId = null;
  selectOneFields = gql`
    fragment TransactionSelectOneFields on Transaction  {
      id
      # dashboard transactions
      canCancel { enabled }
      continue { enabled } toIsoCode3 amount fixedFeePaid variableFeePaid quote
      expectedPayoutTime
      id transactionId fromName toName currency paidAmount receivingCurrency receivingAmount created
      transactionStatusInfo { 
        statusId, note, icon, name, flagCount, flagTooltip, ticketCount, ticketTooltip
        # dashboard transactions
        isWarning isInfo
      }
      status { id name }
      recipientFirstName recipientLastName  recipientPhone inbound
      paymentMethodName paymentMethodNumber paymentNetworkName pickupCode
    }
  `;

  selectAllFields = gql`
    fragment TransactionSelectAllFields on Transaction  {
      id ...TransactionSelectOneFields
    }
    ${this.selectOneFields}
  `;

  constructor(protected apollo: Apollo, private ui: UiService, private usersService: EndUserService) {
    super(apollo);
    this.mainScope = "user";
    this.initGql('transaction');

    this.createMutation = gql`
    mutation createTransaction(
      $countryFromId: ID!
      $countryToId: ID!
      $sendAmount: Float
      $receiveAmount: Float
      $serviceId: ID!
      $networkId: ID!
      $recipientId: ID!
      $purposeId: ID,
      $quoteId: ID,
      $locationId: ID
    ) {
      user { 
      createTransaction(
        countryFromId: $countryFromId
        countryToId: $countryToId
        sendAmount: $sendAmount
        receiveAmount: $receiveAmount
        serviceId: $serviceId
        networkId: $networkId
        recipientId: $recipientId
        purposeId: $purposeId,
        quoteId: $quoteId,
        locationId: $locationId
      ) {
        id
        canCancel { enabled  infoText }
        created
        amount
        fixedFeePaid
        variableFeePaid
        paidAmount
        receivingAmount
        currency
        receivingCurrency
        quote
        transactionId
        inboundName
        inbound
        recipientFirstName
        recipientLastName
        # sender{ firstName lastName }
        paymentInstructions
        raisedFlags(resolved: false) {
          id
          flag
          {
            id
            presentedCode
          }
        }
      }
      }
    }
  `
  }

  public create(data: any): Subscribable<Transaction> {
    this.addRefetchQuery(this.usersService.onboardingQuery, this.usersService.onboardingWQparams);
    this.addRefetchQuery(this.usersService.currentUserGQL, null);

    return this.unwrapData('createTransaction', this.apollo.mutate<Transaction>({
      mutation: this.createMutation,
      refetchQueries: [
        ...this.refetchAdditionalQueries
      ],
      variables: data
    }));
  }


  public getInbounds(transactionId: String) {
    return this.query(gql`
    query inbounds($transactionId: ID){
    user {
      id
      inbounds(transactionId: $transactionId){
        code
        displayName
        paymentMethods {
          id
          paymentMethodType
          account {
            accountType
            accountSubType
            id
            accountNumber
            lastFourNumbers
            accountSubType
            name
            fingerprint
            holderName
            routingNumber
            status
          }
        }
      }
      }
    }
  `, { transactionId }, false, 'inbounds');
  }

  public initiatePayment(inputs): any {
    return this.unwrapData('initiatePayment', this.mutation(gql`
    mutation initiatePayment(
      $transactionId: ID!
      $inbound: String!
      $paymentMethodId: String
    ) {
      user {
        initiatePayment(
          transactionId: $transactionId
          inbound: $inbound
          paymentMethodId: $paymentMethodId
        ) {
          success
          navigateURL
          inbound
          newTab
          inboundName
          errorCode
          errorMessage
        }
      }
    }
  `, inputs))
  }

  public pictureName(inbound: string) {
    return inbound ? inbound
      .toLowerCase() : '';
  }
  public resendEmail(id: string, emailType: string = "PAYMENT_INSTRUCTIONS") {
    return this.mutation(gql`
      mutation resendEmail($id: ID!, $emailType: EmailTemplateEnumType!) {
          ${this.mainScope} {
              resendEmail(id: $id, emailType: $emailType) 
          }
      }
    `,
      { id, emailType });
  }



  public getHistory(transactionId: string): any {
    return this.unwrapData('transactionHistory', this.query(gql`
    query transactionsHistory($transactionId: ID!) {
      user {
        transactionHistory(pageRequest: { filters: {transactionId: $transactionId}}) { data { created id note  transactionStatus { id name } } }
        id
      }
    }
  `,
      { transactionId },
      false, 'transactionsHistory'
    ));
  }

  public cancelTransaction(transactionId: string) {
    this.mutation(gql`mutation cancelTransaction($transactionId: ID!) {
      user {
        cancelTransaction(transactionId: $transactionId) {
          amount
          id
          status {
            id
            name
          }
        }
      }
    }`, { transactionId }).subscribe({
      next: res => {
        this.ui.snack('Transaction successfully canceled');
      },
      error: x => {
        this.ui.snack('Error in canceling transaction');
      }
    });
  }

  public getTransactionReceiptData(transactionId: string): any {
    return this.query(gql`query transactionReceipt($transactionId: ID!) {
      user {
        id
        transaction(id: $transactionId) {
          id receipt { id name }
        }
      }
    }`, { transactionId });
  }
}
