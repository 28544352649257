import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payto-terms',
  templateUrl: './payto-terms.component.html',
  styleUrls: ['./payto-terms.component.scss']
})
export class PayToTermsComponent implements OnInit {
  contact_page = ""
  constructor() {
    this.contact_page = environment.homePageLink + "/contact"

  }

  ngOnInit() {
  }



}
