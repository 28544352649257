import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'arrayFormatter' })
export class ArrayFormatterPipe implements PipeTransform {
    transform(list: any[], mode: string = "all", items: number = 1) {
        if (!Array.isArray(list))
        return list;
        if (mode != 'all' && list.length < items)
            mode = 'all'

        if (mode == 'all') {
            if (list.length <= 2) {
                return list.join(" and ");
            } else {
                return [list.slice(0, list.length - 1).join(", "), list.slice(-1)].join(" and ");
            }
        }
        else {
            return [list.slice(0, items < list.length ? items : list.length).join(", "), (list.length - items + " more")].join(" and ");
        }
    }
}

