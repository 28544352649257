<div *ngIf="noConfigurationLoadedError | async" class="io-py-3">
    <div *ngIf="country?.name; else noConfigAvailable">
        {{ 'This option is not available for ' | translate }}
        {{country?.name }}.
    </div>
    <ng-template #noConfigAvailable>
        {{ 'This option is currently unavailable. Please try later.' | translate }}
    </ng-template>
</div>
<ng-container *ngIf="isStepper">
    <mat-stepper [orientation]="(stepperOrientation | async)!" [linear]="true" *ngIf="fieldsConfig.length"
        [ngClass]="{hideSteps: hideSteps}" #stepper>
        <form [formGroup]="form">
            <mat-step *ngFor="let _section of uniqueFormSections; index as _index" [label]="_section?.label"
                [completed]="isCompleted(_section)">
                <div class="io-steper">
                    <mat-form-field #selectedSection
                        *ngIf="oneRequiredDefaultView[_section.group] && _section.name !='consents'">
                        <mat-label>{{ 'Choose' | translate }} {{_section?.label}}</mat-label>
                        <mat-select (valueChange)="showSectionGroup($event, _section.group)"
                            [value]="oneRequiredDefaultView[_section.group]">
                            <mat-option
                                *ngFor="let section of fieldsConfig | filterOn: ['group']: _section.group; index as k"
                                [value]="section.name">
                                {{ section.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngFor="let section of fieldsConfig | filterOn:['group']:_section.group; index as i">
                        <div *ngIf="_section.group == section.group">
                            <form [formGroupName]="section.name"
                                *ngIf="_section.groupPolicy != 'OneRequired' ||  (section.name == oneRequiredDefaultView[_section.group])">
                                <p *ngIf="section?.description">{{ section?.description}}</p>
                                <div *ngFor="let field of section.fields" [ngClass]="{'io-pb-2': field.description}">
                                    <ng-container [ngTemplateOutlet]="formMainTemplate"
                                        [ngTemplateOutletContext]="{ field: field, form: form.get(section.name), section: section }">
                                    </ng-container>
                                </div>
                                <div class="actions-bar">
                                    <button mat-stroked-button matStepperPrevious color="primary"
                                        *ngIf="_index > 0">Back</button>
                                    <span></span>
                                    <button mat-raised-button matStepperNext color="primary"
                                        data-id="kyc-btn-next-{{section.name}}"
                                        [disabled]="!isCompleted(section)">Next</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ng-template matStepLabel let-index="index">
                    <span
                        [ngStyle]="{'color':(checkIfFormStepsFinished(_index)) ? '#0000008a' : '#000001'}">{{_section?.label}}</span>
                </ng-template>
            </mat-step>
            <mat-step label="Review">
                <app-review-step [form]="form" [fieldsConfig]="fieldsConfig" [uniqueFormSections]="uniqueFormSections">
                    <div class="actions-bar top-margin" submit>
                        <button mat-stroked-button matStepperPrevious color="primary">Back</button>
                        <button mat-raised-button matStepperNext color="primary" (click)="onSubmit()"
                            [disabled]="loading" data-id="{{'kyc-btn-submit'}}">Submit</button>
                    </div>
                </app-review-step>
            </mat-step>
            <ng-template matStepperIcon="edit" let-index="index">
                <mat-icon>done</mat-icon>
            </ng-template>
        </form>
    </mat-stepper>
</ng-container>

<ng-container *ngIf="!isStepper">

    <ng-container *ngFor="let field of fieldsConfig; index as i">
        <form [formGroup]="form">
            <div>
                <div [ngClass]="{'io-pb-2': field.description}">
                    <h3
                        *ngIf="field.visible && fieldsConfig[i].section != '' && (i == 0 || (i>0 && fieldsConfig[i].section != fieldsConfig[i-1].section))">
                        {{ field.section }}</h3>
                    <ng-container [ngTemplateOutlet]="formMainTemplate"
                        [ngTemplateOutletContext]="{ field: field, form: form }">
                    </ng-container>
                </div>
            </div>
        </form>
    </ng-container>
</ng-container>

<ng-template #formMainTemplate let-field="field" let-form="form" let-section="section">
    <div [formGroup]="form" *ngIf="(imageDisplayType | async) as imageDisplayType">
        <ng-container *ngIf="field?.type === 'country-select'">
            <app-single-select placeholder="{{ field.label }}"
                [items]="(confType=='user' || confType=='updateUser') ? (sendingCountires$ | async) : (receivingCountries$ | async) "
                formControlName="countryId" [showFlag]="true" (selectionChange)="selectedCountry($event)">
            </app-single-select>
        </ng-container>
        <ng-container *ngIf="field.visible && field?.type == 'select'" style="display: flex;">
            <app-single-select placeholder="{{ field.label }}" [items]="field?.values" [formControlName]="field.name"
            [optionValue]="['label', 'value']" [hintText]="field?.description" style="position: relative;">
        </app-single-select>
        <ng-container [ngTemplateOutlet]="imageContainerLargeScreen" *ngIf="this.focusedField[field.name]"></ng-container>
        </ng-container>
        <ng-container *ngIf="field.visible && field.type == 'relationship-select'">
            <mat-form-field appearance="outline">
                <mat-label>{{ field.label }}</mat-label>
                <mat-select [formControlName]="field.name">
                    <mat-option [value]="relationship.id" *ngFor="let relationship of relationships$ | async"
                        [disabled]="!relationship.isActive">
                        {{ relationship.name | translate }}
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="field.visible && field.type == 'state-select'">
            <app-single-select placeholder="{{ field.label }}" [items]="field?.values ? field?.values : states$ | async"
                [formControlName]="field.name" [hintText]="field?.description"
                [optionValue]="field?.values ? ['label', 'value'] : ['name', 'id']">
            </app-single-select>
        </ng-container>
        <ng-container *ngIf="'date' == field?.type && (field?.properties 
        | filterOn: ['value']: ['month-picker'])?.length > 0">
            <app-month-year-date-picker [formControlName]="field.name" [required]="field?.required" 
            [field]="field" (hideImage)="hideImage(field)" [imageAttached]="showIcon(field)" [imageDisplayType]="imageDisplayType"
            (showImage)="showImage(field, $event)" (openImageDialog)="openImageDialog(field)">
            <ng-container image [ngTemplateOutlet]="imageContainerLargeScreen" 
            *ngIf="this.focusedField[field.name] && imageDisplayType === 'large'"></ng-container>
            </app-month-year-date-picker>
        </ng-container>
        <ng-container *ngIf="field.visible && field.type == 'gender'">
            <mat-form-field appearance="outline">
                <mat-label>{{ field.label }}</mat-label>
                <mat-select [formControlName]="field.name">
                    <mat-option value="M">{{ 'Male' | translate }}</mat-option>
                    <mat-option value="F">{{ 'Female' | translate }}</mat-option>
                </mat-select>
                <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="'radio' == field?.type">
            <div style="margin-top: 24px; margin-bottom: 12px;">{{field?.label}}
                <span *ngIf="field?.required">*</span>
                <div *ngIf="field?.description">
                    <small>{{ field.description }}</small>
                </div>
            </div>
            <mat-radio-group [formControlName]=" field.name">
                <div *ngFor="let _v of field.values">
                    <mat-radio-button color="primary" [value]="_v.value" style="margin-bottom: 8px;">{{ _v.label |
                        translate }}
                    </mat-radio-button>
                </div>
            </mat-radio-group>
        </ng-container>
        <ng-container></ng-container>
        <ng-container *ngIf="field.visible && field.type == 'numbers'">
            <mat-form-field appearance="outline">
                <mat-label>{{ field.label }}</mat-label>
                <mat-error align="end">{{showErrors(null, field)}}</mat-error>
                <input matInput [type]="field.type" [formControlName]="field.name" [value]="field.value"
                    [ngStyle]="{'padding-bottom.px': (form.get(field.name).value == '' || form.get(field.name).value == null  ? 0: 4)}" />
                <span matPrefix disabled *ngIf="field.name == 'phone' && form.get(field.name).value" style="display: block;
                    padding: 4pt 5pt 4.5pt 0pt;
                    opacity: 0.4;">
                    +{{country?.phonePrefix}}
                </span>
                <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
                <button mat-icon-button *ngIf="field.name == 'phone'" matSuffix disabled>
                    <mat-icon>phone</mat-icon>
                </button>
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="field?.type === 'moov-terms-token'" class="checkbox">
            <mat-checkbox color="primary" [formControlName]="field.name"
                (change)="getMoovTermsToken(section?.name, field?.name)">
                <p class="checkbox checkbox-text" innerHTML="{{field?.label | translate}}"></p>
            </mat-checkbox>
        </ng-container>

        <ng-container *ngIf="['text', 'email'].indexOf(field?.type)>-1">
            <mat-form-field>
                <mat-label>{{field?.label}}
                </mat-label>
                <input matInput [formControlName]="field.name" [type]="field.type" [value]="field.value"
                    [autocomplete]="field.type === 'email' ? 'username' : null" 
                    (focus)="(imageDisplayType === 'large' && showIcon(field)) ? showImage(field) : null" 
                    (blur)="(imageDisplayType === 'large' && showIcon(field)) ? hideImage(field) : null">
                <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
                <mat-error align="end">{{showErrors(section?.name, field)}}</mat-error>
                <button mat-icon-button matSuffix type="button" 
                *ngIf="imageDisplayType === 'small' && showIcon(field)">
                <mat-icon matSuffix (click)="showImage(field); openImageDialog(field)">info</mat-icon>
                </button>
            </mat-form-field>
            <ng-container [ngTemplateOutlet]="imageContainerLargeScreen" 
            *ngIf="this.focusedField[field.name] && imageDisplayType === 'large'"></ng-container>
        </ng-container>

        <ng-container *ngIf="field.visible && field.type == 'password'">
            <mat-form-field appearance="outline"
                hintLabel="{{'Needs to contain at least one lower, upper and one digit character.' | translate}}">
                <mat-label>{{ 'Password' | translate }}</mat-label>
                <input matInput #password [type]="field.type" [formControlName]="field.name" minlength="field.minlength"
                    [type]="hide ? 'password' : 'text'" />
                <button mat-icon-button matSuffix type="button" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
                <mat-hint align="end">{{password.value.length}} / {{ 6 }}</mat-hint>
                <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
            </mat-form-field>
            <app-password-strength [hasNumber]="true" [hasUpper]="true" [hasLower]="true"
                [userPassword]="this.form.get(field.name)">
            </app-password-strength>
            <mat-form-field appearance="outline">
                <mat-label>{{ 'Confirm Password' | translate }}</mat-label>
                <input matInput (keyup)="confPassword($event)" required appConfirmEqualValidator="password"
                    type="password" min="6" />
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="field.visible && field.type == 'date' &&
        (field?.properties | filterOn: ['value']: ['month-picker'])?.length === 0
         && imageDisplayType === 'large'" style="display: flex;">
            <mat-form-field appearance="outline" style="position: relative;">
                <mat-label>{{ field.label }}</mat-label>
                <input matInput [matDatepicker]="picker" [formControlName]="field.name" [value]="field.value"
                    [min]="getMin(field?.max)" [max]="getMax(field?.min)" (focus)="showIcon(field) ? showImage(field) : null;" 
                    (blur)="showIcon(field) ? hideImage(field) : null;"/>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
            </mat-form-field>
            <ng-container [ngTemplateOutlet]="imageContainerLargeScreen" 
            *ngIf="this.focusedField[field.name]"></ng-container>
        </ng-container>

        <ng-container *ngIf="field.visible && field.type == 'date' &&
        (field?.properties | filterOn: ['value']: ['month-picker'])?.length === 0
         && imageDisplayType === 'small'" style="display: flex;">

            <mat-form-field appearance="outline" style="position: relative;" *ngIf="showIcon(field)">
                <mat-label>{{ field.label }}</mat-label>
                <input matInput [matDatepicker]="picker" [formControlName]="field.name" [value]="field.value"
                [min]="getMin(field?.max)" [max]="getMax(field?.min)" (focus)="picker.open()" (blur)="hideImage(field)"/>
                    <button mat-icon-button matSuffix type="button" (click)="showImage(field, $event); openImageDialog(field)">
                        <mat-icon >info</mat-icon>
                    </button>
                <mat-datepicker #picker></mat-datepicker>
                <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" style="position: relative;" *ngIf="!showIcon(field)">
                <mat-label>{{ field.label }}</mat-label>
                <input matInput [matDatepicker]="picker" [formControlName]="field.name" [value]="field.value"
                    [min]="getMin(field?.max)" [max]="getMax(field?.min)"/>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
            </mat-form-field>
        </ng-container>

        <ng-container class="checkbox" *ngIf="field.visible && field.type == 'checkbox'">
            <mat-checkbox color="primary" matInput [formControlName]="field.name">
                <p [ngClass]="{'checkbox-text':true, 'error': form.get(field.name).errors?.required &&
                form.get(field.name).touched}" [innerHTML]=" field.label | translate"></p>
            </mat-checkbox>
        </ng-container>
        <ng-container class="checkbox" *ngIf="field.visible && field.type === 'checkboxstring'">
            <mat-checkbox color="primary"
                (change)="toggleFormArrayData(section?.name, field.name, field.value ? field.value : field.label)">
                <p [ngClass]="{'checkbox-text': true, 'error': form.get(section.name)?.get(field.name)?.errors?.required &&
            form.get(section?.name)?.get(field?.name)?.touched}" innerHTML="{{field?.label | translate}}">
                </p>
            </mat-checkbox>
        </ng-container>
        <ng-container *ngIf="field.visible && field.type == 'file-upload'" class="io-row align-items-center no-gap"
            style="font-size: 10pt">
            <app-input-field-file [formControlName]="field.name" [label]="field?.label"
                [description]="field?.description" *ngIf="field?.type === 'file-upload'" [accept]="field?.pattern"
                [errors]="form.get(section.name).get(field.name).errors">
            </app-input-field-file>
            <!-- <div>{{ field.label }}
                <span *ngIf="field?.required">*</span>
                <small style="display:block; opacity: 0.64;" *ngIf="field?.description">{{
                    field.description }}</small>
                <input hidden type="file" #uploader (change)="handleFileInput($event.target.files, field.name)"
                    [accept]="field?.pattern" />
            </div>
            <div *ngIf="!form.get(field.name).value">
                <button type="button" mat-icon-button (click)="uploader.click()">
                    <mat-icon color="primary">attachment</mat-icon>
                </button>
            </div>
            <div *ngIf="form.get(field.name).value" class="uploaded">{{
                filesUploaded[form.get(field.name).value].name }}</div>
            <button *ngIf="form.get(field.name).value" mat-icon-button type="button" class="no-flex"
                (click)="removeImage(field.name)">
                <mat-icon color="warn">close</mat-icon>
            </button> -->
        </ng-container>
        <ng-container *ngIf="field?.visible && field?.type === 'multiselect'">
            <div style="margin-top: 24px; margin-bottom: 12px;">{{field?.label}}
                <span *ngIf="field?.required">*</span>
            </div>
            <mat-form-field appearance="fill">
                <mat-label>Select options</mat-label>
                <mat-select [formControlName]=" field.name" multiple>
                    <mat-option *ngFor="let _v of field.values" [value]="_v.value">{{ _v.label |
                        translate }}</mat-option>
                </mat-select>
                <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="field.type === 'address-autocomplete'">
            <mat-form-field>
                <mat-label>{{ field.label }}</mat-label>
                <input type="text" matInput [formControlName]="field.name" [matAutocomplete]="autoGroup">
                <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="addressChoosen($event)">
                    <mat-option *ngFor="let element of addressData | async" [value]="element"
                        class="autoCompleteOption">
                        <a>{{ element['description'] | translate }}</a>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </ng-container>
        <ng-template #imageContainerLargeScreen>
            <div class="image-container-large">
                <img [src]="imageUrl">
                <small>{{imageDescription | translate}}</small>
            </div>
        </ng-template>
    </div>
</ng-template>