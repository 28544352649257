import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-panel-placeholder',
  templateUrl: './panel-placeholder.component.html',
  styleUrls: ['./panel-placeholder.component.scss']
})
export class PanelPlaceholderComponent {
  @Input() mode = 'default';
  @Input() superTitle: string = null;
  @Input() superDescription: string = null;
  @Input() key: string = 'default';
  private localTitle = 'title';
  private localImage = null;
  @Input() set title(title: string) {
    this.localTitle = (title && title.trim()) || '';
  }
  get title(): string { return this.superTitle ? this.superTitle : 'placeholder_' + this.key + '_' + this.localTitle; }

  @Input() set image(image: string) {
    this.localImage = image;
  }
  get image(): string { return this.localImage; }

  private localDescription = 'description';
  @Input() set description(title: string) {
    this.localDescription = (title && title.trim()) || '';
  }
  get description(): string { { return this.superDescription ? this.superDescription : 'placeholder_' + this.key + '_' + this.localDescription; } }

  constructor() { }


}
