import { Component, Input, OnInit } from '@angular/core';
import { Subscribable } from 'rxjs';
import { AdminTransactionType, RaisedFlagType } from 'src/app/_graphql/schema';

@Component({
  selector: 'app-sender-section',
  templateUrl: './sender-section.component.html',
  styleUrls: ['./sender-section.component.scss']
})
export class SenderSectionComponent implements OnInit {
  @Input() transaction: AdminTransactionType = null;
  @Input() flags: RaisedFlagType[];

  constructor() { }

  ngOnInit(): void {
  }

}
