import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Subscribable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdminEndUserType, DeleteAccountResponseType } from 'src/app/_graphql/schema';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class AdminUsersService extends BaseService<AdminEndUserType>{

  selectOneFields = gql`
    fragment UserSelectOneFields on AdminEndUserType  {
      id firstName middleName lastName email lastSuccessfulLogin created birthDate isPEP userRef
      nationalityCountryId countryOfBirthId
      phone phoneVerificationEnabled phoneVerified
      socialNumber socialNumberType
      addressLine1 addressLine2 city country { id name currency isoCode2 phonePrefix isoCode3 } unitNumber zip streetNumber streetType territory
      moneySent moneyReceived
      userStatusInfo {flagCount flagTooltip ticketCount ticketTooltip unresolvedFlagsCount userId } 
  }`;

  selectAllFields = gql`
    fragment UserSelectAllFields on AdminEndUserType  {
      id 
      firstName @skip(if: $getMoreDetails)
      lastName @skip(if: $getMoreDetails)
      email @skip(if: $getMoreDetails)
      userRef @skip(if: $getMoreDetails)
      country @skip(if: $getMoreDetails) { currency id name isoCode3 } 
      lastSuccessfulLogin @client
      lastSuccessfulLogin @include(if: $getMoreDetails)
      
      # transactionsCount @include(if: $getMoreDetails)
      # transactionsCount @include(if: $getMoreDetails)
      # recipientsCount 
      # filesCount @include(if: $getMoreDetails)
      # moneyReceived @client
      # moneyReceived @include(if: $getMoreDetails)
      moneySent @client
      moneySent @skip(if: $getMoreDetails)
      kycData @client {kycAttempts kycInProgress }
      kycData @include(if: $getMoreDetails){kycAttempts kycInProgress }
      raisedFlags @client
      raisedFlags(resolved: false, level: USER) @include(if: $getMoreDetails)  { id level flag { id name code presentedCode } ticket { id } } 
      tickets  @client
      tickets(resolved: false, level: USER) @include(if: $getMoreDetails)  { id subject description number } 
  }`;

  constructor(protected apollo: Apollo) {
    super(apollo);
    this.mainScope = "usersManagement";
    this.initGql('user');
  }
  public deleteAccountQuery = gql`query deleteAccountSteps($userId: ID!) {
    usersManagement {
      deleteAccountSteps(userId: $userId) {
        id infoMessage status canAbandon
        steps { description status executed resolved title infoMessage statusMessage }
      }
    }
  }`;

  getCustomProperties(userId: string, tag: string): Subscribable<any> {
    return this.query(gql` query customProperties($userId: ID!, $tag: String) {
      usersManagement {
        __typename
        id
        customProperties(userId: $userId, tag: $tag) {
          __typename
          id
          key
          tag
          value
        }
      }
    }`, { userId, tag }, false, tag + userId)
  }

  getRiskAssessmentData(userId: string): Subscribable<any> {
    let gl = gql` query getRiskAssessmentData($userId: ID!) {
      usersManagement {
        __typename
        id
        assessmentData(userId: $userId){
          __typename
          riskAssessmentData
          riskRating
        }
      }
    }`;
    // this.addRefetchQuery(gl, { userId })
    return this.unwrapData('assessmentData', this.query(gl, { userId }, false, 'risk' + userId))
  }
  setCheckResult(data: any): Subscribable<any> {
    return this.mutation(gql` mutation setCheckResult($userId: ID, $pepHit: Boolean, $watchListHit: Boolean, $adverseMediaHit: Boolean) {
      usersManagement {
        setCheckResult(userId: $userId, pepHit: $pepHit, watchListHit: $watchListHit, adverseMediaHit: $adverseMediaHit) {
          riskAssessmentData
          riskRating
        }
      }
    }`, data)
  }
  setRiskAssessmentData(data: any): Subscribable<any> {
    return this.mutation(gql` mutation setRiskAssessmentData($userId: ID!, $riskRating: Int) {
      usersManagement {
        setRiskRate(userId: $userId, riskRating: $riskRating) {
          riskAssessmentData
          riskRating
        }
      }
    }`, data,)
  }

  public report(filters: any = null): any {
    return this.query(gql`
    query riskAssesmentReport {
      
      usersManagement { id __typename riskAssesmentReport }
      __typename
    }
  `,
      { filters },
      false, 'riskAssesmentReport'
    );
  }
  deleteAccountSteps(userId: string): Subscribable<any> {
    return this.query(
      this.deleteAccountQuery,
      {userId},
    );
  }
  
  public deleteUserAccount(userId: string) {
    return this.apollo.mutate({
      mutation: gql`mutation deleteUserAccount($userId: ID!) {
        usersManagement {
          deleteUserAccount(userId: $userId) {
            id
            status
            infoMessage
            steps {
              description status executed resolved title 
              infoMessage statusMessage
            }
          }
        }
      }
      `,
      refetchQueries: [
        { query: this.deleteAccountQuery,
        variables: { userId: userId } }
      ],
      variables: { userId }
    });
  }
}
