import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BehaviorSubject, Unsubscribable } from 'rxjs';
import { CustomValidators } from 'src/app/_helpers/custom.validators';
import { AddressService } from 'src/app/_services/address.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';

import { PublicService } from 'src/app/_services/public.service';
import { UiService } from 'src/app/_services/ui.service';
import { EndUserService } from 'src/app/_services/user/enduser.service';
import { environment } from 'src/environments/environment';


interface JsonFormControls {
  description: string
  editable: boolean
  group: string
  groupPolicy: string
  label: string
  max: string
  maxLength: string
  min: string
  minLength: string
  name: string
  orderNumber: string
  pattern: string
  required: boolean
  section: string
  sectionName: string
  type: string
  value: string
  visible: boolean

}

export interface JsonFormData {
  fields: JsonFormControls[];
}

@Component({
  selector: 'app-form-configuration',
  templateUrl: './form-configuration.component.html',
  styleUrls: ['./form-configuration.component.scss'],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: MAT_DATE_FORMATS, useValue: PublicService.getDateInputFormat() }
  ]
})
export class FormConfigurationComponent implements OnInit {

  noConfigurationLoadedError = false;
  public environment = environment;
  addressData: BehaviorSubject<any[]> = new BehaviorSubject([]);
  unsubSearchAddress$: Unsubscribable;
  unsubSearchAddressDetails$: Unsubscribable;



  _formData: any;
  get patchValue(): any {
    return this._formData;
  }
  @Input() set patchValue(_value: any) {
    if (!_value)
      return;
    var value = Object.assign({}, _value);
    var keys = Object.keys(value);
    keys.forEach(key => {
      if (typeof value[key] == 'object' && value[key]?.id) {
        value[key + 'Id'] = value[key].id
      }
    });
    this._formData = value;
    if (this._formData?.countryId) {
      this.selectedCountry(this._formData?.countryId);
    }
    this.myForm.patchValue(value);
  }

  hide = true;
  password: string;
  countries: any;
  country: any;
  confirmPassword: string;

  formData: JsonFormData;
  group: any;
  confType: string;
  relationships: any;
  states: any;
  minDate: Date;
  maxDate: Date;
  formValid: any;
  currentUser: any;
  sectionName: any;
  authMethod: string = AuthenticationService.AUTHMETHID_BASIC;
  filesUploaded: any[] = [];

  @Input() fieldsGroup: string;
  @Input() config: any;
  @Input() recipientData: any;
  @Input() endUserData: any;
  @Output() sendForm = new EventEmitter<any>();
  @Output() messageEvent = new EventEmitter<any>();
  @Output() validation = new EventEmitter<any>();
  @Output() formDataLoaded = new EventEmitter<any>();


  public myForm: UntypedFormGroup = this.fb.group({});

  message: any;
  kycForm: any;
  checkboxList = [];
  currentYear = new Date().getFullYear();
  currDate = new Date().getDate() + 1;
  currMonth = new Date().getMonth();

  user: any;
  user$: any;
  unsubCountry$: Unsubscribable;
  countries$: any;
  countries1$: any;
  autocompleteField: any;



  constructor(
    public publicService: PublicService,
    private auth: AuthenticationService,
    private userService: EndUserService,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private ui: UiService,
    private _http: HttpClient,
    private addressService: AddressService,
  ) {
    this.relationships = this.publicService.getRelationships();
    this.countries$ = this.publicService.countries(true, null, null);
    this.countries1$ = this.publicService.countries(null, true, null);
  }

  ngOnInit(): void {

    this.confType = this.fieldsGroup;
    if (this.confType === 'user') {
      const $countrsub = this.publicService.countries(true, null, null).subscribe(countries => {
        this.countries = countries;
        this.country = this.countries?.find(x => x?.isoCode3 === 'SWE');
        if (!this.country) {
          this.country = this.countries?.find(x => x.isActive);
          this.selectedCountry(this.country.id);
        } else {
          if (this.country?.isActive) {
            this.selectedCountry(this.country.id);
            this.authMethod = AuthenticationService.AUTHMETHID_DIGLIAS;
          } else {
            this.country = this.countries?.find(x => x.isActive);
            if (this.country) {
              this.selectedCountry(this.country.id);
            } else {
              this.noConfigurationLoadedError = true;
            }
          }
          this.cdr.detectChanges();
        }
        $countrsub.unsubscribe();
      });
    }
    if (this.confType === 'updateUser') {
      this.publicService.countries(true, null, null).subscribe(countries => {
        this.countries = countries;
        this.selectedCountry(this.endUserData.user.country.id);
      });
    }
    if (this.confType === 'recipient') {
      const $countrsub = this.publicService.countries(null, true, null).subscribe({
        next: countries => {
          this.countries = countries;
          this.country = this.countries?.find(x => x.isActive);
          if (this.patchValue?.country) {
            return this.selectedCountry(this.patchValue.country.id);
          }

          this.selectedCountry(this.country.id);
          $countrsub.unsubscribe();
        }
      });
    }
    if (this.confType === 'KYC') {
      this.user$ = this.userService.currentUser().subscribe(user => {
        this.user = user;
        this.group = this.config;
        this.formData = this.config
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.states = this.publicService.states(this.currentUser.user?.country?.isoCode2);
        this.createForm(this.formData.fields);
      });
    }


  }

  selectedCountry(countryId: any) {
    this.country = this.countries?.find(x => x.id === countryId);
    this.states = this.publicService.states(this.country?.isoCode2);
    if (this.country) {
      this.publicService.getFieldsConfig(this.country?.isoCode2, this.confType).subscribe({
        next: (fields: JsonFormData) => {
          if (fields && fields[0]) {
            this.noConfigurationLoadedError = false;
            this.formData = fields[0];
            this.createForm(this.formData.fields);
            this.myForm.controls?.countryId?.setValue(this.country.id);
            this.formDataLoaded.emit(this.formData);

            this.cdr.detectChanges();
          } else {
            this.noConfigurationLoadedError = true;
          }
        }
      });

    }

    if (this.country?.isoCode2 === 'SE') {
      this.authMethod = AuthenticationService.AUTHMETHID_DIGLIAS;
    } else {
      this.authMethod = AuthenticationService.AUTHMETHID_BASIC;
    }
  }

  createForm(fields: JsonFormControls[]) {
    this.myForm = this.fb.group({});
    this.myForm.addControl('id', this.fb.control(null, []));

    if (this.confType === 'recipient') { this.sectionName = [...new Set(fields.map(item => item.section))]; }
    for (const field of fields) {
      if (field.type === 'address-autocomplete') 
        this.autocompleteField = field.name;
      const validatorsToAdd = [];
      if (field.visible == false) continue;
      for (const [key, value] of Object.entries(field)) {
        if (value == null) continue;
        if (this.confType == 'user' && field.name == 'countryId') {
          continue;
        }
        switch (key) {
          case 'email':
            if (value) {
              validatorsToAdd.push(Validators.email);
            }
            break;
          case 'minLength':
            validatorsToAdd.push(Validators.minLength(value));
            break;
          case 'required':
            if (value)
              validatorsToAdd.push(Validators.required, CustomValidators.noWhitespaceValidator());
            if (field.type == 'checkbox' && value)
              validatorsToAdd.push(Validators.requiredTrue);
            break;
          case 'maxLength':
            validatorsToAdd.push(Validators.maxLength(value));
            break;
          case 'pattern':
            validatorsToAdd.push(Validators.pattern(value));
            break;
          case 'nullValidator':
            if (value) {
              validatorsToAdd.push(Validators.nullValidator);
            }
            break;
          default:
            break;
        }
      }
      if (this.recipientData) {
        const fbcont = this.fb.control(null, validatorsToAdd);
        if (!field.editable) {
          fbcont.disable();
        }
        const formData = this.myForm.addControl(null, fbcont);

      } else if (this.endUserData) {
        const fbcont = this.fb.control(this.endUserData.user[field.name], validatorsToAdd);
        if (!field.editable) {
          fbcont.disable();
        }
        const formData = this.myForm.addControl(field.name, fbcont);
        // this.myForm.addControl(field.name, this.fb.control(this.endUserData.user[field.name], validatorsToAdd));
      } else {
        if (this.confType === 'KYC') {
          switch (field.name) {
            case 'firstName':
            case 'middleName':
            case 'lastName':
            case 'birthDate':
            case 'gender':
            case 'territory':
            case 'city':
            case 'addressLine1':
            case 'unitNumber':
            case 'zip':
              this.myForm.addControl(field.name, this.fb.control(this.user[field.name], validatorsToAdd));
          }
        }
        this.myForm.addControl(field.name, this.fb.control(field.editable ? field.value : { value: field.value, disabled: true }, validatorsToAdd));
      }
    }
    if (this.patchValue) {
      this.myForm.patchValue(this.patchValue)
    }
    this.formValid = this.myForm.valueChanges.subscribe(val => {
      this.validation.emit(this.myForm.dirty)
      if (val[this.autocompleteField]) {
        let countryCode = this.countries?.find(x => x.id === this.myForm.value?.countryId)?.isoCode2;
        if (!countryCode) {
          countryCode = this.auth?.user?.country?.isoCode2;
        }
        this.getGoogleMapsData(val[this.autocompleteField], countryCode);
      }
    })

  }

  getGoogleMapsData(address, country) {
    this.unsubSearchAddress$ = this.addressService.addressLookUp(`input=${address}&types=address&components${country ? '=country:' + country : ''}`, 'autocomplete').subscribe(data => {
      let tmp = JSON.parse(data['addressLookup']);
      this.addressData.next(tmp['predictions']);
    })
  }
  addressChoosen(ev, field) {
    let placeId = ev.option.value.place_id;
    this.unsubSearchAddressDetails$ = this.addressService.addressLookUp(`fields=address_components&place_id=${placeId}`, 'details').subscribe(data => {
      let place = JSON.parse(data['addressLookup']);
      place['result']['address_components'].forEach(el => {
        if (el['types'].includes('postal_code')) {
          this.myForm.patchValue({ zip: el.long_name });
        }
        if (el['types'].includes('locality') || el['types'].includes('sublocality') || el['types'].includes('postal_town')) {
          this.myForm.patchValue({ city: el.long_name });
        }
        let unsubStates$ = this.states.subscribe({
          next: res => {
            let stateId = res?.find(state => state?.name === el?.long_name)?.id;
            if (stateId)
              this.myForm.patchValue({ territory: stateId });
            unsubStates$?.unsubscribe();
          }
        })
      })

    })
    this.myForm.get(field).patchValue(this.myForm.value[field].description?.split(',')[0]);

  }
  getMin(data: any) {
    if (data === null) { return; }
    return new Date(this.currentYear, this.currMonth + data, this.currDate);
  }

  getMax(data: any) {
    if (data === null) { return; }
    return new Date(this.currentYear - data, this.currMonth, this.currDate);
  }

  handleFileInput(files: any, documnetName: any) {
    this.publicService.upload(files, null, this.auth.user.id, null, null, null, true).subscribe((uploadedFiles: any[]) => {
      this.myForm.controls[documnetName].setValue(uploadedFiles[0]?.id)
      this.filesUploaded[uploadedFiles[0]?.id] = uploadedFiles[0];
    });
  }

  removeImage(key) {
    var deleteImage = {}
    deleteImage[key] = null;
    this.myForm.patchValue(deleteImage)
  }

  confPassword(event) {
    this.confirmPassword = event.target.value;
  }

  // onChange(value) {
  //   this.iAgreeStatus = value.checked;
  //   this.checkbox = !value.checked;
  // }

  onChangee(event: any) {
    if (event.source.checked) {
      this.checkboxList.push(event.source.value);
    } else {
      this.checkboxList = this.checkboxList.filter((item) => { return item !== event.source.value })
    }
  }

  enableButton() {
    this.validation.emit(true)
  }

  onSubmit() {
    this.getFormValidationErrors()
    this.myForm.markAllAsTouched();
    if (this.confType === 'user') {
      var form = this.clearJsonFields(this.myForm.value);
      // if (this.iAgreeStatus != true) {
      //   this.checkbox = true;
      //   this.ui.snack('Please fill up all required fields.');
      //   return;
      // }
      if (this.country?.isoCode2 === 'SE') {
        this.authMethod = AuthenticationService.AUTHMETHID_DIGLIAS;
      } else {
        this.authMethod = AuthenticationService.AUTHMETHID_BASIC;
        if (this.myForm.get('password').value !== this.confirmPassword) {
          return this.ui.snack('Password and confirm password are not the same.');;
        }
      }
      form.countryId = this.country.id;
      form.authMethod = this.authMethod;
      if (this.myForm.valid) {
        this.sendForm.emit(form)
      }
    } else if (this.confType === 'recipient') {
      this.myForm.controls.countryId.setValue(this.country.id);
      // var form = this.clearJsonFields(this.myForm.value);
      // form.id = this.recipientData.id
      if (!this.myForm.valid) {
        this.ui.snack('Please fill up all required fields.');
      }
      if (this.myForm.valid) {
        this.sendForm.emit(this.myForm.value)
      }
    } else if (this.confType === 'updateUser') {
      if (this.myForm.valid) {
        this.sendForm.emit(this.myForm.value)
      } else {
        this.ui.snack('Please fill up all required fields.');
      }
    } else if (this.confType === 'KYC') {
      this.kycForm = this.myForm.value;
      if (this.group.name === 'consents') {
        this.kycForm = this.checkboxList;
      }
      this.messageEvent.emit([this.kycForm, this.group.name, this.myForm.valid, this.group.group])
    }
  }

  clearJsonFields(jsonToClear) {
    var excessKeys = [];
    for (let key in jsonToClear) {
      if (this.formData.fields.filter((element) => element.name == key && element.visible == true).length != 1) {
        excessKeys.push(key);
      }
    }
    excessKeys.forEach((element) => {
      delete jsonToClear[element];
    });
    return jsonToClear;
  }

  showErrors(field) {
    let err = '';
    let e = this.myForm.get(field)?.errors;
    if (e?.minlength) {
      err += e.minlength.actualLength + '/' + e.minlength.requiredLength;
    }
    if (e?.maxlength) {
      err += e.maxlength.actualLength + '/' + e.maxlength.requiredLength;
    }
    return err;
  }
  getMoovTermsToken(frmCtl: AbstractControl) {
    if (frmCtl.value) {
      frmCtl.setValue(null); // don't touch this 
      frmCtl.disable();
      fetch('https://api.moov.io/tos-token', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // 'Referer': 'https://wiccon-tango-dev.azurewebsites.net/'
        },
      })
        .then((response) => response.json())
        .then((data) => {
          frmCtl.enable();
          frmCtl.setValue(data?.token);
        })
        .catch((error) => {
          this.ui.snack("Unable to accept T&C");
          frmCtl.enable();
          frmCtl.setValue(null);
        });
    } else {
      frmCtl.setValue(null);
    }

  }
  getFormValidationErrors() {
    return;
  }
  ngOnDestroy(): void {
    if (this.formValid) {
      this.formValid?.unsubscribe();
    }
    this.unsubSearchAddress$?.unsubscribe();
    this.unsubSearchAddressDetails$?.unsubscribe();
  }
}

