import { gql } from "apollo-angular";

const AdminUserFragmentBasic = gql` fragment AdminUserFragmentBasic on AdminUserType  {
    id email firstName lastName
}`;

const EndUserFragmentBasic = gql` fragment EndUserFragmentBasic on EndUserType  {
    id email firstName lastName
}`;
const UserInfoFragment = gql` fragment UserInfoFragment on UserInfoType  {
    id firstName lastName middleName
}`;


const RecipientOnAdminTransactionFragmentBasic = gql`fragment RecipientOnAdminTransactionFragmentBasic on AdminTransactionType  {
    recipientId
    recipientPhone
    recipientName
    recipientFirstName
    recipientMiddleName
    recipientLastName
    recipientCountry
    recipientAddress1
    recipientAddress2
    recipientCity
    recipientCountry
    recipientEmail
    recipientPhone
}`;
const RecipientOnTransactionFragmentBasic = gql`fragment RecipientOnTransactionFragmentBasic on Transaction  {
    recipientId
    recipientPhone
    recipientName
    recipientFirstName
    recipientMiddleName
    recipientLastName
    recipientCountry
    recipientAddress1
    recipientAddress2
    recipientCity
    recipientCountry
    recipientEmail
    recipientPhone
}`;

const RecipientFragmentBasic = gql`fragment RecipientFragmentBasic on Recipient  {
    id email created
    firstName lastName middleName name 
}`;

const DocumentFragmentBasic = gql`fragment DocumentFragmentBasic on DocumentType  {
    id
    documentNumber
    documentExp
    documentIssuer
    documentSource
    documentState
    isPrimary
    created
    # DocumentCategoryType
    category { id code name }
}`;

const DocumentFragmentFull = gql`fragment DocumentFragmentFull on DocumentType  {
    id ...DocumentFragmentBasic
    cardNumber
    category { code id name }
    created
    description
    files {
        id name size document { id } description created contentType 
    }
    documentNote
}
${DocumentFragmentBasic}
`;
const FilesFragmentBasic = gql` fragment FilesFragmentBasic on FileType  {
    contentType
    created
    description
    document {id ...DocumentFragmentBasic }
    id
    name
    size
}
${DocumentFragmentBasic}
`;




export {
    AdminUserFragmentBasic,
    FilesFragmentBasic,
    DocumentFragmentBasic,
    DocumentFragmentFull,
    EndUserFragmentBasic,
    RecipientOnTransactionFragmentBasic,
    RecipientOnAdminTransactionFragmentBasic,
    UserInfoFragment,
    RecipientFragmentBasic
};