import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { PublicService } from 'src/app/_services/public.service';
import { UserRecipientsService } from 'src/app/_services/user/recipients.service';
import { OnboardingType, Recipient } from 'src/app/_graphql/schema';
import { Subscribable } from 'rxjs';
import { EndUserService } from 'src/app/_services/user/enduser.service';

@Component({
  selector: 'app-recipients-selector',
  templateUrl: './recipients-selector.component.html',
  styleUrls: ['./recipients-selector.component.scss']
})
export class RecipientsSelectorComponent implements OnInit, OnDestroy {
  recipients$: any;
  @Input() mode: 'normal' | 'chooseBox' = 'normal';
  @Output() selectionChange = new EventEmitter<any>();
  items$: Subscribable<Recipient[]>;
  onboarding$: Subscribable<OnboardingType[]>

  @ViewChild('widgetsContent', { read: ElementRef, static: false }) public widgetsContent: ElementRef<any>;

  constructor(
    public publicService: PublicService,
    public service: UserRecipientsService,
    public modalLauncherService: ModalLauncherService,
    public endUserService: EndUserService

  ) {
    this.items$ = this.service.all(null, false);
    this.onboarding$ = endUserService.fetchOnboarding();
  }

  ngOnInit() {
   }


  sendMoney(recipient) {
    if (this.mode !== 'normal') {
      this.selectionChange.emit(recipient);
      return;
    }
    this.modalLauncherService.sendMoney(recipient);
  }


  ngOnDestroy() {
    if (this.recipients$) {
      this.recipients$.unsubscribe();
    }
  }

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 350), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 350), behavior: 'smooth' });
  }

  createRecipient() {
    this.modalLauncherService.newRecipient();
  }
}
