<form [formGroup]="form">
    <div mat-dialog-title>
        {{ 'Set Check Results'|translate }}
    </div>

    <mat-dialog-content>
        <!-- <p>If user data is discovered on PEP or sanction lists during background checks, please utilize this form to
            record results.</p> -->
        <p>Is this user possibly <strong>identified as a PEP</strong>?</p>
        <mat-radio-group formControlName="pepHit">
            <div class="io-my-1">
                <mat-radio-button color="primary" class="io-mr-2" [value]="true">
                    Yes
                </mat-radio-button>

                <mat-radio-button color="primary" class="io-mr-2" [value]="false">
                    No
                </mat-radio-button>
            </div>
        </mat-radio-group>
        <p class="io-pt-2">Is this user possibly <strong>listed on any sanctions lists</strong>?</p>
        <mat-radio-group formControlName="watchListHit">
            <div class="io-my-1">
                <mat-radio-button color="primary" class="io-mr-2" [value]="true">
                    Yes
                </mat-radio-button>

                <mat-radio-button color="primary" class="io-mr-2" [value]="false">
                    No
                </mat-radio-button>
            </div>
        </mat-radio-group>
        <p class="io-pt-2">Is this user possibly <strong>listed on Adverse Media</strong>?</p>
        <mat-radio-group formControlName="adverseMediaHit">
            <div class="io-my-1">
                <mat-radio-button color="primary" class="io-mr-2" [value]="true">
                    Yes
                </mat-radio-button>

                <mat-radio-button color="primary" class="io-mr-2" [value]="false">
                    No
                </mat-radio-button>
            </div>
        </mat-radio-group>


    </mat-dialog-content>

    <mat-dialog-actions [formGroup]="form" align="end">
        <button class="no-button" mat-stroked-button mat-dialog-close>Cancel</button>
        <button class="yes-button" mat-raised-button mat-dialog-close [disabled]="!form.valid"
            (click)="onSubmit()">Submit</button>
    </mat-dialog-actions>
</form>