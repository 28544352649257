import { Component, OnInit, Inject, Optional } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UiService } from 'src/app/_services/ui.service';
import { AdminTransactionsService } from 'src/app/_services/admin/admin-transactions.service';
import { CustomValidators } from 'src/app/_helpers/custom.validators';

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss']
})
export class AddNoteComponent implements OnInit {
  form = this.fb.group({
    note: ['', [Validators.required, CustomValidators.noWhitespaceValidator()]],
    transactionId: [this.data.transactionId]
  });
  constructor(
    private dialogRef: MatDialogRef<AddNoteComponent>,
    private ui: UiService,
    public service: AdminTransactionsService,
    private fb: UntypedFormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any = {}
  ) {
    if (this.data.transactionId) {
      this.form.controls.transactionId.setValue(this.data.transactionId);
    }
  }

  ngOnInit(): void {
  }
  addNote() {
    this.service.addNote(this.form.value).subscribe(
      res => {
        this.ui.snack('Note added');
        this.dialogRef.close();
      },
      err => {
        this.ui.snack('Error in adding note.');
      }
    )
  }

}
