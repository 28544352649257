import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'filterExtractValues' })
export class FilterExtractValues implements PipeTransform {
    transform(list: any[] | null, path: string[]) {
        if (!list || !path) {
            return [];
        }
        let r = [];
        list.filter((item: any) => {
            var _item = item;
            for (let p of path) {
                if (_item[p] == undefined) {
                    return false;
                }
                _item = _item[p];
            }
            if (r.indexOf(_item) == -1)
                r.push(_item);
        });
        return r;
    }
}

