<!-- <pre>{{ uid }}</pre>
<pre>{{this.form.value|json}}</pre>
<pre>{{customKey}}</pre> -->
<list-wrapper #wrapper [mainService]="service" [managePerm]="auth.perm.FlagsManage" [readPerm]="auth.perm.FlagsRead"
  [hideAddBtn]="!(user$ | async)" (onEdit)="editItem($event)" (onNew)="newItem()" [customKey]="customKey">
  <form [formGroup]="form" class="io-row-m width-100" toolbox>
    <mat-form-field class="width-mff" appearance="outline">
      <mat-label>{{ 'Level' | translate}}</mat-label>
      <mat-select formControlName="level">
        <mat-option [value]="null">{{ 'All' |translate }}</mat-option>
        <mat-option value="USER">{{ 'User' |translate }}</mat-option>
        <mat-option value="TRANSACTION">{{ 'Transaction' |translate }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="width-mff" appearance="outline">
      <mat-label>{{ 'Status' | translate}}</mat-label>
      <mat-select formControlName="resolved">
        <mat-option [value]="null">{{ 'All' |translate }}</mat-option>
        <mat-option [value]="true">{{ 'Resolved' |translate }}</mat-option>
        <mat-option [value]="false">{{ 'Unresolved' |translate }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="width-mff" appearance="outline">
      <mat-label>{{ 'Type' | translate}}</mat-label>
      <mat-select formControlName="code">
        <mat-option [value]="null">{{'All'|translate}}</mat-option>
        <mat-option *ngFor="let flag of flags$ | async" [value]="flag.code">
          {{ flag.name | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div style="display: flex; align-items: center;">
      <mat-checkbox color="primary" class="on-hold" #checkbox formControlName="hasNoTicket">
        {{'Has no ticket' | translate}}</mat-checkbox>
    </div>
  </form>
  <div class="io-py-1">
    <!-- (click)="wrapper.edit(item)" -->

    <app-admin-flags-list-item *ngFor=" let item of items;" [item]="item" [isClickable]="true"
      (click)="wrapper.edit(item)">
    </app-admin-flags-list-item>
  </div>
</list-wrapper>