import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CountriesComponent } from './countries/countries.component';
import { LoginComponent } from './login/login.component';
import { PublicComponent } from './wrapper/public.component';
import { PasswordComponent } from './login/forgot-password/password.component';
import { RegisterComponent } from './register/register.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { PayToTermsComponent } from './term/payto-terms/payto-terms.component';

const routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    children: [
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: 'login/error/:error', component: LoginComponent },
      { path: 'login/token/:token', component: LoginComponent },
      { path: 'password', component: PasswordComponent },
      { path: 'resetPassword', redirectTo: '/reset-password' },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'reset-password/:username', component: ResetPasswordComponent },
      { path: 'reset-password/:username/:code', component: ResetPasswordComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'terms/payto', component: PayToTermsComponent },
      // { path: 'verify', component: VerifyUserComponent },
      // { path: 'verify/:username', component: VerifyUserComponent },
      // { path: 'phoneverify', component: PhoneVerifyComponent },
      // { path: 'phoneverify/:username', component: PhoneVerifyComponent },
      { path: 'home', component: HomeComponent },


      { path: '**', redirectTo: '/login' },

      // { path: 'how', component: HowComponent },
      // { path: 'help', component: HelpComponent },
      // { path: 'term', component: TermComponent },
      // { path: 'faq', component: FaqComponent },
      // { path: 'contact', component: ContactComponent },
      // { path: 'countries', component: CountriesComponent },
      {
        path: 'calculate/:countryFrom/to/:countryTo',
        component: CountriesComponent
      }
    ]
  },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicRoutingModule { }
