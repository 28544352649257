import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscribable } from 'rxjs';
import { BaseService } from './admin/base.service';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AddressService extends BaseService<any>{

  constructor(protected apollo: Apollo, private http: HttpClient) {
    super(apollo);
   }

   addressLookUpQuery = gql`
   query addressLookup($query: String, $type: String) {
    public {
      id
      addressLookup(query: $query, type: $type) 
  }
  }
  `
  
  public addressLookUp(query, type): any {
   return this.query(this.addressLookUpQuery, {query: query, type: type})
  
}

}



