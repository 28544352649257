import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UiService } from 'src/app/_services/ui.service';
import { AdminCurrenciesService } from 'src/app/_services/admin/admin-currencies.service';
import { CustomValidators } from 'src/app/_helpers/custom.validators';

@Component({
  selector: 'app-currency-item',
  templateUrl: './currency-item.component.html',
  styleUrls: ['./currency-item.component.scss']
})
export class CurrencyItemComponent {

  form = this.fb.group({
    id: [this.data.id],
    isoCode: [this.data.isoCode, [Validators.required, Validators.minLength(3), Validators.maxLength(3), CustomValidators.noWhitespaceValidator()]],
    fxSpread: [this.data.fxSpread, Validators.required],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private service: AdminCurrenciesService,
    private dialogRef: MatDialogRef<CurrencyItemComponent>,
    private ui: UiService
  ) { }


  onSubmit() {
    if (this.form.valid) {
      var d = this.form.value;
      var s = !d.id ? this.service.create(d) : s = this.service.modify(d);
      var k = s.subscribe({
        next: x => {
          this.ui.snack('Currency ' + (d.id ? 'saved!' : 'created!'));
          k?.unsubscribe()
          this.dialogRef.close();
        }, error: e => {
          this.ui.snack(e.message.replace('GraphQL error:', ''));
          k?.unsubscribe()
        }
      });
    }
  }
}
