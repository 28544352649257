<ng-container [formGroup]="form">
  <mat-form-field [appearance]="appearance">
    <mat-label *ngIf="appearance == 'outline'">{{ placeholder | translate }}</mat-label>
    <mat-icon matSuffix *ngIf="form.value.countryControl"
      (click)="!this.form.controls['countryControl'].disabled ? clearInput() : null" class="arrow"
      [ngStyle]="{'cursor': !this.form.controls['countryControl'].disabled ? 'pointer' : 'auto', 'color': this.form.controls['countryControl'].disabled ? '#00000061' : 'null'}" >close</mat-icon>
    <mat-icon matSuffix *ngIf="!form.value.countryControl" class="arrow">arrow_drop_down</mat-icon>
    <span matSuffix>
      <ng-content select="[matSuffix]"></ng-content>
    </span>
    <app-country-flag matPrefix *ngIf="form.value.countryControl && showFlag" [item]="form?.value?.countryControl"
      [size]="'small'" class="flag"></app-country-flag>
    <input type="text" matInput formControlName="countryControl" [matAutocomplete]="autoGroup" (focus)="focusInput()"
    (blur)="onBlur()">
    <mat-autocomplete #autoGroup="matAutocomplete" [displayWith]="displayFn.bind(this)"
      (optionSelected)="selectionChangeEmit()" [panelWidth]="panelWidth">
      <mat-option disabled *ngIf="!items?.length" class="selection-country-label">
        {{ "List is empty" | translate }}
      </mat-option>
      <mat-option *ngFor="let element of items | filterValue: form.value.countryControl: ([optionValue[0]])"
        [value]="element" class="autoCompleteOption" [disabled]="element?.isActive === false">
        <div class="element-line">
          <app-country-flag *ngIf="showFlag" [item]="element" [size]="'small'"
            style="padding-right: 16px; margin-top: 4px"></app-country-flag>
          <a class="selection-country-label">{{ element[optionValue[0]] | translate }}</a>
        </div>
      </mat-option>
    </mat-autocomplete>
    <mat-hint>{{hintText | translate}}</mat-hint>

  </mat-form-field>
</ng-container>
