import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { CorridorType, CurrencyType } from 'src/app/_graphql/schema';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class AdminCorridorsService extends BaseService<CorridorType>{

  feeFields = gql`fragment FeeFields on FeeType  { id feeAmount feeTypeCode feeValueType toAmount }`;
  countryFields = gql`fragment CountryFields on CountryType  { id name isoCode2 isoCode3 currency region { id name code } }`;

  selectOneFields = gql`
    fragment CorridorSelectOneFields on CorridorType  {
      checkCurrency
      exchangeCorrectionRate
      fees { ...FeeFields }
      fixedFee
      from { ...CountryFields }
      id
      isActive
      maxAmount
      minAmount
      network { id name code extendedName }
      receivingCurrency
      service { id name }
      to { ...CountryFields }
      variableFee 
      outboundFees { ...FeeFields }
      variableFees { ...FeeFields }
  }
  ${this.feeFields}
  ${this.countryFields}
`;
  selectAllFields = gql`
    fragment CorridorSelectAllFields on CorridorType  {
      checkCurrency
      exchangeCorrectionRate
      fees { id feeAmount feeTypeCode feeValueType }
      fixedFee
      from { ...CountryFields }
      id
      isActive
      maxAmount
      minAmount
      network { id name extendedName }
      receivingCurrency
      service { id name }
      to { ...CountryFields }
      variableFee 
      outboundFees { ...FeeFields }
      variableFees { ...FeeFields }
  }
  ${this.feeFields}
  ${this.countryFields}
  `;
  // selectAllFields = gql`
  //   fragment CorridorSelectAllFields on CorridorType  {
  //     id
  //     from { id name isoCode3 region { id code } }
  //     to { id name isoCode3 region { id code } }
  //     minAmount maxAmount fixedFee variableFee
  //     isActive
  //     service { id name }
  //     network { id extendedName }
  // }`;




  constructor(protected apollo: Apollo) {
    super(apollo);
    this.initGql('corridor');
  }
}
