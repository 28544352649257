import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscribable } from 'rxjs';
import { KycLogType } from 'src/app/_graphql/schema';
import { AdminTransactionsHistoryService } from 'src/app/_services/admin/admin-transactions-history.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';

import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { PublicService } from 'src/app/_services/public.service';
import { UiService } from 'src/app/_services/ui.service';



@Component({
  selector: 'app-admin-kyc-log-item',
  templateUrl: './admin-kyc-log-item.component.html',
  styleUrls: ['./admin-kyc-log-item.component.scss']
})
export class AdminKycLogItemComponent {
  @Input() item: any;
  error = false;
  receipt: any;
  showHistory = false;
  $history: Subscribable<any>;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public auth: AuthenticationService,
    private ui: UiService,
  ) {

  }
  formatProvidedData(item: KycLogType) {
    var r = [];
    if (item.request?.address)
      r.push("Address")
    if (item.request?.driverLicence)
      r.push("Driver Licence")
    if (item.request?.medicare)
      r.push("Medicare")
    if (item.request?.passport)
      r.push("Passport")
    if (item.request?.personDetails)
      r.push("Person details")
    return r;
  }

  keyValueClean(item: any) {
    if (!item)
      return [];
    let result = [];
    let keys = Object.keys(item);
    keys.forEach(key => {
      if (key && key[0] != "_" && item[key]) {
        result.push({ key, value: item[key] })
      }
    })
    return result;
  }
  onShowHistory(event: MouseEvent, transaction: any) {
    event.preventDefault();
    event.stopPropagation();
    this.showHistory = !this.showHistory;
  }


}
