import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { DocumentNode } from "graphql";
import { of, Subscribable } from "rxjs";
import { catchError, first, map, take } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class BaseService<ReturnT = any, ImportT = any> {
    debug = !true;
    loading = false;
    filters: any;
    totalCount = 0;
    pageIndex = 0;

    mainScope = 'setup';
    removeTypeFromInputType = false;
    getMoreDetails = false;
    permissionProtected = false;

    selectOneFields!: DocumentNode;
    selectAllFields!: DocumentNode;
    private nodeName!: string;
    private nodeNamePlural!: string;

    selectOneQuery!: DocumentNode;
    selectAllQuery!: DocumentNode;
    createMutation!: DocumentNode;
    modifyMutation!: DocumentNode;
    deleteMutation!: DocumentNode;

    public refetchAdditionalQueries: any = [];

    queryAllParams = {
        skip: 0,
        take: 20
    };

    getPageRequest(filters = false) {
        var pr = {
            skip: this.queryAllParams.skip,
            take: this.queryAllParams.take
        }
        if (filters)
            pr['filters'] = filters;
        return { pageRequest: pr, getMoreDetails: this.getMoreDetails, permissionProtected: this.permissionProtected };
    }

    constructor(protected apollo: Apollo) {

    }

    public initGql(nodeName: string, nodeNamePlural: string | null = null) {
        this.nodeName = nodeName;
        this.nodeNamePlural = nodeNamePlural ? nodeNamePlural : `${nodeName}s`;

        this.createQueries();
    }
    public createQueries() {
        this.selectOneQuery = gql`
            query ${this.nodeName}One($id: ID!, $permissionProtected: Boolean = false) {
                ${this.mainScope} {
                    __typename
                    id @skip(if: $permissionProtected)
                    id @include(if: $permissionProtected)
                    ${this.nodeName}(id: $id) {
                        ...${this.camelNodeName()}SelectOneFields
                    }
                }
            }
            ${this.selectOneFields}
        `;

        this.selectAllQuery = gql`
            query ${this.nodeNamePlural}All($getMoreDetails: Boolean! = false, $permissionProtected: Boolean = false, $pageRequest: PageRequest${this.filters == false ? '' : this.camelNodeNamePlural() + 'Filter'}Type) {
                ${this.mainScope} {
                    __typename
                    id @skip(if: $getMoreDetails)
                    id @include(if: $getMoreDetails)
                    id @skip(if: $permissionProtected)
                    id @include(if: $permissionProtected)
                    
                    ${this.nodeNamePlural} (pageRequest: $pageRequest) {
                        data {
                            ...${this.camelNodeName()}SelectAllFields
                        }
                        totalCount
                    }
                }
            }
            ${this.selectAllFields}
        `;


        this.createMutation = gql`
            mutation create${this.camelNodeName()}($data: ${this.overrideCamelNodeName()}Input${this.removeTypeFromInputType ? '' : 'Type'}!) {
                ${this.mainScope} {
                    __typename
                    create${this.camelNodeName()}(data: $data) {
                        ...${this.camelNodeName()}SelectOneFields 
                    }
                }
            }
            ${this.selectOneFields}
        `;

        this.modifyMutation = gql`
            mutation update${this.camelNodeName()}($data: ${this.overrideCamelNodeName()}Input${this.removeTypeFromInputType ? '' : 'Type'}!) {
                ${this.mainScope} {
                    __typename
                    update${this.camelNodeName()}(data: $data) {
                        ...${this.camelNodeName()}SelectOneFields 
                    }
                }
            }
            ${this.selectOneFields}
        `;

        this.deleteMutation = gql`
            mutation delete${this.camelNodeName()}($id: ID!) {
                ${this.mainScope} {
                    __typename
                    delete${this.camelNodeName()}(id: $id) 
                }
            }
        `;
    }


    allWQ = [];
    allWQParams = [];

    public getPageRequestAllFilters(customKey = ""): any {
        let key = customKey || this.nodeNamePlural + 'All';
        return this.getPageRequest(this.allWQParams[key])
    }
    public allClose(customKey = "") {
        let key = (customKey || this.nodeNamePlural + 'All');
        if (this.allWQ[key]) {
            delete this.allWQ[key];
        }
    }
    public all(filters: any = null, useCache = true, customKey = "", fetchWithAdditionalData = false): Subscribable<ReturnT[]> {
        let key = (customKey || this.nodeNamePlural + 'All');
        this.log("Select ALL " + key)

        if (this.filters)
            filters = this.filters;

        this.loading = true;
        if (!this.allWQ[key]) {
            this.log(['Create WQ ' + key, filters])
            if (filters) {
                this.allWQParams[key] = filters;
            }
            this.allWQ[key] = this.apollo.watchQuery({
                query: this.selectAllQuery,
                fetchPolicy: useCache ? 'cache-first' : 'network-only',
                variables: this.getPageRequest(this.allWQParams[key]),
            })
            this.addRefetchQuery(this.selectAllQuery, this.getPageRequest(this.allWQParams[key]))
        }
        return this.allWQ[key].valueChanges.pipe(
            map((result: any) => {
                this.loading = false;
                this.log('Invoked WQ ' + key)
                if (!result || !result.data)
                    return null;
                if (fetchWithAdditionalData) {
                    this.log('fetchWithAdditionalData >' + fetchWithAdditionalData)
                    this.fetchMoreData(filters, key, true)
                }
                let k = result.data[this.mainScope][this.nodeNamePlural];
                if (k) {
                    this.totalCount = k.totalCount || 0;
                    return k.data;
                } else {
                    this.log('nada...')
                }
                return null;
            },
                catchError(error => {
                    this.log('Error WQ ' + key)
                    this.log(error);
                    return of(null);
                })),
        );
    }

    dropdownAllWQParams = [];
    dropdownAll(filters: any = null, useCache = true, customKey = ""): Subscribable<ReturnT[]> {

        let key = customKey || this.nodeNamePlural + 'All';
        this.log("Select ALL " + key)
        if (!this.dropdownAllWQParams[key]) {
            this.log('Create WQ ' + key)
            this.dropdownAllWQParams[key] = { skip: 0, take: null };
            if (filters) {
                this.dropdownAllWQParams[key]['filters'] = filters;
            }
            var f = { pageRequest: this.dropdownAllWQParams[key], getMoreDetails: false };
            this.dropdownAllWQParams[key] = this.apollo.watchQuery({
                query: this.selectAllQuery,
                fetchPolicy: useCache ? 'cache-first' : 'network-only',
                nextFetchPolicy: 'cache-first',
                variables: f
            })
            this.addRefetchQuery(this.selectAllQuery, f)
        }
        return this.dropdownAllWQParams[key].valueChanges.pipe(
            map((result: any) => {
                this.loading = false;
                this.log('Invoked WQ ' + key)
                if (!result || !result.data)
                    return null;

                let k = result.data[this.mainScope][this.nodeNamePlural];
                if (k) {
                    return k.data;
                } else {
                    this.log('nada...')
                }
                return null;
            },
                catchError(error => {
                    this.log('Error WQ ' + key)

                    this.log(error);
                    return of(null);
                })),
        );
    }


    oneWQ: Subscribable<ReturnT>[] = [];
    oneWQPip = [];
    public one(id: string, useCache = true, customKey = ""): Subscribable<ReturnT> {
        let key = customKey || this.nodeName + id;
        this.log('One ' + key + ' for ' + id)
        if (!this.oneWQ[key]) {
            this.log('Create WQ One ' + key + ' for ' + id)
            this.oneWQ[key] = this.apollo.watchQuery({
                query: this.selectOneQuery,
                variables: { id, permissionProtected: this.permissionProtected },
                fetchPolicy: useCache ? 'cache-first' : 'network-only'
            });
            this.addRefetchQuery(this.selectOneQuery, { id })
            this.oneWQPip[key] = this.oneWQ[key].valueChanges.pipe<ReturnT>(
                map<any, ReturnT>((result: any) => {
                    this.log('WCh WQ One ' + key + ' for ' + id)
                    this.loading = false;
                    if (!result || !result.data)
                        return null;

                    const keys = Object.keys(result.data);
                    if (result.data && keys.length) {
                        return result.data[keys[0]][this.nodeName]
                    }

                    return null;
                })
            );
        } else {
            this.log('Invoked WQ One ' + key + ' for ' + id)
        }

        return this.oneWQPip[key]
    }

    public addRefetchQuery(query: DocumentNode, variables: any = null) {
        this.refetchAdditionalQueries.push(
            { query, variables }
        );
    }
    public resetRefetchQuery() {
        this.refetchAdditionalQueries = [];
    }
    public fetchMoreData(filters: any = null, customKey = "", getMoreDetails = false) {
        let key = customKey || this.nodeNamePlural + 'All';
        if (filters) {
            this.allWQParams[key] = filters;
        }
        var v = this.getPageRequest(this.allWQParams[key]);
        if (getMoreDetails) {
            v.getMoreDetails = getMoreDetails;
        }
        this.loading = true;
        this.allWQ[key]?.fetchMore({
            variables: v,
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) { return prev; }
                return fetchMoreResult
            },
        }).then(x => { this.loading = false; });
    }

    public refetchData(customKey = "") {
        let key = customKey || this.nodeNamePlural + 'All';

        this.log('refetchData ' + key + (this.allWQ[key] ? ' OK' : ' Not initiated!'))
        this.allWQ[key]?.refetch();
    }
    public refetchOneData(id, customKey = "") {
        let key = customKey || this.nodeName + id;

        this.log('refetchData oneWQ ' + key + (this.oneWQ[key] ? ' OK' : ' Not initiated!'))
        this.oneWQ[key]?.refetch();
    }
    timer: ReturnType<typeof setTimeout>;

    public applyPager(pageIndex: number, pageSize: number, customKey = null) {
        this.log('applyPager')
        let key = customKey || this.nodeNamePlural + 'All';
        var filters = false;

        this.pageIndex = pageIndex;
        this.queryAllParams.take = pageSize;
        this.queryAllParams.skip = pageIndex * pageSize;

        new Promise((resolve) => {
            if (this.timer) {
                clearTimeout(this.timer);
            }

            this.timer = setTimeout(() => {
                resolve(
                    this.allWQ[key].refetch(this.getPageRequest(this.allWQParams[key]))
                )
            }, 500
            );
        });




        // this.fetchMoreData(this.filters == undefined ? filters : {});
    }

    public mutation(mutation: DocumentNode, data: any, update: any = null) {
        return this.apollo.mutate({
            mutation: mutation,
            refetchQueries: this.refetchAdditionalQueries,
            variables: data,
            update: update
        }).pipe(
            map((result: any) => {
                if (!result || !result.data)
                    return null;

                const keys = Object.keys(result.data);
                if (result.data && keys.length) {
                    return result.data[keys[0]];
                }

                return null;
            },
                catchError(error => {
                    return of(null);
                }))
        );
    }

    public query(query: DocumentNode, data: any = null, useCache = true, objectKey = null): Subscribable<ReturnT[]> {
        return this.apollo.watchQuery({
            query: query,
            fetchPolicy: useCache ? 'cache-first' : 'network-only',
            variables: data
        }).valueChanges.pipe(
            map((result: any) => {
                if (!result || !result.data)
                    return null;

                const keys = Object.keys(result.data);

                if (result.data && keys.length) {
                    if (result.data[keys[0]][objectKey])
                        return result.data[keys[0]][objectKey];
                    return result.data[keys[0]];
                }

                return null;
            },
                // catchError(error => {
                //   log(error);
                //   return of(null);
                // })
            ),
        );
    }


    public create(data: ImportT): Subscribable<ReturnT> {
        return this.unwrapData('create' + this.camelNodeName(), this.apollo.mutate<ImportT>({
            mutation: this.createMutation,
            refetchQueries: [
                ...this.refetchAdditionalQueries
            ],
            variables: { data }
        }));
    }
    public modify(data: any, data2: any = null): Subscribable<ReturnT> {
        return this.unwrapData('update' + this.camelNodeName(), this.apollo.mutate({
            mutation: this.modifyMutation,
            refetchQueries: [
                { query: this.selectOneQuery, variables: { id: data?.id } },
                ...this.refetchAdditionalQueries
            ],
            variables: { data, ...data2 }
        }));
    }

    public delete(id: string): Subscribable<ReturnT> {
        return this.mutation(this.deleteMutation, { id });
    }

    public unwrapData(key: string, data: any) {
        return data.pipe(take(1)).pipe(
            first(),
            map((result: any) => {
                if (result && result[key])
                    return result[key];
                if (!result || !result.data)
                    return null;
                const keys = Object.keys(result.data);
                if (result.data && keys.length) {
                    return result.data[keys[0]][key];
                }

                return null;
            },
                catchError(error => {
                    this.log(error);
                    return of(null);
                }))
        )
    }

    private camelNodeNamePlural() {
        if (!this.nodeName || this.nodeName.length == 0)
            return '';

        return this.nodeNamePlural.charAt(0).toUpperCase() + this.nodeNamePlural.slice(1);
    }

    private overrideCamelNodeName() {
        switch (this.camelNodeName()) {
            case "Manager":
                return "Admin";
            default:
                return this.camelNodeName();
        }
    }
    private camelNodeName() {
        if (!this.nodeName || this.nodeName.length == 0)
            return '';

        return this.nodeName.charAt(0).toUpperCase() + this.nodeName.slice(1);
    }
    log(log) {
        if (this.debug)
            console.log(log)
    }
}