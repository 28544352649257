import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/cdk/stepper';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BehaviorSubject, Observable, Subscribable, Unsubscribable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { KycService } from 'src/app/_services/kyc.service';
import { UiService } from 'src/app/_services/ui.service';
import { EndUserService } from 'src/app/_services/user/enduser.service';
import { PublicService } from 'src/app/_services/public.service';
import { environment } from 'src/environments/environment';
import { AddressService } from 'src/app/_services/address.service';
import { CustomValidators } from 'src/app/_helpers/custom.validators';



interface JsonFormControls {
  description: string
  editable: boolean
  group: string
  groupPolicy: string
  label: string
  max: string
  maxLength: string
  min: string
  minLength: string
  name: string
  orderNumber: string
  pattern: string
  required: boolean
  section: string
  sectionName: string
  type: string
  value: string
  values?: any
  visible: boolean
  properties: any
}

export interface JsonFormData {
  description?: string;
  name?: string;
  group?: string;
  groupPolicy?: string;
  label?: string;
  fields: JsonFormControls[];
}

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss'],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }
  ]
})
export class FormBuilderComponent implements OnInit {
  @Input() loading: boolean = false;
  @Input() hideSteps: boolean = false;

  @Output() onSubmitData = new EventEmitter<any>();

  @Input() confType: string;
  @Input() country: any;

  fieldsConfig: JsonFormData[] = [];
  simplifiedConfig = {};
  uniqueFormSections = [];
  oneRequiredDefaultView = {};
  form = this.fb.group({})

  states$: Subscribable<any>;
  stepperOrientation: Observable<StepperOrientation>;
  public environment = environment;
  unsubCountry$: Unsubscribable;
  countries: any;
  addressData: BehaviorSubject<any[]> = new BehaviorSubject([]);
  unsubSearchAddress$: Unsubscribable;
  unsubSearchAddressDetails$: Unsubscribable;
  unsubListentoElement$: Unsubscribable;

  autocompleteField: any;
  constructor(
    public auth: AuthenticationService,
    private userService: EndUserService,
    private publicService: PublicService,
    private fb: FormBuilder,
    private ui: UiService,
    private kycService: KycService,
    breakpointObserver: BreakpointObserver,
    private addressService: AddressService,
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
  }

  getMoovTermsToken(frmCtl: AbstractControl) {
    if (frmCtl.value) {
      frmCtl.setValue(null); // don't touch this 
      frmCtl.disable();
      fetch('https://api.moov.io/tos-token', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // 'Referer': 'https://wiccon-tango-dev.azurewebsites.net/'
        },
      })
        .then((response) => response.json())
        .then((data) => {
          frmCtl.enable();
          frmCtl.setValue(data?.token);
        })
        .catch((error) => {
          this.ui.snack("Unable to accept T&C");
          frmCtl.enable();
          frmCtl.setValue(null);
        });
    } else {
      frmCtl.setValue(null);
    }

  }

  public get formSections() {
    var t = Object.keys(this.form.controls);
    return t;
  }

  public sectionFields(section: string) {
    var t = Object.keys((this.form.get(section) as FormGroup).controls);
    return t;
  }

  getMin(data: any) {
    var currentYear = new Date().getFullYear();
    var currDate = new Date().getDate() + 1;
    var currMonth = new Date().getMonth();
    if (data === null) { return; }
    return new Date(currentYear, currMonth + data, currDate);
  }

  getMax(data: any) {
    var currentYear = new Date().getFullYear();
    var currDate = new Date().getDate() + 1;
    var currMonth = new Date().getMonth();
    if (data === null) { return; }
    return new Date(currentYear - data, currMonth, currDate);
  }

  createFieldValidators(sectionName, field) {
    var key, value;
    var validators = [];
    var keyValuePair = Object.entries(field);
    for ([key, value] of keyValuePair) {
      if (key === 'type' && value === 'address-autocomplete')
        this.autocompleteField = field.name;
      if (value == null) continue;
      switch (key) {
        case 'checkboxstring':
          // validators.push(Validators.required);
          break;
        case 'email':
          validators.push(Validators.email);
          break;
        case 'minLength':
          validators.push(Validators.minLength(value));
          break;
        case 'maxLength':
          validators.push(Validators.maxLength(value));
          break;
        case 'pattern':
          if (field.type != 'file-upload')
            validators.push(Validators.pattern(value));
          break;
        case 'nullValidator':
          validators.push(Validators.nullValidator);
          break;
        case 'required':
          if (value)
            validators.push(Validators.required, CustomValidators.noWhitespaceValidator());
          if (value && field.type == 'moov-terms-token')
            validators.push(Validators.nullValidator);
          if (value && field.type == 'checkbox')
            validators.push(Validators.requiredTrue);
          break;
      }
    }
    return validators;
  }
  setupForm() {
    this.fieldsConfig.forEach(section => {
      this.simplifiedConfig[section['name']] = {
        "description": section['description'],
        "name": section['name'],
        "group": section['group'],
        "groupPolicy": section['groupPolicy'],
        "label": section['label'],
        fields: []
      };
      var _fields = {};
      section.fields.forEach(field => {
        this.simplifiedConfig[section['name']].fields.push({
          'name': field['name'],
          'label': field['label'],
          'type': field['type'],
          'min': field['min'],
          'max': field['max'],
          'required': field['required'],
          'description': field['description'],
        });

        _fields[field['name']] = (field['type'] == 'checkboxstring'
          ?
          this.fb.array([], this.createFieldValidators(section['name'], field))
          :
          this.fb.control(null, this.createFieldValidators(section['name'], field)));
          
      });

      var _ctlGroup = this.fb.group(_fields)

      this.form.addControl(section['name'], _ctlGroup);
    });
    this.checkCustomValidations();
    this.form.setValidators(this.comparisonValidator());

    this.form.updateValueAndValidity();

    this.createUniqueSections();

  }

  checkCustomValidations():void {
    this.fieldsConfig.forEach(el => {
      el.fields.forEach(field => {
        if (field?.properties) {
          field.properties.forEach(property => {
            if (property.type === 'conditionalPattern') {
              let object = JSON.parse(property.value);
              const nestedProperties = object?.listenTo || [];
              const listenToFormElement = nestedProperties.reduce((nestedObject, property) => {
                return nestedObject?.get(property);
              }, this.form);
              this.form.get(el.name).get(field.name).addValidators(CustomValidators.conditionalValidator(listenToFormElement,
               object.rules));
               this.unsubListentoElement$ = listenToFormElement.valueChanges.pipe().subscribe(() => 
               this.form.get(el.name).get(field.name).updateValueAndValidity());
            }
          })
        }
      })
    })
  }
  getGoogleMapsData(address, country) {
    this.unsubSearchAddress$ = this.addressService.addressLookUp(`input=${address}&types=address&components${country ? '=country:' + country : ''}`, 'autocomplete').subscribe(data => {
      let tmp = JSON.parse(data['addressLookup']);
      this.addressData.next(tmp['predictions']);
    })
  }
  addressChoosen(ev) {
    let placeId = ev.option.value.place_id;
    this.unsubSearchAddressDetails$ = this.addressService.addressLookUp(`fields=address_components&place_id=${placeId}`, 'details').subscribe(data => {
      let place = JSON.parse(data['addressLookup']);
      place['result']['address_components'].forEach(el => {
        if (el['types'].includes('postal_code')) {
          this.form.controls['address'].patchValue({ zip: el.long_name });
        }
        if (el['types'].includes('locality') || el['types'].includes('sublocality') || el['types'].includes('postal_town')) {
          this.form.controls['address'].patchValue({ city: el.long_name });
        }
        let unsubStates$ = this.states$.subscribe({
          next: res => {
            let stateId = res.find(state => state?.name === el?.long_name)?.id;
            if (stateId)
              this.form.controls['address'].patchValue({ territory: stateId });
            unsubStates$?.unsubscribe();
          }
        })
      })

    })
    this.form.controls['address'].patchValue({ addressLine1: this.form.controls['address'].get('addressLine1').value.description?.split(',')[0] });
  }


  showSectionGroup(e, group) {
    this.oneRequiredDefaultView[group] = e;

    //RESET DATA ON CHANGE
    this.fieldsConfig.forEach((fc: any) => {
      if (fc?.name == e) {
        this.fieldsConfig.forEach((_fc: any) => {
          if (fc?.group == _fc?.group) {
            var fg = (this.form.get(_fc.name) as FormGroup);
            // Object.keys(fg.controls).forEach(key => {
            //   fg.get(key).setErrors(null);
            //   fg.get(key).setValue(null);
            //   fg.get(key).updateValueAndValidity()
            // });
            fg.reset()
          }
        });
      }
    })
    // document.querySelectorAll("form.section").forEach(e => { e.classList.add('hide') });
    // document.querySelectorAll("#form-" + e).forEach(e => { e.classList.remove('hide') });
  }
  createUniqueSections() {
    var keys = Object.keys(this.simplifiedConfig);
    var r = [];
    keys.forEach(key => {
      var itm = this.simplifiedConfig[key];
      if (r.filter(_r => _r.group == itm.group && _r.groupPolicy == itm.groupPolicy).length == 0)
        r.push({
          group: itm.group,
          groupPolicy: itm.groupPolicy,
          name: itm.name,
          label: 'OneRequired' == itm.groupPolicy ? itm.group : itm.label
        })
    });
    r.forEach(itm => {
      if (itm.groupPolicy == "OneRequired") {
        this.oneRequiredDefaultView[itm.group] = itm.name;
      }
    });
    this.uniqueFormSections = r;
  }
  public comparisonValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      this.formSections.forEach(section => {
        var sameGroups = [];
        this.formSections.forEach(_section => {
          if (this.simplifiedConfig[section].group == this.simplifiedConfig[_section].group) {
            sameGroups.push(_section);
          }
        });
        if ('AllRequired' == this.simplifiedConfig[section].groupPolicy) {
          var allReqValid = true;
          sameGroups.forEach(item => {
            var fc = this.form.get(item);
            if (!fc.valid) {
              allReqValid = false;
            }
          });
          sameGroups.forEach(item => {
            this.form.get(item).setErrors(allReqValid ? null : { allRequired: false })
          });

        }
        if ('OneRequired' == this.simplifiedConfig[section].groupPolicy) {

          var isOk = false;
          sameGroups.forEach(item => {
            var valid = this.form.get(item).valid;
            if (valid) {
              isOk = true;
            }
          });
          sameGroups.forEach(item => {
            this.form.get(item).setErrors(null)
            this.form.get(item).setErrors(isOk ? null : { oneRequired: false });
          });
        }
      });
      return;
    };
  }

  ngOnInit(): void {
    const $countrsub = this.publicService.countries(true, null, null).subscribe(countries => {
      this.countries = countries;
      $countrsub?.unsubscribe();
    })
    if ('kyc' == this.confType) {
      const local = JSON.parse(localStorage.getItem('currentUser'));
      this.states$ = this.publicService.states(local.user.country.isoCode2);
      var s = this.kycService.configurationKYC(local.user.country.isoCode2).subscribe({
        next: (fields: any) => {
          console.log('Patching KYC form with user data... You should see this only once...')
          if (fields) {

            this.fieldsConfig = [...fields];

            this.setupForm();
            this.prefillForm();
            if (this.autocompleteField) {
              let tmp = this.form.get('address').get('addressLine1').valueChanges.pipe(
              ).subscribe(data => {
                if (!this.form.get('address').get('addressLine1'))
                  return;
                let countryCode = this.auth?.user?.country?.isoCode2;
                this.getGoogleMapsData(data, countryCode);
              });

            }
          }
          s.unsubscribe();
        },
        error: e => {
          this.ui.snack(e.message.replace('GraphQL error:', ''));
          s.unsubscribe()
        }
      });
    }
    if ('Questionnaire' == this.confType) {
      const local = JSON.parse(localStorage.getItem('currentUser'));
      this.publicService.getFieldsConfig(local?.user?.country?.isoCode2 || null, 'Questionnaire').subscribe({
        next: x => {
          this.fieldsConfig = this.transformFieldsConfig(x);
          this.setupForm();
          this.prefillForm();
        }
      })
    }
  }

  transformFieldsConfig(config): JsonFormData[] {
    var data: JsonFormData = {
      "description": "",
      "name": "questionnaire",
      "group": 'questionnaire',
      "groupPolicy": "AllRequired",
      "label": "Questionnaire",
      fields: []
    };
    if (config[0])
      config[0].fields.forEach(field => {
        data.fields.push(
          field
        );
      });
    return [data];
  }



  isCompleted(_section: any) {
    if ('OneRequired' == _section.groupPolicy) {
      // if ('consents' == _section.name) {
      //   return this.checkboxList.length ? true : false;
      // } else {
      return this.form.get(_section.name).errors == null
      // }
    }
    return this.form.get(_section.name).valid
  }
  prefillForm() {
    if ('kyc' == this.confType) {
      let unsub = this.userService.currentUser().subscribe({
        next: x => {
          this.uniqueFormSections.forEach(section => {
            if (['personDetails', 'address'].indexOf(section.name) != -1) {
              var a = Object.assign({}, x);
              var keys = Object.keys(a);
              keys.forEach(key => {
                if (a[key] == null) {
                  delete a[key];
                }
              });
              this.form.get(section.name).patchValue(a);
              this.form.markAllAsTouched();
              // this.fieldsConfig.forEach((el: any) => {
              //   this.getFormValidationErrors(this.form.get(el.name));
              // })
            }
          });
          unsub?.unsubscribe();
        }
      })
    }
  }
  onSubmit() {
    // this.fieldsConfig.forEach((el: any) => {
    //   this.getFormValidationErrors(this.form.get(el.name));
    // })
    // if (!this.form.valid) {
    //   return
    // }
    var data = Object.assign({}, this.form.value);
    for (let el in data) {
      if (Object.values(data[el]).every(value => value === null))
        delete data[el];
    }

    if ('kyc' == this.confType) {
      if (data.hasOwnProperty('consents')) {
        var isAllCheckboxStrings = true;
        this.simplifiedConfig['consents'].fields.forEach(el => {
          if (el.type != "checkboxstring")
            isAllCheckboxStrings = false;
        })
        if (isAllCheckboxStrings) {
          data['consents'] = data['consents'].consents;
        }
        // data.consents = this.checkboxList;
      }
    }
    this.onSubmitData.emit(data)
  }
  ngOnDestroy(): void {
    this.unsubSearchAddress$?.unsubscribe();
    this.unsubSearchAddressDetails$?.unsubscribe();
    this.unsubListentoElement$?.unsubscribe();
  }

  isRequired(section, field) {
    return this.form?.get(section.name)?.get(field.name).errors?.hasOwnProperty('required') ? "*" : "";
  }

  shouldShowSectionInReview(section) {
    if (section.groupPolicy == 'AllRequired') {
      return true;
    } else if (section.name == 'consents') {
      return true;
    } else {
      var hasAnyEntries = false;
      section.fields.forEach(field => {
        if (this.form?.get(section.name)?.get(field.name).value) {
          hasAnyEntries = true;
        }
      });

      return hasAnyEntries;
    }
  }

  showErrors(section, field) {
    let err = '';
    let e = this.form.get(section).get(field).errors;
    if (e?.minlength) {
      err += e.minlength.actualLength + '/' + e.minlength.requiredLength;
    }
    if (e?.maxlength) {
      err += e.maxlength.actualLength + '/' + e.maxlength.requiredLength;
    }
    if (!err && e?.error)
      err = e?.error;
    return err;
  }

  getFormValidationErrors(frm) {
    Object.keys(frm.controls).forEach(key => {
      const controlErrors: ValidationErrors = frm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
  getFA(section: string, name: string): FormArray {
    return this.form.get(section).get(name) as FormArray
  }
  isElementInFormArrayData(section: string, name: string, value: string) {
    var fa = this.getFA(section, name);
    return fa.value.indexOf(value);
  }

  toggleFormArrayData(section: string, name: string, value: string) {
    var fa = this.getFA(section, name);
    var idx = this.isElementInFormArrayData(section, name, value);
    if (idx == -1) {
      fa.push(this.fb.control(value))
    } else {
      fa.removeAt(idx);
    }
  }
}

