<div mat-dialog-title>
    <span>{{ (data?.id ? 'Edit Agreement' : 'New Agreement') | translate }}</span>
    <button mat-icon-button mat-dialog-close class="close">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <form [formGroup]="form" class="main">
        <div class="io-row-l">
            <mat-form-field style="flex:2">
                <mat-label>{{'Agreement Name' | translate}}</mat-label>
                <input matInput formControlName="name">
                <mat-error>{{'Needs to have 6 or more letters' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field style="flex:1">
                <mat-label>{{'Maximum Amount' | translate}}</mat-label>
                <input matInput formControlName="maxAmount" appTwoDigitDecimaNumber>
                <span matSuffix class="my-suffix">{{ auth?.user?.country?.currency }}</span>
                <mat-error>{{"Amount can't be over"|translate}} {{maxAmountAllowed}} {{
                    auth?.user?.country?.currency
                    }}</mat-error>
            </mat-form-field>


        </div>
        <div class="io-row-l">
            <mat-form-field *ngIf="!data?.id">
                <mat-label>{{'Create With' | translate}}</mat-label>
                <mat-select (selectionChange)="changeCreationType()" [formControl]="creationType" name="creationType">
                    <mat-option value="payID">{{ 'PayID' | translate }}</mat-option>
                    <mat-option value="bsb">{{ 'BSB Number' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="creationType.value === 'payID'">
                <mat-label>{{'PayID Type' | translate}}</mat-label>
                <mat-select formControlName="payIDType"
                    (selectionChange)="changeVlidators(['payID', 'payIDType'], ['bsb', 'accountNumber'])">
                    <mat-option value="PHONE">{{ 'Mobile' | translate }}</mat-option>
                    <mat-option value="EMAIL">{{ 'E-mail' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="creationType.value === 'payID'">
                <mat-label>{{'PayID' | translate}} {{(form.get('payIDType')?.value == 'PHONE' ? 'Mobile Number' :
                    'E-mail' | translate)}}</mat-label>
                <input matInput formControlName="payID">
                <mat-error *ngIf="form.get('payID').hasError('email')"></mat-error>
                <mat-error *ngIf="form.get('payID').errors">
                    {{ (form.get('payIDType')?.value == 'PHONE'?'Enter valid mobile number' : 'Enter valid
                    e-mail')|translate}}
                </mat-error>

            </mat-form-field>

            <mat-form-field *ngIf="creationType.value === 'bsb'">
                <mat-label>{{'BSB Number' | translate}}</mat-label>
                <input matInput formControlName="bsb" appTwoDigitDecimaNumber>
                <mat-error>{{'Needs to have 6 numbers' | translate}}</mat-error>

            </mat-form-field>
            <mat-form-field *ngIf="creationType.value === 'bsb'">
                <mat-label>{{'Account Number' | translate}}</mat-label>
                <input matInput formControlName="accountNumber" appTwoDigitDecimaNumber>
                <mat-error>{{'Needs to have 6 to 9 numbers' | translate}}</mat-error>
            </mat-form-field>
        </div>

    </form>
    <!-- <pre>{{form.value|json}}</pre> -->

</mat-dialog-content>

<mat-dialog-actions class="io-row-l">

    <div class="io-row align-items-center">
        <mat-icon class="no-flex" color="primary">info</mat-icon>
        <p class="info-note">
            {{ 'For more details view ' |
            translate }}
            <a href="/terms/payto" target="_blank">PayTo Terms &amp; Conditions</a>
        </p>
    </div>

    <div class="no-flex">
        <div>
            <button mat-button color="primary" mat-dialog-close>{{ 'Cancel' | translate }}</button>
            <button mat-raised-button type="submit" color="primary" class="item-save"
                [disabled]="(!data?.id! ? !form?.valid : !(form?.valid && form?.dirty)) || loading"
                (click)="submitForm()">{{'Save' |
                translate }}</button>
        </div>
    </div>

</mat-dialog-actions>