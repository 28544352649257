import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PasswordComponent } from './forgot-password/password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginComponent } from './login.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SingleSelectModule } from 'src/app/components/select-country/single-select.module';
import { InputCodeComponent } from 'src/app/components/input-code/input-code.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { InputCodeModule } from 'src/app/components/input-code/input-code.module';



@NgModule({
  declarations: [
    LoginComponent,
    ChangePasswordComponent,
    PasswordComponent,
    ResetPasswordComponent,
    PasswordStrengthComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    TranslateModule.forChild(),
    SingleSelectModule,
    RouterModule,
    InputCodeModule
  ],
  exports: [
    LoginComponent,
    ChangePasswordComponent,
    PasswordComponent,
    ResetPasswordComponent,
    PasswordStrengthComponent,
  ]
})
export class LoginModule { }
