<form [formGroup]="form">

  <div mat-dialog-title>
    {{ data?.id ? 'Edit Region' : 'Add Region' }}
  </div>

  <mat-dialog-content>
    <div class="io-row">
      <mat-form-field class="flex-2">
        <mat-label>Region name</mat-label>
        <input matInput required formControlName="name">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Region code</mat-label>
        <input matInput required type="text" formControlName="code">
      </mat-form-field>
    </div>
    <!-- <div class="io-row">
      <mat-form-field>
        <mat-label>Maximum amount</mat-label>
        <input matInput required type="number" formControlName="maxAmount">
        <mat-hint>Maximum amount user can transfer for this region.</mat-hint>
      </mat-form-field>
    </div> -->

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <mat-checkbox color="primary" formControlName="isActive">Active</mat-checkbox>
    <span style="flex:1"></span>
    <button mat-button color="primary" mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid"
      *ngIf="auth.hasPermission(auth.perm.RegionsManage)" (click)="onSubmit()">Save</button>
  </mat-dialog-actions>

</form>