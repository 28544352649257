<list-wrapper #wrapper [mainService]="service" panelTitle="Purposes" [managePerm]="auth.perm.CompanyManage"
  [readPerm]="auth.perm.CompanyManage" (onNew)="newItem()" (onEdit)="editItem($event)">

  <mat-action-list [multiple]="false">
    <mat-list-item *ngFor=" let item of items$|async; last as last;" (click)="wrapper.edit(item)"
      [ngClass]="{'io-disabled': !item.isActive}">
      <h4 mat-line>{{ item?.name }}</h4>
      <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
    </mat-list-item>
  </mat-action-list>
</list-wrapper>