import { Component, Input } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';

import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { PublicService } from 'src/app/_services/public.service';

@Component({
  selector: 'app-user-list-item',
  templateUrl: './user-list-item.component.html',
  styleUrls: ['./user-list-item.component.scss']
})
export class UserListItemComponent {
  @Input() item: any;
  offset: number;
  constructor(
    public auth: AuthenticationService,
    public modalLauncherService: ModalLauncherService,
    public publicService: PublicService
  ) {

    this.offset = (new Date().getTimezoneOffset());
  }

  getTicketTooltip(tickets: any[]) {
    let res = [];
    if (!tickets)
      return '';
    tickets.forEach(function (fl) {
      if (!fl.ticket) {
        let key = `${fl.subject}`;
        if (res[key]) {
          res[key]++;
        } else {
          res[key] = 1;
        }
      }
    });
    let tmp = [];
    for (const [key, value] of Object.entries(res)) {
      tmp.push(key + ' [' + value + ']');
    }
    return tmp.join(", ")
    // return tickets.map(x => `${x.number}: ${x.subject}`).join(', ');
  }

  getFlagTooltip(raisedFlags: any[]) {
    let res = [];
    if (!raisedFlags) {
      return '';
    }
    raisedFlags.forEach(function (fl) {
      if (!fl.ticket) {
        let key = `${fl.level}: ${fl.flag.name}`;
        if (res[key]) {
          res[key]++;
        } else {
          res[key] = 1;
        }
      }
    });
    let tmp = [];
    for (const [key, value] of Object.entries(res)) {
      tmp.push(key + ' [' + value + ']');
    }
    return tmp.join(", ")
    // return raisedFlags
    //   .filter(x => !x.ticket)
    //   .map(x => `${x.level}: ${x.flag.name}`)
    //   .join(', ');
  }
}