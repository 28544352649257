<list-wrapper #wrapper [mainService]="service" panelTitle="Users" [managePerm]="auth.perm.UsersManage"
  [readPerm]="auth.perm.UsersRead" [hideAddBtn]="true" (onEdit)="editItem($event)">
  <form [formGroup]="form" class="io-row-m width-100" toolbox style="align-items: center">
    <mat-form-field class="width-100 width-mff" appearance="outline">
      <mat-label>{{ 'Search' | translate }}</mat-label>
      <input matInput formControlName="query" placeholder="{{'Name, email, phone or num...' | translate}}">
      <button mat-icon-button matSuffix type="button" (click)="form.patchValue({'query': null})"
        [disabled]="!form.get('query').value">
        <mat-icon *ngIf="form.get('query').value">clear</mat-icon><mat-icon
          *ngIf="!form.get('query').value">search</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="width-100 width-mff" appearance="outline">
      <mat-label>{{'Has recipient in' | translate}}</mat-label>
      <mat-select formControlName="toCountryIds" multiple>
        <mat-option *ngFor="let item of countriesRecipient$ | async" [value]="item.id">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="width-100 width-mff" appearance="outline">
      <mat-label>{{'Active flags' | translate}}</mat-label>
      <mat-select formControlName="activeFlags" multiple>
        <mat-option *ngFor="let flag of flags$ | async" [value]="flag.code">
          {{ flag.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="d-none d-m-show"></div>
    <div class="no-flex" *ngIf="auth.hasPermission(auth.perm.RiskAssementManage)">
      <button type="button" mat-raised-button (click)="getReport()" color="primary" class="btns" type="button">{{'Export
        Risk
        Assessment' |
        translate}}</button>
    </div>
  </form>
  <div class="io-py-1">
    <mat-action-list [multiple]="false">
      <app-user-list-item *ngFor="let item of items" [item]="item" (click)="wrapper.edit(item)"></app-user-list-item>
    </mat-action-list>
  </div>
</list-wrapper>