import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TransactionItemComponent } from 'src/app/admin/transactions/transaction-item/transaction-item.component';


@Component({
  selector: 'app-outbound-status-change-dialog',
  templateUrl: './outbound-status-change-dialog.component.html',
  styleUrls: ['./outbound-status-change-dialog.component.scss']
})
export class OutboundStatusChangeDialogComponent implements OnInit {
  title: string;
  content: string;
  form: FormGroup = this.fb.group({
    status: [null, [Validators.required]],
    note: [null, []],
    pickupCode: [null, [Validators.required]],
    transactionId: [null, [Validators.required]]
  });

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TransactionItemComponent>,
  ) { }

  ngOnInit() {
    this.title = this.data?.title;
    this.content = this.data?.content;
    this.form.patchValue({
      transactionId: this.data?.transaction.id,
      status: this.data?.outboundStatus,
      pickupCode: this.data?.pickupCode
    })
    if (this.data?.outboundStatus == 'Stopped') {
      this.form.get('note').addValidators([Validators.required]);
      this.form.get('note').updateValueAndValidity();
    }
    if (['Reimbursed', 'Approved'].indexOf(this.data?.outboundStatus) > -1 || this.data.paymentServiceCode !== 'CPICK') {
      this.form.get('pickupCode').removeValidators([Validators.required]);
      this.form.get('pickupCode').updateValueAndValidity();
    }
  }

  onSubmit() {
    this.dialogRef.close(this.form.value);
  }
}
