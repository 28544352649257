<div class="io-item io-item-shadow clickable">
    <div class="main">
        <h4>{{item?.userRef}}</h4>
        <div class="flag">
            <app-country-flag [item]="item?.country" [size]="'big'"></app-country-flag>

        </div>
        <div class="data name">
            <small>{{'Name'|translate}}</small>
            <span>{{ item?.firstName }} {{ item?.middleName }} {{ item?.lastName }}&nbsp;</span>
        </div>
        <div class="data country">
            <small>{{'Country'|translate}}</small>
            <span>{{ item?.country?.name }}&nbsp;</span>
        </div>
        <div class="data email">
            <small>{{'E-mail'|translate}}</small>
            <span>{{ item?.email }}&nbsp;</span>
        </div>

        <div [ngClass]="{'data': true, 'medium':true, opacity: (item?.moneySent == null ) }">
            <small>{{'Total summary'|translate}}</small>
            <span matTooltip="{{ item?.country?.currency }} {{ item?.moneySent | number: '1.6-6' }}">
                {{ item?.country?.currency }}
                {{ (item?.moneySent || 0) | number: '1.2-2'}}
                &nbsp;</span>
        </div>
        <div [ngClass]="{'data': true, 'opacity': !item?.lastSuccessfulLogin, 'medium': true}">
            <small>
                <!-- {{item?.lastSuccessfulLogin}} -->
                {{'Last active'|translate}}
            </small>
            <span>
                {{ item?.lastSuccessfulLogin | timezone2 | date:'short'}} &nbsp;</span>
        </div>
        <div [ngClass]="{'data': true, 'kyc':true, opacity: (item?.kycData == null || item?.kycData?.kycAttempts == 0) }"
            *ngIf="auth.hasPermission(auth.perm.VerificationLogsRead)">
            <small>{{'KYC Attempts'|translate}}</small>
            <span>{{item?.kycData?.kycAttempts || 0}}&nbsp;</span>
        </div>
        <div
            [ngClass]="{'data': true,'small':true, 'opacity': (item?.raisedFlags==null || (item?.raisedFlags|filterOn:['ticket']:[null])?.length == 0 )}">
            <small>{{'Flags'|translate}}</small>
            <span
                [matTooltip]="getFlagTooltip(item?.raisedFlags)">{{(item?.raisedFlags|filterOn:['ticket']:[null])?.length}}&nbsp;</span>
        </div>
        <div [ngClass]="{'data': true, 'small':true, opacity: (item?.tickets == null || item?.tickets?.length == 0)}">
            <small>{{'Tickets'|translate}}</small>
            <span [matTooltip]="getTicketTooltip(item?.tickets)">{{item?.tickets?.length || 0}}&nbsp;</span>
        </div>
    </div>
</div>