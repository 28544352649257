import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UiService } from '../../../_services/ui.service';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserRecipientsService } from 'src/app/_services/user/recipients.service';
import { EndUserService } from 'src/app/_services/user/enduser.service';
import { Unsubscribable } from 'rxjs';
import { FormConfigComponent } from 'src/app/components/form-configs/form-config/form-config.component';

@Component({
  selector: 'app-recipient-item',
  templateUrl: './recipient-item.component.html',
  styleUrls: ['./recipient-item.component.scss']
})
export class RecipientItemComponent implements OnInit, OnDestroy {

  recipientData: any;
  changed = true;
  recipient$: Unsubscribable;
  @ViewChild(FormConfigComponent) child;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: UserRecipientsService,
    public usersService: EndUserService,
    public userRecipientsService: UserRecipientsService,
    private dialogRef: MatDialogRef<RecipientItemComponent>,
    private ui: UiService
  ) {
    // this.recipientInfo = data;
    if (data.id) {
     this.recipient$ = this.userRecipientsService.one(data.id).subscribe({
        next: _recipient => {
          this.recipientData = _recipient;
        }
      });
      // this.changed = false;;
    }
  }
  ngOnInit() { }

  submitForm(data) {
    this.service.resetRefetchQuery();
    this.service.addRefetchQuery(this.usersService.onboardingQuery, this.usersService.onboardingWQparams);
    this.service.addRefetchQuery(this.usersService.currentUserGQL, null);
    this.service.addRefetchQuery(this.service.selectAllQuery, this.service.getPageRequestAllFilters())
    var s = !data.id ? this.service.create(data) : this.service.modify(data);

    var k = s.subscribe({
      next: x => {
        this.ui.snack('Recipient ' + (data.id ? 'saved!' : 'created!'));
        k?.unsubscribe();
        this.dialogRef.close();
      }
    });
  }

  formValid($event) {
    if ($event) {
      this.changed = false;
    }
  }
  ngOnDestroy(): void {
    this.recipient$?.unsubscribe();
  }
}