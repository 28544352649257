import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  Optional,
  Output,
  EventEmitter,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '../../_services/authentication.service';
import { environment } from 'src/environments/environment';
import { UserTransactionsService } from 'src/app/_services/user/transactions.service';
import { Transaction, Location } from 'src/app/_graphql/schema';
import { PublicService } from 'src/app/_services/public.service';
import { EndUserService } from 'src/app/_services/user/enduser.service';
import { Unsubscribable } from 'rxjs';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
  animations: [
    trigger('resultanimation', [
      state('expand', style({ height: '100px' })),
      state('collapse', style({ height: '0px' })),
      transition('* => *', animate('0.3s'))
    ]),
    trigger('locationanimation', [
      state('expand', style({ height: '68px', opacity: 1, overflow: 'hidden' })),
      state('collapse', style({ height: '0px', opacity: 0, overflow: 'hidden' })),
      transition('* => *', animate('0.3s'))
    ])
  ]
})
export class CalculatorComponent implements OnInit, OnDestroy {

  // calculatorPreReceipt = environment.calculatorPreReceipt;
  showPreReceipt = false;


  corridors: any;
  currentCorridorGroup: any = {};
  countriesFrom: any[];
  countriesTo: any[];
  purposes: any;
  paymentServices: any[];
  currentCountryFrom: any;
  currentCountryTo: any;
  paymentNetworks: any[];
  receivingCurrency: string;
  sendingCurrency: string;
  receiveAmount: number;
  sendingAmount: number;
  calculationDone = false;
  calculation: any = {};
  loading = false;
  error = '';
  mode: 'public' | 'chooseRecipient' | 'calculator' | 'inbound' | 'inbounds' | 'informations' | 'noCorridor' | 'review' = 'public';

  recipients$: any;
  recipients: any;
  transactionId: any;
  transaction: any;
  inbounds: any;
  iFrameUrl: string = null;
  showTransaction = false;
  sendingMode = 'inbounds';
  informationsMode: boolean;
  sendingAmount$: Unsubscribable;
  receivingAmount$: Unsubscribable;
  calculateAmount$: Unsubscribable;
  corridorsGroup$: Unsubscribable;
  inbounds$: Unsubscribable;
  createTransaction$: Unsubscribable;
  locations: Location[] = []
  calculatorPreReceiptChecked: FormControl<boolean> = new FormControl(false);
  form: FormGroup = this.fb.group({
    serviceId: [null, [Validators.required]],
    networkId: [null, [Validators.required]],
    locationId: [null],
    purposeId: [null, [Validators.required]],
    sendAmount: [{ value: '', disabled: true }],
    receiveAmount: [{ value: '', disabled: true }],
    countryFromId: [null],
    countryToId: [null],
  });

  @Output() modeEvent = new EventEmitter<string>();
  @Output() calculatorData = new EventEmitter<any[]>();
  @Output() currency = new EventEmitter<string>();
  @Output() countryToChange = new EventEmitter<any>();
  @Output() transactionsUpdate = new EventEmitter<any>();
  @Output() transactionID = new EventEmitter<any[]>();

  emitData = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private transService: UserTransactionsService,
    public auth: AuthenticationService,
    private us: EndUserService,
    public publicService: PublicService,
    private fb: FormBuilder,
    @Optional() public dialogRef: MatDialogRef<CalculatorComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (auth.isLoggedIn() && data) {
      this.purposes = this.publicService.getPurposes();
    }
    this.corridorsGroup$ = this.publicService.getCorridorsGroups().subscribe(res => {
      this.corridors = res;

      this.countriesFrom = res.map((x: any) => ({
        id: x.from.id,
        name: x.from.name,
        isActive: x.from.isActive
      }));

      let countryFrom = this.route.snapshot.paramMap.get('countryFrom');
      let countryTo = this.route.snapshot.paramMap.get('countryTo');

      if (this.auth.user?.country) {
        countryFrom = this.auth.user.country.id;
      }

      if (this.data?.recipient) {
        countryTo = this.data.recipient.country.id;
      }

      if (countryFrom) {
        this.setFromCountry(countryFrom, true);
      }

      if (countryFrom && countryTo) {
        this.setToCountry(countryTo, true);
      }
    });

    this.sendingAmount$ = this.form?.get('sendAmount').valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(query => {
        if (query === '' && this.form.get('receiveAmount')?.getRawValue() === null) {
          this.error = 'Amount cannot be empty';
        }
        if (!parseFloat(query)) {
          return;
        }
        this.calculationDone = false;
        this.setRemoveValidators('sendAmount', 'receiveAmount');
        this.form.get('sendAmount').disable();
        this.form.get('receiveAmount').disable();
        this.form.get('receiveAmount').setValue(null);
        this.calculate();
      });

    this.receivingAmount$ = this.form?.get('receiveAmount').valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(query => {
        if (query === '' && this.form.get('sendAmount')?.getRawValue() === null) {
          this.error = 'Amount cannot be empty';
        }
        if (!parseFloat(query)) {
          return;
        }
        this.calculationDone = false;
        this.setRemoveValidators('receiveAmount', 'sendAmount');
        this.form.get('sendAmount').disable();
        this.form.get('receiveAmount').disable();
        this.form.get('sendAmount').setValue(null);
        this.calculate();
      });
  }
  setRemoveValidators(fieldNameSetValidator, fieldNameRemoveValidator) {
    this.form?.get(fieldNameSetValidator).setValidators(Validators.required);
    this.form?.get(fieldNameSetValidator).updateValueAndValidity();
    this.form.get(fieldNameRemoveValidator).clearValidators();
    this.form?.get(fieldNameRemoveValidator).updateValueAndValidity();
  }

  ngOnInit() {
    if (this.data) {
      if (this.data.transaction) {
        this.transactionId = this.data.transaction.id;
        this.transaction = this.data.transaction;
        this.mode = 'inbounds';
      } else if (this.data.recipient === false) {
        this.mode = 'chooseRecipient';
      } else {
        this.mode = this.informationsMode ? 'inbounds' : 'calculator';
      }
    }
    if (this.dialogRef) {
      let dialogUnsub = this.dialogRef.beforeClosed().subscribe(result => {
        this.transactionsUpdate.emit(result);
        dialogUnsub?.unsubscribe();
      });
      if (this.transactionId) {
        this.inbounds$ = this.transService.getInbounds(this.transactionId).subscribe({
          next: (data) => {
            this.inbounds = data;
          }
        });
      }
    }
  }

  clearFields(fromLevel: string) {
    switch (fromLevel) {
      case 'countryFrom':
        this.currentCountryFrom = null;
        this.sendingCurrency = '';

      // tslint:disable-next-line:no-switch-case-fall-through
      case 'countryTo':
        this.currentCountryTo = null;
        this.receivingCurrency = '';

      // tslint:disable-next-line:no-switch-case-fall-through
      case 'services':
        this.paymentServices = [];
        this.form.get('serviceId').setValue(null);

      // tslint:disable-next-line:no-switch-case-fall-through
      case 'networks':
        this.paymentNetworks = [];
        this.form.get('networkId').setValue(null);

      // tslint:disable-next-line:no-switch-case-fall-through
      case 'amounts':
        this.form.get('sendAmount').setValue(null);
        this.form.get('receiveAmount').setValue(null);
        this.calculationDone = false;
        this.calculation = {};
    }
  }

  setFromCountry(countryQuery: string = '', searchName: boolean = false) {
    this.clearFields('countryTo');

    this.currentCountryFrom = this.countriesFrom.find(
      x => x.id === countryQuery || (searchName && x.name === countryQuery)
    );
    this.form.get('countryFromId').setValue(this.currentCountryFrom?.id);

    if (!this.currentCountryFrom) {
      return;
    }

    this.checkIfEmpty();

    this.currentCorridorGroup = this.corridors.find(
      x =>
        x.from.id === countryQuery ||
        (searchName && x.from.name === countryQuery)
    );

    this.sendingCurrency = this.currentCorridorGroup.from.currency;
    if (this.currentCorridorGroup) {
      this.countriesTo = this.currentCorridorGroup.toCountries.map(x => ({
        id: x.country.id,
        name: x.country.name,
        isActive: x.country.isActive
      }));
    }
  }

  setToCountry(countryQuery: string = '', searchName: boolean = false) {
    if (!this.countriesTo) {
      return;
    }

    this.clearFields('services');
    const currentCountry = this.currentCorridorGroup.toCountries.find(
      x =>
        x.country.id === countryQuery ||
        (searchName && x.country.name === countryQuery)
    );

    if (!currentCountry) {
      this.mode = 'noCorridor';
      return;
    }

    this.checkIfEmpty();

    // Changing flags
    this.currentCountryTo = this.countriesTo.find(
      x => x.id === countryQuery || (searchName && x.name === countryQuery)
    );
    this.form.get('countryToId').setValue(this.currentCountryTo?.id);

    this.countryToChange.emit(this.currentCountryTo);

    if (currentCountry && currentCountry.paymentServices) {
      this.paymentServices = currentCountry.paymentServices;
      if (this.paymentServices.length === 1) {
        this.setPaymentService(this.paymentServices[0].paymentService.id);
      }
    }
  }

  setPaymentService(serviceId: string) {
    if (!this.paymentServices) {
      return;
    }
    this.clearFields('networks');

    this.form.get('serviceId')?.setValue(serviceId);

    const currentService = this.paymentServices.find(
      x => x.paymentService.id === serviceId
    );

    this.checkIfEmpty();

    if (currentService && currentService.networks) {
      this.paymentNetworks = currentService.networks;

      if (this.paymentNetworks.length === 1) {
        this.setPaymentNetwork(this.paymentNetworks[0].paymentNetwork.id);
      }
    }
  }

  setPaymentNetwork(networkId: string) {
    this.clearFields('amounts');
    this.locations = [];
    this.form.get('locationId')?.setValue(null);
    this.form.get('networkId')?.setValue(networkId);

    const corridorLimits = this.paymentNetworks.find(
      x => x.paymentNetwork.id === networkId
    );

    this.receivingCurrency = corridorLimits.currency;
    this.locations = corridorLimits.locations;
    if (this.locations.length == 1) {
      this.setLocation(this.locations[0].id);
    }
    this.checkIfEmpty();
  }
  setLocation(locationId: any) {
    this.form.get('locationId')?.setValue(locationId);
    this.clearFields('amounts');
    this.checkIfEmpty();
  }
  setPurpose(purposeId: any) {
    this.form.get('purposeId').setValue(purposeId);
    this.checkIfEmpty();
  }

  setRecipient(recipient) {
    this.data.recipient = recipient;
    this.setToCountry(recipient.country.id);
    this.mode = 'calculator';
  }

  checkIfEmpty() {
    if (
      this.currentCountryFrom &&
      this.currentCountryTo &&
      this.form.get('serviceId')?.value &&
      this.form.get('networkId')?.value &&
      (
        (this.locations.length > 0 && this.form.get('locationId')?.value) ||
        this.locations.length == 0 && !this.form.get('locationId')?.value
      ) &&
      (this.mode === 'public' || this.form.get('purposeId')?.value)
    ) {
      this.form.get('sendAmount').enable();
      this.form.get('receiveAmount').enable();
    } else {
      this.form.get('sendAmount').disable();
      this.form.get('receiveAmount').disable();
    }
  }

  calculate() {

    this.calculationDone = false;
    this.calculation = {};
    this.loading = true;
    this.calculateAmount$ = this.publicService.calculateAmount(this.form.getRawValue()).subscribe(
      res => {
        this.currency.emit(this.receivingCurrency);
        this.currency.emit(this.sendingCurrency);
        this.calculationDone = true;
        this.form.get('sendAmount').enable();
        this.form.get('receiveAmount').enable();
        this.calculation = res;
        this.loading = false;

        this.error = '';
      },
      (error: any) => {
        this.calculationDone = true;
        this.form.get('sendAmount').enable();
        this.form.get('receiveAmount').enable();
        this.error = error.message.replace('GraphQL error:', '');
        this.loading = false;
        if (this.showPreReceipt == true) {
          this.showPreReceipt = false;
          this.calculatorPreReceiptChecked.setValue(false);
        }
      }
    );
  }
  onShowPreReceipt() {
    if (!this.calculationDone) {
      return;
    }
    this.showPreReceipt = true;
  }
  loginAndPay() {
    this.loading = true;
    if (!this.calculationDone) {
      return;
    }

    const params: any = {
      recipientId: '',
      quoteId: this.calculation.quoteId
    };

    if (this.data) {
      params.recipientId = this.data.recipient.id;
      this.calculationDone = false;
      this.us.addConsent('TRANSACTION', params?.quoteId).then(
        () => { this._create({...this.form.getRawValue(), ...params}); }
      );
    } else {
      this.router.navigate(['login', ...this.form.getRawValue(), ...params]);
    }
  }
  private _create(params: any) {
    this.createTransaction$ = this.transService.create(params).subscribe(
      {
        next: (_transaction: Transaction) => {
          this.transaction = _transaction;
          this.calculatorData.emit(this.transaction);
          this.transactionId = this.transaction.id;
          // this.transactionID.emit(this.transactionId);
          this.transactionsUpdate.emit(true);
          this.modeEvent.emit(this.sendingMode);
          this.loading = false;
        },
        error: (error: any) => {
          this.calculationDone = true;
          this.form.get('sendAmount').enable();
          this.form.get('receiveAmount').enable();
          this.error = error.message.replace('GraphQL error:', '');
          this.loading = false;
          if (this.showPreReceipt == true) {
            this.showPreReceipt = false;
            this.calculatorPreReceiptChecked.setValue(false);
          }
        }
      }
    );
  }
  ngOnDestroy() {
    if (this.recipients$) {
      this.recipients$.unsubscribe();
    }
    this.createTransaction$?.unsubscribe();
    this.receivingAmount$?.unsubscribe();
    this.sendingAmount$?.unsubscribe();
    this.calculateAmount$?.unsubscribe();
    this.corridorsGroup$?.unsubscribe();
    this.inbounds$?.unsubscribe();
  }
}
