import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { UsersComponent } from './users/users.component';
import { AdminWrapperComponent } from './wrapper/wrapper.component';
import { LoginComponent } from './login/login.component';
import { MaterialModule } from '../material.module';
import { UsersModule } from './users/users.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CountriesComponent } from './countries/countries.component';
import { AuthGuard } from '../_guards/auth.guard';
import { TranslateModule } from '@ngx-translate/core';
import { RegionItemComponent } from './regions/region-item/region-item.component';
import { CorridorItemComponent } from './constraints/corridor-item/corridor-item.component';
import { CountryItemComponent } from './countries/country-item/country-item.component';
import { ComponentsModule } from '../components/components.module';
import { RelationshipsComponent } from './relationships/relationships.component';
import { PurposesComponent } from './purposes/purposes.component';
import { RelationshipsItemComponent } from './relationships/relationships-item/relationships-item.component';
import { PurposesItemComponent } from './purposes/purposes-item/purposes-item.component';
import { RegionsComponent } from './regions/regions.component';
import { AdminTicketsComponent } from './admin-tickets/admin-tickets.component';
import { CurrencyComponent } from './currency/currency.component';
import { CurrencyItemComponent } from './currency/currency-item/currency-item.component';


import { CorridorsComponent } from './constraints/corridors.component';

import { UserListItemComponent } from './users/user-list-item/user-list-item.component';
import { LanguagesComponent } from './languages/languages.component';
import { LanguageItemComponent } from './languages/language-item/language-item.component';
import { AdminsItemComponent } from './admins/admins-item/admins-item.component';
import { AdminsComponent } from './admins/admins.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { UserProfileComponent } from './users/profile/user-profile.component';

import { AdminRecipientsComponent } from './users/admin-recipients/admin-recipients.component';
import { TranslationsComponent } from './translations/translations.component';
import { TranslationsItemComponent } from './translations/trans-item/trans-item.component';
import { AdminTicketItemComponent } from './admin-tickets/admin-ticket-item/admin-ticket-item.component';
import { AdminTicketListItemComponent } from './admin-tickets/admin-ticket-list-item/admin-ticket-list-item.component';
import { AdminPayoutsComponent } from './payouts/payouts.component';
import { PipesModule } from '../_pipes/pipes.module';
import { DocumentsModule } from '../components/documents/documents.module';
import { LoginModule } from '../public/login/login.module';
import { AdminTabWrapperComponent } from './admin-tab-wrapper/admin-tab-wrapper.component';
import { FlagsModule } from './flags/flags.module';
import { AdminTransactionsModule } from './transactions/admin-transactions.module';
import { ListWrapperModule } from './list-wrapper/list-wrapper.module';
import { CountryFlagModule } from '../components/country-flag/country-flag.module';
import { FeeRatingModule } from './fee-rating/fee-rating.module';
import { RiskAssessmentModule } from './risk-assessment/risk-assessment.module';
import { CheckResultsDialogComponent } from './risk-assessment/check-results-dialog/check-results-dialog.component';
import { AdminKycLogsModule } from './users/admin-kyc-logs/admin-kyc-logs.module';
import { SingleSelectModule } from '../components/select-country/single-select.module';
import { AdminDialogsModule } from './dialogs/dialogs.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AdminPayoutTransactionItemComponent } from './payouts/payout-transaction-item/payout-transaction-item.component';
import { AdminsListItemComponent } from './admins/admins-list-item/admins-list-item.component';
import { CalculatorModule } from '../components/calculator/calculator.module';
import { AdminTicketsAndFlagsWrapperComponent } from './admin-tickets/admin-ticket-and-flags-wrapper/admin-ticket-and-flags-wrapper.component';
import { AdminTicketsAndFlagsOverviewComponent } from './admin-tickets/admin-ticket-and-flags-overview/admin-ticket-and-flags-overview.component';
import { AdminFlagsListItemComponent } from './admin-flags/admin-flags-list-item/admin-flags-list-item.component';
import { AdminFlagsComponent } from './admin-flags/admin-flags.component';
import { ClickStopPropagationDirective } from '../_directives/click-stop-propagation.directive';
import { FormDataDisplayConfigurationComponent } from '../components/form-configs/form-data-display-configuration/form-data-display-configuration.component';
import { FormConfigsModule } from '../components/form-configs/form-configs.module';
import { AdminFlagItemComponent } from './admin-flags/admin-flag-item/admin-flag-item.component';
import { SenderSectionModule } from './sender-section/sender-section.module';




@NgModule({
  declarations: [
    AdminTabWrapperComponent,
    AdminWrapperComponent,
    UsersComponent,
    LoginComponent,
    CorridorsComponent,
    CountriesComponent,
    RegionItemComponent,
    CorridorItemComponent,
    CountryItemComponent,
    RelationshipsComponent,
    PurposesComponent,
    LanguagesComponent,
    LanguageItemComponent,
    RelationshipsItemComponent,
    PurposesItemComponent,
    RegionsComponent,
    AdminTicketsComponent,
    CurrencyComponent,
    CurrencyItemComponent,
    UserListItemComponent,
    AdminsComponent,
    AdminsItemComponent,
    UserProfileComponent,
    AdminRecipientsComponent,
    TranslationsComponent,
    TranslationsItemComponent,
    AdminTicketItemComponent,
    AdminTicketListItemComponent,
    AdminPayoutsComponent,
    AdminPayoutTransactionItemComponent,
    CheckResultsDialogComponent,
    AdminsListItemComponent,

    AdminTicketsAndFlagsWrapperComponent,
    AdminTicketsAndFlagsOverviewComponent,
    AdminFlagsListItemComponent,
    AdminFlagItemComponent,
    AdminFlagsComponent,
    ClickStopPropagationDirective,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    UsersModule,
    AdminKycLogsModule,
    ComponentsModule,
    TranslateModule.forChild(),
    PipesModule,
    FlagsModule,
    SingleSelectModule,
    LoginModule,
    AdminTransactionsModule,
    ListWrapperModule,
    FeeRatingModule,
    RiskAssessmentModule,
    DocumentsModule,
    AdminDialogsModule,
    CountryFlagModule,
    CalculatorModule,
    FormConfigsModule,
    SenderSectionModule
  ],
  providers: [
    AuthGuard,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }
  ],
  entryComponents: [
    RegionItemComponent,
    CorridorItemComponent,
    CountryItemComponent,
    RelationshipsItemComponent,
    PurposesItemComponent,
    CurrencyItemComponent
  ],
  exports: [
    ListWrapperModule,
    FormDataDisplayConfigurationComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AdminModule { }
