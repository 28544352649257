<app-hero></app-hero>

<div class="container">
  <h2>{{ 'page.how.main_page_title' | translate }}</h2>
</div>

<div class="how-container">
  <p>{{ 'page.how.content_title1' | translate }}</p>
  <p>
    {{ 'page.how.main_page_title' | translate }}
    <br />
    {{'page.how.content1' | translate }} 
  </p>
  <p>{{ 'page.how.content2' | translate }}</p>
  <p>{{ 'page.how.content3' | translate }}</p>

  <p>
    {{ 'page.how.content4' | translate }}
    <br />
    {{ 'page.how.content5' | translate }}
    <br />
    <br>
    {{ 'page.how.content6' | translate }}
  </p>

  <p>
    {{ 'page.how.content7' | translate }}
  </p>

  <p>
    {{ 'page.how.content8' | translate }}
  </p>
  <p>
    <br />
    {{ 'page.how.content_title2' | translate }}
  </p>
  <p>
    {{ 'page.how.content9' | translate }}
  </p>

  <p>
    {{ 'page.how.content10' | translate }}
  </p>
</div>
