import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { MaterialModule } from 'src/app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { WarningFlagsListComponent } from '../warning-flags-list/warning-flags-list.component';
import { OnboardingModule } from '../onboarding/onboarding.module';
import { RecipientsModule } from '../recipients/recipients.module';
import { TransactionsModule } from '../transactions/transactions.module';
import { TicketsModule } from '../tickets/tickets.module';
import { WarningFlagsListModule } from '../warning-flags-list/warning-flags-list.module';



@NgModule({
  declarations: [
    DashboardComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule.forChild(),
    OnboardingModule,
    RecipientsModule,
    TransactionsModule,
    TicketsModule,
    WarningFlagsListModule
  ],
  exports: [
    DashboardComponent
  ]
})
export class DashboardModule { }
