<section class="container1">
  <div style="min-height: 4px">
    <mat-progress-bar *ngIf="loading" mode="query"></mat-progress-bar>
  </div>
  <div class="container2">
    <div class="center">
      <form [formGroup]="form" (submit)="resetPassword()">
        <mat-card class="boxed">
          <mat-card-title>{{ 'Reset Password' | translate }}</mat-card-title>
          <mat-card-subtitle>{{ 'Please enter your code and new password' | translate }}</mat-card-subtitle>
          <mat-card-content>
            <app-input-code [numOfDigits]="6" formControlName="verificationCode"></app-input-code>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'Email' | translate }}</mat-label>
              <input matInput formControlName="username" autocomplete="username" />
            </mat-form-field>

            <mat-form-field class="password" appearance="outline"
              hintLabel="{{'Needs to contain at least one lower, upper and one digit character.' | translate}}">

              <mat-label>{{ 'New Password' | translate }}</mat-label>
              <input matInput #password formControlName="newPassword" minlength="6" autocomplete="new-password"
                [type]="hide ? 'password' : 'text'" />
              <button mat-icon-button matSuffix [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide"
                type="button">
                <mat-icon (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
              </button>
              <mat-hint align="end">
                {{password.value.length}} / {{ 6 }}
              </mat-hint>
            </mat-form-field>
            <app-password-strength [hasNumber]="true" [hasUpper]="true" [hasLower]="true"
              [userPassword]="this.form.get('newPassword')">
            </app-password-strength>
          </mat-card-content>
          <mat-action-row>
            <button mat-button color="primary" type="button" [routerLink]="['/password']">
              {{ 'Back' | translate }}
            </button>
            <button mat-raised-button [disabled]="disabled || !form.valid" class="reset-btn" color="primary"
              type="submit">
              {{ 'Confirm' | translate }}
            </button>
          </mat-action-row>
        </mat-card>

        <!-- <h5>{{ 'Please enter your code and new password' | translate }}</h5>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Email' | translate }}</mat-label>
          <input matInput required formControlName="username" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ 'Code' | translate }}</mat-label>
          <input matInput required formControlName="verificationCode" />
        </mat-form-field>
        

        <div class="pass-info">
          <mat-form-field class="password" appearance="outline"
            hintLabel="{{'Needs to contain at least one lower, upper and one digit character.' | translate}}">

            <mat-label>{{ 'New Password' | translate }}</mat-label>
            <input matInput #password required formControlName="newPassword" minlength="6"
              [type]="hide ? 'password' : 'text'" />
            <button mat-icon-button matSuffix [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              <mat-icon (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <mat-hint align="end">
              {{password.value.length}} / {{ 6 }}
            </mat-hint>
          </mat-form-field>
          <app-password-strength [hasNumber]="true" [hasUpper]="true" [hasLower]="true"
            [userPassword]="this.resetPasswordForm.get('newPassword')">
          </app-password-strength>

        </div>

        <div class="btn">
          <button mat-raised-button [disabled]="disabled || !resetPasswordForm.valid" class="reset-btn" color="primary"
            type="submit" (click)="resetPassword()">
            {{ 'Confirm' | translate }}
          </button>
        </div> -->
      </form>
    </div>
  </div>
</section>