import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UserTransactionsService } from 'src/app/_services/user/transactions.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {
  redirect = '';
  constructor(
    private route: ActivatedRoute,
    private apollo: Apollo,
    private auth: AuthenticationService,
    private dialogRef: MatDialog,
    private userTransactions: UserTransactionsService,
  ) {
    this.logout();
  }
  logout() {
    this.userTransactions.pendingTransId = null;
    this.userTransactions.resetRefetchQuery();
    this.apollo.client.clearStore();
    localStorage.removeItem('currentUser');
    this.auth.user = null;
    this.auth.authToken = null;

    this.redirect = this.route.snapshot.paramMap.get('redirect');
    this.redirect = this.redirect ? this.redirect : '/'
    this.dialogRef?.closeAll();
    window.location.href = this.redirect;
  }
}
