import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardingComponent } from './onboarding.component';
import { OnboardingComponent  as OnboardingComponentCircles} from './onboarding-circles/onboarding.component';
import { OnboardingComponent  as OnboardingComponentstepped} from './onboarding-stepped/onboarding.component';
import { MaterialModule } from 'src/app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'src/app/_pipes/pipes.module';



@NgModule({
  declarations: [
    OnboardingComponent,
    OnboardingComponentCircles,
    OnboardingComponentstepped
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule.forChild(),
    PipesModule
    
  ],
  exports: [
    OnboardingComponent,
    OnboardingComponentCircles,
    OnboardingComponentstepped
  ]
})
export class OnboardingModule { }
