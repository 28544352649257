import { Apollo, gql } from 'apollo-angular';
import { InboundAccountType, PaymentMethodInputType, TicketType } from 'src/app/_graphql/schema';
import { BaseService } from '../admin/base.service';
import { Injectable } from '@angular/core';



@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsService extends BaseService<InboundAccountType>{
  changingAgreementId = null;
  selectOneFields = gql`
    fragment InboundAccountSelectOneFields on InboundAccountType  {
      accountNumber
      accountSubType
      accountType
      fingerprint
      holderName
      id
      inboundCode
      inboundName
      lastFourNumbers
      name
      description
      routingNumber
      status
      expiryDate
      maxAmount
    }
  `;

  selectAllFields = gql`
    fragment InboundAccountSelectAllFields on InboundAccountType  {
      id ...InboundAccountSelectOneFields
    }
    ${this.selectOneFields}
  `;

  constructor(protected apollo: Apollo) {
    super(apollo);
    this.filters = false;
    this.mainScope = "user";
    this.initGql('inboundAccount');

  }

  generateInboundToken(inbound: any) {
    return this.mutation(gql`
    mutation generateInboundToken($inbound:String!) {
      user {
        generateInboundToken(inbound: $inbound) {
          accessToken
          isKYCMissing
          expiresIn
          refreshToken
          isSandbox
          redirectUrl
          scope
          tokenType
          userAccountId
        }
      }
    }
  `, { inbound })
  }


  public deleteAccount(accountId, accountType, inbound) {
    return this.mutation(gql`
    mutation removeInboundAccount($id: String!, $accountType: InboundAccountEnumType!, $inbound: String!) {
      user {
        removeInboundAccount(id: $id, accountType: $accountType, inbound: $inbound)
      }
    }`, {
      id: accountId,
      accountType: accountType,
      inbound: inbound,
    })
  }

//   public paymentMethodsRequired() {
//     return this.query(gql`
//     query paymentMethodsRequired($pageRequest:PageRequestType){
//       user {
//         id
//           paymentMethodsRequired(pageRequest: $pageRequest){
//        		code displayName maxAmount
//         }
//       }
// }
//     `)
//   }
  public addPaymentMethod(paymentMethod: PaymentMethodInputType) {
    return this.mutation(gql`
    mutation addPaymentMethod($paymentMethod:PaymentMethodInputType!) {
      user {
        addPaymentMethod(paymentMethod: $paymentMethod) {
          accountNumber
          maxAmount
          expiryDate
          status
          holderName
          id
          inboundCode
          lastFourNumbers
        }
      }
}`, { paymentMethod: paymentMethod })
  }

  public changePaymentMethodStatus(paymentMethodId: string, newStatus: string, inboundCode: string) {
    return this.mutation(gql`
    mutation changePaymentMethodStatus($paymentMethodId:String!, $newStatus: String!, $inboundCode: String!) {
      user {
        changePaymentMethodStatus(paymentMethodId: $paymentMethodId, newStatus: $newStatus, inboundCode: $inboundCode)   
      }
}`, {
      paymentMethodId: paymentMethodId,
      newStatus: newStatus,
      inboundCode: inboundCode
    })
  }
  public updatePaymentMethod(paymentMethod: PaymentMethodInputType) {
    return this.mutation(gql`
    mutation updatePaymentMethod($paymentMethod:PaymentMethodInputType!) {
      user {
        updatePaymentMethod(paymentMethod: $paymentMethod) {
          accountNumber
          maxAmount
          expiryDate
          status
          holderName
          id
          inboundCode
          lastFourNumbers
        }
      }
}`, { paymentMethod: paymentMethod })
  }

  public inboundAccount(accountId: string, inboundCode: string): any {
    return this.query(gql`query inboundAccount($accountId: String, $inboundCode: String) {
      user {
        id
        inboundAccount(accountId: $accountId, inboundCode: $inboundCode) {
          id
          expiryDate
          bsb
          maxAmount
          name
          payID
          status
          inboundCode
          inboundName
        }
      }
    }`, { accountId: accountId, inboundCode: inboundCode });
  }
}
