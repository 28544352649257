import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Event, NavigationStart, Router } from '@angular/router';
import { UiService } from 'src/app/_services/ui.service';
import { DrawerService } from './drawer.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { ChangePasswordComponent } from 'src/app/public/login/change-password/change-password.component';

@Component({
  selector: 'app-admin-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class AdminWrapperComponent implements OnInit {
  loading = false;
  user: any;
  toggleState = false;
  public displayBack: boolean;
  previousUrl: string;

  @ViewChild(MatDrawer, { static: true }) matDrawer: MatDrawer;

  constructor(
    public auth: AuthenticationService,
    private ui: UiService,
    private snack: MatSnackBar,
    private cdRef: ChangeDetectorRef,
    private drawerService: DrawerService,
    private router: Router,
    private dialog: MatDialog,
  ) {

    if (!this.previousUrl) {
      let urls = this.router.url.split("/")
      this.previousUrl = "/" + urls[1] + "/" + urls[2]
    }
    // this.ui.snacks.subscribe(message => {
    //   this.snack.open(message.replace('GraphQL error:', ''), 'Got it', {
    //     duration: 2000
    //   });
    // });
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (event.url === '/admin/transactions') {
          this.previousUrl = event.url;
        } else if (event.url === '/admin/users') {
          this.previousUrl = event.url;
        }
      }
    });
    this.ui.loaders.subscribe(visible => {
      this.loading = visible;
      window['_tangoLoader'] = visible ? true : false;
      //this.cdRef.detectChanges();
    });
  }

  ngOnInit() {
    this.drawerService.sendState.subscribe(x => {
      this.displayBack = x;
      let urls = this.router.url.split("/")
      if (urls.length > 3)
        this.displayBack = true;
    });

    this.drawerService.setDrawer(this.matDrawer);
    if (this.auth.hasRoles(['PayoutAgent'])) {
      this.drawerService.close()
    }
    // this.usersService
    //  .getEndUser()
    //  .subscribe(user => {
    //    this.user = user;
    //  });
  }

  getBack() {
    this.displayBack = false;
    if (this.previousUrl === '/admin/transactions') {
      this.router.navigate(['/admin/transactions']);
    } else if (this.previousUrl === '/admin/users') {
      this.router.navigate(['/admin/users']);
    }
  }

  linkEnabled(route: string) {
    return true;
  }

  changePassword() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '600px',
      maxWidth: '95%',
      maxHeight: '80%',
      data: { user: '', isResetMode: false }
    });
  }
}
