import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UiService } from 'src/app/_services/ui.service';
import { MatDialog } from '@angular/material/dialog';
import { ExternalIdComponent } from './external-id/external-id.component';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { DrawerService } from 'src/app/admin/wrapper/drawer.service';
import { SystemService } from 'src/app/_services/system.service';
import { AdminTransactionType, RaisedFlagType } from 'src/app/_graphql/schema';
import { AdminTransactionsService } from 'src/app/_services/admin/admin-transactions.service';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { Subscribable, Unsubscribable } from 'rxjs';
import { AdminFlagsService } from 'src/app/_services/admin/admin-flags.service';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { AdminTransactionsHistoryService } from 'src/app/_services/admin/admin-transactions-history.service';

@Component({
  selector: 'app-transaction-item',
  templateUrl: './transaction-item.component.html',
  styleUrls: ['./transaction-item.component.scss']
})
export class TransactionItemComponent implements OnInit, OnDestroy {
  @Input() transactionId: any;
  @Input() transaction: AdminTransactionType = null;
  @Input() userId: string = null;
  flags$: Subscribable<RaisedFlagType[]>;
  $transaction: Unsubscribable;
  selectedTab = 0;
  nextStepButtonTitle = '';
  cancelButtonTitle = '';
  nextStatus: number;
  cancelStatus: number;
  statusName: any;
  updateDocument = false;
  updateRecipient = false;
  statuses: any[];
  transactionStatuses$: Unsubscribable;
  $history: Subscribable<any>;

  constructor(
    public auth: AuthenticationService,
    private drawerService: DrawerService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private ui: UiService,
    private service: AdminTransactionsService,
    private adminFlagsService: AdminFlagsService,
    public ms: ModalLauncherService,
    private systemService: SystemService,
    private adminTransactionsHistoryService: AdminTransactionsHistoryService,
  ) {
    if (!this.transactionId) {
      this.transactionId = this.route.snapshot.paramMap.get('transactionId');
      this.userId = this.route.snapshot.paramMap.get('userId');
    }
    if (auth.isAdmin()) {
      this.drawerService.close();
      this.adminTransactionsHistoryService.queryAllParams.skip = 0;
      this.adminTransactionsHistoryService.queryAllParams.take = null
      this.$history = this.adminTransactionsHistoryService.all({ transactionId: this.transactionId }, false, this.transactionId);
    }
  }

  ngOnInit() {
    this.getActivity();
    this.getTransaction();
    this.transactionStatuses$ = this.systemService.transactionStatuses().subscribe({
      next: x => this.statuses = x
    });
  }

  getTransaction() {
    this.flags$ = this.adminFlagsService.all({ userId: this.userId }, false, 'flags4' + this.userId);

    this.$transaction = this.service.one(this.transactionId, false).subscribe({
      next: (x: AdminTransactionType) => {
        this.transaction = x;
        this.transactionId = x.id
        this.getActivity();
        this.checkDocument();
      }
    });

    // scroll to top
    document.querySelectorAll('.mat-drawer-content').forEach(el => el.scrollTop = 0)
  }

  getActivity() {
    if (!this.transaction || !this.transaction.status) {
      return;
    }

    switch (this.transaction.status.id) {
      case '1': // Initiated

        this.nextStepButtonTitle = 'Charge';
        this.cancelButtonTitle = 'Reject';
        this.nextStatus = 2;
        this.cancelStatus = 6;

        if (this.transaction.raisedFlags && this.transaction.raisedFlags.length > 0
          && this.transaction.raisedFlags.filter(x => x.flag.code === 'PAYMENT_WRONG_AMOUNT') != null
          && this.transaction.raisedFlags.filter(x => x.flag.code === 'PAYMENT_WRONG_AMOUNT').length > 0) {
          this.cancelButtonTitle = 'Refund';
          this.cancelStatus = 4;
          this.nextStepButtonTitle = '';
          this.nextStatus = 0;
          break;
        }

        break;
      case '3': //Sent
        this.nextStepButtonTitle = 'Confirm received';
        this.nextStatus = 7;
        this.cancelButtonTitle = 'Back to Charged';
        this.cancelStatus = 2;
        break;
      case '2': // Charged

        if (this.transaction.raisedFlags && this.transaction.raisedFlags.length > 0
          && this.transaction.raisedFlags.filter(x => x.flag.code === 'PAYMENT_FAILED_AFTER_SUCCESS') != null
          && this.transaction.raisedFlags.filter(x => x.flag.code === 'PAYMENT_FAILED_AFTER_SUCCESS').length > 0) {
          this.nextStepButtonTitle = '';
          this.cancelButtonTitle = 'Reject';
          this.nextStatus = 0;
          this.cancelStatus = 6;
          break;
        }
        this.nextStepButtonTitle = 'Send';
        this.cancelButtonTitle = 'Refund';
        this.nextStatus = 3;
        this.cancelStatus = 4;
        if (this.transaction?.inboundStatus === 'RETURN_IN_PROGRESS') {
          this.nextStepButtonTitle = '';
          this.cancelButtonTitle = '';
          this.nextStatus = 0;
          this.cancelStatus = 0;
        }
        break;
      case '5': //Canceled
        this.nextStepButtonTitle = '';
        this.nextStatus = 0;

        if (this.transaction?.raisedFlags && this.transaction?.raisedFlags.length > 0
          && this.transaction.raisedFlags.filter(x => x.flag.code === 'PAYMENT_SUCCESS_AFTER_CANCEL') != null
          && this.transaction.raisedFlags.filter(x => x.flag.code === 'PAYMENT_SUCCESS_AFTER_CANCEL').length > 0) {
          this.cancelButtonTitle = 'Refund';
          this.cancelStatus = 4;
          break;
        }
        this.cancelButtonTitle = '';
        this.cancelStatus = 0;
        break;
      case '6': // Rejected
      default: //3 -Sent,4 - Refunded, 6 - Rejected
        this.nextStepButtonTitle = '';
        this.cancelButtonTitle = '';
        this.nextStatus = 0;
        this.cancelStatus = 0;
        break;
    }
  }

  getBack() {
    if (this.auth.isAdmin())
      this.drawerService.open();
    window.history.back();
  }



  onStatusClick(isCancel: boolean) {
    if (this.nextStatus == 3 && !isCancel) {
      this.openExternaIdDialog(this.transaction);
    } else {
      if (isCancel) {
        this.statusName = this.statuses.find(x => x.id == this.cancelStatus).name;
      } else {
        this.statusName = this.statuses.find(x => x.id == this.nextStatus).name;
      }
      this.openStatusChangeDialog(isCancel, this.transaction, this.statusName, this.cancelButtonTitle);
    }
  }

  changeStatus(
    transactionId: any,
    isCancel: any,
    chargedAmount: any,
    externalId: any
  ) {
    this.service
      .updateTransactionStatus(transactionId,
        (isCancel ? this.cancelStatus : this.nextStatus),
        chargedAmount,
        externalId)
      .subscribe({
        next: result => {
          this.ui.snack('Transaction saved.');

        },
        error: err => {

          this.ui.snack(
            err.message || 'There is an issue on saving the transaction.'
          );
        }
      });
  }

  openExternaIdDialog(transaction: any) {
    const data = {
      transaction,
      externalId: null
    };

    const dialogRef = this.dialog.open(ExternalIdComponent, {
      height: 'auto',
      width: '600px',
      data
    });

    dialogRef.afterClosed().subscribe(retVal => {
      if (retVal) {
        this.changeStatus(
          retVal.transactionId,
          retVal.statusId,
          null,
          retVal.externalId
        );
      }
    });
  }

  openStatusChangeDialog(isCancel: boolean, transaction: any, statusName: any, buttonTitle: string = null) {

    let dialogNote = null;
    if ((transaction.inbound === 'POLI') && (isCancel && this.cancelStatus == 4 || !isCancel && this.nextStatus == 4)) {
      dialogNote = "Please change status after you actually refunded the money within your payment provider.";
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px',
      data: {
        title: 'Change Status',
        content: (transaction?.inbound === 'APAYLO' && buttonTitle === 'Refund') ? ("Refund should be completed manually on provider's dashboard. When updating transaction status to Refunded, include refund transaction ID in note.") :
          'Are you sure you want to change transaction status from ' + transaction.status.name + ' to ' + statusName + '?',
        note: dialogNote
      },
      maxHeight: '80%',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.changeStatus(this.transaction.id, isCancel, null, null)
      }

    });
  }



  updateDocuments() {
    this.ms.updateDocument().afterClosed().subscribe(result => {
      if (result) {
        this.service.updateTransactionDocument(this.transaction.id).subscribe(
          res => {
            this.ui.snack('Document is updated.');
            this.checkDocument();
          },
          err => {
            this.ui.snack('Error in updating document.');
          }
        );
      }
    });
  }

  checkDocument() {
    if (this.transaction?.sDoc?.documentNumber == this.transaction?.sDocNumber &&
      this.transaction?.sDoc?.documentExp == this.transaction?.sDocExp &&
      this.transaction?.sDoc?.documentIssuer == this.transaction?.sDocIssuer &&
      this.transaction?.sDoc?.category?.name == this.transaction?.sDocTypeName &&
      this.transaction?.sDoc?.documentSource == this.transaction?.sDocSource) {
      this.updateDocument = true;
    } else {
      this.updateDocument = false;
    }
  }

  updateData() {
    this.updateRecipient = true;
    this.service.updateTransactionRecipientInfo(this.transaction.id).subscribe(res => {
      this.ui.snack('Transaction updated');
    });
  }

  ngOnDestroy() {
    this.transactionStatuses$?.unsubscribe();
    this.$transaction?.unsubscribe();
  }
}
