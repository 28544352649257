import { Component, OnInit, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { Subscribable, Subscription } from 'rxjs';
import { ConsentGraphType, KycLogType } from 'src/app/_graphql/schema';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminUserConsentsService } from 'src/app/_services/admin/admin-user-consents.service';
import { AdminKycLogsService } from 'src/app/_services/admin/admin-kyc-logs.service';

@Component({
  selector: 'app-admin-kyc-logs',
  templateUrl: './admin-kyc-logs.component.html',
  styleUrls: ['./admin-kyc-logs.component.scss']
})
export class AdminKycLogsComponent implements OnDestroy, AfterViewInit, OnInit {

  items$: Subscribable<KycLogType[]>;
  $form: Subscription;
  tickets$: Subscription;
  tickets: any[];
  form: FormGroup;


  uid: string;
  customKey: string;
  constructor(
    public service: AdminKycLogsService,
    private fb: FormBuilder,
    public auth: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {


    this.form = this.fb.group({
      userId: [this.route.snapshot.paramMap.get('userId')],
    });
    this.uid = this.form.get('userId').value;
    this.customKey = this.uid ? 'consents4' + this.uid : null;
  }
  ngAfterViewInit() {
    var filters = this.service.getPageRequestAllFilters()
    this.form.patchValue(filters.pageRequest?.filters, { emitEvent: false })
  }

  ngOnInit() {
    this.items$ = this.service.all(this.form.value, true, this.customKey);
    this.$form = this.form.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(data => {
      this.getData()
    });
  }
  getData() {
    this.service.queryAllParams.skip = 0;
    this.service.pageIndex = 0;
    this.service.fetchMoreData(this.form.value, this.customKey)
  }


  editItem(ticket: any) {
    // this.router.navigate(['.', 'ticket', ticket.id], { relativeTo: this.route })
  }

  ngOnDestroy(): void {
    this.$form?.unsubscribe();
  }
}
