import { Component, Input } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';

@Component({
  selector: 'app-account-list-item',
  templateUrl: './account-list-item.component.html',
  styleUrls: ['./account-list-item.component.scss']
})
export class AccountListItemComponent {
  @Input() item: any;
  error = false;
  statusMapper: any = {
    'VERIFIED': 'ACTIVE',
    'NEW': 'CREATED',
    'PENDING': 'SUSPENDED',
    'ERRORED': 'CANCELLED',
    'UNDEFINED': 'FAILED',
  }
  constructor(public mls: ModalLauncherService,
    public auth: AuthenticationService) {

  }

}