import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { LoginModule } from 'src/app/public/login/login.module';
import { SingleSelectModule } from '../select-country/single-select.module';
import { InputCodeComponent } from './input-code.component';

@NgModule({
  declarations: [
    InputCodeComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    TranslateModule.forChild(),
  ],
  exports: [
    InputCodeComponent
  ]
})
export class InputCodeModule { }
