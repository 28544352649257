<div class="panel">
  <!-- <mat-spinner *ngIf="loading"></mat-spinner> -->
  <div class="panel-content" *ngIf="!loading">
    <div class="panel-header">
      <mat-toolbar style="min-height: 0px;">
        <mat-toolbar-row>
          <ng-content select="[panel-header]"></ng-content>
        </mat-toolbar-row>
        <mat-toolbar-row>
          <ng-content select="[panel-toolbar]"></ng-content>
        </mat-toolbar-row>
      </mat-toolbar>
    </div>
    <div ngClass="panel-main" *ngIf="!loading">

      <div [style]="'padding:' + (padding === '' ? '0px' : padding)">
        <ng-content></ng-content>
      </div>

    </div>
    <div class="panel-footer" *ngIf="!loading">
      <ng-content select="[panel-footer]"></ng-content>
    </div>
  </div>

</div>