import { Component, Input } from '@angular/core';
import { MessageType } from 'src/app/_graphql/schema';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { PublicService } from 'src/app/_services/public.service';

@Component({
  selector: 'app-message-row-item',
  templateUrl: './message-row-item.component.html',
  styleUrls: ['./message-row-item.component.scss']
})
export class MessageRowItemComponent {
  @Input() item: MessageType;

  constructor(
    public service: PublicService,
    public auth: AuthenticationService
  ) {
  }


}