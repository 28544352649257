import { Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { UiService } from 'src/app/_services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminManagersService } from 'src/app/_services/admin/admin-managers.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { EndUserService } from 'src/app/_services/user/enduser.service';
import { Unsubscribable } from 'rxjs';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  hide1 = true;
  hide2 = true;
  hide3 = true;
  oldPassword: string;
  password: string;

  form = this.fb.group({
    userId: [],
    oldPassword: [null, Validators.required],
    newPassword: [null, Validators.required],
    confirmPassword: [null, Validators.required]
  });
unsubChangePassword$: Unsubscribable;
unsubResetPassword$: Unsubscribable;




  constructor(
    private fb: UntypedFormBuilder,
    private ui: UiService,
    private usersServices: EndUserService,
    private auth: AuthenticationService,
    private adminsService: AdminManagersService,
    private dialogRef: MatDialogRef<ChangePasswordComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    if (!this.data.isResetMode) {
      this.form.removeControl('userId');
    } else {
      this.form.controls.userId.setValue(this.data.user.id);
      this.form.controls.oldPassword.clearValidators();
    }

  }

  update() {
    if (!this.data.isResetMode) {
      if (this.form.get('newPassword').value === this.form.get('confirmPassword').value) {
      const password = this.form.value;
      delete password.confirmPassword;

      const service = this.auth.hasRoles(['User']) ? this.usersServices : this.adminsService;

      this.unsubChangePassword$ = service.changePassword(password).subscribe(
        () => {
          this.ui.snack('Password has been updated');
          this.dialogRef.close();
          this.unsubChangePassword$?.unsubscribe();
        },
        () => {
          this.unsubChangePassword$?.unsubscribe();
        }
);

    } else {
      this.ui.snack('New password does not match confirm password.');
    }
    } else {
      const data = Object.assign({}, this.form.value);
      delete data.confirmPassword;
      delete data.oldPassword;

      this.unsubResetPassword$ = this.adminsService.resetPasswordManager(data).subscribe({
        next: res => {
          this.ui.snack('Password has been updated');
          this.dialogRef.close();
          this.unsubResetPassword$?.unsubscribe();
        }
      }
      );
    }
  }
  ngOnDestroy(): void {
  }
}
