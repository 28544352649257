<div *ngIf="item" class="io-px-2 io-py-2">
  <mat-accordion>
    <mat-expansion-panel [expanded]="true">

      <mat-expansion-panel-header>

        <mat-panel-title>
          <mat-icon matListAvatar class="io-mr-2"
            [ngClass]="{'low': item.riskRating == 'LOW', 'high': item.riskRating == 'HIGH', 'medium': item.riskRating == 'MEDIUM'}">privacy_tip</mat-icon>
          Current Risk Rating: {{item.riskRating ? item.riskRating : 'Unrated'}}
        </mat-panel-title>
        <mat-panel-description *ngIf="!item.riskRating">
          You'll need to set risk rating for user.
        </mat-panel-description>
      </mat-expansion-panel-header>
      <table class="table-details">
        <tbody>
          <tr>
            <th>Risk Rating</th>
            <td>{{item.riskRating}}</td>
          </tr>
          <tr *ngFor="let it of item.riskAssessmentData; last as last;">
            <th>{{it.Key | translate}}</th>
            <td>
              <span *ngIf="it.Key == 'Birth_Date' ">
                {{it.Value | date}}
              </span>
              <span *ngIf="[ 'Maximum_Value'].indexOf(it.Key) >-1 ">
                  {{it.Value ? 'USD ' + it.Value : '> 1000 USD' }}
              </span>
              <span *ngIf="['Pep_Status', 'Pep_Hit', 'WatchList_Hit', 'Adverse_Media_Hit'].indexOf(it.Key) >-1 ">
                {{it.Value == "True" ? 'Yes' : 'No' }}
              </span>
              <span *ngIf="['Destination_Countries'].indexOf(it.Key) >-1 ">
                {{it.Value | toArray | arrayFormatter}}
              </span>
              <span *ngIf="[ 'Nationality'].indexOf(it.Key) >-1 ">
                <div *ngIf="!it.Value ">

                </div>
                <div *ngIf="it.Value ">
                  {{(countries$|async|filterOn:['id']:[it.Value])?.[0]?.name}}
                </div>
              </span>
              <span
                *ngIf="[ 'Nationality', 'Destination_Countries', 'Pep_Status','Adverse_Media_Hit', 'Maximum_Value', 'Birth_Date', 'Pep_Hit', 'WatchList_Hit'].indexOf(it.Key) == -1 ">
                {{it.Value}}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <mat-action-row class="io-px-2">
        <button mat-stroked-button color="primary" (click)="setCheckResults()">Change Check Results</button>
        <button mat-raised-button color="primary" (click)="setRating()">Set Rating</button>
      </mat-action-row>
      <!-- <pre>{{item|json}}</pre> -->
    </mat-expansion-panel>
  </mat-accordion>

</div>