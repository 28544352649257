import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent {
  environment = environment;
  publicNavLinks: any[];
  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    public auth: AuthenticationService
  ) {
    this.publicNavLinks = JSON.parse(this.environment.publicNavLinks)
  }


  show = true;

  logout() {
    this.router.navigate(['/logout']);
  }
}
