<mat-toolbar>
  <mat-toolbar-row class="sidenav-container admin">
    <button *ngIf="!displayBack" mat-icon-button (click)="drawer.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <button *ngIf="displayBack" mat-icon-button (click)="getBack(); drawer.toggle()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <img class="logo" alt="{{ 'company_name' | translate }} Backoffice" style="cursor: pointer"
      src="/assets/images/logo.svg" width="160px" />
    <span class="fill-remaining-space"></span>
    <span class="align-center"></span>
    <span class="spacer"></span>
    <button mat-button class="user-button" *ngIf="auth?.user" [matMenuTriggerFor]="menu">{{ auth?.user?.name }}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <!-- <button class="toolbar-button" mat-button (click)="logout()">Logout</button> -->

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="changePassword()">
        <span>Change Password</span>
      </button>
      <button mat-menu-item routerLink="/logout/admin">
        <span>Logout</span>
      </button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
<div class="loader" *ngIf="loading">
  <mat-progress-bar mode="query"></mat-progress-bar>
</div>
<mat-drawer-container color="primary" class="container" [hasBackdrop]="false">
  <mat-drawer #drawer mode="side" opened="true" style="padding-top: 15px;">
    <button mat-menu-item routerLink="/admin/users" *ngIf="auth.hasPermission(auth.perm.UsersRead)"
      routerLinkActive="active">
      <mat-icon>person</mat-icon>
      <span>Users</span>
    </button>
    <!-- <button mat-menu-item routerLink="/admin/reports" *ngIf="auth.hasAccess('reports')" routerLinkActive="active">
      <mat-icon>leaderboard</mat-icon>
      <span>Reports</span>
    </button> -->
    <button mat-menu-item routerLink="/admin/transactions" *ngIf="auth.hasPermission(auth.perm.TransactionsRead)"
      routerLinkActive="active">
      <mat-icon>payment</mat-icon>
      <span>Transactions</span>
    </button>
    <button mat-menu-item routerLink="/admin/payouts" *ngIf="auth.hasPermission(auth.perm.TransactionsPayoutsRead)"
      routerLinkActive="active">
      <mat-icon>payment</mat-icon>
      <span>Payouts</span>
    </button>
    <button mat-menu-item routerLink="/admin/tickets-and-flags" *ngIf="auth.hasPermission(auth.perm.TicketsRead)"
      routerLinkActive="active">
      <mat-icon>local_offer</mat-icon>
      <span>Tickets &amp; Flags</span>
    </button>
    <button mat-menu-item routerLink="/admin/regions" *ngIf="auth.hasPermission(auth.perm.RegionsRead)"
      routerLinkActive="active">
      <mat-icon>filter_hdr</mat-icon>
      <span>Regions</span>
    </button>
    <button mat-menu-item routerLink="/admin/countries" *ngIf="auth.hasPermission(auth.perm.CountriesRead)"
      routerLinkActive="active">
      <mat-icon>outlined_flag</mat-icon>
      <span>Countries</span>
    </button>
    <button mat-menu-item routerLink="/admin/corridors" *ngIf="auth.hasPermission(auth.perm.CorridorsRead)"
      routerLinkActive="active">
      <mat-icon>supervisor_account</mat-icon>
      <span>Corridors</span>
    </button>
    <button mat-menu-item routerLink="/admin/currencies" *ngIf="auth.hasPermission(auth.perm.CompanyManage)"
      routerLinkActive="active">
      <mat-icon>euro_symbol</mat-icon>
      <span>Currencies</span>
    </button>
    <button mat-menu-item routerLink="/admin/languages" *ngIf="auth.hasPermission(auth.perm.CompanyManage)"
      routerLinkActive="active">
      <mat-icon>language</mat-icon>
      <span>Languages</span>
    </button>
    <button mat-menu-item routerLink="/admin/translations" *ngIf="auth.hasPermission(auth.perm.CompanyManage)"
      routerLinkActive="active">
      <mat-icon>translate</mat-icon>
      <span>Translations</span>
    </button>
    <button mat-menu-item routerLink="/admin/admins" *ngIf="auth.hasPermission(auth.perm.ManagersRead)"
      routerLinkActive="active">
      <mat-icon>supervisor_account</mat-icon>
      <span>Managers</span>
    </button>
    <button mat-menu-item routerLink="/admin/relationships" *ngIf="auth.hasPermission(auth.perm.CompanyManage)"
      routerLinkActive="active">
      <mat-icon>repeat</mat-icon>
      <span>Relationships</span>
    </button>
    <button mat-menu-item routerLink="/admin/purposes" *ngIf="auth.hasPermission(auth.perm.CompanyManage)"
      routerLinkActive="active">
      <mat-icon>public</mat-icon>
      <span>Purposes</span>
    </button>
    <!-- <button mat-menu-item routerLink="/admin/corridors" *ngIf="auth.hasAccess('corridors')">
      <mat-icon>supervisor_account</mat-icon>
      <span>Corridors</span>
    </button> -->
  </mat-drawer>

  <mat-drawer-content style="height: calc(100vh - 64px);">
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>