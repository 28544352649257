<app-hero></app-hero>

<div>
    <h2>{{ 'component.faq.main_page_title'| translate }}</h2>
</div>

<div class="how-container">
<div class="container">
    <h3>{{ 'component.faq.group1_title' | translate }}</h3>
    <mat-accordion>
        <mat-expansion-panel class="accordion" *ngFor="let item of items1">
            <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto">
                <mat-panel-title style="padding: 2% 0% 2% 0%">
                    {{ item.title | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{ item.content | translate}}</p>
        </mat-expansion-panel>
    </mat-accordion>

    <h3>{{ 'component.faq.group2_title' | translate }}</h3>
    <mat-accordion>
        <mat-expansion-panel class="accordion" *ngFor="let item of items2">
            <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto">
                <mat-panel-title style="padding: 2% 0% 2% 0%">
                    {{ item.title | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{ item.content | translate }}</p>
        </mat-expansion-panel>
    </mat-accordion>

    <h3>{{ 'component.faq.group3_title' | translate }}</h3>
    <mat-accordion>
        <mat-expansion-panel class="accordion" *ngFor="let item of items3">
            <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto">
                <mat-panel-title style="padding: 2% 0% 2% 0%">
                    {{ item.title | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{ item.content | translate }}</p>
        </mat-expansion-panel>
    </mat-accordion>

    <h3>{{ 'component.faq.group4_title' | translate }}</h3>
    <mat-accordion>
        <mat-expansion-panel class="accordion" *ngFor="let item of items4">
            <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto">
                <mat-panel-title style="padding: 2% 0% 2% 0%">
                    {{ item.title | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{ item.content | translate }}</p>
        </mat-expansion-panel>
    </mat-accordion>

    <h3>{{ 'component.faq.group5_title' | translate }}</h3>
    <mat-accordion>
        <mat-expansion-panel class="accordion" *ngFor="let item of items5">
            <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto">
                <mat-panel-title style="padding: 2% 0% 2% 0%">
                    {{ item.title | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{ item.content | translate }}</p>
        </mat-expansion-panel>
    </mat-accordion>
</div>