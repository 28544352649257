import { Component, Input, ContentChildren, QueryList, AfterContentInit } from '@angular/core';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent  implements AfterContentInit {
  @Input() loading = false;
  @Input() size = '';
  @Input() padding = '';
  ngAfterContentInit() {
  }
}
