import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscribable, Subscription, Unsubscribable } from 'rxjs';
import { AdminEndUserType, EndUserType, FlagCode, FlagType, RaisedFlagType } from 'src/app/_graphql/schema';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminFlagsService } from 'src/app/_services/admin/admin-flags.service';
import { UiService } from 'src/app/_services/ui.service';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { AdminUsersService } from 'src/app/_services/admin/admin-users.service';
import { SystemService } from 'src/app/_services/system.service';

@Component({
  selector: 'app-admin-flags',
  templateUrl: './admin-flags.component.html',
  styleUrls: ['./admin-flags.component.scss']
})
export class AdminFlagsComponent implements OnDestroy, OnInit {


  items$: Subscribable<RaisedFlagType[]>;
  $form: Subscription;
  form: FormGroup;


  uid: string;
  customKey: string = null;
  $items: Unsubscribable;
  items: RaisedFlagType[];
  user$: Subscribable<AdminEndUserType>;
  codes = FlagCode;
  flags$: Subscribable<FlagType[]>;


  constructor(
    public service: AdminFlagsService,
    private fb: FormBuilder,
    public auth: AuthenticationService,
    private mls: ModalLauncherService,
    private router: Router,
    private ui: UiService,
    private route: ActivatedRoute,
    private users: AdminUsersService,
    private systemService: SystemService
  ) {

    this.form = this.fb.group({
      userId: [this.route.snapshot.paramMap.get('userId')],
      level: [null],
      resolved: [null],
      hasNoTicket: [false],
      transactionId: [this.route.snapshot.paramMap.get('transactionId')],
      code: [null]
    });
    var fd = this.router.getCurrentNavigation()?.extras?.state?.formData;
    if (fd) {
      this.form.patchValue(fd)
    }
  }

  ngOnInit() {
    this.flags$ = this.systemService.flags('USER', true);
    this.getData();
    this.items$ = this.service.all(this.form.value, true, null);
    if (this.route.snapshot.paramMap.get('userId'))
      this.user$ = this.users.one(this.route.snapshot.paramMap.get('userId'));
    this.$form = this.form.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(data => {
      this.getData()
    });
    this.ui.setTitle("Flags");
  }
  getData() {
    this.service.queryAllParams.skip = 0;
    this.service.pageIndex = 0;
    this.$items?.unsubscribe();
    this.service.allClose();
    this.$items = this.service.all(this.form.value, true, null, false).subscribe({
      next: res => {
        this.items = [...res];
      }
    })

  }

  public newItem() {
    this.mls.raiseFlag(this.form.get('userId').value, null)
  }


  editItem(item: any) {

    this.router.navigate(['.', 'flag', item.id], { relativeTo: this.route })
  }

  ngOnDestroy(): void {
    this.$form?.unsubscribe();
    this.$items?.unsubscribe();
  }
}
