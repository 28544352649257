import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { RaisedFlagType } from 'src/app/_graphql/schema';
import { BaseService } from './base.service';
import { UserInfoFragment } from 'src/app/_graphql/shared-fragments';


@Injectable({
  providedIn: 'root'
})
export class AdminFlagsService extends BaseService<RaisedFlagType>{

  selectOneFields = gql`
    fragment RaisedFlagSelectOneFields on RaisedFlagType  {
      id forUserId created resolved resolvedNote level
      ticket { id status number }
      flag {id name code isWarning presentedCode}
      resolvedBy { id ...UserInfoFragment }
      createdByName

      user { id ...UserInfoFragment }
      transaction { id transactionId userLastName userFirstName userMiddleName status { id name } toIsoCode2 fromIsoCode2
      currency paidAmount receivingCurrency receivingAmount recipientName recipientFirstName recipientPhone paymentMethodName
      paymentMethodNumber paymentNetworkName }
      description
  }
  ${UserInfoFragment}`;

  selectAllFields = gql`
    fragment RaisedFlagSelectAllFields on RaisedFlagType  {
      id forUserId created resolved resolvedNote level
      ticket { id status number }
      flag {id name code isWarning presentedCode}
      resolvedBy { id ...UserInfoFragment }
      createdByName

      user { id ...UserInfoFragment }
      transaction { id transactionId userLastName userFirstName userMiddleName status { id name } toIsoCode2 fromIsoCode2
        currency paidAmount receivingCurrency receivingAmount recipientName recipientFirstName recipientPhone paymentMethodName
        paymentMethodNumber paymentNetworkName }
      description
  }
  ${UserInfoFragment}`;

  constructor(protected apollo: Apollo) {
    super(apollo);
    this.mainScope = "flagsAndTickets";
    this.initGql('raisedFlag');
  }

  public raiseFlag(data: any) {
    return this.mutation(
      gql
        `mutation raiseFlag(
          $transactionId: ID
          $userId: ID
          $flagId: ID!
          $description: String
          $raiseTicket: Boolean
        ) {
          flagsAndTickets {
          __typename
          raiseFlag(
            transactionId: $transactionId
            userId: $userId
            flagId: $flagId
            description: $description
            raiseTicket: $raiseTicket
          ) {
            id
            ...RaisedFlagSelectOneFields
            __typename
          }
        }
      }
      ${this.selectOneFields}
      `, data
    );
  }
  public resolveFlag(data: any) {
    return this.mutation(
      gql
        `mutation resolveFlag(
            $raisedFlagId: ID!
            $note: String!
            $markPositive: Boolean,
            $action: ResolveFlagAction
          ) {
            flagsAndTickets {
            __typename
            resolveFlag(
              raisedFlagId: $raisedFlagId
              note: $note
              markPositive: $markPositive,
              action: $action
            ) {
              id
              ...RaisedFlagSelectOneFields
              __typename
            }
          }
        }
        ${this.selectOneFields}
        `, data
    );
  }
  statsActive() {
    return this.unwrapData('flagsActive', this.query(
      gql`query raisedFlagsAll {
        flagsAndTickets {
          flagsActive:raisedFlags(pageRequest:{ filters: { resolved:false }}){
            totalCount
          }
        }
      }
      `
    ))
  }
  statsTotal() {
    return this.unwrapData('flagsTotal', this.query(
      gql`query raisedFlagsAll {
        flagsAndTickets {
          flagsTotal:raisedFlags(pageRequest:{ filters: { resolved:null }}){
            totalCount
          }
        }
      }
      `
    ))
  }
}
