<form [formGroup]="form">

  <div mat-dialog-title>
    {{ form.get('id').value ? 'Edit Manager' : 'Add Manager'}}
  </div>

  <mat-dialog-content>
    <div class="io-row-l">
      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input matInput required formControlName="firstName">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Middle Name</mat-label>
        <input matInput formControlName="middleName">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input matInput required formControlName="lastName">
      </mat-form-field>
    </div>
    <div class="io-row-l">
      <!-- <mat-form-field>
        <mat-label>Country</mat-label>
        <mat-select formControlName="countryId">
          <mat-option [value]="item.id" *ngFor="let item of countriesAll$ | async" [disabled]="!item.isActive">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->
      <app-single-select formControlName="countryId" [items]="countriesAll$ | async"
        placeholder="{{ 'Country' | translate }}" [required]="true">
      </app-single-select>
      <mat-form-field
        [ngClass]="{'phone-hint': form.get('phone').errors?.pattern || form.get('phone').errors?.maxlength }">
        <mat-label>Telephone</mat-label>
        <input matInput type="text" required formControlName="phone"
          [ngStyle]="{'padding-bottom.px': (form.get('phone').value == '' || form.get('phone').value == null  ? 0: 4)}" />

        <span matPrefix
          *ngIf="(countriesAll$ | async|filterOn:['id']:[form.get('countryId').value]) as prefix">{{prefix[0]?.phonePrefix
          ?
          '+'+prefix[0]?.phonePrefix : ''}}&nbsp;</span>

        <button mat-icon-button matSuffix disabled>
          <mat-icon>phone</mat-icon>
        </button>
        <mat-hint *ngIf="form.get('phone').valid && form.get('phone').errors?.required">Telephone is a required field.
        </mat-hint>
        <mat-hint *ngIf="!form.get('phone').valid && form.get('phone').errors?.pattern">Telephone can contain just
          digits.
        </mat-hint>
        <mat-hint *ngIf="!form.get('phone').valid && form.get('phone').errors?.maxlength">Telephone cannot contain more
          then 18 digits.</mat-hint>
      </mat-form-field>

      <mat-form-field>
        <mat-label>E-mail</mat-label>
        <input matInput required formControlName="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$">
      </mat-form-field>
    </div>
    <div>
    </div>

    <div class="io-row-l">
      <mat-form-field>
        <mat-label>Username</mat-label>
        <input matInput required formControlName="userName">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Access Level</mat-label>
        <mat-select formControlName="accessLevel">
          <mat-select-trigger *ngIf="nameRoles$ | async | filterOn:['key']:[form.get('accessLevel')?.value] as v">
            {{v?.[0]?.value}}
        </mat-select-trigger>
          <mat-option [value]="item.key" *ngFor="let item of nameRoles$ | async" class="multiline-mat-option">
            {{ item?.value}}
            <small>{{ item?.description }}</small>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="!form.get('id').value">
      <mat-form-field class="width-100"
        hintLabel="{{'Needs to contain at least one lower, upper and one digit character.'}}">
        <mat-label>Password</mat-label>
        <input #password matInput required minlength="6" formControlName="password"
          [type]="hide ? 'password' : 'text'" />
        <button mat-icon-button type="button" matSuffix [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-hint align="end">
          {{password.value.length}} / {{ 6 }}
        </mat-hint>
      </mat-form-field>

      <app-password-strength [hasNumber]="true" [hasUpper]="true" [hasLower]="true"
        [userPassword]="this.form.get('password')">
      </app-password-strength>
    </div>
    <div *ngIf="auth.hasPermission(auth.perm.ManagerRestriction)">
      <h4>{{ 'Constraints' | translate }}</h4>
      <small>Users will have access to transactions within the following parameters:</small>
      <div class="io-row-l" style="margin-top: 10px;">
        <app-multiselect-search formControlName="assignedToCountryIds" [list]="countries$ | async"
          placeholder="{{ 'Countries' | translate }}">
        </app-multiselect-search>
        <app-multiselect-search formControlName="assignedServiceIds" [list]="paymentServices$ | async"
          placeholder="{{ 'Payment service' | translate }}">
        </app-multiselect-search>
        <app-multiselect-search formControlName="assignedNetworkIds" [list]="paymentNetworks$ | async"
          [optionValue]="'extendedName'" placeholder="{{ 'Payment network' | translate }}">
        </app-multiselect-search>
      </div>
    </div>
    <!-- <pre>{{ form.value | json }}</pre> -->
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <mat-checkbox color="primary" *ngIf="form.get('id').value" formControlName="isActive">Active</mat-checkbox>
    <span style="flex:1"></span>
    <button mat-button color="primary" mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid" (click)="onSubmit()">Save</button>
  </mat-dialog-actions>

</form>