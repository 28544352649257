import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PublicService } from 'src/app/_services/public.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  fusion = [
    {
      img: '/assets/images/lowcost.png',
      title: 'page.home.feature_title1',
      content: 'page.home.feature_content1'
    },
    {
      img: '/assets/images/easy.png',
      title: 'page.home.feature_title2',
      content: 'page.home.feature_content2'
    },
    {
      img: '/assets/images/secure.png',
      title: 'page.home.feature_title3',
      content: 'page.home.feature_content3'
    },
    {
      img: '/assets/images/pickup.png',
      title: 'page.home.feature_title4',
      content: 'page.home.feature_content4'
    }
  ];

  more = [
    {
      img: '/assets/images/mobile.png',
      title: 'page.home.feature_title5',
      content: 'page.home.feature_content5'
    },
    {
      img: '/assets/images/bank.png',
      title: 'page.home.feature_title6',
      content: 'page.home.feature_content6'
    },
    {
      img: '/assets/images/pickup.png',
      title: 'page.home.feature_title7',
      content: 'page.home.feature_content7'
    }
  ];

  items = [
    {
      title: 'page.home.faq_question1',
      content: 'page.home.faq_question_content1'
    },
    {
      title: 'page.home.faq_question2',
      content: 'page.home.faq_question_content2'
    },
    {
      title: 'page.home.faq_question3',
      content: 'page.home.faq_question_content2'
    }
  ];
  countriesFrom$: any;
  countriesTo$: any;

  countries: any[];
  countriesFrom: any[];
  countriesTo: any[];
  countryFrom: any;
  countryTo: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public publicService: PublicService,
  ) {

    this.publicService.getCorridorsGroups().subscribe({
      next: res => {
        this.countries = res;
        this.countriesFrom = res.map((x: any) => ({
          id: x.from.id,
          name: x.from.name,
          isoCode3: x.from.isoCode3,
          isoCode2: x.from.isoCode2,
          isActive: x.from.isActive
        }));

        this.countryFrom = this.countriesFrom.find(x => x.isoCode3 === 'SWE');
        if (this.countryFrom) {
          this.fromCountrySelected(this.countryFrom.id);
        }


      }
    });
  }

  ngOnInit() { }

  fromCountrySelected(id: string) {
    if (!id) {
      return;
    }

    this.countryFrom = this.countriesFrom.find(x => x.id === id);

    this.countriesTo = [];
    const selectedCountry = this.countries.find(x => x.from.id === id);
    this.countriesTo = selectedCountry.toCountries.map(x => ({
      id: x.country.id,
      name: x.country.name,
      isoCode3: x.country.isoCode3,
      isoCode2: x.country.isoCode2,
      isActive: x.country.isActive
    }));
  }

  toCountrySelected(id: string) {
    if (!this.countryFrom) {
      return;
    }

    const countryToName = this.countriesTo.find(x => x.id === id).name;
    this.router.navigate(['calculate/', this.countryFrom.name, 'to', countryToName]);
  }
}
