<div class="io-item io-item-shadow" [ngClass]="{'clickable': auth.isAdmin()}" (click)="transactionDetails(item)">
    <div class="main">
        <h4>{{ item?.transactionId }}</h4>
        <div class="data country">
            <small>{{item?.fromName}}</small>
            <span>{{item?.toName}}
                <!-- <s matTooltip="{{'City'|translate}}">{{ item.recipientCity }}</s> -->
                <s *ngIf="auth.isAdmin()">
                    <table>
                        <tr>
                            <td *ngIf="item?.transactionStatusInfo.flagCount > 0"
                                [matTooltip]="item?.transactionStatusInfo.flagTooltip">
                                <mat-icon>error</mat-icon>
                            </td>
                            <td *ngIf="item?.transactionStatusInfo.flagCount > 0">
                                {{item?.transactionStatusInfo.flagCount}}
                            </td>
                            <td *ngIf="item?.transactionStatusInfo.ticketCount > 0"
                                [matTooltip]="item?.transactionStatusInfo.ticketTooltip">
                                <mat-icon>local_offer</mat-icon>
                            </td>
                            <td *ngIf="item?.transactionStatusInfo?.ticketCount > 0">
                                {{item?.transactionStatusInfo?.ticketCount}}
                            </td>
                        </tr>
                    </table>
                </s>
            </span>
        </div>
        <div class="data money">
            <small>{{ item?.currency }} {{ item?.paidAmount | number: '1.2-2' }}</small>
            <span>{{ item?.receivingCurrency }} {{ item?.receivingAmount | number: '1.2-2' }}
            </span>
        </div>
        <app-transaction-status [iconOnly]="true" [status]="item?.transactionStatusInfo">
        </app-transaction-status>
        <div class="data status">
            <small>{{ 'Status' | translate }}</small>
            <span>
                <aside matTooltip="{{ ('transaction_'+item?.transactionStatusInfo?.name?.replace(' ', '_'
            )?.toUpperCase()+'_info') | translate }}">{{item?.transactionStatusInfo?.name}}</aside>
                <s matTooltip="{{ item?.created | timezone | date: 'short' }}">
                    {{ item?.created | dateAgo }}
                    <aside *ngIf="!auth.isAdmin()">{{ item?.transactionStatusInfo?.note | translate }}</aside>
                </s>
            </span>
        </div>
        <div class="data" *ngIf="auth.isAdmin()">
            <small>{{'Sender'|translate}}</small>
            <span>{{ item?.userFirstName}} {{ item?.userLastName}}
                <s [matTooltip]="item?.userEmail">{{item?.userEmail}}</s>
                <s>
                    <table>
                        <tr>
                            <td *ngIf="item?.sender?.userStatusInfo?.flagCount > 0"
                                [matTooltip]="item?.sender.userStatusInfo.flagTooltip">
                                <mat-icon>error</mat-icon>
                            </td>
                            <td *ngIf="item?.sender?.userStatusInfo?.flagCount > 0">
                                {{item?.sender.userStatusInfo.flagCount}}
                            </td>
                            <td *ngIf="item?.sender?.userStatusInfo?.ticketCount > 0"
                                [matTooltip]="item?.sender.userStatusInfo.ticketTooltip">
                                <mat-icon>local_offer</mat-icon>
                            </td>
                            <td *ngIf="item?.sender?.userStatusInfo?.ticketCount > 0">
                                {{item?.sender.userStatusInfo.ticketCount}}
                            </td>
                        </tr>
                    </table>
                </s>
            </span>
        </div>

        <div class="data recipient">
            <small>{{'Recipient'|translate}}</small>
            <span>{{ item?.recipientFirstName}} {{ item?.recipientLastName}}
                <s>{{item?.recipientPhone}}</s>
            </span>
        </div>
        <div class="data inbound">
            <small>{{'Inbound'|translate}}</small>
            <span>
                {{ (item?.paymentMethodName == null ? 'Not defined' : item?.paymentMethodName) | translate }}
                <s>{{item?.paymentMethodNumber}}</s></span>
        </div>
        <app-outbound-status *ngIf="auth.hasPermission(auth.perm.TransactionOutboundStatusManage)" [iconOnly]="true"
            [status]="item.outboundStatus"></app-outbound-status>
        <div class="data outbound">
            <small>{{'Outbound'|translate}}</small>
            <span>
                {{item.paymentNetworkName }}

                <s *ngIf="item?.outboundRefNo && auth.isAdmin()">
                    {{item?.outboundRefNo}}
                </s>
                <s *ngIf="item?.pickupCode && !auth.isAdmin()">
                    {{item?.pickupCode}}
                </s>

            </span>
        </div>
        <button mat-raised-button color="primary" class="btn" *ngIf="!auth.isAdmin() 
        && item?.continue?.enabled"
            (click)="modalLauncherService.pay(item)">{{
            'Continue'
            | translate }}</button>
        <div class="filler" *ngIf="!auth.isAdmin() && !item?.continue?.enabled">
        </div>
        <button class="button" mat-icon-button (click)="onShowHistory($event, item)"
            *ngIf="auth.hasPermission(auth.perm.TransactionsHistoryRead) || !auth.isAdmin()">
            <mat-icon *ngIf="!showHistory">expand_more</mat-icon>
            <mat-icon *ngIf="showHistory">expand_less</mat-icon>
        </button>

    </div>
    <div [ngClass]="{expand:true, open: showHistory}">
        <div *ngIf="($history|async) as history; else loading">
            <table class="table-details" style="width: unset">
                <tbody>
                    <tr class="history-info" *ngFor="let th of (history?.data ? history?.data : history)">
                        <td *ngIf="auth.isAdmin()" style="max-width: unset; min-width:unset; width:unset">{{
                            th?.createdByName }}</td>
                        <th width="140" style="max-width: unset; min-width:unset; width:unset">{{th.created | timezone2
                            | date:'short'
                            }}</th>
                        <th style="max-width: unset; min-width:unset; width:unset">{{ th?.note }} </th>
                    </tr>
                    <tr *ngIf="history.length == 0">
                        <td>{{ 'No recorded changes found.' | translate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-template #loading>{{'Loading...'|translate}}</ng-template>
        <div *ngIf="!auth.isAdmin()" style="padding-top: 24px;" class="io-row-m">

            <button mat-stroked-button [disabled]="!item?.canCancel?.enabled" (click)="cancelTransactionDialog(item)"
                class="no-m-flex">
                {{
                'Cancel' | translate
                }}
            </button>
            <button mat-stroked-button *ngIf="receipt" (click)="publicService.downloadFile(receipt?.id, receipt?.name)"
                class="no-m-flex">
                {{
                'Download Receipt' | translate
                }}
            </button>
            <button mat-stroked-button *ngIf="item?.inbound === 'MANUAL'"
                [disabled]="item?.status?.id !== '1' || item?.transactionStatusInfo?.name !== item?.status?.name"
                (click)="resendInstructions(item)" class="no-m-flex">
                {{ 'Resend Instructions' | translate }}
            </button>
        </div>
    </div>
</div>