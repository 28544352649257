import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { AdminTransactionType, TransactionsFilters } from 'src/app/_graphql/schema';
import { DocumentFragmentBasic, RecipientFragmentBasic, RecipientOnAdminTransactionFragmentBasic } from 'src/app/_graphql/shared-fragments';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class AdminTransactionsService extends BaseService<AdminTransactionType>{

  selectOneFields = gql`
    fragment TransactionSelectOneFields on AdminTransactionType  {
      id
      created
      transactionId
      transactionStatusInfo { 
        statusId, note, icon, name, flagCount, flagTooltip, ticketCount, ticketTooltip
      }
      fromIsoCode3 fromIsoCode2 fromName
      toIsoCode3 toName
      canUpdateRecipientInfo
      updateRecipientInfoText
      inbound inboundName inboundStatus inboundRefNo
      settlementData {
        name errors senderId
        __typename
        data { amount currency id status type __typename }
      }
      outboundSettlementAmount outboundSettlementCurrency 
      outboundSettlementQuote { amount fromCurrency toCurrency}
      paymentNetworkCode paymentNetworkName paymentServiceName outboundStatus outboundRefNo remarks payoutAgentName
      currency amount paidAmount variableFeePaid fixedFeePaid paymentNetwork  { id name extendedName }
      receivingCurrency receivingAmount checkCurrency checkQuote quote receivingQuote
      raisedFlags(resolved: false) { id flag { id presentedCode } }
      notes { created createdBy note }
      to { id name isoCode2 isoCode3 }
      sDocId sDocTypeName sDocNumber sDocExp sDocIssuer sDocSource
      sDoc {
        ...DocumentFragmentBasic
      }
      status {
        id
        name
      }
      ... RecipientOnAdminTransactionFragmentBasic
      recipient {
        id
        ... RecipientFragmentBasic
        moneySent
      }
      sender {
        id firstName lastName created moneySent moneyReceived
        country {
          id
          name
          currency
          isoCode2
          isoCode3
        },
      }
      paymentMethodName  
      userAddressLine1 userSocialNumber userAddressLine2 userCity userCountry userBirthDate userEmail userFirstName userId userLastName 
      userMiddleName userPhone userTerritory userUnitNumber userUserName userZip 
      relationshipName purposeName
  }
  ${DocumentFragmentBasic}
  ${RecipientOnAdminTransactionFragmentBasic}
  ${RecipientFragmentBasic}
  `;

  selectAllFields = gql`
    fragment TransactionSelectAllFields on AdminTransactionType  {
      id transactionId fromName fromIsoCode2 toName currency paidAmount receivingCurrency receivingAmount
      created
      userFirstName userLastName userEmail userId userAddressLine1 userSocialNumber userAddressLine2 userCity userCountry 
      userBirthDate userMiddleName userPhone userTerritory userUnitNumber userUserName userZip
      transactionStatusInfo { 
        statusId, note, icon, name, flagCount, flagTooltip, ticketCount, ticketTooltip
      }
      sender {
        id
        userStatusInfo {
          flagCount ticketTooltip ticketCount
        }
      }
      recipientFirstName recipientLastName  recipientPhone
      paymentMethodName paymentMethodNumber paymentNetworkName outboundRefNo outboundStatus
  }`;

  constructor(protected apollo: Apollo) {
    super(apollo);
    this.mainScope = "transactionsManagement";
    this.initGql('transaction');
  }

  public updateTransactionDocument(transactionId: string) {
    return this.apollo.mutate({
      mutation: gql
        `mutation updateTransactionDocument($transactionId: ID!) {
          transactionsManagement {
            __typename
            updateTransactionDocument(transactionId: $transactionId) {
              id
              ...TransactionSelectOneFields
            }
          }
        }
      ${this.selectOneFields}
      `,
      refetchQueries: [
        ...this.refetchAdditionalQueries
      ],
      variables: { transactionId }
    });
  }

  public changeTransactionDocument(data: any) {
    return this.apollo.mutate({
      mutation: gql
        `mutation changeTransactionDocument($transactionId: ID!, $documentId: ID!) {
          transactionsManagement {
            __typename
            changeTransactionDocument(transactionId: $transactionId, documentId: $documentId) {
              id
              ...TransactionSelectOneFields
            }
          }
        }
      ${this.selectOneFields}
      `,
      refetchQueries: [
        ...this.refetchAdditionalQueries
      ],
      variables: data
    });
  }

  public updateTransactionRecipientInfo(transactionId: string) {
    return this.apollo.mutate({
      mutation: gql
        `mutation updateTransactionRecipientInfo($transactionId: ID!) {
          transactionsManagement {
            __typename
            updateTransactionRecipientInfo(transactionId: $transactionId) 
          }
        }
      `,
      refetchQueries: [
        ...this.refetchAdditionalQueries
      ],
      variables: { transactionId }
    });
  }

  public updateTransactionStatus(transactionId: string, statusId: any, chargedAmount: number,
    externalId: string) {
    return this.apollo.mutate({
      mutation: gql
        `mutation updateTransactionStatus($transactionId: ID!, $statusId: ID!, $chargedAmount: Float, $externalId: String) {
          transactionsManagement {
            __typename
            updateTransactionStatus(transactionId: $transactionId, statusId: $statusId, chargedAmount: $chargedAmount, externalId: $externalId) {
              id
              ...TransactionSelectOneFields
            }
          }
        }
      ${this.selectOneFields}
      `,
      refetchQueries: [
        ...this.refetchAdditionalQueries
      ],
      variables: { transactionId, statusId, chargedAmount, externalId }
    });
  }

  public addNote(data: any) {
    return this.apollo.mutate({
      mutation: gql
        `mutation addNote($transactionId: ID, $note: String) {
          transactionsManagement {
            __typename
            addNote(transactionId: $transactionId, note: $note) {
              created
              createdBy
              note
            }
          }
        }
      `,
      refetchQueries: [
        ...this.refetchAdditionalQueries
      ],
      variables: data
    });
  }



  public report(filters: TransactionsFilters = null): any {
    return this.query(gql`
          query transactionsReport($filters: TransactionsFilters) {
            transactionsManagement { id __typename transactionsReport (filters:$filters) }
            __typename
          }
        `,
      { filters },
      false, 'transactionsReport'
    );
  }
}
