import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionItemComponent } from './transaction-item/transaction-item.component';
import { TransactionsHistoryComponent } from './transactions-history/transactions-history.component';
import { AdminTransactionsComponent } from './transactions.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { StatusesModule } from 'src/app/components/statuses/statuses.module';
import { AddNoteComponent } from './transaction-item/add-note/add-note.component';
import { ExternalIdComponent } from './transaction-item/external-id/external-id.component';
import { NotesListComponent } from './transaction-item/notes-list/notes-list.component';
import { TransactionsModule } from 'src/app/components/transactions/transactions.module';
import { ListWrapperModule } from '../list-wrapper/list-wrapper.module';
import { FlagsModule } from '../flags/flags.module';
import { CountryFlagModule } from 'src/app/components/country-flag/country-flag.module';
import { RouterModule } from '@angular/router';
import { SingleSelectModule } from 'src/app/components/select-country/single-select.module';
import { FormConfigsModule } from 'src/app/components/form-configs/form-configs.module';
import { TransactionHistoryListComponent } from './transaction-item/transaction-history-list/transaction-history-list.component';
import { SenderSectionModule } from '../sender-section/sender-section.module';



@NgModule({
  declarations: [
    TransactionItemComponent,
    TransactionsHistoryComponent,
    AdminTransactionsComponent,
    AddNoteComponent,
    ExternalIdComponent,
    NotesListComponent,
    TransactionHistoryListComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    TranslateModule.forChild(),
    StatusesModule,
    SingleSelectModule,
    TransactionsModule,
    ListWrapperModule,
    FlagsModule,
    CountryFlagModule,
    RouterModule,
    FormConfigsModule,
    SenderSectionModule
  ],
  exports: [
    TransactionItemComponent,
    TransactionsHistoryComponent,
    AdminTransactionsComponent,
    AddNoteComponent,
    ExternalIdComponent,
    NotesListComponent
  ]
})
export class AdminTransactionsModule { }
