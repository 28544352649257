<div *ngIf="item$|async as item">
    <div class="io-row align-items-center io-pl-3 border-bottom" style="background-color: white; ">
        <button mat-icon-button routerLink="../.." class="no-flex">
            <mat-icon>chevron_left</mat-icon>
        </button>
        <app-admin-flags-list-item [item]="item" [hasShadow]="false" [isClickable]="false">
            <div class="actionButtons">
                <button mat-stroked-button class="resolve-btn" class="no-m-flex" (click)="mls.removeFlag(item);"
                    *ngIf="!item.resolved && auth.hasPermission(auth.perm.FlagsManage) && item?.flag?.code !== 'DELETE_ACCOUNT_REQUESTED'">
                    {{ 'Resolve' | translate }}
                </button>
                <button mat-raised-button color="primary" *ngIf="item.ticket && auth.hasPermission(auth.perm.FlagsRead)"
                    class="no-m-flex"
                    [routerLink]="['/','admin','users', item.forUserId, 'tickets','ticket', item.ticket.id]">
                    {{ 'View Ticket' | translate }}
                </button>
                <button mat-raised-button color="primary" class="no-m-flex"
                    *ngIf="!item.resolved && !item.ticket && auth.hasPermission(auth.perm.FlagsManage) && ['WATCHLIST_HIT', 'KYC_PEP'].indexOf(item?.flag?.code) == -1"
                    (click)="mls.createTicket(item, item.forUserId, item.transaction?.id);">
                    {{ 'Add Ticket' | translate }}
                </button>
            </div>

        </app-admin-flags-list-item>
    </div>
    <div class="io-row-l io-mx-2 io-my-2">
        <div class="flex-3">
            <mat-card class="io-mb-2">
                <mat-card-title>Flag Info</mat-card-title>
                <mat-card-content>
                    <div class="io-row-l align-items-start">
                        <table class="table-details narrow">
                            <tbody>
                                <tr>
                                    <th>Description</th>
                                    <td>{{item?.description }}</td>
                                </tr>
                                <tr>
                                    <th>Created</th>
                                    <td>{{item?.created | timezone | date }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table-details narrow">
                            <tbody>
                                <tr>
                                    <th>Created by</th>
                                    <td>{{item?.createdByName }}</td>
                                </tr>
                                <tr>
                                    <th>Resolved</th>
                                    <td>{{ item?.resolved | timezone | date | noData:'—' }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table-details narrow">
                            <tbody>
                                <tr>
                                    <th>Resolved by</th>
                                    <td>{{ item?.resolvedBy?.firstName | noData:'—' }} {{ item?.resolvedBy?.lastName }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Resolved Note</th>
                                    <td>{{ item?.resolvedNote | noData:'—' }}</td>
                                </tr>
                             
                            </tbody>
                        </table>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card class="io-mb-2" *ngIf="(deleteAccountSteps$ | async)?.deleteAccountSteps?.steps">
            <ng-container *ngIf="((deleteAccountSteps$ | async)?.deleteAccountSteps) as deleteSteps">
            <mat-card-title>User Data</mat-card-title>
            <mat-card-content>
            <mat-accordion class="delete-steps-accordion">
                <mat-expansion-panel *ngFor="let step of deleteSteps?.steps" 
                [ngClass]="step?.status === 'RESOLVED' ? 'io-item non-expandable' : 'io-item'" 
                [expanded]="deleteSteps?.steps | firstNotStarted : step" 
                [hideToggle]="step?.status === 'RESOLVED' ? true : false">
                    <mat-expansion-panel-header class="main">
                        <div class="data">
                            <small>{{'Account'| translate}}</small>
                            <span [matTooltip]="step?.title">{{ step?.title }}</span>
                        </div>
                        <mat-icon class="small-status" *ngIf="step.status === 'RESOLVED'" color="success">check_circle</mat-icon>
                        <mat-icon class="small-status" *ngIf="step.status === 'PENDING'" color="warn">info</mat-icon>
                        <mat-icon class="small-status" *ngIf="step.status === 'NOT_STARTED'" color="accent">replay_circle_filled</mat-icon>
                        <div class="data status">
                            <small>{{'Status'| translate}}</small>
                            <span>{{ step?.status | translate }} </span>
                        </div>
                        <div class="data">
                            <small>{{'Date'| translate}}</small>
                             <span>{{ (step?.status === 'RESOLVED' ? step.resolved : step.executed) | timezone | date | noData: '—' }}</span>
                        </div>
                        <div class="data">
                            <small *ngIf="step?.description && step?.status !== 'RESOLVED'">{{'Description'| translate}}</small>
                            <span *ngIf="step?.description && step?.status !== 'RESOLVED'">{{ step?.description }}</span>
                        </div>  
                    </mat-expansion-panel-header>
                    <div class="io-row-l align-items-start">
                        <table class="table-details">
                            <tbody>
                                <tr *ngIf="step?.infoMessage && step.status !== 'RESOLVED'">
                                    <th class="th-narrow">{{'Info Message'| translate}}</th>
                                    <td>{{ step?.infoMessage }}</td>
                                </tr>
                                <tr *ngIf="step?.statusMessage">
                                    <th class="th-narrow">{{'Status Message'| translate}}</th>
                                    <td>{{step?.statusMessage }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <mat-card-content class="delete-steps-cards">
                <mat-card class="io-row-l align-items-start" *ngFor="let step of deleteSteps?.steps" style="margin-top: 8px;">
                    <table class="table-details">
                        <tbody>
                            <tr>
                                <th>{{'Account' | translate}}</th>
                                <td>{{ step?.title }}</td>
                            </tr>
                            <tr>
                                <th>{{'Status' | translate}}</th>
                                <td>{{step?.status | translate }}</td>
                            </tr>
                            <tr>
                                <th>{{'Date' | translate}}</th>
                                <td>{{ (step?.status === 'RESOLVED' ? step.resolved : step.executed) | timezone | date | noData: '—' }}</td>
                            </tr>
                            <tr *ngIf="step?.description && step?.status !== 'RESOLVED'">
                                <th>{{'Description' | translate}}</th>
                                <td>{{step?.description }}</td>
                            </tr>
                            <tr *ngIf="step?.infoMessage && step.status !== 'RESOLVED'">
                                <th>{{'Info Message' | translate}}</th>
                                <td>{{step?.infoMessage | noData:'—' }}</td>
                            </tr>
                            <tr>
                                <th>{{'Status Message' | translate}}</th>
                                <td>{{step?.statusMessage | noData:'—' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card>
            </mat-card-content>
            <mat-action-row class="action-row">
                <button mat-stroked-button color="primary" (click)="mls.abandonDelete(item, 'CHECK_DELETE')" *ngIf="!item?.resolved"
                [disabled]="deleteButtonsDisabled || !(deleteSteps?.canAbandon)">
                    {{ 'Abandon Delete' | translate }}
                </button>
                <button mat-raised-button color="primary" (click)="deleteAccount()" *ngIf="!item?.resolved" 
                [disabled]="deleteButtonsDisabled">
                    {{ 'Delete Account' | translate }}
                </button>
              </mat-action-row>
        </mat-card-content>
    </ng-container>
    </mat-card>
        </div>
        

        <div>
            <div *ngIf="item?.level=='USER' && item?.forUserId">
                <mat-accordion *ngIf="(this.adminUsersService.one(item?.forUserId)|async) as user">
                    <mat-expansion-panel class="sender-receiver-card" expanded="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="io-row align-items-center">
                                    <app-country-flag [item]="{isoCode3: user.country.isoCode3}" class="no-flex"
                                        [size]="'big'"></app-country-flag>
                                    <div>
                                        <small>{{ 'User' | translate }}</small>
                                        {{ item?.user?.firstName }} {{ item?.user?.lastName }}
                                    </div>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <form-data-display-configuration [columns]="1" [hideEmptyFields]="true" fieldsGroup="updateUser"
                            [country]="user?.country?.isoCode2" [data]="user">
                        </form-data-display-configuration>
                        <mat-action-row>
                            <button mat-raised-button color="primary"
                                [routerLink]="['/', 'admin', 'users', item?.user?.id]">View
                                profile</button>
                        </mat-action-row>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div *ngIf="item?.level=='TRANSACTION'">
                <mat-accordion *ngIf="(this.adminTransactionsService.one(item?.transaction.id)|async) as transaction">
                    <app-sender-section *ngIf="(this.adminTransactionsService.one(item?.transaction.id)|async) as transaction" 
                    [transaction]="transaction"></app-sender-section>
                    <div class="io-mb-2" style="margin-top: 16px;">
                        <mat-expansion-panel class="sender-receiver-card">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <div class="io-row align-items-center">
                                        <app-country-flag [item]="transaction.to" class="no-flex"
                                            [size]="'big'"></app-country-flag>
                                        <div>
                                            <small>{{ 'Transaction' | translate }}</small>
                                            {{ transaction?.transactionId }}
                                        </div>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div>
                                <table class="table-details">
                                    <tbody>
                                        <tr>
                                            <th>{{ 'Status' | translate }}</th>
                                            <td>
                                              <span *ngIf="transaction?.status.name">{{ transaction?.status.name }}</span>
                                            </td>
                                          </tr>
                                        <tr>
                                            <th>{{ 'Sending amount' | translate }}</th>
                                            <td>
                                              <span *ngIf="transaction?.paidAmount">{{ transaction?.currency }} {{ transaction?.paidAmount | number: '1.2-2' }}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{{ 'Receiving amount' | translate }}</th>
                                            <td>
                                                <span *ngIf="transaction?.receivingAmount">{{ transaction?.receivingCurrency }} {{ transaction?.receivingAmount | number: '1.2-2' }}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{{ 'Status' | translate }}</th>
                                            <td>
                                              <span *ngIf="transaction?.status?.name">{{ transaction?.status?.name }}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{{ 'Recipient' | translate }}</th>
                                            <td>
                                              <span *ngIf="transaction?.recipientName">{{ transaction?.recipientName }}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{{ 'Inbound' | translate }}</th>
                                            <td>
                                              <span>{{ (transaction?.paymentMethodName == null ? 'Not defined' : transaction?.paymentMethodName) | translate }}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{{ 'Outbound' | translate }}</th>
                                            <td>
                                                <span *ngIf="transaction?.paymentNetworkName">{{ transaction?.paymentNetworkName }}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <mat-action-row>
                                  <button mat-raised-button color="primary"
                                      [routerLink]="['/', 'admin', 'users', transaction?.userId, 'transactions', transaction?.id]">View
                                      transaction</button>
                              </mat-action-row>
                        </mat-expansion-panel>

                    </div>
                </mat-accordion>
            <!-- <pre>{{item|json}}</pre> -->
        </div>
    </div>