export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  CustomDateGraphType: any;
  Date: any;
  DateTime: any;
  Decimal: any;
  Long: any;
};

export enum AccountAction {
  DeleteAccount = 'DELETE_ACCOUNT'
}

export type ActionStatusType = {
  __typename?: 'ActionStatusType';
  enabled?: Maybe<Scalars['Boolean']>;
  infoText?: Maybe<Scalars['String']>;
};

export type AddressType = {
  __typename?: 'AddressType';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  streetType?: Maybe<Scalars['String']>;
  territory?: Maybe<Scalars['String']>;
  unitNumber?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type AdminEndUserType = {
  __typename?: 'AdminEndUserType';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  authMethod?: Maybe<AuthMethod>;
  authToken?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['CustomDateGraphType']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<CountryType>;
  countryOfBirthId?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['DateTime']>;
  customProperties?: Maybe<Array<Maybe<CustomPropertyType>>>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  filesCount?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isPEP?: Maybe<Scalars['Boolean']>;
  isPepHit?: Maybe<Scalars['Boolean']>;
  isWatchlistHit?: Maybe<Scalars['Boolean']>;
  kycData?: Maybe<KycDataType>;
  /** User's current language */
  language?: Maybe<LanguageType>;
  lastName?: Maybe<Scalars['String']>;
  lastSuccessfulLogin?: Maybe<Scalars['DateTime']>;
  middleName?: Maybe<Scalars['String']>;
  moneyReceived?: Maybe<Scalars['Decimal']>;
  moneySent?: Maybe<Scalars['Decimal']>;
  nationalityCountryId?: Maybe<Scalars['ID']>;
  permissions?: Maybe<Array<Maybe<InterfacePermissions>>>;
  phone?: Maybe<Scalars['String']>;
  phoneVerificationEnabled?: Maybe<Scalars['Boolean']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  /** If resolved not set all (resolved and not resolved) raised flags will be returned.If level is not set, both raised flags for user and user's transactions will be returned */
  raisedFlags?: Maybe<Array<Maybe<RaisedFlagType>>>;
  recipientsCount?: Maybe<Scalars['Int']>;
  riskRating?: Maybe<RiskRating>;
  roles?: Maybe<Array<Maybe<AdminKindEnumType>>>;
  socialNumber?: Maybe<Scalars['String']>;
  socialNumberType?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  streetType?: Maybe<Scalars['String']>;
  territory?: Maybe<Scalars['String']>;
  /** If resolved not set all (resolved and not resolved) user's tickets will be returned. */
  tickets?: Maybe<Array<Maybe<TicketType>>>;
  transactionsCount?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  unitNumber?: Maybe<Scalars['String']>;
  unseenMessagesCount?: Maybe<Scalars['Int']>;
  userName?: Maybe<Scalars['String']>;
  userRef?: Maybe<Scalars['String']>;
  userStatusInfo?: Maybe<UserStatusInfoType>;
  zip?: Maybe<Scalars['String']>;
};


export type AdminEndUserTypePermissionsArgs = {
  action?: InputMaybe<Scalars['String']>;
};


export type AdminEndUserTypeRaisedFlagsArgs = {
  hasNoTickets?: InputMaybe<Scalars['Boolean']>;
  level?: InputMaybe<FlagLevel>;
  resolved?: InputMaybe<Scalars['Boolean']>;
};


export type AdminEndUserTypeTicketsArgs = {
  level?: InputMaybe<FlagLevel>;
  resolved?: InputMaybe<Scalars['Boolean']>;
};

export type AdminInputType = {
  accessLevel?: InputMaybe<Array<InputMaybe<AssignableRoleNames>>>;
  assignedNetworkIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  assignedServiceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  assignedToCountryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  countryId?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  userName?: InputMaybe<Scalars['String']>;
};

export enum AdminKindEnumType {
  /** Admin */
  Admin = 'Admin',
  /** Account Manager */
  Manager = 'Manager',
  /** Payout Agent */
  PayoutAgent = 'PayoutAgent',
  /** Payout Partner */
  PayoutPartner = 'PayoutPartner',
  /** User */
  User = 'User'
}

export type AdminTransactionType = {
  __typename?: 'AdminTransactionType';
  amount?: Maybe<Scalars['Decimal']>;
  canUpdateRecipientInfo?: Maybe<Scalars['Boolean']>;
  checkCurrency?: Maybe<Scalars['String']>;
  checkQuote?: Maybe<Scalars['Decimal']>;
  confirmedAmount?: Maybe<Scalars['Decimal']>;
  created?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Scalars['String']>;
  expectedPayoutTime?: Maybe<Scalars['DateTime']>;
  fixedFee?: Maybe<Scalars['Float']>;
  fixedFeePaid?: Maybe<Scalars['Decimal']>;
  from?: Maybe<CountryType>;
  fromId?: Maybe<Scalars['ID']>;
  fromIsoCode2?: Maybe<Scalars['String']>;
  fromIsoCode3?: Maybe<Scalars['String']>;
  fromName?: Maybe<Scalars['String']>;
  fxSpread?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  inbound?: Maybe<Scalars['String']>;
  inboundName?: Maybe<Scalars['String']>;
  inboundRefNo?: Maybe<Scalars['String']>;
  inboundStatus?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Maybe<TransactionNoteType>>>;
  /** @deprecated Legacy code. */
  outboundRefNo?: Maybe<Scalars['String']>;
  outboundSettlementAmount?: Maybe<Scalars['Decimal']>;
  outboundSettlementCurrency?: Maybe<Scalars['String']>;
  outboundSettlementQuote?: Maybe<OutboundSettlementType>;
  outboundStatus?: Maybe<Scalars['String']>;
  paidAmount?: Maybe<Scalars['Decimal']>;
  paymentMethodName?: Maybe<Scalars['String']>;
  paymentMethodNumber?: Maybe<Scalars['String']>;
  paymentNetwork?: Maybe<PaymentNetworkType>;
  paymentNetworkCode?: Maybe<Scalars['String']>;
  paymentNetworkId?: Maybe<Scalars['ID']>;
  paymentNetworkName?: Maybe<Scalars['String']>;
  paymentService?: Maybe<PaymentServiceType>;
  paymentServiceCode?: Maybe<Scalars['String']>;
  paymentServiceId?: Maybe<Scalars['ID']>;
  paymentServiceName?: Maybe<Scalars['String']>;
  payoutAgentName?: Maybe<Scalars['String']>;
  pickupCode?: Maybe<Scalars['String']>;
  purpose?: Maybe<PurposeType>;
  purposeId?: Maybe<Scalars['ID']>;
  purposeName?: Maybe<Scalars['String']>;
  quote?: Maybe<Scalars['Decimal']>;
  /** If resolved not set all (resolved and not resolved) raised flags will be returned.If level is not set, raised flags for transaction and transaction's sender will be returned. */
  raisedFlags?: Maybe<Array<Maybe<RaisedFlagType>>>;
  receipt?: Maybe<FileType>;
  receivingAmount?: Maybe<Scalars['Decimal']>;
  receivingCurrency?: Maybe<Scalars['String']>;
  receivingQuote?: Maybe<Scalars['Decimal']>;
  recipient?: Maybe<Recipient>;
  recipientAddress1?: Maybe<Scalars['String']>;
  recipientAddress2?: Maybe<Scalars['String']>;
  recipientCity?: Maybe<Scalars['String']>;
  recipientCountry?: Maybe<Scalars['String']>;
  recipientDateOfBirth?: Maybe<Scalars['Date']>;
  recipientEmail?: Maybe<Scalars['String']>;
  recipientFirstName?: Maybe<Scalars['String']>;
  recipientId?: Maybe<Scalars['ID']>;
  recipientLastName?: Maybe<Scalars['String']>;
  recipientMiddleName?: Maybe<Scalars['String']>;
  recipientName?: Maybe<Scalars['String']>;
  recipientPhone?: Maybe<Scalars['String']>;
  relationship?: Maybe<RelationshipType>;
  relationshipId?: Maybe<Scalars['ID']>;
  relationshipName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  sDoc?: Maybe<DocumentType>;
  sDocExp?: Maybe<Scalars['CustomDateGraphType']>;
  sDocId?: Maybe<Scalars['ID']>;
  sDocIssuer?: Maybe<Scalars['String']>;
  sDocNumber?: Maybe<Scalars['String']>;
  sDocSource?: Maybe<Scalars['String']>;
  sDocType?: Maybe<Scalars['String']>;
  sDocTypeName?: Maybe<Scalars['String']>;
  sender?: Maybe<EndUserType>;
  settlementData?: Maybe<SettlmentDataType>;
  status?: Maybe<TransactionStatusType>;
  to?: Maybe<CountryType>;
  toId?: Maybe<Scalars['ID']>;
  toIsoCode2?: Maybe<Scalars['String']>;
  toIsoCode3?: Maybe<Scalars['String']>;
  toName?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactionStatusInfo?: Maybe<TransactionStatusInfoType>;
  updateRecipientInfoText?: Maybe<Array<Maybe<Scalars['String']>>>;
  userAddressLine1?: Maybe<Scalars['String']>;
  userAddressLine2?: Maybe<Scalars['String']>;
  userBirthDate?: Maybe<Scalars['DateTime']>;
  userCity?: Maybe<Scalars['String']>;
  userCountry?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  userFirstName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  userLastName?: Maybe<Scalars['String']>;
  userMiddleName?: Maybe<Scalars['String']>;
  userPhone?: Maybe<Scalars['String']>;
  userSocialNumber?: Maybe<Scalars['String']>;
  userTerritory?: Maybe<Scalars['String']>;
  userUnitNumber?: Maybe<Scalars['String']>;
  userUserName?: Maybe<Scalars['String']>;
  userZip?: Maybe<Scalars['String']>;
  variableFee?: Maybe<Scalars['Float']>;
  variableFeePaid?: Maybe<Scalars['Decimal']>;
};


export type AdminTransactionTypeRaisedFlagsArgs = {
  level?: InputMaybe<FlagLevel>;
  resolved?: InputMaybe<Scalars['Boolean']>;
};

export type AdminUserType = {
  __typename?: 'AdminUserType';
  accessLevel?: Maybe<Array<Maybe<AssignableRoleNames>>>;
  assignedNetworkIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  assignedServiceIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  assignedToCountryIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  authToken?: Maybe<Scalars['String']>;
  country?: Maybe<CountryType>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  /** Id of the UserProfile */
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** User's current language */
  language?: Maybe<LanguageType>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<InterfacePermissions>>>;
  phone?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<AdminKindEnumType>>>;
  userName?: Maybe<Scalars['String']>;
};

export enum AssignableRoleNames {
  /** Manager */
  Manager = 'Manager',
  /** Payout Agent */
  PayoutAgent = 'PayoutAgent',
  /** Payout Partner */
  PayoutPartner = 'PayoutPartner'
}

export enum AuthMethod {
  Basic = 'BASIC',
  Diglias = 'DIGLIAS'
}

export type CalcResultType = {
  __typename?: 'CalcResultType';
  expectedPayoutTime?: Maybe<Scalars['DateTime']>;
  fee?: Maybe<Scalars['Decimal']>;
  quote?: Maybe<Scalars['Decimal']>;
  quoteId?: Maybe<Scalars['ID']>;
  recipientReceives?: Maybe<Scalars['Decimal']>;
  sendAmount?: Maybe<Scalars['Decimal']>;
  totalToPay?: Maybe<Scalars['Decimal']>;
};

export type CalculatorDefaultType = {
  __typename?: 'CalculatorDefaultType';
  fromCountryId: Scalars['ID'];
  paymentNetworkId?: Maybe<Scalars['ID']>;
  paymentServiceId: Scalars['ID'];
  receivingAmount?: Maybe<Scalars['Decimal']>;
  sendingAmount?: Maybe<Scalars['Decimal']>;
  toCountryId: Scalars['ID'];
};

export type ConsentFilters = {
  userId?: InputMaybe<Scalars['ID']>;
};

export type ConsentGraphType = {
  __typename?: 'ConsentGraphType';
  consentType?: Maybe<ConsentTypeEnumeration>;
  created?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  listOfConsents?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export enum ConsentTypeEnumeration {
  Kyc = 'KYC',
  Registration = 'REGISTRATION',
  Transaction = 'TRANSACTION'
}

export type CorridorGroupType = {
  __typename?: 'CorridorGroupType';
  from?: Maybe<CountryType>;
  toCountries?: Maybe<Array<Maybe<CountryServicesType>>>;
};

export type CorridorInputType = {
  checkCurrency?: InputMaybe<Scalars['String']>;
  exchangeCorrectionRate?: InputMaybe<Scalars['Decimal']>;
  fees?: InputMaybe<Array<InputMaybe<FeeInputType>>>;
  fixedFee: Scalars['Decimal'];
  fromId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  maxAmount: Scalars['Decimal'];
  minAmount: Scalars['Decimal'];
  paymentNetworkId: Scalars['ID'];
  paymentServiceId: Scalars['ID'];
  receivingCurrency: Scalars['String'];
  toId: Scalars['ID'];
  variableFee: Scalars['Decimal'];
};

export type CorridorLimits = {
  __typename?: 'CorridorLimits';
  currency?: Maybe<Scalars['String']>;
  /** @deprecated Not applicable for public queries. */
  fixedFee?: Maybe<Scalars['Float']>;
  locations?: Maybe<Array<Maybe<Location>>>;
  /** @deprecated Not applicable for public queries. */
  maxAmount?: Maybe<Scalars['Float']>;
  /** @deprecated Not applicable for public queries. */
  minAmount?: Maybe<Scalars['Float']>;
  paymentNetwork?: Maybe<PaymentNetworkType>;
  /** @deprecated Not applicable for public queries. */
  variableFee?: Maybe<Scalars['Float']>;
};

export type CorridorType = {
  __typename?: 'CorridorType';
  checkCurrency?: Maybe<Scalars['String']>;
  exchangeCorrectionRate?: Maybe<Scalars['Decimal']>;
  fees?: Maybe<Array<Maybe<FeeType>>>;
  fixedFee?: Maybe<Scalars['Decimal']>;
  fixedFees?: Maybe<Array<Maybe<FeeType>>>;
  from?: Maybe<CountryType>;
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  maxAmount?: Maybe<Scalars['Decimal']>;
  minAmount?: Maybe<Scalars['Decimal']>;
  network?: Maybe<PaymentNetworkType>;
  outboundFees?: Maybe<Array<Maybe<FeeType>>>;
  receivingCurrency?: Maybe<Scalars['String']>;
  service?: Maybe<PaymentServiceType>;
  to?: Maybe<CountryType>;
  variableFee?: Maybe<Scalars['Decimal']>;
  variableFees?: Maybe<Array<Maybe<FeeType>>>;
};

export type CorridorsFilters = {
  active?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  fromIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  paymentNetworkIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  paymentServiceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  toIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type CountriesFilters = {
  active?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  isRecipient?: InputMaybe<Scalars['Boolean']>;
  isSender?: InputMaybe<Scalars['Boolean']>;
  query?: InputMaybe<Scalars['String']>;
};

export type CountryInputType = {
  currency: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  isRecipient: Scalars['Boolean'];
  isSender: Scalars['Boolean'];
  isoCode2: Scalars['String'];
  isoCode3: Scalars['String'];
  maxAmount?: InputMaybe<Scalars['Decimal']>;
  name: Scalars['String'];
  phonePrefix: Scalars['Int'];
  regionId: Scalars['ID'];
};

export type CountryServicesType = {
  __typename?: 'CountryServicesType';
  country?: Maybe<CountryType>;
  paymentServices?: Maybe<Array<Maybe<ServiceNetworkType>>>;
};

export type CountryType = {
  __typename?: 'CountryType';
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** Replaced with isRecipient */
  isReceiver?: Maybe<Scalars['Boolean']>;
  isRecipient?: Maybe<Scalars['Boolean']>;
  isSender?: Maybe<Scalars['Boolean']>;
  isoCode2?: Maybe<Scalars['String']>;
  isoCode3?: Maybe<Scalars['String']>;
  maxAmount?: Maybe<Scalars['Decimal']>;
  name?: Maybe<Scalars['String']>;
  phonePrefix?: Maybe<Scalars['Int']>;
  region?: Maybe<RegionType>;
};

export type CurrencyInputType = {
  fxSpread: Scalars['Float'];
  id?: InputMaybe<Scalars['ID']>;
  isoCode: Scalars['String'];
};

export type CurrencyType = {
  __typename?: 'CurrencyType';
  fxSpread?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  isoCode?: Maybe<Scalars['String']>;
};

export type CustomPropertyInputType = {
  key: Scalars['String'];
  tag?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type CustomPropertyType = {
  __typename?: 'CustomPropertyType';
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type DeleteAccountResponseType = {
  __typename?: 'DeleteAccountResponseType';
  canAbandon?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  infoMessage?: Maybe<Scalars['String']>;
  status?: Maybe<ResponseStatus>;
  steps?: Maybe<Array<Maybe<DeleteAccountStepType>>>;
};

export enum DeleteAccountStepStatus {
  NotStarted = 'NOT_STARTED',
  Pending = 'PENDING',
  Resolved = 'RESOLVED'
}

export type DeleteAccountStepType = {
  __typename?: 'DeleteAccountStepType';
  description?: Maybe<Scalars['String']>;
  executed?: Maybe<Scalars['DateTime']>;
  infoMessage?: Maybe<Scalars['String']>;
  resolved?: Maybe<Scalars['DateTime']>;
  status?: Maybe<DeleteAccountStepStatus>;
  statusMessage?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type DocumentCategoryType = {
  __typename?: 'DocumentCategoryType';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type DocumentInputType = {
  categoryId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  documentExp?: InputMaybe<Scalars['CustomDateGraphType']>;
  documentIssuer?: InputMaybe<Scalars['String']>;
  documentNote?: InputMaybe<Scalars['String']>;
  documentNumber?: InputMaybe<Scalars['String']>;
  documentState?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type DocumentType = {
  __typename?: 'DocumentType';
  cardNumber?: Maybe<Scalars['String']>;
  category?: Maybe<DocumentCategoryType>;
  created?: Maybe<Scalars['CustomDateGraphType']>;
  createdByName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  documentExp?: Maybe<Scalars['CustomDateGraphType']>;
  documentIssuer?: Maybe<Scalars['String']>;
  documentNote?: Maybe<Scalars['String']>;
  documentNumber?: Maybe<Scalars['String']>;
  documentSource?: Maybe<Scalars['String']>;
  documentState?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<FileType>>>;
  id?: Maybe<Scalars['ID']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
};

export type DocumentsFilters = {
  notExpired?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type DriverLicenceType = {
  __typename?: 'DriverLicenceType';
  dateOfExpiry?: Maybe<Scalars['CustomDateGraphType']>;
  documentBackId?: Maybe<Scalars['ID']>;
  documentFrontId?: Maybe<Scalars['ID']>;
  number?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export enum EmailTemplateEnumType {
  /** Payment instructions email */
  PaymentInstructions = 'PAYMENT_INSTRUCTIONS'
}

export type EndUserProfileType = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  birthDate?: InputMaybe<Scalars['CustomDateGraphType']>;
  city?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['ID']>;
  countryOfBirthId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  isPEP?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  nationalityCountryId?: InputMaybe<Scalars['ID']>;
  phone?: InputMaybe<Scalars['String']>;
  socialNumber?: InputMaybe<Scalars['String']>;
  socialNumberType?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  territory?: InputMaybe<Scalars['String']>;
  unitNumber?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type EndUserRegisterType = {
  additionalConsent?: InputMaybe<Scalars['Boolean']>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  authMethod?: InputMaybe<AuthMethod>;
  birthDate?: InputMaybe<Scalars['CustomDateGraphType']>;
  city?: InputMaybe<Scalars['String']>;
  countryId: Scalars['ID'];
  countryOfBirthId?: InputMaybe<Scalars['ID']>;
  customProperties?: InputMaybe<Array<InputMaybe<CustomPropertyInputType>>>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  iAgreeAccepted?: InputMaybe<Scalars['Boolean']>;
  isPEP?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  nationalityCountryId?: InputMaybe<Scalars['ID']>;
  password?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  socialNumber?: InputMaybe<Scalars['String']>;
  territory?: InputMaybe<Scalars['String']>;
  unitNumber?: InputMaybe<Scalars['String']>;
  verifyBy?: InputMaybe<VerificationType>;
  zip?: InputMaybe<Scalars['String']>;
};

export type EndUserType = {
  __typename?: 'EndUserType';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  authMethod?: Maybe<AuthMethod>;
  authToken?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['CustomDateGraphType']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<CountryType>;
  countryOfBirthId?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['DateTime']>;
  customProperties?: Maybe<Array<Maybe<CustomPropertyType>>>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  filesCount?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isPEP?: Maybe<Scalars['Boolean']>;
  /** User's current language */
  language?: Maybe<LanguageType>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  moneyReceived?: Maybe<Scalars['Decimal']>;
  moneySent?: Maybe<Scalars['Decimal']>;
  nationalityCountryId?: Maybe<Scalars['ID']>;
  permissions?: Maybe<Array<Maybe<InterfacePermissions>>>;
  phone?: Maybe<Scalars['String']>;
  phoneVerificationEnabled?: Maybe<Scalars['Boolean']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  recipientsCount?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<Maybe<AdminKindEnumType>>>;
  socialNumber?: Maybe<Scalars['String']>;
  socialNumberType?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  streetType?: Maybe<Scalars['String']>;
  territory?: Maybe<Scalars['String']>;
  transactionsCount?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  unitNumber?: Maybe<Scalars['String']>;
  unseenMessagesCount?: Maybe<Scalars['Int']>;
  userName?: Maybe<Scalars['String']>;
  userRef?: Maybe<Scalars['String']>;
  userStatusInfo?: Maybe<UserStatusInfoType>;
  zip?: Maybe<Scalars['String']>;
};


export type EndUserTypePermissionsArgs = {
  action?: InputMaybe<Scalars['String']>;
};

export type FeeInputType = {
  corridorId?: InputMaybe<Scalars['ID']>;
  feeAmount: Scalars['Decimal'];
  feeTypeCode: FeeTypeCode;
  feeValueType?: InputMaybe<FeeValueType>;
  toAmount: Scalars['Decimal'];
};

export type FeeType = {
  __typename?: 'FeeType';
  feeAmount?: Maybe<Scalars['Decimal']>;
  feeTypeCode?: Maybe<FeeTypeCode>;
  feeValueType?: Maybe<FeeValueType>;
  id?: Maybe<Scalars['ID']>;
  toAmount?: Maybe<Scalars['Decimal']>;
};

export enum FeeTypeCode {
  FixedFee = 'FIXED_FEE',
  OutboundFee = 'OUTBOUND_FEE',
  VariableFee = 'VARIABLE_FEE'
}

export enum FeeValueType {
  Percent = 'PERCENT',
  Value = 'VALUE'
}

export type FieldConfigType = {
  __typename?: 'FieldConfigType';
  /** Specifies the descriptive text associated with an input element. */
  description?: Maybe<Scalars['String']>;
  /** Specifies whether the field is editable or read-only. */
  editable?: Maybe<Scalars['Boolean']>;
  /** Specifies the descriptive text associated with an input element */
  label?: Maybe<Scalars['String']>;
  /**
   * - `type:numbers`: Specifies the maximum allowable numerical value.
   * - `type:date`: Specifies the maximum number of years e.g. 10 for document expiry date would be `currentDate` + 10.
   */
  max?: Maybe<Scalars['Int']>;
  /** - `type:text`: Specifies the max length of the value. */
  maxLength?: Maybe<Scalars['Int']>;
  /**
   * - `type:numbers`: Specifies the minimum allowable numerical value.
   * - `type:date`: Specifies the minimum number of years e.g. 18 for registration would be `currentDate` - 18.
   */
  min?: Maybe<Scalars['Int']>;
  /** - `type:text`: Specifies the min length of the value. */
  minLength?: Maybe<Scalars['Int']>;
  /** Used to define the `name` of an input field within a form. This attribute is crucial when submitting the form data to the backend server. The `name` attribute is paired with the value attribute to create key-value pairs, where the `name` represents the variable name, and the `value` is the data entered by the user. */
  name: Scalars['String'];
  /** Defines order of element in the form rendering. */
  orderNumber?: Maybe<Scalars['Int']>;
  /**
   * - `type:any`: Specifies the regex validation for the value.
   * - `type:file-upload`: List of allowed file types (.jpg,.jpeg,.gif)
   */
  pattern?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<Maybe<PropertyType>>>;
  /** Specifies whether the field is required or optional. */
  required?: Maybe<Scalars['Boolean']>;
  /** Utilized for visually organizing fields, a section can be employed to represent multiple fields with the same value. In cases where several fields share a common attribute within a section, the initial occurrence of the section can serve as the title. For instance, in a recipients form, a section might be applied to distinguish a basic set of fields from the bank information. */
  section?: Maybe<Scalars['String']>;
  /**
   * `text` | `numbers` | `password` | `address-autocomplete` | `email` | `select` | `date` | `multiselect` | `checkbox` | `radio` | `file-upload` | `relationship-select` | `country-select`
   * - `relationship-select` shows a list of relationships from the relationships query;
   * - `country-select` should always show the sender countries, except on the recipient where it should show recipient countries;
   *
   */
  type?: Maybe<Scalars['String']>;
  /**
   * - `type:any`: Specifies default value for the field.
   * - `type:file-upload`: value must be `file.id`.
   * - `type:relationship-select`: value must be `relationship.id`.
   */
  value?: Maybe<Scalars['String']>;
  /**
   * List of predefined values that user can select.
   * - `type:select`
   * - `type:multiselect`
   * - `type:radio`.
   */
  values?: Maybe<Array<Maybe<LabelValueType>>>;
  /** Specifies whether the field is visible or not. */
  visible?: Maybe<Scalars['Boolean']>;
};

export type FieldSetType = {
  __typename?: 'FieldSetType';
  description?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<Maybe<FieldConfigType>>>;
  group?: Maybe<Scalars['String']>;
  /**
   *
   *  - `AllRequired` - all sections in the group must be filled and valid.
   *  - `OneRequired` - one section from the group must be filled and valid.
   *  - `NoneRequired` - all sections in the group are optional.
   */
  groupPolicy?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type FieldsConfigType = {
  __typename?: 'FieldsConfigType';
  /** countryIsoCode2 */
  country?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<Maybe<FieldConfigType>>>;
};

export type FileInputType = {
  categoryId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  documentId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type FileType = {
  __typename?: 'FileType';
  /** @deprecated Legacy code. */
  blobName?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['CustomDateGraphType']>;
  description?: Maybe<Scalars['String']>;
  document?: Maybe<DocumentType>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
};

export type FilesFilters = {
  documentId?: InputMaybe<Scalars['ID']>;
  noDocument?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export enum FlagCode {
  AbvLmt = 'ABV_LMT',
  AbvMntLmt = 'ABV_MNT_LMT',
  AccountReview = 'ACCOUNT_REVIEW',
  DeleteAccountRequested = 'DELETE_ACCOUNT_REQUESTED',
  Edd = 'EDD',
  EmailNotVerified = 'EMAIL_NOT_VERIFIED',
  HiRisk = 'HI_RISK',
  KycMissing = 'KYC_MISSING',
  KycPep = 'KYC_PEP',
  OnHold = 'ON_HOLD',
  OutboundError = 'OUTBOUND_ERROR',
  PaymentFailedAfterSuccess = 'PAYMENT_FAILED_AFTER_SUCCESS',
  PaymentSuccessAfterCancel = 'PAYMENT_SUCCESS_AFTER_CANCEL',
  PaymentWrongAmount = 'PAYMENT_WRONG_AMOUNT',
  Pep = 'PEP',
  PhoneNotVerified = 'PHONE_NOT_VERIFIED',
  QuestionnaireMissing = 'QUESTIONNAIRE_MISSING',
  RecieverAmlCheck = 'RECIEVER_AML_CHECK',
  RecAbvMntLmt = 'REC_ABV_MNT_LMT',
  RegisterPaymentMethod = 'REGISTER_PAYMENT_METHOD',
  RiskAssessment = 'RISK_ASSESSMENT',
  VulnerablePerson = 'VULNERABLE_PERSON',
  WatchlistHit = 'WATCHLIST_HIT'
}

export enum FlagLevel {
  Transaction = 'TRANSACTION',
  User = 'USER'
}

export type FlagType = {
  __typename?: 'FlagType';
  code?: Maybe<FlagCode>;
  id?: Maybe<Scalars['ID']>;
  isWarning?: Maybe<Scalars['Boolean']>;
  level?: Maybe<FlagLevel>;
  name?: Maybe<Scalars['String']>;
  presentedCode?: Maybe<Scalars['String']>;
  presentedColor?: Maybe<Scalars['String']>;
};

export type FrontendConfigurationType = {
  __typename?: 'FrontendConfigurationType';
  calculatorDefault?: Maybe<CalculatorDefaultType>;
  dateFormatLong?: Maybe<Scalars['String']>;
  dateFormatShort?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  inbounds?: Maybe<Array<Maybe<InboundServiceType>>>;
  inputDateFormat?: Maybe<Scalars['String']>;
  mobileDateOnlyFormatMedium?: Maybe<Scalars['String']>;
  mobileDateTimeFormatMedium?: Maybe<Scalars['String']>;
  mobileInputDateFormat?: Maybe<Scalars['String']>;
};


export type FrontendConfigurationTypeInboundsArgs = {
  pageRequest?: InputMaybe<PageRequestInboundsFilterType>;
};

export enum InboundAccountEnumType {
  Bank = 'BANK'
}

export enum InboundAccountSubType {
  Bsb = 'BSB',
  Checking = 'CHECKING',
  PayId = 'PAY_ID',
  Savings = 'SAVINGS',
  Unknown = 'UNKNOWN'
}

export type InboundAccountType = {
  __typename?: 'InboundAccountType';
  accountNumber?: Maybe<Scalars['String']>;
  accountSubType?: Maybe<InboundAccountSubType>;
  accountType?: Maybe<InboundAccountEnumType>;
  bsb?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  fingerprint?: Maybe<Scalars['String']>;
  holderName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inboundCode?: Maybe<Scalars['String']>;
  inboundName?: Maybe<Scalars['String']>;
  lastFourNumbers?: Maybe<Scalars['String']>;
  maxAmount?: Maybe<Scalars['Decimal']>;
  name?: Maybe<Scalars['String']>;
  payID?: Maybe<Scalars['String']>;
  payIDType?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  status?: Maybe<PaymentMethodStatus>;
};

export type InboundAuthTokenType = {
  __typename?: 'InboundAuthTokenType';
  accessToken?: Maybe<Scalars['String']>;
  expiresIn?: Maybe<Scalars['Int']>;
  isKYCMissing?: Maybe<Scalars['Boolean']>;
  isSandbox?: Maybe<Scalars['Boolean']>;
  redirectUrl?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  tokenType?: Maybe<Scalars['String']>;
  userAccountId?: Maybe<Scalars['String']>;
};

export type InboundPaymentMethodType = {
  __typename?: 'InboundPaymentMethodType';
  account?: Maybe<InboundAccountType>;
  id?: Maybe<Scalars['String']>;
  paymentMethodType?: Maybe<Scalars['String']>;
};

export type InboundResponseType = {
  __typename?: 'InboundResponseType';
  errorCode?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  inbound?: Maybe<Scalars['String']>;
  inboundName?: Maybe<Scalars['String']>;
  inboundRefNo?: Maybe<Scalars['String']>;
  navigateURL?: Maybe<Scalars['String']>;
  newTab?: Maybe<Scalars['Boolean']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type InboundServiceType = {
  __typename?: 'InboundServiceType';
  addNew?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  maxAmount?: Maybe<Scalars['Decimal']>;
  paymentMethods?: Maybe<Array<Maybe<InboundPaymentMethodType>>>;
};

export type InboundsFilter = {
  usesPaymentMethods?: InputMaybe<Scalars['Boolean']>;
};

export enum InterfacePermissions {
  CompanyManage = 'COMPANY_MANAGE',
  ConsentCreate = 'CONSENT_CREATE',
  ConsentView = 'CONSENT_VIEW',
  CorridorsManage = 'CORRIDORS_MANAGE',
  CorridorsRead = 'CORRIDORS_READ',
  CountriesManage = 'COUNTRIES_MANAGE',
  CountriesRead = 'COUNTRIES_READ',
  DeleteAccount = 'DELETE_ACCOUNT',
  DocumentsManage = 'DOCUMENTS_MANAGE',
  DocumentsRead = 'DOCUMENTS_READ',
  EndUser = 'END_USER',
  FilesManage = 'FILES_MANAGE',
  FilesRead = 'FILES_READ',
  FlagsManage = 'FLAGS_MANAGE',
  FlagsRead = 'FLAGS_READ',
  ManagersManage = 'MANAGERS_MANAGE',
  ManagersRead = 'MANAGERS_READ',
  ManagerRestriction = 'MANAGER_RESTRICTION',
  Messaging = 'MESSAGING',
  PaymentMethodsManage = 'PAYMENT_METHODS_MANAGE',
  RecipientsRead = 'RECIPIENTS_READ',
  RegionsManage = 'REGIONS_MANAGE',
  RegionsRead = 'REGIONS_READ',
  RiskAssementManage = 'RISK_ASSEMENT_MANAGE',
  TicketsManage = 'TICKETS_MANAGE',
  TicketsRead = 'TICKETS_READ',
  TransactionsCreate = 'TRANSACTIONS_CREATE',
  TransactionsHistoryRead = 'TRANSACTIONS_HISTORY_READ',
  TransactionsManage = 'TRANSACTIONS_MANAGE',
  TransactionsPayoutsManage = 'TRANSACTIONS_PAYOUTS_MANAGE',
  TransactionsPayoutsRead = 'TRANSACTIONS_PAYOUTS_READ',
  TransactionsRead = 'TRANSACTIONS_READ',
  TransactionsReport = 'TRANSACTIONS_REPORT',
  TransactionOutboundStatusManage = 'TRANSACTION_OUTBOUND_STATUS_MANAGE',
  TransactionUpdateDocument = 'TRANSACTION_UPDATE_DOCUMENT',
  TransactionUpdateRecipient = 'TRANSACTION_UPDATE_RECIPIENT',
  TransactionUpdateStatus = 'TRANSACTION_UPDATE_STATUS',
  TransactionViewSettlement = 'TRANSACTION_VIEW_SETTLEMENT',
  UsersManage = 'USERS_MANAGE',
  UsersRead = 'USERS_READ',
  VerificationLogsRead = 'VERIFICATION_LOGS_READ'
}

export type KycDataType = {
  __typename?: 'KYCDataType';
  kycAttempts?: Maybe<Scalars['Int']>;
  kycInProgress?: Maybe<Scalars['Boolean']>;
};

export type KycLogDetailsType = {
  __typename?: 'KYCLogDetailsType';
  endpoint?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  request?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type KycLogType = {
  __typename?: 'KYCLogType';
  created?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  request?: Maybe<KycRequestType>;
  requests?: Maybe<Array<Maybe<KycLogDetailsType>>>;
  response?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  user?: Maybe<EndUserType>;
};

export type KycLogsFilters = {
  success?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type KycRequestType = {
  __typename?: 'KYCRequestType';
  address?: Maybe<AddressType>;
  driverLicence?: Maybe<DriverLicenceType>;
  medicare?: Maybe<MedicareType>;
  passport?: Maybe<PassportType>;
  personDetails?: Maybe<PersonType>;
};

export type LabelValueType = {
  __typename?: 'LabelValueType';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type LanguageInputType = {
  id?: InputMaybe<Scalars['Int']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isoCode2: Scalars['String'];
  isoCode3?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type LanguageType = {
  __typename?: 'LanguageType';
  id: Scalars['Int'];
  isDefault: Scalars['Boolean'];
  isoCode2: Scalars['String'];
  isoCode3?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ManagersFilters = {
  active?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  query?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type MedicareType = {
  __typename?: 'MedicareType';
  color?: Maybe<Scalars['String']>;
  dateOfExpiry?: Maybe<Scalars['CustomDateGraphType']>;
  documentBackId?: Maybe<Scalars['ID']>;
  documentFrontId?: Maybe<Scalars['ID']>;
  number?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type MessageInputType = {
  fileId?: InputMaybe<Scalars['ID']>;
  receiverId?: InputMaybe<Scalars['ID']>;
  subject?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  ticketId?: InputMaybe<Scalars['ID']>;
  transactionId?: InputMaybe<Scalars['ID']>;
};

export type MessageType = {
  __typename?: 'MessageType';
  created?: Maybe<Scalars['DateTime']>;
  file?: Maybe<FileType>;
  id?: Maybe<Scalars['ID']>;
  receiver?: Maybe<UserInfoType>;
  seen?: Maybe<Scalars['DateTime']>;
  sender?: Maybe<UserInfoType>;
  subject?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type Mutations = {
  __typename?: 'Mutations';
  employee?: Maybe<EmployeeMutation>;
  flagsAndTickets?: Maybe<FlagsAndTicketsMutations>;
  messaging?: Maybe<MessagingMutations>;
  payoutsManagement?: Maybe<PayoutMutations>;
  setup?: Maybe<SetupMutations>;
  transactionsManagement?: Maybe<TransactionsMutations>;
  user?: Maybe<UserMutations>;
  usersManagement?: Maybe<UsersManagementMutations>;
};

export type NotificationPayloadType = {
  __typename?: 'NotificationPayloadType';
  ticketId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
};

export type NotificationType = {
  __typename?: 'NotificationType';
  action?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  payload?: Maybe<NotificationPayloadType>;
  seen?: Maybe<Scalars['CustomDateGraphType']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type NotificationsFilters = {
  seen?: InputMaybe<Scalars['Boolean']>;
};

export type OnboardingStepType = {
  __typename?: 'OnboardingStepType';
  action?: Maybe<Scalars['String']>;
  actionLabel?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type OnboardingType = {
  __typename?: 'OnboardingType';
  id?: Maybe<Scalars['ID']>;
  steps?: Maybe<Array<Maybe<OnboardingStepType>>>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type OutboundSettlementType = {
  __typename?: 'OutboundSettlementType';
  amount?: Maybe<Scalars['Decimal']>;
  fromCurrency?: Maybe<Scalars['String']>;
  toCurrency?: Maybe<Scalars['String']>;
};

export type PageRequestConsentsFilterType = {
  filters?: InputMaybe<ConsentFilters>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PageRequestCorridorsFilterType = {
  filters?: InputMaybe<CorridorsFilters>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PageRequestCountriesFilterType = {
  filters?: InputMaybe<CountriesFilters>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PageRequestDocumentsFilterType = {
  filters?: InputMaybe<DocumentsFilters>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PageRequestFilesFilterType = {
  filters?: InputMaybe<FilesFilters>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PageRequestInboundsFilterType = {
  filters?: InputMaybe<InboundsFilter>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PageRequestKycLogsFilterType = {
  filters?: InputMaybe<KycLogsFilters>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PageRequestManagersFilterType = {
  filters?: InputMaybe<ManagersFilters>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PageRequestNotificationsFilterType = {
  filters?: InputMaybe<NotificationsFilters>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PageRequestPayoutsFilterType = {
  filters?: InputMaybe<PayoutsFilters>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PageRequestPurposesFilterType = {
  filters?: InputMaybe<PurposesFilters>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PageRequestRaisedFlagsFilterType = {
  filters?: InputMaybe<RaisedFlagsFilters>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PageRequestRecipientsFilterType = {
  filters?: InputMaybe<RecipientsFilters>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PageRequestRegionsFilterType = {
  filters?: InputMaybe<RegionsFilters>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PageRequestRelationshipsFilterType = {
  filters?: InputMaybe<RelationshipsFilters>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PageRequestTicketsFilterType = {
  filters?: InputMaybe<TicketsFilters>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PageRequestTransactionHistoryFilterType = {
  filters?: InputMaybe<TransactionHistoryFilters>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PageRequestTransactionsFilterType = {
  filters?: InputMaybe<TransactionsFilters>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PageRequestTransactionsHistoryFilterType = {
  filters?: InputMaybe<TransactionsHistoryFilters>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PageRequestTranslationsFilterType = {
  filters?: InputMaybe<TranslationsFilters>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PageRequestType = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PageRequestUsersFilterType = {
  filters?: InputMaybe<UsersFilters>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PaginatorAdminEndUserType = {
  __typename?: 'PaginatorAdminEndUserType';
  data?: Maybe<Array<Maybe<AdminEndUserType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorAdminTransactionTypeGraphType = {
  __typename?: 'PaginatorAdminTransactionTypeGraphType';
  data?: Maybe<Array<Maybe<AdminTransactionType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorAdminUserType = {
  __typename?: 'PaginatorAdminUserType';
  data?: Maybe<Array<Maybe<AdminUserType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorConsentGraphType = {
  __typename?: 'PaginatorConsentGraphType';
  data?: Maybe<Array<Maybe<ConsentGraphType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorCorridorType = {
  __typename?: 'PaginatorCorridorType';
  data?: Maybe<Array<Maybe<CorridorType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorCountryType = {
  __typename?: 'PaginatorCountryType';
  data?: Maybe<Array<Maybe<CountryType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorCurrencyType = {
  __typename?: 'PaginatorCurrencyType';
  data?: Maybe<Array<Maybe<CurrencyType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorDocumentType = {
  __typename?: 'PaginatorDocumentType';
  data?: Maybe<Array<Maybe<DocumentType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorFileType = {
  __typename?: 'PaginatorFileType';
  data?: Maybe<Array<Maybe<FileType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorInboundAccountType = {
  __typename?: 'PaginatorInboundAccountType';
  data?: Maybe<Array<Maybe<InboundAccountType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorKycLogType = {
  __typename?: 'PaginatorKYCLogType';
  data?: Maybe<Array<Maybe<KycLogType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorLanguageType = {
  __typename?: 'PaginatorLanguageType';
  data?: Maybe<Array<Maybe<LanguageType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorNotificationType = {
  __typename?: 'PaginatorNotificationType';
  data?: Maybe<Array<Maybe<NotificationType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorPurposeType = {
  __typename?: 'PaginatorPurposeType';
  data?: Maybe<Array<Maybe<PurposeType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorRaisedFlagType = {
  __typename?: 'PaginatorRaisedFlagType';
  data?: Maybe<Array<Maybe<RaisedFlagType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorRecipientType = {
  __typename?: 'PaginatorRecipientType';
  data?: Maybe<Array<Maybe<Recipient>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorRegionType = {
  __typename?: 'PaginatorRegionType';
  data?: Maybe<Array<Maybe<RegionType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorRelationshipType = {
  __typename?: 'PaginatorRelationshipType';
  data?: Maybe<Array<Maybe<RelationshipType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorTicketType = {
  __typename?: 'PaginatorTicketType';
  data?: Maybe<Array<Maybe<TicketType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorTransactionHType = {
  __typename?: 'PaginatorTransactionHType';
  data?: Maybe<Array<Maybe<TransactionChange>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorTransactionType = {
  __typename?: 'PaginatorTransactionType';
  data?: Maybe<Array<Maybe<Transaction>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorTranslationType = {
  __typename?: 'PaginatorTranslationType';
  data?: Maybe<Array<Maybe<TranslationType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PassportType = {
  __typename?: 'PassportType';
  country?: Maybe<Scalars['String']>;
  dateOfExpiry?: Maybe<Scalars['CustomDateGraphType']>;
  dateOfIssue?: Maybe<Scalars['CustomDateGraphType']>;
  documentBackId?: Maybe<Scalars['ID']>;
  documentFrontId?: Maybe<Scalars['ID']>;
  number?: Maybe<Scalars['String']>;
};

export type PaymentMethodInputType = {
  accountNumber?: InputMaybe<Scalars['String']>;
  bsb?: InputMaybe<Scalars['String']>;
  expiryDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  inboundCode?: InputMaybe<Scalars['String']>;
  maxAmount?: InputMaybe<Scalars['Decimal']>;
  name?: InputMaybe<Scalars['String']>;
  payID?: InputMaybe<Scalars['String']>;
  payIDType?: InputMaybe<Scalars['String']>;
};

export enum PaymentMethodStatus {
  Errored = 'ERRORED',
  New = 'NEW',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN',
  VerificationFailed = 'VERIFICATION_FAILED',
  Verified = 'VERIFIED'
}

export type PaymentNetworkType = {
  __typename?: 'PaymentNetworkType';
  code?: Maybe<Scalars['String']>;
  extendedName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PaymentServiceType = {
  __typename?: 'PaymentServiceType';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PayoutsFilters = {
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  pickupCode?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
};

export type PersonType = {
  __typename?: 'PersonType';
  birthDate?: Maybe<Scalars['CustomDateGraphType']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  iagreeCheckBox?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  moovTermsToken?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  socialNumber?: Maybe<Scalars['String']>;
  socialNumberType?: Maybe<Scalars['String']>;
};

export type PropertyType = {
  __typename?: 'PropertyType';
  description?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PurposeInputType = {
  id?: InputMaybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type PurposeType = {
  __typename?: 'PurposeType';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type PurposesFilters = {
  active?: InputMaybe<Scalars['Boolean']>;
};

export type Queries = {
  __typename?: 'Queries';
  flagsAndTickets?: Maybe<FlagsAndTicketsQueries>;
  messaging?: Maybe<MessagingQueries>;
  payoutsManagement?: Maybe<PayoutQueries>;
  public?: Maybe<PublicQueries>;
  setup?: Maybe<SetupQueries>;
  system?: Maybe<SystemQueries>;
  transactionHistory?: Maybe<Array<Maybe<TransactionChange>>>;
  transactionsManagement?: Maybe<TransactionsQueries>;
  user?: Maybe<UserQueries>;
  usersManagement?: Maybe<UsersManagementQueries>;
};


export type QueriesTransactionHistoryArgs = {
  transactionId: Scalars['ID'];
};

export type RaisedFlagType = {
  __typename?: 'RaisedFlagType';
  created?: Maybe<Scalars['DateTime']>;
  createdByName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  flag?: Maybe<FlagType>;
  forUserId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  level?: Maybe<FlagLevel>;
  /** If not set current raised flag is not resolved yet. */
  resolved?: Maybe<Scalars['DateTime']>;
  resolvedBy?: Maybe<UserInfoType>;
  resolvedNote?: Maybe<Scalars['String']>;
  ticket?: Maybe<TicketType>;
  transaction?: Maybe<Transaction>;
  user?: Maybe<UserInfoType>;
};

export type RaisedFlagsFilters = {
  code?: InputMaybe<FlagCode>;
  hasNoTicket?: InputMaybe<Scalars['Boolean']>;
  level?: InputMaybe<FlagLevel>;
  resolved?: InputMaybe<Scalars['Boolean']>;
  transactionId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

/** Money recipient */
export type Recipient = {
  __typename?: 'Recipient';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  bankAccNumber?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country: CountryType;
  created?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['CustomDateGraphType']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  moneySent?: Maybe<Scalars['Decimal']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneCode?: Maybe<Scalars['String']>;
  relationship?: Maybe<RelationshipType>;
  sendingCurrency?: Maybe<Scalars['String']>;
};

export type RecipientInputType = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  bankAccNumber?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  countryId: Scalars['ID'];
  dateOfBirth?: InputMaybe<Scalars['CustomDateGraphType']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  relationshipId?: InputMaybe<Scalars['ID']>;
};

export type RecipientsFilters = {
  query?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type RegionInputType = {
  code: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  maxAmount?: InputMaybe<Scalars['Decimal']>;
  name: Scalars['String'];
};

export type RegionType = {
  __typename?: 'RegionType';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  maxAmount?: Maybe<Scalars['Decimal']>;
  name?: Maybe<Scalars['String']>;
};

export type RegionsFilters = {
  active?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
};

export type RelationshipInputType = {
  id?: InputMaybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type RelationshipType = {
  __typename?: 'RelationshipType';
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type RelationshipsFilters = {
  active?: InputMaybe<Scalars['Boolean']>;
};

export enum ResolveFlagAction {
  CheckDelete = 'CHECK_DELETE',
  None = 'NONE'
}

export enum ResponseStatus {
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS'
}

export type RiskAssessmentType = {
  __typename?: 'RiskAssessmentType';
  riskAssessmentData?: Maybe<Scalars['String']>;
  riskRating?: Maybe<RiskRating>;
};

export enum RiskRating {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type ServiceNetworkType = {
  __typename?: 'ServiceNetworkType';
  networks?: Maybe<Array<Maybe<CorridorLimits>>>;
  paymentService?: Maybe<PaymentServiceType>;
};

export type SettlementTransactionType = {
  __typename?: 'SettlementTransactionType';
  amount?: Maybe<Scalars['Decimal']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SettlmentDataType = {
  __typename?: 'SettlmentDataType';
  data?: Maybe<Array<Maybe<SettlementTransactionType>>>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['String']>;
};

export type StateType = {
  __typename?: 'StateType';
  countryIsoCode2: Scalars['String'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type TicketInputType = {
  description?: InputMaybe<Scalars['String']>;
  internalNote?: InputMaybe<Scalars['String']>;
  raisedFlagId: Scalars['ID'];
  subject: Scalars['String'];
};

export enum TicketStatus {
  Closed = 'CLOSED',
  Initiated = 'INITIATED',
  MoreInfoNeeded = 'MORE_INFO_NEEDED',
  Responded = 'RESPONDED'
}

export type TicketType = {
  __typename?: 'TicketType';
  created?: Maybe<Scalars['DateTime']>;
  createdByName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<FileType>>>;
  id?: Maybe<Scalars['ID']>;
  internalNote?: Maybe<Scalars['String']>;
  messages?: Maybe<Array<Maybe<MessageType>>>;
  number?: Maybe<Scalars['Int']>;
  raisedFlag?: Maybe<RaisedFlagType>;
  status?: Maybe<TicketStatus>;
  subject?: Maybe<Scalars['String']>;
  unseenMessagesCount?: Maybe<Scalars['Int']>;
};

export type TicketsFilters = {
  notResolved?: InputMaybe<Scalars['Boolean']>;
  query?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<InputMaybe<TicketStatus>>>;
  transactionId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  amount?: Maybe<Scalars['Decimal']>;
  canCancel?: Maybe<ActionStatusType>;
  canContinue?: Maybe<ActionStatusType>;
  confirmedAmount?: Maybe<Scalars['Decimal']>;
  /** @deprecated Replaced with canContinue. */
  continue?: Maybe<ActionStatusType>;
  created?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Scalars['String']>;
  expectedPayoutTime?: Maybe<Scalars['DateTime']>;
  fixedFee?: Maybe<Scalars['Float']>;
  fixedFeePaid?: Maybe<Scalars['Decimal']>;
  /** @deprecated Spelling error */
  fixedFeePayed?: Maybe<Scalars['Decimal']>;
  fromId?: Maybe<Scalars['ID']>;
  fromIsoCode2?: Maybe<Scalars['String']>;
  fromIsoCode3?: Maybe<Scalars['String']>;
  fromName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inbound?: Maybe<Scalars['String']>;
  inboundName?: Maybe<Scalars['String']>;
  inboundRefNo?: Maybe<Scalars['String']>;
  /** @deprecated Legacy code. */
  outboundRefNo?: Maybe<Scalars['String']>;
  paidAmount?: Maybe<Scalars['Decimal']>;
  /** @deprecated Spelling error */
  payedAmount?: Maybe<Scalars['Decimal']>;
  paymentInstructions?: Maybe<Scalars['String']>;
  paymentMethodName?: Maybe<Scalars['String']>;
  paymentMethodNumber?: Maybe<Scalars['String']>;
  paymentNetworkCode?: Maybe<Scalars['String']>;
  paymentNetworkId?: Maybe<Scalars['ID']>;
  paymentNetworkName?: Maybe<Scalars['String']>;
  paymentServiceCode?: Maybe<Scalars['String']>;
  paymentServiceId?: Maybe<Scalars['ID']>;
  paymentServiceName?: Maybe<Scalars['String']>;
  pickupCode?: Maybe<Scalars['String']>;
  purposeId?: Maybe<Scalars['ID']>;
  purposeName?: Maybe<Scalars['String']>;
  quote?: Maybe<Scalars['Decimal']>;
  /** If resolved not set all (resolved and not resolved) raised flags will be returned.If level is not set, raised flags for transaction and transaction's sender will be returned. */
  raisedFlags?: Maybe<Array<Maybe<RaisedFlagType>>>;
  receipt?: Maybe<FileType>;
  receivingAmount?: Maybe<Scalars['Decimal']>;
  receivingCurrency?: Maybe<Scalars['String']>;
  /** @deprecated Spelling error */
  recepientId?: Maybe<Scalars['ID']>;
  /** @deprecated Spelling error */
  recepientName?: Maybe<Scalars['String']>;
  recipientAddress1?: Maybe<Scalars['String']>;
  recipientAddress2?: Maybe<Scalars['String']>;
  recipientCity?: Maybe<Scalars['String']>;
  recipientCountry?: Maybe<Scalars['String']>;
  recipientDateOfBirth?: Maybe<Scalars['Date']>;
  recipientEmail?: Maybe<Scalars['String']>;
  recipientFirstName?: Maybe<Scalars['String']>;
  recipientId?: Maybe<Scalars['ID']>;
  recipientLastName?: Maybe<Scalars['String']>;
  recipientMiddleName?: Maybe<Scalars['String']>;
  recipientName?: Maybe<Scalars['String']>;
  recipientPhone?: Maybe<Scalars['String']>;
  relationshipId?: Maybe<Scalars['ID']>;
  relationshipName?: Maybe<Scalars['String']>;
  status?: Maybe<TransactionStatusType>;
  toId?: Maybe<Scalars['ID']>;
  toIsoCode2?: Maybe<Scalars['String']>;
  toIsoCode3?: Maybe<Scalars['String']>;
  toName?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactionStatusInfo?: Maybe<TransactionStatusInfoType>;
  userAddressLine1?: Maybe<Scalars['String']>;
  userAddressLine2?: Maybe<Scalars['String']>;
  userBirthDate?: Maybe<Scalars['DateTime']>;
  userCity?: Maybe<Scalars['String']>;
  userCountry?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  userFirstName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  userLastName?: Maybe<Scalars['String']>;
  userMiddleName?: Maybe<Scalars['String']>;
  userPhone?: Maybe<Scalars['String']>;
  userSocialNumber?: Maybe<Scalars['String']>;
  userTerritory?: Maybe<Scalars['String']>;
  userUnitNumber?: Maybe<Scalars['String']>;
  userUserName?: Maybe<Scalars['String']>;
  userZip?: Maybe<Scalars['String']>;
  variableFee?: Maybe<Scalars['Float']>;
  variableFeePaid?: Maybe<Scalars['Decimal']>;
  /** @deprecated Spelling error */
  variableFeePayed?: Maybe<Scalars['Decimal']>;
};


export type TransactionRaisedFlagsArgs = {
  level?: InputMaybe<FlagLevel>;
  resolved?: InputMaybe<Scalars['Boolean']>;
};

/** Transaction status change. */
export type TransactionChange = {
  __typename?: 'TransactionChange';
  created?: Maybe<Scalars['DateTime']>;
  createdByName?: Maybe<Scalars['String']>;
  /** The unique identifier of the transaction change. */
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  transaction?: Maybe<Transaction>;
  transactionStatus?: Maybe<TransactionStatusType>;
};

export type TransactionHistoryFilters = {
  transactionId?: InputMaybe<Scalars['ID']>;
};

export type TransactionNoteType = {
  __typename?: 'TransactionNoteType';
  created?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type TransactionStatusInfoType = {
  __typename?: 'TransactionStatusInfoType';
  flagCount?: Maybe<Scalars['Int']>;
  flagTooltip?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  isInfo?: Maybe<Scalars['Boolean']>;
  isWarning?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  statusId?: Maybe<Scalars['Int']>;
  ticketCount?: Maybe<Scalars['Int']>;
  ticketTooltip?: Maybe<Scalars['String']>;
};

export type TransactionStatusType = {
  __typename?: 'TransactionStatusType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type TransactionsFilters = {
  countryFromId?: InputMaybe<Scalars['ID']>;
  countryToId?: InputMaybe<Scalars['ID']>;
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  onHold?: InputMaybe<Scalars['Boolean']>;
  outboundStatusIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  paymentNetworkId?: InputMaybe<Scalars['ID']>;
  payoutAgentId?: InputMaybe<Scalars['ID']>;
  pickupCode?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  recipientId?: InputMaybe<Scalars['ID']>;
  statusIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type TransactionsHistoryFilters = {
  query?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type TranslationInputType = {
  id?: InputMaybe<Scalars['Int']>;
  key: Scalars['String'];
  languageId: Scalars['Int'];
  tags?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

export type TranslationType = {
  __typename?: 'TranslationType';
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  language?: Maybe<LanguageType>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  value?: Maybe<Scalars['String']>;
};

export type TranslationsFilters = {
  query?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UserInfoType = {
  __typename?: 'UserInfoType';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
};

export type UserStatusInfoType = {
  __typename?: 'UserStatusInfoType';
  flagCount?: Maybe<Scalars['Int']>;
  flagTooltip?: Maybe<Scalars['String']>;
  ticketCount?: Maybe<Scalars['Int']>;
  ticketTooltip?: Maybe<Scalars['String']>;
  unresolvedFlagsCount?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['ID']>;
};

export type UsersFilters = {
  active?: InputMaybe<Scalars['Boolean']>;
  activeFlags?: InputMaybe<Array<InputMaybe<FlagCode>>>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  markedPositive?: InputMaybe<Scalars['Boolean']>;
  query?: InputMaybe<Scalars['String']>;
  toCountryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export enum VerificationType {
  Email = 'EMAIL',
  Phone = 'PHONE'
}

export type VerifyAddressInputType = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  streetType?: InputMaybe<Scalars['String']>;
  territory?: InputMaybe<Scalars['String']>;
  unitNumber?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type VerifyCentrelinkInputType = {
  /** Centrelink card type. Can be HCC (Health Care), SHC (Seniors Health) or PCC (Pension Concession). */
  cardType?: InputMaybe<Scalars['String']>;
  dateOfExpiry?: InputMaybe<Scalars['CustomDateGraphType']>;
  /** Centrelink customer reference number. */
  referenceNumber?: InputMaybe<Scalars['String']>;
};

export type VerifyDataInputType = {
  address?: InputMaybe<VerifyAddressInputType>;
  /** Centrelink card */
  centrelink?: InputMaybe<VerifyCentrelinkInputType>;
  consents?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  driverLicence?: InputMaybe<VerifyDriverLicenceInputType>;
  medicare?: InputMaybe<VerifyMedicareInputType>;
  passport?: InputMaybe<VerifyPassportInputType>;
  personDetails?: InputMaybe<VerifyPersonDetailsInputType>;
  photoId?: InputMaybe<VerifyPhotoIdInputType>;
};

export type VerifyDriverLicenceInputType = {
  dateOfExpiry?: InputMaybe<Scalars['CustomDateGraphType']>;
  dateOfIssue?: InputMaybe<Scalars['CustomDateGraphType']>;
  documentBackId?: InputMaybe<Scalars['ID']>;
  documentFrontId?: InputMaybe<Scalars['ID']>;
  driverLicenceCardNumber?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type VerifyMedicareInputType = {
  color?: InputMaybe<Scalars['String']>;
  dateOfExpiry?: InputMaybe<Scalars['CustomDateGraphType']>;
  number?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
};

export type VerifyPassportInputType = {
  country?: InputMaybe<Scalars['String']>;
  dateOfExpiry?: InputMaybe<Scalars['CustomDateGraphType']>;
  dateOfIssue?: InputMaybe<Scalars['CustomDateGraphType']>;
  documentBackId?: InputMaybe<Scalars['ID']>;
  documentFrontId?: InputMaybe<Scalars['ID']>;
  number?: InputMaybe<Scalars['String']>;
};

export type VerifyPersonDetailsInputType = {
  birthDate?: InputMaybe<Scalars['CustomDateGraphType']>;
  countryOfBirthId?: InputMaybe<Scalars['ID']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  iagreeCheckBox?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  moovTermsToken?: InputMaybe<Scalars['String']>;
  nationalityCountryId?: InputMaybe<Scalars['ID']>;
  phone?: InputMaybe<Scalars['Long']>;
  socialNumber?: InputMaybe<Scalars['String']>;
  socialNumberType?: InputMaybe<Scalars['String']>;
};

export type VerifyPhotoIdInputType = {
  country?: InputMaybe<Scalars['String']>;
  dateOfExpiry?: InputMaybe<Scalars['CustomDateGraphType']>;
  dateOfIssue?: InputMaybe<Scalars['CustomDateGraphType']>;
  documentBackId?: InputMaybe<Scalars['ID']>;
  documentFrontId?: InputMaybe<Scalars['ID']>;
  number?: InputMaybe<Scalars['String']>;
};

export type EmployeeMutation = {
  __typename?: 'employeeMutation';
  changePassword?: Maybe<Scalars['Boolean']>;
  login?: Maybe<AdminUserType>;
};


export type EmployeeMutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type EmployeeMutationLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type FlagsAndTicketsMutations = {
  __typename?: 'flagsAndTicketsMutations';
  assingManagerToTicket?: Maybe<TicketType>;
  closeTicket?: Maybe<TicketType>;
  createTicket?: Maybe<TicketType>;
  raiseFlag?: Maybe<RaisedFlagType>;
  resolveFlag?: Maybe<RaisedFlagType>;
};


export type FlagsAndTicketsMutationsAssingManagerToTicketArgs = {
  ticketId: Scalars['ID'];
};


export type FlagsAndTicketsMutationsCloseTicketArgs = {
  ticketId?: InputMaybe<Scalars['ID']>;
};


export type FlagsAndTicketsMutationsCreateTicketArgs = {
  data: TicketInputType;
};


export type FlagsAndTicketsMutationsRaiseFlagArgs = {
  description?: InputMaybe<Scalars['String']>;
  flagId: Scalars['ID'];
  raiseTicket?: InputMaybe<Scalars['Boolean']>;
  transactionId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type FlagsAndTicketsMutationsResolveFlagArgs = {
  action?: InputMaybe<ResolveFlagAction>;
  markPositive?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  raisedFlagId: Scalars['ID'];
};

export type FlagsAndTicketsQueries = {
  __typename?: 'flagsAndTicketsQueries';
  id?: Maybe<Scalars['ID']>;
  raisedFlag?: Maybe<RaisedFlagType>;
  /** If resolved not set all (resolved and not resolved) raised flags will be returned.If userId set: level not set - user's raised flags and user's transaction raised flags will be returned,level = User - just user's flags will be returned,level = Transaction - just flags from all user's transactions will be returned.If transactionId set: level not set - transaction's flags and sender's flags will be returned,level = User - sender's flags will be returned,level = Transaction - just flags for transaction will be returned */
  raisedFlags?: Maybe<PaginatorRaisedFlagType>;
  ticket?: Maybe<TicketType>;
  tickets?: Maybe<PaginatorTicketType>;
};


export type FlagsAndTicketsQueriesRaisedFlagArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type FlagsAndTicketsQueriesRaisedFlagsArgs = {
  pageRequest?: InputMaybe<PageRequestRaisedFlagsFilterType>;
};


export type FlagsAndTicketsQueriesTicketArgs = {
  id: Scalars['ID'];
};


export type FlagsAndTicketsQueriesTicketsArgs = {
  pageRequest?: InputMaybe<PageRequestTicketsFilterType>;
};

export type MessagingMutations = {
  __typename?: 'messagingMutations';
  markMessagesSeen?: Maybe<Scalars['Boolean']>;
  sendMessage?: Maybe<MessageType>;
};


export type MessagingMutationsMarkMessagesSeenArgs = {
  ticketId?: InputMaybe<Scalars['ID']>;
  transactionId?: InputMaybe<Scalars['ID']>;
};


export type MessagingMutationsSendMessageArgs = {
  data: MessageInputType;
};

export type MessagingQueries = {
  __typename?: 'messagingQueries';
  id?: Maybe<Scalars['ID']>;
  messages?: Maybe<Array<Maybe<MessageType>>>;
};


export type MessagingQueriesMessagesArgs = {
  ticketId?: InputMaybe<Scalars['ID']>;
};

export type PayoutMutations = {
  __typename?: 'payoutMutations';
  setOutboundStatus?: Maybe<AdminTransactionType>;
};


export type PayoutMutationsSetOutboundStatusArgs = {
  note?: InputMaybe<Scalars['String']>;
  pickupCode?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  transactionId: Scalars['ID'];
};

export type PayoutQueries = {
  __typename?: 'payoutQueries';
  id?: Maybe<Scalars['ID']>;
  outboundStatuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  payout?: Maybe<AdminTransactionType>;
  payouts?: Maybe<PaginatorAdminTransactionTypeGraphType>;
};


export type PayoutQueriesPayoutArgs = {
  id: Scalars['ID'];
};


export type PayoutQueriesPayoutsArgs = {
  pageRequest?: InputMaybe<PageRequestPayoutsFilterType>;
};

/** Only public queries do not require authentication. */
export type PublicQueries = {
  __typename?: 'publicQueries';
  /** Suggests valid addresses based on provided input. */
  addressLookup?: Maybe<Scalars['String']>;
  /** Calculate fees and get details related for the future transaction. */
  calculateAmount?: Maybe<CalcResultType>;
  /** List of active corridor groups to be used on the calculator. */
  corridorsGroups?: Maybe<Array<Maybe<CorridorGroupType>>>;
  /** List of countries available on the system. Depending on the arguments, could be used on registration or edit end user profile (isSender: true), on recipient management (isRecipient: true). */
  countries?: Maybe<Array<Maybe<CountryType>>>;
  /** Retrive fields configuration to create dynamic forms. */
  fieldsConfig?: Maybe<Array<Maybe<FieldsConfigType>>>;
  /** Retrive default settings for the frontend application. */
  frontendConfiguration?: Maybe<FrontendConfigurationType>;
  id?: Maybe<Scalars['ID']>;
  /** List of purposes on the system. `Purpose.ID` should be used on `[createTransaction](/docs/api/mutations#createtransaction)` mutation. */
  purposes?: Maybe<Array<Maybe<PurposeType>>>;
  /** List of relationships on the system. `Relationship.ID` should be used on `[addRecipient](/docs/api/mutations#addrecipient)` and `[updateRecipient](/docs/api/mutations#updaterecipient)`, mutation. */
  relationships?: Maybe<Array<Maybe<RelationshipType>>>;
  /** List of states in specified country. */
  states?: Maybe<Array<Maybe<StateType>>>;
  /** List of translations defined on the system. */
  translations?: Maybe<Array<Maybe<TranslationType>>>;
};


/** Only public queries do not require authentication. */
export type PublicQueriesAddressLookupArgs = {
  query?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


/** Only public queries do not require authentication. */
export type PublicQueriesCalculateAmountArgs = {
  countryFromId: Scalars['ID'];
  countryToId: Scalars['ID'];
  locationId?: InputMaybe<Scalars['ID']>;
  networkId: Scalars['ID'];
  receiveAmount?: InputMaybe<Scalars['Float']>;
  sendAmount?: InputMaybe<Scalars['Float']>;
  serviceId: Scalars['ID'];
};


/** Only public queries do not require authentication. */
export type PublicQueriesCorridorsGroupsArgs = {
  countryFrom?: InputMaybe<Scalars['String']>;
  countryFromId?: InputMaybe<Scalars['ID']>;
  countryTo?: InputMaybe<Scalars['String']>;
  countryToId?: InputMaybe<Scalars['ID']>;
};


/** Only public queries do not require authentication. */
export type PublicQueriesCountriesArgs = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  isRecipient?: InputMaybe<Scalars['Boolean']>;
  isSender?: InputMaybe<Scalars['Boolean']>;
};


/** Only public queries do not require authentication. */
export type PublicQueriesFieldsConfigArgs = {
  countryCode?: InputMaybe<Scalars['String']>;
  fieldsGroup: Scalars['String'];
};


/** Only public queries do not require authentication. */
export type PublicQueriesRelationshipsArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
};


/** Only public queries do not require authentication. */
export type PublicQueriesStatesArgs = {
  countryIsoCode2?: InputMaybe<Scalars['String']>;
};


/** Only public queries do not require authentication. */
export type PublicQueriesTranslationsArgs = {
  isoCode2?: InputMaybe<Scalars['String']>;
  langId?: InputMaybe<Scalars['Int']>;
};

export type SetupMutations = {
  __typename?: 'setupMutations';
  createCorridor?: Maybe<CorridorType>;
  createCurrency?: Maybe<CurrencyType>;
  createFee?: Maybe<FeeType>;
  createLanguage?: Maybe<LanguageType>;
  createManager?: Maybe<AdminUserType>;
  createPurpose?: Maybe<PurposeType>;
  createRegion?: Maybe<RegionType>;
  createRelationship?: Maybe<RelationshipType>;
  createTranslation?: Maybe<TranslationType>;
  deleteCorridor?: Maybe<Scalars['Boolean']>;
  deleteCountry?: Maybe<Scalars['Boolean']>;
  deleteCurrency?: Maybe<CurrencyType>;
  deleteFee?: Maybe<FeeType>;
  deleteLanguage?: Maybe<LanguageType>;
  deletePurpose?: Maybe<Scalars['Boolean']>;
  deleteRegion?: Maybe<Scalars['Boolean']>;
  deleteRelationship?: Maybe<Scalars['Boolean']>;
  deleteTranslation?: Maybe<Array<Maybe<TranslationType>>>;
  resetPasswordForManager?: Maybe<Scalars['Boolean']>;
  updateCorridor?: Maybe<CorridorType>;
  updateCountry?: Maybe<CountryType>;
  updateCurrency?: Maybe<CurrencyType>;
  updateFee?: Maybe<FeeType>;
  updateLanguage?: Maybe<LanguageType>;
  updateManager?: Maybe<AdminUserType>;
  updatePurpose?: Maybe<PurposeType>;
  updateRegion?: Maybe<RegionType>;
  updateRelationship?: Maybe<RelationshipType>;
  updateTranslation?: Maybe<TranslationType>;
};


export type SetupMutationsCreateCorridorArgs = {
  data: CorridorInputType;
};


export type SetupMutationsCreateCurrencyArgs = {
  data?: InputMaybe<CurrencyInputType>;
};


export type SetupMutationsCreateFeeArgs = {
  data: FeeInputType;
};


export type SetupMutationsCreateLanguageArgs = {
  data: LanguageInputType;
};


export type SetupMutationsCreateManagerArgs = {
  data: AdminInputType;
};


export type SetupMutationsCreatePurposeArgs = {
  data: PurposeInputType;
};


export type SetupMutationsCreateRegionArgs = {
  data: RegionInputType;
};


export type SetupMutationsCreateRelationshipArgs = {
  data: RelationshipInputType;
};


export type SetupMutationsCreateTranslationArgs = {
  data: TranslationInputType;
};


export type SetupMutationsDeleteCorridorArgs = {
  id: Scalars['ID'];
};


export type SetupMutationsDeleteCountryArgs = {
  id: Scalars['ID'];
};


export type SetupMutationsDeleteCurrencyArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type SetupMutationsDeleteFeeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type SetupMutationsDeleteLanguageArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type SetupMutationsDeletePurposeArgs = {
  id: Scalars['ID'];
};


export type SetupMutationsDeleteRegionArgs = {
  id: Scalars['ID'];
};


export type SetupMutationsDeleteRelationshipArgs = {
  id: Scalars['ID'];
};


export type SetupMutationsDeleteTranslationArgs = {
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type SetupMutationsResetPasswordForManagerArgs = {
  managerId?: InputMaybe<Scalars['ID']>;
  newPassword: Scalars['String'];
};


export type SetupMutationsUpdateCorridorArgs = {
  data: CorridorInputType;
};


export type SetupMutationsUpdateCountryArgs = {
  data: CountryInputType;
};


export type SetupMutationsUpdateCurrencyArgs = {
  data?: InputMaybe<CurrencyInputType>;
};


export type SetupMutationsUpdateFeeArgs = {
  data: FeeInputType;
};


export type SetupMutationsUpdateLanguageArgs = {
  data: LanguageInputType;
};


export type SetupMutationsUpdateManagerArgs = {
  data: AdminInputType;
};


export type SetupMutationsUpdatePurposeArgs = {
  data: PurposeInputType;
};


export type SetupMutationsUpdateRegionArgs = {
  data: RegionInputType;
};


export type SetupMutationsUpdateRelationshipArgs = {
  data: RelationshipInputType;
};


export type SetupMutationsUpdateTranslationArgs = {
  data: TranslationInputType;
};

export type SetupQueries = {
  __typename?: 'setupQueries';
  corridor?: Maybe<CorridorType>;
  corridors?: Maybe<PaginatorCorridorType>;
  countries?: Maybe<PaginatorCountryType>;
  country?: Maybe<CountryType>;
  currencies?: Maybe<PaginatorCurrencyType>;
  /** Get currency by Id */
  currency?: Maybe<CurrencyType>;
  fees?: Maybe<Array<Maybe<FeeType>>>;
  id?: Maybe<Scalars['ID']>;
  language?: Maybe<LanguageType>;
  languages?: Maybe<PaginatorLanguageType>;
  manager?: Maybe<AdminUserType>;
  managers?: Maybe<PaginatorAdminUserType>;
  purpose?: Maybe<PurposeType>;
  purposes?: Maybe<PaginatorPurposeType>;
  region?: Maybe<RegionType>;
  regions?: Maybe<PaginatorRegionType>;
  relationship?: Maybe<RelationshipType>;
  relationships?: Maybe<PaginatorRelationshipType>;
  translation?: Maybe<PaginatorTranslationType>;
  translations?: Maybe<PaginatorTranslationType>;
};


export type SetupQueriesCorridorArgs = {
  id: Scalars['ID'];
};


export type SetupQueriesCorridorsArgs = {
  pageRequest?: InputMaybe<PageRequestCorridorsFilterType>;
};


export type SetupQueriesCountriesArgs = {
  pageRequest?: InputMaybe<PageRequestCountriesFilterType>;
};


export type SetupQueriesCountryArgs = {
  id: Scalars['ID'];
};


export type SetupQueriesCurrenciesArgs = {
  pageRequest?: InputMaybe<PageRequestType>;
};


export type SetupQueriesCurrencyArgs = {
  id: Scalars['ID'];
};


export type SetupQueriesFeesArgs = {
  corridorId: Scalars['ID'];
};


export type SetupQueriesLanguageArgs = {
  id: Scalars['ID'];
};


export type SetupQueriesLanguagesArgs = {
  pageRequest?: InputMaybe<PageRequestType>;
};


export type SetupQueriesManagerArgs = {
  id: Scalars['ID'];
};


export type SetupQueriesManagersArgs = {
  pageRequest?: InputMaybe<PageRequestManagersFilterType>;
};


export type SetupQueriesPurposeArgs = {
  id: Scalars['ID'];
};


export type SetupQueriesPurposesArgs = {
  pageRequest?: InputMaybe<PageRequestPurposesFilterType>;
};


export type SetupQueriesRegionArgs = {
  id: Scalars['ID'];
};


export type SetupQueriesRegionsArgs = {
  pageRequest?: InputMaybe<PageRequestRegionsFilterType>;
};


export type SetupQueriesRelationshipArgs = {
  id: Scalars['ID'];
};


export type SetupQueriesRelationshipsArgs = {
  pageRequest?: InputMaybe<PageRequestRelationshipsFilterType>;
};


export type SetupQueriesTranslationArgs = {
  id: Scalars['ID'];
};


export type SetupQueriesTranslationsArgs = {
  pageRequest?: InputMaybe<PageRequestTranslationsFilterType>;
};

export type SystemQueries = {
  __typename?: 'systemQueries';
  /** List of document categories on the system. */
  documentCategories?: Maybe<Array<Maybe<DocumentCategoryType>>>;
  /** List of available flags on the system. */
  flags?: Maybe<Array<Maybe<FlagType>>>;
  /** List of payment networks. Can be used on the `transactionsManagement.transactions` query. */
  paymentNetworks?: Maybe<Array<Maybe<PaymentNetworkType>>>;
  /** List of payment services. Can be used on the `transactionsManagement.transactions` query. */
  paymentServices?: Maybe<Array<Maybe<PaymentServiceType>>>;
  /** List of possible statuses for the transaction. */
  transactionStatuses?: Maybe<Array<Maybe<TransactionStatusType>>>;
  /** List of translation tags. */
  translationTags?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type SystemQueriesFlagsArgs = {
  all?: InputMaybe<Scalars['Boolean']>;
  level?: InputMaybe<FlagLevel>;
};

export type TransactionsMutations = {
  __typename?: 'transactionsMutations';
  addNote?: Maybe<TransactionNoteType>;
  /** Replace user's document assigned to current transaction and updates transaction data record from selected document. */
  changeTransactionDocument?: Maybe<AdminTransactionType>;
  /** Update transaction data record with current data from assigned user's document. */
  updateTransactionDocument?: Maybe<AdminTransactionType>;
  updateTransactionRecipientInfo?: Maybe<Scalars['Boolean']>;
  updateTransactionStatus?: Maybe<AdminTransactionType>;
};


export type TransactionsMutationsAddNoteArgs = {
  note?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['ID']>;
};


export type TransactionsMutationsChangeTransactionDocumentArgs = {
  documentId: Scalars['ID'];
  transactionId: Scalars['ID'];
};


export type TransactionsMutationsUpdateTransactionDocumentArgs = {
  transactionId: Scalars['ID'];
};


export type TransactionsMutationsUpdateTransactionRecipientInfoArgs = {
  transactionId: Scalars['ID'];
};


export type TransactionsMutationsUpdateTransactionStatusArgs = {
  chargedAmount?: InputMaybe<Scalars['Float']>;
  externalId?: InputMaybe<Scalars['String']>;
  statusId: Scalars['ID'];
  transactionId: Scalars['ID'];
};

export type TransactionsQueries = {
  __typename?: 'transactionsQueries';
  id?: Maybe<Scalars['ID']>;
  /** Get one transaction. */
  transaction?: Maybe<AdminTransactionType>;
  /** Get the list of transactions for the user. */
  transactions?: Maybe<PaginatorAdminTransactionTypeGraphType>;
  /** Get transaction history for all transactions or for one. */
  transactionsHistory?: Maybe<PaginatorTransactionHType>;
  /** Get the transaction report. */
  transactionsReport?: Maybe<Scalars['String']>;
};


export type TransactionsQueriesTransactionArgs = {
  id: Scalars['ID'];
};


export type TransactionsQueriesTransactionsArgs = {
  pageRequest?: InputMaybe<PageRequestTransactionsFilterType>;
};


export type TransactionsQueriesTransactionsHistoryArgs = {
  pageRequest?: InputMaybe<PageRequestTransactionsHistoryFilterType>;
};


export type TransactionsQueriesTransactionsReportArgs = {
  filters?: InputMaybe<TransactionsFilters>;
};

export type UserMutations = {
  __typename?: 'userMutations';
  /**
   * Use it to track contents from the user during the following mutations: `register`, `initiateTransaction`, and `verify`. Consents should be recorded if a user has `ConsentCreate` permission.
   *
   * As permissions are available only after a user is logged in, after `register` mutation, use `user.login` mutation, and after login, if permissions exist, add consent for registration.
   */
  addConsent?: Maybe<Scalars['String']>;
  /** Use it to add a new payment method to user's payment methods. */
  addPaymentMethod?: Maybe<InboundAccountType>;
  /** Add a new property to the custom properties. */
  addProperties?: Maybe<Array<Maybe<CustomPropertyType>>>;
  /** Is used to cancel a transaction. To be sure that the transaction can be canceled check `canCancel` property in the transaction. */
  cancelTransaction?: Maybe<Transaction>;
  /** Change password for a user. */
  changePassword?: Maybe<Scalars['Boolean']>;
  /** Use it to change a payment method status. */
  changePaymentMethodStatus?: Maybe<Scalars['Boolean']>;
  /** Login user before account deletion to confirm it's identity. */
  confirmIdentity?: Maybe<EndUserType>;
  /** Use the `public.fieldsConfig` query to retrieve fields needed for recipient creation. */
  createRecipient?: Maybe<Recipient>;
  /**
   * To be able to create the transaction provide necessary arguments.
   *  - Please note that you should send only `sendAmount` or `receiveAmount`.
   *  - The `serviceId`, `networkId`, and `locationId` should be provided from the `public.corridorsGroups` query.
   *  - The `quoteId` should be used from the calculate `public.calculateAmount` query.
   */
  createTransaction?: Maybe<Transaction>;
  /** Use it to initiate account deletion process. */
  deleteAccount?: Maybe<DeleteAccountResponseType>;
  /** Remove the recipient from the list. Recipients can be deleted only if there are no related transactions. */
  deleteRecipient?: Maybe<Scalars['Boolean']>;
  /** Generate inbound token to initialise inbound widget. */
  generateInboundToken?: Maybe<InboundAuthTokenType>;
  /** Once the transaction is created you should initiate payment with the selected inbound. The list of available inbounds for a transaction can be obtained by running `userQueries.inbounds` with `transaction.id` argument. */
  initiatePayment?: Maybe<InboundResponseType>;
  /** Used to verify data provided in the KYC form. Form definition can be obtained from the `userQueries.kycFieldsConfig` query. Data will be rejected even before checking with the KYC provider if there is no active raised flag with `presentedCode:KYC_MISSING`. */
  kycVerify?: Maybe<Scalars['Boolean']>;
  /** Login user on the system. */
  login?: Maybe<EndUserType>;
  /** You can mark notifications seen by a list of `ID's` or all at once. */
  markNotificationsSeen?: Maybe<Scalars['Boolean']>;
  /** Register user on the system. Use the `public.fieldsConfig` query to retrieve fields needed for registration. */
  register?: Maybe<EndUserType>;
  /** Use to remove the inbound account from the list of inbound accounts. */
  removeInboundAccount?: Maybe<Scalars['Boolean']>;
  /** Send a reset password request for a user. */
  requestResetPassword?: Maybe<Scalars['Boolean']>;
  /** Resend email related to specific action. */
  resendEmail?: Maybe<Scalars['Boolean']>;
  /** Resend verification codes to communication channel that the user provided. */
  resendVerificationCode?: Maybe<Scalars['Boolean']>;
  /** Complete reset password flow for a user. */
  resetPassword?: Maybe<Scalars['Boolean']>;
  /** Use to set push token provided by an iOS or Android device using Firebase. You can refer to the Firebase documentation for step-by-step instructions on how to set up push notifications on your app for both iOS and Android. */
  setPushToken?: Maybe<Scalars['Boolean']>;
  /** Use it to update a payment method details. */
  updatePaymentMethod?: Maybe<InboundAccountType>;
  /** Update the user's profile. Use the `public.fieldsConfig` query to retrieve fields needed for updating a profile. */
  updateProfile?: Maybe<EndUserType>;
  /** Use the `public.fieldsConfig` query to retrieve fields needed for recipient update. */
  updateRecipient?: Maybe<Recipient>;
  /** Verify communication channels that the user provided. */
  verify?: Maybe<EndUserType>;
};


export type UserMutationsAddConsentArgs = {
  consentType: Scalars['Int'];
  metadata?: InputMaybe<Scalars['String']>;
};


export type UserMutationsAddPaymentMethodArgs = {
  paymentMethod: PaymentMethodInputType;
};


export type UserMutationsAddPropertiesArgs = {
  flagCode?: InputMaybe<FlagCode>;
  properties?: InputMaybe<Array<InputMaybe<CustomPropertyInputType>>>;
};


export type UserMutationsCancelTransactionArgs = {
  transactionId: Scalars['ID'];
};


export type UserMutationsChangePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type UserMutationsChangePaymentMethodStatusArgs = {
  inboundCode: Scalars['String'];
  newStatus: Scalars['String'];
  paymentMethodId: Scalars['String'];
};


export type UserMutationsConfirmIdentityArgs = {
  action: AccountAction;
  appVersion?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  username: Scalars['String'];
};


export type UserMutationsCreateRecipientArgs = {
  data: RecipientInputType;
};


export type UserMutationsCreateTransactionArgs = {
  countryFromId: Scalars['ID'];
  countryToId: Scalars['ID'];
  locationId?: InputMaybe<Scalars['ID']>;
  networkId: Scalars['ID'];
  purposeId?: InputMaybe<Scalars['ID']>;
  quoteId?: InputMaybe<Scalars['ID']>;
  receiveAmount?: InputMaybe<Scalars['Float']>;
  recipientId: Scalars['ID'];
  sendAmount?: InputMaybe<Scalars['Float']>;
  serviceId: Scalars['ID'];
};


export type UserMutationsDeleteRecipientArgs = {
  id: Scalars['ID'];
};


export type UserMutationsGenerateInboundTokenArgs = {
  inbound: Scalars['String'];
};


export type UserMutationsInitiatePaymentArgs = {
  inbound: Scalars['String'];
  paymentMethodId?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['ID'];
};


export type UserMutationsKycVerifyArgs = {
  data: VerifyDataInputType;
};


export type UserMutationsLoginArgs = {
  appVersion?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  username: Scalars['String'];
};


export type UserMutationsMarkNotificationsSeenArgs = {
  all?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type UserMutationsRegisterArgs = {
  data: EndUserRegisterType;
};


export type UserMutationsRemoveInboundAccountArgs = {
  accountType: InboundAccountEnumType;
  id: Scalars['String'];
  inbound: Scalars['String'];
};


export type UserMutationsRequestResetPasswordArgs = {
  username: Scalars['String'];
};


export type UserMutationsResendEmailArgs = {
  emailType: EmailTemplateEnumType;
  id: Scalars['ID'];
};


export type UserMutationsResendVerificationCodeArgs = {
  type?: InputMaybe<Scalars['String']>;
};


export type UserMutationsResetPasswordArgs = {
  code: Scalars['String'];
  newPassword: Scalars['String'];
  username: Scalars['String'];
};


export type UserMutationsSetPushTokenArgs = {
  pushToken?: InputMaybe<Scalars['String']>;
};


export type UserMutationsUpdatePaymentMethodArgs = {
  paymentMethod: PaymentMethodInputType;
};


export type UserMutationsUpdateProfileArgs = {
  data: EndUserProfileType;
};


export type UserMutationsUpdateRecipientArgs = {
  data: RecipientInputType;
};


export type UserMutationsVerifyArgs = {
  code: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

/** All data related to the currently logged user. */
export type UserQueries = {
  __typename?: 'userQueries';
  /** Get data for logged user. */
  customProperties?: Maybe<Array<Maybe<CustomPropertyType>>>;
  /** List of documents (e.g. passport, driver's license...) for the user. */
  documents?: Maybe<PaginatorDocumentType>;
  /** Currently logged in user details. */
  endUser?: Maybe<EndUserType>;
  /** List of files for the user. */
  files?: Maybe<PaginatorFileType>;
  id?: Maybe<Scalars['ID']>;
  /** Get one inbound account for the user. */
  inboundAccount?: Maybe<InboundAccountType>;
  /** Get list of the inbound accounts. */
  inboundAccounts?: Maybe<PaginatorInboundAccountType>;
  /** Get inbounds list for a specific transaction. */
  inbounds?: Maybe<Array<Maybe<InboundServiceType>>>;
  /** Retrive fields configuration to create KYC dynamic forms. */
  kycFieldsConfig?: Maybe<Array<Maybe<FieldSetType>>>;
  /** Get one notification for the user. */
  notification?: Maybe<NotificationType>;
  /** List of notifications for the user. */
  notifications?: Maybe<PaginatorNotificationType>;
  /** Get list of onboarding steps and their status. */
  onboarding?: Maybe<OnboardingType>;
  /** @deprecated Moved to query: frontendConfiguration */
  paymentMethodsRequired?: Maybe<Array<Maybe<InboundServiceType>>>;
  /**
   *
   * `resolved: null` all raised flags will be returned (resolved and not resolved).
   *
   * If `userId` is set, but:
   * - `level: null` - user's raised flags and user's transaction raised flags will be returned,
   * - `level: User` - just user's flags will be returned,
   * - `level: Transaction` - just flags from all user's transactions will be returned.
   *
   * If `transactionId` is set, but:
   * - `level: null` - transaction's flags and sender's flags will be returned,
   * - `level: User` - sender's flags will be returned,
   * - `level: Transaction` - just flags for transaction will be returned.
   */
  raisedFlags?: Maybe<PaginatorRaisedFlagType>;
  /** Get one recipient. */
  recipient?: Maybe<Recipient>;
  /** List of recipients for the user. */
  recipients?: Maybe<PaginatorRecipientType>;
  /** Get one ticket. */
  ticket?: Maybe<TicketType>;
  /** List of tickets for the user. */
  tickets?: Maybe<PaginatorTicketType>;
  /** Get one transaction. */
  transaction?: Maybe<Transaction>;
  /** Transaction history list for specific transaction. */
  transactionHistory?: Maybe<PaginatorTransactionHType>;
  /** List of transactions for the user. */
  transactions?: Maybe<PaginatorTransactionType>;
};


/** All data related to the currently logged user. */
export type UserQueriesCustomPropertiesArgs = {
  tag?: InputMaybe<Scalars['String']>;
};


/** All data related to the currently logged user. */
export type UserQueriesDocumentsArgs = {
  pageRequest?: InputMaybe<PageRequestType>;
};


/** All data related to the currently logged user. */
export type UserQueriesFilesArgs = {
  pageRequest?: InputMaybe<PageRequestFilesFilterType>;
};


/** All data related to the currently logged user. */
export type UserQueriesInboundAccountArgs = {
  accountId?: InputMaybe<Scalars['String']>;
  inboundCode?: InputMaybe<Scalars['String']>;
};


/** All data related to the currently logged user. */
export type UserQueriesInboundAccountsArgs = {
  pageRequest?: InputMaybe<PageRequestType>;
};


/** All data related to the currently logged user. */
export type UserQueriesInboundsArgs = {
  transactionId?: InputMaybe<Scalars['ID']>;
};


/** All data related to the currently logged user. */
export type UserQueriesKycFieldsConfigArgs = {
  countryIsoCode2?: InputMaybe<Scalars['String']>;
};


/** All data related to the currently logged user. */
export type UserQueriesNotificationArgs = {
  id: Scalars['ID'];
};


/** All data related to the currently logged user. */
export type UserQueriesNotificationsArgs = {
  pageRequest?: InputMaybe<PageRequestNotificationsFilterType>;
};


/** All data related to the currently logged user. */
export type UserQueriesOnboardingArgs = {
  type?: InputMaybe<Scalars['String']>;
};


/** All data related to the currently logged user. */
export type UserQueriesPaymentMethodsRequiredArgs = {
  pageRequest?: InputMaybe<PageRequestType>;
};


/** All data related to the currently logged user. */
export type UserQueriesRaisedFlagsArgs = {
  pageRequest?: InputMaybe<PageRequestRaisedFlagsFilterType>;
};


/** All data related to the currently logged user. */
export type UserQueriesRecipientArgs = {
  id: Scalars['ID'];
};


/** All data related to the currently logged user. */
export type UserQueriesRecipientsArgs = {
  pageRequest?: InputMaybe<PageRequestRecipientsFilterType>;
};


/** All data related to the currently logged user. */
export type UserQueriesTicketArgs = {
  id: Scalars['ID'];
};


/** All data related to the currently logged user. */
export type UserQueriesTicketsArgs = {
  pageRequest?: InputMaybe<PageRequestTicketsFilterType>;
};


/** All data related to the currently logged user. */
export type UserQueriesTransactionArgs = {
  id: Scalars['ID'];
};


/** All data related to the currently logged user. */
export type UserQueriesTransactionHistoryArgs = {
  pageRequest?: InputMaybe<PageRequestTransactionHistoryFilterType>;
};


/** All data related to the currently logged user. */
export type UserQueriesTransactionsArgs = {
  pageRequest?: InputMaybe<PageRequestTransactionsFilterType>;
};

export type UsersManagementMutations = {
  __typename?: 'usersManagementMutations';
  addFilesToDocument?: Maybe<Array<Maybe<FileType>>>;
  createDocument?: Maybe<DocumentType>;
  deleteUserAccount?: Maybe<DeleteAccountResponseType>;
  setCheckResult?: Maybe<RiskAssessmentType>;
  setDocumentAsPrimary?: Maybe<Scalars['Boolean']>;
  setRiskRate?: Maybe<RiskAssessmentType>;
  updateDocument?: Maybe<DocumentType>;
  updateFile?: Maybe<FileType>;
};


export type UsersManagementMutationsAddFilesToDocumentArgs = {
  documentId: Scalars['ID'];
  fileIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type UsersManagementMutationsCreateDocumentArgs = {
  data: DocumentInputType;
};


export type UsersManagementMutationsDeleteUserAccountArgs = {
  userId?: InputMaybe<Scalars['ID']>;
};


export type UsersManagementMutationsSetCheckResultArgs = {
  adverseMediaHit?: InputMaybe<Scalars['Boolean']>;
  pepHit?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['ID']>;
  watchListHit?: InputMaybe<Scalars['Boolean']>;
};


export type UsersManagementMutationsSetDocumentAsPrimaryArgs = {
  documentId: Scalars['ID'];
};


export type UsersManagementMutationsSetRiskRateArgs = {
  riskRating?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type UsersManagementMutationsUpdateDocumentArgs = {
  addedFilesIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  data: DocumentInputType;
  deletedFileIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type UsersManagementMutationsUpdateFileArgs = {
  data: FileInputType;
};

export type UsersManagementQueries = {
  __typename?: 'usersManagementQueries';
  assessmentData?: Maybe<RiskAssessmentType>;
  consents?: Maybe<PaginatorConsentGraphType>;
  customProperties?: Maybe<Array<Maybe<CustomPropertyType>>>;
  deleteAccountSteps?: Maybe<DeleteAccountResponseType>;
  document?: Maybe<DocumentType>;
  documents?: Maybe<PaginatorDocumentType>;
  file?: Maybe<FileType>;
  files?: Maybe<PaginatorFileType>;
  id?: Maybe<Scalars['ID']>;
  kycLog?: Maybe<KycLogType>;
  kycLogs?: Maybe<PaginatorKycLogType>;
  recipients?: Maybe<PaginatorRecipientType>;
  riskAssesmentReport?: Maybe<Scalars['String']>;
  roleNames?: Maybe<Array<Maybe<PropertyType>>>;
  user?: Maybe<AdminEndUserType>;
  users?: Maybe<PaginatorAdminEndUserType>;
};


export type UsersManagementQueriesAssessmentDataArgs = {
  userId: Scalars['ID'];
};


export type UsersManagementQueriesConsentsArgs = {
  pageRequest?: InputMaybe<PageRequestConsentsFilterType>;
};


export type UsersManagementQueriesCustomPropertiesArgs = {
  tag?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};


export type UsersManagementQueriesDeleteAccountStepsArgs = {
  userId?: InputMaybe<Scalars['ID']>;
};


export type UsersManagementQueriesDocumentArgs = {
  id: Scalars['ID'];
};


export type UsersManagementQueriesDocumentsArgs = {
  pageRequest?: InputMaybe<PageRequestDocumentsFilterType>;
};


export type UsersManagementQueriesFileArgs = {
  id: Scalars['ID'];
};


export type UsersManagementQueriesFilesArgs = {
  pageRequest?: InputMaybe<PageRequestFilesFilterType>;
};


export type UsersManagementQueriesKycLogArgs = {
  id: Scalars['ID'];
};


export type UsersManagementQueriesKycLogsArgs = {
  pageRequest?: InputMaybe<PageRequestKycLogsFilterType>;
};


export type UsersManagementQueriesRecipientsArgs = {
  pageRequest?: InputMaybe<PageRequestRecipientsFilterType>;
};


export type UsersManagementQueriesUserArgs = {
  id: Scalars['ID'];
};


export type UsersManagementQueriesUsersArgs = {
  pageRequest?: InputMaybe<PageRequestUsersFilterType>;
};
