import { Component, OnInit } from '@angular/core';
import { TranslationsService } from 'src/app/_services/translations.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { AdminEndUserType, EndUserType, RaisedFlagType } from 'src/app/_graphql/schema';
import { Subscribable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AdminFlagsService } from 'src/app/_services/admin/admin-flags.service';
import { AdminUsersService } from 'src/app/_services/admin/admin-users.service';
import { DrawerService } from '../wrapper/drawer.service';

@Component({
  selector: 'admin-tab-wrapper',
  templateUrl: './admin-tab-wrapper.component.html',
  styleUrls: ['./admin-tab-wrapper.component.scss']
})
export class AdminTabWrapperComponent implements OnInit {

  navLinks = [];
  adminFlagsService$: Subscribable<RaisedFlagType[]>
  user$: Subscribable<AdminEndUserType>

  constructor(
    public trans: TranslationsService,
    public auth: AuthenticationService,
    public adminUsersService: AdminUsersService,
    private route: ActivatedRoute,
    private adminFlagsService: AdminFlagsService,
    private drawerService: DrawerService
  ) {
    let userId = this.route.snapshot.paramMap.get('userId');
    this.user$ = this.adminUsersService.one(userId)
    var take = this.adminFlagsService.queryAllParams.take
    this.adminFlagsService.queryAllParams.take = null;
    this.adminFlagsService$ = this.adminFlagsService.all({ userId: userId, resolved: null }, false, userId ? 'flags4' + userId : null);
    this.adminFlagsService.queryAllParams.take = take;

    this.navLinks.push({ url: 'profile', 'label': 'Profile' });
    if (auth.hasPermission(auth.perm.TransactionsRead)) {
      this.navLinks.push({ url: 'transactions', 'label': 'Transactions' });
    }
    // keep array as recipients make some bug
    this.navLinks.push({ url: 'recipients', 'label': 'Recipients' });
  }

  ngOnInit() {
  }
  getBack() {
    this.drawerService.open();
    window.history.back();
  }
}
