import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { TicketType } from 'src/app/_graphql/schema';
import { FilesFragmentBasic, UserInfoFragment } from 'src/app/_graphql/shared-fragments';
import { BaseService } from '../admin/base.service';



@Injectable({
  providedIn: 'root'
})
export class UserTicketsService extends BaseService<TicketType>{

  selectOneFields = gql`
    fragment TicketSelectOneFields on TicketType  {
      id description status number subject
      raisedFlag {
        level resolved 
        transaction { id transactionId } 
        forUserId 
        user { id ...UserInfoFragment }
      }
      created
      files { id ...FilesFragmentBasic }
    }
    ${UserInfoFragment}
    ${FilesFragmentBasic}
  `;

  selectAllFields = gql`
    fragment TicketSelectAllFields on TicketType  {
      id status number subject 
      raisedFlag { 
        level transaction { id transactionId } 
        forUserId 
        user { id ...UserInfoFragment }
      }
      unseenMessagesCount
      description
      created
  }
  ${UserInfoFragment}
  `;

  constructor(protected apollo: Apollo) {
    super(apollo);
    this.mainScope = "user";
    this.initGql('ticket');
  }

  public sendMessage(data: any) {
    return this.mutation(
      gql
        `mutation sendMessage(
          $data: MessageInputType!
        ) {
          flagsAndTickets {
          __typename
          sendMessage(
            data: $data
          ) {
            id
            __typename
          }
        }
      }
      `, { data }
    );
  }
}
