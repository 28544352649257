<section class="container1">
  <div style="min-height: 4px">
    <mat-progress-bar *ngIf="loading" mode="query"></mat-progress-bar>
  </div>
  <div class="container2">
    <div class="center">
      <form [formGroup]="passwordForm" (submit)="sendCode()">
        <mat-card class="boxed">
          <mat-card-title>{{ 'Forgot Password' | translate }}</mat-card-title>
          <mat-card-subtitle>{{ 'Please enter your email address' | translate }}</mat-card-subtitle>
          <mat-card-content>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'Email' | translate }}</mat-label>
              <input matInput required formControlName="username" /><mat-hint
                *ngIf="!passwordForm.get('username').valid && passwordForm.get('username').touched">
                {{ 'Please enter a valid email' | translate }}
              </mat-hint>
            </mat-form-field>
          </mat-card-content>
          <mat-action-row>
            <button mat-raised-button [disabled]="disabled" class="reset-btn" color="primary" type="submit"
              [disabled]="!passwordForm.valid">
              {{ 'Reset Password' | translate }}
            </button>
          </mat-action-row>
        </mat-card>
      </form>
    </div>
  </div>


  <!-- <div class="center">
      <form class="login" [formGroup]="passwordForm">
        <h2>{{ 'Forgot Password' | translate }}</h2>
        <h5>{{ 'Please enter your email address.' | translate }}</h5>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Email' | translate }}</mat-label>
          <input matInput required formControlName="username" /><mat-hint
            *ngIf="!passwordForm.get('username').valid && passwordForm.get('username').touched">
            {{ 'Please enter a valid email' | translate }}
          </mat-hint>
        </mat-form-field>

        <div class="btn">
          <button mat-raised-button [disabled]="disabled" class="reset-btn" color="primary" type="submit"
            [disabled]="!passwordForm.valid" (click)="sendCode()">
            {{ 'Reset Password' | translate }}
          </button>
        </div>
      </form>
    </div> -->

</section>