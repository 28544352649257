<mat-stepper class="hideSteps" #stepper>
        <mat-step>
            <div class="io-steper">
                <h1>Delete your account</h1>
                <span class="text">{{ 'delete_account_info_title' | translate }}</span>
                <img src="/assets/images/delete-account.png" />
                <p [innerHTML]="'delete_account_info_desc' | translate"></p>
                    <div class="actions-bar">
                        <button mat-stroked-button color="primary" routerLink="/platform/dashboard">Cancel</button>
                        <span></span>
                        <button mat-raised-button matStepperNext color="primary">Continue</button>
                    </div>
            </div>
        </mat-step>
        <mat-step>
            <div class="io-steper">
                <h1>Delete your account</h1>
                <span>{{ 'delete_account_info_title' | translate }}</span>
                <p>{{ 'delete_account_info_user_actions' | translate }}</p>
                <p>{{ 'delete_account_confirmation_desc' | translate }}</p>
                <mat-form-field class="width-100">
                    <mat-label>Password</mat-label>
                    <input matInput [formControl]="password" minlength="6" [type]="hide ? 'password' : 'text'" />
                        <button mat-icon-button matSuffix [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide"
                        type="button">
                            <mat-icon (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                  </button>
                </mat-form-field>
                    <div class="actions-bar">
                        <button mat-stroked-button color="primary" routerLink="/platform/dashboard" 
                        [disabled]="deleteButtonDisabled">Cancel</button>
                        <span></span>
                        <button mat-raised-button color="primary" (click)="confirmIdentity()" 
                        [disabled]="password.invalid || deleteButtonDisabled">Delete Account</button>
                    </div>
            </div>
        </mat-step>
        <mat-step>
            <div class="io-steper">
                <h1>Delete your account</h1>
                <span>{{ 'delete_account_info_title' | translate }}</span>
                <img src="/assets/images/delete-account1.png" />
                <p *ngIf="deleteAccountResponse?.infoMessage">{{ deleteAccountResponse?.infoMessage | translate }}</p>
                <button mat-raised-button matStepperNext color="primary" (click)="navigateAfterDelete()">OK</button>
            </div>
        </mat-step>
</mat-stepper>