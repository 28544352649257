
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { BaseService } from './base.service';
import { KycLogType } from 'src/app/_graphql/schema';


@Injectable({
  providedIn: 'root'
})
export class AdminKycLogsService extends BaseService<KycLogType>{

  selectOneFields = gql`
    fragment KycLogSelectOneFields on KYCLogType  {
      id note created
      request {
        address { addressLine1 addressLine2 city country streetNumber streetType territory unitNumber zip }
        driverLicence { dateOfExpiry documentBackId documentFrontId number state }
        medicare { color dateOfExpiry documentBackId documentFrontId number reference }
        passport { country dateOfExpiry dateOfIssue documentBackId documentFrontId number }
        personDetails { birthDate firstName gender lastName middleName moovTermsToken phone socialNumber socialNumberType }
      }
    }
  `;

  selectAllFields = gql`
    fragment KycLogSelectAllFields on KYCLogType  {
      id note created
      request {
        address { addressLine1 addressLine2 city country streetNumber streetType territory unitNumber zip }
        driverLicence { dateOfExpiry documentBackId documentFrontId number state }
        medicare { color dateOfExpiry documentBackId documentFrontId number reference }
        passport { country dateOfExpiry dateOfIssue documentBackId documentFrontId number }
        personDetails { birthDate firstName gender lastName middleName moovTermsToken phone socialNumber socialNumberType }
      }
      requests {
        endpoint
        id
        note
        request
        response
        service
        success
      }
      response
      service
      success
    }`;

  constructor(protected apollo: Apollo) {
    super(apollo);
    this.mainScope = "usersManagement";
    this.initGql('kycLog');
  }


}
