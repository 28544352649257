import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Subscribable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CorridorType, CurrencyType, DocumentCategoryType } from 'src/app/_graphql/schema';
import { BaseService } from './admin/base.service';


@Injectable({
  providedIn: 'root'
})
export class SystemService extends BaseService<CorridorType>{


  constructor(protected apollo: Apollo) {
    super(apollo);
    // this.mainScope = "system";
    // this.initGql('system');
  }

  private documentCategoriesWQ = null;
  public documentCategories(): any {
    if (!this.documentCategoriesWQ)
      this.documentCategoriesWQ = this.apollo
        .watchQuery({
          query: gql` query documentCategories { system { documentCategories { code id name } } } `
        })
    return this.documentCategoriesWQ
      .valueChanges.pipe(
        map((result: any) => result.data && result.data.system && result.data.system.documentCategories)
      );
  }

  private flagsWQ = [];
  public flags(level: string, all: boolean = false): any {
    var key = (level || "none") + (all ? 'all' : '')
    if (!this.flagsWQ[key])
      this.flagsWQ[key] = this.apollo
        .watchQuery({
          query: gql` query flags($level: FlagLevel, $all: Boolean) { system { flags(level: $level, all: $all) { code id isWarning level name presentedCode presentedColor } } } `,
          variables: { level, all }
        })
    return this.flagsWQ[key]
      .valueChanges.pipe(
        map((result: any) => result.data && result.data.system && result.data.system.flags)
      );
  }

  private paymentNetworksWQ = null;
  public paymentNetworks(): any {
    if (!this.paymentNetworksWQ) this.paymentNetworksWQ = this.apollo
      .watchQuery({
        query: gql` query paymentNetworks { system { paymentNetworks { code extendedName id name } } } `
      });
    return this.paymentNetworksWQ.valueChanges.pipe(
      map((result: any) => result.data && result.data.system && result.data.system.paymentNetworks)
    );
  }
  private paymentServicesWQ = null;
  public paymentServices(): any {
    if (!this.paymentServicesWQ)
      this.paymentServicesWQ = this.apollo
        .watchQuery({
          query: gql` query paymentServices { system { paymentServices { code id name } } } `
        })
    return this.paymentServicesWQ
      .valueChanges.pipe(
        map((result: any) => result.data && result.data.system && result.data.system.paymentServices)
      );
  }

  private transactionStatusesWQ = null;
  public transactionStatuses(): any {
    if (!this.transactionStatusesWQ)
      this.transactionStatusesWQ = this.apollo
        .watchQuery({
          query: gql` query transactionStatuses { system { transactionStatuses { id name } } } `
        })
    return this.transactionStatusesWQ
      .valueChanges.pipe(
        map((result: any) => result.data && result.data.system && result.data.system.transactionStatuses)
      );
  }

  private translationTagsWQ = null;
  public translationTags(): any {
    if (!this.translationTagsWQ)
      this.translationTagsWQ = this.apollo
        .watchQuery({
          query: gql` query translationTags { system { translationTags } } `
        })
    return this.translationTagsWQ
      .valueChanges.pipe(
        map((result: any) => result.data && result.data.system && result.data.system.translationTags)
      );
  }


}
