import { Component, OnInit, OnDestroy } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { Location } from '@angular/common';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { MessagesService } from '../../_services/messages.service';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { PublicService } from 'src/app/_services/public.service';
import { Subscribable } from 'rxjs';
import { DocumentType, FileType } from 'src/app/_graphql/schema';
import { EndUserService } from 'src/app/_services/user/enduser.service';
import { EnduserEditComponent } from './enduser-edit/enduser-edit.component';

@Component({
  selector: 'app-enduser-profile',
  templateUrl: './enduser-profile.component.html',
  styleUrls: ['./enduser-profile.component.scss']
})
export class EnduserProfileComponent implements OnInit {

  documents$: Subscribable<DocumentType[]>
  files$: Subscribable<FileType[]>
  user$: any;

  disablePersonalEdit = true;



  constructor(
    private service: EndUserService,
    public publicService: PublicService,
    public ms: ModalLauncherService,
    private dialog: MatDialog,
    private location: Location,
    public auth: AuthenticationService,
    public messagesService: MessagesService
  ) {
    this.user$ = this.service.currentUser();
    this.documents$ = this.service.getDocuments();
    this.files$ = this.service.getFiles();
  }

  ngOnInit() {
    // this.user$ = this.usersService.getEndUser().subscribe(user => {
    //   this.user = user;

    //   this.country = this.user.country;
    //   if (this.country.isoCode3 === 'AUS') {
    //     this.ausState = false;
    //   }
    // });

    var el = document.querySelector('mat-drawer-content');
    if (el) {
      el.scrollTop = 0;
    }

    // this.usersService.getCustomProperties(this.userId, "QUESTIONNAIRE").subscribe(data => {
    //   this.questionnaire = data;
    // })
  }

  editProfile(user) {
    const dialogRef = this.dialog.open(EnduserEditComponent, {
      width: '600px',
      data: { user, disablePersonalEdit: this.disablePersonalEdit },
      maxWidth: '95%',
      disableClose: true,
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }


  getBack() {
    this.location.back();
  }


}
