import { Component, Inject, OnInit } from '@angular/core';
import { UiService } from 'src/app/_services/ui.service';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SystemService } from 'src/app/_services/system.service';
import { forkJoin, Subscribable } from 'rxjs';
import { AdminLanguagesService } from 'src/app/_services/admin/admin-languages.service';
import { LanguageType, TranslationInputType } from 'src/app/_graphql/schema';
import { PublicService } from 'src/app/_services/public.service';
import { AdminTranslationsService } from 'src/app/_services/admin/admin-translations.service';

@Component({
  selector: 'app-trans-item',
  templateUrl: './trans-item.component.html',
  styleUrls: ['./trans-item.component.scss']
})
export class TranslationsItemComponent implements OnInit {
  translationTags$: Subscribable<any>
  languages: LanguageType[] = [];
  form: UntypedFormGroup = this.fb.group(
    {
      key: [{ value: this.data?.key, disabled: (this.data?.key ? true : false) }, [Validators.required]],
      tags: [null],
      langs: new UntypedFormArray([]),
    }
  )
  constructor(
    private ui: UiService,
    private service: AdminTranslationsService,
    public langService: AdminLanguagesService,
    public systemService: SystemService,
    public publicService: PublicService,
    private dialogRef: MatDialogRef<TranslationsItemComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.translationTags$ = this.systemService.translationTags();
    var s = this.langService.dropdownAll().subscribe({
      next: languages => {
        this.fetchExistingData(languages)
        s.unsubscribe()
      }
    })
  }
  fetchExistingData(languages: LanguageType[]) {
    if (!this.data?.key) {
      languages.forEach(language => {
        this.addLanguage(language);
      });
    } else {
      this.form.disable()
      var lp = Object.assign({}, this.service.queryAllParams);
      this.service.queryAllParams = { skip: 0, take: null }
      let k = this.service.all(null, false, 'allTrans').subscribe({
        next: translations => {
          k.unsubscribe();
          var reformatData = {};
          var firstTags = true;

          translations.filter(translation => (translation?.key == this.data.key && translation.language)).forEach(translation => {
            if (firstTags && translation?.tags?.length) {
              this.form.patchValue({ tags: translation.tags })
              firstTags = false;
            }
            reformatData['a' + translation?.language?.id] = {
              languageId: translation.language.id,
              value: translation.value,
              id: translation.id
            }
          })
          this.service.queryAllParams = lp;
          languages.forEach(language => {

            this.addLanguage({
              id: language.id,
              isoCode3: language.isoCode3,
              name: language.name,
              translationValue: reformatData?.['a' + language.id]?.value || null,
              translationId: reformatData?.['a' + language.id]?.id || null,
            });
          });
          this.enableForm();
        }
      });
    }
  }
  enableForm() {
    this.form.enable()
    var fieldKey = this.form.get('key');
    if (this.data.key)
      fieldKey.disable()
  }
  ngOnInit() {

  }

  addLanguage(item: any) {
    var langs = this.form.get('langs') as UntypedFormArray;
    langs.push(this.fb.group({
      languageId: [item?.id, [Validators.required]],
      _isoCode3: [item?.isoCode3, [Validators.required]],
      _countryName: [item?.name, [Validators.required]],
      value: [item?.translationValue, []],
      id: [item?.translationId, []],
    }));
  }

  hasEnteredData() {
    var hasData = false;
    var langs = this.form.get('langs') as UntypedFormArray;
    langs.value.forEach(element => {
      if (element.value)
        hasData = true;
    });
    return hasData;

  }


  onSubmit() {
    if (!this.form.valid)
      return;
    const tasks = [];
    var data = this.form.getRawValue();
    data.langs.forEach(ln => {
      var k: TranslationInputType = {
        id: ln?.id,
        key: data.key.trim(),
        value: ln?.value ?? "",
        tags: data.tags?.join(','),
        languageId: ln?.languageId
      }
      if (ln?.value)
        tasks.push(k?.id ? this.service.modify(k) : this.service.create(k))
    });
    if (tasks.length == 0)
      return;
    this.form.disable();
    forkJoin(tasks).subscribe({
      next: x => {
        this.ui.snack('Translation saved');
        this.service.refetchData('allTrans');
        this.enableForm();
        this.dialogRef.close();
      },
      error: e => {
        this.ui.snack(e?.message);
        this.enableForm();
      }
    }
    );
  }
  // onDelete() {
  //   if (!this.form.valid)
  //     return;
  //   const toDelete = [];
  //   var data = this.form.getRawValue();
  //   data.langs.forEach(ln => {
  //     if (ln?.id)
  //       toDelete.push(ln?.id)
  //   });
  //   this.service.delete(toDelete).subscribe({
  //     next: x => {
  //       this.service.refetchData('allTrans');
  //       this.form.enable();
  //       this.dialogRef.close();
  //     }
  //   })
  //   this.form.disable();
  // }
}
