import { Component, OnDestroy } from '@angular/core';
import { Subscribable } from 'rxjs';
import { UiService } from 'src/app/_services/ui.service';
import { AdminTicketsService } from 'src/app/_services/admin/admin-tickets.service';
import { FlagType, RaisedFlagType, TicketType } from 'src/app/_graphql/schema';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagesService } from 'src/app/_services/messages.service';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { AdminFlagsService } from 'src/app/_services/admin/admin-flags.service';

@Component({
  selector: 'app-admin-ticket-and-flags-overview',
  templateUrl: './admin-ticket-and-flags-overview.component.html',
  styleUrls: ['./admin-ticket-and-flags-overview.component.scss']
})
export class AdminTicketsAndFlagsOverviewComponent implements OnDestroy {
  itemId: string;
  tickets$: Subscribable<TicketType[]>
  flags$: Subscribable<RaisedFlagType[]>
  flagsActive$: Subscribable<RaisedFlagType[]>
  flagsTotal$: Subscribable<RaisedFlagType[]>
  ticketsActive$: Subscribable<TicketType[]>
  ticketsTotal$: Subscribable<TicketType[]>

  adminTicketsServiceQueryAllParamsTake = 0
  adminFlagsServiceQueryAllParamsTake = 0
  constructor(
    public adminTicketsService: AdminTicketsService,
    public adminFlagsService: AdminFlagsService,
    public auth: AuthenticationService,
    private ui: UiService,
    public router: Router,
  ) {
    this.adminTicketsServiceQueryAllParamsTake = this.adminTicketsService.queryAllParams.take;
    this.adminFlagsServiceQueryAllParamsTake = this.adminFlagsService.queryAllParams.take;
    this.adminTicketsService.queryAllParams.take = 10;
    this.adminFlagsService.queryAllParams.take = 10;
    this.tickets$ = this.adminTicketsService.all({ statuses: ["INITIATED", "RESPONDED", "MORE_INFO_NEEDED"] }, false, 'dashTickets');
    this.flags$ = this.adminFlagsService.all({ resolved: false }, false, 'dashFlags');
    this.ui.setTitle("Flags & Tickets Overview");
    this.flagsActive$ = this.adminFlagsService.statsActive();
    this.flagsTotal$ = this.adminFlagsService.statsTotal();
    this.ticketsActive$ = this.adminTicketsService.statsActive();
    this.ticketsTotal$ = this.adminTicketsService.statsTotal();

  }
  ngOnDestroy(): void {
    this.adminTicketsService.queryAllParams.take = this.adminTicketsServiceQueryAllParamsTake;
    this.adminFlagsService.queryAllParams.take = this.adminFlagsServiceQueryAllParamsTake;
  }

  openTicketItem(item: TicketType) {
    this.router.navigate(['/', 'admin', 'tickets-and-flags', 'tickets', 'ticket', item.id])
  }

  navigateTo(destination: string) {
    switch (destination) {
      case "ticketsActive":
        var ticketsActive = {
          state: {
            formData: {
              query: null,
              userId: null,
              statuses: [],
              notResolved: true,
              transactionId: null
            }
          }
        }
        this.router.navigateByUrl('/admin/tickets-and-flags/tickets', ticketsActive);
        break;
      case "ticketsTotal":
        var ticketsTotal = {
          state: {
            formData: {
              query: null,
              userId: null,
              statuses: [],
              notResolved: null,
              transactionId: null
            }
          }
        }
        this.router.navigateByUrl('/admin/tickets-and-flags/tickets', ticketsTotal);
        break;

      case "flagsActive":
        var flagsActive = {
          state: {
            formData: {
              userId: null,
              level: null,
              resolved: false,
              hasNoTicket: null,
              transactionId: null
            }
          }
        }
        this.router.navigateByUrl('/admin/tickets-and-flags/flags', flagsActive);
        break;
      case "flagsTotal":
        var flagsTotal = {
          state: {
            formData: {
              userId: null,
              level: null,
              resolved: null,
              hasNoTicket: null,
              transactionId: null
            }
          }
        }
        this.router.navigateByUrl('/admin/tickets-and-flags/flags', flagsTotal);
        break;

    }
  }

}
