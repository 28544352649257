import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatDrawer } from '@angular/material/sidenav';


@Injectable({
  providedIn: 'root'
})
export class DrawerService {
  public matDrawer: MatDrawer;

  public sendState = new BehaviorSubject(false);

  setDrawer(drawer: MatDrawer) {
    // if (this.matDrawer)
    this.matDrawer = drawer;
  }

  toggle() {
    if (this.matDrawer)
      this.matDrawer.toggle();
  }
  close() {
    if (this.matDrawer)
      this.matDrawer.close()
  }
  open() {
    if (this.matDrawer)
      this.matDrawer.open()
  }

  displayState(x: boolean) {
    this.sendState.next(x)
  }
}