<div mat-dialog-title>
  {{ data?.title ?  data?.title : ('Resolve flag' | translate) }}
</div>
<form class="container" [formGroup]="form">
  <mat-form-field appearance="outline" *ngIf="additionalFileds">
    <mat-label>Resolve Check Result</mat-label>
    <mat-select formControlName="markPositive">
      <mat-option [value]="true">Yes, this user is person detected by check (positive result)</mat-option>
      <mat-option [value]="false">No, this user is not person detected by check (false positive result)</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{ 'Note' | translate }}</mat-label>
    <textarea matInput formControlName="note" maxlength="200"></textarea>
  </mat-form-field>
</form>
<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>{{ 'Cancel' | translate }}</button>
  <button mat-raised-button color="primary" (click)="resolveFlag()" [disabled]="!form.valid">
  {{ data?.approveLabel ?  (data?.approveLabel | translate) : 'Resolve' | translate }}</button>
</mat-dialog-actions>