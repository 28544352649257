import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormBuilder } from '@angular/forms';
import { UiService } from 'src/app/_services/ui.service';
import { AdminTicketsService } from 'src/app/_services/admin/admin-tickets.service';
import { TicketType } from 'src/app/_graphql/schema';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin-ticket-list-item',
  templateUrl: './admin-ticket-list-item.component.html',
  styleUrls: ['./admin-ticket-list-item.component.scss']
})
export class AdminTicketListItemComponent {
  @Input() item: TicketType;
  @Input() showBackBtn: boolean = false;
  @Input() isClickable: boolean = true;
  @Input() hasShadow: boolean = true;

  constructor(
    public service: AdminTicketsService,
    private fb: UntypedFormBuilder,
    public auth: AuthenticationService,
    private ui: UiService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
  }
}
