import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  items1 = [
    {
      title: 'component.faq.question1',
      content: 'component.faq.content1'
    },
    {
      title: 'component.faq.question2',
      content: 'component.faq.content2'
    },
    {
      title: 'component.faq.question3',
      content: 'component.faq.content3'
    },
    {
      title: 'component.faq.question4',
      content: 'component.faq.content4'
    },
    {
      title: 'component.faq.question5',
      content: 'component.faq.content5'
    }
  ];

  items2 = [
    {
      title: 'component.faq.question6',
      content: 'component.faq.content6'
    },
    {
      title: 'component.faq.question7',
      content: 'component.faq.content7'
    },
    {
      title: 'component.faq.question8',
      content: 'component.faq.content8'
    },
    {
      title: 'component.faq.question9',
      content: 'component.faq.content9'
    }
  ];

  items3 = [
    {
      title: 'component.faq.question10',
      content: 'component.faq.content10'
    },
    {
      title: 'component.faq.question11',
      content: 'component.faq.content11'
    }
  ];

  items4 = [
    {
      title: 'component.faq.question12',
      content: 'component.faq.content12'
    }
  ];

  items5 = [
    {
      title: 'component.faq.question13',
      content: 'component.faq.content13'
    },
    {
      title: 'component.faq.question14',
      content: 'component.faq.content14'
    },
    {
      title: 'component.faq.question15',
      content: 'component.faq.content15'
    },
    {
      title: 'component.faq.question16',
      content: 'component.faq.content16'
    },
    {
      title: 'component.faq.question17',
      content: 'component.faq.content17'
    },
    {
      title: 'component.faq.question18',
      content: 'component.faq.content18'
    },
    {
      title: 'component.faq.question19',
      content: 'component.faq.content19'
    }
  ];

}
