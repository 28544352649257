<div class="io-item io-item-shadow">
    <div class="main">
        <div class="flag">
            <app-country-flag [item]="item?.country"></app-country-flag>
        </div>
        <div class="data">
            <small>{{'Name'|translate}}</small>
            <span>{{item?.name}}&nbsp;</span>
        </div>
        <div class="data">
            <small>{{'Country'|translate}}</small>
            <span>{{ item?.country?.name }}&nbsp;</span>
        </div>
        <div class="data">
            <small>{{'City'|translate}}</small>
            <span>{{ item?.city }}&nbsp;</span>
        </div>
        <div class="data">
            <small>{{'Phone'|translate}}</small>
            <span>{{ item?.phoneCode }}&nbsp;</span>
        </div>

        <div class="data">
            <small>{{'Delivered'|translate}}</small>
            <span>{{ item.sendingCurrency }} {{ item?.moneySent }}</span>
        </div>
        <button mat-raised-button color="primary" class="btn" *ngIf="!auth.isAdmin()"
            (click)="modalLauncherService.sendMoney(item)">{{
            'Send Money'
            | translate }}</button>
        <button mat-stroked-button class="btn" *ngIf="auth.isAdmin()"
            [routerLink]="['../transactions/recipient/'+ item.id]">{{
            'Transactions'
            | translate }}</button>

        <button class="recipient-button" mat-icon-button [matMenuTriggerFor]="recipientMenu" *ngIf="!auth.isAdmin()">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #recipientMenu="matMenu">
            <button mat-menu-item [routerLink]="['/', 'platform', 'transactions', 'recipient', item.id]">
                {{ 'Transactions' | translate }}
            </button>
            <button mat-menu-item (click)="modalLauncherService.editRecipient(item.id)">
                {{ 'Edit' | translate }}
            </button>
            <button mat-menu-item (click)="modalLauncherService.deleteRecipient(item)">
                {{ 'Delete' | translate }}
            </button>
        </mat-menu>
    </div>
</div>