<div class="io-row io-item-shadow align-items-center" *ngIf="transaction">

  <div class="io-item">

    <div class="main">
      <h4>{{ transaction?.transactionId }}</h4>
      <button mat-icon-button (click)="getBack()" class="no-flex">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <div class="data">
        <small>{{transaction?.fromName}}</small>
        <span>{{transaction?.toName}}
          <!-- <s>
            <table>
              <tr>
                <td *ngIf="transaction?.transactionStatusInfo.flagCount > 0"
                  [matTooltip]="transaction?.transactionStatusInfo.flagTooltip">
                  <mat-icon>error</mat-icon>
                </td>
                <td *ngIf="transaction?.transactionStatusInfo.flagCount > 0">
                  {{transaction?.transactionStatusInfo.flagCount}}
                </td>
                <td *ngIf="transaction?.transactionStatusInfo.ticketCount > 0"
                  [matTooltip]="transaction?.transactionStatusInfo.ticketTooltip">
                  <mat-icon>local_offer</mat-icon>
                </td>
                <td *ngIf="transaction?.transactionStatusInfo?.ticketCount > 0">
                  {{transaction?.transactionStatusInfo?.ticketCount}}
                </td>
              </tr>
            </table>
          </s> -->
        </span>
      </div>
      <div class="data money">
        <small>{{ transaction?.currency }} {{ transaction?.paidAmount | number: '1.2-2' }}</small>
        <span>{{ transaction?.receivingCurrency }} {{ transaction?.receivingAmount | number: '1.2-2' }}
        </span>
      </div>
      <div class="no-flex">
        <app-transaction-status iconOnly="true" [status]="transaction?.transactionStatusInfo">
        </app-transaction-status>
      </div>
      <div class="data">
        <small>{{'Status'|translate}}</small>
        <span>{{transaction?.transactionStatusInfo.name}}</span>
      </div>
      <div class="data inbound">
        <small>{{'Inbound'|translate}}</small>
        <span>
          {{ (transaction?.inboundName == null ? 'Not defined' : transaction?.inboundName) | translate }}
          <s>{{transaction?.inboundRefNo}}</s></span>
      </div>
      <app-outbound-status *ngIf="auth.hasPermission(auth.perm.TransactionOutboundStatusManage)" [iconOnly]="true"
        [status]="transaction.outboundStatus"></app-outbound-status>
      <div class="data outbound">
        <small>{{'Outbound'|translate}}</small>
        <span>
          {{transaction.paymentNetworkName }}
          <s *ngIf="transaction?.outboundRefNo && auth.isAdmin()">
            {{transaction?.outboundRefNo}}
          </s>
        </span>
      </div>
      <div class="data no-flex" *ngIf="(nextStepButtonTitle || cancelButtonTitle) && transaction?.sender">
        <div class="actionButtons">
          <button mat-stroked-button *ngIf="nextStepButtonTitle" color="primary" (click)="onStatusClick(false)"
            class="actionButton">{{
            nextStepButtonTitle | translate }}</button>

          <button mat-raised-button *ngIf="cancelButtonTitle" color="warn" (click)="onStatusClick(true)"
            class="actionButton">{{
            cancelButtonTitle | translate}}</button>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="io-row-l io-mx-2 io-my-2">
  <div class="flex-3">
    <mat-card class="io-mb-2">
      <mat-card-title>{{ 'Amount Info' | translate }}</mat-card-title>
      <mat-card-content>
        <div class="io-row-l align-items-start">
          <table class="table-details">
            <tbody>
              <tr>
                <th>{{ 'Sending amount'| translate }}</th>
                <td>{{ transaction?.currency }} {{ transaction?.amount | number: '1.2-2' }}</td>
              </tr>
              <tr>
                <th>{{ 'Variable fee charge'| translate }}</th>
                <td>{{ transaction?.currency }} {{ transaction?.variableFeePaid | number: '1.2-2' }}</td>
              </tr>
              <tr>
                <th>{{ 'Fixed fee charge'| translate }}</th>
                <td>{{ transaction?.currency }} {{ transaction?.fixedFeePaid | number: '1.2-2' }}</td>
              </tr>
              <tr>
                <th>{{ 'Charged amount' | translate}}</th>
                <td>{{ transaction?.currency }} {{ transaction?.paidAmount | number: '1.2-2' }}</td>
              </tr>

            </tbody>
          </table>
          <table class="table-details">
            <tbody>
              <!-- <tr>
                <th matTooltip="Sending currency to check currency">{{ transaction?.currency }} to {{
                  transaction?.checkCurrency }}</th>
                <td>{{ transaction?.checkQuote }}</td>
              </tr>-->
              <tr>
                <th>Exchange rate</th>
                <td>
                  <span *ngIf="transaction">
                    {{ transaction?.currency }} 1 = {{ transaction?.receivingCurrency }} {{ transaction?.receivingQuote
                    | number: '1.6-6' }}
                  </span>
                </td>
              </tr>
              <tr>
                <th>
                  Corrected exchange rate</th>
                <td>
                  <span *ngIf="transaction">{{ transaction?.currency }} 1 = {{ transaction?.receivingCurrency }} {{
                    transaction?.quote |
                    number: '1.6-6' }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table-details">
            <tbody>
              <!-- <tr>
                <th>Transaction ID</th>
                <td>{{ transaction?.transactionId }}</td>
              </tr> -->
              <tr>
                <th>{{ 'Receiving amount'| translate }}</th>
                <td>{{ transaction?.receivingCurrency }} {{ transaction?.receivingAmount | number: '1.2-2' }}</td>
              </tr>
              <tr>
                <th>{{ 'Created' | translate}}</th>
                <td>{{ transaction?.created | timezone2 | date:'short' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="io-mb-2">
      <mat-card-title>{{ 'Inbound' | translate }}</mat-card-title>
      <mat-card-content>
        <div class="io-row-l">
          <table class="table-details">
            <tbody>
              <tr>
                <th>{{ 'Name' }}</th>
                <td>{{ transaction?.inboundName | noData:'—' }}</td>
              </tr>
              <tr>
                <th>{{ 'Status' }}</th>
                <td>{{ transaction?.inboundStatus | noData:'—' }}</td>
              </tr>
              <tr>
                <th>{{ 'Ref number' }}</th>
                <td>{{ transaction?.inboundRefNo | noData:'—' }}</td>
              </tr>
            </tbody>
          </table>
          <div class="flex-2"></div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card *ngIf="auth.hasPermission(auth.perm.TransactionViewSettlement)" class="io-mb-2">
      <mat-card-title>{{ 'Settlement Data' | translate }}</mat-card-title>
      <mat-card-content>
        <div class="io-row-l align-items-start">
          <table class="table-details">
            <tbody>
              <tr>
                <th>{{ 'Provider Name' }}</th>
                <td>{{ transaction?.settlementData?.name | noData:'—' }}</td>
              </tr>
              <tr>
                <th>{{ 'Sender ID' }}</th>
                <td>{{ transaction?.settlementData?.senderId | noData:'—' }}</td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="transaction?.settlementData?.data== null"></div>
          <div *ngIf="transaction?.settlementData?.data == null"></div>

          <table class="table-details" *ngFor="let data of transaction?.settlementData?.data">
            <tbody>
              <tr>
                <th>{{data.type}} ID</th>
                <td>{{data.id}}</td>
              </tr>
              <tr>
                <th>Amount</th>
                <td>
                  <span *ngIf="data.amount">{{data.currency}} {{data.amount | number: '1.2-2'}}</span>
                  <span *ngIf="!data.amount">{{ data.amount| noData:'—'}}</span>
                </td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{{data.status}}</td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="transaction?.settlementData?.data?.length == 1"></div>
        </div>
      </mat-card-content>
      <mat-card-footer *ngIf="transaction?.settlementData?.errors">
        <mat-action-row style="padding-left: 16px;">
          <div class="flex io-row info-box align-items-center">
            <mat-icon class="info-icon no-flex" color="warn">info</mat-icon>
            <div> Error: <span *ngFor="let error of transaction?.settlementData?.errors">{{error}}</span>
            </div>
          </div>
          <!-- <button mat-raised-button color="primary">Change status</button> -->
        </mat-action-row>
      </mat-card-footer>


    </mat-card>

    <mat-card class="io-mb-2">
      <mat-card-title>{{ 'Outbound' | translate }}</mat-card-title>
      <mat-card-content>
        <div class="io-row-l align-items-start">
          <table class="table-details">
            <tbody>
              <tr>
                <th>{{ 'Outbound' }}</th>
                <td>{{ transaction?.paymentNetwork?.extendedName | noData:'—' }}</td>
              </tr>
              <tr>
                <th>{{ 'Payment Service' }}</th>
                <td>{{ transaction?.paymentServiceName | noData:'—'}}</td>
              </tr>
              <tr>
                <th>{{ 'Status' }}</th>
                <td>{{ transaction?.outboundStatus | noData:'—' }}</td>
              </tr>
            </tbody>
          </table>
          <table class="table-details">
            <tbody>
              <tr *ngIf="transaction?.paymentNetworkCode === 'MANUAL'">
                <th>{{ 'Payout Agent' }}</th>
                <td>{{ transaction?.payoutAgentName | noData:'—' }}</td>
              </tr>
              <tr>
                <th>{{ 'Ref Number' }}</th>
                <td>{{ transaction?.outboundRefNo | noData:'—' }}</td>
              </tr>
              <tr>
                <th>{{ 'Remarks' }}</th>
                <td>
                  <span *ngIf="!transaction?.remarks">—</span>
                  <div *ngFor="let item of transaction?.remarks?.split('\\n')">{{item.replace("\\r", "")}}</div>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table-details">
            <tbody *ngIf="transaction?.paymentNetworkCode && transaction?.paymentNetworkCode != 'MANUAL'">
              <tr>
                <th>{{ 'Amount' }}</th>
                <td>
                  {{ transaction?.outboundSettlementCurrency }}
                  {{ transaction?.outboundSettlementAmount | noData:'—' }}
                </td>
              </tr>
              <tr>
                <th>{{ 'Exchange Rate' }}</th>
                <td>{{ transaction?.outboundSettlementQuote?.fromCurrency }} 1
                  = {{ transaction?.outboundSettlementQuote?.toCurrency }} {{
                  transaction?.outboundSettlementQuote?.amount
                  | number: '1.6-6' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card-content>
      <mat-card-footer
        *ngIf="auth.hasPermission(auth.perm.TransactionOutboundStatusManage) && transaction?.paymentNetworkCode == 'MANUAL'">
        <mat-action-row>
          <button mat-raised-button color="primary"
            [disabled]="!(['Stopped', 'Delivered'].indexOf(transaction?.outboundStatus) != -1)"
            (click)="ms.outboundUpdateStatus(transaction, transaction?.outboundStatus == 'Stopped'?'Approved':'Reimbursed')">Change
            status</button>
        </mat-action-row>
      </mat-card-footer>
    </mat-card>

    <mat-card class="io-mb-2">
      <mat-card-title>{{ 'Document' | translate }}</mat-card-title>
      <mat-card-content>
        <div class="io-row-l align-items-start">
          <table class="table-details">
            <tbody>
              <tr>
                <th>{{ 'Type' }}</th>
                <td>{{ transaction?.sDocTypeName | noData:'—'}}</td>
              </tr>
              <tr>
                <th>{{ 'Number' }}</th>
                <td>{{ transaction?.sDocNumber | noData:'—' }}</td>
              </tr>
              <tr>
                <th>{{ 'Expiration Date' }}</th>
                <td>{{ transaction?.sDocExp | date | noData:'—' }}</td>
              </tr>
            </tbody>
          </table>
          <table class="table-details">
            <tbody>
              <tr>
                <th>{{ 'Issuer' }}</th>
                <td>{{ transaction?.sDocIssuer | noData:'—' }}</td>
              </tr>
              <tr>
                <th>{{ 'Source' }}</th>
                <td>{{ transaction?.sDocSource | noData:'—' }}</td>
              </tr>
            </tbody>
          </table>
          <div></div>
        </div>
        <div class="io-row" *ngIf="transaction && !updateDocument">
          <div>
            <div class="io-row align-items-center io-mt-3 io-mb-1">
              <mat-icon class="no-flex">info</mat-icon>
              Updated data available:
            </div>
            <table class="table-details">
              <tbody>
                <tr *ngIf="transaction?.sDoc?.documentNumber != transaction?.sDocNumber">
                  <th>Number </th>
                  <td>{{transaction?.sDoc?.documentNumber}}</td>
                </tr>
                <tr *ngIf="transaction?.sDoc?.documentExp != transaction?.sDocExp">
                  <th>Expiration Date </th>
                  <td>{{transaction?.sDoc?.documentExp | date}}</td>
                </tr>
                <tr *ngIf="transaction?.sDoc?.documentIssuer != transaction?.sDocIssuer">
                  <th>Issuer </th>
                  <td>{{transaction?.sDoc?.documentIssuer}}</td>
                </tr>
                <tr *ngIf="transaction?.sDoc?.category?.name != transaction?.sDocTypeName">
                  <th>Type </th>
                  <td>{{transaction?.sDoc?.category?.name}}</td>
                </tr>
                <tr *ngIf="transaction?.sDoc?.documentSource != transaction?.sDocSource">
                  <th>Source </th>
                  <td>{{transaction?.sDoc?.documentSource}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div></div>
          <div></div>
        </div>
      </mat-card-content>
      <mat-card-footer>
        <mat-action-row>
          <button mat-raised-button color="primary" (click)="updateDocuments()"
            [disabled]="transaction?.status?.id > '2' || updateDocument || !transaction?.sender">Update
            Document</button>
          <button mat-raised-button color="primary"
            (click)="ms.openDocuments(transaction.sender.id, transaction.id, transaction.sDocId)"
            [disabled]="transaction?.status?.id > '2' || !transaction?.sender">Replace Document</button>
        </mat-action-row>
      </mat-card-footer>
    </mat-card>
  </div>
  <div class="flex">
    <div class="io-mb-2">
      <!-- <mat-expansion-panel class="sender-receiver-card" *ngIf="transaction">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="io-row align-items-center" style="width:100%">
              <app-country-flag [item]="{isoCode3: transaction?.fromIsoCode3 }" class="no-flex"
                [size]="'big'"></app-country-flag>
              <div>
                <small>{{ 'Sender' | translate }}</small>
                {{ transaction?.sender?.firstName }} {{ transaction?.sender?.lastName }}
              </div>
              <div class="no-flex" *ngIf="((((flags$|async) |filterOn:['level']:['USER'])).length - (((flags$|async)
              |filterOn:['level']:['USER'])|filterOn:['resolved']:[null]:'exclude').length) as flagNum">
                <button mat-icon-button>
                  <mat-icon matBadgeColor="warn" matBadgeSize="small" [matBadge]="flagNum">local_offer</mat-icon>
                </button>
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <form-data-display-configuration [columns]="1" [hideEmptyFields]="true" fieldsGroup="updateUser"
          [country]="transaction.sender.country.isoCode2" [data]="{ addressLine1: transaction?.userAddressLine1,
            socialNumber: transaction?.userSocialNumber,
            addressLine2: transaction?.userAddressLine2,
            city: transaction?.userCity,
            country: transaction?.userCountry,
            birthDate: transaction?.userBirthDate,
            email: transaction?.userEmail,
            firstName: transaction?.userFirstName,
            id: transaction?.userId,
            lastName: transaction?.userLastName,
            middleName: transaction?.userMiddleName,
            phone: transaction?.userPhone,
            territory: transaction?.userTerritory,
            unitNumber: transaction?.userUnitNumber,
            userName: transaction?.userUserName,
            zip: transaction?.userZip }">

        </form-data-display-configuration>
        <table class="table-details">
          <tbody>
            <tr>
              <th>{{ 'Sent' }}</th>
              <td>{{ transaction?.sender?.country?.currency }} {{ transaction?.sender?.moneySent | number: '1.2-2' }}
              </td>
            </tr>
            <tr>
              <th>{{ 'Delivered' }}</th>
              <td>{{ transaction?.sender?.country?.currency }} {{ transaction?.sender?.moneyReceived | number: '1.2-2'
                }}
              </td>
            </tr>

            <tr *ngIf="((((flags$|async) |filterOn:['level']:['USER'])).length - (((flags$|async)
              |filterOn:['level']:['USER'])|filterOn:['resolved']:[null]:'exclude').length) as flagNum">
              <th>{{ 'Raised Flags' }}</th>
              <td>{{ flagNum }}</td>
            </tr>
            <tr>
              <th>{{ 'Created' }}</th>
              <td>{{ transaction?.sender?.created | timezone2 | date:'short' }}</td>
            </tr>
          </tbody>
        </table>
        <mat-action-row>
          <button mat-raised-button color="primary" [routerLink]="['/', 'admin', 'users', transaction?.sender?.id]">View
            profile</button>
        </mat-action-row>
      </mat-expansion-panel> -->
      <app-sender-section [transaction]="transaction" [flags]="flags$ | async"></app-sender-section>
    </div>
    <div class="io-mb-2">
      <mat-expansion-panel class="sender-receiver-card" *ngIf="transaction">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="io-row align-items-center" style="width:100%">
              <app-country-flag [item]="{isoCode3: transaction?.toIsoCode3 }" class="no-flex"
                [size]="'big'"></app-country-flag>
              <div>
                <small>{{ 'Recipient' | translate }}</small>
                {{ transaction?.recipientName }}
              </div>
              <div class="no-flex" *ngIf="transaction?.canUpdateRecipientInfo">
                <button mat-icon-button>
                  <mat-icon matBadgeColor="warn" matBadgeSize="small" [matBadge]="1">change_circle</mat-icon>
                </button>
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <table class="table-details">
          <tbody>
            <tr>
              <th>{{ 'Email' }}</th>
              <td class="ellipsize" matTooltip="{{ transaction?.recipientEmail  }}"
                matTooltipClass="tooltip-width-unset">
                <span>{{ transaction?.recipientEmail | noData:'—'
                  }}</span>
              </td>
            </tr>
            <tr>
              <th>{{ 'Phone Number' }}</th>
              <td>{{ transaction?.recipientPhone }}</td>
            </tr>
            <tr>
              <th>{{ 'Address' }}</th>
              <td>{{ transaction?.recipientAddress1 }} {{ transaction?.recipientAddress2 }}</td>
            </tr>
            <tr>
              <th>{{ 'City' }}</th>
              <td>{{ transaction?.recipientCity }}</td>
            </tr>
            <tr>
              <th>{{ 'Country' }}</th>
              <td>{{ transaction?.recipientCountry }}</td>
            </tr>
            <tr>
              <th>{{ 'Relationship' }}</th>
              <td>{{ transaction?.relationshipName }}</td>
            </tr>
            <tr>
              <th>{{ 'Purpose' }}</th>
              <td>{{ transaction?.purposeName }}</td>
            </tr>
            <tr>
              <th>{{ 'Received' }}</th>
              <td>{{ transaction?.currency }} {{ transaction?.recipient?.moneySent | number: '1.2-2' }}</td>
            </tr>
            <tr>
              <th>{{ 'Created' }}</th>
              <td>{{ transaction?.recipient?.created | timezone2 | date:'short' }}</td>
            </tr>
          </tbody>
        </table>
        <mat-action-row>
          <button mat-raised-button color="primary"
            [disabled]="!transaction?.canUpdateRecipientInfo || !transaction?.sender"
            (click)="updateData()">Update</button>
        </mat-action-row>
        <div class="note"
          *ngIf="transaction?.updateRecipientInfoText.length > 0 && transaction?.canUpdateRecipientInfo">
          <mat-icon class="info-icon">info</mat-icon>
          <div>
            <p *ngFor="let note of transaction?.updateRecipientInfoText"> {{note}} </p>
          </div>
        </div>

      </mat-expansion-panel>
    </div>
    <div class="io-mb-2">
      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'Flags and Tickets' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-flag-list level="TRANSACTION" [transaction]="transaction" [items]="flags$|async"
          [userId]="userId"></app-flag-list>
        <mat-action-row>
          <button mat-raised-button color="primary" (click)="ms.raiseFlag(transaction?.sender?.id, transactionId)"
            [disabled]="!transaction?.sender">Raise
            new flag</button>
        </mat-action-row>
      </mat-expansion-panel>
    </div>
    <div class="io-mb-2">
      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'Transaction History' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-transaction-history-list [items]="($history|async)"></app-transaction-history-list>
      </mat-expansion-panel>
    </div>
    <div class="io-mb-2">
      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'Notes' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-notes-list [items]="transaction?.notes"></app-notes-list>
        <mat-action-row>
          <button mat-raised-button color="primary" (click)="ms.addNote(transaction.id)"
            [disabled]="!transaction?.sender">Add note</button>
        </mat-action-row>
      </mat-expansion-panel>
    </div>
  </div>
</div>
<!-- <pre>
  {{ transaction | json }}
</pre> -->