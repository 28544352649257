import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UiService } from 'src/app/_services/ui.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { Subscribable, Subscription, Unsubscribable } from 'rxjs';
import { Router } from '@angular/router';
import { EndUserService } from 'src/app/_services/user/enduser.service';
import { PaymentMethodsService } from 'src/app/_services/user/payment-methods.service';
import { InboundAccountType, InboundAuthTokenType, RaisedFlagType } from 'src/app/_graphql/schema';
import { UserTransactionsService } from 'src/app/_services/user/transactions.service';
import { UserFlagsService } from 'src/app/_services/user/flags.service';
import { UserTicketsService } from 'src/app/_services/user/tickets.service';
import { PublicService } from 'src/app/_services/public.service';



@Component({
  selector: 'app-accounts-list',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsListComponent implements OnInit, OnDestroy {
  $user: Subscription;
  items$: Subscribable<InboundAccountType[]>;
  showVerify = false;
  accountUnsub$: Unsubscribable;
  flagsUnsub$: Unsubscribable;
  paymentMethods$: any;

  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;

  constructor(
    private dialog: MatDialog,
    public modalLauncherService: ModalLauncherService,
    private ui: UiService,
    private router: Router,
    private auth: AuthenticationService,
    private userFlagsService: UserFlagsService,
    private userTicketsService: UserTicketsService,
    private usersService: EndUserService,
    public service: PaymentMethodsService,
    private publicService: PublicService,
    private userTransactionsService: UserTransactionsService,
    private mls: ModalLauncherService,

  ) {

  }

  ngOnInit() {
    this.flagsUnsub$ = this.userFlagsService.all({ resolved: false }).subscribe({
      next: (x: RaisedFlagType[]) => {
        var kne = (x.filter(x => x.flag.code === 'KYC_MISSING'));
        this.showVerify = kne.length > 0
        if (!this.showVerify)
          this.items$ = this.service.all();
        this.paymentMethods$ = this.publicService.getFrontendConfiguration()
        if (this.service.changingAgreementId) {
          let unsubPaymentMethods = this.service.inboundAccount(this.service.changingAgreementId, 'PAYTO').subscribe({
            next: res => {
              if (res?.inboundAccount) {
              this.mls.newAgreement(res?.inboundAccount);
              this.service.changingAgreementId = null;
              }
              unsubPaymentMethods.unsubscribe();
            }
          })
        }
      }, error: e => { }
    });
  }
  openModal() {
    var s = this.service.generateInboundToken('MOOV').subscribe({
      next: (data: InboundAuthTokenType) => {
        s?.unsubscribe();
        if (data['generateInboundToken'].isKYCMissing) {
          this.userFlagsService.refetchData();
          this.usersService.refetchData();
          // this.router.navigateByUrl('/platform/kyc')

        } else {
          this.accountUnsub$ = this.modalLauncherService.accountAdd(data['generateInboundToken']).afterClosed().subscribe(shouldRefresh => {
            // if (shouldRefresh) {
            this.userTransactionsService.refetchData();
            // webhook is sometimes late... this should allow it to be late
            setTimeout(() => {
              this.userFlagsService.refetchData();
            }, 1500)
            this.service.refetchData();
            this.userTicketsService.refetchData();
            this.userTicketsService.refetchData('dashboard');
            this.usersService.refetchData();
            // }
          });
        }
      },
      error: (error: any) => {
        this.usersService.refetchData();
      }
    });

  }

  ngOnDestroy(): void {
    this.$user?.unsubscribe();
    this.accountUnsub$?.unsubscribe();
    this.flagsUnsub$?.unsubscribe();
  }
}
