import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ActivatedRoute, Router } from '@angular/router';
import { Unsubscribable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { InterfacePermissions } from 'src/app/_graphql/schema';
import { AuthenticationService } from 'src/app/_services/authentication.service';

import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'list-wrapper',
  templateUrl: './list-wrapper.component.html',
  styleUrls: ['./list-wrapper.component.scss'],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'standard' }, },
  ]
})
export class ListWrapperComponent implements OnInit, OnDestroy {

  queryString: UntypedFormControl = new UntypedFormControl();

  @Input('showQueryField') showQueryField = true;
  @Input('showPagination') showPagination: boolean = true;

  @Input('mainService') mainService: any;
  @Input('navigateItem') navigateItem: string;
  @Input('panelTitle') title: string;

  @Input('customKey') customKey: string = null;
  @Input('readPerm') readPerm: InterfacePermissions;
  @Input('managePerm') managePerm: InterfacePermissions;
  @Input('hideAddBtn') hideAddBtn: boolean = false;

  @Output() onNew: EventEmitter<any> = new EventEmitter();
  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  data$: Unsubscribable;
  dateFilter$: Unsubscribable;


  constructor(
    public auth: AuthenticationService,
    public ui: UiService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
  ) {

  }
  ngOnDestroy() {
    this.data$?.unsubscribe();
    this.dateFilter$?.unsubscribe();
  }

  ngOnInit(): void {

    if (this.title)
      this.ui.setTitle(this.title);
    // this.mainService.queryParams.showDeleted = false;

    // this.queryString.valueChanges.pipe(
    //   debounceTime(500),
    //   distinctUntilChanged()
    // )
    //   .subscribe(query => {
    //     this.mainService.pageIndex = 0;
    //     // this.mainService.queryParams.skip = 0;
    //     this.mainService.fetchMoreData();
    //   });
    // this.data$ =
    //   this.mainService?.all().subscribe({
    //     next: data => {
    //       if (data && data.length && data[0].hasOwnProperty('deleted')) {
    //         // this.showDeletedEnabled = true;

    //       }
    //     }, error: error => {
    //     }
    //   });
  }

  public newItem() {
    this.onNew.emit(null)
  }

  public edit(id: number | string | null | undefined) {
    if (this.auth.hasPermission(this.managePerm))
      this.onEdit.emit(id)
    // var path = [id];
    // this.router.navigate(path, { relativeTo: this.activatedRoute });
  }





}
