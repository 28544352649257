<mat-expansion-panel class="sender-receiver-card" *ngIf="transaction">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="io-row align-items-center" style="width:100%">
          <app-country-flag [item]="{isoCode3: transaction?.fromIsoCode3 }" class="no-flex"
            [size]="'big'"></app-country-flag>
          <div>
            <small>{{ 'Sender' | translate }}</small>
            {{ transaction?.userFirstName }} {{ transaction?.userLastName }}
          </div>
          <div class="no-flex" *ngIf="(((flags |filterOn:['level']:['USER'])).length - ((flags
          |filterOn:['level']:['USER'])|filterOn:['resolved']:[null]:'exclude').length) as flagNum">
            <button mat-icon-button>
              <mat-icon matBadgeColor="warn" matBadgeSize="small" [matBadge]="flagNum">local_offer</mat-icon>
            </button>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <form-data-display-configuration [columns]="1" [hideEmptyFields]="true" fieldsGroup="updateUser"
      [country]="transaction?.fromIsoCode2" [data]="{ addressLine1: transaction?.userAddressLine1,
        socialNumber: transaction?.userSocialNumber,
        addressLine2: transaction?.userAddressLine2,
        city: transaction?.userCity,
        country: transaction?.userCountry,
        birthDate: transaction?.userBirthDate,
        email: transaction?.userEmail,
        firstName: transaction?.userFirstName,
        id: transaction?.userId,
        lastName: transaction?.userLastName,
        middleName: transaction?.userMiddleName,
        phone: transaction?.userPhone,
        territory: transaction?.userTerritory,
        unitNumber: transaction?.userUnitNumber,
        userName: transaction?.userUserName,
        zip: transaction?.userZip }">

    </form-data-display-configuration>
    <table class="table-details">
      <tbody>
        <tr>
          <th>{{ 'Sent' }}</th>
          <td>{{ transaction?.from?.currency }} {{ transaction?.sender?.moneySent | number: '1.2-2' }}
          </td>
        </tr>
        <tr>
          <th>{{ 'Delivered' }}</th>
          <td>{{ transaction?.from?.currency }} {{ transaction?.sender?.moneyReceived | number: '1.2-2'
            }}
          </td>
        </tr>
        <tr *ngIf="(((flags |filterOn:['level']:['USER'])).length - ((flags
          |filterOn:['level']:['USER'])|filterOn:['resolved']:[null]:'exclude').length) as flagNum">
          <th>{{ 'Raised Flags' }}</th>
          <td>{{ flagNum }}</td>
        </tr>
        <tr>
          <th>{{ 'Created' }}</th>
          <td>{{ transaction?.sender?.created | timezone2 | date:'short' }}</td>
        </tr>
      </tbody>
    </table>
    <mat-action-row>
      <button mat-raised-button color="primary" [routerLink]="['/', 'admin', 'users', transaction?.sender?.id]"
      [disabled]="!transaction?.sender">View profile</button>
    </mat-action-row>
  </mat-expansion-panel>