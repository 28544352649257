import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../../../_services/authentication.service';
import { UiService } from 'src/app/_services/ui.service';
import { FilesUploadComponent } from '../files-upload/files-upload.component';
import { AdminTicketsService } from 'src/app/_services/admin/admin-tickets.service';
import { PublicService } from 'src/app/_services/public.service';
import { AdminDocumentsService } from 'src/app/_services/admin/admin-documents.service';

@Component({
    selector: 'app-files-list',
    templateUrl: './files-list.component.html',
    styleUrls: ['./files-list.component.scss']
})
export class FilesListComponent implements OnInit {

    @Input() userId: any;
    @Input() ticketId: any;
    @Input() files: any;
    @Input('newButtonHide') newButtonHide: boolean;

    isAdminMode: boolean;
    i = true;
    first = false;

    constructor(
        private publicService: PublicService,
        private adminTicketsService: AdminTicketsService,
        private adminDocumentsService: AdminDocumentsService,
        private dialog: MatDialog,
        private auth: AuthenticationService,
        private ui: UiService) {
        this.isAdminMode = auth.isAdmin();
    }



    ngOnInit() { }

    downloadDocument(file: any) {
        this.publicService.downloadFile(file.id, file.name);
    }


    editDocument(userId: any, file: any = null) {
        const dialogRef = this.dialog.open(FilesUploadComponent, {
            width: '600px',
            disableClose: true,
            data: { userId: userId, file: { ...file }, changeDocument: false },
            maxWidth: '95%',
            height: 'auto'
        });

        dialogRef.afterClosed().subscribe(result => {
            this.adminDocumentsService.refetchData();
            this.adminTicketsService.refetchOneData(this.ticketId);
        });
    }

}
