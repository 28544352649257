import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RelationshipsItemComponent } from './relationships-item/relationships-item.component';
import { AdminRelationshipsService } from 'src/app/_services/admin/admin-relationships.service';
import { Subscribable } from 'rxjs';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { RelationshipType } from 'src/app/_graphql/schema';

@Component({
  selector: 'app-relationships',
  templateUrl: './relationships.component.html',
  styleUrls: ['./relationships.component.scss']
})
export class RelationshipsComponent {
  items$: Subscribable<any>;


  constructor(
    public auth: AuthenticationService,
    private dialog: MatDialog,
    public service: AdminRelationshipsService,
  ) {
    this.items$ = this.service.all()
  }

  public newItem() {
    this.openDialog(RelationshipsItemComponent);
  }

  editItem(data: RelationshipType) {
    this.openDialog(RelationshipsItemComponent, data);
  }

  openDialog(component: any, param = {}) {
    this.dialog.open(component, {
      disableClose: true,
      height: 'auto',
      width: '360px',
      data: param
    });
  }
}
