import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CorridorItemComponent } from './corridor-item/corridor-item.component';
import { AuthenticationService } from '../../_services/authentication.service';
import { UiService } from '../../_services/ui.service';
import { Subscribable, Subscription, Unsubscribable } from 'rxjs';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AdminCorridorsService } from 'src/app/_services/admin/admin-corridors.service';
import { CorridorType } from 'src/app/_graphql/schema';
import { AdminCountriesService } from 'src/app/_services/admin/admin-countries.service';
import { SystemService } from 'src/app/_services/system.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-corridors',
  templateUrl: './corridors.component.html',
  styleUrls: ['./corridors.component.scss']
})
export class CorridorsComponent implements OnInit, OnDestroy, AfterViewInit {

  items$: Subscribable<CorridorType[]>;
  items: CorridorType[];

  corridors: any;
  corridors$: Subscription;
  dialogRef: any;

  countrySenders$: any;
  countryReceivers$: any;
  paymentServices$: any;
  paymentNetworks$: any;

  errors: any[] = [];

  form: UntypedFormGroup = this.fb.group({
    deleted: [null],
    active: [null],
    fromIds: [null],
    toIds: [null],
    paymentServiceIds: [null],
    paymentNetworkIds: [null],
  });
  form$: Unsubscribable;
  $items: Unsubscribable;

  constructor(
    public service: AdminCorridorsService,
    public systemService: SystemService,
    public countries: AdminCountriesService,
    private router: Router,
    private ui: UiService,
    public auth: AuthenticationService,
    private fb: UntypedFormBuilder,
  ) {
    this.items$ = this.service.all();
    this.countrySenders$ = this.countries.dropdownAll({ isSender: true }, true, 'senders');
    this.countryReceivers$ = this.countries.dropdownAll({ isRecipient: true }, true, 'receivers');

    this.paymentNetworks$ = this.systemService.paymentNetworks();
    this.paymentServices$ = this.systemService.paymentServices();
  }

  ngAfterViewInit() {
    // var filters = this.service.getPageRequestAllFilters()
    // this.form.patchValue(filters.pageRequest?.filters, { emitEvent: false })
  }

  ngOnInit() {
    this.getData();
    this.form$ = this.form.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(data => {
      this.getData();
    });
  }

  getData() {
    this.service.queryAllParams.skip = 0;
    this.service.pageIndex = 0;
    this.$items?.unsubscribe();
    this.service.allClose();
    this.$items = this.service.all(this.form.value, true, null, false).subscribe({
      next: res => {
        this.items = res;
      }
    })
  }

  setError(id) {
    this.errors.push(id)
  }

  hasError(id) {
    return this.errors.indexOf(id) > -1
  }

  newItem() {
    this.router.navigate(['/', 'admin', 'corridors', 'new'])
  }

  editItem(corridor: any) {
    this.router.navigate(['/', 'admin', 'corridors', corridor.id])
  }

  ngOnDestroy() {
    this.form$?.unsubscribe();
    this.$items?.unsubscribe();

  }
}
