<div *ngIf="mode == 'review'">
    <h3 class="recipient-name">{{ 'Review your transaction' | translate }}</h3>
</div>

<div *ngIf="mode == 'noCorridor'" class="no-corridor io-row align-items-center">
    <div>
        <mat-dialog-content>
            <mat-icon class="error">info</mat-icon>
            <h4 class="info-description">{{ 'This service is not available in' | translate }} {{
                data.recipient.country.name
                }}</h4>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button class="info-actions" mat-raised-button color="primary" mat-dialog-close>{{'OK' |
                translate}}</button>
        </mat-dialog-actions>
    </div>
</div>

<div *ngIf="mode == 'calculator' || mode == 'public'"
    [ngClass]="{ calculator: !data, modal: true, 'add-margin': !data }">
    <h3 *ngIf="mode == 'public'" [ngClass]="{ 'recipient-name': data?.recipient?.name }">{{ data ? 'Sending money to' :
        '' | translate }} {{ data?.recipient?.name || '' }}</h3>
    <mat-dialog-content class="dialogContent">
        <form [formGroup]="form">
        <div *ngIf="!showPreReceipt">
            <div class="io-row" *ngIf="mode == 'public'">
                <app-single-select #countryFrom [items]="countriesFrom" (selectionChange)="setFromCountry($event)"
                    placeholder="{{ 'Send money from' | translate }}" [disabled]="!!data">
                </app-single-select>
            </div>
            <div class="io-row" *ngIf="mode == 'public'">
                <app-single-select #countryTo [items]="countriesTo" (selectionChange)="setToCountry($event)"
                    placeholder="{{ 'Send money to' | translate }}" [disabled]="!!data">
                </app-single-select>
            </div>
            <div class="io-row">
                <mat-form-field>
                    <mat-label>{{ 'Select Service' | translate }}</mat-label>
                    <mat-select formControlName="serviceId" (selectionChange)="setPaymentService($event.value)">
                        <mat-option *ngFor="let service of paymentServices" [value]="service.paymentService.id">{{
                            service.paymentService.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="io-row">
                <mat-form-field>
                    <mat-label>{{ 'Select Network' | translate }}</mat-label>
                    <mat-select formControlName="networkId" (selectionChange)="setPaymentNetwork($event.value)">
                        <mat-option *ngFor="let network of paymentNetworks" [value]="network.paymentNetwork.id">{{
                            network.paymentNetwork.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="io-row" [@locationanimation]="locations.length ? 'expand' : 'collapse'">
                <mat-form-field *ngIf="locations.length">
                    <mat-label>{{ 'Select Location' | translate }}</mat-label>
                    <mat-select formControlName="locationId" (selectionChange)="setLocation($event.value)">
                        <mat-select-trigger *ngIf="locations|filterOn:['id']:[form.get('locationId')?.value] as v">
                            {{v?.[0]?.name}}
                        </mat-select-trigger>
                        <mat-option *ngFor="let location of locations" [value]="location.id"
                            class="multiline-mat-option">
                            {{ location?.name }}
                            <small>{{ location?.city }} <span *ngIf="location?.phone">&mdash; {{ location?.phone
                                    }}</span></small>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="io-row" *ngIf="auth.isLoggedIn() && data">
                <mat-form-field>
                    <mat-label>{{ 'Select Purpose' | translate }}</mat-label>
                    <mat-select formControlName="purposeId" (selectionChange)="setPurpose($event.value)">
                        <mat-option *ngFor="let purpose of purposes | async" [value]="purpose.id">
                            {{ purpose.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="amounts-section">
                <div class="io-row">
                    <mat-form-field>
                        <mat-label>{{ 'Send' | translate }}</mat-label>
                        <input matInput type="textbox" maxlength="20" placeholder="100.00" step=".01"
                        appTwoDigitDecimaNumber formControlName="sendAmount" class="right-align">
                        <span matSuffix class="currency-suffix" *ngIf="sendingCurrency">{{sendingCurrency}}</span>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{ 'Receive' | translate }}</mat-label>
                        <input matInput type="textbox" maxlength="20" placeholder="100.00" step=".01"
                             appTwoDigitDecimaNumber formControlName="receiveAmount" class="right-align">
                        <span matSuffix class="currency-suffix" *ngIf="receivingCurrency">{{receivingCurrency}}</span>
                    </mat-form-field>
                </div>

            </div>
            <div class="output" [@resultanimation]="calculationDone ? 'expand' : 'collapse'">
                <table class="index-table" *ngIf="!error">
                    <tbody>
                        <tr>
                            <th>{{ 'Send Amount' | translate }}</th>
                            <td>{{ calculation.sendAmount | number: '1.2-2' }}<a> {{sendingCurrency}}</a></td>
                        </tr>
                        <tr>
                            <th>{{ 'Fee' | translate }}</th>
                            <td>{{ calculation.fee | number: '1.2-2'}}<a> {{sendingCurrency}}</a></td>
                        </tr>
                        <tr>
                            <th>{{ 'Exchange Rate' | translate }}</th>
                            <td><a>{{sendingCurrency}} 1 = {{receivingCurrency }} </a>{{ calculation.quote | number:
                                '1.6-6'
                                }}</td>
                        </tr>
                        <div style="margin-top: 10px"></div>
                        <tr>
                            <th>{{ 'Total to Pay' | translate }}</th>
                            <td>{{ calculation.totalToPay | number: '1.2-2' }}<a> {{sendingCurrency}}</a></td>
                        </tr>
                        <tr>
                            <th>{{ 'Recipient Receives' | translate }}</th>
                            <td>{{ calculation.recipientReceives | number: '1.2-2' }}<a> {{receivingCurrency}}</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p *ngIf="error" class="error-message">
                    {{ error | translate }}
                </p>
            </div>
        </div>

        <div *ngIf="!showPreReceipt">
            <!-- <div class="btn io-row" *ngIf="!calculatorPreReceipt">
                <button *ngIf="mode !== 'public'" mat-raised-button mat-dialog-close class="cancle" color="warn">{{
                    'Cancel' | translate }}</button>
                <button mat-raised-button color="primary" class="calculator-send" (click)="loginAndPay()"
                    [disabled]="((!calculationDone || error != '') || (!auth.hasPermission(auth.perm.TransactionsCreate)))">{{ data ? 'Send' : 'Log in and
                    send' | translate
                    }}</button>
            </div> -->
            <div class="btn io-row">
                <button *ngIf="mode !== 'public'" mat-raised-button mat-dialog-close class="cancle" color="warn">{{
                    'Cancel' | translate }}</button>
                <button mat-raised-button color="primary" class="calculator-send" (click)="onShowPreReceipt()"
                    [disabled]="!calculationDone || error != ''">{{ data ? 'Next' :
                    'Log in and
                    Continue' | translate
                    }}</button>
            </div>
        </div>
        <div *ngIf="showPreReceipt">

            <app-calculator-transaction-preview
                [fromCalc]="{calc: calculation, sendingCurrency, receivingCurrency, recipient: data?.recipient}">
            </app-calculator-transaction-preview>
            <div class="btn io-row align-items-center">
                <div class="flex-3">
                    <mat-checkbox [formControl]="calculatorPreReceiptChecked" color="primary"
                        [disabled]="!calculationDone || error != ''">
                        <!-- [ngClass]="!calculatorPreReceiptCheckbox ? 'agreeFalse' : 'agreeTrue' " -->
                        <p class="checkbox-text" [innerHTML]="'iagree.register.checkbox' | translate"></p>
                    </mat-checkbox>

                </div>
                <div class="no-flex">
                    <button mat-raised-button color="primary" class="calculator-send" (click)="loginAndPay()"
                        [disabled]="((!calculationDone || error != '' || !calculatorPreReceiptChecked.value) || (!auth.hasPermission(auth.perm.TransactionsCreate)))">{{ data ?
                        'Send' :
                        'Log in and send' | translate
                        }}</button>
                </div>
            </div>
        </div>
    </form>
    </mat-dialog-content>
</div>