import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Subscribable, Subscription, Unsubscribable } from 'rxjs';
import { DrawerService } from '../wrapper/drawer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TransactionsComponentConfig } from 'src/app/_modules/transactions.module';
import { AdminTransactionType, CountryType, Recipient, TransactionStatusType } from 'src/app/_graphql/schema';
import { PublicService } from 'src/app/_services/public.service';
import { SystemService } from 'src/app/_services/system.service';
import { AdminPayoutsService } from 'src/app/_services/admin/admin-payouts.service';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';


@Component({
  selector: 'app-admin-payouts',
  templateUrl: './payouts.component.html',
  styleUrls: ['./payouts.component.scss']
})
export class AdminPayoutsComponent implements OnInit, AfterViewInit, OnDestroy {
  $items: Unsubscribable;
  items: AdminTransactionType[];
  countriesSender$: Subscribable<CountryType>;
  countriesRecipient$: Subscribable<CountryType>;
  recipients$: Subscribable<Recipient[]>;
  transactionStatuses$: Subscribable<TransactionStatusType>;
  maxDate = new Date();
  @Input() componentConfig: TransactionsComponentConfig;


  form: UntypedFormGroup = this.fb.group({
    query: [null],
    dateFrom: [null],
    dateTo: [null],
    pickupCode: []
  });
  $form: Subscription;

  constructor(
    public service: AdminPayoutsService,
    public systemService: SystemService,
    public publicService: PublicService,
    public auth: AuthenticationService,
    public mls: ModalLauncherService,
    private drawerService: DrawerService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
  ) {
    service.permissionProtected = auth.hasPermission(auth.perm.TransactionOutboundStatusManage)

    if (route.snapshot.data?.config) {
      this.componentConfig = route.snapshot.data?.config;
    }

    this.transactionStatuses$ = this.systemService.transactionStatuses();
    this.countriesSender$ = this.publicService.countries(true, null, null)
    this.countriesRecipient$ = this.publicService.countries(null, true, null)


  }
  ngOnDestroy(): void {
    this.form.reset();
    this.$form?.unsubscribe();
    this.$items?.unsubscribe();
  }

  ngAfterViewInit() {
    // var filters = this.service.getPageRequestAllFilters();
    // this.form.patchValue({ ...filters.pageRequest?.filters, recipientId: this.route.snapshot.paramMap.get('recipientId') })
  }

  ngOnInit() {
    this.drawerService.sendState.next(false);

    this.$items = this.service.all(this.form.value, false).subscribe({
      next: x => {
        if (x?.length == 1 && this.form.get('pickupCode').value && this.auth.hasPermission(this.auth.perm.TransactionsPayoutsManage)) {
          this.editItem(x[0]);
          this.form.reset();
          this.getData();
        } else {
          this.items = x;
        }
      }
    });
    this.$form = this.form.valueChanges.pipe(
      // debounceTime(500),
      distinctUntilChanged()
    ).subscribe(data => {
      this.getData()
    });
  }
  getData() {
    this.service.queryAllParams.skip = 0;
    this.service.pageIndex = 0;
    this.service.fetchMoreData(this.form.value); 
    setTimeout(()=> {
      if (this.items?.length == 1 && this.form.get('pickupCode').value && this.auth.hasPermission(this.auth.perm.TransactionsPayoutsManage)) {
        this.editItem(this.items[0]);
      }
    }, 300);
  }

  public editItem(data: any) {
    if (this.form.get('pickupCode').value)
      this.router.navigate(['/', 'admin', 'payouts', data.id, this.form.get('pickupCode').value]);
    else
      this.router.navigate(['/', 'admin', 'payouts', data.id]);
  }
}
