import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomValidators } from 'src/app/_helpers/custom.validators';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { PublicService } from 'src/app/_services/public.service';
import { UiService } from 'src/app/_services/ui.service';
import { PaymentMethodsService } from 'src/app/_services/user/payment-methods.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-agreement-item',
  templateUrl: './agreement-item.component.html',
  styleUrls: ['./agreement-item.component.scss'],
})
export class AgreementItemComponent implements OnInit {

  loading = false;
  creationType: UntypedFormControl = new UntypedFormControl('bsb', [Validators.required]);
  form = this.fb.group({
    id: [null],
    payID: [null],
    payIDType: [null],
    bsb: [null, [Validators.minLength(6), Validators.maxLength(6), Validators.max(999999)]],
    accountNumber: [null, [Validators.minLength(6), Validators.maxLength(9)]],
    name: [null, [Validators.minLength(6), Validators.maxLength(120), CustomValidators.noWhitespaceValidator()]],
    maxAmount: [null, [Validators.required]],
    inboundCode: ['PAYTO'],
  });
  requiredValidator = Validators.required;
  maxAmountAllowed = 0;


  constructor(
    private fb: UntypedFormBuilder,
    private service: PaymentMethodsService,
    private publicService: PublicService,
    private ui: UiService,
    public auth: AuthenticationService,
    private modalService: ModalLauncherService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AgreementItemComponent>,
  ) {
  }


  ngOnInit(): void {
      var s = this.publicService.getFrontendConfiguration().subscribe({
        next: (x: any) => {
          var paymentMethodsRequired = x?.inbounds?.filter(el => el?.code == 'PAYTO');
          if (paymentMethodsRequired?.length) {
            this.maxAmountAllowed = paymentMethodsRequired[0]?.maxAmount;
            this.form.get('maxAmount').addValidators([Validators.max(paymentMethodsRequired[0]?.maxAmount)]);
          }
          if (this.data) {
            this.creationType.setValue(null)
            this.form.patchValue(this.data);
            this.form.get('name').disable();
            this.changeVlidators(['maxAmount'], ['payID', 'payIDType', 'bsb', 'accountNumber']);
          } else {
              if (paymentMethodsRequired?.length)
                this.form.patchValue({ maxAmount: this.maxAmountAllowed });
              this.form.patchValue({ name: environment.companyName + ' Agreement' })
              this.changeVlidators(['name'], []);
              this.changeCreationType();
          }
          s?.unsubscribe();
        }
      })
  }

  changeVlidators(choosenOption: string[], lastOption: string[]) {
    this.form.get('payID').patchValue(null);
    if (choosenOption.indexOf('payID') > -1) {
      if (this.form.get('payIDType').value == 'EMAIL') {
        this.form.get('payID').setValidators([Validators.required, Validators.email, Validators.pattern("^[^A-Z]*$")]);
      } else {
        this.form.get('payID').setValidators([Validators.required, Validators.minLength(9), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]);
      }
    }
    choosenOption.forEach(el => {
      this.form.controls[el].addValidators([Validators.required]);
      this.form.controls[el].updateValueAndValidity();
    })
    lastOption.forEach(el => {
      this.form.controls[el].removeValidators(this.requiredValidator);
      this.form.controls[el].updateValueAndValidity();
      this.form.controls[el].patchValue(null)
    })


  }

  changeCreationType() {
    if (this.creationType.value === 'payID') {
      this.changeVlidators(['payID', 'payIDType'], ['bsb', 'accountNumber']);
      this.form.get('payIDType').setValue('PHONE');
    }
    else
      this.changeVlidators(['bsb', 'accountNumber'], ['payID', 'payIDType']);
  }

  submitForm() {
    this.loading = true;
    var s = !this.data?.id ? this.service.addPaymentMethod(this.form.getRawValue()) : this.service.updatePaymentMethod(this.form.getRawValue());

    var k = s.subscribe({
      next: x => {
        this.ui.snack('Agreement ' + (this.data?.id ? 'saved!' : 'created!') + ' Login to your bank app or bank website and confirm requested changes.');
        k?.unsubscribe();
        this.dialogRef.close();
      }, error: (err: any) => {
        this.loading = false;
        if (err?.graphQLErrors[0]?.extensions?.code === 'FLAG_KYC_MISSING') {
          k?.unsubscribe();
          this.dialogRef.close();
          this.modalService.showVerify = true;
        }
      }
    });
  }
}
