<div class="close-box">
    <div></div>
    <div>
        <span>Info</span>
    </div>
    <button mat-icon-button matSuffix type="button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="image-box-small">
    <div class="center-box">
        <img [src]="data?.imageUrl">
        <span>{{data?.imageDescription | translate}}</span>
    </div>
</mat-dialog-content>
