<list-wrapper #wrapper [mainService]="service" panelTitle="Countries" [managePerm]="auth.perm.CountriesManage"
  [readPerm]="auth.perm.CountriesRead" [hideAddBtn]="true" (onNew)="newItem()" (onEdit)="editItem($event)">
  <form [formGroup]="form" class="io-row-m width-100" toolbox>
    <mat-form-field class="width-100 width-mff" appearance="outline">
      <mat-label>{{'Search'|translate}}</mat-label>
      <input matInput formControlName="query" placeholder="{{'Name'|translate}}">
      <button mat-icon-button matSuffix type="button" (click)="form.patchValue({'query': null})"
        [disabled]="!form.get('query').value">
        <mat-icon *ngIf="form.get('query').value">clear</mat-icon><mat-icon
          *ngIf="!form.get('query').value">search</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="width-100 width-mff" appearance="outline">
      <mat-label>{{'Status' | translate}}</mat-label>
      <mat-select formControlName="active">
        <mat-option [value]="null">{{'All'|translate}}</mat-option>
        <mat-option [value]="true">{{'Active'|translate}}</mat-option>
        <mat-option [value]="false">{{'Inactive'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="width-100 width-mff" appearance="outline">
      <mat-label>{{'Sender' | translate}}</mat-label>
      <mat-select formControlName="isSender">
        <mat-option [value]="null">{{'All'|translate}}</mat-option>
        <mat-option [value]="true">{{'Sender'|translate}}</mat-option>
        <mat-option [value]="false">{{'Nonsender'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="width-100 width-mff" appearance="outline">
      <mat-label>{{'Recipient' | translate}}</mat-label>
      <mat-select formControlName="isRecipient">
        <mat-option [value]="null">{{'All'|translate}}</mat-option>
        <mat-option [value]="true">{{'Recipient'|translate}}</mat-option>
        <mat-option [value]="false">{{'Nonrecipient'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <div class="io-py-1">
    <div [ngClass]="{'io-item':true, 'io-item-shadow': true, 'io-item-inactive':!item?.isActive, clickable:true}"
      *ngFor=" let item of items; last as last;" (click)="wrapper.edit(item)">
      <!-- {{item?.raisedFlag|json}} -->
      <div class="main">
        <div class="flag">
          <app-country-flag [item]="item" [size]="'big'"></app-country-flag>
        </div>
        <div class="data name">
          <small>{{ 'Name' | translate}}</small>
          <span>{{ item?.name }}</span>
        </div>

        <div class="data">
          <small>{{ 'ISO Code 2' | translate}}</small>
          <span>{{ item?.isoCode2 }}</span>
        </div>
        <div class="data">
          <small>{{ 'ISO Code 3' | translate}}</small>
          <span>{{ item?.isoCode3 }}</span>
        </div>

        <div class="data">
          <small>{{ 'Calling Code' | translate}}</small>
          <span>{{ item?.phonePrefix ? '+'+item?.phonePrefix : null | noData:'—'}}</span>
        </div>
        <div class="data">
          <small>{{ 'Mode' | translate}}</small>
          <span>{{ (item?.isSender ? (item?.isRecipient? "Sender & Recipient":"Sender"): (item?.isRecipient?
            "Recipient": null) ) | noData:'—'
            }}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- <mat-action-list [multiple]="false">
    <mat-list-item *ngFor=" let item of items$|async; last as last;" (click)="wrapper.edit(item)"
      [ngClass]="{'io-disabled': !item?.isActive}">
      <img matListIcon *ngIf="!hasError(item.id)" (error)="setError(item.id)"
        [src]="publicService.countryFlagImage(item?.isoCode3)">
      <mat-icon *ngIf="hasError(item.id)" matListIcon>outlined_flag</mat-icon>
      <h4 mat-line>{{ item?.name }}</h4>
      <p mat-line>
        {{ item?.isoCode2 }} / +{{ item?.phonePrefix }} / {{ item?.region?.name }}
      </p>
     

      <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
    </mat-list-item>
  </mat-action-list> -->
</list-wrapper>