<ng-container [ngTemplateOutlet]="moovTemplate" *ngIf="item?.inboundCode === 'MOOV'"></ng-container>
<ng-container [ngTemplateOutlet]="paytoTemplate" *ngIf="item?.inboundCode === 'PAYTO'"></ng-container>

<ng-template #moovTemplate>
    <div class="io-item io-item-shadow">
        <div class="main">
            <div class="data">
                <small>{{'Account Holder' |translate}}</small>
                <span>{{item?.holderName}}&nbsp;</span>
            </div>
            <mat-icon *ngIf="item?.status == 'NEW'" class="pending">outbound</mat-icon>
            <mat-icon *ngIf="item?.status == 'PENDING'" class="pending">outbound</mat-icon>
            <mat-icon *ngIf="item?.status == 'VERIFIED'" class="verified">check_circle</mat-icon>
            <mat-icon *ngIf="item?.status == 'verificationFailed'" class="failed">report</mat-icon>
            <div class="data">
                <small>{{'Status' |translate}}</small>
                <span>{{('bankAccountStatus_'+item?.status|translate)}}&nbsp;</span>
            </div>
            <div class="data">
                <small>{{'Account No' |translate}}</small>
                <span *ngIf="item?.lastFourNumbers"><em
                        class="lastFourNumbers">XX-XXXX-</em>{{item?.lastFourNumbers}}&nbsp;</span>
            </div>
            <div class="data">
                <small>{{'Routing Number'|translate}}</small>
                <span>{{item?.routingNumber}}&nbsp;</span>
            </div>
            <div class="data">
                <small>{{ 'Bank Name'|translate }}</small>
                <span>{{ item?.name }}&nbsp;</span>
            </div>
            <button mat-icon-button class="btn" [matMenuTriggerFor]="menu" *ngIf="!auth.isAdmin()">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="mls.deleteAccount(item)">
                    {{ 'Delete' | translate }}
                </button>
            </mat-menu>
        </div>
    </div>
</ng-template>


<ng-template #paytoTemplate>
    <div class="io-item io-item-shadow">
        <div class="main">
            <div class="data">
                <small>{{ 'Agreement Name'|translate }}</small>
                <span>
                    {{ item?.name }}&nbsp;
                    <s *ngIf="item?.description">{{item?.description}}</s>
                </span>
            </div>
            <mat-icon *ngIf="item?.status == 'NEW'" class="pending">outbound</mat-icon>
            <mat-icon *ngIf="item?.status == 'VERIFIED'" class="verified">check_circle</mat-icon>
            <mat-icon *ngIf="item?.status == 'PENDING'" class="failed">report</mat-icon>
            <mat-icon *ngIf="item?.status == 'ERRORED'" class="failed">cancel</mat-icon>
            <mat-icon *ngIf="['NEW', 'VERIFIED', 'PENDING', 'ERRORED'].indexOf(item?.status) == -1"
                class="failed">cancel</mat-icon>
            <div class="data" [matTooltip]="('agreementStatus_'+statusMapper[item?.status]) | translate">
                <small>{{'Status' |translate}}</small>
                <span>{{(('agreementStatus_'+statusMapper[item?.status]) | translate)}}&nbsp;</span>

            </div>
            <div class="data" *ngIf="item?.lastFourNumbers">
                <small>{{'Account No' |translate}}</small>
                <span><em class="lastFourNumbers">XX-XXXX-</em>{{item?.lastFourNumbers}}&nbsp;</span>

            </div>
            <div class="data" *ngIf="!item?.lastFourNumbers">
                <small>{{'Pay ID' |translate}}</small>
                <span>{{item.accountNumber}}</span>
            </div>
            <div class="data">
                <small>{{'Maximum Amount' | translate}}</small>
                <span>{{auth?.user?.country?.currency + ' ' + item?.maxAmount }}&nbsp;</span>
            </div>
            <button mat-icon-button class="btn" [matMenuTriggerFor]="menu"
                *ngIf="!auth.isAdmin()  && ['ACTIVE', 'SUSPENDED', 'CREATED'].indexOf(statusMapper[item?.status]) > -1; else mjau">
                <mat-icon>more_vert</mat-icon>
            </button>
            <ng-template #mjau>
                <button mat-icon-button disabled="disabled" class="btn">
                    <mat-icon style="opacity: 0;">more_vert</mat-icon>
                </button>
            </ng-template>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="mls.newAgreement(item)" *ngIf="item?.status === 'VERIFIED'">
                    {{ 'Edit' | translate }}
                </button>


                <button mat-menu-item (click)="mls.changeAgreementStatus(item, statusMapper['VERIFIED'])"
                    *ngIf="statusMapper[item?.status] === 'SUSPENDED'">
                    {{ 'Activate' | translate }}
                </button>
                <button mat-menu-item (click)="mls.changeAgreementStatus(item, statusMapper['PENDING'])"
                    *ngIf="statusMapper[item?.status] === 'ACTIVE'">
                    {{ 'Suspend' | translate }}
                </button>
                <button mat-menu-item (click)="mls.changeAgreementStatus(item, statusMapper['ERRORED'])"
                    *ngIf="statusMapper[item?.status] === 'ACTIVE' || statusMapper[item?.status] === 'CREATED' || statusMapper[item?.status] === 'SUSPENDED'">
                    {{ 'Cancel' | translate }}
                </button>
            </mat-menu>
        </div>
    </div>
</ng-template>