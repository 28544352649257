<div *ngIf="onboarding && onboarding.visible" class="onboarding">

  <div class="io-row-l">
    <div class="flex-2">
      <h3>{{ 'Please finish up your settings' | translate}}</h3>
      <div *ngFor="let step of (onboarding.steps| filter : filterFunction); let first = first; let last = last;">
        <p *ngIf="first">{{ step.description |translate }}</p>
      </div>

    </div>
    <div class="steps">
      <div *ngFor="let step of onboarding.steps" [ngClass]="{'active': step.active, step:true}">
        <!-- {{ step | json }} -->
        <div>
          <mat-icon *ngIf="step.action=='CreateRecipient'">contact_page</mat-icon>
          <mat-icon *ngIf="step.action=='CreateTransaction'">payments</mat-icon>
          <mat-icon *ngIf="step.action=='Verification'">perm_identity</mat-icon>
          <mat-icon *ngIf="step.action=='ViewTransactions'">payments</mat-icon>
        </div>
        <span>{{ step?.actionLabel | translate }}</span>
      </div>
    </div>
  </div>

  <!-- 
  <h3 matLine>{{ 'Recipients' | translate}}</h3>


  <mat-icon matListIcon>payments</mat-icon>
  <h3 matLine>{{ 'Transactions' | translate }}</h3>

  <mat-icon matListIcon>perm_identity</mat-icon>
  <h3 matLine>{{'Profile' | translate}}</h3> -->
</div>