import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { UiService } from '../../../_services/ui.service';
import { EndUserService } from 'src/app/_services/user/enduser.service';

@Component({
  selector: 'app-enduser-edit',
  templateUrl: './enduser-edit.component.html',
  styleUrls: ['./enduser-edit.component.scss']
})
export class EnduserEditComponent implements OnInit {
  hide = true;

  email: string;
  password: string;
  countries: any;
  authMethod: string;
  country: any;
  phonePrefix: any;
  ausState: boolean;
  registerAus: any[] = [];
  changed = true;

  registerForm = this.fb.group({
    phone: [this.data.phone || null, Validators.compose([Validators.required, Validators.pattern("[0-9]+"), Validators.maxLength(18)])],
    city: [this.data.city || null, Validators.required],
    addressLine1: [this.data.addressLine1 || null, Validators.required],
    addressLine2: [this.data.addressLine2 || null],
    unitNumber: [this.data.unitNumber || null],
    territory: [this.data.territory || null],
    firstName: [null, Validators.required],
    middleName: [null],
    lastName: [null, Validators.required],
    email: [null],
    zip: [null, Validators.required]
  });

  fromType = "updateUser";
  form: any;
  endUserInfo: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private usersServices: EndUserService,
    private ui: UiService,
    private dialogRef: MatDialogRef<EnduserEditComponent>,
    private dialog: MatDialog,
  ) {
    this.endUserInfo = data;

    this.phonePrefix = data.user.country.phonePrefix;
    this.country = data.user.country.isoCode3;
    if (this.country === 'AUS') {
      this.ausState = false;
      this.registerForm.controls.territory.setValidators(Validators.required);
    } else {
      this.ausState = true;
      this.registerForm.controls.territory.clearValidators();
    }
    this.registerForm.patchValue(this.data.user);
  }

  ngOnInit() { }

  formValid($event) {
    if ($event) {
      this.changed = false;
    }
  }

  update($event) {
    this.form = $event;
    delete this.form?.id
    this.usersServices.updateProfile(this.form).subscribe(
      res => {
        this.ui.snack('Profile updated');
        this.dialogRef.close();
      }
    );
  }
}
