import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { RegionType } from 'src/app/_graphql/schema';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class AdminRegionsService extends BaseService<RegionType>{

  selectOneFields = gql`
    fragment RegionSelectOneFields on RegionType  {
      id
      name
      maxAmount
      code
      isActive
    }
  `;

  selectAllFields = gql`
    fragment RegionSelectAllFields on RegionType  {
      id
      name
      maxAmount
      code
      isActive
    }
  `;

  constructor(protected apollo: Apollo) {
    super(apollo);
    this.initGql('region');
  }
}
