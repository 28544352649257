import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-transaction-status',
  templateUrl: './transaction-status.component.html',
  styleUrls: ['./transaction-status.component.scss']
})
export class TransactionStatusComponent {
  _statusId: string;
  _statusName: string;
  _icon: string;
  @Input() iconOnly: Boolean = false;
  get status(): any {
    return {}
  }
  @Input() set status(value: any) {
    if (value?.statusId) {
      this._statusId = value.statusId
    }
    if (value?.icon) {
      this._icon = value.icon
    }
    if (value?.name) {
      this._statusName = value.name
    }
  }
  @Input() nolabel: Boolean = false;

  constructor(
  ) { }
}
