import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import { UiService } from '../_services/ui.service';
import { Router } from '@angular/router';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    public ui: UiService,
    private router: Router,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (event.body.errors && event.body.errors.length) {
          for (let index = 0; index < event.body.errors.length; index++) {
            const error = event.body.errors[index];
            console.log(error)
            if (error?.message?.indexOf("You are not authorized to run this") > -1) {
              error.message = "Your session has expired. Please log in.";
              this.ui.snack(error.message);
              this.router.navigate([window.location.pathname.startsWith('/admin') ? '/admin/login' : '/logout'], { queryParams: { returnUrl: window.location.pathname } });
            } else {
              if (!error?.extensions?.data?.code) {
                error.message = "Something went wrong. Please try later.";
              } else {
                error.message = error?.extensions.data?.message ? error?.extensions.data?.message : "Something went wrong. Please try later.";
              }
            }
            if (error?.extensions?.data?.code == "NOT_FOUND" && error.message.search("User not found") > -1)
              error.message = null;

            if (error.message)
              this.ui.snack(error.message);
          }
        }
        return event.clone();
      }
    }));
  }
}
