import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../../../_services/authentication.service';
import { UiService } from 'src/app/_services/ui.service';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { PublicService } from 'src/app/_services/public.service';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit {

  @Input() userId: any;
  @Input() ticketId: any;
  @Input() documents: any;
  @Input() country: any;
  isAdminMode: boolean;
  i = true;
  first = false;

  constructor(
    private dialog: MatDialog,
    public auth: AuthenticationService,
    public publicService: PublicService,
    public ms: ModalLauncherService,
    private ui: UiService) {
    this.isAdminMode = auth.isAdmin();
  }

  isUndefined(variable) {
    return !(typeof variable == 'undefined')
  }

  ngOnInit() { }
}
