import { Component, OnInit, Input } from '@angular/core';
import { FlagRaiseComponent } from '../flag-raise/flag-raise.component';
import { FlagResolveComponent } from '../flag-resolve/flag-resolve.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { AdminTransactionType, FlagType, RaisedFlagType } from 'src/app/_graphql/schema';
import { Subscribable } from 'rxjs';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { TicketRaiseComponent } from 'src/app/components/tickets/ticket-raise/ticket-raise.component';
import { TicketItemComponent } from 'src/app/components/tickets/ticket-item/ticket-item.component';

@Component({
  selector: 'app-flag-list',
  templateUrl: './flag-list.component.html',
  styleUrls: ['./flag-list.component.scss']
})
export class FlagListComponent implements OnInit {
  items$: Subscribable<RaisedFlagType[]>;

  @Input() userId: any;
  @Input() transaction: AdminTransactionType;
  @Input() level: any;
  @Input() items: FlagType[];

  customCollapsedHeight = '100%';
  customExpandedHeight = '100%';

  constructor(
    private dialog: MatDialog,
    public auth: AuthenticationService,

    public ms: ModalLauncherService
  ) { }

  ngOnInit() {

  }
  localFilter() {
    var x = arguments;
  }
  raiseFlag() {
    const dialogRef = this.dialog.open(FlagRaiseComponent, {
      width: '600px',
      data: { userId: this.userId, transactionId: this.transaction?.id },
      maxHeight: '80%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }



  openTicket(ticket: any) {
    var data = { ticketId: ticket.id, userId: this.userId, transactionId: this.transaction?.id };
    const dialogRef = this.dialog.open(TicketItemComponent, {
      height: 'auto',
      disableClose: true,
      maxWidth: '960px',
      minWidth: '960px',
      panelClass: 'no-padding',
      data: data,
    });

  }
}
