<div mat-dialog-title>
  {{ data ? "Edit Country" : "Add Country"}}
</div>

<mat-dialog-content>
  <form [formGroup]="form">
    <div class="io-row">
      <mat-form-field appearance="outline" style="margin-bottom: 24px;">
        <mat-label>Name</mat-label>
        <input matInput required formControlName="name">
        <mat-hint>Make sure you have correctly spelled country name as it's used within the system.</mat-hint>
      </mat-form-field>
    </div>

    <div class="io-row-l" style="margin-bottom: 16px;">
      <mat-form-field appearance="outline">
        <mat-label>Currency</mat-label>
        <mat-select required formControlName="currency">
          <mat-option [value]="currency.isoCode" *ngFor="let currency of currencies | async">
            {{ currency.isoCode }}</mat-option>
        </mat-select>
        <button matSuffix mat-icon-button (click)="createCurrency($event)"
          *ngIf="auth.hasPermission(auth.perm.CompanyManage)">
          <mat-icon>add</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Phone prefix</mat-label>
        <input matInput required type="number" formControlName="phonePrefix">
        <mat-hint>Enter prefix number (i.e. 49) w/o '+' sign.</mat-hint>
      </mat-form-field>
    </div>
    <div class="io-row-l">
      <mat-form-field appearance="outline">
        <mat-label>Region</mat-label>
        <mat-select required formControlName="regionId">
          <mat-option value="{{ region.id }}" *ngFor="let region of regions | async" [disabled]="!region.isActive"> {{
            region.name }}</mat-option>
        </mat-select>
        <button matSuffix mat-icon-button (click)="createRegion($event)"
          *ngIf="auth.hasPermission(auth.perm.RegionsManage)">
          <mat-icon>add</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="io-row-l">
      <!-- <mat-form-field appearance="outline">
        <mat-label>Maximum amount</mat-label>
        <input matInput required type="number" formControlName="maxAmount">
        <mat-hint>Maximum amount user can transfer for this country.</mat-hint>
      </mat-form-field> -->
      <mat-form-field appearance="outline">
        <mat-label>ISO Code 2</mat-label>
        <input matInput required formControlName="isoCode2">
        <mat-hint>
          2-letter country code (defined by <a href="https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2"
            target="_blank">ISO 3166-1 alpha-2</a>).
        </mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label class="label">ISO Code 3</mat-label>
        <mat-select-trigger class="selection-container">
          <img class="selection-country-img" *ngIf="!countryAdd?.error"
            (error)="countryAdd ? (countryAdd.error = true) : ''"
            src="/assets/images/CountryFlags/{{ service.pictureName(country) }}.png">
          <mat-icon *ngIf="countryAdd?.error && form.get('isoCode3').value"
            class="selection-country-img">outlined_flag</mat-icon>
          <span class="selection-country-label "><input matInput required formControlName="isoCode3"></span>
        </mat-select-trigger>
        <mat-hint>
          3-letter country code (defined by <a href="https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3"
            target="_blank">ISO 3166-1 alpha-3</a>).
        </mat-hint>
      </mat-form-field>
    </div>


    <div class="io-row" style="padding: 24px 0 24px 0 ">
      <mat-checkbox color="primary" formControlName="isActive">Active</mat-checkbox>
      <mat-checkbox color="primary" formControlName="isSender">Sender</mat-checkbox>
      <mat-checkbox color="primary" formControlName="isRecipient">Recipient</mat-checkbox>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid"
    *ngIf="auth.hasPermission(auth.perm.CountriesManage)" (click)="onSubmit()">Save</button>
</mat-dialog-actions>