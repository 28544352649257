import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { DocumentType } from 'src/app/_graphql/schema';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class AdminDocumentsService extends BaseService<DocumentType>{

  selectOneFields = gql`
    fragment DocumentSelectOneFields on DocumentType  {
      id 
    }`;

  selectAllFields = gql`
    fragment DocumentSelectAllFields on DocumentType  {
      cardNumber
      category{ id name code }
      created
      description
      documentExp
      documentIssuer
      documentNote
      documentNumber
      documentSource
      documentState
      createdByName
      files { id name }
      id
      isPrimary
    }
    `;

  constructor(protected apollo: Apollo) {
    super(apollo);
    this.mainScope = "usersManagement";
    this.initGql('document');


    this.modifyMutation = gql`mutation updateDocument($data: DocumentInputType!, $addedFilesIds: [ID], $deletedFileIds: [ID]) {
      usersManagement {
        __typename
        updateDocument(data: $data, addedFilesIds: $addedFilesIds, deletedFileIds: $deletedFileIds) {
          ...DocumentSelectOneFields
          __typename
        }
      }
    }
    ${this.selectOneFields}
    `
  }


  public setPrimaryDocument(documentId: String, userId: String) {
    return this.apollo.mutate({
      mutation: gql` mutation setPrimaryDocumentMutation( $documentId: ID! ) {
        ${this.mainScope} { setDocumentAsPrimary( documentId: $documentId ) }
      }`,
      refetchQueries: [
        ...this.refetchAdditionalQueries
      ],
      // refetchQueries: [
      //   {
      //     query: this.ticketsService.ticketQuery
      //   },
      //   {
      //     query: this.endUserDataFilesQuery,
      //   }, {
      //     query: this.gqlGetDocumentsQuery,
      //     variables: this.queryGetDocumentsVars
      //   }
      // ],
      variables: { documentId }
    });
  }


  public addFilesToDocument(fileIds: string[], documentId: string) {
    return this.apollo.mutate({
      mutation: gql
        `mutation addFilesToDocument($fileIds: [ID], $documentId: ID!) {
        usersManagement {
          __typename
          addFilesToDocument(fileIds: $fileIds, documentId: $documentId) {
            id
            __typename
          }
        }
      }
      `,
      refetchQueries: [
        ...this.refetchAdditionalQueries
      ],
      variables: { documentId, fileIds }
    });
  }

  public changeTransactionDocument(transactionId: string, documentId: string) {
    return this.apollo.mutate({
      mutation: gql
        `mutation changeTransactionDocument($transactionId: ID!, $documentId: ID!) {
          transactions {
            __typename
            changeTransactionDocument(transactionId: $transactionId, documentId: $documentId) {
              id
              ...DocumentSelectOneFields
            }
          }
        }
      ${this.selectOneFields}
      `,
      refetchQueries: [
        ...this.refetchAdditionalQueries
      ],
      variables: { transactionId, documentId }
    });
  }
}
