<label [ngClass]="{required : errors?.hasOwnProperty('required')}">
  <span>{{label}}:{{errors?.hasOwnProperty('required') ? '*': ''}}
    <small *ngIf="description">{{description}}</small>
  </span>

  <mat-icon color="primary" *ngIf="!loading">attachment</mat-icon>

  <mat-progress-spinner *ngIf="loading" [diameter]="16" [strokeWidth]="2" [mode]="profileProgressMode" diameter="36">
  </mat-progress-spinner>
  <form #form>
    <input class="file" type="file" [accept]="accept">
  </form>
  <div class="field">
    {{ file && !errors?.hasOwnProperty('required') ? file.name : '' }}
  </div>
  <button mat-icon-button (click)="onRemoveFile($event)" *ngIf="file && !errors?.hasOwnProperty('required')"
    color="warn">
    <mat-icon>close</mat-icon>
  </button>
</label>