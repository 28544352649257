import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, AfterViewChecked, OnDestroy } from '@angular/core';
import { TranslationsService } from 'src/app/_services/translations.service';
import { UiService } from 'src/app/_services/ui.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PublicComponent implements OnInit, AfterViewChecked {
  public loading = false;

  constructor(
    public trans: TranslationsService,
    private ui: UiService,
    private snack: MatSnackBar,
    private cdRef: ChangeDetectorRef,
  ) {
    this.ui.snacks.subscribe(message => {
      this.snack.open(message.replace('GraphQL error:', ''), 'Got it', {});
    });

    this.ui.loaders.subscribe(visible => {
      this.loading = visible;
      this.cdRef.detectChanges();
    });
  }

  ngOnInit() { }

  ngAfterViewChecked() {
    requestAnimationFrame(() => {
      this.loading = false;
    });
  }
}
