import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { CurrencyType } from 'src/app/_graphql/schema';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class AdminCurrenciesService extends BaseService<CurrencyType>{

  selectOneFields = gql`
    fragment CurrencySelectOneFields on CurrencyType  {
      fxSpread
      isoCode
      id
  }`;

  selectAllFields = gql`
    fragment CurrencySelectAllFields on CurrencyType  {
      fxSpread
      isoCode
      id
  }`;

  constructor(protected apollo: Apollo) {
    super(apollo);
    this.filters = false;
    this.initGql('currency', 'currencies');
  }
}
