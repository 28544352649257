import { Component, Input, forwardRef, OnInit, HostListener, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormControl, Validator } from '@angular/forms';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AdminFilesService } from 'src/app/_services/admin/admin-files.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UiService } from 'src/app/_services/ui.service';

@Component({
    selector: 'app-input-field-file',
    templateUrl: 'input-field-file.component.html',
    styleUrls: ['input-field-file.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputFieldFileComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputFieldFileComponent),
            multi: true,
        }]
})


export class InputFieldFileComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
    @Input() file: any;
    @Input() label: string;
    @Input() description: string;
    @Input() errors: {};
    @Input() accept: "";
    @ViewChild('form') form;
    private avatarSubscription: Subscription;
    profileProgressMode: ProgressSpinnerMode = 'indeterminate';
    loading = false;
    backgroundImage: any;
    private parseError: boolean;
    data: any;


    @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
        this.loading = true;
        const file = event && event.item(0);
        let backgroundImage;
        const reader = new FileReader();
        reader.onload = () => { backgroundImage = reader.result; }
        reader.readAsDataURL(file);

        this.avatarSubscription = this.filesService.upload(event, null, this.auth.user.id, null, null, null, true).subscribe(data => {
            if (data && data[0]) {
                this.data = data[0].id;
                this.propagateChange(this.data);
                this.backgroundImage = backgroundImage;
                this.loading = false;
                this.file = data[0];
            }
        }, errors => {
            if (errors.status == 401) {
                this.ui.snack("Your session has expired. Please log in.")
                this.auth.logout();
            }
        });
        this.form.nativeElement.reset()
    }
    constructor(
        private auth: AuthenticationService,
        private filesService: AdminFilesService,
        private ui: UiService
    ) {

    }
    ngOnInit(): void {

    }



    // this is the initial value set to the component
    public writeValue(obj: any) {
        if (obj) {
            this.data = obj;
        }
    }

    // registers 'fn' that will be fired wheb changes are made
    // this is how we emit the changes back to the form
    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    // validates the form, returns null when valid else the validation object
    // in this case we're checking if the json parsing has passed or failed from the onChange method
    public validate(c: UntypedFormControl) {
        return (!this.parseError) ? null : {
            jsonParseError: {
                valid: false,
            },
        };
    }

    // not used, used for touch input
    public registerOnTouched() { }

    // change events from the textarea
    private onChange(event) {

        // get value from text area
        let newValue = event.target.value;

        try {
            // parse it to json
            this.data = newValue;
            this.parseError = false;
        } catch (ex) {
            // set parse error if it fails
            this.parseError = true;
        }

        // update the form
        this.propagateChange(this.data);
    }

    // the method set in registerOnChange to emit changes back to the form
    private propagateChange = (_: any) => { };

    onRemoveFile(e: MouseEvent) {
        e.stopPropagation();
        if (this.file) {
            // this.docsService.deleteFile(this.file.id, false, false).subscribe(res => {
            this.form.nativeElement.reset()
            this.file = null;
            this.data = null;
            this.propagateChange(this.data);
            // })
        }
        return false;
    }

    ngOnDestroy() {
        if (this.avatarSubscription) {
            this.avatarSubscription.unsubscribe();
        }
    }
}