import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecipientItemComponent } from './recipient-item/recipient-item.component';
import { RecipientListItemComponent } from './recipient-list-item/recipient-list-item.component';
import { RecipientsSelectorComponent } from './recipients-selector/recipients-selector.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormConfigsModule } from 'src/app/components/form-configs/form-configs.module';
import { PanelModule } from 'src/app/components/panel/panel.module';
import { WrapperModule } from '../wrapper/wrapper.module';
import { RecipientsComponent } from './recipients.component';
import { RouterModule } from '@angular/router';
import { CountryFlagModule } from 'src/app/components/country-flag/country-flag.module';
import { FormDataDisplayConfigurationComponent } from 'src/app/components/form-configs/form-data-display-configuration/form-data-display-configuration.component';



@NgModule({
  declarations: [
    RecipientItemComponent,
    RecipientListItemComponent,
    RecipientsSelectorComponent,
    RecipientsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    PipesModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    FormConfigsModule,
    PanelModule,
    WrapperModule,
    RouterModule,
    CountryFlagModule
  ],
  exports: [
    RecipientItemComponent,
    RecipientListItemComponent,
    RecipientsSelectorComponent,
    FormDataDisplayConfigurationComponent
  ]
})
export class RecipientsModule { }
