<!-- <pre>{{ uid }}</pre>
<pre>{{this.form.value|json}}</pre>
<pre>{{customKey}}</pre> -->
<list-wrapper #wrapper [mainService]="service" [panelTitle]="componentConfig?.hideTitle ? '':  'Tickets'"
  [managePerm]="auth.perm.TicketsManage" [readPerm]="auth.perm.TicketsRead" [hideAddBtn]="true"
  (onEdit)="editItem($event)" [customKey]="customKey">
  <form [formGroup]="form" class="io-row-m width-100" toolbox>
    <mat-form-field class="width-mff" appearance="outline">
      <mat-label>{{'Search'|translate}}</mat-label>
      <input matInput formControlName="query" placeholder="{{'Title, description or number'|translate}}">
      <button mat-icon-button matSuffix type="button" (click)="form.patchValue({'query': null})"
        [disabled]="!form.get('query').value">
        <mat-icon *ngIf="form.get('query').value">clear</mat-icon><mat-icon
          *ngIf="!form.get('query').value">search</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field class="width-mff" appearance="outline">
      <mat-label>{{ 'Statuses' | translate}}</mat-label>
      <mat-select multiple formControlName="statuses">
        <mat-option *ngFor="let status of statuses" [value]="status.id">{{ status.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div style="display: flex; align-items: center;">
      <mat-checkbox color="primary" class="on-hold" #checkbox formControlName="notResolved">
        {{'Not Resolved' | translate}}</mat-checkbox>
    </div>
  </form>
  <div class="io-py-1">
    <app-admin-ticket-list-item *ngFor=" let item of items; last as last;" [item]="item" (click)="wrapper.edit(item)">
      <div class="no-flex io-mr-2 io-mt-1">
        <mat-icon matBadge="{{ item?.unseenMessagesCount || '' }}" matBadgeColor="warn">message</mat-icon>
      </div>
    </app-admin-ticket-list-item>
  </div>
</list-wrapper>