<div mat-dialog-title>{{ data.document.id ? 'Edit document' : 'Add document' }}</div>
<mat-dialog-content>
  <form [formGroup]="form">
    <div class="io-row">
      <mat-form-field>
        <mat-label>Document type</mat-label>
        <mat-select formControlName="categoryId">
          <mat-option *ngFor="let category of categories" [value]="category.id">{{ category.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="io-row">
      <mat-form-field>
        <mat-label>{{ 'Description' | translate }}</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>
    </div>
    <div class="io-row">
      <mat-form-field>
        <mat-label>{{ 'Number' | translate }}</mat-label>
        <input matInput formControlName="documentNumber">
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'Expiration Date' | translate }}</mat-label>
        <input matInput placeholder="Expiration Date" [matDatepicker]="picker" formControlName="documentExp"
          [min]="minDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="io-row">
      <mat-form-field>
        <mat-label>{{ 'Issuer' | translate }}</mat-label>
        <input matInput formControlName="documentIssuer">
      </mat-form-field>
      <mat-form-field *ngIf="(states$|async)?.length == 0 && documentType !='PASSPORT'">
        <mat-label>{{ 'State' | translate }}</mat-label>
        <input matInput formControlName="documentState">
      </mat-form-field>
    </div>
    <div class="io-row">
      <mat-form-field *ngIf="(states$|async)?.length  && documentType !='PASSPORT'">
        <mat-label>{{ 'State' | translate }}</mat-label>
        <mat-select formControlName="documentState">
          <mat-option *ngFor="let state of states$|async" [value]="state.id">{{ state.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="io-row">
      <mat-form-field>
        <mat-label>{{ 'Note' | translate }}</mat-label>
        <input matInput formControlName="documentNote">
      </mat-form-field>
    </div>
    <!-- <mat-form-field >
      <mat-label>{{ 'Source' | translate }}</mat-label>
      <input matInput formControlName="documentSource">
    </mat-form-field> -->
    <div class="io-row" *ngIf="data.document.documentSource">
      <p>Document source: {{ data.document.documentSource }}</p>
    </div>



    <div *ngIf="($files | async) as files">
      <div class="io-row">
        <mat-form-field>
          <mat-label>{{ 'Choose the files to attach' | translate }}</mat-label>
          <mat-select [disabled]="!(deleteFileIds.length > 0 || files.length != addedFiles.length)">
            <mat-option *ngFor="let file of getFiles(files, addedFiles)" (click)="addFiles(file.id);">
              <mat-icon>{{file.name|filetypeIcon}}</mat-icon>
              <span>{{file.name}}</span>
            </mat-option>
            <mat-option *ngFor="let file of getFiles(filesUploaded, deleteFileIds, true)"
              (click)="undeleteFile(file.id);">
              <mat-icon>{{file.name|filetypeIcon}}</mat-icon>
              <span>{{file.name}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>

  <mat-action-list dense>
    <div mat-subheader>
      Choosen files: &nbsp;
      <strong *ngIf="addedFiles.length == 0">No
        files choosen</strong>
    </div>
    <span *ngFor="let file of $files | async ">
      <button mat-list-item *ngIf="addedFiles.indexOf(file.id) !=-1" color="primary" (click)="downloadFile(file); ">
        <mat-icon mat-list-icon>{{file.name|filetypeIcon}}</mat-icon>
        <div mat-line>{{file.name}}</div>
        <mat-icon class="icon" mat-icon-button
          (click)="addedFiles.splice(addedFiles.indexOf(file.id),1);$event.stopPropagation();"
          class="uploaded-file-delete" color="warn">close
        </mat-icon>
      </button>
    </span>
  </mat-action-list>
  <mat-action-list dense>
    <div mat-subheader>
      Attached files: &nbsp;
      <strong *ngIf="(filesUploaded.length == 0 ) ||(filesUploaded.length == deleteFileIds.length)">No
        files attached</strong>
    </div>
    <span *ngFor="let file of filesUploaded">
      <button mat-list-item color="primary" (click)="downloadFile(file)" *ngIf="deleteFileIds.indexOf(file.id)==-1">
        <mat-icon mat-list-icon>{{file.name|filetypeIcon}}</mat-icon>
        <div mat-line>{{file.name}}</div>
        <mat-icon class="icon" mat-icon-button (click)="deleteFile(file.id);$event.stopPropagation();"
          class="uploaded-file-delete" color="warn">close
        </mat-icon>
      </button>
    </span>
  </mat-action-list>



</mat-dialog-content>

<mat-dialog-actions align="end">


  <input hidden type="file" multiple #uploader (change)="handleFileInput($event.target.files)" />
  <button mat-button color="primary" mat-dialog-close>{{ 'Close' | translate }}</button>
  <button mat-raised-button color="primary" [disabled]="!form.valid || submited" (click)="submitForm()">{{ 'Save' |
    translate }}</button>
</mat-dialog-actions>