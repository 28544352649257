import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslatePipe } from '@ngx-translate/core';
import { ChangePasswordComponent } from 'src/app/public/login/change-password/change-password.component';

@Component({
  selector: 'app-admins-list-item',
  templateUrl: './admins-list-item.component.html',
  styleUrls: ['./admins-list-item.component.scss'],
})
export class AdminsListItemComponent implements OnInit {
  @Input() item: any;
  nesto:any

  constructor(
    private dialog: MatDialog,

  ) {}

  ngOnInit(): void {
  }
  formatProvidedData(item: any) {
    var r = [];
   if (item?.accessLevel?.indexOf('PayoutAgent')> -1)
    r.push('Payout Agent');

   if (item?.accessLevel?.indexOf('Manager')> -1)
    r.push('Manager');

    if (item?.accessLevel?.indexOf('PayoutPartner')> -1)
    r.push('Payout Partner');

    return r;
  }
  changePassword(user: any) {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '400px',
      disableClose: true,
      maxWidth: '95%',
      maxHeight: '80%',
      data: { user, isResetMode: true }
    });
  }

}
