<div mat-dialog-title>
  <span>{{ 'Change Password' | translate}}</span>
  <button mat-icon-button mat-dialog-close class="close">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>

<form class="update-profil" [formGroup]="form">
  <mat-form-field appearance="outline" *ngIf="!data.isResetMode">
    <mat-label>{{'Current Password' | translate}}</mat-label>
    <input minlength="6" required matInput formControlName="oldPassword" [type]="hide1 ? 'password' : 'text'" />
    <button mat-icon-button matSuffix [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
      <mat-icon (click)="hide1 = !hide1">{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field appearance="outline"
    hintLabel="{{'Needs to contain at least one lower, upper and one digit character.' | translate}}">
    <mat-label>{{'New Password' | translate}}</mat-label>
    <input minlength="6" #password required matInput formControlName="newPassword"
      [type]="hide2 ? 'password' : 'text'" />
    <button mat-icon-button matSuffix [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
      <mat-icon (click)="hide2 = !hide2">{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
    <mat-hint align="end">
      {{password.value.length}} /
      {{ 6 }}
    </mat-hint>
  </mat-form-field>

  <app-password-strength [hasNumber]="true" [hasUpper]="true" [hasLower]="true"
    [userPassword]="this.form.get('newPassword')">
  </app-password-strength>
  <br />
  <mat-form-field appearance="outline">
    <mat-label>{{'Confirm Password' | translate}}</mat-label>
    <input minlength="6" required matInput formControlName="confirmPassword" appConfirmEqualValidator="newPassword"
      [type]="hide3 ? 'password' : 'text'" />
    <button mat-icon-button matSuffix [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide3">
      <mat-icon (click)="hide3 = !hide3">{{hide3 ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
    <mat-error class="error-password"
      *ngIf="form.get('confirmPassword').touched && form.get('confirmPassword').errors?.notEqual && !form.get('confirmPassword').errors?.required">
      {{'New Password and Confirm Password does not match' | translate}}
    </mat-error>
  </mat-form-field>

</form>

<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>{{ 'Cancel' | translate }}</button>
  <button mat-raised-button color="primary" class="update-button" [disabled]="!form.valid" (click)="update()">{{
    'Update' | translate }}</button>
</mat-dialog-actions>