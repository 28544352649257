<app-hero></app-hero>

<div class="container">
  <h2>{{ 'page.help.front_page_title' | translate }}</h2>
</div>

<div class="help-container">
  <p>{{ 'page.help.content_title1' | translate }}</p>
  <p>
    {{ 'page.help.content1' | translate }}
  </p>
  <br />
  <p>{{ 'page.help.content_title2' | translate }}</p>
  <p>
    {{ 'page.help.content2' | translate }} 
  </p>
</div>
