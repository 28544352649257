import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CurrencyItemComponent } from './currency-item/currency-item.component';
import { Subscribable } from 'rxjs';
import { AdminCurrenciesService } from 'src/app/_services/admin/admin-currencies.service';
import { CurrencyType } from 'src/app/_graphql/schema';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent {
  items$: Subscribable<any>;

  constructor(
    public service: AdminCurrenciesService,
    public auth: AuthenticationService,
    private dialog: MatDialog,
  ) {
    this.items$ = this.service.all()
  }


  newItem() {
    this.openDialog(CurrencyItemComponent);
  }

  editItem(currency: CurrencyType) {
    this.openDialog(CurrencyItemComponent, currency);
  }

  openDialog(component: any, param = {}) {
    this.dialog.open(component, {
      disableClose: true,
      height: 'auto',
      width: '360px',
      data: param
    });
  }
}
