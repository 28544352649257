<div mat-dialog-title>
  <span>{{ 'Edit Profile' | translate}}</span>
  <button mat-icon-button mat-dialog-close class="close">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>

<mat-dialog-content>

  <!-- <app-form-configuration [fieldsGroup]="fromType" [endUserData]="endUserInfo" (sendForm)="update($event)"
    (validation)="formValid($event)" #child></app-form-configuration> -->
    <app-form-config confType="updateUser" [patchValue]="endUserInfo" (onSubmitData)="update($event)"
    (validation)="formValid($event)" #child></app-form-config>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>{{ 'Cancel' | translate }}</button>
  <button mat-raised-button color="primary" class="update-button" [disabled]="changed" (click)="child.onSubmit()">{{
    'Save' | translate }}</button>
</mat-dialog-actions>