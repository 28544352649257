<user-list-wrapper [disablePlaceholder]="true" panelTitle="Transactions" #wrapper [mainService]="service"
  [managePerm]="true">
  <form [formGroup]="form" class="io-row-l align-items-center width-100" toolbox>
    <mat-form-field class="width-100 width-mff" appearance="outline" *ngIf="componentConfig?.query">
      <mat-label>{{ 'Transaction Number' | translate }}</mat-label>
      <input matInput formControlName="query" placeholder="{{ 'Transaction Number' | translate }}">
      <button mat-icon-button matSuffix type="button" (click)="form.patchValue({'query': null})"
        [disabled]="!form.get('query').value">
        <mat-icon *ngIf="form.get('query').value">clear</mat-icon><mat-icon
          *ngIf="!form.get('query').value">search</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="width-100 width-mff" appearance="outline" *ngIf="componentConfig?.dateFrom">
      <mat-label>{{'From' | translate}}</mat-label>
      <input matInput readonly [matDatepicker]="picker2" [max]="form.get('dateTo').value || maxDate"
        placeholder="{{'From' | translate}}" formControlName="dateFrom">
      <div matSuffix>
        <mat-datepicker-toggle [for]="picker2"></mat-datepicker-toggle>
        <button mat-icon-button (click)="form.get('dateFrom').setValue(null)" *ngIf="form.get('dateFrom').value">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="width-100 width-mff" appearance="outline" *ngIf="componentConfig?.dateTo">
      <mat-label>{{'To' | translate}}</mat-label>
      <input matInput readonly [matDatepicker]="picker3" [min]="form.get('dateFrom').value" [max]="maxDate"
        placeholder="{{'To' | translate}}" formControlName="dateTo">
      <div matSuffix>
        <mat-datepicker-toggle [for]="picker3"></mat-datepicker-toggle>
        <button mat-icon-button (click)="form.get('dateTo').setValue(null)" *ngIf="form.get('dateTo').value">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <mat-datepicker #picker3></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="width-100 width-mff" appearance="outline" *ngIf="componentConfig?.recipientId">
      <mat-label>{{'Recipient' | translate}}</mat-label>
      <mat-select formControlName="recipientId">
        <mat-option [value]="null">{{'All'|translate}}</mat-option>
        <mat-option *ngFor="let item of recipients$ | async" [value]="item.id">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div></div>
    <div class="no-flex">
      <button mat-raised-button type="button" color="primary" (click)="modalLauncherService.sendMoney()"
        [disabled]="!(recipients$|async)?.length" class="btns">
        {{ 'Send Money' | translate }}</button>
    </div>
  </form>


  <app-panel-placeholder key="transactions" *ngIf="service.totalCount == 0" mode="large"
    image="/assets/images/placeholder-transactions.svg">
    <button mat-raised-button color="primary" (click)="modalLauncherService.sendMoney()"
      [disabled]="!(recipients$|async)?.length" class="btns">{{ 'Send Money'
      | translate }}</button>
  </app-panel-placeholder>

  <app-transaction *ngFor=" let item of items$|async; last as last;" [item]="item"
    (click)="wrapper.edit(item)"></app-transaction>
</user-list-wrapper>