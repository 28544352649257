import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketStatusComponent } from './ticket-status/ticket-status.component';
import { TransactionStatusComponent } from './transaction-status/transaction-status.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { OutboundStatusComponent } from './outbound-status/outbound-status.component';



@NgModule({
  declarations: [
    TicketStatusComponent,
    TransactionStatusComponent,
    OutboundStatusComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    TranslateModule.forChild(),

  ],
  exports: [
    TicketStatusComponent,
    TransactionStatusComponent,
    OutboundStatusComponent
  ]
})
export class StatusesModule { }
