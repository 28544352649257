import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { UiService } from 'src/app/_services/ui.service';
import { AdminTicketsService } from 'src/app/_services/admin/admin-tickets.service';
import { RaisedFlagType, TicketType } from 'src/app/_graphql/schema';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';

@Component({
  selector: 'app-admin-flags-list-item',
  templateUrl: './admin-flags-list-item.component.html',
  styleUrls: ['./admin-flags-list-item.component.scss']
})
export class AdminFlagsListItemComponent {
  @Input() item: RaisedFlagType;
  @Input() showBackBtn: boolean = false;
  @Input() isClickable: boolean = true;
  @Input() hasShadow: boolean = true;
  userCountry = "";
  expanded = false;

  constructor(
    public service: AdminTicketsService,
    private fb: FormBuilder,
    public auth: AuthenticationService,
    private ui: UiService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public mls: ModalLauncherService
  ) {
    const local = JSON.parse(localStorage.getItem('currentUser'));
    this.userCountry = local?.user?.country?.isoCode2;
  }
  mylocalize(key, string, keyLocalised, stringLocalised) {
    if (stringLocalised != keyLocalised) {
      return stringLocalised;
    } else if (string != key) {
      return string;
    }
    return key
  }

  onExpand(e, item) {
    this.expanded = !this.expanded
  }
}
