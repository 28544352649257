import { Component, Input } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { PublicService } from 'src/app/_services/public.service';

@Component({
  selector: 'app-recipient-list-item',
  templateUrl: './recipient-list-item.component.html',
  styleUrls: ['./recipient-list-item.component.scss']
})
export class RecipientListItemComponent {
  @Input() item: any;
  error = false;
  constructor(
    public auth: AuthenticationService,
    public modalLauncherService: ModalLauncherService,
    public publicService: PublicService
  ) {

  }

}