import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, UntypedFormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { UiService } from 'src/app/_services/ui.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  hide = true;
  loading = false;
  disabled = false;

  form: FormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthenticationService,
    private ui: UiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    var username = this.route.snapshot.paramMap.get('username');

    this.form = this.fb.group({
      username: [username, [Validators.required, Validators.email]],
      verificationCode: [this.route.snapshot.paramMap.get('code'), [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      newPassword: [null, [Validators.required]],
    });
  }



  ngOnInit() { }

  resetPassword() {
    this.loading = true;
    this.disabled = true;


    this.auth.resetPasswordEndUser(this.form.getRawValue())
      .subscribe((x: any) => {
        if (x && x.data && x.data.user?.resetPassword) {
          this.loading = false;
          this.disabled = false;
          this.ui.snack('Password is reset, please use it for a new login.');
          this.router.navigate(['../login']);
        }
      },
        error => {
          this.disabled = false;
          this.ui.snack('There was an error during password reset. Please populate details correctly or try later.');
        });
  }
}
