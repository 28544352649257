import { gql, Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';


import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class TranslationsService {
  query: any;
  listQuery: any;
  selectQuery = gql`
    query publicTranslations($langId: Int, $isoCode2: String) { 
      public { 
        id
        translations (langId: $langId, isoCode2: $isoCode2) { 
          id
          key
          language { id isDefault isoCode2 isoCode3 name }
          tags
          value
        }
      }
    }
  `;

  tagsQuery = gql`
    query translationTags {
      translationTags
  }`;

  selectBackendQuery = gql`
    query allTranslationsPaging(
      $tags: [String]
      $query: String
      $skip: Int
      $take: Int
    ) {
      allTranslationsPaging(
        tags: $tags
        query: $query
        take: $take
        skip: $skip
      ) {
        totalCount
        data {
          id
          key
          value
          tags
          language {
            id
            name
            isoCode2
            isoCode3
          }
        }
      }
    }
  `;

  createMutation = gql`
    mutation createTranslation(
      $langId: Int!
      $key: String!
      $value: String!
      $tags: String
    ) {
      addTranslation(
        translation: {
          languageId: $langId
          key: $key
          value: $value
          tags: $tags
        }
      ) {
        id
        key
        value
        tags
        language {
          id
          name
          isoCode2
          isoCode3
        }
      }
    }
  `;

  editMutation = gql`
    mutation editTranslation(
      $id: Int!
      $langId: Int!
      $key: String!
      $value: String!
      $tags: String
    ) {
      editTranslation(
        translation: {
          id: $id
          languageId: $langId
          key: $key
          value: $value
          tags: $tags
        }
      ) {
        id
        key
        value
        language {
          id
          name
          isoCode2
          isoCode3
        }
      }
    }
  `;

  private lanugagesQuery = gql`
    query languages {
      languages {
        id
        name
        isoCode2
        isoCode3
        isDefault
      }
    }
  `;

  public loadedTranslations = false;

  trans: any = {
    // Verification
    verification_code_info: 'You can find your verification in your email inbox, please check spam folder as well.',

    // questionnaire
    source_of_income: 'What is your source of income?',
    send_each_month: 'How much will you send each month?',
    how_many_times: 'How many times per month will you send?',
  };

  constructor(
    private apollo: Apollo,
    public tt: TranslateService,
  ) {
    try {
      var _trans = JSON.parse(localStorage.getItem('__translations_en'));
      if (_trans) {
        this.trans = _trans;
      }
    } catch (e) {
      console.log(e)
    }
    this.tt.setTranslation('en', this.trans);
    this.tt.setDefaultLang('en');
    this.tt.use('en');
    this.getTranslations();
  }

  public selectParameters = {
    skip: 0,
    take: 25,
    query: null,
    tags: null
  };

  public get(term: any) {
    return this.tt.get(term);
  }

  public all(sskip = 0, ttake = 25, qquery = null, ttags: string[]): any {
    this.selectParameters.skip = sskip;
    this.selectParameters.take = ttake;
    this.selectParameters.query = qquery;
    this.selectParameters.tags = ttags;

    this.listQuery = this.apollo.watchQuery({
      query: this.selectBackendQuery,
      pollInterval: 500000,
      variables: this.selectParameters
    });

    return this.listQuery.valueChanges.pipe(
      map((result: any) => result.data && result.data.allTranslationsPaging),
      map((x: any) => this.groupTransaltions(x))
    );
  }

  private groupTransaltions(x) {
    const res = {
      totalCount: x.totalCount,
      data: []
    };
    const grp = {};
    x.data.forEach(el => {
      if (!grp[el.key]) {
        grp[el.key] = [];
      }

      grp[el.key].push({
        id: el.id,
        lang: el.language.isoCode2,
        trans: el.value,
        tags: el.tags
      });
    });
    Object.keys(grp).forEach(xx => {
      res.data.push({ key: xx, langs: grp[xx], tags: grp[xx][0].tags });
    });
    return res;
  };

  public translationTags(): any {
    return this.apollo
      .watchQuery({
        query: this.tagsQuery
      })
      .valueChanges.pipe(
        map((result: any) => result.data && result.data.translationTags)
      );
  }

  public fetchMore(qskip: number, qtake: any, qquery: string, qtags: string[]) {

    this.selectParameters.skip = qskip;
    this.selectParameters.take = qtake;
    this.selectParameters.query = qquery;
    this.selectParameters.tags = qtags;

    this.listQuery.fetchMore({
      variables: this.selectParameters,
      updateQuery: (prev, { fetchMoreResult }) => {
        return fetchMoreResult;
      }
    });
  }

  public modify(data: any) {
    return this.apollo.mutate({
      mutation: this.editMutation,
      variables: data
    });
  }

  public create(data: any) {
    return this.apollo.mutate({
      mutation: this.createMutation,
      refetchQueries: [
        { query: this.selectBackendQuery, variables: this.selectParameters }, { query: this.tagsQuery }
      ],
      variables: data
    });
  }

  getLanguages() {
    return this.apollo
      .watchQuery({
        query: this.lanugagesQuery
      })
      .valueChanges.pipe(
        map((result: any) => result.data && result.data.languages)
      );
  }

  getTranslations(langCode = 'en') {
    this.apollo
      .watchQuery({
        query: this.selectQuery,
        variables: { isoCode2: langCode }
      })
      .valueChanges.pipe(
        map((result: any) => {

          if (
            !result.data ||
            !result.data.public ||
            !result.data.public.translations
          ) {
            return;
          }


          let currLang = result?.data?.public?.languages?.filter(l => l.isoCode2 === langCode || l.isDefault);

          if (currLang) {
            currLang = currLang.length == 1 ? currLang[0] : currLang.find(l => l.isoCode2 === langCode);
          }

          const translations = {};
          result.data.public.translations.map(tr => {
            translations[tr.key] = tr.value;
          });


          var key = '__translations_' + langCode;
          localStorage.removeItem(key);
          var str = JSON.stringify(translations);
          localStorage.setItem(key, str);

          this.tt.setTranslation(langCode, translations);
          this.tt.setDefaultLang(langCode);
          this.tt.use(langCode);
          this.trans = translations;
          this.loadedTranslations = true;

          return;
        })
      )
      .subscribe(langs => { });
  }

  getTransactionIcon(transactionStatus: string) {
    // TODO: finish up
    switch (transactionStatus) {
      case 'Initial':
        return false;
      case 'Processing':
        return false;
    }
  }
}
