import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { SafePipe } from 'src/app/_pipes/safe.pipe';
import { DateAgoPipe } from './date-ago.pipe';
import { FiletypeIcon } from './filetype-icon.pipe';
import { FilterExtractValues } from './filter-extract-values.pipe';
import { FilterOnPipe } from './filter-on.pipe';
import { FilterPipe } from './filter.pipe';
import { NoDataPipe } from './no-data-dash.pipe';
import { TimezonePipe } from './timezone.pipe';
import { TimezonePipe2 } from './timezone2.pipe';
import { ToArrayPipe } from './to-array.pipe';
import { ArrayFormatterPipe } from './array-formatter.pipe';
import { FilterValuePipe } from './filter-value.pipe';
import { DatePipe } from './date.pipe';
import { FirstNotStartedPipe } from './first-not-started.pipe';

@NgModule({
  declarations: [
    SafePipe,
    DateAgoPipe,
    FiletypeIcon,
    FilterExtractValues,
    FilterOnPipe,
    FilterPipe,
    NoDataPipe,
    TimezonePipe,
    TimezonePipe2,
    ToArrayPipe,
    ArrayFormatterPipe,
    FilterValuePipe,
    DatePipe,
    FirstNotStartedPipe
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
  ],
  exports: [
    SafePipe,
    DateAgoPipe,
    FiletypeIcon,
    FilterExtractValues,
    FilterOnPipe,
    FilterPipe,
    NoDataPipe,
    TimezonePipe,
    TimezonePipe2,
    ToArrayPipe,
    ArrayFormatterPipe,
    FilterValuePipe,
    DatePipe,
    FirstNotStartedPipe
  ]
})
export class PipesModule { }
