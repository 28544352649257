import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timezone',
  pure: true
})
export class TimezonePipe implements PipeTransform {
  timezone: any;
  transform(value: any): any {
    if (!value) {
      return '';
    } else {
      value = new Date(new Date(value).getTime() + -1*60000*new Date().getTimezoneOffset())
    }
    this.timezone = value.toString().slice(0, 25);
    return this.timezone;
  }
}