import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how',
  templateUrl: './how.component.html',
  styleUrls: ['./how.component.scss']
})
export class HowComponent implements OnInit {

  dummyText = `<br />
    It’s so much simpler and more convenient to send money online, than having
    to visit an agent or a bank. Just follow these steps to get started:
  </p>
  <p>1. Choose the country you want to send to.</p>
  <p>2. Choose how you want to send your money:</p>

  <p>
    Bank deposit
    <br />
    Mobile money
    <br />
    3. Choose how much you want to send: enter an amount and our low fees and
    exchange rates will be clearly shown.
  </p>

  <p>
    4. Enter your recipient’s details : add a new person by entering their
    details or choose from a list of people you’ve already sent to.
  </p>

  <p>
    5. Pay and relax: Choose how you want to pay – bank account or debit/credit
    card - then confirm the amount.
  </p>
  <p>
    <br />
    Take your first step
  </p>
  <p>
    As you can see we offer a choice of ways to send money, but please remember
    that not every service is available in every country. Whichever service you
    choose, we’ll require certain personal details from you, to ensure each
    transaction is as secure as you’d like it to be.
  </p>

  <p>
    Your money’s there in an instant – because we’re already there! We always
    get your money to the people you love fast. How? We already hold funds in
    all the countries we send to, so there’s no wait. And, as soon as your
    transaction’s complete, you and your recipient will be alerted with an SMS
    and email.
  </p>`;

  constructor() { }

  ngOnInit() {
  }

}
