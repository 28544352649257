<div class="io-item io-item-shadow ">
    <div class="main">
        <div class="data">
            <small>{{'Type'|translate}}</small>
            <span>{{item?.consentType}}</span>
        </div>
        <div class="data" *ngIf="item?.consentType == 'TRANSACTION'">
            <small>{{'Transaction ID'|translate}}</small>
            <span>{{(item?.metadata|toArray)?.TransactionId}}</span>
        </div>
        <div class="data" *ngIf="item?.consentType != 'TRANSACTION'">
            <small>{{''|translate}}</small>
            <span></span>
        </div>
        <div class="data">
            <small>{{'Provided'}}</small>
            <span>{{item?.created | timezone2 | date:"short"}}</span>
        </div>
        <div class="data">
            <small>{{'Consents'|translate}}</small>
            <span>{{(item?.listOfConsents|toArray)?.length || 0}}</span>
        </div>





        <button class="button" mat-icon-button (click)="onShowHistory($event, item)">
            <mat-icon *ngIf="!showHistory">expand_more</mat-icon>
            <mat-icon *ngIf="showHistory">expand_less</mat-icon>
        </button>

    </div>
    <div [ngClass]="{expand:true, open: showHistory}">

        <table class="table-details" style="width: unset">
            <tbody>
                <tr>
                    <td>Name</td>
                    <td>URL</td>
                    <td>Version</td>
                </tr>
                <tr class="history-info" *ngFor="let th of (item?.listOfConsents|toArray)">
                    <th>{{th.Name }}</th>
                    <th class="checkbox-text"><a href="{{th.Link }}">{{th.Link }}</a></th>
                    <th>{{th.Version }}</th>
                </tr>
            </tbody>
        </table>

    </div>
</div>