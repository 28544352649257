import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../components.module';
import { TranslateModule } from '@ngx-translate/core';
import { DocumentListComponent } from './document-list/document-list.component';
import { DocumentUploadComponent } from './document-upload/document-upload.component';



@NgModule({
  declarations: [
    DocumentListComponent,
    DocumentUploadComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    // ComponentsModule,
  ],
  exports: [
    DocumentListComponent,
    DocumentUploadComponent
  ]

})
export class DocumentsModule { }
