<div>
    <table class="table-details" *ngFor="let column of columns; index as idx">
        <tbody *ngIf="getFieldsByColumns(fields, idx) as _flds">

            <tr *ngFor="let field of _flds">
                <th>{{field.label | translate}}</th>
                <td>
                    <span
                        *ngIf="!['territory', 'birthDate', 'nationalityCountryId', 'countryOfBirthId'].includes(field.name)">
                        {{ data[field?.name] }}
                        <b class="tag"
                            *ngIf="field.name == 'phone' && data?.phoneVerificationEnabled == false && data?.phoneVerified == true">{{'Verified'
                            | translate}}</b>
                    </span>
                    <span class="td" *ngIf="field.name =='birthDate' " [title]="data[field?.name]">
                        {{ data[field?.name] | date}}
                    </span>
                    <span class="td" *ngIf="field.name =='territory' ">
                        <span *ngIf="(states$|async|filterOn:['id']:[data[field.name]]) as states">
                            <span *ngFor="let state of states">
                                {{state?.name}}
                            </span>
                            <ng-template *ngIf="states.length == 0">{{data[field.name]}}</ng-template>
                        </span>
                    </span>
                    <span class="td" *ngIf="['nationalityCountryId', 'countryOfBirthId'].includes(field.name)">
                        <span *ngFor="let country of (countries$|async|filterOn:['id']:[data[field.name]])">
                            {{country.name}}
                            <span *ngIf="!country?.isoCode2">{{country?.isoCode2}}</span>
                        </span>


                    </span>
                </td>
            </tr>

            <tr *ngIf="data?.country && idx==columns.length-1 && fields.length">
                <th>{{ 'Country' | translate }}</th>
                <td>{{data?.country?.name || data?.country}}</td>
            </tr>
            <tr *ngIf="data?.language && idx==columns.length-1 && fields.length">
                <th>{{ 'Language' | translate }}</th>
                <td>{{data?.language?.name}}</td>
            </tr>

        </tbody>
    </table>
    <!-- 
<dl *ngIf="fields" class="table-details">
    <dd *ngFor="let field of fields">
        <span class="th">
            {{field.label}}
        </span>
        <span class="td" *ngIf="['territory', 'nationalityCountryId', 'countryOfBirthId'].indexOf(field.name) == -1">
            {{ data[field.name] }}
            <b class="tag"
                *ngIf="field.name == 'phone' && data?.phoneVerificationEnabled == false && data?.phoneVerified == true">{{'Verified'
                | translate}}</b>
        </span>
        <span class="td" *ngIf=" field.name =='territory' ">
            {{(states$|async|filterOn:['id']:[data[field.name]])?.[0]?.name}}
        </span>
        <span class="td" *ngIf="field.name =='countryOfBirthId' ||  field.name =='nationalityCountryId'">
            {{(countries$|async|filterOn:['id']:[data[field.name]])?.[0]?.name}}
        </span>

    </dd>
    <dd *ngIf="data?.country">
        <span class="th">{{ 'Country' | translate }}</span>
        <span class="td">{{ data?.country?.name }}</span>
    </dd>
    <dd *ngIf="data?.language">
        <span class="th">{{ 'Language' | translate }}</span>
        <span class="td">{{ data?.language?.name }}</span>
    </dd>

</dl> -->
</div>