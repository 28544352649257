import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { TransactionChange } from 'src/app/_graphql/schema';
import { EndUserFragmentBasic } from 'src/app/_graphql/shared-fragments';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class AdminTransactionsHistoryService extends BaseService<TransactionChange>{

  selectOneFields = gql`
    fragment TransactionsHistorySelectOneFields on TransactionChange  {
      id
    }`;

  selectAllFields = gql`
    fragment TransactionsHistorySelectAllFields on TransactionChange  {
      created
      createdByName
      id
      note
      transaction { id transactionId }
      
      transactionStatus { id name }
  }
  `;

  constructor(protected apollo: Apollo) {
    super(apollo);
    this.mainScope = "transactionsManagement";
    this.initGql('transactionsHistory', 'transactionsHistory');
  }
}
