import { Component, OnInit, HostListener, Inject, Optional, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserFlagsService } from 'src/app/_services/user/flags.service';
import { PaymentMethodsService } from 'src/app/_services/user/payment-methods.service';
import { UserTicketsService } from 'src/app/_services/user/tickets.service';
import { UserTransactionsService } from 'src/app/_services/user/transactions.service';



@Component({
  selector: 'app-modal-account-add',
  templateUrl: './modal-account-add.component.html',
  styleUrls: ['./modal-account-add.component.scss']
})
export class ModalAccountAddComponent implements OnDestroy {
  iFrameUrl: string = null;
  refetchData = false;

  @HostListener('window:message', ['$event'])
  onMessage(e) {
    if (e.data === 'tango-success') {
      this.refetchData = true;
      this.dialogRef.close(true)
    } else if (e.data === 'tango-error') {
      this.dialogRef.close(false)
    } else if (e.data === 'tango-fail') {
      this.dialogRef.close(false)
    } else if (e.data === 'tango-cancel') {
      this.dialogRef.close(false)
    }
  }

  constructor(
    private dialogRef: MatDialogRef<ModalAccountAddComponent>,
    private snacks: MatSnackBar,
    private router: Router,
    private paymentMethodsService: PaymentMethodsService,
    private userTransactionsService: UserTransactionsService,
    private userFlagsService: UserFlagsService,
    private userTicketsService: UserTicketsService,
    private dialog: MatDialog,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any = {}
  ) {
    var primary = getComputedStyle(document.getElementsByTagName('body')[0]).getPropertyValue('--tng-color-secondary');
    this.iFrameUrl = "/assets/moov/index.html?primaryColor=" + encodeURIComponent(primary) + "&userAccountId=" + data?.userAccountId + "&redirectUrl=" + data?.redirectUrl + "&isSandbox=" + data?.isSandbox + "&accessToken=" + data?.accessToken
  }
  ngOnDestroy(): void {
    if (this.refetchData) {
      this.paymentMethodsService.refetchData();
      this.userTransactionsService.refetchData();
      this.userFlagsService.refetchData();
      this.userTicketsService.refetchData();
      this.userTicketsService.refetchData('dashboard');
    }
  }
}
