import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timezone2',
  pure: true
})
export class TimezonePipe2 implements PipeTransform {
  timezone: any;
  transform(value: any): any {
    if (!value) {
      return '';
    } else {
      value = new Date(new Date(value).getTime() + -1 * 60000 * new Date().getTimezoneOffset())
    }

    return value;
  }
}