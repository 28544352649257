import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscribable } from 'rxjs';
import { AdminTransactionsHistoryService } from 'src/app/_services/admin/admin-transactions-history.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';

import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { PublicService } from 'src/app/_services/public.service';
import { UiService } from 'src/app/_services/ui.service';



@Component({
  selector: 'app-consent-item',
  templateUrl: './consent-item.component.html',
  styleUrls: ['./consent-item.component.scss']
})
export class ConsentItemComponent {
  @Input() item: any;
  error = false;
  receipt: any;
  showHistory = false;
  $history: Subscribable<any>;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public auth: AuthenticationService,
    private ui: UiService,
  ) {

  }


  onShowHistory(event: MouseEvent, transaction: any) {
    event.preventDefault();
    event.stopPropagation();
    this.showHistory = !this.showHistory;
  }


}
