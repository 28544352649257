import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { of, Subscribable } from 'rxjs';
import { catchError, first, map, take } from 'rxjs/operators';
import { BaseService } from './base.service';
import { AdminUserType, PropertyType } from 'src/app/_graphql/schema';


@Injectable({
  providedIn: 'root'
})
export class AdminManagersService extends BaseService<AdminUserType>{

  selectOneFields = gql`
    fragment ManagerSelectOneFields on AdminUserType  {
      assignedNetworkIds
      assignedServiceIds
      accessLevel
      assignedToCountryIds
      country {
        id
        name
        phonePrefix
      }
      email
      firstName
      id
      isActive
      language {
        id
        isDefault
        isoCode2
        isoCode3
        name
      }
      lastName
      middleName
      name
      permissions
      phone
      userName
  }`;

  selectAllFields = gql`
    fragment ManagerSelectAllFields on AdminUserType  {
      email
      firstName
      id
      isActive
      lastName
      middleName
      phone
      userName
      accessLevel
  }`;



  constructor(protected apollo: Apollo) {
    super(apollo);
    // this.filters = false;
    this.initGql('manager');
  }

  public resetPasswordManager(data: any): Subscribable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation resetPasswordForManager($userId: ID, $newPassword: String!) {
          setup {
            resetPasswordForManager(managerId:$userId, newPassword:$newPassword)
          }
        }
      `,
      // refetchQueries: [
      //   ...this.refetchAdditionalQueries
      // ],
      variables: data
    }).pipe(take(1)).pipe(
      first(),
      map((result: any) => {
        if (!result || !result.data || !result.data.setup)
          return null;
        const keys = Object.keys(result.data.setup);

        if (result.data.setup && keys.length) {
          return result.data.setup[keys[0]];
        }
        return null;
      },
        catchError(error => {
          this.log(error);
          return of(null);
        }))
    );
  }

  public changePassword(data: any): Subscribable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation changePassword($oldPassword: String!, $newPassword: String!) {
          employee {
            changePassword(oldPassword:$oldPassword, newPassword:$newPassword)
          }
        }
      `,
      // refetchQueries: [
      //   ...this.refetchAdditionalQueries
      // ],
      variables: data
    }).pipe(take(1)).pipe(
      first(),
      map((result: any) => {
        if (!result || !result.data)
          return null;
        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          return result.data[keys[0]];
        }
        return null;
      },
        catchError(error => {
          this.log(error);
          return of(null);
        }))
    );
  }
  private documentCategoriesWQ = null;

  public roleNames(): Subscribable<PropertyType[]> {
    if (!this.documentCategoriesWQ)
      this.documentCategoriesWQ = this.apollo
        .watchQuery({
          query: gql`
          query roleNames {
            usersManagement { roleNames {type key value description } }
          }
          `
        })
    return this.documentCategoriesWQ
      .valueChanges.pipe(
        map((result: any) => result?.data?.usersManagement?.roleNames)
      );
  }
}
