import { Component, Input } from '@angular/core';
import { CountryType } from 'src/app/_graphql/schema';
import { PublicService } from 'src/app/_services/public.service';


@Component({
selector: 'app-country-flag',
 templateUrl: './country-flag.component.html',
 styleUrls: ['./country-flag.component.scss']
})
export class CountryFlagComponent {
  error:boolean = false;
  errors: any[] = [];

  @Input() item: CountryType = null;
  sizeMap = {
    small: '16',
    medium: '24',
    big: '32',
    large: '48',
    xlarge: '78'
  }

  @Input() size: string = 'medium';


constructor(public publicService: PublicService) { }
hasError(id) {
  return this.errors.indexOf(id) > -1
  }

  setError(id) {
    this.errors.push(id)
  }
}