<div class="container2">
    <div class="center">
        <div class="register-form login-register-form">
            <img class="register-image" src="/assets/images/register.png">
            <div class="image-text">
                <p class="image-text1">{{ 'Transfer money easily' | translate }}</p>
                <p class="image-text2">{{ 'company_name' | translate }}</p>
                <p class="image-text3" routerLink="/login">
                    {{ 'LOG IN' | translate }}
                </p>
            </div>

            <div class="register">
                <h2 *ngIf="isLoaded">{{ 'Enter Details' | translate }}</h2>
                <!-- <app-form-configuration [fieldsGroup]="fromType" (sendForm)="register($event)"
                    (formDataLoaded)="checkIfConfigIsLoaded()" #child class="configuration"></app-form-configuration> -->
                <app-form-config confType="user" (formDataLoaded)="checkIfConfigIsLoaded()"
                    (onSubmitData)="register($event)" #child class="configuration"></app-form-config>
                <div class="btn" *ngIf="isLoaded">
                    <a mat-button color="primary" routerLink="/">{{ 'Cancel' | translate }}</a>
                    <button mat-raised-button color="primary" (click)="child.onSubmit()" [disabled]="disabled"> {{
                        'Register' |
                        translate }} </button>
                </div>
                <div class="account" *ngIf="isLoaded">
                    <p class="account-text1"> {{ 'Already have an account?' | translate }} </p>
                    <a class="account-text2" routerLink="/login">{{ 'Log in here' | translate }}</a>
                </div>
            </div>
        </div>
    </div>
</div>