import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { PublicService } from '../_services/public.service';

@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {
  constructor(
  ) { }

  transform(value: any, format: string = PublicService.getDisplayDateFormat().dateFormatLong): any {
    if (value) {
      if (format === 'short') 
        return moment(value).format(PublicService.getDisplayDateFormat().dateFormatLong); 
      if (format === 'yyyy') 
        return moment(value).format('yyyy');
      if (format === 'MM/yyyy') 
        return moment(value).format('MM/yyyy');
      else {
        return moment(value).format(PublicService.getDisplayDateFormat().dateFormatShort); 
      }
    }
  }
}