<div class="stats io-mx-2 io-my-2">
    <div matRipple (click)="navigateTo('ticketsActive')">
        <small>Active Tickets</small>
        <strong>
            {{(ticketsActive$|async)?.totalCount ?? 0 }}
        </strong>
    </div>
    <span class="separator"></span>
    <div matRipple (click)="navigateTo('ticketsTotal')">
        <small>Total Tickets</small>
        <strong>
            {{(ticketsTotal$|async)?.totalCount ?? 0 }}
        </strong>
    </div>
    <span class="separator"></span>
    <div matRipple (click)="navigateTo('flagsActive')">
        <small>Active Flags</small>
        <strong>
            {{(flagsActive$|async)?.totalCount ?? 0 }}
        </strong>
    </div>
    <span class="separator"></span>
    <div matRipple (click)="navigateTo('flagsTotal')">
        <small>Total Flags</small>
        <strong>
            {{(flagsTotal$|async)?.totalCount ?? 0 }}
        </strong>
    </div>
</div>


<div class="io-pb-2">
    <h3 class="io-px-2">Recent Tickets</h3>
    <app-panel-admin-placeholder *ngIf="(tickets$|async)?.length === 0"></app-panel-admin-placeholder>
    <app-admin-ticket-list-item *ngFor=" let item of (tickets$|async)" [item]="item" (click)="openTicketItem(item)">
        <div class="no-flex io-mr-2 io-mt-1">
            <mat-icon matBadge="{{ item?.unseenMessagesCount || '' }}" matBadgeColor="warn">message</mat-icon>
        </div>
    </app-admin-ticket-list-item>
</div>
<div class="io-pb-2">
    <h3 class="io-px-2">Recent Flags</h3>
    <app-panel-admin-placeholder *ngIf="(flags$|async)?.length === 0"></app-panel-admin-placeholder>
    <app-admin-flags-list-item *ngFor=" let item of (flags$|async)" [item]="item">
        <!-- <div class="no-flex io-mr-2 io-mt-1">
            <mat-icon matBadge="{{ item?.unseenMessagesCount || '' }}" matBadgeColor="warn">message</mat-icon>
        </div> -->
    </app-admin-flags-list-item>
</div>