import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { TranslationType } from 'src/app/_graphql/schema';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class AdminTranslationsService extends BaseService<TranslationType>{

  selectOneFields = gql`
    fragment TranslationSelectOneFields on TranslationType  {
      id key language { id isDefault isoCode2 isoCode3 name } tags value
  }`;

  selectAllFields = gql`
    fragment TranslationSelectAllFields on TranslationType  {
      id key language { id isDefault isoCode2 isoCode3 name } tags value
    }`;

  constructor(protected apollo: Apollo) {
    super(apollo);
    this.initGql('translation');
    this.selectOneQuery = gql`query oneAll($getMoreDetails: Boolean! = false) {
        ${this.mainScope} {
            __typename
            id @skip(if: $getMoreDetails)
            id @include(if: $getMoreDetails)
            translations (pageRequest: { skip: 0, take: 1 }) {
                data {
                    id
                    ... TranslationSelectOneFields
                }
                totalCount
            }
        }
    }
    ${this.selectOneFields}
    `;

  }

}
