import { AfterViewInit, Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { UiService } from 'src/app/_services/ui.service';

import * as M from '../../../../assets/materialize/js/materialize.js';

@Component({
  selector: 'app-onboarding-circles',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent {
  //onboarding component should be updated after recipient added, after transaction added, after kyc is completed
  @Input() onboarding: any;
  onboardingType: any = 'circles';
  options: any = {};
  carousel: any;
  private carouselEl: ElementRef;

  @ViewChild("carouselEl", { static: false }) set content(
    content: ElementRef
  ) {
    if (content) {
      // initially setter gets called with undefined
      this.carouselEl = content;
      if (this.onboarding && this.onboarding.visible)
        this.focusStep()
    }
  }
  constructor(

    public modalLauncherService: ModalLauncherService,
    private router: Router,
    public ui: UiService
  ) {
    // this.service.fetchOnboarding(this.onboardingType).subscribe((result: any) => {
    //   this.onboarding = result;
    // });
  }


  focusStep() {
    this.carousel = M.Carousel.init(this.carouselEl.nativeElement, this.options);
    if (this.onboarding && this.onboarding.steps[1].active && !this.onboarding.steps[2].active)
      this.carousel.set(1);
    else if (this.onboarding && this.onboarding.steps[2].active)
      this.carousel.set(2);
  }

  runStepAction(stepAction) {
    if (stepAction === "CreateRecipient")
      this.modalLauncherService.newRecipient();

    else if (stepAction == "CreateTransaction")
      this.modalLauncherService.sendMoney();

    else if (stepAction == "Verification")
      this.router.navigate(['/', 'platform', 'kyc']);

    else if (stepAction == "ViewTransactions")
      this.router.navigate(['/', 'platform', 'transactions']);
  }
}
