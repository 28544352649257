<mat-stepper [orientation]="(stepperOrientation | async)!" [linear]="true" *ngIf="fieldsConfig.length"
    [ngClass]="{hideSteps: hideSteps}">
    <form [formGroup]="form">
        <mat-step *ngFor="let _section of uniqueFormSections; index as _index" [completed]="isCompleted(_section)"
            [label]="_section?.label">
            <div class="io-steper">
                <mat-form-field #selectedSection
                    *ngIf="oneRequiredDefaultView[_section.group] && _section.name !='consents'">
                    <mat-label>{{ 'Choose' | translate }} {{_section?.label}}</mat-label>
                    <mat-select (valueChange)="showSectionGroup($event, _section.group)"
                        [value]="oneRequiredDefaultView[_section.group]">
                        <mat-option *ngFor="let section  of fieldsConfig| filterOn:['group']:_section.group; index as k"
                            [value]="section.name" ch>
                            {{ section.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div *ngFor="let section  of fieldsConfig | filterOn:['group']:_section.group; index as i">
                    <div *ngIf="_section.group == section.group">
                        <form [formGroupName]="section.name"
                            *ngIf="_section.groupPolicy != 'OneRequired' ||  (section.name == oneRequiredDefaultView[_section.group])">

                            <p *ngIf="section?.description">{{ section?.description}}</p>
                            <div *ngFor="let field of section.fields" [ngClass]="{'io-pb-2': field.description}">
                                <mat-form-field *ngIf="['text', 'email'].indexOf(field?.type)>-1">
                                    <mat-label>{{field?.label}}
                                    </mat-label>
                                    <input matInput [formControlName]="field.name">
                                    <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
                                    <mat-error align="end">{{showErrors(section.name, field.name)}}</mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" *ngIf="field.visible && field.type == 'numbers'">
                                    <mat-label>{{ field.label }}</mat-label>
                                    <mat-error align="end">{{showErrors(section.name, field.name)}}</mat-error>
                                    <input matInput [type]="field.type" [formControlName]="field.name"
                                        [value]="field.value" [required]="field.required" />
                                    <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
                                    <span matPrefix disabled
                                        *ngIf="field.name == 'phone' && form.get(section.name).get(field.name).value"
                                        style="display: block; padding: 7pt 5pt 4.5pt 0pt; opacity: 0.4;">
                                        +{{auth?.user?.country?.phonePrefix}}
                                    </span>

                                    <button mat-icon-button *ngIf="field.name == 'phone'" matSuffix disabled>
                                        <mat-icon>phone</mat-icon>
                                    </button>

                                </mat-form-field>
                                <mat-form-field *ngIf="field.type === 'address-autocomplete'">
                                    <mat-label>{{ field.label }}</mat-label>
                                    <input type="text" matInput [formControlName]="field.name"
                                        [matAutocomplete]="autoGroup">
                                    <mat-autocomplete #autoGroup="matAutocomplete"
                                        (optionSelected)="addressChoosen($event)">
                                        <mat-option *ngFor="let element of addressData | async" [value]="element"
                                            class="autoCompleteOption">
                                            <a>{{ element['description'] | translate }}</a>
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>

                                <!-- <mat-form-field *ngIf="'select' == field?.type">
                                    <mat-label>{{field?.label}}
                                    </mat-label>
                                    <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
                                    <mat-select [formControlName]="field.name">
                                        <mat-option *ngFor="let _v of field.values" [value]="_v.value">{{ _v.label |
                                            translate }}</mat-option>
                                    </mat-select>
                                </mat-form-field> -->
                                <app-single-select placeholder="{{ field.label }}"
                                    *ngIf="field?.visible && field?.type == 'select'" [items]="field?.values"
                                    [formControlName]="field.name" [optionValue]="['label', 'value']"
                                    [hintText]="field?.description">
                                </app-single-select>
                                <app-month-year-date-picker *ngIf="'date' == field?.type 
                	                && (field?.properties | filterOn: ['value']: ['month-picker'])?.length > 0" [field]="field"
                                    [formControlName]="field.name" [required]="field?.required">
                                </app-month-year-date-picker>

                                <div *ngIf="'multiselect' == field?.type">
                                    <div style="margin-top: 24px; margin-bottom: 12px;">{{field?.label}}
                                        <span *ngIf="field?.required">*</span>
                                    </div>
                                    <mat-form-field appearance="fill">
                                        <mat-label>Select options</mat-label>
                                        <mat-select [formControlName]=" field.name" multiple>
                                            <mat-option *ngFor="let _v of field.values" [value]="_v.value">{{ _v.label |
                                                translate }}</mat-option>
                                        </mat-select>
                                        <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div class="checkbox" *ngIf="field.visible && (field.type == 'checkbox')">
                                    <mat-checkbox color="primary" matInput [formControlName]="field.name">
                                        <p [ngClass]="{'checkbox-text': true, 'error': form.get(section.name).get(field.name).errors?.required &&
                                        form.get(section.name).get(field.name).touched}"
                                            [innerHTML]=" field.label | translate"></p>
                                    </mat-checkbox>
                                </div>
                                <div *ngIf="'radio' == field?.type">
                                    <div style="margin-top: 24px; margin-bottom: 12px;">{{field?.label}}
                                        <span *ngIf="field?.required">*</span>
                                        <div *ngIf="field?.description">
                                            <small>{{ field.description }}</small>
                                        </div>
                                    </div>
                                    <mat-radio-group [formControlName]=" field.name">
                                        <div *ngFor="let _v of field.values">
                                            <mat-radio-button color="primary" [value]="_v.value"
                                                style="margin-bottom: 8px;">{{ _v.label
                                                |
                                                translate }}</mat-radio-button>
                                        </div>
                                    </mat-radio-group>
                                </div>
                                <mat-form-field *ngIf="'date' == field?.type &&
                                    (field?.properties | filterOn: ['value']: ['month-picker'])?.length === 0">
                                    <mat-label>{{field?.label}}</mat-label>
                                    <input matInput [matDatepicker]="picker" [formControlName]="field.name"
                                        [min]="getMin(field?.max)" [max]="getMax(field?.min)" />
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>

                                <mat-form-field *ngIf="'gender'==field?.type">
                                    <mat-label>{{field?.label}}</mat-label>
                                    <mat-select [formControlName]="field.name">
                                        <mat-option value="M">{{ 'Male' | translate }}</mat-option>
                                        <mat-option value="F">{{ 'Female' | translate }}</mat-option>
                                    </mat-select>
                                    <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
                                </mat-form-field>

                                <!-- <mat-form-field *ngIf="'state-select'==field?.type">
                                    <mat-label>{{field?.label}}</mat-label>
                                    <mat-select [formControlName]="field.name">
                                        <mat-option *ngFor="let state of states$ | async" [value]="state.id">
                                            {{state.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-hint *ngIf="field?.description">{{ field.description }}</mat-hint>
                                </mat-form-field> -->
                                <app-single-select placeholder="{{ field.label }}"
                                    *ngIf="field.visible && field.type == 'state-select'" [items]="states$ | async"
                                    [formControlName]="field.name" [hintText]="field?.description">
                                </app-single-select>

                                <div class="checkbox" *ngIf="'moov-terms-token'==field?.type">

                                    <mat-checkbox color="primary" matInput [formControlName]="field.name"
                                        (change)="getMoovTermsToken(form.get(section?.name).get(field.name))">
                                        <p class="checkbox checkbox-text" innerHTML="{{field?.label | translate}}">
                                        </p>
                                    </mat-checkbox>
                                </div>


                                <div class="checkbox" *ngIf="'checkboxstring'==field?.type">
                                    <mat-checkbox color="primary"
                                        (change)="toggleFormArrayData(section.name, field.name, field.value ? field.value : field.label)">
                                        <p [ngClass]="{'checkbox-text':true, 'error':form.get(section.name).get(field.name).errors?.required &&
                                        form.get(section.name).get(field.name).touched}"
                                            innerHTML="{{field?.label | translate}}">
                                        </p>
                                    </mat-checkbox>
                                </div>
                                <app-input-field-file [formControlName]="field.name" [label]="field?.label"
                                    [description]="field?.description" *ngIf="'file-upload'==field?.type"
                                    [accept]="field?.pattern" [errors]="form.get(section.name).get(field.name).errors">
                                </app-input-field-file>
                            </div>
                            <div class="actions-bar">
                                <button mat-stroked-button matStepperPrevious color="primary"
                                    *ngIf="_index>0">Back</button>
                                <span></span>
                                <button mat-raised-button matStepperNext color="primary"
                                    data-id="kyc-btn-next-{{section.name}}"
                                    [disabled]="!isCompleted(section)">Next</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </mat-step>
        <mat-step label="Review">
            <div class="io-steper">
                <div *ngFor="let _reviewSection of uniqueFormSections; ">
                    <div
                        *ngFor="let sectionReview  of fieldsConfig | filterOn:['group']:_reviewSection.group; index as i">
                        <div
                            *ngIf="_reviewSection.group == sectionReview.group && shouldShowSectionInReview(sectionReview)">
                            <h4>{{ _reviewSection.label }}</h4>
                            <table>
                                <tr *ngFor="let fld of sectionReview.fields">
                                    <th>
                                        <span innerHTML="{{fld?.label | translate}}"></span>
                                    </th>
                                    <td
                                        *ngIf="['text', 'email', 'numbers', 'address-autocomplete'].indexOf(fld?.type) != -1">
                                        {{form.get(sectionReview?.name).get(fld.name).value}}
                                    </td>
                                    <td *ngIf="'date'==fld?.type && (fld?.properties | filterOn: ['value']: ['month-picker'])?.length === 0">
                                        {{form.get(sectionReview?.name).get(fld.name).value | date }}
                                    </td>
                                    <td *ngIf="'date'==fld?.type && (fld?.properties | filterOn: ['value']: ['month-picker'])?.length > 0">
                                        {{form.get(sectionReview?.name).get(fld.name).value | date :'MM/yyyy' }}
                                    </td>
                                    <td *ngIf="['radio', 'select'].indexOf(fld?.type) != -1">
                                        <span *ngFor="let _el of fld.values">
                                            <span
                                                *ngIf="_el.value == form.get(sectionReview?.name).get(fld.name).value">
                                                {{_el.label}}
                                            </span>
                                        </span>

                                    </td>
                                    <td *ngIf="'gender'==fld?.type">
                                        {{form.get(sectionReview?.name).get(fld.name).value}}
                                    </td>
                                    <td *ngIf="'state-select'==fld?.type">
                                        {{form.get(sectionReview?.name).get(fld.name).value}}
                                    </td>

                                    <td
                                        *ngIf="'multiselect' == fld?.type && fieldsConfig[0]['fields'] |filterOn:['name']:[fld.name] as data">
                                        <div
                                            *ngIf="data.length && ( data[0]['values']|filterOn:['value']:form.get(sectionReview?.name).get(fld.name).value) as niz">
                                            <span *ngFor="let ctr of niz; index as _idx">
                                                {{ctr.label}}<span *ngIf="_idx+2 < niz.length">, </span><span
                                                    *ngIf="_idx+2 == niz.length"> and </span>
                                            </span>
                                        </div>
                                    </td>

                                    <td *ngIf="'moov-terms-token'==fld?.type || 'checkbox'==fld?.type">
                                        <mat-icon *ngIf="form.get(sectionReview?.name).get(fld.name).value">check
                                        </mat-icon>
                                        <mat-icon *ngIf="!form.get(sectionReview?.name).get(fld.name).value">close
                                        </mat-icon>
                                    </td>
                                    <td *ngIf="'checkboxstring'==fld?.type">
                                        <mat-icon
                                            *ngIf="isElementInFormArrayData(sectionReview?.name, fld?.name, fld?.value || fld?.label)>-1">check</mat-icon>
                                        <mat-icon
                                            *ngIf="isElementInFormArrayData(sectionReview?.name, fld?.name, fld?.value || fld?.label)==-1">close</mat-icon>
                                    </td>
                                    <td *ngIf="'file-upload'==fld?.type">
                                        <mat-icon *ngIf="form.get(sectionReview?.name).get(fld.name).value">check
                                        </mat-icon>
                                        <mat-icon *ngIf="!form.get(sectionReview?.name).get(fld.name).value">close
                                        </mat-icon>
                                    </td>
                                </tr>
                            </table>

                        </div>
                    </div>
                </div>
                <div class="actions-bar top-margin">
                    <button mat-stroked-button matStepperPrevious color="primary">Back</button>
                    <button mat-raised-button matStepperNext color="primary" (click)="onSubmit()" [disabled]="loading"
                        data-id="{{'kyc-btn-submit'}}">Submit</button>
                </div>
            </div>
        </mat-step>
    </form>
</mat-stepper>
<!-- <pre>{{simplifiedConfig|json}}</pre>
<pre>{{form.value|json}}</pre> -->