import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-check-results-dialog',
  templateUrl: './check-results-dialog.component.html',
  styleUrls: ['./check-results-dialog.component.scss']
})
export class CheckResultsDialogComponent implements OnInit {

  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CheckResultsDialogComponent>,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      userId: [this.data.userId, [Validators.required]],
      pepHit: [null, [Validators.required]],
      watchListHit: [null, [Validators.required]],
      adverseMediaHit: [null, [Validators.required]]
    })
  }

  onSubmit() {
    this.dialogRef.close(this.form.value);
  }
}
