import { Component, OnInit, ChangeDetectorRef, OnDestroy, ElementRef, ViewChild, HostListener } from '@angular/core';
import { UiService } from 'src/app/_services/ui.service';
import { TranslationsService } from 'src/app/_services/translations.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router, RouterLinkWithHref, RouterOutlet } from '@angular/router';
import { Subscription, Unsubscribable } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { environment } from 'src/environments/environment';
import { PublicService } from 'src/app/_services/public.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UserTransactionsService } from 'src/app/_services/user/transactions.service';
import { RaisedFlagType, Transaction } from 'src/app/_graphql/schema';
import { UserFlagsService } from 'src/app/_services/user/flags.service';
import { UserTicketsService } from 'src/app/_services/user/tickets.service';
import { EndUserService } from 'src/app/_services/user/enduser.service';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent implements OnInit, OnDestroy {
  environment = environment;

  user$: any;
  countries$: Subscription;
  recipientDef$: Subscription;
  updateUserDef$: Subscription;
  isMobile = false;
  user: any;
  tickets: any[] = [];
  warningFlags: any[] = [];
  loading = false;
  mode = "side"; //over
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  navigationEnd$: Subscription;
  title = "";
  setTitle = true;
  raisedFLags$: Unsubscribable;
  userTickets$: Unsubscribable;
  userTransaction$: Unsubscribable;

  constructor(
    public publicService: PublicService,
    public modalService: ModalLauncherService,
    public trans: TranslationsService,
    public auth: AuthenticationService,
    private userTransactionsService: UserTransactionsService,
    private userFlagsService: UserFlagsService,
    private userTicketsService: UserTicketsService,
    private ui: UiService,
    private snack: MatSnackBar,
    private service: EndUserService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private tt: TranslateService,
    private modalLauncherService: ModalLauncherService
  ) {
    this.user$ = this.service.currentUser();
    this.raisedFLags$ = this.userFlagsService.all().subscribe({
      next: (warningFlags: RaisedFlagType[]) => {
        this.warningFlags = warningFlags;
      }, error: e => { }
    });
    this.userTickets$ = this.userTicketsService.all({ notResolved: true }, false, 'notresolved').subscribe({
      next: (tickets: any[]) => {
        this.tickets = tickets;
      }, error: e => { }
    });
    if (this.auth.user)
      this.publicService.getFieldsConfig(this.auth.user?.country?.isoCode2, 'updateUser').subscribe()
    if (this.userTransactionsService.pendingTransId) {
      this.userTransaction$ = this.userTransactionsService.one(this.userTransactionsService.pendingTransId).subscribe({
        next: (x: Transaction) => {
          this.userTransactionsService.pendingTransId = null;
          if (x.continue.enabled) {
            this.modalLauncherService.pay(x);
          }
        }
      });
    }
    this.navigationEnd$ = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setTitle = true;
      }
      if (event instanceof ActivationEnd && this.setTitle) {
        this.setTitle = false;
        this.tt.get(event.snapshot.data?.title).subscribe(trans => {
          this.title = trans;
          this.titleService.setTitle(trans);
        })
      }

    });
    this.ui.snacks.subscribe(message => {
      this.snack.open(message.replace('GraphQL error:', ''), 'Got it', {
        duration: 2000
      });
    });

    this.ui.loaders.subscribe(visible => {
      this.loading = visible;
      window['_tangoLoader'] = visible ? true : false;
      // this.cdRef.detectChanges();
    });
  }
  ngOnDestroy(): void {
    this.userTransaction$?.unsubscribe();
    this.raisedFLags$?.unsubscribe();
    this.userTickets$?.unsubscribe();
    this.navigationEnd$?.unsubscribe()
    this.countries$?.unsubscribe();
    this.recipientDef$?.unsubscribe();
    this.updateUserDef$?.unsubscribe();
    this.tt.get('company_name').subscribe(trans => {
      this.titleService.setTitle(trans)
    })
  }

  ngOnInit() {
    this.onResize();
  }

  shouldCloseNav() {
    if (this.isMobile)
      this.sidenav.close();
  }

  @HostListener("window:resize", [])
  private onResize() {
    this.isMobile = window.innerWidth < 960;
    this.mode = this.isMobile ? "over" : "side";
    if (!this.isMobile) {
      this.sidenav.open();
    } else {
      this.sidenav.close();
    }
  }

}
