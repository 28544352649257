import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { RaisedFlagType, TicketType } from 'src/app/_graphql/schema';
import { BaseService } from '../admin/base.service';


@Injectable({
  providedIn: 'root'
})
export class UserFlagsService extends BaseService<RaisedFlagType>{

  selectOneFields = gql`
    fragment RaisedFlagSelectOneFields on RaisedFlagType  {
      id
      description
      level
      created
      resolved
      flag {
        id
        name
        code
        presentedCode
        presentedColor
        isWarning
      }
      ticket {
        id
        number
        status
      }
  }`;

  selectAllFields = gql`
    fragment RaisedFlagSelectAllFields on RaisedFlagType  {
      id
      description
      level
      created
      resolved
      flag {
        id
        name
        code
        presentedCode
        presentedColor
        isWarning
      }
      ticket {
        id
        number
        status
      }
  }`;

  constructor(protected apollo: Apollo) {
    super(apollo);
    this.mainScope = "user";
    this.filters = {
      level: 'USER',
      resolved: false,
      hasNoTicket: null
    }
    this.initGql('raisedFlag');
  }
}
