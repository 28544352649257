import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-panel-title',
  templateUrl: './panel-title.component.html',
  styleUrls: ['./panel-title.component.scss']
})
export class PanelTitleComponent implements OnInit {
  @Input() heading = 'h1'
  private localTitle = '';
  @Input() set title(title: string) {
    this.localTitle = (title && title.trim()) || '';
  }
  get title(): string { return this.localTitle; }

  private localSubtitle = '';
  @Input() set subtitle(title: string) {
    this.localSubtitle = (title && title.trim()) || '';
  }
  get subtitle(): string { return this.localSubtitle; }

  constructor() { }

  ngOnInit() {
  }

}
