import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserTicketsService } from 'src/app/_services/user/tickets.service';
import { TicketType } from 'src/app/_graphql/schema';
import { Subscribable } from 'rxjs';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';

@Component({
    selector: 'app-tickets-active',
    templateUrl: './tickets-active.component.html',
    styleUrls: ['./tickets-active.component.scss']
})
export class TicketsActiveComponent {
    items$: Subscribable<TicketType[]>;
    @Input() excludeClosed: boolean = false;
    constructor(
        public service: UserTicketsService,
        private mls: ModalLauncherService,
    ) {
        this.items$ = this.service.all({ notResolved: true }, true, 'dashboard')
    }
    openItem(ticket: any) {
        this.mls.openTicket(ticket.id)
    }
}
