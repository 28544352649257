import { Component, OnInit } from '@angular/core';
import { LanguageItemComponent } from './language-item/language-item.component';
import { MatDialog } from '@angular/material/dialog';
import { AdminLanguagesService } from 'src/app/_services/admin/admin-languages.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { AdminCountriesService } from 'src/app/_services/admin/admin-countries.service';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {
  items$: any;
  errors = [];

  constructor(
    public service: AdminLanguagesService,
    public auth: AuthenticationService,
    private dialog: MatDialog,
    public countriesService: AdminCountriesService
  ) {

  }

  ngOnInit() {
    this.items$ = this.service.all(null, false);
  }
  hasError(id) {
    return this.errors.indexOf(id) > -1;
  }
  addError(id) {
    this.errors.push(id)
  }

  public newItem() {
    this.openDialog(LanguageItemComponent, false);
  }
  public editItem(data: any) {
    this.openDialog(LanguageItemComponent, data);
  }
  openDialog(component: any, param = {}) {
    this.dialog.open(component, {
      height: 'auto',
      width: '360px',
      disableClose: true,
      data: param
    });
  }
}
