import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-panel-admin-placeholder',
  templateUrl: './panel-placeholder.component.html',
  styleUrls: ['./panel-placeholder.component.scss']
})
export class PanelPlaceholderAdminComponent implements OnInit {
  @Input() key: string = 'default';
  private localTitle = 'title';
  @Input() set title(title: string) {
    this.localTitle = (title && title.trim()) || '';
  }
  get title(): string { return this.localTitle; }

  private localDescription = 'description';
  @Input() set description(title: string) {
    this.localDescription = (title && title.trim()) || '';
  }
  get description(): string { return this.localDescription; }

  constructor() { }

  ngOnInit() {
  }

}
