import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'filterOn' })
export class FilterOnPipe implements PipeTransform {
    transform(list: any[] | null, path: string[] | string, acceptedIds: string[] | any[], include: string = "include") {
        if (!list || (acceptedIds?.length == 0 && include == 'include')) {
            return [];
        }

        if (typeof path == "string")
            path = [path];
        return list.filter((item: any) => {
            var _item = item;
            for (let p of path) {
                if (_item && _item.hasOwnProperty(p)) {
                    _item = _item[p];
                } else {
                    return false;
                }

            }
            return include === "include" ? acceptedIds?.indexOf(_item) > -1 : acceptedIds?.indexOf(_item) === -1
        });
    }
}

