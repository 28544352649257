<div mat-dialog-title>
    {{ data?.id ? "Edit Currency" : "Add Currency"}}
</div>
<form mat-dialog-content [formGroup]="form">
    <div class="io-row">
        <mat-form-field>
            <mat-label>ISO Code 3</mat-label>
            <input matInput required type="text" formControlName="isoCode">
            <mat-hint>
                3-letter currency code (defined by <a href="https://en.wikipedia.org/wiki/ISO_4217" target="_blank">ISO
                    4217</a>).
            </mat-hint>
        </mat-form-field>
    </div>
    <div class="io-row">
        <mat-form-field>
            <mat-label>fxSpread - % </mat-label>
            <input matInput required type="number" formControlName="fxSpread">
            <mat-hint>
                A fxSpread is the difference between the bid price and the ask price of a currency pair.
            </mat-hint>
        </mat-form-field>
    </div>

</form>

<mat-dialog-actions align="end">
    <button mat-button color="primary" mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid" color="primary"
        (click)="onSubmit()">Save</button>
</mat-dialog-actions>