import { Component, OnDestroy } from '@angular/core';
import { Subscribable } from 'rxjs';
import { UiService } from 'src/app/_services/ui.service';
import { AdminTicketsService } from 'src/app/_services/admin/admin-tickets.service';
import { TicketType } from 'src/app/_graphql/schema';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { MessagesService } from 'src/app/_services/messages.service';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { TranslationsService } from 'src/app/_services/translations.service';

@Component({
  selector: 'app-admin-ticket-and-flags-wrapper',
  templateUrl: './admin-ticket-and-flags-wrapper.component.html',
  styleUrls: ['./admin-ticket-and-flags-wrapper.component.scss']
})
export class AdminTicketsAndFlagsWrapperComponent implements OnDestroy {
  navLinks = [];

  constructor(
    public trans: TranslationsService,
    public auth: AuthenticationService,
  ) {
    this.navLinks.push({
      path: "/admin/tickets-and-flags",
      label: "Overview",
      active: true
    });
    this.navLinks.push({
      path: "tickets",
      label: "Tickets",
      active: false
    });
    this.navLinks.push({
      path: "flags",
      label: "Flags",
      active: false
    });

  }
  ngOnDestroy(): void {

  }

  ngOnInit() { }
}
