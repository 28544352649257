<div mat-dialog-title *ngIf="!data.changeDocument">{{ data.file ? 'Edit file' : 'Add file' }}</div>
<div mat-dialog-title *ngIf="data.changeDocument">{{ 'Replace Document in Transaction' }}</div>
{{this.data.ticketId}}
<div *ngIf="!data.changeDocument">
    <mat-dialog-content>

        <p style="word-break: break-all;">{{ showDocuments ? "File name:" : "Choose the file to upload:" }}</p>
        <div class="uploaded-files">
            <div style="margin-left: 20px;">
                <button *ngIf="!data.file && !showDocuments" mat-icon-button color="primary" (click)="uploader.click()">
                    <mat-icon>attachment</mat-icon>
                </button>
            </div>
            <div *ngIf="filesUploaded">
                <div *ngFor="let file of filesUploaded" class="io-row">
                    <p>{{ file.name }}</p>
                    <button *ngIf="!data.file" mat-icon-button (click)="deleteFile(file.id)" color="primary"
                        class="no-flex">
                        <mat-icon class="delete-icon">close</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <!-- && !assignedDocument -->
        <!-- <p *ngIf="assignedDocument">Currently assigned to <strong>{{ data.file?.document?.category?.name }}</strong>.</p> -->
        <div *ngIf="showDocuments">
            <p>Attach the file to the document:</p>
            <mat-form-field appearance="outline">
                <mat-label>Documents</mat-label>
                <mat-select [(value)]="serviceSelected" (selectionChange)="changeDocument($event.value)">
                    <mat-option value="{{ document.id }}" *ngFor="let document of documents$|async"> {{
                        document.category.name }} - {{ document.documentNumber }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <input matInput [(ngModel)]="description">
            </mat-form-field>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <input hidden type="file" #uploader (change)="handleFileInput($event.target.files)" />
        <button mat-button color="primary" mat-dialog-close>{{ 'Close' | translate }}</button>
        <button mat-raised-button color="primary" [disabled]="!showDocuments || submited" (click)="submitForm()">{{
            'Save' | translate }}</button>
    </mat-dialog-actions>
</div>

<div *ngIf="data.changeDocument" [formGroup]="dataForm">
    <p>Select sender's document:</p>
    <mat-form-field appearance="outline">
        <mat-label>Documents</mat-label>
        <mat-select formControlName="documentId">
            <mat-option disabled *ngIf="!(documents$ | async)?.length">
                {{ "List is empty" | translate }}
              </mat-option>
            <div *ngFor="let document of documents$|async">
                <mat-option [value]="document.id" *ngIf="document.id != data.transactionSdocId">{{
                    document.category.name }} - {{ document.documentNumber }}</mat-option>
            </div>
        </mat-select>
    </mat-form-field>
</div>

<mat-dialog-actions align="end" *ngIf="data.changeDocument">
    <button mat-button color="primary" mat-dialog-close>{{ 'Close' | translate }}</button>
    <button mat-raised-button color="primary" [disabled]="!dataForm.valid  || submited"
        (click)="submitChangeDocument()">{{ 'Save' | translate }}</button>
</mat-dialog-actions>