<div>
  <br>
  <table class="index-table">
    <tbody>
      <tr>
        <th>{{ 'Recipient' | translate }}</th>
        <td>
          {{ item?.recipientFirstName }} {{ item?.recipientLastName }}
        </td>
      </tr>
      <tr>
        <th></th>
        <td>
          {{ item?.recipientCity }}
        </td>
      </tr>
      <tr>
        <th></th>
        <td>
          {{ item?.recipientPhone }}
        </td>
      </tr>
      <tr>
        <th>{{ 'Send Amount' | translate }}</th>
        <td>{{ item?.amount | number: '1.2-2'}}<a> {{item?.currency}}</a></td>
      </tr>
      <tr>
        <th>{{ 'Fee' | translate }}</th>
        <td>{{ item?.fixedFeePaid + item?.variableFeePaid | number: '1.2-2'}}<a>
            {{item?.currency}}</a></td>
      </tr>
      <tr>
        <th>{{ 'Exchange Rate' | translate }}</th>
        <td><a>{{item?.currency}} 1 = {{item?.receivingCurrency }} </a>{{ item?.quote |
          number: '1.6-6' }}</td>
      </tr>
      <tr>
        <td colspan="2">
          <div class="info-manual"></div>
        </td>
      <tr>
        <th>{{ 'Total to Pay' | translate }}</th>
        <td style="color: #011227; font-size: 20px;">{{ item?.paidAmount | number: '1.2-2' }}<a>
            {{item?.currency}}</a></td>
      </tr>
      <tr>
        <th>{{ 'Recipient Receives' | translate }}</th>
        <td style="color: #011227; font-size: 14px;">{{ item?.receivingAmount | number: '1.2-2' }}<a>
            {{item?.receivingCurrency}}</a></td>
      </tr>
      <tr *ngIf="item?.expectedPayoutTime">
        <th>{{ 'Estimated Date Available' | translate }}</th>
        <td>{{ item?.expectedPayoutTime | timezone | date }}</td>
      </tr>
      <tr>
        <th colspan="2" class="checkbox-text io-pt-2">
          <small
            innerHTML="{{'Recipient may receive less due to fees charged by the recipient\'s bank and foreign taxes.'|translate}}">
          </small>
        </th>
      </tr>
    </tbody>
  </table>
</div>