<app-hero></app-hero>

<div class="container">
  <h2>{{ 'page.contact.main_page_title' | translate }}</h2>
</div>

<div class="how-container">
    <h3>{{ 'page.contact.title1' | translate}}</h3>
    <p>
      {{ 'page.contact.content1' | translate}}
    </p>
    <h3>{{ 'page.contact.title2' | translate}}</h3>
    <p>
      {{ 'page.contact.content2' | translate}}
    </p>
    <h3>{{ 'page.contact.title3' | translate}}</h3>
    <p>
      {{ 'page.contact.content3' | translate}}
    </p>
  </div>
    <div class="center">
      <form class="login" #authForm="ngForm" (ngSubmit)="onSend()">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Name' | translate }}</mat-label>
          <input
          matInput
          type="name"
          id="name"
          ngModel
          name="nameFirst"
          first
          (keyup.enter)="onSend()"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ 'Telephone' | translate }}</mat-label>
          <input matInput type="tel" id="tel" ngModel name="tel" tel (keyup.enter)="onSend()"/>
          <mat-icon matSuffix>phone</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{'Message' | translate}}</mat-label>
          <textarea
            matInput
            type="message"
            id="message"
            ngModel
            name="message"
            text
            (keyup.enter)="onSend()"
          >
          </textarea>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ 'Email' | translate }}</mat-label>
          <input matInput type="email" id="email" ngModel name="email" email (keyup.enter)="onSend()"/>
        </mat-form-field>

        <div class="btn">
          <button
            mat-raised-button
            (click)="onSend()"
            color="primary"
            class="contact-button"
          >
            {{ 'Send Now' | translate }}
          </button>
        </div>
      </form>
    </div>

