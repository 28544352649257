<list-wrapper #wrapper [mainService]="service" [panelTitle]="" [managePerm]="auth.perm.TransactionsManage"
  [readPerm]="auth.perm.TransactionsRead" [hideAddBtn]="true" [customKey]="customRefreshKey">
  <div class="io-py-1">
    <div class="io-item io-item-shadow" *ngFor="let item of items$|async; let i = index">
      <div class="main">
        <div class="flag">
          <app-country-flag [item]="item?.country" [size]="'big'"></app-country-flag>
        </div>
        <div class="data">
          <small>{{'Name'|translate}}</small>
          <span>{{item?.name}}&nbsp;</span>
        </div>
        <div class="data">
          <small>{{'Country'|translate}}</small>
          <span>{{ item?.country?.name }}&nbsp;</span>
        </div>
        <div class="data">
          <small>{{'City'|translate}}</small>
          <span>{{ item?.city }}&nbsp;</span>
        </div>
        <div class="data">
          <small>{{'Phone'|translate}}</small>
          <span>{{ item?.phoneCode }}&nbsp;</span>
        </div>

        <div class="data">
          <small>{{'Delivered'|translate}}</small>
          <span>{{ item.sendingCurrency }} {{ item?.moneySent }}</span>
        </div>
        <button mat-stroked-button class="btn" [routerLink]="['../transactions/recipient/'+ item.id]">{{
          'Transactions'
          | translate }}</button>


      </div>
    </div>
  </div>
</list-wrapper>