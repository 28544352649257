<list-wrapper #wrapper [mainService]="service" panelTitle="Translations" [managePerm]="auth.perm.CompanyManage"
  [readPerm]="auth.perm.CompanyManage" (onNew)="newItem()" (onEdit)="editItem($event)">
  <form [formGroup]="form" class="io-row-m width-100" toolbox>
    <mat-form-field class="width-100 width-mff" appearance="outline">
      <mat-label>{{'Search'|translate}}</mat-label>
      <input matInput formControlName="query" placeholder="{{'Title or description'|translate}}">
      <button mat-icon-button matSuffix type="button" (click)="form.patchValue({'query': null})"
        [disabled]="!form.get('query').value">
        <mat-icon *ngIf="form.get('query').value">clear</mat-icon><mat-icon
          *ngIf="!form.get('query').value">search</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="width-100 width-mff" appearance="outline">
      <mat-label>Tags</mat-label>
      <mat-select multiple formControlName="tags">
        <mat-option *ngFor="let itm of translationTags$|async" [value]="itm">{{ itm }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <!-- <pre>
  {{languages | json}}
  </pre> -->
  <mat-action-list [multiple]="false" *ngIf="items">
    <mat-list-item *ngFor=" let _key of items|filterExtractValues:['key']; last as last;" (click)="wrapper.edit(_key)">
      <mat-icon matListIcon [color]="languages.length == (items | filterOn:['key']:[_key]).length ? 'primary': 'warn'">
        translate
      </mat-icon>
      <span class="bdges" *ngIf="items|filterOn:['key']:[_key]?.[0] as _trans">
        <span class="tag" *ngFor="let tag of _trans?.[0]?.tags">{{tag}}</span>
      </span>
      <h4 mat-line>
        {{ _key }}

      </h4>
      <p mat-line>Translated on {{(items | filterOn:['key']:[_key]).length }} of {{languages.length }} languages</p>
      <p mat-line *ngIf="items|filterOn:['key']:[_key]?.[0] as _trans">
        {{ _trans?.[0]?.language?.name }} : {{ _trans?.[0]?.value }}



      </p>
      <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
    </mat-list-item>
  </mat-action-list>
</list-wrapper>