import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { LanguageType } from 'src/app/_graphql/schema';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class AdminLanguagesService extends BaseService<LanguageType>{

  selectOneFields = gql`
    fragment LanguageSelectOneFields on LanguageType  {
      id isDefault isoCode2 isoCode3 name
  }`;

  selectAllFields = gql`
    fragment LanguageSelectAllFields on LanguageType  {
      id isDefault isoCode2 isoCode3 name
    }`;

  constructor(protected apollo: Apollo) {
    super(apollo);
    this.filters = false;
    this.initGql('language');
  }
}
