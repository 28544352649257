import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UiService } from 'src/app/_services/ui.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminRelationshipsService } from 'src/app/_services/admin/admin-relationships.service';
import { CustomValidators } from 'src/app/_helpers/custom.validators';

@Component({
  selector: 'app-relationships-item',
  templateUrl: './relationships-item.component.html',
  styleUrls: ['./relationships-item.component.scss']
})
export class RelationshipsItemComponent {

  form = this.fb.group({
    id: [this.data.id],
    name: [this.data.name, [Validators.required, CustomValidators.noWhitespaceValidator()]],
    isActive: [this.data.isActive || false, Validators.required],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private service: AdminRelationshipsService,
    private ui: UiService,
    private snack: MatSnackBar,
    private dialogRef: MatDialogRef<RelationshipsItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }



  onSubmit() {
    console.log(this.data)
    if (this.form.valid) {
      var d = this.form.value;
      var s = !d.id ? this.service.create(d) : s = this.service.modify(d);
      var k = s.subscribe({
        next: x => {
          this.ui.snack('Relationship ' + (d.id ? 'saved!' : 'created!'));
          k?.unsubscribe();
          this.dialogRef.close();
        }
      });
    }
  }
}


