import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-outbound-status',
  templateUrl: './outbound-status.component.html',
  styleUrls: ['./outbound-status.component.scss']
})
export class OutboundStatusComponent {
  _status: string;
  @Input() iconOnly: Boolean = false;
  get status(): any {
    return {}
  }
  @Input() set status(value: any) {
    if (value) {
      this._status = value
    }
  }


  constructor(
  ) { }
}
