<div mat-dialog-title>
  {{ data ? "Edit Language" : "Add Language"}}
</div>

<form mat-dialog-content [formGroup]="form">
  <div class="io-row">
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput required formControlName="name">
    </mat-form-field>
  </div>
  <div class="io-row">
    <mat-form-field appearance="outline">
      <mat-label>ISO Code 2</mat-label>
      <input matInput required maxlength="2" formControlName="isoCode2">
      <mat-hint>2-letter language code (defined by <a href="https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes"
          target="_blank">ISO 639-1</a>).</mat-hint>
    </mat-form-field>
  </div>
  <div class="io-row">
    <mat-form-field appearance="outline">
      <mat-label>ISO Code 3</mat-label>
      <input matInput required maxlength="3" formControlName="isoCode3">
      <mat-hint>3-letter language code (defined by <a href="https://en.wikipedia.org/wiki/ISO_639-2" target="_blank">ISO
          639-2</a>).</mat-hint>
    </mat-form-field>
  </div>
</form>

<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid" (click)="submitForm()">Save</button>
</mat-dialog-actions>