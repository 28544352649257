import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionnaireComponent } from './questionnaire.component';
import { FormConfigsModule } from 'src/app/components/form-configs/form-configs.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    QuestionnaireComponent
  ],
  imports: [
    CommonModule,
    FormConfigsModule,
    TranslateModule.forChild(),
  ], 
  exports: [
    QuestionnaireComponent
  ]
})
export class QuestionnaireModule { }
