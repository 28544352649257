<div mat-dialog-title>
    {{ title|translate }}
</div>

<form [formGroup]="form" (submit)="onSubmit()">
    <div class="content">
        <p>{{ content|translate }}</p>
        <mat-form-field appearance="outline" *ngIf="['Stopped', 'Delivered'].indexOf(form.get('status').value) > -1 && data?.transaction?.paymentServiceCode === 'CPICK'">
            <mat-label>{{ 'Pickup Code' | translate }}</mat-label>
            <input matInput required formControlName="pickupCode">
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="form.get('status').value == 'Stopped'">
            <mat-label>{{ 'Remark' | translate }}</mat-label>
            <textarea matInput required formControlName="note"></textarea>
        </mat-form-field>
    </div>
    <!-- <pre>{{ form.value | json}}</pre> -->
    <mat-dialog-actions [formGroup]="form" align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!form.valid" type="submit">
            {{ title|translate }}
        </button>
    </mat-dialog-actions>
</form>