<div class="io-steper">
        <div *ngFor="let _reviewSection of uniqueFormSections">
            <div
                *ngFor="let sectionReview  of fieldsConfig | filterOn:['group']:_reviewSection.group; index as i">
                <div
                    *ngIf="_reviewSection.group == sectionReview.group && shouldShowSectionInReview(sectionReview)">
                    <h4>{{ _reviewSection.label }}</h4>
                    <table>
                        <tr *ngFor="let fld of sectionReview.fields">
                            <th>
                                <span innerHTML="{{fld?.label | translate}}"></span>
                            </th>
                            <td
                                *ngIf="['text', 'email', 'numbers', 'address-autocomplete'].indexOf(fld?.type) != -1">
                                {{form.get(sectionReview?.name).get(fld.name).value}}
                            </td>
                            <td *ngIf="'date'==fld?.type && (fld?.properties | filterOn: ['value']: ['month-picker'])?.length === 0">
                                {{form.get(sectionReview?.name).get(fld.name).value | date }}
                            </td>
                            <td *ngIf="'date'==fld?.type && (fld?.properties | filterOn: ['value']: ['month-picker'])?.length > 0">
                                {{form.get(sectionReview?.name).get(fld.name).value | date :'MM/yyyy' }}
                            </td>
                            <td *ngIf="['radio', 'select'].indexOf(fld?.type) != -1">
                                <span *ngFor="let _el of fld.values">
                                    <span
                                        *ngIf="_el.value == form.get(sectionReview?.name).get(fld.name).value">
                                        {{_el.label}}
                                    </span>
                                </span>

                            </td>
                            <td *ngIf="'gender'==fld?.type">
                                {{form.get(sectionReview?.name).get(fld.name).value}}
                            </td>
                            <td *ngIf="'state-select'==fld?.type">
                                {{form.get(sectionReview?.name).get(fld.name).value}}
                            </td>

                            <td
                                *ngIf="'multiselect' == fld?.type && fieldsConfig[0]['fields'] |filterOn:['name']:[fld.name] as data">
                                <div
                                    *ngIf="data.length && ( data[0]['values']|filterOn:['value']:form.get(sectionReview?.name).get(fld.name).value) as niz">
                                    <span *ngFor="let ctr of niz; index as _idx">
                                        {{ctr.label}}<span *ngIf="_idx+2 < niz.length">, </span><span
                                            *ngIf="_idx+2 == niz.length"> and </span>
                                    </span>
                                </div>
                            </td>

                            <td *ngIf="'moov-terms-token'==fld?.type || 'checkbox'==fld?.type">
                                <mat-icon *ngIf="form.get(sectionReview?.name).get(fld.name).value">check
                                </mat-icon>
                                <mat-icon *ngIf="!form.get(sectionReview?.name).get(fld.name).value">close
                                </mat-icon>
                            </td>
                            <td *ngIf="'checkboxstring'==fld?.type">
                                <mat-icon
                                    *ngIf="isElementInFormArrayData(sectionReview?.name, fld?.name, fld?.value || fld?.label)>-1">check</mat-icon>
                                <mat-icon
                                    *ngIf="isElementInFormArrayData(sectionReview?.name, fld?.name, fld?.value || fld?.label)==-1">close</mat-icon>
                            </td>
                            <td *ngIf="'file-upload'==fld?.type">
                                <mat-icon *ngIf="form.get(sectionReview?.name).get(fld.name).value">check
                                </mat-icon>
                                <mat-icon *ngIf="!form.get(sectionReview?.name).get(fld.name).value">close
                                </mat-icon>
                            </td>
                        </tr>
                    </table>

                </div>
            </div>
        </div>
    <ng-content [form]="form" [fieldsConfig]="fieldsConfig" [uniqueFormSections]="uniqueFormSections" select="[submit]"></ng-content>
    </div>