<div class="io-item io-item-shadow clickable">
    <div class="main">
        <div class="flag">
            <mat-icon matListIcon [ngClass]="(item?.accessLevel?.indexOf('PAYOUT_AGENT')=== -1 || !item?.accessLevel) ? 'managerIcon' : 'payoutIcon'">person</mat-icon>
        </div>
        <div class="data name">
            <small color="primary">{{'Name'|translate}}</small>
            <span>{{ item?.firstName }} {{ item?.middleName }} {{ item?.lastName }}&nbsp;</span>
        </div>
        <div class="data phone" matTooltip="{{ formatProvidedData(item) | arrayFormatter }}">
            <small>{{'Role'|translate}}</small>
            <span>{{ formatProvidedData(item) | arrayFormatter }} &nbsp;</span>
        </div>
        <div class="data phone">
            <small>{{'Phone'|translate}}</small>
            <span>{{ item?.phone }}&nbsp;</span>
        </div>
        <div class="data email">
            <small>{{'E-mail'|translate}}</small>
            <span>{{ item?.email }}&nbsp;</span>
        </div>
        <div class="no-flex button">    
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="changePassword(item)">
                <mat-icon>vpn_key</mat-icon>
                <span>Change Password</span>
              </button>
            </mat-menu>
        </div>
        
    </div>
</div>