import { Component, OnInit, Input } from '@angular/core';
import { Subscribable } from 'rxjs';
import { PublicService } from 'src/app/_services/public.service';
import { CountryType, StateType } from 'src/app/_graphql/schema';


interface JsonFormControls {
  description: string
  editable: boolean
  group: string
  groupPolicy: string
  label: string
  max: string
  maxLength: string
  min: string
  minLength: string
  name: string
  orderNumber: string
  pattern: string
  required: boolean
  section: string
  sectionName: string
  type: string
  value: string
  visible: boolean
}

export interface JsonFormData {
  fields: JsonFormControls[];
}

@Component({
  selector: 'form-data-display-configuration',
  templateUrl: './form-data-display-configuration.component.html',
  styleUrls: ['./form-data-display-configuration.component.scss'],
})
export class FormDataDisplayConfigurationComponent implements OnInit {
  hide = true;
  fields: JsonFormData[];
  filteredFields: JsonFormData[];

  _data: any;

  _columns = [0, 1];
  @Input()
  public set columns(v: any) {
    this._columns = Array(v).fill(0).map((x, i) => i);
  }
  public get columns() {
    return this._columns;
  }
  _fieldsGroup: string;
  @Input()
  public set fieldsGroup(v: any) {
    this._fieldsGroup = v;
    this.getFields();
  }
  public get fieldsGroup() {
    return this._fieldsGroup;
  }

  _country: string;
  @Input()
  public set country(v: any) {
    if (v) {

      this.states$ = this.publicService.states(v);
      this._country = v;
      this.getFields();
    }
  }
  public get country() {
    return this._country;
  }
  @Input()
  public set data(v: any) {
    this._data = v;
    this.getFields();
  }
  public get data() {
    return this._data;
  }
  @Input() hideEmptyFields: boolean = false;

  states$: Subscribable<StateType[]>;
  countries$: Subscribable<CountryType[]>;

  constructor(
    private publicService: PublicService,
  ) {
    this.countries$ = this.publicService.countries(null, null, null);

  }

  getFields() {
    if (!this.country || !this.fieldsGroup || this.fields != null)
      return;
    this.fields = []

    var s = this.publicService.getFieldsConfig(this.country, this.fieldsGroup).subscribe({
      next: x => {

        if (x?.length && x?.[0]?.fields?.length) {

          this.fields = x?.[0].fields.filter(el => el.visible == true);
          if (this.hideEmptyFields) {
            this.fields = this.fields.filter(field => this.data.hasOwnProperty(field['name']));
          }
        }
        s.unsubscribe();
      }, error: e => {
        s.unsubscribe();
      }
    });
    return this.fields;
  }

  ngOnInit(): void {

  }

  getFieldsByColumns(fields, _column) {
    if (fields && this.columns && this.columns.length > 1) {
      var column = _column + 1
      var numOfCols = this.columns.length
      var numOfFields = (fields.length + 1);
      var fieldsPerColumn = Math.ceil(numOfFields / numOfCols);
      var flds = fields.slice(_column * fieldsPerColumn, (column == numOfCols ? numOfFields : column * fieldsPerColumn));
      return flds;
    }
    return fields;
  }



}

