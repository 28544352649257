<app-hero></app-hero>
<div class="container">
  <h2>{{ 'page.term.main_page_title' | translate }}</h2>
</div>

<div class="how-container">
  <p innerHTML="{{ 'page.term.content' | translate }}">
    {{ 'page.term.content' | translate }}
  </p>
</div>
  