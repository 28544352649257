import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import moment from 'moment';
import { Moment } from 'moment';
import { FieldConfigType } from 'src/app/_graphql/schema';
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-month-year-date-picker',
  templateUrl: './month-year-date-picker.component.html',
  styleUrls: ['./month-year-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MonthYearDatePickerComponent
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class MonthYearDatePickerComponent implements ControlValueAccessor, OnInit {

  $listener;
  @Input() field: FieldConfigType;
  @Input() required: boolean = false;
  @Input() imageAttached = false;
  @Input() imageDisplayType = 'large';
  @Output() hideImage = new EventEmitter();
  @Output() showImage = new EventEmitter();
  @Output() openImageDialog = new EventEmitter();




  control: FormControl = new FormControl(null, this.required ? [Validators.required] : null);

  value = null;

  constructor(
  ) { }
  ngOnDestroy(): void { }
  ngOnInit(): void {
    this.$listener = this.control.valueChanges.subscribe({
      next: x => {
        this.writeValue(x);
        this.onChange(x);
      }
    })
  }
  onChange = (value) => { };

  onTouched = () => { };

  touched = false;

  disabled = false;



  writeValue(value: any) {
    this.value = value;
    if (value && !moment.isMoment(value))
      this.control.setValue(moment.utc(value), { emitEvent: false });
  }
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
 
    normalizedMonthAndYear.date(1);
    this.control.setValue(moment.utc(normalizedMonthAndYear));
    datepicker.close();

  }
  getMin(data: any) {
    var currentYear = new Date().getFullYear();
    var currDate = new Date().getDate() + 1;
    var currMonth = new Date().getMonth();
    if (data === null) { return; }
    return new Date(currentYear, currMonth + data, currDate);
  }

  getMax(data: any) {
    var currentYear = new Date().getFullYear();
    var currDate = new Date().getDate() + 1;
    var currMonth = new Date().getMonth();
    if (data === null) { return; }
    return new Date(currentYear - data, currMonth, currDate);
  }
  hideImageComponent() {
    this.hideImage.emit(this.field);
  }
  showImageComponent(event = null) {
    this.showImage.emit(event);
  }
  openImageDialogComponent() {
    this.openImageDialog.emit();
  }

}
