import { Component, Input, OnInit, QueryList, ViewChildren } from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";

@Component({
  selector: 'app-input-code',
  templateUrl: "input-code.component.html",
  styleUrls: ["input-code.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputCodeComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: InputCodeComponent
    }
  ]
})
export class InputCodeComponent implements OnInit, ControlValueAccessor, Validator {

  code = "";

  @Input()
  increment: number;

  onChange = (code) => { };

  onTouched = () => { };

  touched = false;

  disabled = false;



  writeValue(code: string) {
    this.code = code;
    this.populateForm()
  }

  registerOnChange(onChange: any) {

    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.confirmCodeForm.disable()
    } else {
      this.confirmCodeForm.enable()
    }
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const code = control.value;
    if (code?.length != this.numOfDigits || code < 0) {
      return {
        mustBePositive: {
          code
        }
      };
    }
  }

  // UI
  @Input() numOfDigits = 4;
  @ViewChildren('inputs') inputs: QueryList<any>;
  confirmCodeForm: FormGroup;
  constructor(private fb: FormBuilder) {
    this.confirmCodeForm = this.fb.group({
      digits: this.fb.array([])
    });
  }
  ngOnInit() {
    for (let i = 0; i < this.numOfDigits; i++) {
      (this.confirmCodeForm.get('digits') as FormArray).push(this.fb.control(null))
    }

  }
  getDigits(): FormArray {
    return (this.confirmCodeForm.get('digits') as FormArray)
  }
  populateForm() {
    for (let i = 0; i < this.numOfDigits; i++) {
      if (this.code?.length >= i)
        this.getDigits().at(i).setValue(this.code[i])
      else
        this.getDigits().at(i).setValue(null)
    }
  }
  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData.getData('text');
    var text = clipboardData.match(/\d/g)?.join("");
    if (text.length > this.numOfDigits)
      text = text.slice(0, this.numOfDigits);
    this.writeValue(text)
    this.updateValue();
  }
  check(index, field, event) {
    if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace' && event.key !== 'Enter' && event.key !== 'Tab') {
      console.log(event.key)
      event.preventDefault();
    }
    if (field.value && event.key !== 'Backspace') {
      if (index < this.inputs.toArray().length - 1) {
        this.inputs.toArray()[index + 1].nativeElement.focus();
      }
    } else if (event.key === 'Backspace') {
      if (index > 0) {
        field.setValue(null)
        this.inputs.toArray()[index - 1].nativeElement.focus();
      } else {
      }
    }
  }
  updateValue() {
    var val = this.getDigits().value.join('');
    this.onChange(val)
  }
}