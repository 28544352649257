<div [ngClass]="{'large':mode =='large', 'default':mode =='default', 'row align-items-center':mode =='default'}">
  <div [ngClass]="{'details':true, 'row align-items-center':mode =='default'}">
    <img [src]="image" *ngIf="image" />
    <strong>{{ title | translate }}</strong>
    <small>{{ description | translate }}</small>
    <div *ngIf="mode == 'large'">&nbsp;</div>
    <div class="no-flex">
      <ng-content></ng-content>
    </div>
  </div>
</div>