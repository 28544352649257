import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UiService } from "src/app/_services/ui.service";
import { Subscribable } from "rxjs";
import { ModalLauncherService } from "src/app/_services/modal-launcher.service";
import { PublicService } from "src/app/_services/public.service";
import { EndUserService } from "src/app/_services/user/enduser.service";
import { UserTransactionsService } from "src/app/_services/user/transactions.service";
import { Transaction } from "src/app/_graphql/schema";

@Component({
  selector: "app-transactions-box",
  templateUrl: "./transactions-box.component.html",
  styleUrls: ["./transactions-box.component.scss"],
})
export class TransactionsBoxComponent implements OnInit, OnDestroy {
  transactions$: Subscribable<Transaction[]>;
  user$: any;

  private widgetsContent: ElementRef;
  @ViewChild("widgetsContent", { static: false }) set content(
    content: ElementRef
  ) {
    if (content) {
      // initially setter gets called with undefined
      this.widgetsContent = content;
    }
  }

  constructor(
    public publicService: PublicService,
    private usersService: EndUserService,
    public userTransactionsService: UserTransactionsService,
    public modalLauncherService: ModalLauncherService,
    public ui: UiService,
  ) {
    this.user$ = this.usersService.currentUser();
    this.transactions$ = this.userTransactionsService.all(null, false);
  }

  ngOnInit() {
  }
  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft + 350,
      behavior: "smooth",
    });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft - 350,
      behavior: "smooth",
    });
  }




  ngOnDestroy() {

  }
}
