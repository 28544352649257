import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class AdminRelationshipsService extends BaseService<any>{

  selectOneFields = gql`
    fragment RelationshipSelectOneFields on RelationshipType  {
      id isActive name
  }`;

  selectAllFields = gql`
    fragment RelationshipSelectAllFields on RelationshipType  {
      id isActive name
  }`;

  constructor(protected apollo: Apollo) {
    super(apollo);
    this.filters = { active: null };
    this.initGql('relationship');
  }
}
