<mat-sidenav-container>
  <mat-sidenav #drawer class="sidenav" fixedInViewport position="end" mode="over">
    <mat-nav-list>
      <a mat-list-item (click)="drawer.toggle()" *ngFor="let link of publicNavLinks" [href]="link.url">{{ link.label |
        translate }}</a>

      <a mat-list-item *ngIf="!auth.isLoggedIn(true)" (click)="drawer.toggle()" routerLink="/login">{{ 'Log In' |
        translate }}</a>
      <a mat-list-item class="button2 register-button" *ngIf="!auth.isLoggedIn(true)" (click)="drawer.toggle()"
        routerLink="/register">{{ 'Register' | translate }}</a>

      <a mat-list-item *ngIf="auth.isLoggedIn(true)" (click)="logout(); drawer.toggle()">{{ 'Log Out' | translate }}</a>
      <a mat-list-item class="button2" *ngIf="auth.isLoggedIn(true)" (click)="drawer.toggle()" routerLink="/platform">{{
        'Platform' | translate }}</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class="toolbar">
      <a href="{{ environment.homePageLink }}" class="main-logo-public">
      </a>
      <span class="spacer"></span>
      <button class="d-show d-l-none" type="button" aria-label="Toggle sidenav" mat-icon-button
        (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <nav class="sidenav2 d-none d-l-show">
        <a mat-button *ngFor="let link of publicNavLinks" [href]="link.url">{{ link.label | translate }}</a>

        <a mat-button *ngIf="!auth.isLoggedIn(true)" routerLink="/login">{{ 'Log In' | translate }}</a>
        <a mat-button class="button2 register-button" *ngIf="!auth.isLoggedIn(true)" routerLink="/register">{{
          'Register' | translate }}</a>

        <a mat-button *ngIf="auth.isLoggedIn(true)" (click)="logout();">{{ 'Log Out' | translate }}</a>
        <a mat-button class="button2" *ngIf="auth.isLoggedIn(true)" routerLink="/platform">{{ 'Platform' | translate
          }}</a>
      </nav>

    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>