import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-risk-assessment-dialog',
  templateUrl: './risk-assessment-dialog.component.html',
  styleUrls: ['./risk-assessment-dialog.component.scss']
})
export class RiskAssessmentDialogComponent implements OnInit {

  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<RiskAssessmentDialogComponent>,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      userId: [this.data.userId, [Validators.required]],
      riskRating: [null, [Validators.required]]
    })
  }

  onSubmit() {
    this.dialogRef.close(this.form.value);
  }
}
