import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/cdk/stepper';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatStepper } from '@angular/material/stepper';
import { BehaviorSubject, Observable, Subscribable, Unsubscribable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CountryType, FieldConfigType, FieldSetType, RelationshipType, StateType } from 'src/app/_graphql/schema';
import { CustomValidators } from 'src/app/_helpers/custom.validators';
import { AddressService } from 'src/app/_services/address.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { KycService } from 'src/app/_services/kyc.service';
import { PublicService } from 'src/app/_services/public.service';
import { UiService } from 'src/app/_services/ui.service';
import { EndUserService } from 'src/app/_services/user/enduser.service';
import { ImageContainerComponent } from '../../image-container/image-container.component';

@Component({
  selector: 'app-form-config',
  templateUrl: './form-config.component.html',
  styleUrls: ['./form-config.component.scss'],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: MAT_DATE_FORMATS, useValue: PublicService.getDateInputFormat() }
  ]
})

export class FormConfigComponent implements OnInit, OnDestroy {
  @Input() confType: string;
  @Input() hideSteps: boolean = false;
  @Input() loading: boolean = false;
  @Output() onSubmitData = new EventEmitter<any>();
  @Output() validation = new EventEmitter<any>();
  @Output() formDataLoaded = new EventEmitter<any>();


  form = this.fb.group({});


  fieldsConfig: any[] = [];
  simplifiedConfig = {};
  uniqueFormSections = [];
  oneRequiredDefaultView = {};
  countries: CountryType[];
  country: CountryType;
  isStepper = false;
  noConfigurationLoadedError: BehaviorSubject<boolean> = new BehaviorSubject(false);
  hide = true;
  confirmPassword: string;
  filesUploaded: any[] = [];
  addressData: BehaviorSubject<any[]> = new BehaviorSubject([]);
  autocompleteField: any;
  authMethod: string = AuthenticationService.AUTHMETHID_BASIC;
  formData: FieldSetType;
  focusedField: { [key: string]: boolean } = {};


  imageUrl: string;
  imageDescription: string;




  states$: Subscribable<StateType[]>;
  sendingCountires$: Subscribable<CountryType[]>;
  receivingCountries$: Subscribable<CountryType[]>;
  relationships$: Subscribable<RelationshipType[]>;

  stepperOrientation: Observable<StepperOrientation>;
  unsubSearchAddress$: Unsubscribable;
  unsubSearchAddressDetails$: Unsubscribable;
  unsubListentoElement$: Unsubscribable;
  @ViewChild('stepper') stepper: MatStepper;
  imageDisplayType;


  _formData: any;
  get patchValue(): any {
    return this._formData;
  }
  @Input() set patchValue(_value: any) {
    if (!_value)
      return;
    var value = Object.assign({}, _value);
    var keys = Object.keys(value);
    keys.forEach(key => {
      if (typeof value[key] == 'object' && value[key]?.id) {
        value[key + 'Id'] = value[key].id;
      }
    });
    this._formData = value;
    if (this._formData?.countryId) {
      this.selectedCountry(this._formData?.countryId);
    }
    this.form.patchValue(value);
  }

  constructor(
    private fb: FormBuilder,
    public publicService: PublicService,
    public kycService: KycService,
    private cdr: ChangeDetectorRef,
    public userService: EndUserService,
    private breakpointObserver: BreakpointObserver,
    private ui: UiService,
    private auth: AuthenticationService,
    private addressService: AddressService,
    public dialog: MatDialog

  ) {
    this.sendingCountires$ = this.publicService.countries(true, null, null);
    this.receivingCountries$ = this.publicService.countries(null, true, null);
    this.relationships$ = this.publicService.getRelationships();


    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));

    this.imageDisplayType = breakpointObserver
      .observe('(min-width: 1100px)')
      .pipe(map(({ matches }) => (matches ? 'large' : 'small')));
  }
  public get formSections() {
    var t = Object.keys(this.form.controls);
    return t;
  }

  // ngAfterViewInit() {
  //   this.stepper._getIndicatorType = () => STEP_STATE.NUMBER;
  // }
  ngOnInit(): void {
    if ('kyc' == this.confType) {
      const local = JSON.parse(localStorage.getItem('currentUser'));
      this.states$ = this.publicService.states(local.user.country.isoCode2);
      var s = this.kycService.configurationKYC(local.user.country.isoCode2).subscribe({
        next: (fields: any) => {
          console.log('Patching KYC form with user data... You should see this only once...')
          if (fields) {

            this.fieldsConfig = [...fields];

            this.setupForm();
            this.prefillForm();
            if (this.autocompleteField) {
              let tmp = this.form.get('address').get('addressLine1').valueChanges.pipe(
              ).subscribe(data => {
                if (!this.form.get('address').get('addressLine1'))
                  return;
                let countryCode = this.auth?.user?.country?.isoCode2;
                this.getGoogleMapsData(data, countryCode);
              });

            }
          }
          s.unsubscribe();
        }
      });
    }
    if ('Questionnaire' == this.confType) {
      const local = JSON.parse(localStorage.getItem('currentUser'));
      this.publicService.getFieldsConfig(local?.user?.country?.isoCode2 || null, 'Questionnaire').subscribe({
        next: x => {
          this.fieldsConfig = this.transformFieldsConfig(x);
          this.setupForm();
          this.prefillForm();
        }
      })
    }
    if (this.confType === 'recipient') {
      this.publicService.countries(null, true, null).pipe(take(1)).subscribe({
        next: countries => {
          this.countries = countries;
          this.country = this.countries?.find(x => x.isActive);
          if (this.patchValue?.country) {
            return this.selectedCountry(this.patchValue.country?.id);
          }
          this.selectedCountry(this.country?.id);
        }
      });
    }
    if (this.confType === 'updateUser') {
        this.publicService.countries(true, null, null).pipe(take(1)).subscribe(countries => {
        this.countries = countries;
        this.selectedCountry(this.patchValue.user?.country?.id);
      });
    }
    if (this.confType === 'user') {
      this.publicService.countries(true, null, null).pipe(take(1)).subscribe(countries => {
        this.countries = countries;
        this.country = this.countries?.find(x => x.isActive);
        if (this.country) {
          this.selectedCountry(this.country?.id);
          if (this.country?.isoCode3 === 'SWE') {
            this.authMethod = AuthenticationService.AUTHMETHID_DIGLIAS;
          }
        } else {
          this.noConfigurationLoadedError.next(true);
        }
      });
    }
  }
  transformFieldsConfig(config): FieldSetType[] {
    var data: FieldSetType = {
      "description": "",
      "name": "questionnaire",
      "group": 'questionnaire',
      "groupPolicy": "AllRequired",
      "label": "Questionnaire",
      fields: []
    };
    if (config[0])
      config[0].fields.forEach(field => {
        data.fields.push(
          field
        );
      });
    return [data];
  }
  prefillForm() {
    let unsub = this.userService.currentUser().subscribe({
      next: x => {
        this.uniqueFormSections.forEach(section => {
          if (['personDetails', 'address'].indexOf(section.name) != -1) {
            var a = Object.assign({}, x);
            var keys = Object.keys(a);
            keys.forEach(key => {
              if (a[key] == null) {
                delete a[key];
              }
            });
            this.form.get(section.name).patchValue(a);
            this.form.markAllAsTouched();
            // this.fieldsConfig.forEach((el: any) => {
            //   this.getFormValidationErrors(this.form.get(el.name));
            // })
          }
        });
        unsub?.unsubscribe();
      }
    })
  }
  selectedCountry(countryId: string) {
    this.country = this.countries?.find(x => x?.id === countryId);
    this.states$ = this.publicService.states(this.country?.isoCode2);
    if (this.country) {
      this.publicService.getFieldsConfig(this.country?.isoCode2, this.confType).subscribe({
        next: (fields: any) => {
          if (fields && fields[0]) {
            this.noConfigurationLoadedError.next(false);
            this.formData = fields[0];
            this.fieldsConfig = fields[0].fields;
            this.setupForm();
            this.form.controls?.['countryId']?.setValue(this.country?.id);
            this.formDataLoaded.emit(fields[0]);
          } else {
            this.noConfigurationLoadedError.next(true);
          }
        }
      });

    }
    if (this.country?.isoCode2 === 'SE') {
      this.authMethod = AuthenticationService.AUTHMETHID_DIGLIAS;
    } else {
      this.authMethod = AuthenticationService.AUTHMETHID_BASIC;
    }
  }

  setupForm() {
    if (this.confType === 'kyc' || this.confType === 'Questionnaire') {
      this.fieldsConfig.forEach((section: FieldSetType) => {

        this.isStepper = true;

        this.simplifiedConfig[section['name']] = {
          "description": section['description'],
          "name": section['name'],
          "group": section['group'],
          "groupPolicy": section['groupPolicy'],
          "label": section['label'],
          fields: []
        };
        var _fields = {};
        section.fields.forEach(field => {
          this.simplifiedConfig[section['name']].fields.push({
            'name': field['name'],
            'label': field['label'],
            'type': field['type'],
            'min': field['min'],
            'max': field['max'],
            'required': field['required'],
            'description': field['description'],
          });

          _fields[field['name']] = (field['type'] == 'checkboxstring'
            ?
            this.fb.array([], this.createFieldValidators(field))
            :
            this.fb.control(null, this.createFieldValidators(field)));

        });

        var _ctlGroup = this.fb.group(_fields)

        this.form.addControl(section['name'], _ctlGroup);
        this.form.setValidators(this.comparisonValidator());
      })
    }
    else {
      this.form = this.fb.group({});
      this.form.addControl('id', this.fb.control(null, []));
      this.fieldsConfig.forEach(field => {
        this.form.addControl(field.name, this.fb.control(field.editable ? field.value : { value: field.value, disabled: true }, this.createFieldValidators(field)));
      })
      if (this.patchValue) {
        this.form.patchValue(this.patchValue.user ? this.patchValue.user : this.patchValue);
      }
      this.form.valueChanges.subscribe(val => {
        this.validation.emit(this.form.dirty)
        if (val[this.autocompleteField]) {
          let countryCode = this.countries?.find(x => x.id === this.form.value?.['countryId'])?.isoCode2;
          if (!countryCode) {
            countryCode = this.auth?.user?.country?.isoCode2;
          }
          this.getGoogleMapsData(val[this.autocompleteField], countryCode);
        }
      })
    }
    this.checkCustomValidations();
    this.form.updateValueAndValidity();
    this.createUniqueSections();
  }
  createUniqueSections() {
    var keys = Object.keys(this.simplifiedConfig);
    var r = [];
    keys.forEach(key => {
      var itm = this.simplifiedConfig[key];
      if (r.filter(_r => _r.group == itm.group && _r.groupPolicy == itm.groupPolicy).length == 0)
        r.push({
          group: itm.group,
          groupPolicy: itm.groupPolicy,
          name: itm.name,
          label: 'OneRequired' == itm.groupPolicy ? itm.group : itm.label
        })
    });
    r.forEach(itm => {
      if (itm.groupPolicy == "OneRequired") {
        this.oneRequiredDefaultView[itm.group] = itm.name;
      }
    });
    this.uniqueFormSections = r;
  }
  public comparisonValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {

      this.formSections.forEach(section => {
        var sameGroups = [];
        this.formSections.forEach(_section => {
          if (this.simplifiedConfig[section].group == this.simplifiedConfig[_section].group) {
            sameGroups.push(_section);
          }
        });
        if ('AllRequired' == this.simplifiedConfig[section]?.groupPolicy) {
          var allReqValid = true;
          sameGroups.forEach(item => {
            var fc = this.form.get(item);
            if (!fc.valid) {
              allReqValid = false;
            }
          });
          sameGroups.forEach(item => {
            this.form.get(item).setErrors(allReqValid ? null : { allRequired: false })
          });

        }
        if ('OneRequired' == this.simplifiedConfig[section].groupPolicy) {

          var isOk = false;
          sameGroups.forEach(item => {
            var valid = this.form.get(item).valid;
            if (valid) {
              isOk = true;
            }
          });
          sameGroups.forEach(item => {
            this.form.get(item).setErrors(null)
            this.form.get(item).setErrors(isOk ? null : { oneRequired: false });
          });
        }
      });
      return;
    };
  }
  showSectionGroup(e, group) {
    this.oneRequiredDefaultView[group] = e;
    //RESET DATA ON CHANGE
    this.fieldsConfig.forEach((fc: any) => {
      if (fc?.name == e) {
        this.fieldsConfig.forEach((_fc: any) => {
          if (fc?.group == _fc?.group) {
            var fg = (this.form.get(_fc.name) as FormGroup);
            fg.reset();
          }
        });
      }
    })
  }

  createFieldValidators(field) {
    var key, value;
    var validators = [];
    var keyValuePair = Object.entries(field);
    for ([key, value] of keyValuePair) {
      if (key === 'type' && value === 'address-autocomplete')
        this.autocompleteField = field.name;
      if (value == null) continue;
      switch (key) {
        case 'checkboxstring':
          break;
        case 'email':
          validators.push(Validators.email);
          break;
        case 'minLength':
          validators.push(Validators.minLength(value));
          break;
        case 'maxLength':
          validators.push(Validators.maxLength(value));
          break;
        case 'pattern':
          if (field.type != 'file-upload')
            validators.push(Validators.pattern(value));
          break;
        case 'nullValidator':
          validators.push(Validators.nullValidator);
          break;
        case 'required':
          if (value)
            validators.push(Validators.required, CustomValidators.noWhitespaceValidator());
          if (value && field.type == 'moov-terms-token')
            validators.push(Validators.nullValidator);
          if (value && field.type == 'checkbox')
            validators.push(Validators.requiredTrue);
          break;
      }
    }
    return validators;
  }
  getMin(data: any) {
    var currentYear = new Date().getFullYear();
    var currDate = new Date().getDate() + 1;
    var currMonth = new Date().getMonth();
    if (data === null) { return; }
    return new Date(currentYear, currMonth + data, currDate);
  }

  getMax(data: any) {
    var currentYear = new Date().getFullYear();
    var currDate = new Date().getDate() + 1;
    var currMonth = new Date().getMonth();
    if (data === null) { return; }
    return new Date(currentYear - data, currMonth, currDate);
  }
  checkIfFormStepsFinished(index: number): boolean {
    let tmpFieldsConfig = this.fieldsConfig.slice(0, index + 1);
    if (tmpFieldsConfig.every(el => this.isCompleted(el)) || (this.stepper?.['selectedIndex'] === index)) {
      return false;
    }
    return true;
  }

  isCompleted(_section: any = null) {
    if ('OneRequired' == _section.groupPolicy) {
      return this.form.get(_section.name).errors == null;
    }
    return this.form.get(_section.name).valid;
  }

  showErrors(section = null, field: FieldConfigType) {
    let err = '';
    let e;
    if (section)
      e = this.form.get(section).get(field.name).errors;
    else
      e = this.form.get(field.name)?.errors;

    if (e?.minlength) {
      err += e.minlength.actualLength + '/' + e.minlength.requiredLength;
    }
    if (e?.maxlength) {
      err += e.maxlength.actualLength + '/' + e.maxlength.requiredLength;
    }
    if (e?.pattern) {
      let tmp = field?.properties?.find(property => property?.type === 'patternErrorMessage');
      if (tmp) {
        err = tmp?.value;
      }
    }
    if (!err && e?.error)
      err = e?.error;
    return err;
  }
  getMoovTermsToken(section, field) {
    let frmCtl: AbstractControl = this.form.get(section).get(field);
    if (frmCtl?.value) {
      frmCtl.setValue(null); // don't touch this 
      frmCtl.disable();
      fetch('https://api.moov.io/tos-token', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // 'Referer': 'https://wiccon-tango-dev.azurewebsites.net/'
        },
      })
        .then((response) => response.json())
        .then((data) => {
          frmCtl.enable();
          frmCtl.setValue(data?.token);
        })
        .catch((error) => {
          this.ui.snack("Unable to accept T&C");
          frmCtl.enable();
          frmCtl.setValue(null);
        });
    } else {
      frmCtl?.setValue(null);
    }
  }
  confPassword(event) {
    this.confirmPassword = event.target.value;
  }
  handleFileInput(files: any, documentName: any) {
    this.publicService.upload(files, null, this.auth.user.id, null, null, null, true).subscribe((uploadedFiles: any[]) => {
      this.form.controls[documentName].setValue(uploadedFiles[0]?.id)
      this.filesUploaded[uploadedFiles[0]?.id] = uploadedFiles[0];
    });
  }
  removeImage(key) {
    var deleteImage = {}
    deleteImage[key] = null;
    this.form.patchValue(deleteImage)
  }
  getFA(section: string, name: string): FormArray {
    return this.form.get(section).get(name) as FormArray;
  }
  isElementInFormArrayData(section: string, name: string, value: string) {
    var fa = this.getFA(section, name);
    return fa.value.indexOf(value);
  }
  toggleFormArrayData(section: string, name: string, value: string) {
    var fa = this.getFA(section, name);
    var idx = this.isElementInFormArrayData(section, name, value);
    if (idx == -1) {
      fa.push(this.fb.control(value));
    } else {
      fa.removeAt(idx);
    }
  }
  addressChoosen(ev) {
    let placeId = ev.option.value.place_id;
    let formElement;
    if (this.confType === 'kyc') {
      formElement = this.form.get('address');
    } else {
      formElement = this.form;
    }
    this.unsubSearchAddressDetails$ = this.addressService.addressLookUp(`fields=address_components&place_id=${placeId}`, 'details').subscribe(data => {
      let place = JSON.parse(data['addressLookup']);
      place['result']['address_components'].forEach(el => {
        if (el['types'].includes('postal_code')) {
          formElement.get('zip')?.patchValue(el.long_name);
        }
        if (el['types'].includes('locality') || el['types'].includes('sublocality') || el['types'].includes('postal_town')) {
          formElement.get('city').patchValue(el.long_name);
        }
        let unsubStates$ = this.states$.subscribe({
          next: res => {
            let stateId = res.find(state => state?.name === el?.long_name)?.id;
            if (stateId)
              formElement.get('territory').patchValue(stateId);
            unsubStates$?.unsubscribe();
          }
        })
      })

    })
    formElement.get('addressLine1').patchValue(formElement.get('addressLine1').value.description?.split(',')[0]);
  }
  getGoogleMapsData(address, country) {
    this.unsubSearchAddress$ = this.addressService.addressLookUp
      (`input=${address}&types=address&components${country ? '=country:' + country : ''}`, 'autocomplete')
      .subscribe(data => {
        let tmp = JSON.parse(data['addressLookup']);
        this.addressData.next(tmp['predictions']);
      })
  }
  onSubmit() {
    this.form.markAllAsTouched();
    if ('kyc' == this.confType) {
      var data = Object.assign({}, this.form.value);
      for (let el in data) {
        if (Object.values(data[el]).every(value => value === null))
          delete data[el];
      }
      if (data.hasOwnProperty('consents')) {
        var isAllCheckboxStrings = true;
        this.simplifiedConfig['consents'].fields.forEach(el => {
          if (el.type != "checkboxstring")
            isAllCheckboxStrings = false;
        })
        if (isAllCheckboxStrings) {
          data['consents'] = data['consents'].consents;
        }
      }
      this.onSubmitData.emit(data);

    } else {
      if (this.confType === 'user') {
        var form = this.clearJsonFields(this.form.value);
        if (this.country?.isoCode2 === 'SE') {
          this.authMethod = AuthenticationService.AUTHMETHID_DIGLIAS;
        } else {
          this.authMethod = AuthenticationService.AUTHMETHID_BASIC;
          if (this.form.get('password').value !== this.confirmPassword) {
            return this.ui.snack('Password and confirm password are not the same.');;
          }
        }
        form.countryId = this.country.id;
        form.authMethod = this.authMethod;
      }
      // this.form.controls.countryId.setValue(this.country.id);
      // var form = this.clearJsonFields(this.myForm.value);
      // form.id = this.recipientData.id
      if (!this.form.valid) {
        this.ui.snack('Please fill up all required fields.');
      }
      if (this.form.valid)
        this.onSubmitData.emit(this.form.value);
    }
  }
  clearJsonFields(jsonToClear) {
    var excessKeys = [];
    for (let key in jsonToClear) {
      if (this.formData?.fields.filter((element) => element.name == key && element.visible == true).length != 1) {
        excessKeys.push(key);
      }
    }
    excessKeys.forEach((element) => {
      delete jsonToClear[element];
    });
    return jsonToClear;
  }

  enableButton() {
    this.validation.emit(true);
  }
  checkCustomValidations(): void {
    this.fieldsConfig.forEach(el => {
      if (el?.fields) {
        (el.fields ? el.fields : el)?.forEach(field => {
          if (field?.properties) {
            field.properties.forEach(property => {
              if (property?.type === 'conditionalPattern') {
                let object = JSON.parse(property?.value);
                const nestedProperties = object?.listenTo || [];
                const listenToFormElement = nestedProperties.reduce((nestedObject, property) => {
                  return nestedObject?.get(property);
                }, this.form);
                this.form.get(el.name).get(field.name).addValidators(CustomValidators.conditionalValidator(listenToFormElement,
                  object.rules));
                this.unsubListentoElement$ = listenToFormElement.valueChanges.pipe().subscribe(() =>
                  this.form.get(el.name)?.get(field.name).updateValueAndValidity());
              }
              if (property?.type === 'validationType' && property?.value === 'medicareNumber') {
                this.form.get(el.name).get(field.name).addValidators(CustomValidators.medicareNumberValidator());
              }
            })
          }
        })
      }
    })
  }
  ngOnDestroy(): void {
    this.unsubSearchAddress$?.unsubscribe();
    this.unsubSearchAddressDetails$?.unsubscribe();
    this.unsubListentoElement$?.unsubscribe();
  }
  replaceParam(original: string, substringToReplace: string, replacement: string): string {
    const index = original.indexOf(substringToReplace);
    if (index === -1) {
        return original;
    }
    const newUrl = original.substring(0, index) + replacement + original.substring(index + substringToReplace.length);
    return newUrl;
  }

  showImage(field, event: Event = null) {
    if (field?.properties) {
      field.properties.forEach(property => {
        if (property?.type === 'imageDescription') {
          let object = JSON.parse(property?.value);
          const nestedProperties = object?.paramPath || [];
          const listenToFormElement = nestedProperties.reduce((nestedObject, property) => {
            return nestedObject?.get(property);
            }, this.form);
            this.focusedField[field.name] = true;
            this.imageUrl = this.replaceParam(object?.url, '{param}', listenToFormElement?.value);
            this.imageDescription = this.replaceParam(object?.description, '{param}', listenToFormElement?.value);
            if (event) {
              event?.stopPropagation();
            }
        }
      })
    }
  }

  openImageDialog(field) {
    let dialogRef = this.dialog.open(ImageContainerComponent, {
      disableClose: true,
      data: {
        imageUrl: this.imageUrl,
        imageDescription: this.imageDescription
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      this.hideImage(field);
    });
  }
  showIcon(field): boolean {
    let iconVisible = false;
    if (field?.properties) {
      field.properties.forEach(property => {
        if (property?.type === 'imageDescription') {
          let object = JSON.parse(property?.value);
          const nestedProperties = object?.paramPath || [];
          const listenToFormElement = nestedProperties.reduce((nestedObject, property) => {
            return nestedObject?.get(property);
            }, this.form);
            let imageUrl = this.replaceParam(object?.url, '{param}', listenToFormElement?.value);
            if (listenToFormElement?.value && imageUrl) {
              iconVisible = true;
            }
        }
      })
    } 
    return iconVisible;
  }
  hideImage(field) {
    this.focusedField[field.name] = false;
    this.imageDescription = null;
    this.imageUrl = null;
  }
}
