<form [formGroup]="form">
    <div class="io-item-shadow">
        <div class="io-py-3 io-px-3">
            <div class="io-row align-items-center">
                <div class="center">
                    <button mat-icon-button (click)="navigateToCorridors()">
                        <mat-icon>keyboard_arrow_left</mat-icon>
                      </button>
                    <div class="column">
                        <div style="font-size: 22px">{{ form.get('id').value ? "Edit Corridor" : "Add Corridor"}}</div>
                        <div cless="center">
                            <p class="info-note">
                                <span *ngIf="currency && form.get('receivingCurrency').value ">
                                    For every transaction using this corridor - user will be charged with
                                    {{ currency }}
                                    and recipient will get
                                    {{ form.get('receivingCurrency').value }}.</span>
                                Minimal amount per transaction is {{ form.controls.minAmount.value || 0 | number: '1.2-2' }}
                                {{ form.controls.checkCurrency.value }} and maximum amount is
                                {{ form.controls.maxAmount.value || 0 | number: '1.2-2' }}
                                {{ form.controls.checkCurrency.value }}.
                                <span>
                                    <span *ngIf="calculateFee() as calc">If sender is sending {{calculatorAmount?.value}}
                                        {{ currency }}, fee will be {{ calc?.total | number: '1.2-2' }}
                                        {{ currency }}.</span>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="no-flex">
                    <mat-checkbox color="primary" class="checkbox-margin" formControlName="isActive">Active
                    </mat-checkbox>


                    <button mat-raised-button class="no-flex" color="primary" type="submit"
                        [disabled]="isSubmitDisabled()" (click)="onSubmit()">Save</button>
                </div>

            </div>
        </div>

    </div>
    <div>
        <div class="io-row io-px-3 io-pb-2 io-pt-3">
            <mat-card>
                <mat-card-title>Corridor Details</mat-card-title>
                <div class="io-row-m io-pt-1 io-pb-1">
                    <app-single-select formControlName="fromId" [items]="countrySenders"
                        placeholder="{{ 'From country' | translate }}" [required]="true">
                    </app-single-select>
                    <app-single-select formControlName="toId" [items]="countryReceivers"
                        placeholder="{{ 'To country' | translate }}" [required]="true">
                    </app-single-select>

                    <app-single-select formControlName="paymentServiceId" [items]="paymentServices$ | async"
                        placeholder="{{ 'Payment service' | translate }}" [required]="true">
                    </app-single-select>
                    <app-single-select formControlName="paymentNetworkId" [items]="paymentNetworks"
                        placeholder="{{ 'Payment network' | translate }}" [required]="true"
                        [optionValue]="['extendedName', 'id']">
                    </app-single-select>
                </div>
                <div class="io-row-l">
                    <div class="io-row-m io-pt-1  io-pb-1">
                        <mat-form-field>
                            <mat-label>{{'Sending Currency'|translate}}</mat-label>
                            <input matInput disabled="disabed" type="textbox" [value]="currency">
                        </mat-form-field>
                        <app-single-select formControlName="receivingCurrency" [items]="currencies$ | async"
                            placeholder="{{ 'Receiving Currency' | translate }}" [required]="true"
                            [optionValue]="['isoCode', 'isoCode']">
                            <button matSuffix mat-icon-button type="button"
                                (click)="createCurrency($event)"><mat-icon>add</mat-icon>
                            </button>
                        </app-single-select>
                        <mat-form-field>
                            <mat-label>Exchange Rate Correction</mat-label>
                            <input matInput formControlName="exchangeCorrectionRate">
                            <mat-error>Enter number with up to 6 decimals eg. 1.123456</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="io-row-m io-pt-1  io-pb-1">
                        <mat-form-field>
                            <mat-label>Minimum amount</mat-label>
                            <input matInput required type="textbox" formControlName="minAmount" appTwoDigitDecimaNumber>
                            <span matSuffix class="currency-suffix" *ngIf='form.get("checkCurrency").value as val'>{{
                                val }}</span>
                            <mat-hint>Minimum amount user can transfer</mat-hint>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Maximum amount</mat-label>
                            <input matInput required type="textbox" formControlName="maxAmount" appTwoDigitDecimaNumber>
                            <span matSuffix class="currency-suffix" *ngIf='form.get("checkCurrency").value as val'>{{
                                val }}</span>
                            <mat-hint>Maximum amount user can transfer</mat-hint>
                        </mat-form-field>
                        <app-single-select formControlName="checkCurrency" [items]="currencies$ | async"
                            placeholder="{{ 'Check Currency' | translate }}" [required]="true"
                            [optionValue]="['isoCode', 'isoCode']"
                            [hintText]="'Corridor limits will be checked in this currency'">
                            <button matSuffix mat-icon-button type="button" (click)="createCurrency($event)">
                                <mat-icon>add</mat-icon>
                            </button>
                        </app-single-select>
                    </div>
                </div>
            </mat-card>
        </div>
        <ng-template #tplErrorMessage let-fa="fa">
            <div *ngIf="fa?.value as itms" class="io-ml-1">
                <div *ngIf="itms.length">
                    <div *ngIf="checkCurrency == currency; else differentCurrencies">
                        <div class="flex io-row info-box align-items-center" *ngIf="isRangeCovered(itms)">
                            <mat-icon class="info-icon no-flex" color="warn">info</mat-icon>
                            <span>
                                {{'Last upper amount must be at least' | translate}}
                                {{ (maxAmount+1) | number: '1.2-2'}} {{checkCurrency}}
                            </span>
                        </div>
                    </div>
                    <ng-template #differentCurrencies>
                        <div class="flex io-row info-box align-items-center">
                            <mat-icon class="info-icon no-flex" color="warn">info</mat-icon>
                            <span>{{
                                ('To cover all ranges on the corridor, '
                                +' last upper amount (when converted) must be at least') |translate}}
                                {{ (maxAmount+1) | number: '1.2-2'}} {{checkCurrency}}
                            </span>
                        </div>
                    </ng-template>
                </div>
            </div>

        </ng-template>
        <ng-template #tplForm let-form="form" let-idx="idx" let-fa="fa" let-mode="mode" let-matchedIdx="matchedIdx">
            <form [formGroup]="form">
                <div class="io-row align-items-center">

                    <mat-form-field class="flex">
                        <mat-label>Lower Amount</mat-label>
                        <input matInput type="textbox" disabled=""
                            [value]="idx == 0 ? minAmount : getFG(fa, idx-1).get('toAmount').value">
                        <span matSuffix class="currency-suffix">{{ currency }}</span>
                    </mat-form-field>
                    <div class="io-pb-2 text-center unmatched" [ngClass]="{matched: idx == matchedIdx}">
                        &le; <span>amount</span> &lt;
                    </div>
                    <mat-form-field>
                        <mat-label>Upper Amount</mat-label>
                        <input matInput required type="textbox" formControlName="toAmount" appTwoDigitDecimaNumber
                            (input)="checkArray(idx, fa)">
                        <span matSuffix class="currency-suffix">{{currency}}</span>
                        <mat-error *ngIf="getFG(fa, idx).get('toAmount')?.errors?.error as msg">{{msg}}</mat-error>
                    </mat-form-field>
                    <div class="io-pb-2 text-center unmatched" [ngClass]="{matched: idx == matchedIdx}">:</div>
                    <mat-form-field>
                        <mat-label>Fee</mat-label>
                        <input matInput required type="textbox" formControlName="feeAmount" appTwoDigitDecimaNumber>
                        <span matSuffix class="currency-suffix">{{mode}}</span>
                    </mat-form-field>
                    <div style="flex: 0 0  40px; align-self: top;">
                        <button mat-icon-button type="button" class="btn-remove" color="warn"
                            [disabled]="idx < fa.controls.length-1" (click)="removeLine(idx, fa)">
                            <mat-icon>remove_circle_outline</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
        </ng-template>



        <div class="io-row-xl io-px-3 io-pb-2">
            <div>
                <div class="io-row-l align-items-start io-pb-2">
                    <mat-card *ngIf="getFA('FIXED_FEE') as fa">
                        <mat-card-header class="io-row">
                            <mat-card-title>Fixed Fee</mat-card-title>
                            <mat-card-subtitle>Fixed amount sender will be charged for transaction</mat-card-subtitle>
                            <mat-form-field class="no-flex" *ngIf="fa.controls.length == 0" style="width: 120px;">
                                <mat-label>Fixed fee</mat-label>
                                <input matInput formControlName="fixedFee" appTwoDigitDecimaNumber>
                                <span matSuffix class="currency-suffix" *ngIf='currency'>{{ currency }}</span>
                            </mat-form-field>
                        </mat-card-header>

                        <div formArrayName="FIXED_FEE">
                            <div *ngFor="let item of fa.controls; index as _idx">
                                <ng-template [ngTemplateOutlet]="tplForm"
                                    [ngTemplateOutletContext]="{form:getFG(fa, _idx), idx: _idx, fa: fa, mode: currency, matchedIdx: feeFixedIdx}"></ng-template>
                            </div>
                        </div>
                        <mat-card-actions>

                            <div class="io-row">
                                <ng-template [ngTemplateOutlet]="tplErrorMessage"
                                    [ngTemplateOutletContext]="{fa: getFA('FIXED_FEE')}"></ng-template>
                                <button class="no-flex" mat-stroked-button color="primary"
                                    (click)="insertFeeGroup('FIXED_FEE', 'VALUE')"
                                    [disabled]="form.get('FIXED_FEE').invalid">Add
                                    fee</button>
                            </div>
                        </mat-card-actions>

                    </mat-card>

                    <mat-card *ngIf="getFA('VARIABLE_FEE') as fa">
                        <mat-card-header class="io-row">
                            <mat-card-title>Variable Fee</mat-card-title>
                            <mat-card-subtitle>Variable amount sender will be charged for
                                transaction</mat-card-subtitle>
                            <mat-form-field class="no-flex" *ngIf="fa.controls.length == 0" style="width: 120px;">
                                <mat-label>Variable fee</mat-label>
                                <input matInput formControlName="variableFee" appTwoDigitDecimaNumber>
                                <span matSuffix class="currency-suffix">%</span>
                            </mat-form-field>
                        </mat-card-header>


                        <div formArrayName="VARIABLE_FEE">
                            <div *ngFor="let item of fa.controls; index as _idx">
                                <ng-template [ngTemplateOutlet]="tplForm"
                                    [ngTemplateOutletContext]="{form:getFG(fa, _idx), idx: _idx, fa: fa, mode: '%', matchedIdx: feeVariableIdx}"></ng-template>
                            </div>
                        </div>
                        <mat-card-actions>
                            <div class="io-row">
                                <ng-template [ngTemplateOutlet]="tplErrorMessage"
                                    [ngTemplateOutletContext]="{fa: getFA('VARIABLE_FEE')}"></ng-template>

                                <button mat-stroked-button class="no-flex"
                                    (click)="insertFeeGroup('VARIABLE_FEE', 'PERCENT')"
                                    [disabled]="form.get('VARIABLE_FEE').invalid">Add fee</button>
                            </div>
                        </mat-card-actions>
                    </mat-card>
                </div>
                <div class="io-row io-pb-2" *ngIf="showOutbound">
                    <mat-card>
                        <mat-card-title>Outbound Fee</mat-card-title>
                        <mat-card-subtitle>Fee you will be charged by outbound network. These fee calculations are based
                            on your
                            outbound contract - please do not change it unless you know what you are
                            doing.</mat-card-subtitle>

                        <div formArrayName="OUTBOUND_FEE" *ngIf="getFA('OUTBOUND_FEE') as fa">
                            <div *ngFor="let item of fa.controls; index as _idx">
                                <div *ngIf="getFG(fa, _idx) as fg">
                                    <form [formGroup]="fg">
                                        <div class="io-row align-items-center">
                                            <mat-form-field class="flex">
                                                <mat-label>Lower Amount</mat-label>
                                                <input matInput type="textbox" disabled=""
                                                    [value]="_idx == 0 ? minAmount : getFG(fa, _idx-1).get('toAmount').value">

                                            </mat-form-field>
                                            <div class="io-pb-2 text-center unmatched"
                                                [ngClass]="{matched: _idx == feeOutboundIdx}">
                                                &le; amount &lt;
                                            </div>
                                            <mat-form-field>
                                                <mat-label>Upper Amount</mat-label>
                                                <input matInput required type="textbox" formControlName="toAmount"
                                                    (input)="checkArray(_idx, fa)" appTwoDigitDecimaNumber>
                                                <mat-error
                                                    *ngIf="getFG(fa, _idx).get('toAmount')?.errors?.error as msg">{{msg}}</mat-error>
                                            </mat-form-field>
                                            <div class="io-pb-2 text-center unmatched"
                                                [ngClass]="{matched: _idx == feeOutboundIdx}">:</div>
                                            <mat-form-field>
                                                <mat-label>Fee</mat-label>
                                                <input matInput required type="textbox" formControlName="feeAmount"
                                                    appTwoDigitDecimaNumber>


                                                <button matSuffix mat-icon-button type="button"
                                                    [matMenuTriggerFor]="menu">
                                                    <span *ngIf="fg.get('feeValueType').value=='VALUE'">Value</span>
                                                    <span *ngIf="fg.get('feeValueType').value=='PERCENT'">%</span>
                                                </button>
                                                <mat-menu #menu="matMenu">
                                                    <button mat-menu-item
                                                        (click)="fg.patchValue({'feeValueType': 'PERCENT' })">Percent</button>
                                                    <button mat-menu-item
                                                        (click)="fg.patchValue({'feeValueType': 'VALUE' })">Value</button>
                                                </mat-menu>

                                            </mat-form-field>
                                            <div style="flex: 0 0  40px; align-self: top;">
                                                <button mat-icon-button type="button" class="btn-remove" color="warn"
                                                    [disabled]="_idx < fa.controls.length-1"
                                                    (click)="removeLine(_idx, fa)">
                                                    <mat-icon>remove_circle_outline</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <mat-card-actions>
                            <div class="io-row">
                                <ng-template [ngTemplateOutlet]="tplErrorMessage"
                                    [ngTemplateOutletContext]="{fa: getFA('OUTBOUND_FEE')}"></ng-template>
                                <button class="no-flex" mat-stroked-button color="primary"
                                    (click)="insertFeeGroup('OUTBOUND_FEE', 'VALUE')"
                                    [disabled]="form.get('OUTBOUND_FEE').invalid">Add fee</button>
                            </div>
                        </mat-card-actions>
                    </mat-card>
                </div>
            </div>
        </div>

    </div>
</form>

<ng-template let-data #calcDialog>
    <div>
        <h2 mat-dialog-title> Fee Calculator </h2>
        <div mat-dialog-content>

            <p style="font-size: 9pt">Change sending amount to test fee calculations.</p>
            <mat-form-field>
                <mat-label>Amount</mat-label>
                <input matInput [formControl]="calculatorAmount" appTwoDigitDecimaNumber>
                <span matSuffix>{{currency}}</span>
                <mat-hint>It doesn't affect corridor settings</mat-hint>
            </mat-form-field>
            <table *ngIf="calculateFee() as calc; else calculatorRulesError" class="table-details">
                <tbody>
                    <tr>
                        <th>Fixed fee</th>
                        <td style="text-align:right; padding-right: 0">{{ calc?.fixedFee | number: '1.2-2' }}
                            {{currency}}</td>
                    </tr>
                    <tr>
                        <th>Variable fee</th>
                        <td style="text-align:right; padding-right: 0">{{ calc?.variableFee | number: '1.2-2' }}
                            {{currency}}</td>
                    </tr>
                    <tr>
                        <th>Total</th>
                        <td style="text-align:right; padding-right: 0">{{ calc?.total | number: '1.2-2' }} {{currency}}
                        </td>
                    </tr>
                </tbody>
            </table>
            <ng-template #calculatorRulesError>
                <p style="font-size: 9pt">Some rules are not entered correctly. Please make needed
                    changes.</p>
            </ng-template>


        </div>
        <div mat-dialog-actions class="io-row">
            <div></div>
            <button mat-button mat-dialog-close color="primary" class="no-flex">Close</button>
        </div>
    </div>
</ng-template>
<button color="primary" class="fab-fixed" mat-fab (click)="openCalculator()" *ngIf="!calculatorOpen">
    <mat-icon>calculate</mat-icon>
</button>