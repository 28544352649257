<div style="min-height:98vh"><app-main-nav></app-main-nav>
  <div class="loader">
    <mat-progress-bar *ngIf="loading" mode="query"></mat-progress-bar>
  </div>
  <router-outlet></router-outlet>
</div>
<footer>
  <div>
    <a class="footer-dashboard">{{ "company_name" | translate }},
      Copyright &copy;
      {{ currentDate | date: "yyyy" }}</a>
  </div>
  <small [innerHTML]="'footer_text'|translate"></small>
</footer>