<div class="tickets">
  <div mat-dialog-title *ngIf="ticket">
    <div class="io-row-l no-gap" style="align-items: stretch;">
      <div class="flex-2 io-row align-items-center">
        <div class="no-flex status-block {{ticket?.status}}">
          <mat-icon>local_offer</mat-icon>
          <small>{{ticket?.number}}</small>
        </div>
        <div class="title" [matTooltip]="ticket?.subject">
          <span>{{ticket?.subject}}</span>
        </div>
        <div class="d-none d-l-show no-flex io-row align-items-center ">
          <app-ticket-status class="no-flex" style="margin-top: 12px;" [ticket]="ticket"></app-ticket-status>
          <div class="status">
            <div class="label">{{ 'Status:' | translate }}</div>
            <div class="value">{{ ticket?.status.split('_').join(' ') | translate }}</div>
          </div>
        </div>
        <button mat-icon-button mat-dialog-close class="close-2">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="io-row align-items-center toolbar-right d-none d-l-show">
        <span>Ticket Info</span>
        <button mat-icon-button mat-dialog-close class="close-2">
          <mat-icon>close</mat-icon>
        </button>
      </div>

    </div>
  </div>

  <mat-dialog-content style="height: 60vh; position: relative; width:90vw; MARGIN-BOTTOM: -24PX;">
    <div class="io-row-l d-reverse" style="align-items: stretch;height: 60vh;">
      <div class="flex-2 d-flex" style="flex-direction:column;position: relative;">
        <div class="flex" style=" position: relative;">

          <div class="panel-left">
            <div class="first-message" *ngIf="isJSON(ticket?.description) === false">
              <mat-icon color="warn">info</mat-icon>
              {{ ticket?.description }}

            </div>

            <div class="d-show d-l-none">
              <h4>{{ 'FILES' | translate }}</h4>
              <app-files-list [files]="ticket?.files" [ticketId]="ticket?.id" [userId]="data?.userId"
                [newButtonHide]="true">
              </app-files-list>

              <h4>{{ 'MESSAGES' | translate }}</h4>
            </div>

            <!-- <app-chat *ngIf="ticket?.id" [ticketId]="ticket?.id"></app-chat> -->

            <div #scrollMe *ngFor="let msg of messages$ | async" class="messages"
              [ngClass]="{ 'message-right': msg.sender.id !== auth.user.id, 'io-row':true }">
              <img class="user-image no-flex" src="/assets/images/avatar.png" *ngIf="msg.sender.id == auth.user.id">
              <div class="message">
                <h5>
                  {{ msg.sender.firstName }} {{ msg.sender.lastName }}
                  <small>{{ msg.sender.email }}</small>
                </h5>
                <div class="bubble">
                  <p *ngFor="let p of msg.text.split('\n')"> {{ p }}</p>


                  <button class="no-flex" mat-flat-button color="secondary" (click)="downloadFile(msg.file)"
                    *ngIf="msg.file">
                    <span>{{ msg.file.name }}</span>
                    <mat-icon class="upload-icon">get_app</mat-icon>
                  </button>

                  <div class="timestamp">{{ msg.created | dateAgo }}</div>
                </div>
              </div>
              <img class="user-image no-flex" src="/assets/images/avatar.png" *ngIf="msg.sender.id != auth.user.id">
            </div>
            <div class="messages" style="opacity: 0; user-select: none;">Lorem ipsum dolor sit amet, consectetur
              adipiscing
              elit. Quisque ut felis sit amet purus efficitur tempus in convallis neque. </div>

          </div>

        </div>
        <div class="no-flex">
          <div class="io-row width-100 align-items-center">
            <div>
              <mat-form-field class="width-100">
                <textarea matInput id="message" type="text" (keydown.enter)="sendMessage()" placeholder="Send Message"
                  [(ngModel)]="messageText" [disabled]="sendingMessage" matTextareaAutosize matAutosizeMinRows="1"
                  matAutosizeMaxRows="5"></textarea>
                <div matSuffix>
                  <button *ngIf="!filesUploaded.length" type="button" mat-icon-button color="primary"
                    (click)="uploader.click()" [disabled]="sendingMessage" style="margin-right: 4px;">
                    <mat-icon>attachment</mat-icon>
                  </button>
                  <button matSuffix mat-flat-button color="primary" class="send-button" (click)="sendMessage()"
                    [disabled]="isDisabled() ">Send</button>
                  <input hidden type="file" #uploader (change)="handleFileInput($event.target.files)" />
                </div>

                <div *ngIf="filesUploaded.length" class="uploaded-files">
                  <div *ngFor="let file of filesUploaded" style="max-width: 80%;"
                    [ngClass]="{ 'file-uploading': !file.uploaded, 'io-row': true, 'align-items-center ':true }">
                    <p class="no-flex" style="word-break: break-all; width:100%;">{{ file.name }}</p>
                    <button mat-icon-button (click)="deleteFile(file.id)" class="uploaded-file-delete no-flex"
                      color="primary" [disabled]="sendingMessage">
                      <mat-icon class="delete-icon">close</mat-icon>
                    </button>
                  </div>
                </div>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <div style="position: relative; border-left: 1px solid rgb(241, 241, 241);" class="d-none d-l-show">
        <div style="position: absolute; top: 0;left: 0;right: 0;bottom: 0; overflow: hidden; overflow-y: auto;">
          <div style="padding-left: 16px">
            <h4>{{ 'FILES' | translate }}</h4>
            <app-files-list [files]="ticket?.files" [ticketId]="ticket?.id" [userId]="data?.userId"
              [newButtonHide]="true">
            </app-files-list>
            <div *ngIf="auth.isAdmin()">
              <h4>{{ 'INTERNAL NOTE' | translate }}</h4>
              <p class="internal-note">{{ticket?.internalNote}}</p>
              <p *ngIf="!ticket?.internalNote?.length">{{ 'No notes yet.' | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>