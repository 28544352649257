import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiService } from 'src/app/_services/ui.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AdminFlagsService } from 'src/app/_services/admin/admin-flags.service';
import { AdminUsersService } from 'src/app/_services/admin/admin-users.service';
import { CustomValidators } from 'src/app/_helpers/custom.validators';

@Component({
  selector: 'app-flag-resolve',
  templateUrl: './flag-resolve.component.html',
  styleUrls: ['./flag-resolve.component.scss']
})
export class FlagResolveComponent implements OnInit {
  form = this.fb.group({
    transactionId: [this.data.transactionId || false],
    raisedFlagId: [this.data.flagId, Validators.required],
    userId: [this.data.userId],
    markPositive: [null, []],
    note: [null, [Validators.required, CustomValidators.noWhitespaceValidator()]],
    action: [this.data?.action]
  });
  additionalFileds = false;
  constructor(
    private dialogRef: MatDialogRef<FlagResolveComponent>,
    private ui: UiService,
    private fb: UntypedFormBuilder,
    private service: AdminFlagsService,
    private userService: AdminUsersService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any = {}
  ) {
    this.additionalFileds = ['KYC_PEP', 'WATCHLIST_HIT'].indexOf(data.code) != -1;
    if (this.additionalFileds) {
      this.form.get('markPositive').addValidators(Validators.required)
      this.form.get('markPositive').updateValueAndValidity();
    }
  }

  ngOnInit() { }

  resolveFlag() {
    this.service.resolveFlag(this.form.value).subscribe({
      next: x => {
        this.userService.refetchOneData(this.form.get('userId').value);
        this.ui.snack(this.data?.successMessage ? this.data?.successMessage : 'Flag resolved');
        this.dialogRef.close();
      },
      error: e => {
        this.ui.snack(e?.message);
      }
    }
    );
  }
}
