import { Component, OnInit, ChangeDetectorRef, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { UiService } from 'src/app/_services/ui.service';
import { TranslationsService } from 'src/app/_services/translations.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router, RouterLinkWithHref, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent implements OnInit, OnDestroy {
  currentDate: any = new Date();
  environment = environment;
  loading = false;
  navigationEnd$: Subscription;
  setTitle = true;

  constructor(
    public trans: TranslationsService,
    private ui: UiService,
    private snack: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,

    private tt: TranslateService,
    private cdRef: ChangeDetectorRef
  ) {

    this.navigationEnd$ = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setTitle = true;
      }
      if (event instanceof ActivationEnd && this.setTitle) {
        this.setTitle = false;
        this.tt.get(event.snapshot.data?.title).subscribe(trans => {
          this.titleService.setTitle(trans)
        })
      }

    });
    this.ui.snacks.subscribe(message => {
      this.snack.open(message?.replace('GraphQL error:', ''), 'Got it', {
        duration: 2000
      });
    });

    this.ui.loaders.subscribe(visible => {
      this.loading = visible;
      window['_tangoLoader'] = visible ? true : false;
      // this.cdRef.detectChanges();
    });
  }
  ngOnDestroy(): void {
    this.navigationEnd$?.unsubscribe()
    this.tt.get('company_name').subscribe(trans => {
      this.titleService.setTitle(trans)
    })
  }

  ngOnInit() { }
}
