import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CancelTransactionComponent } from './cancel-transaction/cancel-transaction.component';
import { TransactionsBoxComponent } from './transactions-box/transactions-box.component';
import { TransactionsComponent } from './transactions.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { PanelModule } from 'src/app/components/panel/panel.module';
import { TransactionsModule as ComponentsTransactionsModule} from 'src/app/components/transactions/transactions.module';
import { WrapperModule } from '../wrapper/wrapper.module';
import { RouterModule } from '@angular/router';
import { CountryFlagModule } from 'src/app/components/country-flag/country-flag.module';
import { SingleSelectModule } from 'src/app/components/select-country/single-select.module';


@NgModule({
  declarations: [
    CancelTransactionComponent,
    TransactionsBoxComponent,
    TransactionsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    PipesModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    PanelModule,
    ComponentsTransactionsModule,
    WrapperModule,
    RouterModule,
    CountryFlagModule,
    SingleSelectModule
  ],
  exports: [
    CancelTransactionComponent,
    TransactionsBoxComponent,
    TransactionsComponent
  ]
})
export class TransactionsModule { }
