import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscribable, Subscription } from 'rxjs';
import { UiService } from 'src/app/_services/ui.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { AdminTransactionsHistoryService } from 'src/app/_services/admin/admin-transactions-history.service';

@Component({
  selector: 'app-transactions-history',
  templateUrl: './transactions-history.component.html',
  styleUrls: ['./transactions-history.component.scss']
})
export class TransactionsHistoryComponent implements OnInit, OnDestroy {
  items$: Subscribable<any>;

  data: any;
  userId: string;
  customKey: string;

  constructor(
    public service: AdminTransactionsHistoryService,
    public auth: AuthenticationService,
    private route: ActivatedRoute,
    private ui: UiService
  ) {
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.customKey = "transHistory" + this.userId;
    this.items$ = this.service.all({ userId: this.userId }, false, this.customKey)
  }

  ngOnInit() {

  }


  ngOnDestroy() {
  }
}
