<mat-toolbar class="toolbar">
  <nav class="sidenav">
    <button class="back-button" *ngIf="router.url !== '/platform/dashboard'" mat-icon-button (click)="getBack()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <a routerLink="/platform" routerLinkActive="router-link-active" class="main-logo"></a>
  </nav>
  <nav *ngIf="user$ | async as user">
    <button mat-button class="user-button" *ngIf="user" [matMenuTriggerFor]="menu">{{ user?.firstName }}
      <img class="user-image" [src]="publicService.countryFlagImage(user?.country?.isoCode3)">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item routerLink="/platform/profile">{{ 'Profile' | translate }}</button>
      <button mat-menu-item routerLink="/platform/transactions">{{ 'Transactions' | translate }}</button>
      <button mat-menu-item routerLink="/platform/recipients">{{ 'Recipients' | translate }}</button>
      <button mat-menu-item routerLink="/platform/tickets">{{ 'Tickets' | translate }}</button>

      <button mat-menu-item *ngIf="auth.hasPermission(auth.perm.PaymentMethodsManage)"
        routerLink="/platform/payment-methods">{{ 'Payment Methods' |
        translate }}</button>
      <mat-divider></mat-divider>
      <button mat-menu-item *ngIf="auth.hasPermission(auth.perm.TransactionsCreate)" 
      routerLink="/platform/delete-account">{{ 'Delete Account' | translate }}</button>
      <button mat-menu-item (click)="modalService.changePassword()">{{ 'Change Password' | translate }}</button>
      <button mat-menu-item routerLink="/logout">{{ 'Log Out' | translate }}</button>
    </mat-menu>
  </nav>
</mat-toolbar>