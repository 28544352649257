import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserListWrapperComponent } from './list-wrapper/list-wrapper.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { WrapperComponent } from './wrapper.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { WrapperComponent as WrapperComponentTmp } from 'src/app/components/sidebar-wrapper/wrapper.component';
import { OnboardingModule } from '../onboarding/onboarding.module';
import { CountryFlagModule } from 'src/app/components/country-flag/country-flag.module';




@NgModule({
  declarations: [
    UserListWrapperComponent,
    MainNavComponent,
    WrapperComponent,
    WrapperComponentTmp
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    TranslateModule.forChild(),
    RouterModule,
    OnboardingModule,
    CountryFlagModule
  ],
  exports: [
    UserListWrapperComponent,
    MainNavComponent,
    WrapperComponent,
    WrapperComponentTmp
  ]
})
export class WrapperModule { }
