import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdminsItemComponent } from './admins-item/admins-item.component';
import { AdminManagersService } from 'src/app/_services/admin/admin-managers.service';
import { Subscribable, Unsubscribable } from 'rxjs';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ChangePasswordComponent } from 'src/app/public/login/change-password/change-password.component';
import { AdminUserType, AssignableRoleNames, PropertyType } from 'src/app/_graphql/schema';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss']
})
export class AdminsComponent implements OnInit, OnDestroy {
  data: any;
  items$: Subscribable<AdminUserType[]>;
  $unsubForm: Unsubscribable;
  $items: Unsubscribable;
  items: AdminUserType[];
  nameRoles$: Subscribable<PropertyType[]>;



  form: FormGroup = this.fb.group({
    query: [null],
    active: [null],
    type: [null],
    deleted: [null],
  });
  constructor(
    public service: AdminManagersService,
    private dialog: MatDialog,
    public auth: AuthenticationService,
    private fb: FormBuilder,

  ) {
    this.items$ = this.service.all();
    this.nameRoles$ = this.service.roleNames();
  }

  ngOnInit() {
    this.getData();
    this.$unsubForm = this.form.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(data => {
      this.getData()
    });

  }
  getData() {
    this.service.queryAllParams.skip = 0;
    this.service.pageIndex = 0;
    this.$items?.unsubscribe();
    var f = this.form.value;
    this.service.allClose();
    this.$items = this.service.all(f, true, null, false).subscribe({
      next: x => {
        this.items = x
        this.service.getMoreDetails = true;
        var data = this.service.getPageRequestAllFilters()
        this.service.getMoreDetails = false;
        var k = this.service.query(this.service.selectAllQuery,
          data, false, 'users'
        ).subscribe({
          next: (_items: any) => {
            _items.data?.forEach(_item => {
              var i = this.items.find(el => el.id == _item.id)
              if (i)
                i = { ...i, ..._item }
            })
            k.unsubscribe()
          }
        })
      }
    })
  }

  public newItem() {
    this.openDialog(AdminsItemComponent, false);
  }

  editItem(itemId: any) {
    this.openDialog(AdminsItemComponent, itemId?.id);
  }
  openDialog(component: any, param = {}) {
    this.dialog.open(component, {
      height: 'auto',
      width: '800px',
      disableClose: true,
      data: param
    });
  }

  changePassword(user: any) {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '400px',
      disableClose: true,
      maxWidth: '95%',
      maxHeight: '80%',
      data: { user, isResetMode: true }
    });
  }
  ngOnDestroy(): void {
    this.$items?.unsubscribe();
  }
}
