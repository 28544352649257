<div mat-dialog-title>
  <span>{{ (data?.id ? 'Edit Recipient' : 'New Recipient') | translate }}</span>
  <button mat-icon-button mat-dialog-close class="close">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
    <!-- <app-form-configuration fieldsGroup="recipient" [patchValue]="recipientData" (sendForm)="submitForm($event)"
      (validation)="formValid($event)" *ngIf="data?.id ? recipientData : true"></app-form-configuration> -->
      <app-form-config confType="recipient" (validation)="formValid($event)"
       [patchValue]="recipientData" *ngIf="data?.id ? recipientData : true" (onSubmitData)="submitForm($event)"></app-form-config>
</mat-dialog-content>

<mat-dialog-actions class="io-row-l">

  <div class="io-row align-items-center">
    <mat-icon class="no-flex" color="warn">info</mat-icon>
    <p class="info-note">
      {{ 'Please enter the correct information about recipient or we will not be able to complete your payment.' |
      translate }}
    </p>
  </div>

  <div class="no-flex">
    <button mat-button color="primary" mat-dialog-close>{{ 'Cancel' | translate }}</button>
    <button mat-raised-button type="submit" color="primary" class="item-save" [disabled]="changed"
      (click)="child.onSubmit()">{{'Save' | translate }}</button>
  </div>

</mat-dialog-actions>