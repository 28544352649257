<div class="io-pt-2">

  <mat-accordion [multi]="true"
    *ngIf="(items |filterOn:(transaction?.id?['transaction', 'id']:'level'):[transaction?.id ? transaction?.id : level]) as items">
    <div *ngIf="items?.length == 0">No flags raised and tickets added.</div>
    <mat-expansion-panel *ngFor="let rf of items; last as last">
      <mat-expansion-panel-header style="padding: 0 16px 0 8px;">
        <mat-panel-title class="io-row no-gap">
          <mat-icon mat-card-avatar class="icon no-flex" *ngIf="!rf.resolved" color="warn">error</mat-icon>
          <mat-icon mat-card-avatar class="done no-flex" *ngIf="rf.resolved" color="success">check_circle</mat-icon>
          <span class="file-name">
            <div matTooltip="{{ rf?.flag?.name }}">{{ rf?.flag?.name }}</div>
            <span matTooltip="{{ rf?.created | timezone }} {{ rf?.resolved ? '-' : '' }} {{ rf?.resolved | timezone }}">
              {{ rf.created | dateAgo }}</span>
          </span>

          <div class="no-flex io-mr-1 io-pr-1">
            <div class="open-ticket-{{rf?.ticket?.status?.toLowerCase().split('_').join('')}}" *ngIf="rf.ticket">
              <mat-icon class="icon-ticket">local_offer</mat-icon>
              <p class="ticket-number">{{rf.ticket?.number}}</p>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="output">


        <table class="table-details">
          <tbody>
            <tr>
              <th>{{ 'Created' | translate }}</th>
              <td>{{ rf?.created | timezone | date }}</td>
            </tr>
          </tbody>
        </table>
        <app-json-renderer [items]="rf?.description"></app-json-renderer>
        <table class="table-details">
          <tbody>
            <tr>
              <th>{{ 'Resolved by' | translate }}</th>
              <td>{{rf.resolvedBy?.email | noData }}</td>
            </tr>
            <tr>
              <th>{{ 'Resolved on' | translate }}</th>
              <td>{{ rf?.resolved | timezone | date | noData }}</td>
            </tr>
            <tr>
              <th>{{ 'Resolved Note' | translate }}</th>
              <td>{{ rf?.resolvedNote | noData }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <mat-action-row
        *ngIf="!rf.resolved && auth.hasPermission(auth.perm.FlagsManage) 
        || (rf.ticket && auth.hasPermission(auth.perm.FlagsRead)) 
        || (!rf.resolved && !rf.ticket && auth.hasPermission(auth.perm.FlagsManage))">
        <button mat-stroked-button class="resolve-btn" (click)="ms.removeFlag(rf);"
          *ngIf="!rf.resolved && auth.hasPermission(auth.perm.FlagsManage)" 
          [disabled]="!transaction?.sender">{{ 'Resolve' | translate }}
        </button>
        <button mat-raised-button color="primary" *ngIf="rf.ticket && auth.hasPermission(auth.perm.FlagsRead)"
          [routerLink]="['/','admin','users', userId, 'tickets','ticket', rf.ticket.id]" 
          [disabled]="!transaction?.sender">{{ 'View Ticket' | translate }}
        </button>
        <button mat-raised-button color="primary"
          *ngIf="!rf.resolved && !rf.ticket && auth.hasPermission(auth.perm.FlagsManage) && ['WATCHLIST_HIT', 'KYC_PEP'].indexOf(rf?.flag?.code) == -1"
          (click)="ms.createTicket(rf, userId, transaction?.id);" [disabled]="!transaction?.sender">
          {{ 'Add Ticket' | translate }}
        </button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>


</div>