import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UiService } from 'src/app/_services/ui.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminPurposesService } from 'src/app/_services/admin/admin-purposes.service';
import { CustomValidators } from 'src/app/_helpers/custom.validators';

@Component({
  selector: 'app-purposes-item',
  templateUrl: './purposes-item.component.html',
  styleUrls: ['./purposes-item.component.scss']
})
export class PurposesItemComponent implements OnInit, OnDestroy {
  create$: Subscription
  modify$: Subscription
  form = this.fb.group({
    id: [this.data.id],
    name: [this.data.name, [Validators.required, CustomValidators.noWhitespaceValidator()]],
    isActive: [this.data.isActive || false, Validators.required],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private service: AdminPurposesService,
    private ui: UiService,
    private snack: MatSnackBar,
    private dialogRef: MatDialogRef<PurposesItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.form.patchValue(this.data)
    if (this.data.code) {
      this.form.get('name').disable()
    }
  }

  onSubmit() {
    if (this.form.valid) {
      var d = this.form.getRawValue();
      var s = !d.id ? this.service.create(d) : s = this.service.modify(d);
      var k = s.subscribe({
        next: x => {
          this.ui.snack('Purpose ' + (d.id ? 'saved!' : 'created!'));
          k?.unsubscribe();
          this.dialogRef.close();
        }
      });
    }
  }

  ngOnDestroy(): void {

  }
}
