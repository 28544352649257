<div *ngIf="documents?.length" style="margin-top: 20px;">

  <mat-accordion [multi]="true">
    <mat-expansion-panel *ngFor="let document of documents; let i = index;let first = first">
      <mat-expansion-panel-header style="padding: 0 16px 0 8px;">
        <mat-panel-title class="io-row">
          <mat-icon class="file-icon no-flex">{{document?.name|filetypeIcon}}</mat-icon>
          <span class="file-name" *ngIf="document.category?.name.length">{{
            document.category?.name }} <span *ngIf="document?.documentNumber">
              {{document?.documentNumber}}</span>
          </span>
          <span class="file-name" *ngIf="!document.category?.name.length">{{
            document.name }} <span *ngIf="document?.documentNumber">
              {{document?.documentNumber}}</span></span>

          <span *ngIf="document?.isPrimary" class="tag no-flex">{{ 'Primary document' | translate}}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <table class="table-details narrow">
        <tbody>
          <tr>
            <th>
              {{ 'Number' | translate }}
              <span *ngIf="document?.cardNumber"> / {{ 'Card Number' | translate }}</span>
            </th>
            <td>
              {{ document?.documentNumber | noData }}
              <span *ngIf="document?.cardNumber">/ {{ document?.cardNumber | noData }}</span>
            </td>
          </tr>
          <tr>
            <th>{{ 'Expiration Date' | translate }}</th>
            <td>{{ document?.documentExp | date | noData }}</td>
          </tr>
          <tr>
            <th>{{ 'Created By' | translate }}</th>
            <td>{{ document?.createdByName | noData }}</td>
          </tr>
          <tr>
            <th>{{ 'Issuer' | translate }}</th>
            <td>{{ document?.documentIssuer | noData }}</td>
          </tr>
          <tr>
            <th>{{ 'State' | translate }}</th>
            <td>{{ document?.documentState | noData }}</td>
          </tr>
          <tr *ngIf="isUndefined(document?.documentNote)">
            <th>{{ 'Note' | translate }}</th>
            <td>{{ document?.documentNote | noData}}</td>
          </tr>
          <tr *ngIf="isUndefined(document?.documentSource)">
            <th>{{ 'Source' | translate }}</th>
            <td>{{ document?.documentSource | noData }}</td>
          </tr>
          <tr>
            <th>{{ 'Entered' | translate }}</th>
            <td>{{ document?.created | date | noData }}</td>
          </tr>
          <tr>
            <th>{{ 'Files' | translate }}</th>
            <td>
              <span *ngIf="!document?.files.length">{{null|noData}}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <mat-action-list dense style="width:100%;">
        <button mat-list-item *ngFor="let file of document?.files" color="primary"
          (click)="publicService.downloadFile(file.id, file.name)" style="padding-left: 0;">
          <mat-icon style="color: #707070;" mat-list-icon>{{file.name|filetypeIcon}}</mat-icon>
          <div mat-line>
            {{file.name}}
          </div>
        </button>
      </mat-action-list>

      <mat-action-row *ngIf="auth.hasPermission(auth.perm.DocumentsManage)" style="margin-left: 8px">
        <button (click)="ms.uploadDocument(userId, document, true, ticketId, country);" mat-stroked-button
          color="primary">
          {{'Edit'|translate}}
        </button>
        <button mat-raised-button color="primary" (click)="ms.onPrimarySelectedDialog(document, userId)"
          [disabled]="document?.isPrimary">{{'Set as primary'|translate}}</button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<p *ngIf="!documents?.length">{{ 'Nothing uploaded yet.' | translate }}</p>