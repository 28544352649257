<div class="io-item  io-item-shadow" *ngIf="user$|async as item "
  style="margin: 0 0 6px 0; padding-top: 8px; padding-bottom: 8px;">
  <div class="main">
    <h4>{{item?.userRef}}</h4>
    <button mat-icon-button routerLink="/admin/users" class="no-flex">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <div class="flag">
      <app-country-flag [item]="item?.country" [size]="'big'"></app-country-flag>
    </div>
    <div class="data name">
      <small>{{'Name'|translate}}</small>
      <span>{{ item?.firstName }} {{ item?.middleName }} {{ item?.lastName }}&nbsp;</span>
    </div>
    <div class="data country">
      <small>{{'Country'|translate}}</small>
      <span>{{ item?.country?.name }}&nbsp;</span>
    </div>
    <div class="data email">
      <small>{{'E-mail'|translate}}</small>
      <span>{{ item?.email }}&nbsp;</span>
    </div>

    <div [ngClass]="{'data': true, 'medium':true, opacity: (item?.moneySent == null ) }">
      <small>{{'Total summary'|translate}}</small>
      <span matTooltip="{{ item?.country?.currency }} {{ item?.moneySent | number: '1.6-6' }}">
        {{ item?.country?.currency }}
        {{ (item?.moneySent || 0) | number: '1.2-2'}}
        &nbsp;</span>
    </div>
    <div [ngClass]="{'data': true, 'opacity': !item?.lastSuccessfulLogin, 'medium': true}">
      <small>
        <!-- {{item?.lastSuccessfulLogin}} -->
        {{'Last active'|translate}}
      </small>
      <span>
        {{ item?.lastSuccessfulLogin | timezone2 | date:'short'}} &nbsp;</span>
    </div>

  </div>
</div>
<nav mat-tab-nav-bar>
  <a mat-tab-link *ngFor="let item of navLinks" [active]="rla.isActive" [routerLink]="item.url" routerLinkActive
    #rla="routerLinkActive" [routerLinkActiveOptions]="{exact:false}">
    {{item.label | translate | uppercase}}
  </a>

  <a mat-tab-link [active]="rla.isActive" routerLink="tickets" routerLinkActive #rla="routerLinkActive"
    *ngIf="auth.hasPermission(auth.perm.TicketsRead)" [routerLinkActiveOptions]="{exact:false}">
    <div
      [matBadge]="(adminFlagsService$|async|filterOn:['ticket', 'status']:['INITIATED','RESPONDED','MORE_INFO_NEEDED'])?.length"
      [matBadgeHidden]="(adminFlagsService$|async|filterOn:['ticket', 'status']:['INITIATED','RESPONDED','MORE_INFO_NEEDED'])?.length == 0"
      matBadgeOverlap="false" matBadgeColor="warn" matBadgeSize="small">
      {{'Tickets' | translate | uppercase}}
    </div>
  </a>
  <a mat-tab-link [active]="rla.isActive" routerLink="flags" routerLinkActive #rla="routerLinkActive"
    *ngIf="auth.hasPermission(auth.perm.FlagsRead)" [routerLinkActiveOptions]="{exact:false}">
    <div [matBadge]="(adminFlagsService$|async|filterOn:['resolved']:[null])?.length"
      [matBadgeHidden]="(adminFlagsService$|async|filterOn:['resolved']:[null])?.length == 0" matBadgeOverlap="false"
      matBadgeColor="warn" matBadgeSize="small">
      {{'Flags' | translate | uppercase}}
    </div>
  </a>
  <a mat-tab-link [active]="rla.isActive" routerLink="risk-assessment" routerLinkActive #rla="routerLinkActive"
    *ngIf="auth.hasPermission(auth.perm.RiskAssementManage)" [routerLinkActiveOptions]="{exact:false}">
    {{'Risk Assessment' | translate | uppercase}}
  </a>
  <a mat-tab-link [active]="rla.isActive" routerLink="transactions-history" routerLinkActive #rla="routerLinkActive"
    *ngIf="auth.hasPermission(auth.perm.TransactionsHistoryRead)" [routerLinkActiveOptions]="{exact:false}">
    {{'Transactions History' | translate | uppercase}}
  </a>
  <a mat-tab-link [active]="rla.isActive" routerLink="consents" routerLinkActive #rla="routerLinkActive"
    *ngIf="auth.hasPermission(auth.perm.ConsentView)" [routerLinkActiveOptions]="{exact:false}">
    {{'Consents' | translate | uppercase}}
  </a>
  <a mat-tab-link [active]="rla.isActive" routerLink="kyc-logs" routerLinkActive #rla="routerLinkActive"
    *ngIf="auth.hasPermission(auth.perm.VerificationLogsRead)" [routerLinkActiveOptions]="{exact:false}">
    {{'KYC Logs' | translate | uppercase}}
  </a>
</nav>

<router-outlet></router-outlet>