import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilderComponent } from './form-builder/form-builder.component';
import { FormConfigurationComponent } from './form-configuration/form-configuration.component';
import { FormDataDisplayConfigurationComponent } from './form-data-display-configuration/form-data-display-configuration.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { InputFieldFileComponent } from './input-field-file/input-field-file.component';
import { ComponentsModule } from '../components.module';
import { PublicModule } from 'src/app/public/public.module';
import { LoginModule } from 'src/app/public/login/login.module';
import { SingleSelectModule } from '../select-country/single-select.module';
import { MonthYearDatePickerModule } from '../month-year-date-picker/month-year-date-picker.module';
import { FormConfigComponent } from './form-config/form-config.component';
import { ReviewStepComponent } from './review-step/review-step.component';

@NgModule({
  declarations: [
    FormBuilderComponent,
    FormConfigurationComponent,
    FormDataDisplayConfigurationComponent,
    InputFieldFileComponent,
    FormConfigComponent,
    ReviewStepComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    TranslateModule.forChild(),
    LoginModule,
    SingleSelectModule,
    MonthYearDatePickerModule
  ],
  exports: [
    FormBuilderComponent,
    FormConfigurationComponent,
    FormDataDisplayConfigurationComponent,
    InputFieldFileComponent,
    FormConfigComponent,
    ReviewStepComponent
  ]
})
export class FormConfigsModule { }
