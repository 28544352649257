import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminWrapperComponent } from './wrapper/wrapper.component';
import { UsersComponent } from './users/users.component';
import { LoginComponent } from './login/login.component';
import { TranslationsComponent } from './translations/translations.component';
import { LanguagesComponent } from './languages/languages.component';
import { AdminsComponent } from './admins/admins.component';

import { CountriesComponent } from './countries/countries.component';
import { AuthGuard } from '../_guards/auth.guard';

import { RelationshipsComponent } from './relationships/relationships.component';
import { PurposesComponent } from './purposes/purposes.component';
import { TransactionItemComponent } from '../admin/transactions/transaction-item/transaction-item.component';
import { RegionsComponent } from './regions/regions.component';
import { AdminTicketsComponent } from './admin-tickets/admin-tickets.component';
import { CurrencyComponent } from './currency/currency.component';


import { TransactionsHistoryComponent } from './transactions/transactions-history/transactions-history.component';
import { CorridorsComponent } from './constraints/corridors.component';
import { AdminTransactionsComponent } from './transactions/transactions.component';
import { InterfacePermissions } from '../_graphql/schema';
import { UserProfileComponent } from './users/profile/user-profile.component';
import { AdminRecipientsComponent } from './users/admin-recipients/admin-recipients.component';
import { AdminTicketItemComponent } from './admin-tickets/admin-ticket-item/admin-ticket-item.component';
import { AdminPayoutsComponent } from './payouts/payouts.component';
import { AdminTabWrapperComponent } from './admin-tab-wrapper/admin-tab-wrapper.component';
import { RiskAssessmentComponent } from './risk-assessment/risk-assessment.component';
import { AdminConsentsComponent } from './users/consents/admin-consents.component';
import { AdminKycLogsComponent } from './users/admin-kyc-logs/admin-kyc-logs.component';
import { LogoutComponent } from '../components/panel/logout/logout.component';
import { AdminPayoutTransactionItemComponent } from './payouts/payout-transaction-item/payout-transaction-item.component';
import { CorridorItemComponent } from './constraints/corridor-item/corridor-item.component';
import { AdminTicketsAndFlagsOverviewComponent } from './admin-tickets/admin-ticket-and-flags-overview/admin-ticket-and-flags-overview.component';
import { AdminTicketsAndFlagsWrapperComponent } from './admin-tickets/admin-ticket-and-flags-wrapper/admin-ticket-and-flags-wrapper.component';
import { AdminFlagsComponent } from './admin-flags/admin-flags.component';
import { AdminFlagItemComponent } from './admin-flags/admin-flag-item/admin-flag-item.component';

export const routes: Routes = [
  {
    path: 'admin/login',
    component: LoginComponent,
    data: {
      title: "Login"
    }
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: {
      title: "Logout"
    }
  },
  {
    path: 'logout/:redirect',
    component: LogoutComponent,
  },
  {
    path: 'admin',
    component: AdminWrapperComponent,
    children: [
      {
        path: '',
        redirectTo: '/admin/login',
        pathMatch: 'full'
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [AuthGuard],
        data: {
          allowedPermission: InterfacePermissions.UsersRead,
          title: "Users"
        }
      },
      {
        path: 'users/:userId/transactions/:transactionId',
        component: TransactionItemComponent,
        canActivate: [AuthGuard],
        data: {
          allowedPermission: InterfacePermissions.TransactionsRead,
          title: "Transaction"
        },
      },
      {
        path: 'users/:userId',
        component: AdminTabWrapperComponent,
        canActivate: [AuthGuard],
        data: {
          allowedPermission: InterfacePermissions.UsersRead,
          title: "User"
        },

        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'profile'
          },
          {
            path: 'profile',
            component: UserProfileComponent,
            canActivate: [AuthGuard],
            data: {
              allowedPermission: InterfacePermissions.TransactionsRead,
              title: "Profile"
            }
          },
          {
            path: 'transaction-item',
            component: UserProfileComponent,
            canActivate: [AuthGuard],
            data: {
              allowedPermission: InterfacePermissions.TransactionsRead,
              title: "Transaction"
            }
          },
          {
            path: 'transactions',
            canActivate: [AuthGuard],
            data: {
              allowedPermission: InterfacePermissions.TransactionsRead,
              title: "Transactions"
            },
            children: [
              {
                path: 'recipient/:recipientId',
                component: AdminTransactionsComponent,
                canActivate: [AuthGuard],
                data: {
                  allowedPermission: InterfacePermissions.TransactionsRead,
                  config: {
                    hideTitle: true,
                    dateTo: true,
                    dateFrom: true,
                    recipientId: true,
                    query: true,
                    searchButton: false,
                    title: "Transactions"
                  }
                },
              },
              {
                path: '',
                component: AdminTransactionsComponent,
                canActivate: [AuthGuard],
                data: {
                  allowedPermission: InterfacePermissions.TransactionsRead,
                  config: {
                    hideTitle: true,
                    dateTo: true,
                    dateFrom: true,
                    recipientId: true,
                    query: true,
                    searchButton: false,
                    title: "Transactions"
                  }
                },
              },
            ]
          },
          {
            path: 'tickets/ticket/:ticketId',
            component: AdminTicketItemComponent,
            canActivate: [AuthGuard],
            data: {
              allowedPermission: InterfacePermissions.TicketsRead,
              title: "Ticket"
            }
          },
          {
            path: 'tickets',
            component: AdminTicketsComponent,
            canActivate: [AuthGuard],
            data: {
              config: {
                hideTitle: true,
              },
              allowedPermission: InterfacePermissions.TicketsRead,
              title: "Tickets"
            }
          },
          {
            path: 'flags/flag/:flagId',
            component: AdminFlagItemComponent,
            canActivate: [AuthGuard],
            data: {
              allowedPermission: InterfacePermissions.FlagsRead,
              title: "Flag"
            }
          },
          {
            path: 'flags',
            component: AdminFlagsComponent,
            canActivate: [AuthGuard],
            data: {
              config: {
                hideTitle: true,
              },
              allowedPermission: InterfacePermissions.FlagsRead,
              title: "Flags"
            }
          },

          {
            path: 'recipients',
            component: AdminRecipientsComponent,
            canActivate: [AuthGuard],
            data: {
              allowedPermission: InterfacePermissions.RecipientsRead,
              title: "Recipients"
            }
          }, {
            path: 'transactions-history',
            component: TransactionsHistoryComponent,
            canActivate: [AuthGuard],
            data: {
              allowedPermission: InterfacePermissions.TransactionsHistoryRead,
              title: "Transactions History"
            }
          }, {
            path: 'risk-assessment',
            component: RiskAssessmentComponent,
            canActivate: [AuthGuard],
            data: {
              allowedPermission: InterfacePermissions.TransactionsHistoryRead,
              title: "Risk Assessment"
            }
          }, {
            path: 'consents',
            component: AdminConsentsComponent,
            canActivate: [AuthGuard],
            data: {
              allowedPermission: InterfacePermissions.ConsentView,
              title: "Consents"
            }
          }, {
            path: 'kyc-logs',
            component: AdminKycLogsComponent,
            canActivate: [AuthGuard],
            data: {
              allowedPermission: InterfacePermissions.VerificationLogsRead,
              title: "KYC Logs"
            }
          }
        ]
      },
      {
        path: 'users/:userId/:transactionId',
        component: UserProfileComponent,
        canActivate: [AuthGuard],
        data: {
          allowedPermission: InterfacePermissions.TransactionsRead,
          title: "Transaction"
        }
      },
      {
        path: 'admins',
        component: AdminsComponent,
        canActivate: [AuthGuard],
        data: {
          allowedPermission: InterfacePermissions.ManagersRead,
          title: "Managers"
        }
      },
      {
        path: 'transactions',
        component: AdminTransactionsComponent,
        canActivate: [AuthGuard],
        data: {
          config: {
            dateTo: true,
            dateFrom: true,
            query: true,
            onHold: true,
            statusIds: true,
            searchButton: false,
          },
          title: "Transactions",
          allowedPermission: InterfacePermissions.TransactionsRead,
        }
      },
      {
        path: 'payouts',
        component: AdminPayoutsComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Payouts",
          config: {
            dateTo: true,
            dateFrom: true,
            query: true,
            onHold: true,
            statusIds: true,
            searchButton: false,
          },
          allowedPermission: InterfacePermissions.TransactionsPayoutsRead,
        }
      },
      {
        path: 'payouts/:transactionId/:pickupCode',
        component: AdminPayoutTransactionItemComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Payouts",
          config: {
            dateTo: true,
            dateFrom: true,
            query: true,
            onHold: true,
            statusIds: true,
            searchButton: false,
          },
          allowedPermission: InterfacePermissions.TransactionOutboundStatusManage,
        }
      },
      {
        path: 'payouts/:transactionId',
        component: AdminPayoutTransactionItemComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Payouts",
          config: {
            dateTo: true,
            dateFrom: true,
            query: true,
            onHold: true,
            statusIds: true,
            searchButton: false,
          },
          allowedPermission: InterfacePermissions.TransactionOutboundStatusManage,
        }
      },
      {
        path: 'tickets-and-flags',
        component: AdminTicketsAndFlagsWrapperComponent,
        canActivate: [AuthGuard],
        data: {
          allowedPermission: InterfacePermissions.TicketsRead,
        },
        children: [
          {
            path: 'flags',
            canActivate: [AuthGuard],
            component: AdminFlagsComponent,
            data: {
              title: "Flags",
              config: {
                hideTitle: true,
              },
              allowedPermission: InterfacePermissions.FlagsRead,
            }
          }, {
            path: 'tickets',
            component: AdminTicketsComponent,
            canActivate: [AuthGuard],
            data: {
              title: "Tickets",
              config: {
                hideTitle: true,
              },
              allowedPermission: InterfacePermissions.TicketsRead,
            }
          },
          {
            path: '',
            component: AdminTicketsAndFlagsOverviewComponent,
            canActivate: [AuthGuard],
            data: {
              title: "Flags & Tickets Overview",
              config: {
                hideTitle: true,
              },
              allowedPermission: InterfacePermissions.TicketsRead,
            }
          },
        ]
      },
      {
        path: 'tickets-and-flags/flags/flag/:flagId',
        component: AdminFlagItemComponent,
        canActivate: [AuthGuard],
        data: {
          allowedPermission: InterfacePermissions.FlagsRead,
          title: "Flag"
        }
      },
      {
        path: 'tickets-and-flags/tickets/ticket/:ticketId',
        canActivate: [AuthGuard],
        component: AdminTicketItemComponent,
        data: {
          title: "Ticket",
          allowedPermission: InterfacePermissions.TicketsRead,
        }
      },
      {
        path: 'regions',
        component: RegionsComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Regions",
          allowedPermission: InterfacePermissions.RegionsRead,
        }
      },
      {
        path: 'transaction-item/:transactionId',
        component: TransactionItemComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Transaction",
          allowedPermission: InterfacePermissions.TransactionsRead,
        }
      },
      {
        path: 'countries',
        component: CountriesComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Countries",
          allowedPermission: InterfacePermissions.CountriesRead,
        }
      },
      {
        path: 'corridors',
        component: CorridorsComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Corridors",
          allowedPermission: InterfacePermissions.CorridorsRead,
        }
      },
      {
        path: 'corridors/:corridorId',
        component: CorridorItemComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Corridor",
          allowedPermission: InterfacePermissions.CorridorsManage,
        }
      },
      {
        path: 'currencies',
        component: CurrencyComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Currencies",
          allowedPermission: InterfacePermissions.CompanyManage,
        }
      },
      {
        path: 'relationships',
        component: RelationshipsComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Relationships",
          allowedPermission: InterfacePermissions.CompanyManage,
        }
      },
      {
        path: 'purposes',
        component: PurposesComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Purposes",
          allowedPermission: InterfacePermissions.CompanyManage,
        }
      },
      {
        path: 'translations',
        component: TranslationsComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Translations",
          allowedPermission: InterfacePermissions.CompanyManage,
        }
      },
      {
        path: 'languages',
        component: LanguagesComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Languages",
          allowedPermission: InterfacePermissions.CompanyManage,
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
