import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { TransactionItemComponent } from 'src/app/admin/transactions/transaction-item/transaction-item.component';


@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TransactionItemComponent>,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      status: Boolean
    })
  }

  statusChange(form) {
    form.value.status = true;
    this.dialogRef.close(`${form.value.status}`);
  }
}
