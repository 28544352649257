<div mat-dialog-title>
  {{ 'Raise flag' | translate }}
</div>

<mat-dialog-content>
  <form class="container" [formGroup]="form">
    <mat-form-field appearance="outline">
      <mat-label>Flag</mat-label>
      <mat-select required formControlName="flagId">
        <mat-option *ngFor="let flag of flags$ | async" [value]="flag.id">
          {{ flag.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'Description' | translate }}</mat-label>
      <textarea matInput required formControlName="description" maxlength="200"></textarea>
    </mat-form-field>
    <mat-hint align="center">Raising flag without ticket will display issue message on transactions without
      details.</mat-hint>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>{{ 'Cancel' | translate }}</button>
  <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="setFlag(false)">{{ 'Raise flag' |
    translate }}</button>
  <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="setFlag(true)">{{ 'Raise flag and create
    ticket' | translate }}</button>
</mat-dialog-actions>