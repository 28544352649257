<div>

  <div *ngFor="let item of items$|async|filterOn:['flag', 'isWarning']:[true]">
    <div class="io-item io-flag-item io-item-shadow clickable" (click)="flagAction(item.flag)">
      <!-- {{item?.raisedFlag|json}} -->
      <div class="main">
        <div class="io-ml-2">
          <mat-icon>error</mat-icon>
        </div>
        <div class="data subject">
          <div>
            <div><strong>
                {{ mylocalize(('FLAG_' + item.flag.presentedCode), ('FLAG_' + item.flag.presentedCode) | translate,
                ('FLAG_' + item.flag.presentedCode+'_'+userCountry),
                ('FLAG_' + item.flag.presentedCode+'_'+userCountry) | translate) }}
              </strong>
            </div>
            <div>
              {{ mylocalize(('FLAG_' + item.flag.presentedCode+'_DESC'), ('FLAG_' + item.flag.presentedCode+'_DESC') |
              translate,
              ('FLAG_' + item.flag.presentedCode+'_DESC_'+userCountry),
              ('FLAG_' + item.flag.presentedCode+'_DESC_'+userCountry) | translate) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf=" (items$|async|filterOn:['flag', 'isWarning']:[true])?.length == 0">
  <ng-content></ng-content>
</div>

<!-- <div *ngIf="flags?.length">
  <mat-action-list>
    <mat-list-item class="flag-item" *ngFor="let flag of flags" (click)="flagAction(flag.flag)">
      <div class="data">
        <div matListIcon class="icon-section">
          <mat-icon class="flag-icon">error</mat-icon>
        </div>
        <div class="content">

          <h3> {{ mylocalize(('FLAG_' + flag.flag.presentedCode), ('FLAG_' + flag.flag.presentedCode) | translate,
            ('FLAG_' + flag.flag.presentedCode+'_'+userCountry),
            ('FLAG_' + flag.flag.presentedCode+'_'+userCountry) | translate) }}</h3>
          <p> {{ mylocalize(('FLAG_' + flag.flag.presentedCode+'_DESC'), ('FLAG_' + flag.flag.presentedCode+'_DESC') |
            translate,
            ('FLAG_' + flag.flag.presentedCode+'_DESC_'+userCountry),
            ('FLAG_' + flag.flag.presentedCode+'_DESC_'+userCountry) | translate) }}</p>
        </div>
      </div>
    </mat-list-item>
  </mat-action-list>
</div> -->