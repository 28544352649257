import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ListWrapperModule } from '../list-wrapper/list-wrapper.module';
import { AdminConsentsComponent } from './consents/admin-consents.component';
import { TranslateModule } from '@ngx-translate/core';
import { ConsentItemComponent } from './consents/consent-item/consent-item.component';
import { PipesModule } from 'src/app/_pipes/pipes.module';

@NgModule({
    declarations: [AdminConsentsComponent, ConsentItemComponent],
    imports: [CommonModule, MaterialModule, ListWrapperModule, TranslateModule, FormsModule, ReactiveFormsModule, PipesModule]
})
export class UsersModule { }
