import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ActivatedRoute, Router } from '@angular/router';
import { Unsubscribable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { InterfacePermissions } from 'src/app/_graphql/schema';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';

import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'user-list-wrapper',
  templateUrl: './list-wrapper.component.html',
  styleUrls: ['./list-wrapper.component.scss'],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'standard' }, },
  ]
})
export class UserListWrapperComponent implements OnInit, OnDestroy {

  @Input('disablePlaceholder') disablePlaceholder = false;

  @Input('showQueryField') showQueryField = true;
  @Input('showPagination') showPagination: boolean = true;

  @Input('mainService') mainService: any;
  @Input('navigateItem') navigateItem: string;
  @Input('panelTitle') title: string;
  @Input('key') key: string = null;
  @Input('customKey') customKey: string = null;
  @Input('readPerm') readPerm: InterfacePermissions;
  @Input('managePerm') managePerm: InterfacePermissions;
  @Input('hideAddBtn') hideAddBtn: boolean = false;

  @Output() onNew: EventEmitter<any> = new EventEmitter();
  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  data$: Unsubscribable;
  dateFilter$: Unsubscribable;


  constructor(
    public auth: AuthenticationService,
    public ui: UiService,
    public modalLauncherService: ModalLauncherService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
  ) {

  }
  ngOnDestroy() {
    this.data$?.unsubscribe();
    this.dateFilter$?.unsubscribe();
  }

  ngOnInit(): void {

    this.ui.setTitle(this.title);
  }

  public newItem() {
    this.onNew.emit(null)
  }

  public edit(id: number | string | null | undefined) {
    this.onEdit.emit(id)
  }





}
