import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { TicketType } from 'src/app/_graphql/schema';
import { AdminUserFragmentBasic, EndUserFragmentBasic, FilesFragmentBasic, UserInfoFragment } from 'src/app/_graphql/shared-fragments';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class AdminTicketsService extends BaseService<TicketType>{

  selectOneFields = gql`
    fragment TicketSelectOneFields on TicketType  {
      id internalNote description status number subject
      raisedFlag { 
        created  description  resolvedNote
        createdByName
        flag { id name code }
        level resolved
        resolvedBy { id ...UserInfoFragment }
        transaction { 
          id transactionId 
          # sender { id firstName lastName middleName}
        } 
        forUserId user { id ...UserInfoFragment }
      }
      created createdByName
      files { id ...FilesFragmentBasic }
    }
    ${UserInfoFragment}
    ${FilesFragmentBasic}
  `;

  selectAllFields = gql`
    fragment TicketSelectAllFields on TicketType  {
      id status number subject created 
      raisedFlag { level transaction { 
        id transactionId 
        # sender { id firstName lastName middleName} 
        } forUserId user { id firstName lastName middleName} createdByName 
      }
      unseenMessagesCount createdByName
      description
  }`;

  constructor(protected apollo: Apollo) {
    super(apollo);
    this.mainScope = "flagsAndTickets";
    this.initGql('ticket');
  }

  public sendMessage(data: any) {
    return this.mutation(
      gql
        `mutation sendMessage(
          $data: MessageInputType!
        ) {
          flagsAndTickets {
          __typename
          sendMessage(
            data: $data
          ) {
            id
            __typename
          }
        }
      }
      `, { data }
    );
  }



  public closeTicket(ticketId: any) {
    return this.mutation(
      gql
        `mutation closeTicket(
          $ticketId: ID
        ) {
          flagsAndTickets {
          __typename
          closeTicket(
            ticketId: $ticketId
          ) {
            id
            __typename
          }
        }
      }
      `, { ticketId }
    );
  }


  statsActive() {
    return this.unwrapData('ticketsActive', this.query(
      gql`query ticketsActive {
        flagsAndTickets {
          ticketsActive: tickets(pageRequest: { filters: { notResolved: true } }) {
            totalCount
          }
        }
      }`
    ))
  }

  statsTotal() {
    return this.unwrapData('ticketsTotal', this.query(
      gql`query ticketsTotal {
        flagsAndTickets {
          ticketsTotal:tickets {
            totalCount
          }
        }
      }      
      `
    ))
  }
}
