import { Component, OnInit, Input } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { UiService } from 'src/app/_services/ui.service';


@Component({
  selector: 'app-transaction-history-list',
  templateUrl: './transaction-history-list.component.html',
  styleUrls: ['./transaction-history-list.component.scss']
})
export class TransactionHistoryListComponent implements OnInit {

  @Input() items: any[];


  constructor() { }

  ngOnInit() { }


}
