<div mat-dialog-title>
  {{ 'Cancel Transaction' | translate }}
  <button mat-icon-button mat-dialog-close class="close">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content>

  {{ 'Are you sure you want cancel transaction' | translate }} <a>{{ data?.transactionId || '' }} </a>{{ 'for' |
  translate}} <a>{{ data?.recipientFirstName || '' }}</a>?

</mat-dialog-content>
<mat-dialog-actions>
  <button class="no-button" mat-stroked-button mat-dialog-close>{{ 'No' | translate }}</button>
  <button class="yes-button" mat-raised-button color="warn" mat-dialog-close (click)="cancelTransaction()">{{ 'Yes' |
    translate }}</button>
</mat-dialog-actions>