<user-list-wrapper [mainService]="service" panelTitle="Payment Methods">

  <div class="io-row io-mx-2">
    <div></div>
    <button mat-stroked-button color="primary" class="no-flex" [matMenuTriggerFor]="paymentMenu" 
    *ngIf="(paymentMethods$ | async)?.inbounds?.length >1">
      {{'Add New'|translate}}
    </button>
    <mat-menu #paymentMenu="matMenu">
      <button mat-menu-item (click)="openModal()">
          {{ 'Payment Method' | translate }}
      </button>
      <button mat-menu-item (click)="modalLauncherService.newAgreement()">
          {{ 'Agreement' | translate }}
      </button>
  </mat-menu>
  <button mat-stroked-button color="primary" class="no-flex" 
  *ngIf="(paymentMethods$ | async)?.inbounds?.length === 1 &&
  ((paymentMethods$ | async)?.inbounds | filterOn : 'code' : ['PAYTO']).length >0" 
  (click)="modalLauncherService.newAgreement()">
    {{'Add New Agreement'|translate}}
  </button>
  <button mat-stroked-button color="primary" class="no-flex" 
  *ngIf="(paymentMethods$ | async)?.inbounds?.length === 1 &&
  ((paymentMethods$ | async)?.inbounds | filterOn : 'code' : ['MOOV']).length >0" 
  (click)="openModal()">
    {{'Add New Payment Method'|translate}}
  </button>
  </div>


  <app-panel-placeholder placeholder *ngIf="((items$|async)?.length == 0 || (items$|async) == null) && !(showVerify || modalLauncherService.showVerify)"
    key="no_payment_methods" mode="large" image="/assets/images/placeholder-payment-method.svg">
    <button mat-raised-button color="primary" aria-label="Add account" *ngIf="(paymentMethods$ | async)?.inbounds?.length >1"
      [matMenuTriggerFor]="matMenuPlaceholder">
      {{'Add New'|translate}}</button>
      <mat-menu #matMenuPlaceholder="matMenu">
        <button mat-menu-item (click)="openModal()">
            {{ 'Payment Method' | translate }}
        </button>
        <button mat-menu-item (click)="modalLauncherService.newAgreement()">
            {{ 'Agreement' | translate }}
        </button>
    </mat-menu>
      <button mat-raised-button color="primary" aria-label="Add account"
      *ngIf="(paymentMethods$ | async)?.inbounds?.length === 1 &&
      ((paymentMethods$ | async)?.inbounds | filterOn : 'code' : ['PAYTO']).length >0" 
      (click)="modalLauncherService.newAgreement()">  {{'Add New Agreement'|translate}}</button>
      <button mat-raised-button color="primary" aria-label="Add account"
      *ngIf="(paymentMethods$ | async)?.inbounds?.length === 1 &&
      ((paymentMethods$ | async)?.inbounds | filterOn : 'code' : ['MOOV']).length >0" 
      (click)="openModal()">  {{'Add New Payment Method'|translate}}</button>
  </app-panel-placeholder>
  <app-panel-placeholder placeholder *ngIf="((items$|async)?.length == 0 || (items$|async) == null) && (showVerify || modalLauncherService.showVerify)"
    superTitle="FLAG_KYC_MISSING" superDescription="FLAG_KYC_MISSING_DESC" mode="large"
    image="/assets/images/placeholder-identity.svg">
    <button mat-raised-button color="primary" aria-label="Add account" [routerLink]="['/', 'platform', 'kyc']">{{'Go to
      Verification '|translate}}</button>
  </app-panel-placeholder>


  <app-account-list-item [item]="inbound" *ngFor="let inbound of (items$|async)">
  </app-account-list-item>

</user-list-wrapper>