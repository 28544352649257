import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { PurposeType } from 'src/app/_graphql/schema';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class AdminPurposesService extends BaseService<PurposeType>{

  selectOneFields = gql`
    fragment PurposeSelectOneFields on PurposeType  {
      id isActive name code
  }`;

  selectAllFields = gql`
    fragment PurposeSelectAllFields on PurposeType  {
      id isActive name code
  }`;

  constructor(protected apollo: Apollo) {
    super(apollo);
    this.filters = { active: null };
    this.initGql('purpose');
  }
}
