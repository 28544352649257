<div class="message" [ngClass]="{ 'message-right': item.sender.id !== auth.user.id, 'io-row':true }">
    <img class="user-image no-flex" src="/assets/images/avatar.png" *ngIf="item.sender.id == auth.user.id">
    <div class="message">
        <h5>
            {{ item.sender.firstName }} {{ item.sender.middleName }} {{ item.sender.lastName }}
            <!-- <small>{{ item.sender.email }}</small> -->
        </h5>
        <div class="bubble">
            <p *ngFor="let p of item.text.split('\n')"> {{ p }}</p>
            <button class="no-flex" mat-flat-button color="secondary"
                (click)="service.downloadFile(item.file.id, item.file.name)" *ngIf="item.file">
                <span>{{ item.file.name }}</span>
                <mat-icon>get_app</mat-icon>
            </button>
            <div class="timestamp">{{ item.created | dateAgo }}</div>
        </div>
    </div>
    <img class="user-image no-flex" src="/assets/images/avatar.png" *ngIf="item.sender.id != auth.user.id">
</div>