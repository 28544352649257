import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { ConsentGraphType } from 'src/app/_graphql/schema';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class AdminUserConsentsService extends BaseService<ConsentGraphType>{

  selectOneFields = gql`
    fragment ConsentSelectOneFields on ConsentGraphType  {
      consentType id listOfConsents userId metadata
    }
  `;

  selectAllFields = gql`
    fragment ConsentSelectAllFields on ConsentGraphType  {
      consentType id listOfConsents userId created metadata
  }`;

  constructor(protected apollo: Apollo) {
    super(apollo);
    this.mainScope = "usersManagement";
    this.initGql('consent');
  }


}
