import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { of } from 'rxjs';
import { catchError, first, map, take } from 'rxjs/operators';
import { EndUserRegisterType, EndUserType, FlagCode } from 'src/app/_graphql/schema';
import { environment } from 'src/environments/environment';
import { BaseService } from '../admin/base.service';
import { AuthenticationService } from '../authentication.service';
import { UiService } from '../ui.service';



@Injectable({
  providedIn: 'root'
})
export class EndUserService extends BaseService<EndUserType>{

  selectOneFields = gql`
    fragment EndUserSelectOneFields on EndUserType  {
      id
      country { id name isoCode3 isoCode2 currency phonePrefix }
      territory
      firstName middleName lastName
      addressLine1 addressLine2 unitNumber streetNumber streetType birthDate city zip
      email emailVerified
      phone phoneVerified phoneVerificationEnabled
      moneyReceived moneySent nationalityCountryId countryOfBirthId socialNumber socialNumberType
    }
  `;

  selectAllFields = gql`
    fragment EndUserSelectAllFields on EndUserType  {
      id
  }`;

  constructor(protected apollo: Apollo, private ui: UiService, public auth: AuthenticationService) {
    super(apollo);
    this.mainScope = "user";
    this.initGql('endUser');
    this.modifyMutation = gql`
            mutation updateProfile($data: EndUserProfileType!) {
                ${this.mainScope} {
                    __typename
                    updateProfile(data: $data) {
                        ...EndUserSelectOneFields
                    }
                }
            }
            ${this.selectOneFields}
        `;
  }

  private currentUserWQ = null;
  currentUserGQL = gql` query usr { user { id endUser { id ...EndUserSelectOneFields } } __typename } ${this.selectOneFields}`;
  public currentUser(): any {
    if (!this.currentUserWQ)
      this.currentUserWQ = this.query(
        this.currentUserGQL,
        null,
        true,
        'endUser'
      );
    return this.currentUserWQ;
  }

  private onboardingWQ = null;
  public onboardingQuery = gql` query onboarding($type: String) { user { id onboarding(type: $type) { id visible steps{ id active title description actionLabel action } } } __typename }`;
  public onboardingWQparams = null;
  public fetchOnboarding(): any {
    if (!this.onboardingWQ)
      this.onboardingWQparams = { type: environment.onboardingType };
    this.onboardingWQ = this.query(
      this.onboardingQuery,
      this.onboardingWQparams,
      true,
      'onboarding'
    );
    return this.onboardingWQ;
  }

  public updateProfile(data: any) {
    return this.mutation(this.modifyMutation, { data });
  }

  public verify(code: string, type: string = "EMAIL") {
    return this.unwrapData('verify',
      this.mutation(gql`
      mutation verify($code: String!, $type: String) {
          ${this.mainScope} {
              verify(code: $code, type: $type) {
                  ...EndUserSelectOneFields
              }
          }
      }
    ${this.selectOneFields}
    `, { code, type }));
  }

  public resendVerificationCode(type: string = 'Email') {
    return this.mutation(gql`
      mutation resendVerificationCode($type: String) {
          ${this.mainScope} {
              resendVerificationCode(type: $type) 
          }
      }
    `, { type }).subscribe({
      next: x => {
        this.ui.snack('Verification code sent');
      }
    });
  }
  public addProperties(properties: string[], flagCode: FlagCode) {
    return this.mutation(gql`
      mutation resendVerificationCode($properties: [CustomPropertyInputType], $flagCode: FlagCode) {
          ${this.mainScope} {
              addProperties(properties: $properties, flagCode: $flagCode) { 
                id key tag value 
              }
          }
      }
    `, { properties, flagCode });
  }


  public getDocuments(): any {
    return this.query(
      gql` query userDocuments {
        user {
          id
          documents {
            data {
              id
              category { id name }
              files { id name }
              created
              isPrimary
              documentExp
              documentNumber
              cardNumber
              documentIssuer
              documentState
              description
            }
            totalCount
          }
        }
        __typename
      }`,
      null,
      true,
      'documents'
    );
  }

  public getFiles(): any {
    return this.query(
      gql` query userFiles {
        user {
          id
          files {
            data {
              id
              contentType
              name
              size
              created
              document {
                id
                category {
                  id
                  name
                }
                documentNumber
              } 
              description
            }
            totalCount
          }
        }
        __typename
      }`,
      null,
      true,
      'files'
    );
  }

  public registerEndUser(user: EndUserRegisterType) {
    return this.mutation(gql`
      mutation register($user: EndUserRegisterType!) {
          ${this.mainScope} {
              __typename
              register(data: $user) { 
                ...EndUserSelectOneFields
              }
          }
      }
      ${this.selectOneFields}
    `, user);
  }

  public changePassword(data: any) {
    return this.mutation(gql`
      mutation changePassword($oldPassword: String!, $newPassword: String!) {
          ${this.mainScope} {
              __typename
              changePassword(oldPassword: $oldPassword, newPassword: $newPassword) 
          }
      }
    `, data);
  }

  public addConsent(_consentType: string, metadata: string = null) {



    let consentType = null;
    switch (_consentType) {
      case "REGISTER":
        consentType = 1;
        break;
      case "TRANSACTION":
        consentType = 2;
        break;
      case "KYC":
        consentType = 3;
        break;
      default:
        console.log('Invalid consent type.... exiting... ')
        return;
    }

    return new Promise((resolve, reject) => {
      if (
        (this.auth.hasPermission(this.auth.perm.ConsentCreate) && _consentType != "TRANSACTION") ||
        (this.auth.hasPermission(this.auth.perm.ConsentCreate) && environment.calculatorPreReceipt && _consentType == "TRANSACTION")) {
        this.unwrapData('addConsent',
          this.mutation
            (gql`mutation addConsent($consentType: Int!, $metadata: String) { ${this.mainScope} { addConsent(consentType: $consentType, metadata: $metadata) } }`,
              { consentType, metadata })).subscribe({
                next: x => {
                  resolve(x);
                }, error: e => {
                  reject(e);
                }
              });
      } else {
        resolve(null);
      }
    });
  }
}
