import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog
} from '@angular/material/dialog';
import { MessagesService } from 'src/app/_services/messages.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public messagesService: MessagesService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {}
}
