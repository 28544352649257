import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { UiService } from 'src/app/_services/ui.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EndUserService } from 'src/app/_services/user/enduser.service';
import { AdminUsersService } from 'src/app/_services/admin/admin-users.service';
import { AdminManagersService } from 'src/app/_services/admin/admin-managers.service';
import { UserTicketsService } from 'src/app/_services/user/tickets.service';
import { CustomValidators } from 'src/app/_helpers/custom.validators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  loading = false;
  disabled = false;
  authForm: UntypedFormGroup;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ui: UiService,
    private auth: AuthenticationService,
    public service: EndUserService,
    private snack: MatSnackBar,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.authForm = new UntypedFormGroup({
      username: new UntypedFormControl(null, [Validators.required, CustomValidators.noWhitespaceValidator()]),
      password: new UntypedFormControl(null, [Validators.required])
    });
  }

  login(): void {
    if (!this.authForm.valid) {
      this.ui.snack('Please fill up credentials.');
      return;
    }
    this.loading = true;
    this.disabled = true;
    var s = this.auth.adminLogin(this.authForm.value).subscribe({
      next: (data: any) => {
        s?.unsubscribe();
        this.loading = false;
        this.disabled = false;
        if (data.login && data.login.authToken) {
          this.auth.storeUser(data.login);
          const returnUrl = this.route.snapshot.queryParams['returnUrl'];
          if (returnUrl) {
            this.router.navigate([returnUrl]);
          } else {
            if (this.auth.hasPermission(this.auth.perm.TransactionsPayoutsRead)) {
              this.router.navigate(['/admin/payouts']);
            } else {
              this.router.navigate(['/admin/transactions']);
            }
          }

          // this.router.navigate([this.auth.getDefaultPath(data.loginAdmin.roles)]);
        }
      },
      error: e => {
        s?.unsubscribe();
        this.ui.snack(e.message);
        this.loading = false;
        this.disabled = false;
      }
    });

  }
}
