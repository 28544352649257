<div class="container2">
  <div class="center">
    <form [formGroup]="form" (submit)="verify()">
      <mat-card class="boxed">
        <mat-card-title *ngIf="!isSuccess">{{ 'Verification' | translate }}</mat-card-title>
        <mat-card-subtitle *ngIf="!isSuccess">{{ (( type == 'PHONE' ? 'phone_' : '') + 'verification_code_info') |
          translate
          }}</mat-card-subtitle>

        <mat-card-title *ngIf="isSuccess">
          <div class="status"><mat-icon>check_circle</mat-icon></div>
          {{ ( type == 'PHONE' ? 'The phone number has been verified!' : 'The email has been verified!') | translate }}
        </mat-card-title>
        <mat-card-subtitle *ngIf="isSuccess">{{ subtitle | translate}}</mat-card-subtitle>
        <mat-card-content *ngIf="!isSuccess">
          <app-input-code formControlName="verificationCode"></app-input-code>
        </mat-card-content>


        <div *ngIf="isSuccess == null" class="actionRow">
          <button mat-stroked-button color="primary" [disabled]="counter>0" (click)="resendCode()" type="button">
            {{ (counter > 0 ? 'Retry in '+counter+' seconds...': 'Resend code') | translate }}
          </button>
          <button mat-raised-button color="primary" [disabled]="!form.valid" *ngIf="isSuccess == null" type="submit">
            {{ 'Verify' | translate }}
          </button>
        </div>
        <div *ngIf="isSuccess == false" class="actionRow">
          <button mat-raised-button color="primary" (click)="onRestart()">
            {{ 'Try Again' | translate }}
          </button>
        </div>
        <div *ngIf="isSuccess == true" class="actionRow">
          <button mat-raised-button color="primary" routerLink="/platform" type="button">
            {{ 'Continue' | translate }}
          </button>
        </div>
      </mat-card>
    </form>
  </div>
</div>