import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlatformRoutingModule } from './platform-routing.module';
// import { WrapperComponent } from './wrapper/wrapper.component';
import { MaterialModule } from '../material.module';
// import { MainNavComponent } from './wrapper/main-nav/main-nav.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// import { DashboardComponent } from './dashboard/dashboard.component';
import { LayoutModule } from '@angular/cdk/layout';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '../components/components.module';
// import { TransactionsBoxComponent } from './transactions/transactions-box/transactions-box.component';
// import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
// import { KycComponent } from './kyc/kyc.component';
// import { OnboardingComponent } from './onboarding/onboarding.component';
// import { OnboardingComponent as OnboardingComponentCircles } from './onboarding/onboarding-circles/onboarding.component';
// import { OnboardingComponent as OnboardingComponentStepped } from './onboarding/onboarding-stepped/onboarding.component';
// import { TicketsComponent } from './tickets/tickets.component';
// import { TicketListItemComponent } from './tickets/ticket-list-item/ticket-list-item.component';
// import { WarningFlagsListComponent } from './warning-flags-list/warning-flags-list.component';
// import { TransactionsComponent } from './transactions/transactions.component';
// import { CancelTransactionComponent } from './transactions/cancel-transaction/cancel-transaction.component';
// import { RecipientsComponent } from './recipients/recipients.component';
// import { RecipientListItemComponent } from './recipients/recipient-list-item/recipient-list-item.component';
// import { RecipientItemComponent } from './recipients/recipient-item/recipient-item.component';
// import { ModalAccountAddComponent } from './accounts/modal-account-add/modal-account-add.component';
// import { AccountListItemComponent } from './accounts/account/account-list-item.component';
// import { AccountsListComponent } from './accounts/accounts.component';
// import { TicketsActiveComponent } from './tickets/tickets-active/tickets-active.component';
// import { EnduserEditComponent } from '../components/enduser-profile/enduser-edit/enduser-edit.component';
// import { EnduserProfileComponent } from '../components/enduser-profile/enduser-profile.component';
import { CalculatorModule } from '../components/calculator/calculator.module';
import { PipesModule } from '../_pipes/pipes.module';
import { ChatModule } from '../components/chat/chat.module';
import { DocumentsModule } from '../components/documents/documents.module';
import { DialogsModule } from '../components/confirmation-dialog/dialogs.module';
import { FilesModule } from '../components/files/files.module';
import { FormConfigsModule } from '../components/form-configs/form-configs.module';
import { LoginModule } from '../public/login/login.module';
import { MessagesModule } from '../components/messages/messages.module';
import { PanelModule } from '../components/panel/panel.module';
import { StatusesModule } from '../components/statuses/statuses.module';
import { TicketsModule } from '../components/tickets/tickets.module';
import { AccountsModule } from './accounts/accounts.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { KycModule } from './kyc/kyc.module';
import { OnboardingModule } from './onboarding/onboarding.module';
import { QuestionnaireModule } from './questionnaire/questionnaire.module';
import { RecipientsModule } from './recipients/recipients.module';
import { TransactionsModule } from './transactions/transactions.module';
import { TicketsModule as EndUserTicketsModule } from "./tickets/tickets.module";
import { WarningFlagsListModule } from './warning-flags-list/warning-flags-list.module';
import { EnduserProfileModule } from './enduser-profile/enduser-profile.module';
import { WrapperModule } from './wrapper/wrapper.module';
import { SingleSelectModule } from '../components/select-country/single-select.module';
import { VerifyModule } from '../public/register/verify-user/verify-user.module';






@NgModule({
    declarations: [
    // WrapperComponent,
    // WrapperComponentTmp,
    // MainNavComponent,
    // DashboardComponent,
    // QuestionnaireComponent,
    // KycComponent,
    // ModalAccountAddComponent,
    // AccountListItemComponent,
    // AccountsListComponent,
    // EnduserProfileComponent,
    // EnduserEditComponent,
    // OnboardingComponent,
    // OnboardingComponentCircles,
    // OnboardingComponentStepped,
    // RecipientsComponent,
    // RecipientListItemComponent,
    // RecipientItemComponent,
    // UserListWrapperComponent,
    // TicketsComponent,
    // TicketListItemComponent,
    // TicketsActiveComponent,
    // WarningFlagsListComponent,
    // TransactionsBoxComponent,
    // TransactionsComponent,
    // CancelTransactionComponent,
  ],
  imports: [
    CommonModule,
    PlatformRoutingModule,
    MaterialModule,
    CalculatorModule,
    FormsModule,
    PipesModule,
    ReactiveFormsModule,
    LayoutModule,
    ComponentsModule,
    TranslateModule.forChild(),
    ChatModule,
    DocumentsModule,
    EnduserProfileModule,
    DialogsModule,
    FilesModule,
    FormConfigsModule,
    LoginModule,
    PanelModule,
    StatusesModule,
    TicketsModule,
    TransactionsModule,
    AccountsModule,
    DashboardModule,
    KycModule,
    OnboardingModule,
    QuestionnaireModule,
    RecipientsModule,
    WrapperModule,
    EndUserTicketsModule,
    WarningFlagsListModule,
    SingleSelectModule,
    VerifyModule
  ],

  entryComponents: [
  ]
})
export class PlatformModule { }
