import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { NgForm, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UiService } from 'src/app/_services/ui.service';
import { ThemePalette} from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @Input() color: ThemePalette = 'primary';
  loading = false;

  contactForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private snack: MatSnackBar,
    private ui: UiService,
    private cdRef: ChangeDetectorRef) 
    {

     }

  ngOnInit() {
    this.contactForm = this.fb.group({
      name: [null, Validators.required],
      phone: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      message: [null, Validators.required]
    });
  }

  onSend(){
    this.snack.open('Your message has been successfully sent', 'Got it', {
      duration: 3000
    });
  }
}
