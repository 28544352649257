import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterValue'
})
export class FilterValuePipe implements PipeTransform {

  transform(items: any[], value: any, key: string[], out: string = 'in'): any {
    if (!items || !value || !key) {
      return items;
    }
    if (out === 'in' && typeof value !== 'object') {
      if ([...items?.filter(item =>item[key[0]]?.toLowerCase()?.includes(value?.toLowerCase()))].length)
        return ([...items?.filter(item =>item[key[0]]?.toLowerCase()?.includes(value?.toLowerCase()))]);
      else 
       return ([...items?.filter(item =>item[key[1]]?.toLowerCase()?.includes(value?.toLowerCase()))]);

    }
  }
}