import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Subscribable, Subscription } from 'rxjs';
import { DrawerService } from '../wrapper/drawer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminTransactionsService } from 'src/app/_services/admin/admin-transactions.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, first } from 'rxjs/operators';
import { TransactionsComponentConfig } from 'src/app/_modules/transactions.module';
import { AdminUserType, CountryType, PaymentNetworkType, Recipient, TransactionStatusType } from 'src/app/_graphql/schema';
import { PublicService } from 'src/app/_services/public.service';
import { AdminRecipientsService } from 'src/app/_services/admin/admin-recipients.service';
import { SystemService } from 'src/app/_services/system.service';
import { AdminPayoutsService } from 'src/app/_services/admin/admin-payouts.service';
import { AdminManagersService } from 'src/app/_services/admin/admin-managers.service';

@Component({
  selector: 'app-admin-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class AdminTransactionsComponent implements OnInit, AfterViewInit, OnDestroy {
  items$: Subscribable<any>;
  countriesSender$: Subscribable<CountryType>;
  countriesRecipient$: Subscribable<CountryType>;
  recipients$: Subscribable<Recipient[]>;
  paymentNetworks$: Subscribable<PaymentNetworkType>;
  payoutAgents$: Subscribable<AdminUserType[]>;
  transactionStatuses$: Subscribable<TransactionStatusType>;
  outboundStatuses$: Subscribable<TransactionStatusType>;
  maxDate = new Date();
  @Input() componentConfig: TransactionsComponentConfig;

  uid: string;
  customKey: string;
  displayPayoutFilters: boolean = false;
  allPaymentNetworks: any[];
  form: UntypedFormGroup = this.fb.group({
    query: [null],
    dateFrom: [null],
    dateTo: [null],
    userId: [this.route.snapshot.paramMap.get('userId')],
    statusIds: [null],
    countryFromId: [null],
    countryToId: [null],
    paymentNetworkId: [null],
    outboundStatusIds: [null],
    payoutAgentId: [null],
    onHold: [false],
    recipientId: [this.route.snapshot.paramMap.get('recipientId')],
  });
  form$: Subscription;

  constructor(
    public service: AdminTransactionsService,
    public systemService: SystemService,
    public publicService: PublicService,
    public adminPayoutsService: AdminPayoutsService,

    private adminRecipientsService: AdminRecipientsService,

    private dialog: MatDialog,
    public auth: AuthenticationService,
    public adminManagersService: AdminManagersService,
    private drawerService: DrawerService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,

  ) {
    if (route.snapshot.data?.config) {
      this.componentConfig = route.snapshot.data?.config;
    }
    this.uid = this.form.get('userId').value;
    this.transactionStatuses$ = this.systemService.transactionStatuses();
    this.outboundStatuses$ = this.adminPayoutsService.outboundStatuses();
    this.paymentNetworks$ = this.systemService.paymentNetworks();
    this.countriesSender$ = this.publicService.countries(true, null, null)
    this.countriesRecipient$ = this.publicService.countries(null, true, null)
    this.customKey = this.uid ? 'user' + this.uid : null;
    if (auth.hasPermission(auth.perm.ManagersRead) && auth.hasPermission(auth.perm.TransactionOutboundStatusManage)) {
      this.payoutAgents$ = this.adminManagersService.dropdownAll({ type: 'PayoutAgent' }, true, 'payoutAgents');
    }



  }
  ngOnDestroy(): void {
    this.form$.unsubscribe();
    this.form.reset();
  }

  ngAfterViewInit() {
    var filters = this.service.getPageRequestAllFilters(this.customKey);
    // this.form.patchValue({ ...filters.pageRequest?.filters, recipientId: this.route.snapshot.paramMap.get('recipientId') })
  }

  ngOnInit() {
    this.drawerService.sendState.next(false);
    this.items$ = this.service.all(this.form.value, false, this.customKey);
    this.recipients$ = this.adminRecipientsService.all({ userId: this.uid }, false, 'recipients4' + this.uid);
    this.form$ = this.form.valueChanges.pipe(
      debounceTime(600),
      distinctUntilChanged()
    ).subscribe(() => {
      this.getData()
    });
    let unsubPaymentNetworks = this.systemService.paymentNetworks().subscribe({
      next: res => {
        this.allPaymentNetworks = [...res];
        unsubPaymentNetworks?.unsubscribe();
      }
    })
  }
  getData() {
    this.service.queryAllParams.skip = 0;
    this.service.pageIndex = 0;
    this.service.fetchMoreData(this.form.value, this.customKey)
  }

  getReport() {
    var s = this.service
      .report(this.form.value)
      .subscribe({
        next: reportId => {
          this.publicService.downloadFile(reportId, 'report_file.xlsx');
          s?.unsubscribe();
        }
      });
  }

  editItem(transaction) {
    this.router.navigate(['/admin/users/', transaction.userId, 'transactions', transaction.id]);
    this.drawerService.toggle();
    this.drawerService.displayState(true);
  }

  changeDisplayedFilters(item: any) {
    if (this.allPaymentNetworks.find(el => el.id === item.value)?.extendedName === 'MANUAL-Payout agent') {
      this.displayPayoutFilters = true;
    } else {
      this.displayPayoutFilters = false;
      this.form.controls['outboundStatusIds'].reset();
      this.form.controls['payoutAgentId'].reset();
    }
  }
}