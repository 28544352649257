import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UiService } from 'src/app/_services/ui.service';
import { TransactionsComponentConfig } from '../../_modules/transactions.module';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscribable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { Recipient } from 'src/app/_graphql/schema';
import { UserTransactionsService } from 'src/app/_services/user/transactions.service';
import { SystemService } from 'src/app/_services/system.service';
import { UserRecipientsService } from 'src/app/_services/user/recipients.service';


@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit, OnDestroy {
  items$: Subscribable<any>;
  recipients$: Subscribable<Recipient[]>;
  maxDate = new Date();
  @Input() componentConfig: TransactionsComponentConfig;


  form: UntypedFormGroup = this.fb.group({
    query: [null],
    dateFrom: [null],
    dateTo: [null],
    userId: [this.route.snapshot.paramMap.get('userId')],
    statusIds: [null],
    countryFromId: [null],
    countryToId: [null],
    onHold: [false],
    recipientId: [this.route.snapshot.paramMap.get('recipientId')],
  });
  form$: Subscription;

  constructor(
    public service: UserTransactionsService,
    public systemService: SystemService,
    public modalLauncherService: ModalLauncherService,
    private recipientsService: UserRecipientsService,
    private ui: UiService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
  ) {
    if (route.snapshot.data?.config) {
      this.componentConfig = route.snapshot.data?.config;
    }

  }
  ngOnInit(): void {
    this.items$ = this.service.all(this.form.value, false);
    this.recipients$ = this.recipientsService.dropdownAll();
    this.form$ = this.form.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(() => {
      this.getData();
    });
  }
  // ngAfterViewInit() {
  //   var filters = this.service.getPageRequestAllFilters();
  //   this.form.patchValue({ ...filters.pageRequest?.filters});
  // }
  getData() {
    this.service.queryAllParams.skip = 0;
    this.service.pageIndex = 0;
    this.service.fetchMoreData(this.form.value);
  }

  ngOnDestroy() {
    this.form$?.unsubscribe();
  }
}
