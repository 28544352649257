import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { UserRecipientsService } from 'src/app/_services/user/recipients.service';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscribable, Unsubscribable } from 'rxjs';
import { Recipient } from 'src/app/_graphql/schema';

@Component({
  selector: 'app-recipients',
  templateUrl: './recipients.component.html',
  styleUrls: ['./recipients.component.scss']
})
export class RecipientsComponent implements OnInit, OnDestroy {
  items$: Subscribable<Recipient[]>;
  query: FormControl = new FormControl(null);
  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
  search$: Unsubscribable;


  constructor(
    public service: UserRecipientsService,
    public modalLauncherService: ModalLauncherService,
  ) {
  }
  
  ngOnInit() {
    this.items$ = this.service.all(null, false);
    this.search$ = this.query.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(() => {
      this.getData();
    });
  }
  // ngAfterViewInit() {
  //   var filters = this.service.getPageRequestAllFilters();
  //   this.query.patchValue(filters.pageRequest?.filters?.query);
  // }
  getData() {
    this.service.queryAllParams.skip = 0;
    this.service.pageIndex = 0;
    this.service.fetchMoreData({query: this.query.value});
  }
  sendMoney(recipient) {
    this.modalLauncherService.sendMoney(recipient);
  }
  ngOnDestroy(): void {
    this.search$?.unsubscribe();
  }
}
