import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscribable } from 'rxjs';
import { AdminTransactionsHistoryService } from 'src/app/_services/admin/admin-transactions-history.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';

import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { PublicService } from 'src/app/_services/public.service';
import { UiService } from 'src/app/_services/ui.service';
import { UserTransactionsService } from 'src/app/_services/user/transactions.service';
import { CancelTransactionComponent } from '../../../platform/transactions/cancel-transaction/cancel-transaction.component';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent {
  @Input() item: any;
  error = false;
  receipt: any;
  showHistory = false;
  $history: Subscribable<any>;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public auth: AuthenticationService,
    public modalLauncherService: ModalLauncherService,
    public publicService: PublicService,
    public userTransactionsService: UserTransactionsService,
    private adminTransactionsHistoryService: AdminTransactionsHistoryService,
    private ui: UiService,
  ) {

  }

  resendInstructions(transaction) {
    if (!transaction) {
      return;
    }
    if (!transaction.continue.enabled) {
      this.ui.snack(
        'Your transaction is in pending status, please fix the issues first.'
      );
      return;
    }
    this.userTransactionsService
      .resendEmail(transaction.id, 'PAYMENT_INSTRUCTIONS')
      .subscribe(res => {
        this.ui.snack(
          `Payment instructions sent, please check your inbox.`
        );
      });
  }

  cancelTransactionDialog(transaction: any) {
    const dialogRef = this.dialog.open(CancelTransactionComponent, {
      height: 'auto',
      width: '600px',
      data: transaction,
      disableClose: true,
      maxWidth: '95%'
    });
  }
  onShowHistory(event: MouseEvent, transaction: any) {
    event.preventDefault();
    event.stopPropagation();


    if (!this.auth.isAdmin()) {
      let _s = this.userTransactionsService
        .getTransactionReceiptData(transaction.id).subscribe({
          next: x => {
            this.receipt = x?.transaction?.receipt;
            this.$history = this.userTransactionsService
              .getHistory(transaction.id);
            this.showHistory = !this.showHistory;
            _s.unsubscribe();
          }
        });
    } else {
      this.adminTransactionsHistoryService.queryAllParams.skip = 0;
      this.adminTransactionsHistoryService.queryAllParams.take = null
      this.$history = this.adminTransactionsHistoryService.all({ transactionId: transaction.id }, false, transaction.id);
      this.showHistory = !this.showHistory;
    }
  }
  transactionDetails(item: any) {
    if (this.auth.isAdmin()) {
      var userId = this.route.snapshot.paramMap.get('userId');
      this.router.navigate(['/', 'admin', 'users', item?.sender?.id ? item?.sender?.id : item?.userId, 'transactions', item?.id]);
      // this.selected.emit(transaction);
    }
  }
}
