import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlagListComponent } from './flag-list/flag-list.component';
import { FlagRaiseComponent } from './flag-raise/flag-raise.component';
import { FlagResolveComponent } from './flag-resolve/flag-resolve.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { JsonRendererComponent } from './json-renderer/json-renderer.component';




@NgModule({
  declarations: [
    FlagListComponent,
    FlagRaiseComponent,
    FlagResolveComponent,
    JsonRendererComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    TranslateModule.forChild(),
    RouterModule

  ],
  exports: [
    FlagListComponent,
    FlagRaiseComponent,
    FlagResolveComponent,
    JsonRendererComponent
  ]
})
export class FlagsModule { }
