<ng-container [formGroup]="form">
  <mat-form-field [appearance]="appearance">
    <mat-label *ngIf="appearance == 'outline'">{{ placeholder | translate }}</mat-label>
    <mat-icon matSuffix class="arrow">arrow_drop_down</mat-icon>
    <span matSuffix>
      <ng-content select="[suffix]"></ng-content>
    </span>
    <input type="text" matInput [formControl]="displayValue" 
    (focus)="changeInputVisibility(); setMatSelectWidth()" *ngIf="!searchInputVisible">
    <input type="text" #searchInput matInput formControlName="selectControl"  
    *ngIf="searchInputVisible" [matAutocomplete]="autoGroup">
    <mat-autocomplete #autoGroup="matAutocomplete" [displayWith]="displayFn.bind(this)" 
    (closed)="closeAutoComplete()" [ngStyle]="{ '--option-box-width': (maxWidth) + 'px'}">
      <mat-option *ngFor="let item of selectedItems | filterValue: form.value.selectControl : ([optionValue]), let i = index" 
      [value]="item" class="autoCompleteOption"> 
       <div (click)="optionClicked($event, item)" style="margin-left: 16px;">
        <mat-checkbox color="primary" [checked]="selectedItems.indexOf(item)>=0" 
          (change)="selectionChangeEmit(item)" (click)="$event.stopPropagation()"></mat-checkbox>
        <a class="selection-search-label">{{ item[optionValue] | translate }}</a>
      </div>
    </mat-option>
    <mat-option *ngFor="let element of list | filterValue: form.value.selectControl: ([optionValue])  
    | filterOn: optionValue: selectedItemsControl.value :'exclude', let i = index" 
    [value]="element" class="autoCompleteOption">
    <div (click)="optionClicked($event, element)" style="margin-left: 16px;">
      <mat-checkbox color="primary" [checked]="selectedItems.indexOf(element)>=0" 
        (change)="selectionChangeEmit(element)" (click)="$event.stopPropagation()"></mat-checkbox>
      <a class="selection-search-label" #valueOption>{{ element[optionValue] | translate }}</a>
    </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
</ng-container>
