import { Component, OnDestroy } from '@angular/core';
import { Subscribable } from 'rxjs';
import { UiService } from 'src/app/_services/ui.service';
import { AdminTicketsService } from 'src/app/_services/admin/admin-tickets.service';
import { TicketType } from 'src/app/_graphql/schema';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { MessagesService } from 'src/app/_services/messages.service';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { AdminFlagsService } from 'src/app/_services/admin/admin-flags.service';

@Component({
  selector: 'app-admin-ticket-item',
  templateUrl: './admin-ticket-item.component.html',
  styleUrls: ['./admin-ticket-item.component.scss']
})
export class AdminTicketItemComponent implements OnDestroy {
  itemId: string;
  item$: Subscribable<TicketType>
  constructor(
    public service: AdminTicketsService,
    public adminFlagsService: AdminFlagsService,
    private mls: ModalLauncherService,
    public messagesService: MessagesService,
    public auth: AuthenticationService,
    private route: ActivatedRoute,
    private ui: UiService,
  ) {
    this.itemId = this.route.snapshot.paramMap.get('ticketId');
    this.item$ = this.service.one(this.itemId);
  }
  ngOnDestroy(): void {
    this.service.refetchData()
    if (this.route.snapshot.paramMap.get('userId')) {
      this.service.refetchData('tickets4' + this.route.snapshot.paramMap.get('userId'))
      this.adminFlagsService.refetchData('flags4' + this.route.snapshot.paramMap.get('userId'))
    }
  }
  isJSON(txt: any) {
    try {
      let a = JSON.parse(txt);
      if (typeof a !== 'object')
        return false;

      return a == null ? false : a;
    } catch (e) {
    }
    return false;
  }
  closeTicket() {
    this.mls.closeTicket().afterClosed().subscribe(result => {
      if (result) {
        this.service
          .closeTicket(this.itemId)
          .subscribe({
            next: x => {
              this.ui.snack('Ticket resolved.');
              this.ngOnDestroy();
            }
          });
      }
    });

  }
  onFileUploaded() {
    this.service.refetchOneData(this.itemId)
  }
}
