<div class="top-separator-50 io-mx-2" *ngIf="(transactions$|async)?.length">
  <div class="io-row align-items-center io-mt-3">
    <h3>
      {{ "latest transactions" | translate | uppercase }}
    </h3>
    <div class="no-flex">
      <button mat-raised-button class="send-money" color="primary" 
      (click)="modalLauncherService.sendMoney()">
        {{ "Send Money" | translate }}
      </button>
    </div>
  </div>
  <div class="io-carousel">
    <button class="io-carousel-btn btn-left" mat-icon-button (click)="scrollLeft()">
      <mat-icon>arrow_back_ios</mat-icon>
    </button>
    <button class="io-carousel-btn btn-right" mat-icon-button (click)="scrollRight()">
      <mat-icon>arrow_forward_ios</mat-icon>
    </button>
    <div class="io-carousel-container" #widgetsContent>
      <div class="box mobile-view" *ngIf="user$|async as user">
        <mat-card class="transaction-box summary-box">
          <mat-card-header>
            <mat-card-title style="text-align: end">{{
              "summary total" | translate | uppercase
              }}</mat-card-title>
          </mat-card-header>
          <mat-card-content class="summary-content">
            <h3>
              {{ user?.country?.currency }}
              {{ user?.moneySent || 0 | number: "1.2-2" }}
            </h3>
            <p>{{ "Amount Sent" | translate }}</p>
            <h3>
              {{ user?.country?.currency }}
              {{ user?.moneyReceived || 0 | number: "1.2-2" }}
            </h3>
            <p>{{ "Amount Delivered" | translate }}</p>
          </mat-card-content>
          <mat-card-actions style="padding: 0px">
            <a routerLink="/platform/transactions">{{
              "See all transaction" | translate
              }}</a>
          </mat-card-actions>
        </mat-card>
      </div>

      <div class="box" *ngFor="let transaction of transactions$|async | slice: 0:3">
        <mat-card class="transaction-box" [ngClass]="{
          warning: transaction.transactionStatusInfo.isWarning || transaction.transactionStatusInfo.isInfo
        }">
          <mat-card-header>
            <mat-card-title>
              <img class="countries" [src]="publicService.countryFlagImage(transaction.toIsoCode3)" />
              {{ transaction.recipientFirstName }}
              {{ transaction.recipientLastName }}
            </mat-card-title>
            <p class="status-name" matTooltip="{{ ('transaction_'+transaction?.transactionStatusInfo?.name?.replace(' ', '_'
          )?.toUpperCase()+'_info') | translate }}">
              {{ transaction.transactionStatusInfo.name }}
            </p>
          </mat-card-header>
          <mat-card-content class="output">
            <div class="recipient-info">
              <th class="recipient-phone"></th>
              <td class="recipient-amount">
                {{ transaction.currency }}
                {{ transaction.paidAmount | number: "1.2-2" }}
              </td>
            </div>
            <table class="index-table">
              <tbody>
                <tr>
                  <th>{{ "ID" | translate }}</th>
                  <td>{{ transaction.transactionId }}</td>
                </tr>
                <tr>
                  <th>{{ "Date" | translate }}</th>
                  <td>{{ transaction.created | date }}</td>
                </tr>
                <tr>
                  <th>{{ "Sent" | translate }}</th>
                  <td>
                    {{ transaction.currency }}
                    {{ transaction.amount | number: "1.2-2" }}
                  </td>
                </tr>
                <tr>
                  <th>{{ "Fee" | translate }}</th>
                  <td>
                    {{ transaction.currency }}
                    {{
                    transaction.fixedFeePaid + transaction.variableFeePaid
                    | number: "1.2-2"
                    }}
                  </td>
                </tr>
                <tr>
                  <th>{{ "Received" | translate }}</th>
                  <td>
                    {{ transaction.receivingCurrency }}
                    {{ transaction.receivingAmount | number: "1.2-2" }}
                  </td>
                </tr>
              </tbody>
            </table>

            <button mat-raised-button color="primary" class="continue-button"
              (click)="modalLauncherService.pay(transaction)" *ngIf="transaction.continue.enabled">
              {{ "Continue" | translate }}
            </button>
          </mat-card-content>
          <mat-card-actions *ngIf="!transaction.continue.enabled">
            <span style="padding-left: 14px; color:white">
              <mat-icon *ngIf="transaction.transactionStatusInfo.note">error</mat-icon>
            </span>
            <span *ngIf="
              !transaction.transactionStatusInfo.note &&
              !transaction.continue.enabled
            ">
              {{ transaction.currency }} 1 = {{ transaction.receivingCurrency }}
              {{ transaction.quote | number: "1.6-6" }}
            </span>
            <span *ngIf="transaction.transactionStatusInfo.note">
              {{ transaction.transactionStatusInfo.note | translate }}
            </span>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</div>