import { Component, Inject, OnInit } from '@angular/core';
import { UiService } from '../../../_services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AdminTicketsService } from 'src/app/_services/admin/admin-tickets.service';
import { AdminFlagsService } from 'src/app/_services/admin/admin-flags.service';
import { CustomValidators } from 'src/app/_helpers/custom.validators';

@Component({
    selector: 'app-ticket-raise',
    templateUrl: './ticket-raise.component.html',
    styleUrls: ['./ticket-raise.component.scss']
})
export class TicketRaiseComponent implements OnInit {

    form = this.fb.group({
        raisedFlagId: [this.data && this.data.raisedFlag ? this.data.raisedFlag.id : null],
        description: [this.data && this.data.raisedFlag ? this.data.raisedFlag.description : null, [Validators.required, CustomValidators.noWhitespaceValidator()]],
        internalNote: [null],
        subject: [this.data && this.data.raisedFlag && this.data.raisedFlag.flag ? this.data.raisedFlag.flag.name : null, [Validators.required, CustomValidators.noWhitespaceValidator()]]
    });
    constructor(private ui: UiService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public fb: UntypedFormBuilder,
        private service: AdminTicketsService,
        private adminFlagsService: AdminFlagsService,
        private dialogRef: MatDialogRef<TicketRaiseComponent>
    ) { }

    ngOnInit() {
    }

    createTicket() {
        this.adminFlagsService.refetchAdditionalQueries.forEach(element => {
            this.service.addRefetchQuery(element.query, element.variables)
        });
        var s = this.service.create(this.form.value).subscribe(
            res => {
                s.unsubscribe();
                this.ui.snack('Ticket created.');
                this.dialogRef.close();
            },
            err => {
                this.ui.snack('Error while creating ticket.');
            }
        );
    }
}
