<div mat-dialog-title>
  {{ data?.key ? 'Edit Translation' : 'Add Translation'}}
  <!-- <code *ngIf="data">{{ data.key }}</code> -->
</div>
<mat-dialog-content>
  <form [formGroup]="form">
    <div class="io-row">
      <mat-form-field appearance="outline">
        <mat-label>Translation Key</mat-label>
        <input matInput formControlName="key">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Tags</mat-label>
        <mat-select multiple formControlName="tags">
          <mat-option *ngFor="let itm of translationTags$|async" [value]="itm">{{ itm }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngFor="let item of (form.get('langs') )?.controls; index as i">
      <ng-container [formGroup]="item">
        <div class="io-row align-items-center">
          <div class="no-flex">
            <app-country-flag matListIcon [item]="{
              isoCode3: item.get('_isoCode3').value
            }"></app-country-flag>
          </div>
          <mat-form-field appearance="outline">
            <mat-label>{{item.get('_countryName').value}} ({{item.get('_isoCode3').value}})</mat-label>
            <textarea matInput formControlName="value" rows="5"></textarea>
          </mat-form-field>
        </div>
      </ng-container>
    </div>


  </form>
  <!-- <pre>{{languages|json}}</pre> -->
  <!-- <pre>{{form.value|json}}</pre> -->
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- <button mat-button color="warn" [disabled]="!form?.valid" (click)="onDelete()">Delete</button> -->
  <span style="flex:1"></span>
  <button mat-button color="primary" mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" [disabled]="!form?.valid || !hasEnteredData()"
    (click)="onSubmit()">Save</button>
</mat-dialog-actions>