import { Component, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Subscribable, Subscription } from 'rxjs';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { AdminUsersService } from 'src/app/_services/admin/admin-users.service';
import { FileType } from 'src/app/_graphql/schema';
import { AdminFilesService } from 'src/app/_services/admin/admin-files.service';
import { AdminDocumentsService } from 'src/app/_services/admin/admin-documents.service';
import { TransactionsComponentConfig } from 'src/app/_modules/transactions.module';
import { PublicService } from 'src/app/_services/public.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnDestroy, AfterViewInit {
  documents$: Subscribable<any[]>;
  files$: Subscribable<FileType[]>;

  questionnaire$: Subscribable<any>;
  questionnaireLabels$: Subscribable<any>;


  documents: any[] = [];
  updateUserDef$: Subscription;
  userId: string;
  transactionId: string;

  user: any;
  user$: any;

  country: any;
  ausState = true;
  userDeleted = false;




  selectedTransactions = [];
  selectedTab = 0;
  transactionsConfig: TransactionsComponentConfig = {
    dateTo: true,
    dateFrom: true,
    query: true,
    searchButton: false,
  };
  constructor(
    public ms: ModalLauncherService,
    private adminUsersService: AdminUsersService,
    public adminDocumentsService: AdminDocumentsService,
    public adminFilesService: AdminFilesService,
    private route: ActivatedRoute,
    private location: Location,
    public publicService: PublicService,
    public auth: AuthenticationService
  ) {
    this.userId = this.route.snapshot.paramMap.get('userId');

    this.adminFilesService.queryAllParams.take = null;
    this.adminDocumentsService.queryAllParams.take = null;


    this.files$ = this.adminFilesService.all({ userId: this.userId }, false, 'files4' + this.userId);
    this.documents$ = this.adminDocumentsService.all({ userId: this.userId }, false, 'docs4' + this.userId);


    this.user$ = this.adminUsersService.one(this.userId)
      .subscribe({
        next: user => {
          if (user) {
            this.user = user;
            this.country = this.user?.country;
            if (this.country?.isoCode3 === 'AUS') {
              this.ausState = false;
            } 
          }
        },
        error: () => {
          this.userDeleted = true;
        }
      });

    var el = document.querySelector('mat-drawer-content');
    if (el) {
      el.scrollTop = 0;
    }

    this.questionnaire$ = this.adminUsersService.getCustomProperties(this.userId, "QUESTIONNAIRE");
    this.questionnaireLabels$ = this.publicService.getFieldsConfig(this.user?.country?.isoCode2, 'questionnaire');

  }
  ngAfterViewInit(): void {
    this.refetchData();
  }
  refetchData() {
    this.adminFilesService.fetchMoreData({ userId: this.userId });
    this.adminDocumentsService.fetchMoreData({ userId: this.userId });
  }
  getBack() {
    this.location.back();
  }

  ngOnDestroy() {
    this.user$?.unsubscribe();
    this.updateUserDef$?.unsubscribe();
    this.adminFilesService.resetRefetchQuery()
    this.adminDocumentsService.resetRefetchQuery()
  }
}
