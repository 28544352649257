import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../../_services/ui.service';
import { UserFlagsService } from 'src/app/_services/user/flags.service';
import { UserTransactionsService } from 'src/app/_services/user/transactions.service';
import { EndUserService } from 'src/app/_services/user/enduser.service';
import { FlagCode } from 'src/app/_graphql/schema';



@Component({
    selector: 'app-questionnaire',
    templateUrl: './questionnaire.component.html',
    styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {
    loading = false;
    constructor(
        private router: Router,
        private userFlagsService: UserFlagsService,
        public userTransactionsService: UserTransactionsService,
        private service: EndUserService,
        private route: ActivatedRoute,
        public ui: UiService) { }

    ngOnInit() {

    }

    onSubmitData(data) {
        let data2 = [];
        for (const key in data.questionnaire) {
            try {
                var json = JSON.parse(data.questionnaire[key]);
                for (const key2 in json) {
                    if (json[key2])
                        data2.push({
                            "key": key2,
                            "value": json[key2].toString(),
                            "tag": "QUESTIONNAIRE"
                        })
                }
            } catch (e) {
                data2.push({
                    "key": key,
                    "value": data.questionnaire[key].toString(),
                    "tag": "QUESTIONNAIRE"
                });
            }


        }

        this.loading = true;
        this.service.addProperties(data2, FlagCode.QuestionnaireMissing).subscribe(
            {
                next: res => {
                    this.loading = false;
                    this.ui.snack('Questionnaire saved');
                    this.userTransactionsService.refetchData();
                    this.userFlagsService.refetchData();
                    window.history.back();
                },
                error: x => {
                    this.loading = false;
                }
            });
    }
}
