import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PurposesItemComponent } from './purposes-item/purposes-item.component';
import { AdminPurposesService } from 'src/app/_services/admin/admin-purposes.service';
import { Subscribable } from 'rxjs';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-purposes',
  templateUrl: './purposes.component.html',
  styleUrls: ['./purposes.component.scss']
})
export class PurposesComponent {
  items$: Subscribable<any>;
  dialogRef: any;

  constructor(
    public service: AdminPurposesService,
    private dialog: MatDialog,
    public auth: AuthenticationService,
  ) {
    this.items$ = this.service.all();
  }

  public newItem() {
    this.openDialog(PurposesItemComponent, false);
  }
  public editItem(data: any) {
    this.openDialog(PurposesItemComponent, data);
  }
  openDialog(component: any, param = {}) {
    this.dialog.open(component, {
      height: 'auto',
      width: '360px',
      disableClose: true,
      data: param
    });
  }
}
