import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UiService } from 'src/app/_services/ui.service';
import { TranslationsService } from 'src/app/_services/translations.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'tab-top-wrapper',
  templateUrl: './tab-top-wrapper.component.html',
  styleUrls: ['./tab-top-wrapper.component.scss']
})
// dummu component to disable tabs for AUS
export class TabTopWrapperComponent {


  constructor() { }
}
