import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, forwardRef, Optional, Self, ViewChild, ElementRef, OnDestroy, ViewChildren, AfterViewInit, QueryList, Renderer2 } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, Unsubscribable } from 'rxjs';
import { CustomValidators } from 'src/app/_helpers/custom.validators';
import { ArrayFormatterPipe } from 'src/app/_pipes/array-formatter.pipe';
// import { ArrayFormatterPipe } from 'src/app/_pipes/array-formatter.pipe';
import { PublicService } from 'src/app/_services/public.service';
export interface FormFieldValue {
  selectControl: string;
}
@Component({
  selector: 'app-multiselect-search',
  templateUrl: './multiselect-search.component.html',
  styleUrls: ['./multiselect-search.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: MultiselectSearchComponent,
    },
    ArrayFormatterPipe
  ]
})
export class MultiselectSearchComponent implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit, MatFormFieldControl<FormFieldValue> {
  selectedItemsControl = new UntypedFormControl ([null], []);
  form:UntypedFormGroup = this.fb.group({
    selectControl: new UntypedFormControl(null, [CustomValidators.checkIfCountryIsChoosen()]),

  })

  @ViewChild(MatInput, { read: ElementRef, static: true }) input: ElementRef;
  @ViewChildren('valueOption') valueOption: ElementRef;
  maxWidth = 0;


  @Input()
  set value(value: any) {
    this.form.patchValue(value);
    this.stateChanges.next();
  }
  get value() {
    return this.form.value;
  }
  @Input()
  set placeholder(value: string) {
    this._placeholder = value
    this.stateChanges.next();
  }
  get placeholder() {
    return this._placeholder;
  }
  @Input()
  set list(value: any[]) {
    this._list = value;
    value?.forEach((item) => {
      this.itemsFromParent?.forEach(el => {
        if (el === item?.id) {
          this.selectionChangeEmit(item);
        }
      })
    })
    }
  get list() {
    return this._list;
  }
  _list: any[];
  itemsFromParent:any[]

  @Input() disabled = false;
  @Input() appearance = 'outline';
  @Input() required: boolean = false;
  @Input() optionValue: string = 'name';



  constructor(
    public publicService: PublicService,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    @Optional() @Self() public ngControl: NgControl,
    private fb: UntypedFormBuilder,
    private focusMonitor: FocusMonitor,
    private arrayFormatterPipe: ArrayFormatterPipe,
    private renderer: Renderer2

  ) {
    if (this.ngControl != null) {
      ngControl.valueAccessor = this;
    }

   }

  stateChanges = new Subject<void>();
  id: string;
  private _placeholder: string;
  focused: boolean;
  get empty(): boolean {
    return !this.value.selectControl;
  }
  shouldLabelFloat: boolean;
  get errorState() {
    return this.ngControl.errors !== null && !!this.ngControl.touched ;
  } 
  selectedItems: any[] = [];
  searchInputVisible: boolean = false;
  @ViewChild('searchInput') searchInput: ElementRef;
  displayValue = new UntypedFormControl (null);
  unsubList$:Unsubscribable;
  // items:any[];





  setDescribedByIds(ids: string[]): void {
    // throw new Error('Method not implemented.');
  }
  onContainerClick(event: MouseEvent): void {
    // throw new Error('Method not implemented.');
  }

  ngOnInit() {
    if (this.ngControl?.control?.hasValidator(Validators.required)) {
      this.displayValue.setValidators([Validators.required]);
      this.form.controls['selectControl'].setValidators([Validators.required, CustomValidators.checkIfCountryIsChoosen()]);
    }
  }

  ngAfterViewInit(): void {
    // this.setMatSelectWidth();
  }

  selectionChangeEmit(item) {
    //checkbox checked
    if (this.selectedItems.indexOf(item) === -1) {
      this.selectedItems = [...this.selectedItems, item];
    } 
    //checkbox unchecked
    else {
      this.selectedItems.filter(el => {
        this.selectedItems = this.selectedItems.filter(el => el.id !== item?.id);        
      })
    }
    this.selectedItemsControl.setValue(this.selectedItems.map(el=>el[this.optionValue]));
    this.clearInput();  
    this.displayValue.setValue(this.arrayFormatterPipe.transform(this.selectedItemsControl.value));
    this.propagateChange(this.selectedItems.map(el=>el.id));
    this.searchInput?.nativeElement?.focus();
  }
  public writeValue(data: any) {
    this.itemsFromParent = data;
    if (this.list) {
      this.list?.forEach((item) => {
        data?.forEach(el => {
          if (el === item?.id) {
            this.selectionChangeEmit(item);
      }})})
    }
  }

  registerOnTouched(onTouched: any) {
  }

   public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  setDisabledState(disabled: boolean) {
  }
  private propagateChange = (_: any) => {};
  focusInput() {
    this.focusMonitor.monitor(this.input).subscribe((focused) => {
      this.focused = !!focused;
      this.stateChanges.next();
     })
  }
  displayFn(value?: any) {
    return value ? this.list?.find(_ => _.id === value?.id)?.optionValue : undefined;
  }
  ngOnDestroy(): void {
    this.unsubList$?.unsubscribe();
  }
  clearInput() {
    this.form.patchValue({selectControl: null});
  }
  optionClicked(event, element) {
    event.stopPropagation();
    this.selectionChangeEmit(element);
  }
  changeInputVisibility() {
    this.searchInputVisible = true;
    setTimeout(()=>{                    
      this.searchInput?.nativeElement?.focus();
      this.clearInput()       
  }, 10);

  }
  closeAutoComplete() {
    this.searchInputVisible = false;
    this.displayValue.markAsTouched();
  }

  setMatSelectWidth() {
    const optionElements = this.valueOption['_results'];
    setTimeout(()=>{                     
      optionElements.forEach((option: any) => {
        const optionWidth = option.nativeElement.offsetWidth;
        this.maxWidth = Math.max(this.maxWidth, optionWidth);
      });
    }, 10);
  }
}
