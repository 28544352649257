import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscribable, Subscription, Unsubscribable } from 'rxjs';
import { UiService } from 'src/app/_services/ui.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { AdminTransactionsHistoryService } from 'src/app/_services/admin/admin-transactions-history.service';
import { AdminUsersService } from 'src/app/_services/admin/admin-users.service';
import { PublicService } from 'src/app/_services/public.service';
import { CountryType } from 'src/app/_graphql/schema';
import { RiskAssessmentDialogComponent } from './risk-assessment-dialog/risk-assessment-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CheckResultsDialogComponent } from './check-results-dialog/check-results-dialog.component';

@Component({
  selector: 'app-risk-assessment',
  templateUrl: './risk-assessment.component.html',
  styleUrls: ['./risk-assessment.component.scss']
})
export class RiskAssessmentComponent implements OnInit, OnDestroy {
  $items: Unsubscribable;
  item: any;

  countries$: Subscribable<CountryType[]>;

  data: any;
  userId: string;
  form$: Subscription;
  form: UntypedFormGroup;

  constructor(
    public service: AdminUsersService,
    private dialog: MatDialog,
    private publicService: PublicService,
    public auth: AuthenticationService,
    private route: ActivatedRoute,
    private ui: UiService
  ) {
    this.countries$ = this.publicService.countries(null, null, null);
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.$items = this.service.getRiskAssessmentData(this.userId).subscribe({
      next: (x: any) => {
        if (x)
          this.setLocalRating(x);

      }
    })
  }
  setLocalRating(x: any) {
    this.item = {
      riskRating: x?.riskRating,
      riskAssessmentData: JSON.parse(x.riskAssessmentData)
    }
  }
  setCheckResults() {
    const dialogRef = this.dialog.open(CheckResultsDialogComponent, {
      height: 'auto',

      maxWidth: '400px',
      disableClose: true,
      data: { userId: this.userId },
    });
    dialogRef.afterClosed().subscribe({
      next: x => {
        if (x)
          this.service.setCheckResult(x).subscribe({
            next: a => {
              this.setLocalRating(a.setCheckResult);
            }
          })
      }
    })
  }
  setRating() {
    const dialogRef = this.dialog.open(RiskAssessmentDialogComponent, {
      height: 'auto',
      maxWidth: '960px',
      disableClose: true,
      data: { userId: this.userId },
    });
    dialogRef.afterClosed().subscribe({
      next: x => {
        if (x)
          this.service.setRiskAssessmentData(x).subscribe({
            next: a => {
              this.setLocalRating(a.setRiskRate);
            }
          })
      }
    })
  }

  ngOnInit() {

  }


  ngOnDestroy() {
    if (this.form$) {
      this.form$.unsubscribe();
    }
  }
}
