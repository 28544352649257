import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalAccountAddComponent } from '../platform/accounts/modal-account-add/modal-account-add.component';
import { CalculatorEnduserComponent } from '../platform/calculator-enduser/calculator-enduser.component';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { DocumentUploadComponent } from '../components/documents/document-upload/document-upload.component';
import { FilesUploadComponent } from '../components/files/files-upload/files-upload.component';
import { FlagRaiseComponent } from '../admin/flags/flag-raise/flag-raise.component';
import { RecipientItemComponent } from '../platform/recipients/recipient-item/recipient-item.component';
import { TicketItemComponent } from '../components/tickets/ticket-item/ticket-item.component';
import { AddNoteComponent } from '../admin/transactions/transaction-item/add-note/add-note.component';
import { AdminDocumentsService } from './admin/admin-documents.service';
import { AdminFilesService } from './admin/admin-files.service';
import { AdminUsersService } from './admin/admin-users.service';
import { UiService } from './ui.service';
import { UserRecipientsService } from './user/recipients.service';
import { EndUserService } from './user/enduser.service';
import { PaymentMethodsService } from './user/payment-methods.service';
import { UserFlagsService } from './user/flags.service';
import { UserTicketsService } from './user/tickets.service';
import { ChangePasswordComponent } from '../public/login/change-password/change-password.component';
import { OutboundStatusChangeDialogComponent } from '../admin/dialogs/outbound-status-change/outbound-status-change-dialog.component';
import { AdminTransactionType, RaisedFlagType } from '../_graphql/schema';
import { AdminPayoutsService } from './admin/admin-payouts.service';
import { AgreementItemComponent } from '../platform/accounts/agreement-item/agreement-item.component';
import { FlagResolveComponent } from '../admin/flags/flag-resolve/flag-resolve.component';
import { TicketRaiseComponent } from '../components/tickets/ticket-raise/ticket-raise.component';

@Injectable({
    providedIn: 'root'
})
export class ModalLauncherService {
    constructor(
        private dialog: MatDialog,
        private recipientsService: UserRecipientsService,
        private paymentMethodsService: PaymentMethodsService,
        private userFlagsService: UserFlagsService,
        private userTicketsService: UserTicketsService,
        private usersService: EndUserService,
        private adminDocumentsService: AdminDocumentsService,
        private adminFilesService: AdminFilesService,
        private adminUsersService: AdminUsersService,
        public adminPayoutsService: AdminPayoutsService,
        private ui: UiService,
    ) { }
    statusMapper: any = {
        'VERIFIED': 'ACTIVE',
        'NEW': 'CREATED',
        'PENDING': 'SUSPENDED',
        'ERRORED': 'CANCELLED'
    }

    showVerify: boolean = false;

    newRecipient() {
        this.dialog.open(RecipientItemComponent, {
            closeOnNavigation: true,
            height: 'auto',
            width: '600px',
            data: false,
            disableClose: true,
            maxWidth: '95%'
        });
    }
    pay(transaction) {
        const dialogRef = this.dialog.open(CalculatorEnduserComponent, {
            closeOnNavigation: true,
            height: "auto",
            width: "600px",
            data: { recipient: false, transaction: transaction },
            maxWidth: "100%",
            disableClose: true,
        });
    }

    sendMoney(recipient = false) {
        this.dialog.open(CalculatorEnduserComponent, {
            closeOnNavigation: true,
            height: 'auto',
            width: '600px',
            data: { user: false, recipient: recipient },
            maxWidth: '100%',
            disableClose: true
        });
    }

    editRecipient(recipientId: any) {
        this.dialog.open(RecipientItemComponent, {
            closeOnNavigation: true,
            height: 'auto',
            width: '600px',
            data: { id: recipientId },
            disableClose: true,
            maxWidth: '95%'
        });
    }

    deleteRecipient(recipient: any) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            closeOnNavigation: true,
            width: '600px',
            data: {
                title: 'Delete Recipient',
                content: 'Are you sure you want to delete ' + recipient.name + " from the list of recipients? The recipient transactions won't be deleted from transaction history. ",
                note: '',
            },
            disableClose: true,
            maxHeight: '80%'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.recipientsService.addRefetchQuery(this.usersService.onboardingQuery, this.usersService.onboardingWQparams);
                this.recipientsService.addRefetchQuery(this.usersService.currentUserGQL, null);
                this.recipientsService.delete(recipient.id).subscribe(res => {
                    this.ui.snack('Recipient is deleted');
                    // this.usersService.refetchOnboarding()
                }, err => {
                    this.ui.snack(err.message.replace('GraphQL error:', ''));
                });
            }

        });
    }

    deleteAccount(account: any) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            closeOnNavigation: true,
            width: '600px',
            data: {
                title: 'Delete Payment Method',
                content: 'Are you sure you want to delete ' + account.name + " from the list of payment methods?",
                note: '',
            },
            disableClose: true,
            maxHeight: '80%'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                var k = this.paymentMethodsService.deleteAccount(account.id, account.accountType, account.inboundCode).subscribe(res => {
                    this.userFlagsService.refetchData();
                    this.userTicketsService.refetchData();
                    this.ui.snack('Account deleted!');
                    k.unsubscribe();
                }, err => {
                    // this.usersService.refetchUserData();
                    this.ui.snack(err.message.replace('GraphQL error:', ''));
                    k.unsubscribe();
                });
            }

        });
    }



    changePassword(data: any = null) {
        const dialogRef = this.dialog.open(ChangePasswordComponent, {
            closeOnNavigation: true,
            width: '600px',
            maxWidth: '95%',
            maxHeight: '80%',
            disableClose: true,
            data: { data, isResetMode: false }
        });
    }
    // DOCUMENTS
    onPrimarySelectedDialog(document, userId) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            closeOnNavigation: true,
            width: '600px',
            data: {
                title: 'Set document as primary',
                content: (document?.documentSource ? 'The document is verified. Confirmation is needed for it to be set as primary in all transactions.' : 'The document is not verified. Confirmation is needed for it to be set as primary in all transactions.'),
                note: 'Select a new document and click the "Set as primary" button to change the primary document.',
                color: 'primary'
            },
            disableClose: true,
            maxHeight: '80%'
        });
        dialogRef.afterClosed().subscribe(isPrimary => {
            if (isPrimary) {
                this.adminDocumentsService.addRefetchQuery(this.adminUsersService.selectOneQuery, { id: userId })
                var s = this.adminDocumentsService.setPrimaryDocument(document.id, userId).subscribe({
                    next: x => {
                        this.adminFilesService.refetchData()
                        this.adminDocumentsService.refetchData()
                        s.unsubscribe();
                    }, error: e => {
                        this.ui.snack(e.message.replace('GraphQL error:', ''));
                        s.unsubscribe()
                    },
                });
            }
        });
    }


    uploadFile(userId: any) {
        const dialogRef = this.dialog.open(FilesUploadComponent, {
            closeOnNavigation: true,
            width: '600px',
            disableClose: true,
            data: { userId: userId, changeDocument: false },
            maxWidth: '95%',
            height: 'auto'
        });

        return dialogRef;
    }

    uploadDocument(userId: any, document: any = null, file: any = null, ticketId = null, country = null) {
        return this.dialog.open(DocumentUploadComponent, {
            closeOnNavigation: true,
            width: '600px',
            disableClose: true,
            data: { userId: userId, document: { ...document }, file: { ...file }, ticketId, country },
            maxWidth: '95%',
            height: 'auto'
        });

    }

    accountAdd(data) {
        const dialogRef = this.dialog.open(ModalAccountAddComponent, {
            closeOnNavigation: true,
            panelClass: 'no-scrolls',
            width: '380px',
            disableClose: false,
            data: data,
            maxWidth: '95%',
            maxHeight: '95%',
            height: 'auto'
        });
        return dialogRef;
    }

    raiseFlag(userId: string = null, transactionId: string = null) {
        const dialogRef = this.dialog.open(FlagRaiseComponent, {
            closeOnNavigation: true,
            width: '600px',
            data: { userId, transactionId },
            maxWidth: '95%',
            maxHeight: '80%',
            disableClose: true
        });
        return dialogRef;
    }

    addNote(transactionId) {
        const dialogRef = this.dialog.open(AddNoteComponent, {
            closeOnNavigation: true,
            width: '600px',
            data: { transactionId: transactionId, note: "test" },
            maxWidth: '95%',
            maxHeight: '80%',
            disableClose: true
        })
        return dialogRef;
    }
    openDocuments(userId: string, transactionId: string, transactionSdocId: string) {
        const dialogRef = this.dialog.open(FilesUploadComponent, {
            closeOnNavigation: true,
            height: 'auto',
            width: '600px',
            data: { userId, changeDocument: true, transactionId, transactionSdocId },
        });
        return dialogRef;
    }
    updateDocument() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            closeOnNavigation: true,
            width: '600px',
            data: {
                title: 'Update Document in Transaction',
                content: 'Are you sure you want to update document data in transaction?',
            },
            maxHeight: '80%',
            disableClose: true,
        });
        return dialogRef;
    }

    closeTicket() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            closeOnNavigation: true,
            width: '600px',
            data: {
                title: 'Close Ticket',
                content: 'Are you sure you want to close this ticket?',
            },
            maxHeight: '80%',
            disableClose: true,
        });
        return dialogRef;
    }

    openTicket(ticketId: string, userId: string = null) {

        const dialogRef = this.dialog.open(TicketItemComponent, {
            closeOnNavigation: true,
            height: 'auto',
            panelClass: 'no-padding',
            maxWidth: '960px',
            disableClose: true,
            data: { ticketId, userId },
        });
        let s = dialogRef.afterClosed().subscribe({
            next: x => {
                s.unsubscribe()
                this.userTicketsService.refetchData();
                this.userTicketsService.refetchData('dashboard');

            }
        })
        return dialogRef;
    }

    outboundUpdateStatus(transaction: AdminTransactionType, outboundStatus: string = null, pickupCode: string = null) {

        var dialogRef;
        var title = 'Change Outbound Status';
        var content = 'Are you sure you want to change outbound status from ' + transaction.outboundStatus + ' to ' + outboundStatus + '?';
        if (outboundStatus == 'Stopped') {
            title = "Stop transaction"
            content = 'Are you sure you want to stop transaction?'
        }
        if (outboundStatus == 'Approved') {
            title = "Approve transaction"
            content = 'Are you sure you want to approve transaction?'
        }
        if (outboundStatus == 'Delivered') {
            title = "Transaction delivered"
            content = 'Are you sure you want to mark transaction as delivered?'
        }

        if (outboundStatus == 'Reimbursed') {
            title = "Reimburse transaction"
            content = 'Are you sure you want to mark transaction as reimbursed?'
        }


        dialogRef = this.dialog.open(OutboundStatusChangeDialogComponent, {
            width: '380px',
            closeOnNavigation: true,
            data: {
                title: title,
                content: content,
                transaction: transaction,
                outboundStatus: outboundStatus,
                pickupCode: pickupCode
            },
            maxHeight: '80%',
            disableClose: true,
        });

        if (dialogRef)
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.adminPayoutsService.setOutboundStatus(result)
                    // this.changeStatus(this.transaction.id, isCancel, null, null)
                }

            });

        return dialogRef;
    }
    newAgreement(agreement: any = null) {
        const dialogRef = this.dialog.open(AgreementItemComponent, {
            closeOnNavigation: true,
            height: 'auto',
            width: '600px',
            data: agreement,
            disableClose: true,
            maxWidth: '95%'
        });
        return dialogRef;
    }

    changeAgreementStatus(agreement: any, status: string) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            closeOnNavigation: true,
            width: '600px',
            data: {
                title: 'Change Agreement Status',
                content: 'Are you sure you want to change agreement status from ' + this.statusMapper[agreement.status] + ' to ' + status + '?',
                note: '',
            },
            disableClose: true,
            maxHeight: '80%'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                var k = this.paymentMethodsService.changePaymentMethodStatus(agreement.id, status, agreement.inboundCode).subscribe(res => {
                    this.userFlagsService.refetchData();
                    this.userTicketsService.refetchData();
                    this.ui.snack('Agreement status changed!');
                    k.unsubscribe();
                }, err => {
                    // this.usersService.refetchUserData();
                    this.ui.snack(err.message.replace('GraphQL error:', ''));
                    k.unsubscribe();
                });
            }

        });
        return dialogRef;
    }


    removeFlag(item: RaisedFlagType) {
        const dialogRef = this.dialog.open(FlagResolveComponent, {
            closeOnNavigation: true,
            width: '600px',
            disableClose: true,
            data: { flagId: item.id, code: item.flag.code, userId: item.forUserId, transactionId: item.transaction?.transactionId ? item.transaction?.transactionId : null },
            maxHeight: '80%'
        });

        dialogRef.afterClosed().subscribe(result => { });
    }
    abandonDelete(item: RaisedFlagType, action: string) {
        const dialogRef = this.dialog.open(FlagResolveComponent, {
            closeOnNavigation: true,
            width: '600px',
            disableClose: true,
            data: { flagId: item.id, code: item.flag.code, userId: item.forUserId, 
                transactionId: item.transaction?.transactionId ? item.transaction?.transactionId : null,
                title: 'Abandon Delete',
                approveLabel: 'Abandon',
                successMessage: 'Delete account abandoned',
                action: action
            },
            maxHeight: '80%'
        });

        dialogRef.afterClosed().subscribe(result => { });
    }



    createTicket(item: RaisedFlagType, userId: string, transactionId: string) {
        const dialogRef = this.dialog.open(TicketRaiseComponent, {
            closeOnNavigation: true,
            width: '600px',
            data: { userId: userId, raisedFlag: item, transactionId: transactionId },
            maxHeight: '80%',
            disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => { });
    }

}
