import { Component, OnInit, Input } from '@angular/core';
import { TicketType } from 'src/app/_graphql/schema';

@Component({
  selector: 'app-ticket-status',
  templateUrl: './ticket-status.component.html',
  styleUrls: ['./ticket-status.component.scss']
})
export class TicketStatusComponent {
  @Input('ticket') ticket: any;
  constructor() { }
}