<h2 mat-dialog-title>
  <!-- <button mat-button  mat-dialog-close>
    <mat-icon class="back">arrow_back</mat-icon>
</button> -->
  Send Message
</h2>
<mat-dialog-content>
  <form class="container">
    <!-- <mat-form-field appearance="outline">
          <mat-label>{{ 'Name' | translate }}</mat-label>
          <input matInput placeholder="Name" type="text" formControlName="name">
        </mat-form-field> -->

    <mat-form-field appearance="outline">
      <mat-label>To</mat-label>
      <input matInput />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Subject</mat-label>
      <input matInput />
    </mat-form-field>

    <!-- <mat-form-field appearance="outline">
          <mat-select placeholder="Country" formControlName="countryId">
            <mat-option value="{{ country.id }}" *ngFor="let country of countries | async"
              >
              <img
                *ngIf="!country.error"
                (error)="country.error = true"
                class="countries"
                src="/assets/images/CountryFlags/{{ countriesService.pictureName(country.name) }}.png">
                <mat-icon *ngIf="country.error" matListIcon >outlined_flag</mat-icon>
                <a>{{ country.name | translate }}</a>
          </mat-option>
          </mat-select>
        </mat-form-field> -->

    <mat-form-field appearance="outline">
      <textarea matInput placeholder="Message"></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>

<!--
<mat-dialog-actions align="end" style="padding: 0px 20px 20px 0px;">
  <button mat-button  mat-dialog-close>Cancle</button>
  <button mat-raised-button  type="submit"
  class="send-message"
  (click)="submitForm()">Send</button>
</mat-dialog-actions> -->
