import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo } from '../../../node_modules/apollo-angular';
import { map } from '../../../node_modules/rxjs/operators';
import { EndUserService } from './user/enduser.service';



@Injectable({
  providedIn: 'root'
})
export class KycService {
  query: any;

  //countriesSelect = gql`
  //  query kycCountries {
  //    kycCountries {
  //      id
  //      name
  //      isoCode2
  //    }
  //  }
  //`;

  kycConfig = gql`
    query kycFieldsConfig($countryIsoCode2: String){
      user {
        id
          kycFieldsConfig(countryIsoCode2: $countryIsoCode2){
          description
          group
          groupPolicy
          label
          name
          fields {
            description
            editable
            label
            max
            maxLength
            min
            minLength
            name
            orderNumber
            pattern
            required
            section
            type
            visible
            value
            values { label value}
            properties { type value }
          }
        }
      }
    }
  `;

  constructor(private apollo: Apollo, private usersService: EndUserService) { }
  public configurationKYC(countryIsoCode2: string) {
    this.query = this.apollo.watchQuery({
      query: this.kycConfig,
      variables: { countryIsoCode2 }
    });
    return this.query.valueChanges.pipe(
      map((result: any) => result.data && result.data.user && result.data.user.kycFieldsConfig)
    );
  }

  public verify(data: any) {
    return this.apollo.mutate({
      mutation: gql`
        mutation kycVerify($data: VerifyDataInputType!) {
          user {
            kycVerify(data: $data) 
          }
        }
      `,
      refetchQueries: [
        { query: this.usersService.onboardingQuery, variables: this.usersService.onboardingWQparams },
        { query: this.usersService.currentUserGQL }
        // { query: this.usersService.fieldsConfigurations, variables: this.usersService.fieldsConfigurationsQueryParams, },
      ],
      variables: {
        data
      }
    });
  }
}
