import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo } from '../../../node_modules/apollo-angular';
import { BaseService } from './admin/base.service';
import { MessageType } from '../_graphql/schema';
import { FilesFragmentBasic, UserInfoFragment } from '../_graphql/shared-fragments';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessagesService extends BaseService<MessageType>{

  selectOneFields = gql`
    fragment MessageSelectOneFields on MessageType  {
      id 
    }
  `;

  selectAllFields = gql`
    fragment MessageSelectAllFields on MessageType  {
      id
  }`;

  constructor(protected apollo: Apollo, private http: HttpClient, public auth: AuthenticationService) {
    super(apollo);
    this.mainScope = "messaging";
    this.initGql('message');
  }

  gqlMessages = gql`query messages( $ticketId: ID ) {
    messaging  {
      id
      messages( ticketId: $ticketId ) {
        id
        created
        sender { id ...UserInfoFragment }
        file { id ...FilesFragmentBasic }
        subject
        text
      }
    }
  }
  ${UserInfoFragment}
  ${FilesFragmentBasic}
`;
  public all(ticketId: string) {
    this.addRefetchQuery(this.gqlMessages, { ticketId });
    return this.query(this.gqlMessages, { ticketId }, true, 'messages');
  }
  public sendMessage(data: any) {
    return this.mutation(
      gql
        `mutation sendMessage(
      $data: MessageInputType!
    ) {
      messaging {
        __typename
        sendMessage(
          data: $data
        ) {
          id
          __typename
        }
      }
    }
    `, { data }
    );
  }



  public markMessagesSeen(ticketId: any) {
    this.mutation(
      gql
        `mutation markMessagesSeen(
      $ticketId: ID
    ) {
          messaging {
        __typename
        markMessagesSeen(
          ticketId: $ticketId
        )
      }
    }
    `, { ticketId }
    ).subscribe(data => { });;
  }

  upload(
    filesToUpload: FileList,
    categoryId: string = null,
    userId: any = null,
    description: string = null,
    ticketId: any = null,
    documentId: any = null,
    refetchQuery: boolean
  ) {
    const endpoint = environment.uploadEndpoint;
    const formData: FormData = new FormData();

    for (let i = 0; i < filesToUpload.length; i++) {
      const fileToUpload = filesToUpload.item(i);
      formData.append('files', fileToUpload, fileToUpload.name);
    }

    if (userId && this.auth.isAdmin()) {
      formData.append('forUserId', userId);
    }

    if (ticketId) {
      formData.append('ticketId', ticketId);
    }

    if (description) {
      formData.append('description', description);
    }

    if (documentId) {
      formData.append('documentId', documentId);
    }

    return this.http.post(endpoint, formData, { headers: {} }).pipe(
      map((files: any) => {
        if (!refetchQuery) {
          // this.updateFileList(userId, ticketId);
        }
        return files;
      })
    );
  }
}