import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PublicRoutingModule } from './public-routing.module';
import { PublicComponent } from './wrapper/public.component';
import { HomeComponent } from './home/home.component';
import { HowComponent } from './how/how.component';
import { HelpComponent } from './help/help.component';
import { TermComponent } from './term/term.component';
import { FaqComponent } from './faq/faq.component';
import { ContactComponent } from './contact/contact.component';
import { CountriesComponent } from './countries/countries.component';
import { MainNavComponent } from './wrapper/main-nav/main-nav.component';
import { MaterialModule } from '../material.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeroComponent } from './wrapper/hero/hero.component';
import { FooterComponent } from './wrapper/footer/footer.component';
import { TranslateModule } from '@ngx-translate/core';
// import { PasswordComponent } from './login/forgot-password/password.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { RegisterComponent } from './register/register.component';
import { ComponentsModule } from '../components/components.module';
// import { ResetPasswordComponent } from './login/reset-password/reset-password.component';

import { CalculatorModule } from '../components/calculator/calculator.module';
import { FormConfigsModule } from '../components/form-configs/form-configs.module';
import { LoginModule } from './login/login.module';
import { SingleSelectModule } from '../components/select-country/single-select.module';
import { PayToTermsComponent } from './term/payto-terms/payto-terms.component';

@NgModule({
  declarations: [
    PublicComponent,
    HomeComponent,
    HowComponent,
    HelpComponent,
    PayToTermsComponent,
    // LoginComponent,
    TermComponent,
    FaqComponent,
    ContactComponent,
    FooterComponent,
    CountriesComponent,
    MainNavComponent,
    HeroComponent,
    // PasswordComponent,
    RegisterComponent,
    // ResetPasswordComponent,
  ],
  imports: [
    MatBottomSheetModule,
    CommonModule,
    CalculatorModule,
    PublicRoutingModule,
    MaterialModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    TranslateModule.forChild(),
    FormConfigsModule,
    SingleSelectModule,
    LoginModule
  ],
  entryComponents: [CountriesComponent],
  bootstrap: [CountriesComponent]
})
export class PublicModule { }
