import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Subscribable } from 'rxjs';
import { saveAs } from 'file-saver';
import { map } from 'rxjs/operators';
import { CorridorType } from 'src/app/_graphql/schema';
import { environment } from 'src/environments/environment';
import { BaseService } from './admin/base.service';


@Injectable({
  providedIn: 'root'
})
export class PublicService extends BaseService<CorridorType>{


  constructor(protected apollo: Apollo, private http: HttpClient) {
    super(apollo);
    // this.mainScope = "public";
    // this.initGql('public');
  }

  public countries(isSender: boolean, isRecipient: boolean, isActive: boolean): any {
    return this.apollo
      .watchQuery({
        query: gql` query publicCountries($isSender: Boolean, $isRecipient: Boolean, $isActive: Boolean) { 
            public { 
              id
              countries (isSender: $isSender, isRecipient: $isRecipient, isActive: $isActive) { 
                id name isActive isoCode2 isoCode3 phonePrefix
              }
            }
          }`,
        variables: { isSender, isRecipient, isActive }
      }).valueChanges.pipe(
        map((result: any) => result.data && result.data.public && result.data.public.countries)
      );
  }

  public states(countryIsoCode2: string): any {
    return this.apollo
      .watchQuery({
        query: gql` query publicStates($countryIsoCode2: String) {          
            public { 
              id
              states (countryIsoCode2: $countryIsoCode2) { 
                countryIsoCode2 id name
              }
            }
          }`,
        variables: { countryIsoCode2 }
      }).valueChanges.pipe(
        map((result: any) => result.data && result.data.public && result.data.public.states)
      );
  }

  public countryFlagImage(isoCode3: string) {
    return '/assets/images/CountryFlags/' + (isoCode3
      ? isoCode3
        .toString()
        .toLowerCase()
        .split(' ')
        .join('-')
      : '') + '.png';
  }

  public getFieldsConfig(countryCode: string, fieldsGroup: string, useCache = true): Subscribable<any> {
    return this.query(
      gql` query publicFieldsConfig($countryCode: String, $fieldsGroup: String!) { 
        public { 
          id
          fieldsConfig (countryCode: $countryCode, fieldsGroup: $fieldsGroup) { 
            country
            fields {
              description
              editable
              label
              max
              maxLength
              min
              minLength
              name
              orderNumber
              pattern
              required
              section
              type
              value
              values { label value }
              visible
              properties { type value }
            }
          }
        }
      }`,
      { countryCode, fieldsGroup },
      useCache,
      'fieldsConfig'
    );
  }

  public getTranslations(langId: string, isoCode2: string): Subscribable<any> {
    return this.query(
      gql` query publicTranslations($langId: Int, $isoCode2: String) { 
        public { 
          id
          translations (langId: $langId, isoCode2: $isoCode2) { 
            id
            key
            language { id isDefault isoCode2 isoCode3 name }
            tags
            value
            }
          }
        }
      }`,
      { langId, isoCode2 },
      false,
      'publicTranslations' + langId + isoCode2
    );
  }
  downloadFile(fileId: string, name = 'uploaded_file.png') {
    this.http
      .get(environment.downloadEndpoint, {
        responseType: 'blob',
        params: { id: fileId }
      })
      .subscribe(fileBlob => {
        saveAs(fileBlob, name);
      });
  }

  upload(
    filesToUpload: FileList,
    categoryId: string = null,
    userId: any = null,
    description: string = null,
    ticketId: any = null,
    documentId: any = null,
    refetchQuery: boolean
  ) {
    const endpoint = environment.uploadEndpoint;
    const formData: FormData = new FormData();

    for (let i = 0; i < filesToUpload.length; i++) {
      const fileToUpload = filesToUpload.item(i);
      formData.append('files', fileToUpload, fileToUpload.name);
    }

    if (userId) {
      formData.append('forUserId', userId);
    }

    if (ticketId) {
      formData.append('ticketId', ticketId);
    }

    if (description) {
      formData.append('description', description);
    }

    if (documentId) {
      formData.append('documentId', documentId);
    }

    return this.http.post(endpoint, formData, { headers: {} }).pipe(
      map((files: any) => {
        return files;
      })
    );
  }

  public getCorridorsGroups(countryFrom: string = null, countryTo: string = null, countryFromId: string = null, countryToId: string = null): Subscribable<any> {
    return this.unwrapData('corridorsGroups', this.query(
      gql` query publicCorridorsGroups($countryFrom: String, $countryTo: String, $countryFromId: ID, $countryToId: ID) { 
        public { 
          id
          corridorsGroups (countryFrom: $countryFrom, countryTo: $countryTo, countryFromId: $countryFromId, countryToId: $countryToId) { 
            
            from {
              id
              name
              isoCode2
              currency
              isActive
            }
            toCountries {
              country {
                id
                name
                isoCode2
                isActive
              }

              paymentServices {
                paymentService {
                  id
                  name
                }
                networks {
                  paymentNetwork {
                    id
                    name
                  }
                  locations { id city code name phone }
                  currency
                }
              }
            }
          }
        }
      }`,
      { countryFrom, countryTo, countryFromId, countryToId },
      false,
      'publicCorridorsGroups'
    ));
  }

  public calculateAmount(data: any): Subscribable<any> {
    return this.unwrapData('calculateAmount', this.query(
      gql` query calculateAmount($countryFromId: ID!, $countryToId: ID!, $sendAmount: Float, $receiveAmount: Float, $serviceId: ID!, $networkId: ID!, $locationId: ID ) { 
        public { 
          id
          calculateAmount ( countryFromId: $countryFromId, countryToId: $countryToId, sendAmount: $sendAmount, receiveAmount: $receiveAmount, serviceId: $serviceId, networkId: $networkId, locationId: $locationId ) { 
            sendAmount
            fee
            totalToPay
            expectedPayoutTime
            recipientReceives
            quote
            quoteId
          }
        }
      }`,
      data,
      false,
      'publicCorridorsGroups'
    ));
  }
  public getPurposes(): Subscribable<any> {
    return this.unwrapData('purposes', this.query(
      gql` query purposes {
        public {
          id
          purposes {
            id
            isActive
            name
          }
        }
      }`,
      {},
      false,
      'getPurposes'
    ));
  }

  public getRelationships(active: boolean = null): Subscribable<any> {
    return this.unwrapData('relationships', this.query(
      gql` query relationships(
          $active:Boolean
      ) {
        public {
          id
          relationships( active:$active ){
              id
              isActive
              name
          }
        }
      }`,
      { active },
      true,
      'getRelationships'
    ));
  }

  public getFrontendConfiguration(): Subscribable<any> {
    return this.query(
      gql` query frontendConfiguration { 
        public { 
          id
          frontendConfiguration { 
            id
            inputDateFormat dateFormatLong dateFormatShort
            inbounds { addNew code displayName isDefault maxAmount }
          }
        }
      }`,
      null,
      false,
      'frontendConfiguration'
    );
  }

  static COMPONENT_DATE_FORMATS = {
    parse: {
      dateInput: 'DD/MM/YYYY'
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    }
  };
  static setDateInputFormat(format: string): any {
    if (format)
      this.COMPONENT_DATE_FORMATS.display.dateInput = format;
    return this.COMPONENT_DATE_FORMATS
  }
  static DISPLAY_DATE_FORMATS = {
    dateFormatLong: 'M/D/YY, h:mm A',
    dateFormatShort: 'MMM D, Y',
  };
  static getDateInputFormat(): any {
    return this.COMPONENT_DATE_FORMATS;
  }
  static setDisplayDateFormat(short: string, long: string): void {
    if (short)
      this.DISPLAY_DATE_FORMATS.dateFormatShort = short;
    if (long)
      this.DISPLAY_DATE_FORMATS.dateFormatLong = long;
  };
  static getDisplayDateFormat(): any {
    return this.DISPLAY_DATE_FORMATS;
  }
}
