<form [formGroup]="form">

  <div mat-dialog-title>
    {{ data?.id ? 'Edit Relationship' : 'Add Relationship'}}
  </div>
  <mat-dialog-content>
    <div class="io-row">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput required formControlName="name">
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <mat-checkbox color="primary" formControlName="isActive">Active</mat-checkbox>
    <span style="flex:1"></span>
    <button mat-button color="primary" mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid" (click)="onSubmit()">Save</button>
  </mat-dialog-actions>
</form>