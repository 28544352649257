import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { PublicService } from 'src/app/_services/public.service';
import { EndUserService } from 'src/app/_services/user/enduser.service';
import { CountryType, EndUserType } from 'src/app/_graphql/schema';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit, OnDestroy {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));
  countries$: Subscription;
  recipientDef$: Subscription;
  updateUserDef$: Subscription;
  environment = environment;
  user$: any;


  constructor(
    public service: EndUserService,
    public modalService: ModalLauncherService,
    public publicService: PublicService,
    private breakpointObserver: BreakpointObserver,
    public auth: AuthenticationService,
    public router: Router,
    private location: Location,
  ) {
    if (this.auth.user?.country?.isoCode2) {
      this.publicService.getFieldsConfig(this.auth.user.country.isoCode2, 'updateUser').subscribe()
    }
    this.user$ = this.service.currentUser();
    // preload configurations
    // let s = this.service.currentUser().subscribe({
    //   next: (user: EndUserType) => {
    //     publicService.countries(null, true, true).subscribe({
    //       next: (countries: CountryType[]) => {
    //         countries.forEach(country => {
    //           this.publicService.getFieldsConfig(country.isoCode2, 'recipient').subscribe();
    //           s.unsubscribe()
    //         })
    //       }
    //     })
    //   }
    // });
  }

  ngOnInit() {
  }

  profile() {
    this.router.navigate(['/platform/profile']);
  }

  getBack() {
    this.router.navigate(['/platform']);
  }

  ngOnDestroy() {
    this.countries$?.unsubscribe();
    this.recipientDef$?.unsubscribe();
    this.updateUserDef$?.unsubscribe();
  }
}