import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KycComponent } from './kyc.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/material.module';
import { FormConfigsModule } from 'src/app/components/form-configs/form-configs.module';



@NgModule({
  declarations: [
    KycComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MaterialModule,
    FormConfigsModule
  ],
  exports: [
    KycComponent
  ]
})
export class KycModule { }
