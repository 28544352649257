import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subscribable } from 'rxjs';
import { RaisedFlagType } from 'src/app/_graphql/schema';
import { UserFlagsService } from 'src/app/_services/user/flags.service';

@Component({
  selector: 'app-warning-flags-list',
  templateUrl: './warning-flags-list.component.html',
  styleUrls: ['./warning-flags-list.component.scss']
})
export class WarningFlagsListComponent implements OnInit {
  items$: Subscribable<RaisedFlagType[]>;
  userCountry = "";
  constructor(private router: Router,
    public service: UserFlagsService
  ) {
    const local = JSON.parse(localStorage.getItem('currentUser'));
    this.userCountry = local?.user?.country?.isoCode2;
    this.items$ = this.service.all();
  }

  ngOnInit() { }
  mylocalize(key, string, keyLocalised, stringLocalised) {
    if (stringLocalised != keyLocalised) {
      return stringLocalised;
    } else if (string != key) {
      return string;
    }
    return key
  }
  flagAction(flag: any) {
    switch (flag.presentedCode) {
      case 'QUESTIONNAIRE_MISSING':
        this.router.navigate(['/platform/questionnaire']);
        break;
      case 'EMAIL_NOT_VERIFIED':
        this.router.navigate(['/platform/verify/email']);
        break;
      case 'PHONE_NOT_VERIFIED':
        this.router.navigate(['/platform/verify/phone']);
        break;
      case 'KYC_MISSING':
        this.router.navigate(['/platform/kyc']);
        break;
      case 'REGISTER_PAYMENT_METHOD':
        this.router.navigate(['/platform/payment-methods']);
        break;
      case 'PEP':
        // this.router.navigateByUrl('/assets/form/PEP.pdf');
        window.open('/assets/form/PEP.pdf', 'Download');
        break;

    }
  }
}
