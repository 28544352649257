import { Component, OnInit, Optional, Inject, ViewChild, Output, EventEmitter, HostListener, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UiService } from 'src/app/_services/ui.service';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserTransactionsService } from 'src/app/_services/user/transactions.service';
import { UserFlagsService } from 'src/app/_services/user/flags.service';
import { Subscribable, Unsubscribable } from 'rxjs';
import { RaisedFlagType } from 'src/app/_graphql/schema';

@Component({
  selector: 'app-calculator-enduser',
  templateUrl: './calculator-enduser.component.html',
  styleUrls: ['./calculator-enduser.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalculatorEnduserComponent implements OnInit, OnDestroy {
  calculatorPreReceipt = environment.calculatorPreReceipt;
  loading = true;
  chooseBox: any;
  mode: 'public' | 'chooseRecipient' | 'calculator' | 'inbound' | 'inbounds' | 'informations' | 'pending' | 'flagOrTicket' | 'success' | 'fail' | 'cancel';
  sendingMode: string;
  receivingCurrency: string;
  sendingCurrency: string;
  showTransaction = false;
  inbounds: any;
  transactionId: any;
  transaction: any;
  iFrameUrl: string = null;
  emitData = false;
  tickets: any;
  functionCalls = 0;
  KYCFlag = null;
  inboundType: string;
  disabled = false;
  errorMsg = 'An error occurred, please try again later.';

  inbounds$: Unsubscribable;
  transactionInbounds$: Unsubscribable;
  initiatePayment$: Unsubscribable;
  transactionOne$: Unsubscribable;
  paymentMethodId: string;
  $raisedFlags: any;

  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  @Output() transactionsUpdate = new EventEmitter<any>();
  @HostListener('window:message', ['$event'])
  onMessage(e) {
    if (e.data === 'tango-success') {
      this.mode = 'success';
    } else if (e.data === 'tango-fail') {
      this.mode = 'fail';
    } else if (e.data === 'tango-cancel') {
      this.mode = 'cancel';
    }
    this.emitData = true;
  }

  constructor(
    public userTransactionsService: UserTransactionsService,
    public userFlagsService: UserFlagsService,
    public ui: UiService,
    private router: Router,
    @Optional() public dialogRef: MatDialogRef<CalculatorEnduserComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }

  ngOnInit() {
    this.$raisedFlags = this.userFlagsService.all();
    if (this.dialogRef) {
      let unsubDialog = this.dialogRef.beforeClosed().subscribe(result => {
        this.transactionsUpdate.emit(true);
        this.userTransactionsService.refetchData();
        unsubDialog?.unsubscribe();
      });
    }

    if (this.data) {
      if (this.data.transaction) {
        this.transactionId = this.data.transaction.id;
        this.transaction = Object.assign({}, this.data.transaction);
        this.mode = 'inbounds';
        if (this.transactionId)
          this.inbounds$ = this.userTransactionsService.getInbounds(this.transactionId).subscribe({
            next: data => {
              this.inbounds = data;
              this.loading = false;
            }, error: error => {
              this.errorMsg = error.message;
              this.inbounds = [];
              this.mode = "pending";
              this.stepper.selectedIndex = 3;
              this.loading = false;
            }
          });
        this.stepper.selectedIndex = 2;
      } else if (this.data.recipient === false) {
        this.mode = 'chooseRecipient';

      } else {
        this.mode = 'calculator';
        this.stepper.selectedIndex = 1;
      }
    }
  }

  loadingIframe() {
    this.functionCalls++;
    if (this.functionCalls === 2) {
      this.loading = false;
    }
  }

  setRecipient(recipient) {
    this.data.recipient = recipient;
    this.mode = 'calculator';
    this.stepper.selectedIndex = 1;
  }

  currentCalculatorData($event) {
    this.transaction = $event;
  }

  receiveMode($event) {
    this.sendingMode = $event;
    this.transactionId = this.transaction.id;
    if (this.transaction.raisedFlags.length) {
      this.userFlagsService.refetchData()
      this.KYCFlag = this.transaction.raisedFlags.find(e => e.flag.presentedCode === 'KYC_MISSING')?.flag.presentedCode;
      this.mode = 'flagOrTicket';
      this.stepper.selectedIndex = 3;
    } else {
      this.transactionID(this.transaction.id)
      this.mode = 'inbounds';
      this.stepper.selectedIndex = 2;
      this.showTransaction = true;
    }
  }

  currencies($event) {
    this.receivingCurrency = $event;
    this.sendingCurrency = $event;
  }

  transactionID($event) {
    this.transactionId = $event;
    this.transactionInbounds$ = this.userTransactionsService.getInbounds(this.transactionId).subscribe({
      next: (data) => {
        this.inbounds = data;
        this.loading = false;
        this.transactionInbounds$?.unsubscribe();
      }, error: error => {
        this.errorMsg = error.message;
        this.inbounds = [];
        this.mode = "pending";
        this.stepper.selectedIndex = 3;
        this.loading = false;
        this.transactionInbounds$?.unsubscribe();
      }
    });
  }

  initiatePayment(code, paymentMethodId = null) {
    this.disabled = true;
    this.transactionID(this.transactionId);
    this.paymentMethodId = paymentMethodId;
    this.closeSnack();
    this.initiatePayment$ = this.userTransactionsService.initiatePayment({ transactionId: this.transactionId, inbound: code, paymentMethodId }).subscribe({
      next: result => {
        if (result.newTab && result.navigateURL) {
          window.location.href = result.navigateURL;
          this.dialogRef.close();
          return;
        }
        this.inboundType = result.inbound;
        if (!result.success) {
          this.mode = 'pending';
          this.stepper.selectedIndex = 3;
        } else if (result.navigateURL) {
          if (this.transaction) {
            this.transaction.inboundName = result.inboundName;
          }
          this.iFrameUrl = result.navigateURL;
          this.mode = 'inbound';
          this.emitData = true;
          this.stepper.selectedIndex = 3;
        } else {
          if (this.transaction) {
            this.transactionOne$ = this.userTransactionsService.one(this.transactionId).subscribe({
              next: x => {
                this.transaction = x;
                this.mode = 'informations';
                this.stepper.selectedIndex = 3;
                this.transactionsUpdate.emit(true);
              }
            })
            // this.transaction.inboundRefNo = result.inboundRefNo;
          }
        }
        this.disabled = false;
        this.initiatePayment$?.unsubscribe();
      },
      error: e => {
        if (e?.graphQLErrors[0]?.extensions?.code === 'AMOUNT_OVER_PAYMENT_METHOD_LIMIT') {
          this.ui.specificSnack(e?.graphQLErrors[0]?.extensions?.data?.message, paymentMethodId, this.dialogRef);
        }
        this.disabled = false;
        this.initiatePayment$?.unsubscribe();

      }
    }
    );
  }

  goToKYC() {
    this.dialogRef.close();
    this.userTransactionsService.pendingTransId = this.transactionId;
    this.router.navigate(['/', 'platform', 'kyc']);
  }
  ngOnDestroy(): void {
    this.inbounds$?.unsubscribe();
    this.transactionOne$?.unsubscribe();
    this.closeSnack();
  }

  navigateToPaymentMethods() {
    this.dialogRef.close();
    this.router.navigate(['/', 'platform', 'payment-methods']);
  }

  closeSnack() {
    if (this.ui.specificBarRef)
      this.ui.dismissSpecificSnack(false);
  }

}
