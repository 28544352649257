<div class="wrapper">
  <mat-toolbar style="background-color: transparent;" *ngIf="title">
    <mat-toolbar-row #refHeader>
      <h2>{{title}}</h2>
      <ng-content select="[header]"></ng-content>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="toolbox" style="font-size: 10pt !important">
    <!-- <mat-form-field style="max-width: 200px; margin-bottom: 0;" appearance="outline" *ngIf="showQueryField">
      <mat-label>Type to search...</mat-label>
      <input matInput [formControl]="queryString">
      <button mat-icon-button matSuffix disabled="disabled">
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field> -->

    <ng-content select="[toolbox]"></ng-content>
    <ng-content select="[toolbox2]"></ng-content>



  </div>
  <!-- readPerm: {{readPerm}}
  managePerm: {{managePerm}} -->
  <div *ngIf="readPerm==null" class="infoblock warning"><strong>readPerm</strong> is not defined on List Wrapper</div>
  <div *ngIf="managePerm==null" class="infoblock warning"><strong>managePerm</strong> is not defined on List Wrapper
  </div>
  <div class="main-content">
    <app-panel-admin-placeholder *ngIf=" mainService.totalCount == 0"></app-panel-admin-placeholder>
    <div>

      <div *ngIf=" mainService.totalCount > 0">
        <ng-content></ng-content>
      </div>
    </div>
  </div>


  <ng-content select="[footer]"></ng-content>
  <mat-paginator *ngIf="showPagination && ( mainService.totalCount > 0)" [disabled]="mainService.loading"
    [length]="mainService.totalCount" [pageSize]="mainService.queryAllParams.take" [pageSizeOptions]="[5, 10, 25, 100]"
    (page)="mainService.applyPager($event.pageIndex, $event.pageSize, this.customKey)"
    [pageIndex]="mainService.pageIndex">
  </mat-paginator>
</div>
<button *ngIf="auth.hasPermission(managePerm) && !hideAddBtn" color="primary" class="fab-fixed" mat-fab
  aria-label="Add new" (click)="newItem()">
  <mat-icon>add</mat-icon>
</button>

<ng-template #loading>
  <!-- <div style="min-height: 75vh;">
    <div>
      <mat-spinner diameter="40"></mat-spinner>
    </div>
  </div> -->
</ng-template>