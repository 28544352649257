import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscribable } from 'rxjs';
import { MessageType, TicketType } from 'src/app/_graphql/schema';
import { AdminTicketsService } from 'src/app/_services/admin/admin-tickets.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { MessagesService } from 'src/app/_services/messages.service';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  items$: Subscribable<MessageType[]>;
  @Input() ticketId: string;
  @Output() fileUploaded: EventEmitter<any> = new EventEmitter();

  messageText: string;
  sendingMessage = false;
  filesUploaded: any[] = [];

  transactionId: string;
  receiverId: string;
  addedFiles: any

  showAll = false;

  constructor(
    public ticketService: AdminTicketsService,
    public service: MessagesService,
    public auth: AuthenticationService
  ) {
  }
  ngOnInit(): void {
    if (this.ticketId) {
      this.items$ = this.service.all(this.ticketId);
      var s = this.ticketService.one(this.ticketId).subscribe({
        next: (x: TicketType) => {
          this.receiverId = x?.raisedFlag?.forUserId;
          s.unsubscribe();
        }
      })
      this.service.markMessagesSeen(this.ticketId);
    }
  }

  isDisabled(): boolean {
    return (this.sendingMessage || (((this.messageText || '') == '') && (this.filesUploaded.length == 0)))
  }

  sendMessage() {
    if (this.isDisabled())
      return;
    this.sendingMessage = true;
    const message: any = {
      text: this.messageText || '',
      transactionId: this.transactionId,
      ticketId: this.ticketId,
      receiverId: this.receiverId,
    };

    document.getElementById('message').addEventListener('keypress', function (event) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    });

    if (this.filesUploaded.length) {
      this.service
        .upload(this.addedFiles, null, this.receiverId, null, this.ticketId, null, false)
        .subscribe((uploadedFiles: any) => {

          if (uploadedFiles.length) {
            uploadedFiles.forEach(file => {
              message.fileId = file.id;
              this.transferMessage(message);
              message.text = '';
            });
            this.filesUploaded = [];
            this.fileUploaded.emit()
          }
        });
    } else {
      this.transferMessage(message);
    }
  }
  transferMessage(message: any) {
    this.sendingMessage = true;
    var s = this.service.sendMessage(message).subscribe({
      next: x => {
        this.sendingMessage = false;
        this.messageText = '';
        this.service.refetchData()
      },
      error: e => {
        this.sendingMessage = false;
      }
    });
  }

  handleFileInput(files: any) {
    if (files.length === 0) {
      return;
    }

    this.addedFiles = files;

    const index = this.filesUploaded.length;
    for (let i = 0; i < files.length; i++) {
      files[i].uploaded = false;
      this.filesUploaded[index + i] = files[i];
    }

  }

  deleteFile(fileId) {
    if (this.filesUploaded.length === 0) {
      return;
    }

    this.filesUploaded = this.filesUploaded.filter(
      file => file.id !== fileId
    );
  }


}