<div mat-dialog-title>
    {{ data.title|translate }}
</div>

<mat-dialog-content>

    <p>{{ data.content|translate }}</p>
    <p *ngIf="data.note" class="note-text">{{ data.note |translate }}</p>

</mat-dialog-content>

<mat-dialog-actions [formGroup]="form" align="end">
    <button class="no-button" mat-stroked-button mat-dialog-close>{{ data?.rejectLabel ?  (data?.rejectLabel | translate) : 'No' }}</button>
    <button class="yes-button" mat-raised-button mat-dialog-close [color]="data?.color || 'warn'"
        (click)="statusChange(form)" *ngIf="data?.hideYesButton ? false : true">
        {{ data?.approveLabel ?  (data?.approveLabel | translate) : 'Yes' }}</button>
</mat-dialog-actions>