import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { KycService } from 'src/app/_services/kyc.service';
import { UiService } from 'src/app/_services/ui.service';
import { MatStepper } from '@angular/material/stepper';
import { EndUserService } from 'src/app/_services/user/enduser.service';
import { UserTransactionsService } from 'src/app/_services/user/transactions.service';
import { UserFlagsService } from 'src/app/_services/user/flags.service';
import { UserTicketsService } from 'src/app/_services/user/tickets.service';
import { PublicService } from 'src/app/_services/public.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';


@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss']
})
export class KycComponent implements OnInit, OnDestroy {
  userCountry: string = "";
  loading = false;

  constructor(
    private kyc: KycService,
    private ui: UiService,
    private endUserService: EndUserService,
    private userTransactionsService: UserTransactionsService,
    private publicService: PublicService,
    private auth: AuthenticationService,
    private userFlagsService: UserFlagsService,
    private userTicketsService: UserTicketsService,
    private modalLauncherService: ModalLauncherService,
  ) {
    const local = JSON.parse(localStorage.getItem('currentUser'));
    this.userCountry = local?.user?.country?.isoCode2;



  }

  mylocalize(key, string, keyLocalised, stringLocalised) {
    if (stringLocalised != keyLocalised) {
      return stringLocalised;
    } else if (string != key) {
      return string;
    }
    return key
  }

  ngOnInit() {

  }


  logKeyValuePairs(group: any): void {
    Object.keys(group).forEach((key: string) => {
      const object = group[key];
      for (const data in object) {
        const value = object[data];
      }
    });
  }
  onSubmitData(data) {
    this.loading = true;
    this.endUserService.addConsent('KYC', null).then(() => {
      this.kyc.verify(data).subscribe({
        next: (res: any) => {
          this.loading = false;
          if (res?.data?.user?.kycVerify) {
            this.refetchData();
            this.ui.snack('Sucessfully passed the KYC process.');
            // this.router.navigate(['/platform/dashboard']);
            this.modalLauncherService.showVerify = false;
            window.history.back();
          } else {
            this.ui.snack('Verification process was not successful. We will contact you shortly with more information.');
          }
        },
        error: r => {
          this.loading = false;
        }
      });
    });
  }


  refetchData() {
    if (this.auth.user?.country?.isoCode2) {
      this.publicService.getFieldsConfig(this.auth.user.country.isoCode2, 'updateUser', false).subscribe()
    }
    this.userTransactionsService.refetchData();
    this.userTicketsService.refetchData();
    this.userTicketsService.refetchData('dashboard');
    this.userFlagsService.refetchData();
  }

  ngOnDestroy() {

  }
}
