import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TicketItemComponent } from '../../components/tickets/ticket-item/ticket-item.component';
import { MatDialog } from '@angular/material/dialog';
import { UserTicketsService } from 'src/app/_services/user/tickets.service';
import { RaisedFlagType, TicketType } from 'src/app/_graphql/schema';
import { Subscribable } from 'rxjs';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { UserFlagsService } from 'src/app/_services/user/flags.service';

@Component({
    selector: 'app-tickets',
    templateUrl: './tickets.component.html',
    styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent {

    items$: Subscribable<TicketType[]>;
    userFlagsService$: Subscribable<RaisedFlagType[]>;
    @Input() excludeClosed: boolean = false;
    constructor(
        public service: UserTicketsService,
        private mls: ModalLauncherService,
        public userFlagsService: UserFlagsService
    ) {
        this.items$ = this.service.all()
        this.userFlagsService$ = this.userFlagsService.all();
    }

    editItem(ticket: any) {
        let x = this.mls.openTicket(ticket.id).afterClosed().subscribe({
            next: k => {
                this.service.refetchData();
                x?.unsubscribe();
            }
        })
    }
}
