import { Component, OnDestroy, OnInit } from '@angular/core';
import { UiService } from 'src/app/_services/ui.service';
import { Router } from '@angular/router';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { EndUserService } from 'src/app/_services/user/enduser.service';
import { UserTransactionsService } from 'src/app/_services/user/transactions.service';
import { Transaction } from 'src/app/_graphql/schema';
import { Unsubscribable } from 'rxjs';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy {
  transactionOne$: Unsubscribable;

  constructor(
    public userTransactionsService: UserTransactionsService,
    public modalLauncherService: ModalLauncherService,
    public es: EndUserService,
    public ui: UiService,
    public router: Router,
  ) {
    window.scroll(0, 0);
  }
  ngOnInit() {
    if (this.userTransactionsService.pendingTransId) {
      this.transactionOne$ = this.userTransactionsService.one(this.userTransactionsService.pendingTransId).subscribe({
        next: (x: Transaction) => {
          this.userTransactionsService.pendingTransId = null;
          if (x.continue.enabled) {
            this.modalLauncherService.pay(x);
          }
          this.transactionOne$?.unsubscribe();
        }
      });
    }
  }
  ngOnDestroy(): void {
  }
}
