import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { EndUserType, Recipient } from 'src/app/_graphql/schema';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class AdminRecipientsService extends BaseService<Recipient>{

  selectOneFields = gql`
    fragment RecipientSelectOneFields on Recipient  {
      id 
  }`;

  selectAllFields = gql`
    fragment RecipientSelectAllFields on Recipient  {
      id name city phoneCode
      sendingCurrency moneySent
      country { id name  isoCode2 isoCode3 }
  }`;

  constructor(protected apollo: Apollo) {
    super(apollo);
    this.mainScope = "usersManagement";
    this.initGql('recipient');
  }
}
