<mat-sidenav-container>
  <mat-sidenav #sidenav [mode]="mode" opened>
    <div class="header" *ngIf="!isMobile"><a routerLink="/platform" routerLinkActive="router-link-active"
        class="main-logo"></a></div>


    <mat-action-list>
      <button mat-list-item (click)="shouldCloseNav()" [routerLink]="['/', 'platform', 'transactions']"
        routerLinkActive="router-link-active">
        <mat-icon matListIcon>payments</mat-icon>
        <h3 matLine>{{ 'Transactions' | translate }}</h3>
      </button>
      <button mat-list-item (click)="shouldCloseNav()" [routerLink]="['/', 'platform', 'recipients']"
        routerLinkActive="router-link-active">
        <mat-icon matListIcon>contact_page</mat-icon>
        <h3 matLine>{{ 'Recipients' | translate}}</h3>
      </button>
      <button mat-list-item (click)="shouldCloseNav()" [routerLink]="['/', 'platform', 'tickets']"
        routerLinkActive="router-link-active" [matBadge]="(tickets.length + warningFlags.length)" matBadgeColor="warn"
        [matBadgeHidden]="(tickets.length + warningFlags.length) == 0">
        <mat-icon matListIcon>sms</mat-icon>
        <h3 matLine>{{
          'Tickets' | translate}}
        </h3>
      </button>
      <button mat-list-item (click)="shouldCloseNav()" [routerLink]="['/', 'platform', 'profile']"
        routerLinkActive="router-link-active">
        <mat-icon matListIcon>perm_identity</mat-icon>
        <h3 matLine>{{'Profile' | translate}}</h3>
      </button>
      <button mat-list-item (click)="shouldCloseNav()" [routerLink]="['/', 'platform', 'payment-methods']"
        *ngIf="auth.hasPermission(auth.perm.PaymentMethodsManage)" routerLinkActive="router-link-active">
        <mat-icon matListIcon>account_balance</mat-icon>
        <h3 matLine>{{'Payment Methods' | translate}}</h3>
      </button>

    </mat-action-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="loader">
      <mat-progress-bar *ngIf="loading" mode="query"></mat-progress-bar>
    </div>
    <mat-toolbar *ngIf="isMobile && (user$|async) as user">
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <a routerLink="/platform" routerLinkActive="router-link-active" class="main-logo-mobile"></a>
      <span class="spacer"></span>
      <span class="user-name" *ngIf="user">{{ (user$|async)?.firstName }}</span>
      <img class="user-image" *ngIf="user" [src]="publicService.countryFlagImage((user$|async)?.country?.isoCode3)">
      <button mat-icon-button *ngIf="user" [matMenuTriggerFor]="menu">
        <mat-icon>arrow_drop_down</mat-icon>
      </button>

    </mat-toolbar>
    <mat-toolbar class="title-bar" *ngIf="!isMobile">
      <span>{{ title }} </span>
      <span class="spacer"></span>


      <span class="user-name">{{ (user$|async)?.firstName }}</span>
      <img class="user-image" *ngIf="!isMobile"
        [src]="publicService.countryFlagImage((user$|async)?.country?.isoCode3)">
      <button mat-icon-button *ngIf="!isMobile" [matMenuTriggerFor]="menu">
        <mat-icon>arrow_drop_down</mat-icon>
      </button>

    </mat-toolbar><mat-menu #menu="matMenu">
      <button mat-menu-item *ngIf="(user$|async) as user" (click)="modalService.changePassword(user)">{{ 'Change
        Password' | translate }}</button>
      <button mat-menu-item routerLink="/logout">{{ 'Log Out' | translate }}</button>
    </mat-menu>
    <app-onboarding></app-onboarding>
    <router-outlet></router-outlet>

  </mat-sidenav-content>
</mat-sidenav-container>