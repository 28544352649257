import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from 'src/app/_services/ui.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Apollo, Subscription } from 'apollo-angular';
import { EndUserService } from 'src/app/_services/user/enduser.service';
import { PublicService } from 'src/app/_services/public.service';
import { CountryType } from 'src/app/_graphql/schema';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { Unsubscribable } from 'rxjs';
import { AdminCountriesService } from 'src/app/_services/admin/admin-countries.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  backdoorBankId = '190001010101';
  form: UntypedFormGroup;
  loginMessage: string;
  regularLogin = false;
  errorUserName = false;
  authMethod: string = AuthenticationService.AUTHMETHID_DIGLIAS;
  countries: any;
  $countries: any;
  country: any;
  environment = environment;
  disabled = false;
  form$: Subscription;
  unsubSearch$: Unsubscribable;
  countries$: any;



  constructor(
    private apollo: Apollo,
    private userService: EndUserService,
    private ui: UiService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    public publicService: PublicService,

  ) {
    this.authMethod =
      environment.availableAuth === 'basic'
        ? AuthenticationService.AUTHMETHID_BASIC
        : AuthenticationService.AUTHMETHID_DIGLIAS;


    // this.$countries = this.countriesService.senders().subscribe(countries => {
    //   this.countries = countries;
    //   this.country = this.countries.find(x => x.isoCode3 === 'SWE');
    //   this.disabled = false;
    //   if (!this.country) {
    //     this.country = this.countries.find(x => x.isActive);
    //   }
    // });

    // this.authMethod =
    //   environment.availableAuth === 'basic'
    //     ? AuthenticationService.AUTHMETHID_BASIC
    //     : AuthenticationService.AUTHMETHID_DIGLIAS;
    this.$countries = this.publicService.countries(true, false, true).subscribe({
      next: (countries: CountryType[]) => {
        this.countries = countries;
      }
    })
    this.countries$ = this.publicService.countries(true, false, true);
  }

  ngOnInit() {

    this.form = this.fb.group({
      countryId: new UntypedFormControl(),
      username: [null, [Validators.required]],
      password: [null, [Validators.required]]
    });

    if (this.authMethod === 'BASIC') {
      this.form.controls.username.setValidators([Validators.required, Validators.email]);
      this.form.controls.password.setValidators(Validators.required);
    }
    this.unsubSearch$ = this.form.get('countryId').valueChanges.pipe(
      startWith(''),
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(val => {
      let country = this.countries?.find(x => x.id === this.form.value.countryId);
      if (this.form.value.countryId) {
        if (country?.isoCode3 === 'SWE' && environment.availableAuth == 'all') {
          this.authMethod = AuthenticationService.AUTHMETHID_DIGLIAS;
          this.form.controls.password.clearValidators();
          this.form.controls.password.updateValueAndValidity();
          this.disabled = false;
        } else {
          this.authMethod = AuthenticationService.AUTHMETHID_BASIC;
          this.form.controls.password.setValidators(Validators.required);
        }
      }
    })
    const errorMsg = this.route.snapshot.paramMap.get('error');
    if (errorMsg) {
      this.loginMessage = errorMsg;
    }
  }


  selectedCountry() {
    let country = this.countries.find(x => x.id === this.form.value.countryId);

    if (this.form.value.countryId) {
      if (country.isoCode3 === 'SWE' && environment.availableAuth == 'all') {
        this.authMethod = AuthenticationService.AUTHMETHID_DIGLIAS;
        this.form.controls.password.clearValidators();
        this.form.controls.password.updateValueAndValidity();
        this.disabled = false;
      } else {
        this.authMethod = AuthenticationService.AUTHMETHID_BASIC;
        this.form.controls.password.setValidators(Validators.required);
      }
    }
  }

  login(credentials: any = null): void {
    if (!this.form.valid) {
      this.ui.snack('Please fill up credentials.');
      return;
    }
    this.form.disable()
    if (this.authMethod === AuthenticationService.AUTHMETHID_BASIC) {
      var s = this.auth.login(credentials || this.form.value).subscribe({
        next: (_usr: any) => {
          if (_usr?.authToken) {
            this.auth.storeUser(_usr);
            this.apollo.client.resetStore()
            const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/platform';

            this.router.navigate([returnUrl], { replaceUrl: true });

          }
          this.form.enable()
          s?.unsubscribe();
        }, error: e => {
          this.form.enable();
          s?.unsubscribe();
        }
      })
    }

    // this.auth.checkUserName(this.loginForm.controls.username.value).subscribe(
    //   response => {

    //     if (environment.availableAuth === 'basic') {
    //       this.authMethod = AuthenticationService.AUTHMETHID_BASIC;
    //       return;
    //     }

    //     if (
    //       response &&
    //       response.authMethod !== AuthenticationService.AUTHMETHID_DIGLIAS
    //     ) {
    //       this.authMethod = AuthenticationService.AUTHMETHID_BASIC;
    //     } else {
    //       // Diglias
    //       this.loginForm.controls.password.clearValidators();
    //       this.disabled = false;
    //       window.location.href =
    //         environment.bankIdRedirectUrl +
    //         (this.loginForm.get('username').value || '');
    //     }
    //   },
    //   error => {
    //     this.errorUserName = true;
    //     this.disabled = false;
    //   }
    // );
  }

  isBankIdBackdoor() {
    return this.form.controls.username.value === this.backdoorBankId;
  }

  ngOnDestroy() {
    this.$countries?.unsubscribe();
  }
}
