import { Component, OnInit, Optional, Inject } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiService } from 'src/app/_services/ui.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminDocumentsService } from 'src/app/_services/admin/admin-documents.service';
import { Subscribable } from 'rxjs';
import { DocumentType } from 'src/app/_graphql/schema';
import { AdminFilesService } from 'src/app/_services/admin/admin-files.service';
import { AdminTransactionsService } from 'src/app/_services/admin/admin-transactions.service';


@Component({
  selector: 'app-files-upload',
  templateUrl: './files-upload.component.html',
  styleUrls: ['./files-upload.component.scss']
})
export class FilesUploadComponent implements OnInit {

  documents$: Subscribable<DocumentType[]>;

  fileUploaded: any;
  files: FileList;

  filesUploaded: any[] = [];
  fileId: any;
  documentId: any;
  fileName: string;
  addedFiles: FileList;

  showDocuments = false;
  assignedDocument = false;
  serviceSelected: string;
  description: string;
  submited = false;

  dataForm = this.fb.group({
    transactionId: [this.data.transactionId, Validators.required],
    documentId: [this.data.documentId, Validators.required],
  });

  constructor(
    private ui: UiService,
    private adminDocumentsService: AdminDocumentsService,
    private adminTransactionsService: AdminTransactionsService,
    private service: AdminFilesService,
    private fb: UntypedFormBuilder,
    private snack: MatSnackBar,
    private dialogRef: MatDialogRef<FilesUploadComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any = {}
  ) {
    if (this.data && this.data.file) {
      this.data.file.uploaded = true;
      this.fileUploaded = this.data.file;
      this.showDocuments = true;
      this.filesUploaded.push(this.data.file);
      this.description = data.file.description;

      if (this.data.file.document) {
        this.serviceSelected = data.file.document.id;
        this.documentId = data.file.document.id;
        this.assignedDocument = true;
      }
    }

  }

  ngOnInit(): void {
    this.documents$ = this.adminDocumentsService.all({ userId: this.data.userId, notExpired: this.data.changeDocument });
    this.adminDocumentsService.fetchMoreData({ userId: this.data.userId, notExpired: this.data.changeDocument });

  }

  handleFileInput(files: any) {
    if (files.length === 0) {
      return;
    }
    this.addedFiles = files;

    const index = this.filesUploaded.length;
    for (let i = 0; i < files.length; i++) {
      files[i].uploaded = false;
      this.filesUploaded[index + i] = files[i];
    }
    this.showDocuments = true;
  }

  changeDocument(value) {
    this.documentId = value;
  }

  submitForm() {
    this.submited = true;
    if (this.data.file) {
      this.updateFile();
    } else {
      this.saveFile();
    }
  }

  saveFile() {
    this.service.upload(this.addedFiles, null, this.data.userId, this.description, this.data.ticketId, this.documentId, false).subscribe((uploadedFiles: string[]) => {
      if (!uploadedFiles || uploadedFiles.length !== this.addedFiles.length) {
        console.error('Missing one or more file ids.');
        return;
      }
      this.service.refetchData('files4' + this.data.userId);
      this.adminDocumentsService.refetchData('docs4' + this.data.userId);
      const index = this.filesUploaded.length;
      for (let i = 0; i < this.addedFiles.length; i++) {
        this.filesUploaded[index + i] = uploadedFiles[i];
        this.filesUploaded[index + i].uploaded = true;
      }
      this.ui.snack('You have successfully uploaded files');
      this.dialogRef.close();
    });
  }

  updateFile() {
    const form = this.fb.group({
      id: this.data.file.id,
      categoryId: null,
      description: this.description,
      documentId: this.documentId,
    });


    this.service.updateFile(form.value).subscribe(
      res => {
        this.service.refetchData('files4' + this.data.userId);
        this.adminDocumentsService.refetchData('docs4' + this.data.userId);
        this.dialogRef.close();
        this.ui.snack('File is updated.');
      },
      err => {
        this.ui.snack('Error in updating file.');
      }
    );
  }

  submitChangeDocument() {
    this.submited = true;
    this.adminTransactionsService.changeTransactionDocument(this.dataForm.value).subscribe(
      res => {
        this.dialogRef.close();
        this.ui.snack('Document is replaced.');
      },
      err => {
        this.ui.snack(err.message);
      }
    );
  }

  deleteFile(fileId) {
    if (this.filesUploaded.length === 0) {
      return;
    }
    this.filesUploaded = this.filesUploaded.filter(
      file => file.id !== fileId
    );
    this.showDocuments = false;
    // this.ui.loaderShow();
    // this.documentsService.deleteFile(fileId, this.data.userId).subscribe(
    //     res => {
    //       this.dialogRef.close();
    //       this.ui.loaderHide('File deleted');
    //       this.fileUploaded = null;
    //     },
    //     err => {
    //       this.ui.loaderHide();
    //       this.ui.snack('Error in deleting files');
    //     }
    // );
  }
} 