<div class="container2">
  <div class="center">
    <div style="padding: 0px 24px 24px 24px; text-align: center;">
      <h1>
        {{ mylocalize('page_kyc_title', 'page_kyc_title' | translate, 'page_kyc_title_'+userCountry,
        ('page_kyc_title_'+userCountry) | translate) }}
      </h1>
      <h5>
        {{ mylocalize('page_kyc_subtitle', 'page_kyc_subtitle' | translate, 'page_kyc_subtitle_'+userCountry,
        ('page_kyc_subtitle_'+userCountry) | translate) }}
      </h5>
      <mat-hint>
        {{ mylocalize('page_kyc_description', 'page_kyc_description' | translate, 'page_kyc_description_'+userCountry,
        ('page_kyc_description_'+userCountry) | translate) }}
      </mat-hint>
    </div>
  </div>
  <!-- <app-form-builder confType="kyc" (onSubmitData)="onSubmitData($event)" [loading]="loading"></app-form-builder> -->
  <app-form-config confType="kyc" (onSubmitData)="onSubmitData($event)" [loading]="loading"></app-form-config>

</div>