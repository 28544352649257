<div [ngClass]="{'io-item':true, 'io-item-shadow':hasShadow, clickable:isClickable}">
    <!-- {{item?.raisedFlag|json}} -->
    <div class="main">
        <h4>{{ item?.number }}</h4>
        <div class="data subject">
            <small>{{ 'Subject' | translate}}</small>
            <span>{{item?.subject}}</span>
        </div>
        <app-ticket-status [ticket]="item" class="d-none d-l-show"></app-ticket-status>
        <div class="data status">
            <small>{{ 'Status' | translate}}</small>
            <span>{{ item?.status.split('_').join(' ') | translate }}</span>
        </div>
        <div class="data" *ngIf="item?.raisedFlag?.transaction?.transactionId">
            <small>{{ 'Transaction' | translate}}</small>
            <span>
                {{item?.raisedFlag?.transaction?.transactionId }}
            </span>
        </div>
        <div class="data" *ngIf="!item?.raisedFlag?.transaction?.transactionId">
            <small>{{ 'User' | translate}}</small>
            <span>
                {{item?.raisedFlag?.user?.firstName}}
                {{item?.raisedFlag?.user?.middleName}}
                {{item?.raisedFlag?.user?.lastName}}
            </span>
        </div>
        <div class="data">
            <small>{{ 'Created' | translate}}</small>
            <span matTooltip="{{ item?.created | timezone | date }}">
                {{item?.created | timezone | date }}
            </span>
        </div>

        <div class="data no-flex cta">
            <ng-content></ng-content>
        </div>
    </div>
</div>