import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { AccountAction, DeleteAccountResponseType, EndUserType } from 'src/app/_graphql/schema';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalLauncherService } from 'src/app/_services/modal-launcher.service';
import { MatStepper } from '@angular/material/stepper';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { UiService } from 'src/app/_services/ui.service';
import { TranslatePipe } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
  providers: [TranslatePipe]
})
export class DeleteAccountComponent implements OnInit, OnDestroy {
  password = new FormControl(null, [Validators.required]);
  hide = true;
  @ViewChild('stepper') stepper: MatStepper;
  deleteAccountResponse: DeleteAccountResponseType;
  deleteButtonDisabled = false;

  constructor(
    private auth: AuthenticationService,
    private apollo: Apollo,
    private dialog: MatDialog,
    private ui: UiService,
    private translatePipe: TranslatePipe,
    private router: Router

  ) { }

  ngOnInit(): void {
  }
  confirmIdentity() {
    this.auth.confirmIdentity({userName: this.auth.user.email, password:this.password.value, 
      action: AccountAction.DeleteAccount}).pipe(
        take(1)
      ).subscribe({
      next: (user: EndUserType) => { 
        this.auth.storeUser(user);
        this.apollo.client.resetStore();
        this.deleteUserAccount();
       }
    })
  }
  deleteUserAccount() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '600px',
        data: {
          title: 'Delete Your Account',
          content: this.translatePipe.transform('delete_account_confirmation_popup_desc'),
          note: '',
          approveLabel: 'Delete Account',
          rejectLabel: 'Cancel'
        },
        disableClose: true,
        maxHeight: '80%'
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.deleteButtonDisabled = true;
          this.auth.deleteUserAccount().pipe(
            take(1)
          ).subscribe({
            next: (response: DeleteAccountResponseType) => {
              this.deleteButtonDisabled = false;
              this.deleteAccountResponse = response;
              if (response.status !== 'ERROR') {
                this.ui.snack(response?.infoMessage);
                this.stepper.selectedIndex = 2;
              }
              if (response.status === 'IN_PROGRESS') {
                this.auth.login({username: this.auth.user.email, password:this.password.value}).pipe(
                take(1)).subscribe({
                  next: (_usr: EndUserType) => {
                    if (_usr?.authToken) {
                      this.auth.storeUser(_usr);
                      this.apollo.client.resetStore();
                    }
                  }
                })
              }
            },
            error: () => this.deleteButtonDisabled = false
         })
        } else
          this.deleteButtonDisabled = false;
      })
  }
  ngOnDestroy(): void {
    if (this.deleteAccountResponse?.status === 'SUCCESS')
      this.router.navigate(['/logout']);
  }
  navigateAfterDelete() {
    if (this.deleteAccountResponse?.status === 'SUCCESS')
      this.router.navigate(['/logout']);
    else 
      this.router.navigate(['platform', 'dashboard']);
  }

}
