<div class="container2">
  <div class="center">
    <div class="questionary">
      <h2>{{'Questionnaire' | translate}}</h2>
      <!-- <app-form-builder [hideSteps]="true" [loading]="loading" confType="Questionnaire"
        (onSubmitData)="onSubmitData($event)">
      </app-form-builder> -->
      <app-form-config [hideSteps]="true" [loading]="loading" confType="Questionnaire"
      (onSubmitData)="onSubmitData($event)">
      </app-form-config>
    </div>
  </div>
</div>