import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountListItemComponent } from './account/account-list-item.component';
import { AccountsListComponent } from './accounts.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { ModalAccountAddComponent } from './modal-account-add/modal-account-add.component';
import { PanelModule } from 'src/app/components/panel/panel.module';
import { RouterModule } from '@angular/router';
import { WrapperModule } from '../wrapper/wrapper.module';
import { AgreementItemComponent } from './agreement-item/agreement-item.component';
import { CalculatorModule } from 'src/app/components/calculator/calculator.module';



@NgModule({
  declarations: [
    AccountListItemComponent,
    AccountsListComponent,
    ModalAccountAddComponent,
    AgreementItemComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    TranslateModule.forChild(),
    PanelModule,
    RouterModule,
    WrapperModule,
    CalculatorModule
  ],
  exports: [
    AccountListItemComponent,
    AccountsListComponent,
    ModalAccountAddComponent
  ]
})
export class AccountsModule { }
